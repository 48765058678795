<template>
    <div>
        <table class="w-full table-auto">
            <tr class="table-header-group text-left">
                <th class="py-2 px-4">Name</th>
                <th class="py-2 px-4 text-center">Status</th>
                <th class="py-2 px-4 text-center">Urgency</th>
                <th class="py-2 px-4">Parties</th>
            </tr>

            <tr v-for="item in cases" :key="item.id" class="cursor-pointer rounded-lg bg-white hover:bg-gray-200"
                @click="buttonClicked(item)">
                <td class="flex space-x-4 py-2 px-4">
                    <span>
                        {{ item.name }}
                    </span>
                    <div v-if="item.notification_total > 0" class="flex">
                        <div class="bg-gradient flex w-max justify-center space-x-1 rounded-lg py-1 px-2 text-white">
                            <NotificationIcon />
                            <p class="text-xs font-bold">{{ item.notification_total }}</p>
                        </div>
                    </div>
                </td>
                <td class="justify-center py-2 px-4 text-center">
                    <div v-if="item.status === 'onprepare'"
                        class="flex w-full justify-center rounded-lg bg-red-100 py-1 px-4">
                        <p class="text-xs font-bold text-red-500">
                            {{ $t('case.onPrepare') }}
                        </p>
                    </div>
                    <div v-else-if="item.status === 'ongoing'"
                        class="flex w-full justify-center rounded-lg bg-orange-100 py-1 px-4">
                        <p class="text-xs font-bold text-orange-500">
                            {{ $t('case.onGoing') }}
                        </p>
                    </div>
                    <div v-else-if="item.status === 'solved'"
                        class="flex w-full justify-center rounded-lg bg-green-100 py-1 px-4">
                        <p class="text-xs font-bold text-green-500">
                            {{ $t('case.solved') }}
                        </p>
                    </div>
                </td>
                <td class="justify-center py-2 px-4 text-center">
                    <div v-if="item.priority === 'high'" class="flex w-full justify-center rounded-lg bg-red-100 py-1 px-4">
                        <p class="text-xs font-bold text-red-500">
                            {{ $t('case.highUrgency') }}
                        </p>
                    </div>
                    <div v-else-if="item.priority === 'medium'"
                        class="flex w-full justify-center rounded-lg bg-orange-100 py-1 px-4">
                        <p class="text-xs font-bold text-orange-500">
                            {{ $t('case.mediumUrgency') }}
                        </p>
                    </div>
                    <div v-else-if="item.priority === 'low'"
                        class="flex w-full justify-center rounded-lg bg-green-100 py-1 px-4">
                        <p class="text-xs font-bold text-green-500">
                            {{ $t('case.lowUrgency') }}
                        </p>
                    </div>
                </td>
                <td class="flex py-2 px-4">
                    <Avatars
                    :extract-user="true"
                    :parties="item.case_parties"
                    />
                    <!-- <div
                        class="relative flex"
                        @mouseover="showTooltip = true"
                        @mouseleave="showTooltip = false"
                    >
                        <div
                        v-if="showTooltip"
                        class="absolute bottom-16 right-1 z-10 w-full rounded-lg bg-white p-2 text-center text-xs text-slate-700 shadow-lg"
                        >
                        Share
                        </div> -->
                    <ShareIcon v-if="isConcluded && !item.share_to_you" class="inline h-8 w-8 cursor-pointer"
                        @click.stop="shareCase(item.id)" />
                    <!-- </div> -->
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import Avatars from './Avatars.vue'
import NotificationIcon from '@/assets/svg/notifications.svg'
import ShareIcon from '@/assets/svg/share.svg'

export default {
    name: 'CaseList',
    components: {
        Avatars,
        NotificationIcon,
        ShareIcon,
    },
    props: {
        cases: {
            type: Array,
            default: () => {
                return []
            },
        },
        isConcluded: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['click-case', 'share-case'],
    data: () => ({
        showTooltip: false,
    }),
    methods: {
        buttonClicked(item) {
            this.$emit('click-case', item)
        },
        shareCase(itemId) {
            this.$emit('share-case', itemId)
        },
    },
}
</script>
