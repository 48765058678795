export default {
  data: () => ({
    preventRefresh: true,
  }),

  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav)
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav)
  },

  beforeRouteLeave(to, from, next) {
    if (this.preventRefresh) {
      if (!window.confirm('Leave without saving?')) {
        return
      }
    }
    next()
  },

  methods: {
    preventNav(event) {
      if (!this.preventRefresh) return
      event.preventDefault()
      event.returnValue = ''
    },
  },
}
