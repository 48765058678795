import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'
import { toBase64 } from '../../utils/Utils.js'

// initial state
const state = () => ({
    activeCase: '',
    newCase: {},
    files: [],
    activeNewCaseBreadcrum: 1,
    threeNeutralAdminId: '',
    partyTypes: [
        { key: 'author', translateReference: 'general.user' },
        { key: 'advocate', translateReference: 'general.advocate' },
        { key: 'bank_lawyer', translateReference: 'general.bankLawyer' },
        { key: 'mediator', translateReference: 'general.mediator' },
        { key: 'arbitrator', translateReference: 'general.arbitrator' },
    ],
})

// getters
const getters = {
    getpartyTypes (state) {
        return state.partyTypes
    },
    activeCase(state) {
        // return state.activeCase
        return localStorage.getItem('redek.activeCase')
    },
    newCase(state) {
        return state.newCase || {}
    },
    caseFiles(state) {
        return state.files || []
    },
    activeNewCaseBreadcrum(state) {
        return state.activeNewCaseBreadcrum
    },
    threeNeutralAdminId(state) {
        return state.threeNeutralAdminId
    },
    caseVideo(state) {
        return localStorage.getItem('redek.caseVideo')
    },
    caseModeConcluded(state) {
        return localStorage.getItem('redek.caseModeConcluded') || false
    },
    getActiveCaseGetterVuex (state) {
        return state.activeCase
    },
    currentCaseWasClosed (state) {
        if (state.activeCase) {
            return state.activeCase.stage === 5
        } else {
            return true;
        }
    },
    getCurrentDeadlineStageCase (state) {
        if (state.activeCase) {
            if (state.activeCase.arbitration_end_time) {
                return state.activeCase.arbitration_end_time
            } else if (state.activeCase.mediation_end_time) {
                return state.activeCase.mediation_end_time
            } else if (state.activeCase.negotiation_end_time) {
                return state.activeCase.negotiation_end_time
            }
        } else {
            return null
        }
    }
}

// actions
const actions = {
    generateDocument({ state, commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {
                case_id: payload.case_id,
            }

            axios
                .post(`${routePathAPI()}/cases/arbitration/document`, params, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then((response) => {
                    const responseData = response.data
                    if (
                        (response.status === 200 || response.status === 201) &&
                        responseData.error === undefined
                    ) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    setCurrentCase ({ commit, state, dispatch }, payload) {
        dispatch('getCaseDetail', { id: payload.case_id }).then(response => {
            if (response.data) {
                state.activeCase = response.data
            }
        })
    },
    getActiveCase({ commit }, payload) {
        return localStorage.getItem('redek.activeCase')
    },
    getCaseDetail({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            axios.get(`${routePathAPI()}/cases/${payload.id}`, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            })
                .then(async (response) => {
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: response.data,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: response.data,
                        })
                    }
                }).catch((error) => {
                    reject(error)
                })
        })
    },
    getAllCases({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            let params = {}
            if (payload.page) params.page = payload.page
            if (payload.take) params.per_page = payload.take
            if (payload.order) params.order = 'created_at.' + payload.order
            if (payload.q) params.search = payload.q
            if (payload.status) params.query = 'query=status.' + payload.status

            axios
                .get(`${routePathAPI()}/cases`, {
                    params: params,
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: response.data,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: response.data,
                        })
                    }
                })
                .catch((error) => {

                    console.log(error)

                    reject(error)
                })
        })
    },
    getAllTypes({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {}
            if (payload.page) params.page = payload.page
            if (payload.take) params.per_page = payload.take
            if (payload.order) params.order = 'created_at.' + payload.order
            if (payload.q) params.search = payload.q
            if (!payload.manage) params.query = 'status.1'

            axios.get(`${routePathAPI()}/casestypes`, {
                params: params,
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            }).then(async (response) => {
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAllCurrencies({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {}
            if (payload.page) params.page = payload.page
            if (payload.take) params.per_page = payload.take
            if (payload.order) params.order = 'created_at.' + payload.order
            if (payload.q) params.search = payload.q
            if (!payload.manage) params.query = 'status.1'

            axios.get(`${routePathAPI()}/currencies`, {
                params: params,
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            }).then(async (response) => {
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeCase({ commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            axios
                .delete(`${routePathAPI()}/cases/${payload.id}`, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    const responseData = response.data
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAllAreas({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {}
            if (payload.page) params.page = payload.page
            if (payload.take) params.per_page = payload.take
            if (payload.order) params.order = 'created_at.' + payload.order
            if (payload.q) params.search = payload.q
            if (!payload.manage) params.query = 'status.1'

            axios
                .get(`${routePathAPI()}/casesareas`, {
                    params: params,
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: response.data,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: response.data,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getCaseOverview({ commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {
                semester: payload.semester,
                year: payload.year,
            }
            axios.get(`${routePathAPI()}/cases/overview`, {
                params: params,
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            }).then(async (response) => {
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getCaseAreaOverview({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {
                semester: payload.semester,
                year: payload.year,
            }
            axios.get(`${routePathAPI()}/cases/area/overview`, {
                params: params,
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            }).then(async (response) => {
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    generatePDFCase({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {
                semester: payload.semester,
                year: payload.year,
                image_url: payload.image_url,
            }
            axios
                .post(`${routePathAPI()}/cases/pdf/generate`, params)
                .then(async (response) => {
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: response.data,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'success',
                            data: response.data,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    sendPDFCase({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            let params = {
                semester: payload.semester,
                year: payload.year,
                image_url: payload.image_url,
                to: payload.to
            }
            axios.post(`${routePathAPI()}/cases/pdf/send`, params,
                {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                }
            ).then(async (response) => {
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    getCaseParties({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            axios
                .get(`${routePathAPI()}/cases/${payload.case_id}/parties`, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    const responseData = response.data
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAllCasesAdmin({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            let params = {}
            if (payload.page) params.page = payload.page
            if (payload.take) params.per_page = payload.take
            if (payload.order) params.order = 'created_at.' + payload.order
            if (payload.q) params.search = payload.q
            if (payload.status) params.query = 'status.' + payload.status
            if (payload.area) params.area = payload.area

            axios.get(`${routePathAPI()}/cases`, {
                params: params,
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            })
                .then(async (response) => {
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: response.data,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: response.data,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    createCase({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            let newPayload = JSON.parse(JSON.stringify(payload))
            toBase64(payload.attachment).then(result => {
                newPayload.attachment = result
                    ? {
                        cont: result.split(',').pop(),
                        name: payload.attachment.name.split('.')[0],
                        ext: payload.attachment.name.split('.').pop(),
                        size: payload.attachment.size
                    }
                    : null
                newPayload.currency_id = payload.currency
                newPayload.preCClaimant = payload.preCClaimant ? payload.preCClaimant.id : null
                newPayload.preCAdvocate = payload.preCAdvocate ? payload.preCAdvocate.id : null
                newPayload.preCBanklawyer = payload.preCBanklawyer ? payload.preCBanklawyer.id : null
                newPayload.preCMediator = payload.preCMediator ? payload.preCMediator.id : null
                newPayload.preCArbitrator = payload.preCArbitrator ? payload.preCArbitrator.id : null

                axios.post(`${routePathAPI()}/cases`, newPayload)
                .then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: response.data,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: response.data,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
            })
        })
    },
    casePriority({ state, commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {
                priority: payload.priority,
            }

            axios
                .put(`${routePathAPI()}/case/priority/${payload.id}`, params, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200 && responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    caseAssign({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let newPayload = JSON.parse(JSON.stringify(payload))
            toBase64(payload.attachment).then(result => {
                newPayload.attachment = result
                    ? {
                        cont: result.split(',').pop(),
                        name: payload.attachment.name.split('.')[0],
                        ext: payload.attachment.name.split('.').pop(),
                        size: payload.attachment.size
                    }
                    : null

                axios.post(`${routePathAPI()}/cases/assign/${ payload.id }`, newPayload, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: response.data,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: response.data,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
            })
        })
    },
    caseDuteDate({ state, commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {
                due_date: payload.dueDate,
            }

            axios
                .put(`${routePathAPI()}/case/due-date/${payload.id}`, params, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200 && responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    caseInititate({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {
                stage: payload.stage,
            }

            axios
                .put(`${routePathAPI()}/cases/initiate/${payload.id}`, params, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200 && responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateCase({ state, commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {
                stage: payload.stage,
                party_id: payload.party_id,
            }

            axios
                .put(`${routePathAPI()}/case/${payload.case_id}`, params, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200 && responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    resolveCase({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            let newPayload = JSON.parse(JSON.stringify(payload))
            toBase64(payload.attachment).then(result => {
                newPayload.attachment = result
                    ? {
                        cont: result.split(',').pop(),
                        name: payload.attachment.name.split('.')[0],
                        ext: payload.attachment.name.split('.').pop(),
                        size: payload.attachment.size
                    }
                    : null
                axios
                    .post(
                        `${routePathAPI()}/cases/solve`,
                        newPayload
                    )
                    .then((response) => {
                        const responseData = response.data
                        if (response.status === 200) {
                            resolve({
                                status: response.status,
                                message: 'success',
                                data: responseData,
                            })
                        } else {
                            reject({
                                status: response.status,
                                message: 'failed',
                                data: responseData,
                            })
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        })
    },
    resolveCaseVideo({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            let newPayload = JSON.parse(JSON.stringify(payload))
            toBase64(payload.video).then(result => {
                newPayload.video = result
                    ? {
                        cont: result.split(',').pop(),
                        name: payload.video.name.split('.')[0],
                        ext: payload.video.name.split('.').pop(),
                        size: payload.video.size
                    }
                    : null

                console.log(newPayload);

                axios
                    .post(
                        `${routePathAPI()}/cases/solve/video/upload`,
                        newPayload,
                    )
                    .then((response) => {
                        const responseData = response.data
                        if (response.status === 200) {
                            resolve({
                                status: response.status,
                                message: 'success',
                                data: responseData,
                            })
                        } else {
                            reject({
                                status: response.status,
                                message: 'failed',
                                data: responseData,
                            })
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        })
    },
    uploadFileCase({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            let newPayload = JSON.parse(JSON.stringify(payload))
            toBase64(payload.attachment).then(result => {
                newPayload.attachment = result
                    ? {
                        cont: result.split(',').pop(),
                        name: payload.attachment.name.split('.')[0],
                        ext: payload.attachment.name.split('.').pop(),
                        size: payload.attachment.size
                    }
                    : null
                newPayload.folder_system_identity = 'case_root.case_record.initial_stage.identity'

                axios
                    .post(
                        `${routePathAPI()}/cases/upload/${payload.case_id}`,
                        newPayload,
                    )
                    .then((response) => {
                        const responseData = response.data
                        if (response.status === 200 && responseData.error === undefined) {
                            resolve({
                                status: response.status,
                                message: 'success',
                                data: responseData,
                            })
                        } else {
                            reject({
                                status: response.status,
                                message: 'failed',
                                data: responseData,
                            })
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        })
    },
    getFileCase({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            const params = {
                query: 'type.' + payload.type,
            }
            axios.get(`${routePathAPI()}/cases/documents/${payload.case_id}`, {
                params: params,
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            }).then((response) => {
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    getMediationAgreement({ state, commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            const params = {}

            axios
                .get(`${routePathAPI()}/case/mediation/${payload.case_id}`, params, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200 && responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    createMediationAgreement({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            }
            let formData = new FormData()
            formData.append('result', payload.result)
            formData.append('notes', payload.notes)
            // if(payload.attachment) formData.append('attachment', payload.attachment)

            axios
                .post(
                    `${routePathAPI()}/case/mediation/create/${payload.case_id}`,
                    formData,
                    config
                )
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200 && responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    actionMediationAgreement({ state, commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {
                mediation_id: payload.mediation_id,
                accept: payload.accept,
                reason: payload.reason ? payload.reason : '',
            }

            axios
                .post(
                    `${routePathAPI()}/case/mediation/action/${payload.case_id}`,
                    params,
                    {
                        headers: {
                            'x-language-code': currentLanguageCode,
                        },
                    }
                )
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200 && responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAllConcludedCases({ commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {}
            if (payload.page) params.page = payload.page
            if (payload.take) params.take = payload.take
            if (payload.order) params.order = payload.order
            if (payload.q) params.q = payload.q
            // if (payload.status) params.status = payload.status

            axios
                .get(`${routePathAPI()}/case/concluded`, {
                    params: params,
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    const responseData = response.data
                    if (response.status === 200 && responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    assignConcludedCase({ state, commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {
                case_id: payload.case_id,
                party_ids: payload.party_ids,
            }

            axios
                .post(`${routePathAPI()}/case/concluded/assign`, params, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then((response) => {
                    const responseData = response.data
                    if (
                        (response.status === 200 || response.status === 201) &&
                        responseData.error === undefined
                    ) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    setActiveCase({ commit }, payload) {
        commit('SET_ACTIVE_CASE', payload)
    },
    setModeConcluded({ commit }, payload) {
        commit('SET_MODE_CONCLUDED_CASE', payload)
    },
    setNewCase({ commit }, payload) {
        commit('SET_NEW_CASE', payload)
    },
    setFiles({ commit }, payload) {
        commit('SET_FILES', payload)
    },
    setActiveNewCaseBreadcrum({ commit }, payload) {
        commit('SET_NEW_CASE_ACTIVE_BREADCRUM', payload)
    },
    setThreeNeutralAdminId({ commit }, payload) {
        commit('SET_THREE_NEUTRAL_ADMIN_ID', payload)
    },
    resetActiveCase({ commit }) {
        commit('RESET_ACTIVE_CASE')
    },
    resetModeConcluded({ commit }) {
        commit('RESET_MODE_CONCLUDED_CASE')
    },
    resetNewCase({ commit }) {
        commit('RESET_NEW_CASE')
    },
    resetFiles({ commit }) {
        commit('RESET_FILES')
    },
    resetActiveNewCaseBreadcrum({ commit }) {
        commit('RESET_NEW_CASE_ACTIVE_BREADCRUM')
    },
    resetThreeNeutralAdminId({ commit }) {
        commit('RESET_THREE_NEUTRAL_ADMIN_ID')
    },
    setActiveCaseVuex ({ commit }, payload) {
        commit('SET_ACTIVE_CASE_VUEX', payload);
    },
    getActiveCaseActVuex ({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            resolve(state.activeCase)
        })
    },
    importCases ({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            let newPayload = JSON.parse(JSON.stringify(payload))
            toBase64(payload.attachment).then(result => {
                newPayload.attachment = result
                    ? {
                        cont: result.split(',').pop(),
                        name: payload.attachment.name.split('.')[0],
                        ext: payload.attachment.name.split('.').pop(),
                        size: payload.attachment.size
                    }
                    : null
                axios
                    .post(
                        `${routePathAPI()}/cases/import`,
                        newPayload,
                    )
                    .then((response) => {
                        const responseData = response.data
                        if (response.status === 200) {
                            resolve({
                                status: response.status,
                                message: 'success',
                                data: responseData,
                            })
                        } else {
                            reject({
                                status: response.status,
                                message: 'failed',
                                data: responseData,
                            })
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })


        })
    },
    getCasesImportTemplate({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {

            axios.get(`${routePathAPI()}/preferences/getCasesImportTemplate`, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            })
            .then(async (response) => {
                const responseData = response.data
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: responseData,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: responseData,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    isAValidCaseParty({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            axios.get(`${routePathAPI()}/cases/isAValidCaseParty/${payload.case_id}`, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            }).then(async (response) => {
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    getAvailableYearsCases ({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            axios.get(`${routePathAPI()}/cases/years/available`, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            })
                .then(async (response) => {
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: response.data,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: response.data,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

// mutations
const mutations = {
    SET_ACTIVE_CASE_VUEX (state, val) {
        state.activeCase = val;
    },
    SET_ACTIVE_CASE(state, val) {
        // state.activeCase = val
        return localStorage.setItem('redek.activeCase', val)
    },
    SET_MODE_CONCLUDED_CASE(state, val) {
        return localStorage.setItem('redek.caseModeConcluded', val)
    },
    SET_NEW_CASE(state, val) {
        state.newCase = val
    },
    SET_FILES(state, val) {
        state.files = val
    },
    SET_NEW_CASE_ACTIVE_BREADCRUM(state, val) {
        state.activeNewCaseBreadcrum = val
    },
    SET_THREE_NEUTRAL_ADMIN_ID(state, val) {
        state.threeNeutralAdminId = val
    },
    RESET_ACTIVE_CASE(state) {
        // state.activeCase = ''
        return localStorage.removeItem('redek.activeCase')
    },
    RESET_MODE_CONCLUDED_CASE(state) {
        return localStorage.removeItem('redek.caseModeConcluded')
    },
    RESET_NEW_CASE(state) {
        state.newCase = {}
    },
    RESET_FILES(state) {
        state.files = []
    },
    RESET_NEW_CASE_ACTIVE_BREADCRUM(state) {
        state.activeNewCaseBreadcrum = 1
    },
    RESET_THREE_NEUTRAL_ADMIN_ID(state) {
        state.threeNeutralAdminId = ''
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
