import { createApp } from 'vue'
import { createPinia } from 'pinia'

import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'

import Toast, { POSITION } from 'vue-toastification'
import VueLoadImage from 'vue-load-image'
import Multiselect from 'vue-multiselect'
import { configure } from 'vee-validate'
import Shimmer from 'vue3-shimmer'

import axios from './plugins/axios.js'
import i18n from './plugins/i18n.js'
import store from './stores/vuex'
import router from './router'
import App from './App.vue'
// import globalComponents from './global-components'
import utils from './utils'

import VOtpInput from 'vue3-otp-input'
import socket from "./socket"
// import Iconly from 'vue-iconly'

import 'vue-multiselect/dist/vue-multiselect.css'
// import '@vueform/multiselect/themes/default.css'
import 'vue-toastification/dist/index.css'

import './assets/css/style.scss'

import '@fortawesome/fontawesome-free/css/all.css';

import methods from './global/methods'
import filters from './global/filters'
import directives from './global/directives'
import moment from 'moment/moment.js';

const app = createApp(App)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrBefore)

configure({
  validateOnInput: true,
})

app.use(createPinia())
app.use(Shimmer)
app.use(router)
app.use(store)
app.use(i18n)
app.use(socket)
app.use(Toast, {
    position: POSITION.BOTTOM_RIGHT,
    timeout: 2000,
})
app.use(methods);
app.use(filters);
app.use(directives);
// app.use(Iconly)

app.provide('axios', axios) // provide axios
app.provide('dayjs', dayjs) // provide dayJS
app.provide('moment', moment)
app.provide(
  'defaultImage',
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
) // provide defaultImage
const checkEmptyObject = (obj) => {
  // because Object.keys(new Date()).length === 0;
  // we have to do some additional check
  return (
    obj && // 👈 null and undefined check
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  )
}
app.provide('checkEmptyObject', checkEmptyObject) // provide checkEmptyObject
const getImgUrl = (paramsData = { filename, type, subfolder }) => {
  return new URL(
    `../src/assets/${paramsData.subfolder}${paramsData.filename}.${paramsData.type}`,
    import.meta.url
  ).href
}
app.provide('getImgUrl', getImgUrl) // provide getImgUrl

// app.prototype.$axios = axios
// app.config.globalProperties.$http = axios

app.component('VueMultiselect', Multiselect)
app.component('VueLoadImage', VueLoadImage)
app.component('VOtpInput', VOtpInput)


// globalComponents(app)
utils(app)

app.mount('#app')

app.config.globalProperties.window = window
