<template>
    <div
    v-if="!isLoading"
    class="flex flex-col gap-3"
    >
        <div v-permission="'parameters_master_data_list_currencies'">
            <Currencies
            :list="currencies"
            :toast="toast"
            @reload="getCurrencies"
            />
        </div>
        <div v-permission="'parameters_master_data_list_case_types'">
            <CaseTypes
            :list="caseTypes"
            :toast="toast"
            @reload="getCaseTypes"
            />
        </div>
        <div v-permission="'parameters_master_data_list_areas'">
            <CaseAreas
            :list="caseAreas"
            :toast="toast"
            @reload="getCaseAreas"
            />
        </div>
        <div v-permission="'parameters_master_data_list_id_types'">
            <IdentificationTypes
            :list="identificationTypes"
            :toast="toast"
            @reload="getIdentificationTypes"
            />
        </div>
    </div>
    <DualRingLoader v-if="isLoading" />
</template>
<script>
import Currencies from '@/partials/parameters/master-data/Currencies.vue'
import IdentificationTypes from '@/partials/parameters/master-data/IdentificationTypes.vue'
import CaseTypes from '@/partials/parameters/master-data/CaseTypes.vue'
import CaseAreas from '@/partials/parameters/master-data/CaseAreas.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'

export default {
    components: {
        Currencies,
        DualRingLoader,
        CaseTypes,
        IdentificationTypes,
        CaseAreas,
    },
    props: {
        toast: {
            type: Function,
            default: () => {}
        }
    },
    data: function () {
        return {
            currencies: [],
            caseTypes: [],
            caseAreas: [],
            identificationTypes: [],
            isLoading: false,
            promises: [],

        }
    },
    mounted () {
        this.isLoading = true
        this.getCurrencies()
        this.getCaseTypes()
        this.getCaseAreas()
        this.getIdentificationTypes()
        Promise.all(this.promises).then(() => {
            this.isLoading = false
        })
    },
    methods: {
        getCurrencies () {
            const promise = this.$store.dispatch('cases/getAllCurrencies', { take: 300, manage: true }).then(response => {
                if (response.data && response.data.data) {
                    this.currencies = response.data.data
                }
            }).catch(error => {
                this.toast.error(this.$methods.extractErrorResponse(error, this.$t))
                this.isLoading = false
            })
            this.promises.push(promise)
        },
        getCaseTypes () {
            const promise = this.$store.dispatch('cases/getAllTypes', { take: 300, manage: true }).then(response => {
                if (response.data && response.data.data) {
                    this.caseTypes = response.data.data
                }
            }).catch(error => {
                this.toast.error(this.$methods.extractErrorResponse(error, this.$t))
                this.isLoading = false
            })
            this.promises.push(promise)
        },
        getCaseAreas () {
            const promise = this.$store.dispatch('cases/getAllAreas', { take: 300, manage: true }).then(response => {
                if (response.data && response.data.data) {
                    this.caseAreas = response.data.data
                }
            }).catch(error => {
                this.toast.error(this.$methods.extractErrorResponse(error, this.$t))
                this.isLoading = false
            })
            this.promises.push(promise)
        },
        getIdentificationTypes () {
            const promise = this.$store.dispatch('identificationtypes/getAll', { take: 300, manage: true }).then(response => {
                if (response.data && response.data.data) {
                    this.identificationTypes = response.data.data
                }
            }).catch(error => {
                this.toast.error(this.$methods.extractErrorResponse(error, this.$t))
                this.isLoading = false
            })
            this.promises.push(promise)
        },
    }
}

</script>