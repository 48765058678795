<template>
    <div class="p-2 flex flex-col gap-3">
        <div class="md:grid grid-cols-3 items-center gap-3">
            <div class="flex items-center gap-3 col-span-2">
                <i class="fa-solid fa-address-card text-lg"></i>
                <span class="font-bold text-lg">
                    {{ $t('parameters.masterData.identificationTypes.title') }}
                </span>
                <button
                v-permission="'parameters_master_data_create_id_types'"
                @click="openNewIdentificationType"
                class="btn bg-sky-500 text-white"
                >
                    <i class="fa-solid fa-plus"></i>
                </button>
            </div>
            <input
            v-model="search"
            type="text"
            :placeholder="$t('general.search') + '...'"
            class="form-input w-full rounded-md"
            >
        </div>
        <div>
            <div class="max-h-[300px] overflow-y-auto beauty-scroll">
                <DataTable
                :headers="dataTable.headers"
                :list="getSearchableList"
                @edit="openEditIdentificationType"
                @delete="openDeleteIdentificationType"
                >
                    <template v-slot:cell-status="cell3Props">
                        <div :class="[
                            'w-full flex flex-col items-center',
                            (cell3Props.item.status) ? 'text-green-500' : 'text-red-500'
                        ]">
                            <div class="flex items-center gap-1">
                                <i
                                class="fa-solid fa-power-off"></i>
                                {{ $t((cell3Props.item.status) ? 'status.active' : 'status.inactive') }}
                            </div>
                        </div>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>

    <ModalDelete
    :backdrop="true"
    :id="currentIdentificationType.id"
    :is-open="deleteIdentificationTypeModalOpen"
    :title="$t('parameters.masterData.identificationTypes.delete.confirmation.title')"
    :description="''"
    @closed="deleteIdentificationTypeModalOpen = false"
    @delete="deleteIdentificationType"
    />

    <ModalNewIdentificationType
    :is-open="newIdentificationTypeModalOpen"
    :is-edit="isIdentificationTypeEdit"
    :current-identification-type="currentIdentificationType"
    :saving="saving"
    @closed="modalIdentificationTypeClose"
    @submit-data="submitData"
    />
</template>
<script>
import DataTable from '@/components/DataTable.vue'
import ModalNewIdentificationType from '@/partials/parameters/master-data/identification-types/ModalNewIdentificationType.vue'
import ModalDelete from '@/components/ModalDelete.vue'

export default {
    components: {
        DataTable,
        ModalNewIdentificationType,
        ModalDelete
    },
    emits: ['reload'],
    props: {
        list: {
            type: Array,
            default: [],
        },
        toast: {
            type: Function,
            default: () => {}
        }
    },
    data: function () {
        return {
            newIdentificationTypeModalOpen: false,
            deleteIdentificationTypeModalOpen: false,
            isIdentificationTypeEdit: false,
            currentIdentificationType: {},
            saving: false,
            search: null,
            dataTable: {
                headers: [
                    {
                        id: 'nomenclature',
                        label: this.$t('parameters.masterData.common.table.headers.nomenclature'),
                        column: {
                            key: 'nomenclature',
                        }
                    },
                    {
                        id: 'name',
                        label: this.$t('parameters.masterData.common.table.headers.name'),
                        column: {
                            key: 'name',
                        }
                    },
                    {
                        id: 'created_at',
                        label: this.$t('parameters.masterData.common.table.headers.createdAt'),
                        column: {
                            funct: (value) => this.$filters.newDayjs(value).format('DD MMM YYYY - HH:mm'),
                            key: 'created_at',
                        }
                    },
                    {
                        id: 'status',
                        label: this.$t('parameters.masterData.common.table.headers.status'),
                        column: {
                            funct: (value) => value ? this.$t('status.active') : this.$t('status.inactive'),
                            key: 'status',
                        },
                        isSlot: true,
                    },
                    {
                        id: 'actions',
                        label: '',
                        column: {
                            key: '',
                            action: {
                                edit: {
                                    permission: 'parameters_master_data_edit_id_types'
                                },
                                delete: {
                                    permission: 'parameters_master_data_delete_id_types'
                                }
                            }
                        }
                    }
                ]
            }
        }
    },
    methods: {
        modalIdentificationTypeClose () {
            this.newIdentificationTypeModalOpen = false
        },
        openNewIdentificationType () {
            this.newIdentificationTypeModalOpen = true
            this.isIdentificationTypeEdit = false
        },
        openEditIdentificationType (event) {
            this.newIdentificationTypeModalOpen = true
            this.currentIdentificationType = event
            this.isIdentificationTypeEdit = true
        },
        openDeleteIdentificationType (event) {
            this.deleteIdentificationTypeModalOpen = true
            this.currentIdentificationType = event
        },
        submitData (event) {
            if (event.id) {
                this.update(event)
            } else {
                this.create(event)
            }
        },
        create (event) {
            if (!this.saving) {
                this.saving = true
                this.$store.dispatch('identificationtypes/create', event).then(response => {
                    if (response.data) {
                        this.$emit('reload');
                        this.newIdentificationTypeModalOpen = false
                        this.currentIdentificationType = {}
                        this.toast.success(this.$t('parameters.masterData.identificationTypes.successCreated'))
                    }
                    this.saving = false
                }).catch(error => {
                    this.toast.error(this.$methods.extractErrorResponse(error, this.$t))
                    this.saving = false
                })
            }
        },
        update (event) {
            const formData = JSON.parse(JSON.stringify(event))
            formData.id = this.$methods.encrypt(event.id);
            if (!this.saving) {
                this.saving = true
                this.$store.dispatch('identificationtypes/update', formData).then(response => {
                    if (response.data) {
                        this.$emit('reload');
                        this.newIdentificationTypeModalOpen = false
                        this.currentIdentificationType = {}
                        this.toast.success(this.$t('parameters.masterData.identificationTypes.successUpdated'))
                    }
                    this.saving = false
                }).catch(error => {
                    this.toast.error(this.$methods.extractErrorResponse(error, this.$t))
                    this.saving = false
                })
            }
        },
        deleteIdentificationType (event) {
            this.$store.dispatch('identificationtypes/delete', { id: this.$methods.encrypt(event.id) }).then(response => {
                if (response.data) {
                    this.$emit('reload');
                    this.currentIdentificationType = {}
                    this.deleteIdentificationTypeModalOpen = false
                    this.toast.success(this.$t('parameters.masterData.identificationTypes.successDeleted'))
                }
                this.saving = false
            }).catch(error => {
                this.toast.error(this.$methods.extractErrorResponse(error, this.$t))
                this.saving = false
            })
        }
    },
    computed: {
        getSearchableList () {
            return this.list.filter(
                item => Object.values(item).some(elem => {
                    const that = elem ? elem : ''
                    const search = this.search ? this.search : ''
                    return that.toString().toLowerCase().includes(search.toLowerCase())
                })
            )
        }
    }
}

</script>