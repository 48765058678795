<template>
    <ModalBlank id="danger-modal" :modal-open="isOpen" @close-modal="close">
        <div class="flex space-x-4 p-5">
            <!-- Content -->
            <div class="w-full">
                <!-- Modal header -->
                <div class="mb-2">
                    <div class="text-lg font-semibold text-slate-800">
                        {{ $t('document.modalDocumentRequestInfo.title') }}
                    </div>
                </div>
                <!-- Modal content -->
                <div
                v-if="document"
                class="mb-10 text-sm">
                    <div class="space-y-2">
                        <div class="flex justify-between bg-gray-100 rounded-xl p-2">
                            <div class="font-bold">
                                {{ $t('document.modalDocumentRequestInfo.requestTitle') }}
                            </div>
                            <div>
                                {{ document.title }}
                            </div>
                        </div>
                        <div class="flex justify-between bg-gray-100 rounded-xl p-2">
                            <div class="font-bold">
                                {{ $t('document.modalDocumentRequestInfo.description') }}
                            </div>
                            <div>
                                {{ document.description }}
                            </div>
                        </div>
                        <div class="flex justify-between bg-gray-100 rounded-xl p-2">
                            <div class="font-bold">
                                {{ $t('document.modalDocumentRequestInfo.requestedAt') }}
                            </div>
                            <div>
                                {{ formattedDate(document.created_at, 'dddd, DD MMMM YYYY') }}
                            </div>
                        </div>
                        <div
                        v-if="document.owner"
                        class="flex justify-between bg-gray-100 rounded-xl p-2">
                            <div class="font-bold">
                                {{ $t('document.modalDocumentRequestInfo.requestedBy') }}
                            </div>
                            <div>
                                {{ document.owner.first_name }} {{ document.owner.last_name }}
                            </div>
                        </div>
                        <div
                        v-if="objectHasProperty(document, 'request.providers')"
                        class="flex justify-between bg-gray-100 rounded-xl p-2">
                            <div class="font-bold">
                                {{ $t('document.modalDocumentRequestInfo.requestedTo') }}
                            </div>
                            <div class="flex flex-col items-end">
                                <div
                                v-for="(provider, index) in document.request.providers"
                                :key="index"
                                >
                                    {{ provider.user.first_name }} {{ provider.user.last_name }}
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-between bg-gray-100 rounded-xl p-2">
                            <div class="font-bold">
                                {{ $t('document.modalDocumentRequestInfo.dueDate') }}
                            </div>
                            <div>
                                {{ formattedDate(document.due_date, 'dddd, DD MMMM YYYY') }}
                            </div>
                        </div>
                        <div class="flex justify-between bg-gray-100 rounded-xl p-2">
                            <div class="font-bold">
                                {{ $t('document.modalDocumentRequestInfo.attemps') }}
                            </div>
                            <div>
                                {{ getAttemps }}
                            </div>
                        </div>
                        <div class="flex justify-between bg-gray-100 rounded-xl p-2">
                            <div class="font-bold text-start">
                                {{ $t('document.modalDocumentRequestInfo.statusWord') }}
                            </div>
                            <div class="flex flex-col items-end">
                                <span
                                :class="getClassStatus"
                                >
                                    {{ getStatus }}
                                </span>
                                <div
                                v-if="getDocumentRequestStatusIdentity(document) === 'rejected'"
                                class="flex flex-col italic items-end">
                                    <div class="text-end">
                                        {{ $t('document.modalDocumentRequestInfo.status.rejectedBy', { by: getNameUserWhoRejected }) }}
                                    </div>
                                    <div class="text-end">
                                        {{ $t('document.modalDocumentRequestInfo.status.rejectedReason', { reason: getProviderRejected?.reason }) }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div
                            class="flex flex-col w-full gap-3 mt-5"
                            v-if="objectHasProperty(document, 'complete_request.documents_provided')"
                            >
                                <div
                                v-for="(fileAttemp, index) in document.complete_request.documents_provided"
                                :class="[
                                    'rounded-lg pb-3',
                                    classDocument(fileAttemp)
                                ]"
                                :key="index"
                                >
                                    <FileItem
                                    :file="fileAttemp"
                                    :wrapper-class="'min-w-72 p-3 justify-start ' + classDocument(fileAttemp)"
                                    />
                                    <div class="px-3">
                                        <div class="flex items-center justify-between gap-2">
                                            <div class="flex gap-2 items-center text-xs">
                                                <div class="flex">
                                                    <ImageLoader
                                                    wrapper-class="shrink-0 rounded-full"
                                                    :src="fileAttemp.owner.avatar"
                                                    width="30"
                                                    height="30"
                                                    :alternate="fileAttemp.owner.first_name" />
                                                </div>
                                                <div
                                                class="flex flex-col font-medium">
                                                    <span>
                                                        {{ fileAttemp.owner.first_name }}
                                                        {{ fileAttemp.owner.last_name }}
                                                    </span>
                                                    <span>
                                                        {{ formattedDate(fileAttemp.created_at, 'dddd, DD MMMM YYYY') }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr class="bg-slate-300 h-0.5 my-2 -mx-3">
                                        <div
                                        class="my-2 flex flex-col gap-2"
                                        v-if="fileAttemp.request_result"
                                        >
                                            <span class="font-bold text-gray-500">
                                                {{ $t('document.modalDocumentRequestInfo.fileAttemp.result') }}
                                            </span>
                                            <div
                                            v-if="fileAttemp.request_result.is_accept == false"
                                            class="flex flex-col">
                                                <span class="italic text-red-500">
                                                    {{ $t('document.modalDocumentRequestInfo.fileAttemp.rectification.alert') }}
                                                </span>
                                                <span class="italic">
                                                    {{ $t(
                                                        'document.modalDocumentRequestInfo.fileAttemp.rectification.reason',
                                                        {
                                                            reason: fileAttemp.request_result.reason
                                                        }
                                                        )
                                                    }}
                                                </span>
                                            </div>
                                            <div
                                            v-if="fileAttemp.request_result.is_accept == true"
                                            class="flex flex-col">
                                                <span class="italic text-green-500">
                                                    {{ $t('document.modalDocumentRequestInfo.fileAttemp.approved.alert') }}
                                                </span>
                                            </div>
                                            <div
                                            v-if="objectHasProperty(fileAttemp, 'request_result.approver')"
                                            class="flex gap-2 items-center text-xs">
                                                <div class="flex">
                                                    <ImageLoader
                                                    wrapper-class="shrink-0 rounded-full"
                                                    :src="fileAttemp.request_result.approver.user.avatar"
                                                    width="30"
                                                    height="30"
                                                    :alternate="fileAttemp.request_result.approver.user.first_name" />
                                                </div>
                                                <div
                                                class="flex flex-col font-medium">
                                                    <span>
                                                        {{ fileAttemp.request_result.approver.user.first_name }}
                                                        {{ fileAttemp.request_result.approver.user.last_name }}
                                                    </span>
                                                    <span>
                                                        {{ formattedDate(fileAttemp.created_at, 'dddd, DD MMMM YYYY') }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="flex justify-between">
                            <div class="font-bold">
                                {{ $t('document.modalInfo.type') }}
                            </div>
                            <div>
                                <p>
                                    {{ document.type }}
                                    <span v-if="fileType !== ''" class="text-sm font-medium">
                                        (.{{ fileType }})
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div class="flex justify-between">
                            <div class="font-bold">{{ $t('document.modalInfo.size') }}</div>
                            <div>
                                {{ formatBytes(documentSize) }}
                            </div>
                        </div>
                        <div class="flex justify-between">
                            <div class="font-bold">
                                {{ $t('document.modalInfo.shareWith') }}
                            </div>
                            <div>
                                <span
                                v-for="(share, index) in document.shares"
                                :key="share.id">
                                    {{ share.user.name + ((index !== document.shares.length - 1) ? ', ' : '') }}
                                </span>
                            </div>
                            <div>
                                <span>-</span>
                            </div>
                        </div> -->
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="flex flex-wrap justify-end space-x-2">
                    <button
                    class="btn-lg cursor-pointer bg-gray-400 text-white hover:bg-gray-500"
                    @click.stop="close()">
                        {{ $t('modal.close') }}
                    </button>
                </div>
            </div>
        </div>
    </ModalBlank>
</template>
<script>
import ModalBlank from '@/components/ModalBlank.vue'
import globalMixin from '@/mixins/global.js'
import documentMixin from '@/mixins/document.js'
import FileItem from '@/components/FileItem.vue'
import ImageLoader from '@/components/image/ImageLoader.vue'

export default {
    name: 'ModalDocumentRequestInfoComponent',
    components: {
        ModalBlank,
        FileItem,
        ImageLoader,
    },
    mixins: [globalMixin, documentMixin],
    props: {
        document: {
            type: Object,
            required: true,
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['closed', 'delete'],
    computed: {
        // documentSize() {
        //     if (this.document.type !== 'Folder' && 'files' in this.document) {
        //         if (this.document.files[0] !== undefined)
        //             return this.document.files[0].size
        //         else return 0
        //     } else {
        //         return 0
        //     }
        // },
        // fileType() {
        //     if (this.document.type !== 'Folder' && 'files' in this.document) {
        //         if (this.document.files[0] !== undefined) {
        //             const fileName = this.document.files[0].file
        //             return fileName.substring(fileName.lastIndexOf('.') + 1)
        //         } else {
        //             return ''
        //         }
        //     } else {
        //         return ''
        //     }
        // },
        getAttemps () {
            let attemps = 0;
            if (
                this.objectHasProperty(this.document, 'complete_request.documents_provided')
            ) {
                attemps = this.document.complete_request.documents_provided.length;
            }
            return attemps;
        },
        getStatus () {
            const statusTarget = {
                pending: this.$t('document.requestedDocuments.status.pending'),
                on_review: this.$t('document.requestedDocuments.status.on_review'),
                on_rectification: this.$t('document.requestedDocuments.status.on_rectification'),
                rejected: this.$t('document.requestedDocuments.status.rejected'),
                approved: this.$t('document.requestedDocuments.status.approved'),
            }
            return statusTarget[this.getDocumentRequestStatusIdentity(this.document)];
        },
        getClassStatus () {
            const statusClass = {
                pending: 'text-yellow-500',
                on_review: 'text-sky-500',
                on_rectification: 'text-yellow-500',
                rejected: 'text-red-500',
                approved: 'text-green-500',
            }
            return statusClass[this.getDocumentRequestStatusIdentity(this.document)]
        },
        getProviderRejected () {
            if (
                this.objectHasProperty(this.document, 'request.providers')
            ) {
                return this.document.request.providers.find(provider => provider.is_accept == false)
            }
        },
        getNameUserWhoRejected () {
            return (this.getProviderRejected) ? this.getProviderRejected.user.first_name + ' ' + this.getProviderRejected.user.last_name : ''
        },
        classDocument () {
            return (fileAttemp) => {
                let classReturn = '';
                if (this.objectHasProperty(fileAttemp, 'request_result')) {
                    if (fileAttemp.request_result.is_accept) {
                        classReturn = 'bg-green-100';
                    } else {
                        classReturn = 'bg-red-100';
                    }
                } else {
                    classReturn = 'bg-sky-100';
                }
                return classReturn;
            }
        },
    },
    methods: {
        close() {
            this.$emit('closed')
        },
    },
}
</script>
