<template>
  <div>
    <button
      ref="trigger"
      class="rounded-full"
      :class="[
        dropdownOpen ? 'bg-slate-100 text-slate-500' : '',
        customClass !== ''
          ? customClass
          : 'bg-white text-slate-400 hover:text-slate-500',
      ]"
      aria-haspopup="true"
      :aria-expanded="dropdownOpen"
      @click.prevent="dropdownOpen = !dropdownOpen"
    >
      <svg
        v-if="showIconAdd"
        class="mr-2 mb-1 inline h-4 w-4 shrink-0 fill-current opacity-50"
        viewBox="0 0 16 16"
      >
        <path
          d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"
        />
      </svg>
      <span :class="{ 'sr-only': !showText }">{{ text }}</span>
      <svg
        v-if="showDots"
        class="inline h-8 w-8 fill-current"
        viewBox="0 0 32 32"
      >
        <circle cx="16" cy="16" r="2" />
        <circle cx="10" cy="16" r="2" />
        <circle cx="22" cy="16" r="2" />
      </svg>
    </button>
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0 -translate-y-2"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-out duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="dropdownOpen"
        class="min-w-36 absolute top-full z-10 mt-1 origin-top-right overflow-hidden rounded border border-slate-200 bg-white py-1.5 shadow-lg"
        :class="align === 'right' ? 'right-0' : 'left-0'"
      >
        <ul
          ref="dropdown"
          @focusin="dropdownOpen = true"
          @focusout="dropdownOpen = false"
        >
          <slot />
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'

export default {
  name: 'DropdownEditMenu',
  props: {
    align: {
      type: String,
      default: 'left',
    },
    customClass: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: 'Menu',
    },
    showDots: {
      type: Boolean,
      default: true,
    },
    showText: {
      type: Boolean,
      default: false,
    },
    showIconAdd: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const dropdownOpen = ref(false)
    const trigger = ref(null)
    const dropdown = ref(null)

    // close on click outside
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen.value ||
        dropdown.value.contains(target) ||
        trigger.value.contains(target)
      )
        return
      dropdownOpen.value = false
    }

    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen.value || keyCode !== 27) return
      dropdownOpen.value = false
    }

    onMounted(() => {
      document.addEventListener('click', clickHandler)
      document.addEventListener('keydown', keyHandler)
    })

    onUnmounted(() => {
      document.removeEventListener('click', clickHandler)
      document.removeEventListener('keydown', keyHandler)
    })

    return {
      dropdownOpen,
      trigger,
      dropdown,
    }
  },
}
</script>
