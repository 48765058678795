<template>
    <div
    class="flex cursor-pointer items-center space-x-2 rounded-lg bg-gray-100"
    :class="[wrapperClass !== '' ? wrapperClass : 'min-w-72 py-2 px-4']" @click="toFile">
        <div class="flex w-2/12">
            <FileTypeIcon :file-type="fileType" icon-class="inline w-8 cursor-pointer" />
        </div>
        <div class="flex w-7/12 flex-col">
            <p class="line-clamp-1 text-sm text-black">{{ file.name }}</p>
            <p class="text-xs text-slate-500">
                {{ formatBytes(fileSize) }}
            </p>
        </div>
        <div v-if="showDownload" class="flex w-3/12 items-center justify-end">
            <DownloadIcon />
        </div>
    </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'

import FileTypeIcon from '@/components/FileTypeIcon.vue'
import DownloadIcon from '@/assets/svg/download.svg'

export default {
    name: 'FileItemComponent',
    components: {
        FileTypeIcon,
        DownloadIcon,
    },
    mixins: [globalMixin],
    props: {
        file: {
            type: Object,
            required: true,
        },
        wrapperClass: {
            type: String,
            default: '',
        },
        isFilesObject: {
            type: Boolean,
            default: true,
        },
        showDownload: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        fileType() {
            if (this.isFilesObject) {
                if ('files' in this.file) {
                    if (this.file.files[0] !== undefined) {
                        const fileName = this.file.files[0].file
                        return fileName.substring(fileName.lastIndexOf('.') + 1)
                    } else {
                        return ''
                    }
                } else {
                    return ''
                }
            } else {
                if ('file' in this.file) {
                    const file = this.file.file
                    return file.substring(file.lastIndexOf('.') + 1)
                } else {
                    return ''
                }
            }
        },
        fileSize() {
            if (this.isFilesObject) {
                if ('files' in this.file) {
                    if (this.file.files[0] !== undefined) return this.file.files[0].size
                    else return 0
                } else {
                    return 0
                }
            } else {
                if ('size' in this.file) {
                    return this.file.size
                } else {
                    return 0
                }
            }
        },
    },
    methods: {
        toFile() {
            if (this.isFilesObject) {
                if (this.file && this.file.files && this.file.files.length) {
                    window.open(this.file.files[0].file, '_blank')
                }
            } else {
                if ('file' in this.file) window.open(this.file.file, '_blank')
            }
        },
    },
}
</script>
