import axios, { routePathAPI } from '@/plugins/axios.js'
import router from '@/router'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
    createNote({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            let params = {
                title: payload.title,
                content: payload.content,
                is_pinned: payload.is_pinned,
                case_id: payload.case_id,
            }

            axios
                .post(
                    `${routePathAPI()}/notebooks`,
                    params,
                    {
                        headers: {
                            'x-language-code': currentLanguageCode,
                        },
                    }
                )
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200 || response.status === 201) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateNote({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            let params = {
                title: payload.title,
                content: payload.content,
                is_pinned: payload.is_pinned,
                case_id: payload.case_id,
            }

            axios
                .put(
                    `${routePathAPI()}/notebooks/${payload.id}`,
                    params,
                    {
                        headers: {
                            'x-language-code': currentLanguageCode,
                        },
                    }
                )
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    deleteNote({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            axios
                .delete(`${routePathAPI()}/notebooks/${payload.id}`, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getNoteDetail({ commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang ||
            import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            axios
                .get(`${routePathAPI()}/notebook/detail/${payload.id}`, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    const responseData = response.data
                    if (
                        response.status === 200 &&
                        responseData.error === undefined
                    ) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAllNotes({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            let params = {}
            if (payload.page) params.page = payload.page
            if (payload.take) params.per_page = payload.take
            if (payload.order) params.order = 'created_at.' + payload.order
            if (payload.q) params.search = payload.q

            axios
                .get(`${routePathAPI()}/notebooks/byCase/${payload.case_id}`, {
                    params: params,
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    const responseData = response.data
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}

// mutations
const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
