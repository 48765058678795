export default {
    data: function () {
        return {
            timelineData: {}
        }
    },
    methods: {
        saveTimeline (event) {
            const timelineData = {}
            timelineData.type = event.type_id
            timelineData.title = event.title
            timelineData.subtitle = event.subtitle
            timelineData.content = event.content
            timelineData.extra = event.extra
            timelineData.date = event.date
            timelineData.case_id = this.$methods.decrypt(this.$route.params.slug)
            timelineData.attachment = event.attachment
            this.timelineData = timelineData
            this.createTimeline()
        },
        async createTimeline() {
            if (this.isModalLoading) return
            this.isModalLoading = true

            await this.store.dispatch('timeline/createTimeline', this.timelineData)
                .then((response) => {
                    this.isModalLoading = false
                    this.toast.success(this.$t('timeline.create.success'));
                    this.newActivityModalOpen = false
                    this.getAllTimeline()
                })
                .catch((error) => {
                    this.isModalLoading = false
                    this.newActivityModalOpen = true
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
    },
}
