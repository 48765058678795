<template>
    <div class="flex h-screen overflow-hidden">
        <!-- Sidebar -->
        <Sidebar
        :sidebar-open="sidebarOpen"
        :case-stage="activeCase.stage !== undefined ? activeCase.stage : $route.query.status"
        @close-sidebar="sidebarOpen = false"
        :get-the-case="false"
        />

        <!-- Content area -->
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <main>
                <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
                    <div class="flex w-full flex-col pb-4">
                        <h1 class="text-2xl font-bold text-black">
                            {{ $t('caseStage.title') }}
                        </h1>
                        <p>{{ $t('caseStage.description') }}</p>
                    </div>
                    <!-- Case Stage -->
                    <div v-if="!isLoading" class="flex h-full flex-col">
                        <div class="flex-1">
                            <!-- Progress bar -->
                            <div class="px-4 pt-12 pb-8">
                                <div class="w-full">
                                    <!-- on prepare -->
                                    <div v-if="activeCase.stage === 1" class="relative">
                                        <div class="absolute left-0 top-1/2 -mt-px h-0.5 w-full bg-slate-200"
                                            aria-hidden="true"></div>
                                        <ul class="relative flex w-full justify-between">
                                            <li>
                                                <div
                                                    class="mdxt-xs flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-sky-500 font-semibold text-white">
                                                    1
                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                    class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-2 border-slate-300 bg-slate-100 font-semibold text-slate-500">
                                                    2
                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                    class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-2 border-slate-300 bg-slate-100 font-semibold text-slate-500">
                                                    3
                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                    class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-2 border-slate-300 bg-slate-100 font-semibold text-slate-500">
                                                    4
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <!-- on going -->
                                    <div v-if="[2, 3, 4].includes(activeCase.stage)" class="relative">
                                        <div v-if="activeCase.stage === 2 && !activeCase.mediator_initiate">
                                            <div class="absolute top-1/2 w-full">
                                                <div class="flex w-full">
                                                    <div class="flex h-0.5 w-2/5 bg-sky-300" aria-hidden="true"></div>
                                                    <div class="flex h-0.5 w-3/4 bg-slate-200" aria-hidden="true"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                        v-if="(activeCase.stage === 3 || activeCase.mediator_initiate)
                                        &&
                                        !activeCase.arbitrator_initiate
                                        ">
                                            <div class="absolute top-1/2 w-full">
                                                <div class="flex w-full">
                                                    <div class="flex h-0.5 w-2/4 bg-green-300" aria-hidden="true"></div>
                                                    <div class="flex h-0.5 w-2/4 bg-yellow-500" aria-hidden="true"></div>
                                                    <div class="flex h-0.5 w-2/4 bg-slate-200" aria-hidden="true"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="activeCase.stage === 4 || activeCase.arbitrator_initiate">
                                            <div class="absolute top-1/2 w-full">
                                                <div class="flex w-full">
                                                    <div class="flex h-0.5 w-1/3 bg-green-300" aria-hidden="true"></div>
                                                    <div class="flex h-0.5 w-1/3 bg-green-300" aria-hidden="true"></div>
                                                    <div class="flex h-0.5 w-1/3 bg-yellow-400" aria-hidden="true"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <ul
                                        v-if="activeCase.stage === 2 && !activeCase.mediator_initiate"
                                        class="relative flex w-full justify-between">
                                            <li>
                                                <div
                                                    class="mdxt-xs flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white">
                                                    1
                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                    class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-sky-500 font-semibold text-white">
                                                    2
                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                    class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-2 border-slate-300 bg-slate-100 font-semibold text-slate-500">
                                                    3
                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                    class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-2 border-slate-300 bg-slate-100 font-semibold text-slate-500">
                                                    4
                                                </div>
                                            </li>
                                            <!-- <li>
                                                <div
                                                    class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-2 border-slate-300 bg-slate-100 font-semibold text-slate-500">
                                                    5
                                                </div>
                                            </li> -->
                                        </ul>
                                        <ul v-else-if="(activeCase.stage === 3 ||
                                            activeCase.mediator_initiate) &&
                                            !activeCase.arbitrator_initiate
                                            " class="relative flex w-full justify-between">
                                            <li>
                                                <div
                                                    class="mdxt-xs flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white">
                                                    1
                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                    class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white">
                                                    2
                                                </div>
                                            </li>
                                            <li>
                                                <div class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full font-semibold text-white"
                                                    :class="[
                                                        activeCase.mediator_initiate &&
                                                            activeCase.stage !== 3
                                                            ? 'bg-orange-500'
                                                            : 'bg-sky-500',
                                                    ]">
                                                    3
                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                    class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-2 border-slate-300 bg-slate-100 font-semibold text-slate-500">
                                                    4
                                                </div>
                                            </li>
                                            <!-- <li>
                                                <div
                                                    class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-2 border-slate-300 bg-slate-100 font-semibold text-slate-500">
                                                    5
                                                </div>
                                            </li> -->
                                        </ul>
                                        <ul v-if="activeCase.stage === 4 || activeCase.arbitrator_initiate" class="relative flex w-full justify-between">
                                            <li>
                                                <div
                                                    class="mdxt-xs flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white">
                                                    1
                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                    class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white">
                                                    2
                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                    class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white">
                                                    3
                                                </div>
                                            </li>
                                            <li>
                                                <div class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full font-semibold text-white"
                                                    :class="[
                                                        activeCase.arbitrator_initiate &&
                                                            activeCase.stage !== 4
                                                            ? 'bg-orange-500'
                                                            : 'bg-sky-500',
                                                    ]">
                                                    4
                                                </div>
                                            </li>
                                            <!-- <li>
                                                <div
                                                    class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-2 border-slate-300 bg-slate-100 font-semibold text-slate-500">
                                                    5
                                                </div>
                                            </li> -->
                                        </ul>
                                    </div>

                                    <!-- solved -->
                                    <div v-if="activeCase.stage === 5" class="relative">
                                        <div class="absolute left-0 top-1/2 -mt-px h-0.5 w-full bg-green-300"
                                            aria-hidden="true"></div>
                                        <ul class="relative flex w-full justify-between">
                                            <li>
                                                <div
                                                    class="mdxt-xs flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white">
                                                    1
                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                    class="mdxt-xs flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white">
                                                    2
                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                    class="mdxt-xs flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white">
                                                    3
                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                    class="mdxt-xs flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white">
                                                    4
                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                    class="mdxt-xs flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white">
                                                    5
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="relative px-4 py-8">
                            <!-- On Prepare Stage -->
                            <div
                            v-if="activeCase.stage === 1"
                            class="flex w-full items-center justify-center rounded-lg bg-white px-8 py-8 text-sm text-black">
                                <div class="flex w-full flex-col">
                                    <div class="mb-1 flex items-center justify-between">
                                        <p class="text-lg font-bold text-sky-500">
                                            {{ $t('caseStage.onPrepareStage') }}
                                        </p>
                                        <p class="space-x-1 text-xs" :class="[caseExpired ? 'text-red-400' : 'text-black']">
                                            <span v-if="startDeadlineTime !== null">
                                                {{ formatDateDay(startDeadlineTime) }}
                                            </span>
                                            <span>-</span>
                                            <span v-if="lastDeadlineTime !== null">
                                                {{ formatDateDay(lastDeadlineTime) }}
                                            </span>
                                        </p>
                                    </div>
                                    <p class="text-sm text-slate-500 flex items-center gap-1">
                                        <InfoIcon class="w-6 h-6 rotate-180"/>
                                        {{ $t('caseStage.waitingBankLawyer') }}
                                    </p>
                                </div>
                            </div>
                            <!-- End of On Prepare Stage -->

                            <!-- Negotiation Stage -->
                            <div
                            v-if="activeCase.stage === 2"
                            class="flex w-full items-center justify-center rounded-lg bg-white px-8 py-8 text-sm text-black"
                            >
                                <div class="flex w-full flex-col">
                                    <div v-if="!activeCase.mediator_initiate" class="flex flex-col">
                                        <div class="mb-1 flex items-center justify-between">
                                            <p class="text-lg font-bold text-sky-500">
                                                {{ $t('caseStage.negotiationStage') }}
                                            </p>
                                            <p class="space-x-1 text-xs"
                                                :class="[caseExpired ? 'text-red-400' : 'text-black']">
                                                <span v-if="startDeadlineTime !== null">
                                                    {{ formatDateDay(startDeadlineTime) }}
                                                </span>
                                                <span>-</span>
                                                <span v-if="lastDeadlineTime !== null">
                                                    {{ formatDateDay(lastDeadlineTime) }}
                                                </span>
                                            </p>
                                        </div>

                                        <p class="text-sm text-slate-500">
                                            {{ $t('caseStage.negotiationStageDesc') }}
                                        </p>
                                    </div>
                                    <div v-else class="mb-8 flex flex-col">
                                        <p class="text-lg font-bold text-orange-500">
                                            {{ $t('caseStage.initiateMediationStage') }}
                                        </p>
                                        <p class="text-sm text-slate-500">
                                            {{ $t('caseStage.initiateMediationStageDesc') }}
                                        </p>
                                    </div>

                                    <div
                                    v-if="activeCase && isAValidPartInCase($store.getters['auth/getCurrentUser'])"
                                    class="mt-8 flex flex-col">
                                        <p class="text-sm text-black">
                                            {{ $t('caseStage.actionToDo') }}
                                        </p>
                                        <div
                                        v-if="showResponseToTheClaim"
                                        v-permission="'cases_manage_case_stage_stages_negotiation_offers_response_claim'"
                                        class="mt-4 flex w-full items-center justify-center
                                        rounded-lg border bg-white px-6 py-6 text-sm text-black"
                                        >
                                            <div class="flex items-center justify-center">
                                                <ChatIcon />
                                            </div>
                                            <div class="ml-4 flex w-full flex-col">
                                                <div class="flex">
                                                    {{ $t('caseStage.responseToTheClaim') }}
                                                </div>
                                            </div>
                                            <div class="ml-4 flex items-center">
                                                <button
                                                class="btn rounded-lg bg-sky-500 py-2 px-6 text-white hover:bg-sky-600"
                                                @click.stop="responseToTheClaim">
                                                    {{ $t('button.open') }}
                                                </button>
                                            </div>
                                        </div>

                                        <div
                                        v-permission="'cases_manage_chats_join_case_chats_negotiation_created'"
                                        class="mt-4 flex w-full items-center justify-center rounded-lg border bg-white px-6 py-6 text-sm text-black">
                                            <div class="flex items-center justify-center">
                                                <ChatIcon />
                                            </div>
                                            <div class="ml-4 flex w-full flex-col">
                                                <div class="flex">
                                                    {{ $t('caseStage.participateNegotiationChat') }}
                                                </div>
                                            </div>
                                            <div
                                            class="ml-4 flex items-center"
                                            >
                                                <button
                                                class="btn rounded-lg bg-sky-500 py-2 px-6 text-white hover:bg-sky-600"
                                                @click="toChatDiscussion(activeChat)">
                                                    {{ $t('button.open') }}
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                    <div
                                    v-if="activeCase && isAValidPartInCase($store.getters['auth/getCurrentUser'])"
                                    class="mt-4"
                                    >
                                        <div class="flex justify-end">

                                            <button
                                            v-permission="'cases_manage_case_stage_stages_negotiation_req_mediation'"
                                            v-if="showInitiateMediator && !activeCase.mediator_initiate"
                                            class="btn rounded-lg py-3 px-6 text-white cursor-pointer bg-yellow-500 hover:bg-yellow-600"
                                            @click="initiateMediatorModalOpen = true">
                                                {{ $t('caseStage.inititateMediation') }}
                                            </button>

                                            <button
                                            v-permission="'cases_manage_case_stage_stages_negotiation_resolve'"
                                            v-if="showResolveCase && !activeCase.mediator_initiate"
                                            class="btn ml-4 rounded-lg py-3 px-6 text-white cursor-pointer bg-green-500 hover:bg-green-600"
                                            @click="resolveCaseModalOpen = true">
                                                {{ $t('button.resolveTheCase') }}
                                            </button>

                                        </div>
                                        <div
                                        v-if="caseExpired"
                                        v-permission="'cases_manage_case_stage_ext_time'"
                                        class="flex w-full justify-end">
                                            <button class="hover:text-sky-800" @click.stop="showExtentionModal">
                                                {{ $t('button.timerExtension') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End of Negotiation Stage -->

                            <!-- Mediation Stage -->
                            <div v-if="activeCase.stage === 3"
                                class="flex w-full items-center justify-center rounded-lg bg-white px-8 py-8 text-sm text-black">
                                <div class="flex w-full flex-col">

                                    <div v-if="!activeCase.arbitrator_initiate" class="flex flex-col">
                                        <div class="mb-1 flex items-center justify-between">
                                            <p class="text-lg font-bold text-sky-500">
                                                {{ $t('caseStage.mediationStage') }}
                                            </p>
                                            <p class="space-x-1 text-xs"
                                                :class="[caseExpired ? 'text-red-400' : 'text-black']">
                                                <span v-if="startDeadlineTime !== null">
                                                    {{ formatDateDay(startDeadlineTime) }}
                                                </span>
                                                <span>-</span>
                                                <span v-if="lastDeadlineTime !== null">
                                                    {{ formatDateDay(lastDeadlineTime) }}
                                                </span>
                                            </p>
                                        </div>
                                        <p
                                        v-if="activeCase.mediation_start_time === null"
                                        class="text-sm text-slate-500 flex items-center gap-1">
                                            <InfoIcon class="w-6 h-6 rotate-180"/>
                                            {{ $t('caseStage.waitingMediator') }}
                                        </p>

                                        <p
                                        v-else
                                        class="text-sm text-slate-500">
                                            {{ $t('caseStage.mediationStageDesc') }}
                                        </p>
                                    </div>

                                    <div
                                    v-if="activeChat.id && activeCase && isAValidPartInCase($store.getters['auth/getCurrentUser'])"
                                    class="mt-8 flex flex-col">
                                        <p class="text-sm text-black">
                                            {{ $t('caseStage.actionToDo') }}
                                        </p>

                                        <div
                                        v-if="showMakeFirstOfferMediation"
                                        v-permission="'cases_manage_case_stage_stages_mediation_offers_make_first'"
                                        class="mt-4 flex w-full items-center justify-center
                                        rounded-lg border bg-white px-6 py-6 text-sm text-black"
                                        >
                                            <div class="flex items-center justify-center">
                                                <ChatIcon />
                                            </div>
                                            <div class="ml-4 flex w-full flex-col">
                                                <div class="flex">
                                                    {{ $t('caseStage.mediator.makeFirstOffer') }}
                                                </div>
                                            </div>
                                            <div class="ml-4 flex items-center">
                                                <button
                                                class="btn rounded-lg bg-sky-500 py-2 px-6 text-white hover:bg-sky-600"
                                                @click.stop="makeFirstOfferMediator">
                                                    {{ $t('button.open') }}
                                                </button>
                                            </div>
                                        </div>

                                        <div
                                        v-if="activeChat.id"
                                        class="
                                        mt-4 flex w-full items-center justify-center rounded-lg
                                        border bg-white px-6 py-6 text-sm text-black
                                        "
                                        >
                                            <div class="flex items-center justify-center">
                                                <ChatIcon />
                                            </div>
                                            <div class="ml-4 flex w-full flex-col">
                                                <div class="flex">
                                                    {{ $t('caseStage.participateMediationChat') }}
                                                </div>
                                            </div>
                                            <div class="ml-4 flex items-center">
                                                <button
                                                @click="toChatDiscussion(activeChat)"
                                                class="btn rounded-lg bg-sky-500 py-2 px-6 text-white hover:bg-sky-600"
                                                >
                                                    {{ $t('button.open') }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <section
                                    v-if="
                                        (
                                            (
                                                !activeCase.arbitrator_initiate && showInitiateArbitrator
                                            )
                                            ||
                                            (showResolveCase)
                                        )
                                        &&
                                        activeCase
                                        &&
                                        isAValidPartInCase($store.getters['auth/getCurrentUser'])
                                    "
                                    class="mt-8 flex justify-end gap-3">
                                        <button
                                        v-permission="'cases_manage_case_stage_stages_mediation_req_arbitration'"
                                        v-if="!activeCase.arbitrator_initiate && showInitiateArbitrator"
                                        class="btn rounded-lg py-3 px-6 text-white bg-red-500 hover:bg-red-600"
                                        @click.stop="initiateArbitratorModalOpen = true">
                                            {{ $t('caseStage.inititateArbitration') }}
                                        </button>

                                        <button
                                        v-if="showResolveCase"
                                        v-permission="'cases_manage_case_stage_stages_mediation_resolve'"
                                        class="btn rounded-lg py-3 px-6 text-white bg-green-500 hover:bg-green-600"
                                        @click.stop="resolveCaseModalOpen = true">
                                            {{ $t('button.resolveTheCase') }}
                                        </button>
                                    </section>

                                    <div
                                    v-if="
                                        caseExpired
                                        &&
                                        activeCase
                                        &&
                                        isAValidPartInCase($store.getters['auth/getCurrentUser'])
                                    "
                                    class="mt-2 flex w-full justify-end">
                                        <button class="hover:text-sky-800" @click.stop="showExtentionModal">
                                            {{ $t('button.timerExtension') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- End of Mediation Stage -->

                            <!-- Arbitration Stage -->
                            <div
                            v-if="activeCase.stage === 4"
                            class="flex w-full items-center justify-center rounded-lg bg-white px-8 py-8 text-sm text-black">
                                <div class="flex w-full flex-col">
                                    <div class="mb-8 flex flex-col">
                                        <div class="flex items-center justify-between">
                                            <p class="text-lg font-bold text-sky-500">
                                                {{ $t('caseStage.arbitratorStage') }}
                                            </p>
                                            <p class="space-x-1 text-xs"
                                                :class="[caseExpired ? 'text-red-400' : 'text-black']">
                                                <span v-if="startDeadlineTime !== null">
                                                    {{ formatDateDay(startDeadlineTime) }}
                                                </span>
                                                <span>-</span>
                                                <span v-if="lastDeadlineTime !== null">
                                                    {{ formatDateDay(lastDeadlineTime) }}
                                                </span>
                                            </p>
                                        </div>

                                        <p
                                        v-if="activeCase.arbitration_start_time === null"
                                        class="text-sm text-slate-500 flex items-center gap-1">
                                            <InfoIcon class="w-6 h-6 rotate-180"/>
                                            {{ $t('caseStage.waitingArbitrator') }}
                                        </p>

                                        <p
                                        v-else
                                        class="text-sm text-slate-500">
                                            {{ $t('caseStage.arbitratorStageDesc') }}
                                        </p>
                                    </div>
                                    <div
                                    v-if="activeChat.id && activeCase && isAValidPartInCase($store.getters['auth/getCurrentUser'])"
                                    class="mb-8 flex flex-col">
                                        <p class="text-sm text-black">
                                        {{ $t('caseStage.actionToDo') }}
                                        </p>

                                        <div
                                        v-if="
                                            activeChat.id
                                            &&
                                            activeCase
                                            &&
                                            isAValidPartInCase($store.getters['auth/getCurrentUser'])
                                        "
                                        class="
                                        mt-4 flex w-full items-center justify-center rounded-lg
                                        border bg-white px-6 py-6 text-sm text-black
                                        "
                                        >
                                            <div class="flex items-center justify-center">
                                                <ChatIcon />
                                            </div>
                                            <div class="ml-4 flex w-full flex-col">
                                                <div class="flex">
                                                    {{ $t('caseStage.arbitration.participateInChat') }}
                                                </div>
                                            </div>
                                            <div class="ml-4 flex items-center">
                                                <button
                                                @click="toChatDiscussion(activeChat)"
                                                class="btn rounded-lg bg-sky-500 py-2 px-6 text-white hover:bg-sky-600"
                                                >
                                                    {{ $t('button.open') }}
                                                </button>
                                            </div>
                                        </div>

                                        <!-- <div
                                        class="mt-4 flex w-full cursor-pointer items-center justify-center rounded-lg bg-slate-50 px-6 py-6 text-sm text-black"
                                        >
                                            <div class="flex items-center justify-center">
                                                <DocumentIcon />
                                            </div>
                                            <div class="ml-4 flex w-full flex-col">
                                                <div class="flex">Review Document</div>
                                                <div class="flex text-sm text-slate-500">
                                                Due 12 July 2022
                                                </div>
                                            </div>
                                            <div class="ml-4 flex items-center">
                                                <button
                                                class="btn rounded-lg bg-sky-500 py-2 px-6 text-white hover:bg-sky-600"
                                                @click="
                                                    $router.push({
                                                    name: 'documents',
                                                    params: { slug: $route.params.slug },
                                                    query: {
                                                        name: $route.query.name,
                                                        status: $route.query.status,
                                                        sub_status: $route.query.sub_status,
                                                        lang: $route.query.lang,
                                                    },
                                                    })
                                                "
                                                >
                                                {{ $t('button.open') }}
                                                </button>
                                            </div>
                                        </div> -->

                                    </div>

                                    <div
                                    v-if="
                                        activeCase && isAValidPartInCase($store.getters['auth/getCurrentUser']) && activeCase.arbitration_start_time
                                    "
                                    v-permission="'cases_manage_case_stage_stages_arbitration_resolve'"
                                    class="flex flex-row justify-end">
                                        <button
                                        @click="generateDocument"
                                        class="btn ml-4 rounded-lg bg-gray-500 py-3 px-6 text-white hover:bg-gray-600 flex items-center justify-center gap-2"
                                        >
                                            <i class="fa-solid fa-file"></i>
                                            {{ $t('caseStage.arbitration.generateDocument') }}
                                        </button>
                                        <button
                                        class="btn ml-4 rounded-lg bg-green-500 py-3 px-6 text-white hover:bg-green-600"
                                        @click.stop="resolveCaseModalOpen = true">
                                            {{ $t('button.resolveTheCase') }}
                                        </button>
                                    </div>


                                    <div
                                    v-if="
                                        caseExpired
                                        &&
                                        activeCase
                                        &&
                                        isAValidPartInCase($store.getters['auth/getCurrentUser'])
                                        &&
                                        activeCase.arbitration_start_time
                                    "
                                    class="mt-2 flex w-full justify-end">
                                        <button class="hover:text-sky-800" @click.stop="showExtentionModal">
                                            {{ $t('button.timerExtension') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- End of Arbitration Stage -->

                            <!-- Solved Stage -->
                            <div v-if="activeCase.stage === 5"
                                class="flex w-full items-center justify-center rounded-lg bg-white px-8 py-8 text-sm text-black">
                                <div class="flex w-full flex-col">
                                    <div class="mb-1 flex text-lg font-bold text-green-500">
                                        {{ $t('caseStage.caseResolved') }}
                                    </div>
                                    <div class="mb-8 flex">
                                        {{ $t('caseStage.caseResolvedDesc') }}
                                    </div>
                                    <div class="mb-8 flex flex-col">
                                        <p class="text-sm text-black">
                                            {{ $t('caseStage.actionToDo') }}
                                        </p>
                                        <div
                                            class="mt-4 flex w-full items-center justify-center rounded-lg bg-slate-50 px-6 py-6 text-sm text-black">
                                            <div class="flex items-center justify-center">
                                                <DocumentIcon />
                                            </div>
                                            <div class="ml-4 flex w-full flex-col">
                                                <div class="flex">
                                                    {{ $t('caseStage.accessFinalDecision') }}
                                                </div>
                                            </div>
                                            <div class="ml-4 flex items-center">
                                                <button
                                                class="btn rounded-lg bg-sky-500 py-2 px-6 text-white hover:bg-sky-600"
                                                @click="
                                                        $router.push({
                                                            name: 'final-decision',
                                                            params: { slug: $route.params.slug },
                                                        })
                                                        ">
                                                    {{ $t('button.open') }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Review -->
                                    <div
                                    v-if="
                                        $store.getters['auth/getCurrentUser']
                                        &&
                                        activeCase
                                        &&
                                        isAValidPartInCase($store.getters['auth/getCurrentUser'])
                                    "
                                    class="flex flex-col">
                                        <p
                                        v-if="activeCase.case_parties.find(item => item.type === 'author').id === $store.getters['auth/getCurrentUser'].id"
                                        class="text-sm text-black">
                                            {{ $t('caseStage.feelFreeReview') }}
                                        </p>
                                        <div
                                        v-permission="'cases_manage_case_stage_stages_solved_review_view'"
                                        v-for="party in getPartiesToReview"
                                        :key="party.user.id"
                                        class="mt-4 flex w-full flex-col items-start rounded-lg bg-slate-50 px-6 py-6 text-sm text-black">
                                            <div
                                            class="flex w-full justify-between">
                                                <div class="flex items-center space-x-4">
                                                    <Tooltip
                                                    class="w-8"
                                                    bg="dark"
                                                    :custom-image="true"
                                                    :image="party.user.avatar ?? 'https://miro.medium.com/max/720/1*W35QUSvGpcLuxPo3SRTH4w.png'">
                                                        <div class="text-xs text-slate-200">
                                                            {{ party.user.name }}
                                                        </div>
                                                    </Tooltip>
                                                    <div class="flex">{{ party.user.name }}</div>
                                                    <div
                                                        class="ml-1 flex items-center rounded-lg bg-purple-100 px-1 text-xs text-purple-600">
                                                        {{ party.user.role.name }}
                                                    </div>
                                                </div>
                                                <div
                                                v-permission="'cases_manage_case_stage_stages_solved_review_leave'"
                                                class="flex items-center justify-end">
                                                    <button
                                                    v-if="
                                                        this.$store.getters['auth/getCurrentUser']
                                                        &&
                                                        !party.reviews.some(reviewItem => reviewItem.user_id == this.$store.getters['auth/getCurrentUser'].id)
                                                    "
                                                    class="btn rounded-lg bg-sky-500 py-2 px-6 text-white hover:bg-sky-600"
                                                    @click.stop="doReview(party)"
                                                    >
                                                        {{ $t('button.review') }}
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="flex flex-col gap-3 w-full">
                                                <div
                                                v-for="(reviewItem, index) in party.reviews"
                                                :key="index"
                                                class="flex flex-col gap-2 px-2 mt-5 bg-gray-200 rounded-lg p-4 w-full"
                                                >
                                                    <div class="flex items-center gap-3">
                                                        <ImageLoader
                                                        wrapper-class="shrink-0 rounded-full"
                                                        :src="reviewItem.user.avatar"
                                                        width="25"
                                                        height="25"
                                                        alternate="partyImage"
                                                        />
                                                        <span class="text-sm">
                                                            {{ reviewItem.user.name }}
                                                        </span>
                                                    </div>
                                                    <div class="flex flex-row">
                                                        <svg
                                                        v-for="index in 5"
                                                        :key="index"
                                                        :class="[
                                                            'h-4 w-4 shrink-0 fill-current',
                                                            (reviewItem.rating && reviewItem.rating >= index)
                                                            ? 'text-amber-500'
                                                            : 'text-slate-300'
                                                        ]"
                                                        >
                                                            <path
                                                            d="M8 0L6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934h-6L8 0z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <span>
                                                        {{ reviewItem.review }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End of Review -->
                                </div>
                            </div>
                            <!-- End of Solved Stage -->
                        </div>
                    </div>
                    <DualRingLoader v-else />
                </div>
            </main>
        </div>

        <ModalInitiateConfirm
        type="mediation"
        :is-open="initiateMediatorModalOpen"
        @close="initiateMediatorModalOpen = false"
        @confirm="caseInititate"
        />

        <ModalInitiateConfirm
        type="arbitration"
        :is-open="initiateArbitratorModalOpen"
        @close="initiateArbitratorModalOpen = false"
        @confirm="caseInititate"
        />

        <ModalBasic
        :backdrop="true"
        id="resolve-case-modal"
        :modal-open="resolveCaseModalOpen"
        :show-border-header="false"
        :title="$t('caseStage.resolveTheCase')"
        wrapper-class="max-w-4xl"
        @close-modal="resolveCaseModalOpen = false"
        >
            <!-- Modal content -->
            <Form
            ref="form"
            v-slot="{ errors }"
            :validation-schema="resolveCaseSchema"
            @submit="confirmResolveCaseModalOpen = true"
            >
                <div class="px-5 pt-2">
                    <div class="space-y-3">
                        <div
                        class="bg-gray-100 px-4 py-2 rounded flex flex-col gap-2"
                        v-if="currentAcceptedOffer"
                        >
                            <div class="flex justify-between items-center">
                                <span class="text-sky-500">
                                    {{ $t('caseStage.resolveCase.offer.title') }}
                                </span>
                                <div
                                v-if="currentAcceptedOffer.document_agreement_temp"
                                class="flex items-center">
                                    <div class="grow">
                                        <div class="items-center space-y-4 text-left text-base sm:mb-0">
                                            <a
                                            class="bg-yellow-200 py-2 flex items-center gap-2 px-4 rounded-md"
                                            :href="currentAcceptedOffer.document_agreement_temp"
                                            target="_blank"
                                            >
                                                <FileTypeIcon
                                                file-type="pdf"
                                                icon-class="inline h-8 cursor-pointer"
                                                />
                                                {{ $t('chat.discussion.offer.agreement.view') }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-2 gap-3">
                                <div>
                                    <h4 class="font-bold">
                                        {{ $t('finalDecision.solutionByOffer.offer.title') }}
                                    </h4>
                                    <span class="text-sm text-slate-500">
                                        {{ currentAcceptedOffer.title }}
                                    </span>
                                </div>
                                <div>
                                    <h4 class="font-bold">
                                        {{ $t('finalDecision.solutionByOffer.offer.content') }}
                                    </h4>
                                    <span class="text-sm text-slate-500">
                                        {{ currentAcceptedOffer.content }}
                                    </span>
                                </div>
                                <div>
                                    <h4 class="font-bold">
                                        {{ $t('finalDecision.solutionByOffer.offer.request') }}
                                    </h4>
                                    <span class="text-sm text-slate-500">
                                        {{ currentAcceptedOffer.request }}
                                    </span>
                                </div>
                                <div>
                                    <h4 class="font-bold">
                                        {{ $t('finalDecision.solutionByOffer.offer.amount') }}
                                    </h4>
                                    <span class="text-sm text-slate-500">
                                        {{ currentAcceptedOffer.amount }}
                                        {{ currentAcceptedOffer.currency.code }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label class="mb-1 block text-sm font-medium" for="result">
                                {{ $t('caseStage.finalDecisionResult') }}
                            </label>
                            <Field v-slot="{ field }" v-model="result" name="result">
                                <select id="result" v-bind="field" class="form-select w-full">
                                    <option v-for="caseResult in caseResults" :key="caseResult" :value="caseResult">
                                        {{ caseResult }}
                                    </option>
                                </select>
                            </Field>
                            <div class="flex w-full pb-4">
                                <span v-if="errors.result !== undefined" class="pl-3 text-sm text-red-500">
                                    {{ $t(errors.result) }}
                                </span>
                            </div>
                        </div>
                        <div>
                            <label class="mb-1 block text-sm font-medium" for="notes">
                                {{ $t('caseStage.disputeClosureNote') }}
                            </label>
                            <Field v-slot="{ field }" v-model="notes" name="notes">
                                <textarea id="notes" v-bind="field" class="form-textarea w-full px-2 py-1"
                                    rows="4"></textarea>
                            </Field>
                            <div class="flex w-full pb-4">
                                <span v-if="errors.notes !== undefined" class="pl-3 text-sm text-red-500">
                                    {{ $t(errors.notes) }}
                                </span>
                            </div>
                        </div>
                        <div v-if="activeCase.stage == 4">
                            <label class="mb-1 block text-sm font-medium" for="attachment">
                                {{ $t('modal.attachment') }}
                            </label>

                            <div class="flex w-full items-center justify-center">
                                <Field v-model="attachment" name="attachment">
                                    <label for="files"
                                        class="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-gray-300 bg-gray-50 hover:bg-gray-100">
                                        <div class="flex flex-col items-center justify-center py-2">
                                            <svg aria-hidden="true" class="mb-3 h-10 w-10 text-gray-400" fill="none"
                                                stroke="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12">
                                                </path>
                                            </svg>
                                            <p class="text-sm text-gray-500">
                                                {{ $t('forms.dragAndDrop') }}
                                            </p>
                                            <p class="text-sm text-gray-500">
                                                {{ $t('forms.or') }}
                                            </p>
                                            <p class="text-sm font-semibold text-sky-500">
                                                {{ $t('forms.browseFiles') }}
                                            </p>
                                        </div>
                                        <input id="files" type="file" accept="application/pdf" class="hidden" @change="fileInput" />
                                        <span v-if="attachment">
                                            {{ attachment.name }}
                                        </span>
                                    </label>
                                </Field>
                            </div>
                            <div v-if="errors.attachment !== undefined" class="flex w-full pb-4">
                                <span class="pl-3 text-sm text-red-500">
                                    {{ $t(errors.attachment) }}
                                </span>
                            </div>
                        </div>
                        <div v-if="activeCase.stage == 4">
                            <label class="mb-1 block text-sm font-medium" for="video">
                                {{ $t('modal.videoUpload') }}
                            </label>

                            <div class="flex w-full items-center justify-center">
                                <Field v-model="video" name="video">
                                    <label for="videos"
                                        class="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-gray-300 bg-gray-50 hover:bg-gray-100">
                                        <div class="flex flex-col items-center justify-center py-2">
                                            <svg aria-hidden="true" class="mb-3 h-10 w-10 text-gray-400" fill="none"
                                                stroke="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12">
                                                </path>
                                            </svg>
                                            <p class="text-sm text-gray-500">
                                                {{ $t('forms.dragAndDrop') }}
                                            </p>
                                            <p class="text-sm text-gray-500">
                                                {{ $t('forms.or') }}
                                            </p>
                                            <p class="text-sm font-semibold text-sky-500">
                                                {{ $t('forms.browseFiles') }}
                                            </p>
                                        </div>

                                        <input
                                        accept=".mp4, .avi, .mkv, .mov, .wmv, .flv, .mpg, .mpeg, .webm, .3gp, .ogg"
                                        id="videos"
                                        type="file"
                                        class="hidden"
                                        @change="fileInputVideo" />

                                        <span v-if="video">
                                            {{ video.name }}
                                        </span>
                                    </label>
                                </Field>
                            </div>
                            <div v-if="errors.video !== undefined" class="flex w-full pb-4">
                                <span class="pl-3 text-sm text-red-500">
                                    {{ $t(errors.video) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="px-5 py-4">
                    <button class="btn w-full rounded-lg bg-sky-500 py-3 px-6 text-white hover:bg-sky-600" type="submit">
                        {{ $t('caseStage.resolve') }}
                    </button>
                </div>
            </Form>
        </ModalBasic>

        <ModalBlank
        id="confirmResolveCaseModal"
        :modal-open="confirmResolveCaseModalOpen"
        @close-modal="confirmResolveCaseModalOpen = false"
        >
            <div class="flex flex-col p-5">
                <!-- Modal header -->
                <div class="mb-2 flex w-full justify-center">
                    <div class="text-xl font-semibold text-slate-800">
                        {{ $t('caseStage.areYouSureToResolve') }}
                    </div>
                </div>
                <!-- Modal content -->
                <div class="mb-10 flex w-full justify-center text-base">
                    <p class="text-center">
                        {{ $t('caseStage.resolveCaseDesc') }}
                    </p>
                </div>
                <!-- Modal footer -->
                <div class="flex flex-row justify-between space-x-2">
                    <button class="btn w-full rounded-lg bg-slate-100 py-3 px-6 text-slate-800 hover:bg-slate-200"
                        @click.stop="confirmResolveCaseModalOpen = false">
                        {{ $t('button.cancel') }}
                    </button>
                    <button
                    class="btn w-full rounded-lg bg-sky-500 py-3 px-6 text-white hover:bg-sky-600"
                    @click.stop="resolveCase"
                    :class="[ isLoading ? 'cursor-not-allowed' : 'cursor-pointer' ]">
                        <span v-if="isLoading">
                            {{ $t('general.loading') }}
                        </span>
                        <span v-else>
                            {{ $t('button.confirm') }}
                        </span>
                    </button>
                </div>
            </div>
        </ModalBlank>

        <ModalBasic
        id="review-modal"
        :modal-open="reviewModalOpen"
        :show-border-header="false"
        title="Do Review"
        @close-modal="reviewModalOpen = false"
        >
            <!-- Modal content -->
            <div class="px-5 pt-2">
                <div class="space-y-3">
                    <div
                    v-if="activeParty && activeParty.user"
                    class="bg-sky-100 p-3 rounded-lg text-sm"
                    >
                        <span class="font-bold">
                            {{ $t('caseStage.doReview.leavingReviewTo') }}:
                        </span>
                        <span>
                            {{ activeParty.user.name }}
                        </span>
                    </div>
                    <div>
                        <label class="mb-1 block text-sm font-medium" for="conclusion">
                            {{ $t('caseStage.giveRating') }}
                        </label>
                        <StarRating :value="reviewRating" :total-rating="5" @input="($event) => (reviewRating = $event)" />
                    </div>
                    <div class="mt-2">
                        <label class="mb-1 block text-sm font-medium" for="notes">
                            {{ $t('caseStage.review') }}
                        </label>
                        <textarea
                        id="review"
                        v-model="review"
                        class="form-textarea w-full px-2 py-1"
                        @input="writingReview"
                        rows="4"
                        required
                        :placeholder="$t('caseStage.writeReview')"></textarea>
                    </div>
                </div>
            </div>
            <!-- Modal footer -->
            <div class="px-5 py-4">
                <button class="btn w-full rounded-lg bg-sky-500 py-3 px-6 text-white hover:bg-sky-600"
                    @click.stop="saveReview">
                    {{ $t('caseStage.leaveReview') }}
                </button>
            </div>
        </ModalBasic>

        <ModalExtenstionTime
        :is-open="extenstionTimeModalOpen"
        :quota="extensionTimeQuota"
        :has-quota="hasExtensionQuota"
        @close="extenstionTimeModalOpen = false"
        @confirm="setExtenstionTime"
        />

        <ModalResponseToTheClaim
        v-permission="'cases_manage_case_stage_stages_negotiation_offers_response_claim'"
        :is-open="responseToTheClaimOpen"
        :chat-members="caseParties"
        :is-modal-loading="isLoading"
        @empty-member="emptyMember('selectOfferTo')"
        @closed="responseToTheClaimOpen = false"
        @save="createResponseToTheClaim"
        />

        <ModalMakeFirstOfferMediator
        v-permission="'cases_manage_case_stage_stages_mediation_offers_make_first'"
        :is-open="makeFirstOfferMediatorOpen"
        :chat-members="caseParties"
        :is-modal-loading="isLoading"
        @empty-member="emptyMember('selectOfferTo')"
        @closed="makeFirstOfferMediatorOpen = false"
        @save="createFirstOfferMediator"
        />

        <RightSidebar :sidebar-open="sidebarOpen" @close-sidebar="sidebarOpen = false" />
    </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { Form, Field } from 'vee-validate'
import globalMixin from '@/mixins/global.js'
import chatMixin from '@/mixins/chat.js'
import caseMixin from '@/mixins/case.js'

import Sidebar from '@/partials/Sidebar.vue'
import RightSidebar from '@/partials/RightSidebar.vue'
import ModalBlank from '@/components/ModalBlank.vue'
import ModalBasic from '@/components/ModalBasic.vue'
import ModalInitiateConfirm from '@/components/modal/case/ModalInitiateConfirm.vue'
import ModalExtenstionTime from '@/components/modal/case/ModalExtenstionTime.vue'
import ModalResponseToTheClaim from '@/components/modal/case/ModalResponseToTheClaim.vue'
import ModalMakeFirstOfferMediator from '@/components/modal/case/mediator/ModalMakeFirstOffer.vue'
import Tooltip from '@/components/Tooltip.vue'
import StarRating from '@/components/StarRating.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'
import FileTypeIcon from '@/components/FileTypeIcon.vue'

import ChecklistIcon from '@/assets/svg/checklist.svg'
import CalendarIcon from '@/assets/svg/calendar-icon.svg'
import DocumentIcon from '@/assets/svg/document-icon.svg'
import ChatIcon from '@/assets/svg/chat-icon.svg'
import InfoIcon from '@/assets/svg/info.svg'
import ImageLoader from '@/components/image/ImageLoader.vue'

export default {
    name: 'CaseStagePage',
    components: {
        Sidebar,
        RightSidebar,
        Form,
        Field,
        ModalBlank,
        ModalBasic,
        ModalInitiateConfirm,
        ModalExtenstionTime,
        ModalResponseToTheClaim,
        Tooltip,
        StarRating,
        DualRingLoader,
        ChecklistIcon,
        DocumentIcon,
        CalendarIcon,
        ChatIcon,
        InfoIcon,
        ModalMakeFirstOfferMediator,
        FileTypeIcon,
        ImageLoader
    },
    mixins: [globalMixin, chatMixin, caseMixin],
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()
        const sidebarOpen = ref(false)
        const activeCase = ref({ id: '' })

        const resolveCaseModalOpen = ref(false)
        const initiateMediatorModalOpen = ref(false)
        const initiateArbitratorModalOpen = ref(false)
        const confirmResolveCaseModalOpen = ref(false)
        const extenstionTimeModalOpen = ref(false)
        const responseToTheClaimOpen = ref(false)
        const makeFirstOfferMediatorOpen = ref(false)
        const reviewModalOpen = ref(false)
        const activeParty = ref(null)
        const review = ref('')
        const reviewRating = ref(null)
        const chatDataLoaded = ref(false)

        const caseResults = ref([
            t('caseStage.resolveCase.result.total'),
            t('caseStage.resolveCase.result.partial'),
        ])

        const result = ref(null)
        result.value = caseResults.value[0]
        const notes = ref(null)
        const attachment = ref(null)
        const video = ref(null)
        const activeUserRole = ref(null)
        const activeUserId = ref(null)

        return {
            t,
            n,
            store,
            toast,
            sidebarOpen,
            resolveCaseModalOpen,
            initiateMediatorModalOpen,
            initiateArbitratorModalOpen,
            confirmResolveCaseModalOpen,
            extenstionTimeModalOpen,
            responseToTheClaimOpen,
            reviewModalOpen,
            reviewRating,
            activeCase,
            result,
            notes,
            attachment,
            video,
            activeUserRole,
            activeUserId,
            caseResults,
            activeParty,
            review,
            chatDataLoaded,
            makeFirstOfferMediatorOpen,
        }
    },
    computed: {
        showInitiateMediator() {
            const lastOffers = this.chatData
                .filter(item => item.type === 'offer')
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

            return lastOffers && lastOffers.length && lastOffers[0].chat_offers.some(elem => elem.is_accept === false);
        },
        showInitiateArbitrator () {
            const lastOffers = this.chatData
                .filter(item => item.type === 'offer')
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

            return lastOffers && lastOffers.length && lastOffers[0].chat_offers.some(elem => elem.is_accept === false);
        },
        showResolveCase () {
            const lastOffers = this.chatData
                .filter(item => item.type === 'offer')
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            return lastOffers && lastOffers.length && lastOffers[0].chat_offers.every(elem => elem.is_accept);
        },
        showResponseToTheClaim () {
            return (this.chatData && this.chatDataLoaded) ? !this.chatData.some(item => item.type === 'offer') : false;
        },
        showMakeFirstOfferMediation () {
            return (this.chatData && this.chatDataLoaded) ? !this.chatData.some(item => item.type === 'offer') : false;
        },
        getPartiesToReview () {
            return this.activeCase.case_parties.filter(
                item => item.user.id != this.$store.getters['auth/getCurrentUser'].id
                &&
                this.$methods.oneUserHasPermissions(item.user, ['cases_manage_case_stage_stages_solved_review_be_qualified'])
            )
        },
        currentAcceptedOffer () {
            const lastOffers = this.chatData
                .filter(item => item.type === 'offer')
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

            if (
                lastOffers.length
                &&
                lastOffers[0].chat_offers.every(elem => elem.is_accept === true)
            ) {
                return lastOffers[0];
            } else {
                return null;
            }
        },
    },
    mounted() {
        this.getAllForCaseStage()
        if (this.$echo) {
            this.$echo.channel("CaseStage")
            .listen(".CaseStageEvent", (event) => {
                if (
                    event
                    &&
                    event.data
                    &&
                    (
                        event.data.emitted_by
                        ||
                        event.data.emitted_by == null
                    )
                    &&
                    event.data.case_id
                    &&
                    this.activeCase
                    &&
                    this.activeCase.id == event.data.case_id
                ) {
                    this.getAllForCaseStage();
                }
            });
        }
    },
    methods: {
        getAllForCaseStage () {
            this.getCaseDetail(true).then(() => {
                this.hasBankLawyer = this.activeCase.case_parties.some(item => item.type === 'bank_lawyer');
                if (this.activeCase && this.activeCase.stage >= 2) {
                    this.getCurrentStageChat().then(response => {
                        if (this.activeChat.id) {
                            this.getAllChat({ id: this.activeChat.id, type: 'offer' }).then(responseChat => {
                                this.chatDataLoaded = true;
                            })
                        }
                    })
                }
            });
        },
        toFinalDecision() {
            return this.$router.push({
                name: 'final-decision',
                params: {
                    slug: this.$route.params.slug,
                },
            })
        },
        async resolveCaseVideo(currentStage) {
            const stagesLabel = {
                '2': 'negotiation',
                '3': 'mediation',
                '4': 'arbitration',
            }
            this.isLoading = true
            await this.store
                .dispatch('cases/resolveCaseVideo', {
                    case_id: this.$methods.decrypt(this.$route.params.slug),
                    video: this.video,
                    stage: stagesLabel[currentStage],
                })
                .then((response) => {
                    this.isLoading = false
                    this.resolveCaseModalOpen = false
                    this.confirmResolveCaseModalOpen = false
                    this.toast.success(this.$t('caseStage.resolveCase.success'))
                    this.toFinalDecision()
                    return response
                })
                .catch((error) => {
                    this.isLoading = false

                    this.resolveCaseModalOpen = true
                    this.confirmResolveCaseModalOpen = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async resolveCase() {
            this.isLoading = true
            const currentStage = this.activeCase.stage
            await this.store
                .dispatch('cases/resolveCase', {
                    result: this.result,
                    notes: this.notes,
                    attachment: this.attachment,
                    case_id: this.activeCase.id,
                    offer_id: this.currentAcceptedOffer ? this.currentAcceptedOffer.id : null,
                })
                .then((response) => {
                    const responseData = response.data
                    if (
                        responseData.id
                        &&
                        responseData.stage
                        &&
                        responseData.stage === 5
                        &&
                        !this.video
                    ) {
                        this.toast.success(this.$t('caseStage.resolveCase.success'))
                        this.isLoading = false
                        this.resolveCaseModalOpen = false
                        this.confirmResolveCaseModalOpen = false
                        this.toFinalDecision();
                    } else {
                        this.resolveCaseVideo(currentStage);
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.resolveCaseModalOpen = true
                    this.confirmResolveCaseModalOpen = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async createCaseReview(params) {
            this.isLoading = true

            await this.store
                .dispatch('review/createCaseReview', params)
                .then((response) => {
                    this.isLoading = false
                    this.toast.success(this.$t('caseStage.doReview.success'))
                    this.reviewModalOpen = false
                    this.getCaseDetail(false)
                    this.reviewRating = null
                    this.review = ''
                })
                .catch((error) => {
                    this.isLoading = false
                    this.reviewModalOpen = true
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        saveReview() {
            if (this.reviewRating === null) {
                return this.emptyMember('pleaseInsertRating')
            }

            if (!this.isLoading) {
                const params = {
                    case_id: this.$route.params.slug,
                    party_id: this.activeParty.id,
                    rating: this.reviewRating,
                    review: this.review,
                }
                this.createCaseReview(params)
            }
        },
        doReview(party) {
            this.reviewModalOpen = true
            this.activeParty = party
        },
        fileInput(event) {
            this.attachment = event.target.files[0]
        },
        fileInputVideo(event) {
            this.video = event.target.files[0]
        },
        showExtentionModal() {
            this.extenstionTimeModalOpen = true
        },
        setExtenstionTime() {
            if (this.hasExtensionQuota && !this.isLoading) {
                this.createRequestTime()
            }
        },
        // checkResponseToTheClaim() {
        //     if (!this.checkEmptyObject(this.firstInitialChatRoom)) {
        //         this.toChatRoom(this.firstInitialChatRoom.id)
        //     } else {
        //         this.responseToTheClaim()
        //     }
        // },
        responseToTheClaim() {
            this.responseToTheClaimOpen = true
        },
        makeFirstOfferMediator () {
            this.makeFirstOfferMediatorOpen = true
        },
        createResponseToTheClaim(events) {
            const formData = events
            formData.chat_room_id = this.activeChat.id
            formData.type = 'offer'
            this.createChatAPI(events)
        },
        createFirstOfferMediator(events) {
            const formData = events
            formData.chat_room_id = this.activeChat.id
            formData.type = 'offer'
            this.createChatAPI(events)
        },
        writingReview (event) {
            if (event.target.value.length > 255) {
                event.target.value = event.target.value.substring(0, 255)
            }
        },
        generateDocument () {
            if (!this.isLoading) {
                this.isLoading = true;
                this.store.dispatch('cases/generateDocument', {
                    case_id: this.$route.params.slug
                }).then(response => {
                    const base64String = response.data;
                    const bytes = Uint8Array.from(atob(base64String), c => c.charCodeAt(0));
                    const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'arbitration document.docx';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                    this.isLoading = false;
                }).catch(e => {
                    console.log(e);
                })
            }
        }
    },
}
</script>
