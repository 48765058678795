import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
    getAllRoles({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            let params = {}
            if (payload.page) params.page = payload.page
            if (payload.take)              params.per_page = payload.take
            if (payload.order)             params.order  = 'created_at.' + payload.order
            if (payload.order_code)        params.order  = 'code.' + payload.order_code
            if (payload.order_name)        params.order  = 'name.' + payload.order_name
            if (payload.order_description) params.order  = 'description.' + payload.order_description
            if (payload.q)                 params.search = payload.q

            axios.get(`${routePathAPI()}/roles`, {
                params: params,
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            }).then(async (response) => {
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },
    createRole({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {
                code: payload.code ?? '',
                name: payload.name ?? '',
                description: payload.description ?? '',
            }

            axios.post(`${routePathAPI()}/roles`, params, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            }).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },
    deleteRole({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            axios.delete(`${routePathAPI()}/roles/${payload.role_id}`, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            }).then((response) => {
                const responseData = response.data
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: responseData,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: responseData,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    updateRole({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {
                code: payload.code ?? '',
                name: payload.name ?? '',
                description: payload.description ?? '',
                permissions: payload.permissions ?? [],
            }

            axios.put(`${routePathAPI()}/roles/${payload.role_id}`, params, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            }).then((response) => {
                const responseData = response.data
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: responseData,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: responseData,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    getRoleById ({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            axios.get(`${routePathAPI()}/roles/${payload.role_id}`, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            }).then(async (response) => {
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getBuiltPermissions ({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            axios.get(`${routePathAPI()}/roles/buildPermissions/${payload.role_id}`, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            }).then(async (response) => {
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },
}

// mutations
const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
