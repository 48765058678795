import { storeKey } from 'vuex'
import * as Yup from 'yup'

export default {
    inject: ['dayjs'],
    data: function () {
        return {
            appName: 'Redek',

            userAgent: '',
            // activeUser: {
            //     id: null,
            //     created: null,
            //     modified: null,
            //     full_name: null,
            //     email: null,
            //     phone_number: null,
            //     avatar: null,
            //     is_verified: true,
            //     institute: null,
            //     institute_slug: null,
            //     role: null,
            //     role_position: null,
            // },

            userMenus: {},
            userFeatures: {},

            maintenanceToPage: false,
            isAuthenticated: false,

            showModalMaintenance: false,
            showModalComingSoon: false,
            showModalLogout: false,

            multiSelectClasses: {
                container:
                    'relative mx-auto w-full h-11 flex items-center justify-end box-border cursor-pointer border border-gray-200 rounded-md bg-white text-sm leading-snug outline-none',
                containerDisabled: 'cursor-default bg-gray-100',
                containerOpen: 'rounded-b-none',
                containerOpenTop: 'rounded-t-none',
                containerActive: 'ring ring-primary ring-opacity-30',
                search: 'w-full absolute rounded-md inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-sm bg-white',
                placeholder:
                    'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400',
                clearIcon:
                    'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
                option: 'flex items-center justify-start box-border text-left cursor-pointer text-sm leading-snug py-2 px-3',
                optionPointed: 'text-gray-800 bg-gray-100',
                optionSelected: 'text-white bg-primary',
                optionDisabled: 'text-gray-300 cursor-not-allowed',
                noOptions: 'py-2 px-3 text-gray-600 bg-white text-left',
                noResults: 'py-2 px-3 text-gray-600 bg-white text-left',
            },
            isLoading: false,
            isModalLoading: false,
            roleCode: '',
            userId: '',

            uploadDocumentSchema: Yup.object().shape({
                attachment: Yup.mixed().required(
                    'validation.attachmentRequired'
                ),
            }),
            newActivitySchema: Yup.object().shape({
                title: Yup.string()
                    .typeError('validation.enterActivityTitle')
                    .required('validation.enterActivityTitle'),
            }),
            newNoteSchema: Yup.object().shape({
                // title: Yup.string()
                //   .typeError('validation.enterNoteTitle')
                //   .required('validation.enterNoteTitle'),
                // content: Yup.string()
                //   .typeError('validation.enterNoteContent')
                //   .required('validation.enterNoteContent'),
            }),
            resolveCaseSchema: Yup.object().shape({
                result: Yup.string()
                    .typeError('validation.selectResultSolveCase')
                    .required('validation.selectResultSolveCase'),
                notes: Yup.string()
                    .typeError('validation.enterNote')
                    .required('validation.enterNote'),
                // attachment: Yup.mixed().required(
                //     'validation.attachmentRequired'
                // ),
            }),
            newCaseSchema: Yup.object().shape({
                isCurrency: Yup.boolean(),
                hasRequest: Yup.boolean(),
                caseName: Yup.string()
                    .typeError('validation.enterCaseName')
                    .required('validation.enterCaseName')
                    .min(5, 'validation.minimumCaseName'),
                caseDescription: Yup.string()
                    .typeError('validation.enterCaseDescription')
                    .required('validation.enterCaseDescription')
                    .min(5, 'validation.minimumCaseName'),

                caseRequest: Yup.string().when('hasRequest', {
                    is: true,
                    then: Yup.string()
                        .typeError('validation.enterCaseRequest')
                        .required('validation.enterCaseRequest')
                        .min(5, 'validation.minimumCaseName'),
                }),
                caseAmount: Yup.string().when('isCurrency', {
                    is: true,
                    then: Yup.string()
                        .typeError('validation.enterCaseAmount')
                        .required('validation.enterCaseAmount')
                        .test(
                            'checkMinimumAmount',
                            'validation.minimumCaseAmount',
                            (value) => value >= 1
                        ),
                }),
            }),
            loginSchema: Yup.object().shape({
                email: Yup.string()
                    .email('validation.enterValidEmail')
                    .typeError('validation.enterValidEmail')
                    .required('validation.enterValidEmail'),
                password: Yup.string()
                    .typeError('validation.enterValidPassword')
                    .required('validation.enterValidPassword')
                    .min(6, 'validation.enterValidPassword'),
            }),
            registerSchema: Yup.object().shape({
                identification_type_id: Yup.string()
                    .typeError('validation.selectIdentificationType')
                    .required('validation.selectIdentificationType'),
                identification: Yup.string()
                    .typeError('validation.enterIdentification')
                    .required('validation.enterIdentification')
                    .test(
                        'identification-format',
                        'validation.identificationFormat',
                        function (value) {
                            if (value) {
                                const allowedValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                                return value.toString().split('').every(elem => allowedValues.some(item => item == elem))
                            }
                        }
                    )
                    .test(
                        'unique-identification',
                        'validation.identificationAlreadyInUse',
                        this.checkIdentificationExistence
                    ),
                firstName: Yup.string()
                    .typeError('validation.enterFirstName')
                    .required('validation.enterFirstName')
                    .min(3, 'validation.minimumFirstName')
                    .matches(/^[A-z ]+$/, 'validation.alphabetOnly'),
                secondName: Yup.string(),
                phoneNumber: Yup.string()
                    .test(
                        'number-format',
                        'validation.enterValidPhoneNumber',
                        function (value) {
                            if (value) {
                                const allowedValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                                return value.toString().split('').every(elem => allowedValues.some(item => item == elem))
                            }
                        }
                    )
                    .typeError('validation.enterPhoneNumber')
                    .required('validation.enterPhoneNumber')
                    .test(
                        'unique-phone',
                        'validation.phoneAlreadyInUse',
                        this.checkPhoneExistence
                    ),
                email: Yup.string()
                    .email('validation.enterValidEmail')
                    .typeError('validation.enterValidEmail')
                    .required('validation.enterValidEmail')
                    .test(
                        'unique-email',
                        'validation.emailAlreadyInUse',
                        this.checkEmailExistence
                    ),
                password: Yup.string()
                    .typeError('validation.enterValidPassword')
                    .required('validation.enterValidPassword')
                    .min(6, 'validation.enterValidPassword'),
                confirmPassword: Yup.string()
                    .required('validation.enterValidPassword')
                    .min(6, 'validation.enterValidPassword')
                    .test(
                        'passwords-match',
                        'validation.passwordMustMatch',
                        function (value) {
                            return this.parent.password === value
                        }
                    ),
            }),
            forgotPasswordSchema: Yup.object().shape({
                email: Yup.string()
                    .email('validation.enterValidEmail')
                    .typeError('validation.enterValidEmail')
                    .required('validation.enterValidEmail'),
            }),
            resetPasswordSchema: Yup.object().shape({
                password: Yup.string()
                    .typeError('validation.enterValidPassword')
                    .required('validation.enterValidPassword')
                    .min(6, 'validation.enterValidPassword'),
                confirmPassword: Yup.string()
                    .required('validation.enterValidPassword')
                    .min(6, 'validation.enterValidPassword')
                    .test(
                        'passwords-match',
                        'validation.passwordMustMatch',
                        function (value) {
                            return this.parent.password === value
                        }
                    ),
            }),
            adminRegisterSchema: Yup.object().shape({
                identification: Yup.string()
                    .typeError('validation.enterIdentification')
                    .required('validation.enterIdentification')
                    .test(
                        'identification-format',
                        'validation.identificationFormat',
                        function (value) {
                            if (value) {
                                const allowedValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                                return value.toString().split('').every(elem => allowedValues.some(item => item == elem))
                            }
                        }
                    )
                    .test(
                        'unique-identification',
                        'validation.identificationAlreadyInUse',
                        this.checkIdentificationExistence
                    ),
                identification_type_id: Yup.string()
                    .typeError('validation.enterIdentificationType')
                    .required('validation.enterIdentificationType'),
                role_id: Yup.string()
                        .typeError('validation.selectRole')
                        .required('validation.selectRole'),
                bank_id: Yup.string().when('role_id', {
                    is: 'e10a2333-5ba6-42ee-8960-481f1a469409',
                    then: Yup.string()
                        .typeError('validation.selectBank')
                        .required('validation.selectBank'),
                }),
                username: Yup.string()
                    .typeError('validation.enterUserName')
                    .required('validation.enterUserName')
                    .min(3, 'validation.minimumFirstName'),
                first_name: Yup.string()
                    .typeError('validation.enterFirstName')
                    .required('validation.enterFirstName')
                    .min(3, 'validation.minimumFirstName')
                    .matches(/^[A-z ]+$/, 'validation.alphabetOnly'),
                email: Yup.string()
                    .email('validation.enterValidEmail')
                    .typeError('validation.enterValidEmail')
                    .required('validation.enterValidEmail')
                    .test(
                        'unique-email',
                        'validation.emailAlreadyInUse',
                        this.checkEmailExistence
                    ),
                phone: Yup.string()
                    .typeError('validation.enterPhoneNumber')
                    .required('validation.enterPhoneNumber').test(
                        'unique-phone',
                        'validation.phoneAlreadyInUse',
                        this.checkPhoneExistence
                    ),
                password: Yup.string()
                    // .typeError('validation.enterValidPassword')
                    // .required('validation.enterValidPassword')
                    .min(6, 'validation.enterValidPassword'),
            }),
            rolesCreationSchema: Yup.object().shape({
                isUpdate: Yup.boolean(),
                code: Yup.string()
                    .typeError('validation.roles.creation.enterCode')
                    .required('validation.roles.creation.enterCode'),
                name: Yup.string()
                    .typeError('validation.roles.creation.enterName')
                    .required('validation.roles.creation.enterName'),
            }),

            permissionsCreationSchema: Yup.object().shape({
                isUpdate: Yup.boolean(),
                name: Yup.string()
                    .typeError('validation.permissions.creation.enterName')
                    .required('validation.permissions.creation.enterName'),
                // description: Yup.string()
                //     .typeError('validation.permissions.creation.enterDescription')
                //     .required('validation.permissions.creation.enterDescription'),
                // functionalities_id: Yup.string()
                //     .typeError('validation.permissions.creation.selectPattern')
                //     .required('validation.permissions.creation.selectPattern'),
                reference: Yup.string()
                    .typeError('validation.permissions.creation.enterReference')
                    .required('validation.permissions.creation.enterReference')
                    .test(
                        'unique-permission-reference',
                        'validation.permissions.creation.referenceAlreadyInUse',
                        this.checkPermissionReferenceExistence
                    ),
            }),

            orderOptions: [
                {
                    value: 'ASC',
                    label: 'options.ordering.dateAsc',
                },
                {
                    value: 'DESC',
                    label: 'options.ordering.dateDesc',
                },
            ],
        }
    },
    computed: {
        isLoggedIn: {
            get() {
                return this.$store.getters['auth/isUserLoggedIn']
            },
            set(userLogggedIn) {
                return this.$store.dispatch(
                    'auth/updateLoginState',
                    userLogggedIn
                )
            },
        },
        isUnderMaintenance: {
            get() {
                // return this.underMaintenance
                return this.$store.getters['home/underMaintenanceGetter']
            },
            set(maintenance) {
                return this.$store.dispatch(
                    'home/setUnderMaintenance',
                    maintenance
                )
            },
        },
        isConcludedMode() {
            return Boolean(this.$store.getters['cases/caseModeConcluded'])
        },
        loggedinUser() {
            return this.$store.getters['auth/activeUser']
        },
        currentTimeline() {
            return this.$store.getters['timeline/currentTimeline']
        },
        getParsedReferenceTranslate () {
            return (originalString) => {
                const newString = originalString.replace("$t.", "")
                const onlyReferenceString = newString.substring(
                    0,
                    (newString.indexOf('-') > 0 ? newString.indexOf('-') : newString.length)
                )
                let objectT = [onlyReferenceString];
                if (newString.indexOf('-') > 0) {
                    const paramsString = newString.substring(newString.indexOf('-') + 1);
                    const paramsObject = paramsString ? JSON.parse(paramsString) : null;
                    objectT[1] = paramsObject;
                }
                return objectT
            }
        },
        isAParsedReferenceTranslate () {
            return (string) => {
                return string.indexOf('$t.') > -1
            }
        },
        isAReferenceTranslate () {
            return (string) => {
                if (string.indexOf('translate_ref:') >= 0) {
                    return string.substring(string.indexOf(':') + 1, string.length);
                } else {
                    return null;
                }
            }
        },
        isAValidPartInCase () {
            return (user) => {
                let isValid = false;
                if (user && user.case_parties) {
                    isValid = user.case_parties.some(item => !item.was_reassigned)
                }
                return isValid
            }
        },
        now () {
            return this.dayjs()
        }
    },
    mounted() {
        this.userAgent = navigator.userAgent
    },
    methods: {
        checkIsAuthenticated() {
            const accessToken = localStorage.getItem('accessToken') || null

            if (accessToken !== null) {
                this.$router.push({
                    name: 'index',
                    params: { slug: this.$route.params.slug },
                    query: { lang: this.$route.query.lang },
                })
            }
        },
        checkMaintenance() {
            if (this.isUnderMaintenance) {
                if (this.maintenanceToPage)
                    this.$router.push({ name: 'maintenance' })
                else this.$emit('showModalMaintenance', this.isUnderMaintenance)
            }
        },
        isPhoneNumber: function (evt) {
            evt = evt ? evt : window.event
            var charCode = evt.which ? evt.which : evt.keyCode
            if (
                charCode != 43 &&
                charCode > 31 &&
                (charCode < 48 || charCode > 57)
            ) {
                evt.preventDefault()
            } else {
                return true
            }
        },
        mergeText(text1, text2) {
            return text1 + ' ' + text2
        },
        persentage(price, discount) {
            return Math.floor(((price - discount) / price) * 100)
        },
        imageUrlAlternate(event) {
            crossOriginIsolated.log('called imageUrlAlternate')
            event.target.src = '@/assets/image/icon.png'
        },
        imageUrlAlternateBox(event) {
            event.target.src = '@/assets/image/icon.png'
        },
        formatDateDay(
            date,
            options = {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
            }
        ) {
            return new Date(date).toLocaleDateString('en-US', options)
        },
        hoursMinutesFromTwoDates(date1, date2 = null, is_abs = true) {
            const localStartTime = new Date(date1)
            let localEndTime = new Date()
            if (date2 !== null) {
                localEndTime = new Date(date2)
            }

            let diffTime = localStartTime - localEndTime
            if (is_abs) diffTime = Math.abs(diffTime)

            let hours = diffTime / 36e5
            return Math.round(hours)
        },
        timeAgo(time) {
            return this.dayjs(time).fromNow()
        },
        extractDate(date, type = 'MMM') {
            return this.dayjs(date).format(type)
        },
        extractDateMultiple(dates) {
            let dateString = ''
            let isSameYear = false
            let isSameMonth = false
            if (dates.length > 1) {
                isSameYear = this.dayjs(dates[0]).isSame(dates[1], 'year')
                isSameMonth = this.dayjs(dates[0]).isSame(dates[1], 'month')

                if (!isSameYear) {
                    dateString = this.extractDate(dates[0], 'MMM')
                    dateString += ' ' + this.extractDate(dates[0], 'YYYY')
                    dateString += ' - ' + this.extractDate(dates[1], 'MMM')
                    dateString += ' ' + this.extractDate(dates[1], 'YYYY')
                } else if (isSameYear && !isSameMonth) {
                    dateString = this.extractDate(dates[0], 'MMMM')
                    dateString += ' - ' + this.extractDate(dates[1], 'MMMM')
                    dateString += ' ' + this.extractDate(dates[0], 'YYYY')
                }
            }
            if (dates.length === 1 || (isSameYear && isSameMonth)) {
                dateString = this.extractDate(dates[0], 'MMMM YYYY')
            }

            return dateString
        },
        diffDateFromNow(date, type = 'day') {
            const dateTo = this.dayjs(this.toDatetime(new Date(), false))
            const dateFrom = this.dayjs(date)

            return dateFrom.diff(dateTo, type)
        },
        estimatedTimeCase() {
            return this.dayjs().add(2, 'day')
        },
        dateStillActive(endDate, startToday = true, startDate = '') {
            if (startToday) startDate = new Date()
            endDate = new Date(endDate)
            return endDate > startDate
        },
        dateDiffDays(endDate, startToday = true, startDate = '') {
            if (startToday) startDate = new Date()
            endDate = new Date(endDate)
            const diffTime = endDate - startDate
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
            return diffDays
        },
        diffPrice(price, discountedPrice) {
            return discountedPrice - price
        },
        hoursMinutesFromDate(date) {
            const localDateTime = this.dayjs(date).format('HH:mm')
            return localDateTime
        },
        formattedDate(value, dateFormat = 'DD MMMM YYYY') {
            if (!value) return ''
            const formattedDate = this.dayjs(value.replace('Z', '')).format(dateFormat)
            return formattedDate
        },
        getDateRange(totalDay = 30) {
            const curr = new Date()
            const dateList = []
            dateList.push(
                new Date(curr.setDate(curr.getDate()))
                    .toISOString()
                    .slice(0, 10)
            )

            for (let i = 1; i <= totalDay; i++) {
                const first = curr.getDate() + 1
                const day = new Date(curr.setDate(first))
                    .toISOString()
                    .slice(0, 10)
                dateList.push(day)
            }
            return dateList
        },
        getRangeInteger(start, end, length = end - start) {
            return Array.from({ length }, (_, i) => start + i)
        },
        timeEstimatedFromDate(date, addMinutes = 5) {
            const startTime = this.dayjs(date).format('HH:mm')

            const endTime = this.dayjs(date)
                .add(addMinutes, 'm')
                .format('HH:mm')
            return `${startTime} - ${endTime}`
        },
        mergeTwoArrayByKey(arr1, arr2, key = 'id') {
            return arr1
                .filter(
                    (elem) =>
                        !arr2.find((subElem) => subElem[key] === elem[key])
                )
                .concat(arr2)
        },
        toDatetime(date, withTime = true) {
            const year = date.getFullYear()
            const month = ('0' + (date.getMonth() + 1)).slice(-2)
            const day = ('0' + date.getDate()).slice(-2)
            const hours = ('0' + date.getHours()).slice(-2)
            const minutes = ('0' + date.getMinutes()).slice(-2)
            const seconds = ('0' + date.getSeconds()).slice(-2)

            const dateOnly = year + '-' + month + '-' + day
            if (withTime) {
                return dateOnly + ' ' + hours + ':' + minutes + ':' + seconds
            }
            return dateOnly
        },
        arrayToString(array, key) {
            return array.map((item) => item[key]).join(', ')
        },
        onCopy(e) {
            const messageAlert = {
                show: true,
                message: e.text + ' ' + this.$t('copy_to_clipboard_success'),
                type: 'default',
                position: 'center',
            }
            this.$emit('showToast', messageAlert)
        },
        onError(e) {
            const messageAlert = {
                show: true,
                message: e.text + ' ' + this.$t('copy_to_clipboard_failed'),
                type: 'error',
                position: 'center',
            }
            this.$emit('showToast', messageAlert)
        },
        getIndexString(stringRaw, splitParam = ' ', index = 0) {
            const stringArr = stringRaw.split(splitParam)
            return stringArr[index]
        },
        extractErrorResponse(error) {
            if (error.response) {
                if (
                    error.response.data &&
                    error.response.data.message &&
                    error.response.data.message.detail
                ) {
                    if (Array.isArray(error.response.data.message.detail)) {
                        return error.response.data.message.detail[0]
                    } else {
                        return error.response.data.message.detail
                    }
                } else if (error.response.data && error.response.data.message) {
                    return error.response.data.message
                } else if (error.response.data && error.response.data.error) {
                    return error.response.data.error
                } else if ('statusText' in error.response) {
                    return error.response.statusText
                }
            } else {
                return this.$t('message.failed.ise')
            }
        },
        userCodes(withoutUser = false) {
            const userRoles = [
                'USER',
                'BANK_LAWYER',
                'REDEK_ADVOCATE',
                'REDEK_MEDIATOR',
                'REDEK_ARBITRATOR',
            ]
            if (withoutUser) {
                userRoles.shift()
                userRoles.shift()
            }
            return userRoles
        },
        async checkLoggedIn() {
            const loggedinUser = await this.$store.dispatch(
                'auth/getActiveUser'
            )
            if (loggedinUser === null) {
                this.$router.push({
                    name: 'login',
                    params: { slug: this.$route.params.slug },
                    query: { lang: this.$route.query.lang },
                })
            }
        },
        async checkUserAccess(
            type = 'USER',
            checkUser = false,
            isRegister = false,
            userOnly = false,
            newCase = false
        ) {
            const loggedinUser = await this.$store.dispatch(
                'auth/getActiveUser'
            )
            if (loggedinUser) {
                let userCodes = this.userCodes()
                if (userOnly) {
                    userCodes = ['USER']
                }
                const roleCode = loggedinUser.role.code.toUpperCase()
                this.roleCode = roleCode
                this.userId = loggedinUser.id
                if (
                    newCase === false &&
                    userCodes.includes(roleCode) &&
                    !['REDEK_ADMIN', 'REDEK_CS'].includes(roleCode)
                ) {
                    this.$router.push({
                        name: 'index',
                        params: { slug: this.$route.params.slug },
                        query: { lang: this.$route.query.lang },
                    })
                }

                // if (
                //   userCodes.includes(roleCode) &&
                //   (!userCodes.includes(type) || checkUser)
                // ) {
                //   this.$router.push({
                //     name: 'index',
                //     params: { slug: this.$route.params.slug },
                //     query: { lang: this.$route.query.lang },
                //   })
                // }

                if (
                    roleCode === 'REDEK_ADMIN' &&
                    (type !== 'REDEK_ADMIN' || checkUser)
                ) {
                    this.$router.push({
                        name: 'admin-dashboard',
                        params: { slug: this.$route.params.slug },
                        query: { lang: this.$route.query.lang },
                    })
                }

                if (
                    roleCode === 'REDEK_CS' &&
                    (type !== 'REDEK_CS' || checkUser)
                ) {
                    this.$router.push({
                        name: 'all-cs-chat',
                        params: { slug: this.$route.params.slug },
                        query: { lang: this.$route.query.lang },
                    })
                }
            } else {
                if (isRegister) {
                    this.$router.push({
                        name: 'register',
                        params: { slug: this.$route.params.slug },
                        query: { lang: this.$route.query.lang },
                    })
                } else {
                    this.$router.push({
                        name: 'login',
                        params: { slug: this.$route.params.slug },
                        query: { lang: this.$route.query.lang },
                    })
                }
            }
        },
        stripAndLower(str) {
            return str.toLowerCase().replace(/\s/g, '')
        },
        caseStatusFilter(status) {
            switch (status) {
                case 'onprepare':
                    return 'On prepare'
                case 'ongoing':
                    return 'Ongoing'
                case 'solved':
                    return 'Solved'
                default:
                    return ''
            }
        },
        toCaseDetail(item, isAdmin = false) {
            const caseId = this.$methods.encrypt(item.id)
            if (this.$methods.hasPermissions(['cases_edit'])) {
                this.$router.push({
                    name: 'case-detail-admin',
                    params: { slug: caseId },
                })
            } else if (this.$methods.hasPermissions(['cases_manage'])) {
                if (this.$methods.hasPermissions(['cases_manage_access_event_reassigned'])) {
                    this.$router.push({
                        name: 'dashboard',
                        params: { slug: caseId },
                    })
                } else {
                    this.$store.dispatch('cases/isAValidCaseParty', { case_id: caseId }).then(response => {
                        if (response.data) {
                            this.$router.push({
                                name: 'dashboard',
                                params: { slug: caseId },
                            })
                        } else {
                            this.toast.error(this.$t('case.cannotEnabledToAccess'));
                        }
                    })
                }
            }
        },
        setStatusByName(name) {
            switch (name.toUpperCase()) {
                case 'TOTAL':
                    return 'all'
                case 'ON PREPARE':
                    return 'pending'
                case 'ONGOING':
                    return 'ongoing'
                case 'SOLVED':
                    return 'settled'
                default:
                    return 'all'
            }
        },
        checkEmptyObject(obj) {
            // because Object.keys(new Date()).length === 0;
            // we have to do some additional check
            return (
                obj && // 👈 null and undefined check
                Object.keys(obj).length === 0 &&
                Object.getPrototypeOf(obj) === Object.prototype
            )
        },
        getPercentage(val, sumVal) {
            let percentage = 0
            if (isNaN(val) && isNaN(sumVal)) {
                return percentage
            } else {
                percentage = Math.round((val / sumVal) * 100)
                if (isNaN(percentage)) return 0
                else return percentage
            }
        },
        sumObject(obj) {
            var sum = 0
            for (var el in obj) {
                if (obj.hasOwnProperty(el)) {
                    sum += parseFloat(obj[el])
                }
            }
            return sum
        },
        emptyMember(type = 'selectMember') {
            if (
                typeof this.toast !== 'undefined' &&
                typeof this.t !== 'undefined'
            ) {
                this.toast.error(this.t(`validation.${type}`))
            }
        },
        extractFilename(dispotition, defaultName) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            const matches = filenameRegex.exec(dispotition)
            let filename = defaultName
            if (matches && matches[1]) {
                filename = matches[1].replace(/['"]/g, '')
            }
            return filename
        },
        formatBytes(bytes, decimals = 2) {
            if (!+bytes) return '0 Bytes'

            const k = 1024
            const dm = decimals < 0 ? 0 : decimals
            const sizes = [
                'Bytes',
                'KB',
                'MB',
                'GB',
                'TB',
                'PB',
                'EB',
                'ZB',
                'YB',
            ]

            const i = Math.floor(Math.log(bytes) / Math.log(k))

            return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${
                sizes[i]
            }`
        },
        getName(item, type = 'first', length = 15) {
            if (
                item &&
                type === 'first' &&
                'first_name' in item &&
                'last_name' in item
            ) {
                const name = item.first_name + ' ' + item.last_name
                let dot = ''
                if (name.length > length) {
                    dot = '...'
                }
                return name.slice(0, length) + dot
            } else if (item && type === 'name' && 'name' in item) {
                const name = item.name
                let dot = ''
                if (name.length > length) {
                    dot = '...'
                }
                return name.slice(0, length) + dot
            } else {
                return ''
            }
        },
        snakeToCamel(str) {
            return str
                .toLowerCase()
                .replace(/([-_][a-z])/g, (group) =>
                    group.toUpperCase().replace('-', '').replace('_', '')
                )
        },
        replaceStr(str, rep, change) {
            return str.replace(rep, change)
        },
        inspectCase() {
            this.$store.dispatch('auth/setAdminMode', 'inspector')
            this.$router.push({
                name: 'dashboard',
                params: {
                    slug: this.$route.params.slug,
                },
                query: {
                    ...this.$route.query,
                },
            })
        },
        endInspectCase() {
            this.$store.dispatch('auth/setAdminMode', 'admin')
            const params = {}
            if (this.$route.params.slug !== undefined) {
                params.slug = this.$route.params.slug
            }
            this.$router.push({
                name: 'case-detail-admin',
                params: params,
                query: {
                    ...this.$route.query,
                },
            })
        },
        toMediationAgreement() {
            return this.$router.push({
                name: 'mediation-agreement',
                params: {
                    slug: this.$route.params.slug,
                },
                query: {
                    ...this.$route.query,
                },
            })
        },
        toCaseStage() {
            this.$router.push({
                name: 'case-stage',
                params: {
                    slug: this.$route.params.slug,
                },
                query: {
                    ...this.$route.query,
                },
            })
        },
        async checkIdentificationExistence(identification) {
            let validation = false
            if (identification) {
                validation = await this.$store.dispatch(
                    'auth/checkIdentificationExistence',
                    {
                        identification: identification,
                        current:
                            this.currentUser && this.currentUser.id
                                ? this.currentUser.id
                                : null,
                    }
                )
            }
            return validation
        },
        async checkEmailExistence(email) {
            let validation = true
            if (email) {
                validation = await this.$store.dispatch(
                    'auth/checkEmailExistence',
                    {
                        email: email,
                        current:
                            this.currentUser && this.currentUser.id
                                ? this.currentUser.id
                                : null,
                    }
                )
            }
            return validation
        },
        async checkPhoneExistence(phone) {
            let validation = true
            if (phone) {
                validation = await this.$store.dispatch(
                    'auth/checkPhoneExistence',
                    {
                        phone: phone,
                        current:
                            this.currentUser && this.currentUser.id
                                ? this.currentUser.id
                                : null,
                    }
                )
            }
            return validation
        },
        async checkPermissionReferenceExistence(reference) {
            let validation = true
            if (reference) {
                validation = await this.$store.dispatch(
                    'permissions/checkReferenceExistence',
                    {
                        reference: reference,
                        current:
                            this.currentPermission && this.currentPermission.id
                                ? this.currentPermission.id
                                : null,
                    }
                )
            }
            return validation
        },
        getStatusByBool(status) {
            let statusText = this.$t('status.inactive')
            if (status) statusText = this.$t('status.active')
            return statusText
        },
        verifyRedirectWhenLogin (response) {
            const permissions = localStorage.getItem('redek.permissions')
                ? JSON.parse(atob(localStorage.getItem('redek.permissions')))
                : null;
            if (permissions) {
                if (permissions.some(elem => elem.functionality.reference === 'dashboard_admin')) {

                    this.$router.push({ name: 'admin-dashboard' });
                    this.toast.success(response.message ?? this.$t('login.loginSuccessfully'));

                } else if (permissions.some(elem => elem.functionality.reference === 'cases_list_status_all')) {

                    this.$router.push({ name: 'index' });
                    this.toast.success(response.message ?? this.$t('login.loginSuccessfully'));

                } else if (permissions.some(elem => elem.functionality.reference === 'cases_list_status_pending')) {

                    this.$router.push({ name: 'pending-cases' });
                    this.toast.success(response.message ?? this.$t('login.loginSuccessfully'));

                } else if (permissions.some(elem => elem.functionality.reference === 'cases_list_status_ongoing')) {

                    this.$router.push({ name: 'ongoing-cases' });
                    this.toast.success(response.message ?? this.$t('login.loginSuccessfully'));

                } else if (permissions.some(elem => elem.functionality.reference === 'cases_list_status_solved')) {

                    this.$router.push({ name: 'settled-cases' });
                    this.toast.success(response.message ?? this.$t('login.loginSuccessfully'));

                } else {

                    this.toast.error(this.$t('login.doesntHaveRequiredPermissions'));

                }
            }
        },
        objectHasProperty (object, keyPath) {
            let exists = true;
            let aux = object;
            keyPath.split('.').map(key => {
                if (!aux[key]) {
                    exists = false;
                } else {
                    aux = aux[key];
                }
            });
            return exists;
        },
        newDayJs (date) {
            return this.dayjs(date);
        },
        nowDayJs () {
            return this.dayjs();
        }
    },
}
