<template>
  <div class="mt-4">
    <div class="mb-3 text-xs font-semibold uppercase text-slate-400">
      Direct messages
    </div>
    <ul class="mb-6">
      <li class="-mx-2">
        <button
          class="flex w-full items-center justify-between rounded bg-sky-100 p-2"
          @click.stop="$emit('close-msgsidebar')"
        >
          <div class="flex items-center truncate">
            <img
              class="mr-2 h-8 w-8 rounded-full"
              src="../../assets/images/user-32-01.jpg"
              width="32"
              height="32"
              alt="User 01"
            />
            <div class="truncate">
              <span class="text-sm font-medium text-slate-800"
                >Dominik Lamakani</span
              >
            </div>
          </div>
          <div class="ml-2 flex items-center">
            <div
              class="inline-flex rounded-full bg-sky-400 px-2 text-center text-xs font-medium leading-5 text-white"
            >
              2
            </div>
          </div>
        </button>
      </li>
      <li class="-mx-2">
        <button
          class="flex w-full items-center justify-between rounded p-2"
          @click.stop="$emit('close-msgsidebar')"
        >
          <div class="flex items-center truncate">
            <img
              class="mr-2 h-8 w-8 rounded-full"
              src="../../assets/images/user-32-02.jpg"
              width="32"
              height="32"
              alt="User 02"
            />
            <div class="truncate">
              <span class="text-sm font-medium text-slate-800"
                >Tisha Yanchev</span
              >
            </div>
          </div>
          <div class="ml-2 flex items-center">
            <div
              class="inline-flex rounded-full bg-sky-400 px-2 text-center text-xs font-medium leading-5 text-white"
            >
              4
            </div>
          </div>
        </button>
      </li>
      <li class="-mx-2">
        <button
          class="flex w-full items-center justify-between rounded p-2"
          @click.stop="$emit('close-msgsidebar')"
        >
          <div class="flex items-center truncate">
            <img
              class="mr-2 h-8 w-8 rounded-full"
              src="../../assets/images/user-32-03.jpg"
              width="32"
              height="32"
              alt="User 03"
            />
            <div class="truncate">
              <span class="text-sm font-medium text-slate-800"
                >Jerzy Wierzy</span
              >
            </div>
          </div>
          <div class="ml-2 flex items-center">
            <img
              class="h-5 w-5 shrink-0 rounded-full"
              src="../../assets/images/user-32-03.jpg"
              width="20"
              height="20"
              alt="User 03"
            />
          </div>
        </button>
      </li>
      <li class="-mx-2">
        <button
          class="flex w-full items-center justify-between rounded p-2"
          @click.stop="$emit('close-msgsidebar')"
        >
          <div class="flex items-center truncate">
            <img
              class="mr-2 h-8 w-8 rounded-full"
              src="../../assets/images/user-32-04.jpg"
              width="32"
              height="32"
              alt="User 04"
            />
            <div class="truncate">
              <span class="text-sm font-medium text-slate-800"
                >Adrian Przetocki</span
              >
            </div>
          </div>
          <div class="ml-2 flex items-center">
            <svg
              class="h-3 w-3 shrink-0 fill-current text-slate-400"
              viewBox="0 0 12 12"
            >
              <path
                d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"
              />
            </svg>
          </div>
        </button>
      </li>
      <li class="-mx-2">
        <button
          class="flex w-full items-center justify-between rounded p-2"
          @click.stop="$emit('close-msgsidebar')"
        >
          <div class="flex items-center truncate">
            <img
              class="mr-2 h-8 w-8 rounded-full"
              src="../../assets/images/user-32-05.jpg"
              width="32"
              height="32"
              alt="User 05"
            />
            <div class="truncate">
              <span class="text-sm font-medium text-slate-800"
                >Simona Lürwer</span
              >
            </div>
          </div>
          <div class="ml-2 flex items-center">
            <svg
              class="h-3 w-3 shrink-0 fill-current text-slate-400"
              viewBox="0 0 12 12"
            >
              <path
                d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"
              />
            </svg>
          </div>
        </button>
      </li>
      <li class="-mx-2">
        <button
          class="flex w-full items-center justify-between rounded p-2"
          @click.stop="$emit('close-msgsidebar')"
        >
          <div class="flex items-center truncate">
            <img
              class="mr-2 h-8 w-8 rounded-full"
              src="../../assets/images/user-32-06.jpg"
              width="32"
              height="32"
              alt="User 06"
            />
            <div class="truncate">
              <span class="text-sm font-medium text-slate-800"
                >Mary Roszczewski</span
              >
            </div>
          </div>
          <div class="ml-2 flex items-center">
            <svg
              class="h-3 w-3 shrink-0 fill-current text-slate-400"
              viewBox="0 0 12 12"
            >
              <path
                d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"
              />
            </svg>
          </div>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'DirectMessages',
  props: ['msgSidebarOpen'],
}
</script>
