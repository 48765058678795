import axios from 'axios'
import CryptoJS from "crypto-js";
import router from '../router';

// const tokenType = localStorage.getItem('redek.tokenType') || 'Bearer'
// const accessToken = localStorage.getItem('redek.accessToken') || null
// const refreshToken = localStorage.getItem('redek.refreshToken') || null

// if (accessToken !== null) {
// axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
// } else {
// delete axios.defaults.headers.common['Authorization']
// }

if (
    import.meta.env.MODE === 'production' ||
    import.meta.env.MODE === 'staging'
) {
    axios.defaults.baseURL = import.meta.env.VITE_API_URL
} else {
    // axios.defaults.baseURL =  import.meta.env.VITE_API_URL // 'https://api.pinia.app'
}

function encrypt(data) {
    var Sha256 = CryptoJS.SHA256;
    var Hex = CryptoJS.enc.Hex;
    var Utf8 = CryptoJS.enc.Utf8;
    var Base64 = CryptoJS.enc.Base64;
    var AES = CryptoJS.AES;
    let key = Sha256(import.meta.env.VITE_DECRYPT_KEY)
        .toString()
        .toString(Hex)
        .substr(0, 32);
    let iv = Sha256(import.meta.env.VITE_DECRYPT_IV)
        .toString()
        .toString(Hex)
        .substr(0, 16);
    // Encryption
    var output = AES.encrypt(JSON.stringify(data), Utf8.parse(key), {
        iv: Utf8.parse(iv),
    }).toString(); // First Base64 encoding, by default (see 1.)
    var output2ndB64 = Utf8.parse(output).toString(Base64); // Second Base64 encoding (see 1.)
    return output2ndB64;
}

function decrypt(data) {
    var Sha256 = CryptoJS.SHA256;
    var Hex = CryptoJS.enc.Hex;
    var Utf8 = CryptoJS.enc.Utf8;
    var Base64 = CryptoJS.enc.Base64;
    var AES = CryptoJS.AES;
    let key = Sha256(import.meta.env.VITE_DECRYPT_KEY)
        .toString()
        .toString(Hex)
        .substr(0, 32);
    let iv = Sha256(import.meta.env.VITE_DECRYPT_IV)
        .toString()
        .toString(Hex)
        .substr(0, 16);
    // Encryption
    try {
        var output = AES.decrypt(atob(data), Utf8.parse(key), {
            iv: Utf8.parse(iv),
        }).toString(Utf8); // First Base64 encoding, by default (see 1.)
        return JSON.parse(output);
    } catch (error) {
        return null;
    }
}

export function
    routePathAPI() {
    if (
        import.meta.env.MODE === 'production' ||
        import.meta.env.MODE === 'staging'
    ) {
        return '';
        // return '/v1'
    } else {
        return '/api'
    }
}

const axiosInstance = axios.create({
    timeout: 90000,
    headers: {
        'x-language-code': import.meta.env.VITE_LANGUAGE_CODE,
    },
})

axiosInstance.interceptors.request.use(
    async function (config) {
        try {
            const token = localStorage.getItem("redek.accessToken");
            const key = localStorage.getItem("token");
            let authorization = null;
            // config.data = await parseFormData(config.data);
            if (token) {
                let auth = JSON.parse(token);
                authorization = "Bearer " + auth;
            }
            if (key) {
                config.headers.common["Authorization"] = "Bearer " + key;
                localStorage.removeItem("token");
            } else {
                if (authorization) {
                    config.headers.common["Authorization"] = authorization;
                }
            }
            config.headers.common["X-Requested-With"] = "XMLHttpRequest";
            config.headers.common["Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;

            if (import.meta.env.VITE_DECRYPT_ENABLE === "true" && config.data) {
                config.data = { data: encrypt(config.data) };
            }
            return config;
        } catch (error) {
            console.log(error);
        }
    },
    function (error) {
        return Promise.reject(error);
    }
)

axiosInstance.interceptors.response.use(
    (response) => {
        let decode = response
        const encryptionEnabled = import.meta.env.VITE_DECRYPT_ENABLE === "true"
        if (encryptionEnabled) {
            if (response.data) {
                if (response.data.data) {
                    decode = decrypt(response.data.data);
                } else {
                    decode = decrypt(response.data);
                }
            }
        }
        if (decode && decode.data && decode.data.isUserAPI) {
            localStorage.removeItem('redek.permissions');
            localStorage.setItem('redek.permissions', window.btoa(JSON.stringify(decode.data.role.permissions)));
        }
        return { status: response.status, data: encryptionEnabled ? decode : response.data };
    },
    (error) => {
        const { response, config } = error
        if (response.status === 401) {
            localStorage.removeItem('redek.permissions');
            localStorage.removeItem('redek.accessToken');
            localStorage.removeItem('redek.activeUser');
            router.push({
                name: 'login'
            });
        }
        return Promise.reject(error)
    }
)

export default axiosInstance
