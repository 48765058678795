import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
    getAllNotification({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {}
            if (payload.page) params.page = payload.page
            if (payload.take) params.per_page = payload.take
            if (payload.order) params.order = 'created_at.' + payload.order
            if (payload.q) params.search = payload.q
            let addroute = '';
            if (payload.case_id) addroute = '/byCase/' + payload.case_id

            axios.get(`${routePathAPI()}/notifications${ addroute }`, {
                params: params,
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            })
            .then(async (response) => {
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    readNotification ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            let params = {}
            params.is_read = true;
            params.case_id = payload.case_id;
            params.user_id = payload.user_id;
            params.type = payload.type;
            params.title = payload.title;

            axios.put(
                `${routePathAPI()}/notifications/${ payload.id }`,
                params,
            )
            .then((response) => {
                const responseData = response.data
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: responseData,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: responseData,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },


    getTotalUnreadNotification({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
        let params = {}
        // if (payload.page) params.page = payload.page
        // if (payload.take) params.take = payload.take
        // if (payload.order) params.order = payload.order
        // if (payload.case_id) params.case_id = payload.case_id
        if (payload.q) params.search = payload.q
        params.query = 'is_read.1';

        axios.get(`${routePathAPI()}/notifications`, {
            params: params,
            headers: {
                'x-language-code': currentLanguageCode,
            },
        }).then(async (response) => {
            if (response.status === 200) {
                resolve({
                    status: response.status,
                    message: 'success',
                    data: response.data,
                })
            } else {
                reject({
                    status: response.status,
                    message: 'failed',
                    data: response.data,
                })
            }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
