<template>
  <div class="flex h-screen overflow-hidden">
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <Header
        :sidebar-open="sidebarOpen"
        @toggle-sidebar="sidebarOpen = !sidebarOpen"
      />

      <main>
        <div class="w-full">
          <div class="mt-10 flex h-full flex-col justify-center bg-gray-100">
            <div class="w-10/12 space-y-4 self-center px-8 py-8 lg:w-1/2">
              <!-- HEADER -->
              <div class="w-full space-y-2 text-center">
                <p class="text-base font-bold text-slate-800">
                  {{ $t('mediationAgreement.title') }}
                </p>
                <p class="text-xs font-semibold">
                  {{ $t('mediationAgreement.subtitle') }}
                </p>
              </div>

              <!-- CARD UP -->
              <div class="rounded-lg bg-white py-4">
                <div class="w-full space-y-2 px-4">
                  <p class="text-base font-bold text-slate-800">
                    {{ $t('mediationAgreement.title') }}
                  </p>
                  <p class="text-xs font-semibold">
                    <span v-if="activeUserRole === 'REDEK_MEDIATOR'">
                      {{ $t('mediationAgreement.description') }}
                    </span>
                    <span v-else>
                      {{ $t('mediationAgreement.aggreementDesc') }}
                    </span>
                  </p>

                  <template v-if="!isLoading">
                    <div
                      v-if="mediatorData !== undefined"
                      class="border-b border-gray-300 pb-2"
                    >
                      <MediationAgreementUser :user-data="mediatorData" />
                    </div>
                    <div v-if="usersData.length > 0" class="space-y-4">
                      <MediationAgreementUser
                        v-for="userData in usersData"
                        :key="userData.id"
                        :user-data="userData"
                      />
                    </div>
                  </template>
                  <DualRingLoader v-else />
                </div>
              </div>

              <!-- CARD DOWN -->
              <!-- <Form
                v-if="activeUserRole === 'REDEK_MEDIATOR'"
                ref="form"
                v-slot="{ errors }"
                :validation-schema="uploadDocumentSchema"
                @submit="createMediationAgreement"
              > -->
              <!--  -->
              <div class="space-y-4 rounded-lg bg-white py-4">
                <template v-if="!isLoading">
                  <template
                    v-if="
                      activeUserRole === 'REDEK_MEDIATOR' &&
                      checkEmptyObject(mediationAgreement)
                    "
                  >
                    <div class="w-full px-4">
                      <label class="mb-1 block text-xs font-bold" for="files">
                        {{ $t('mediationAgreement.attachLabel') }}
                      </label>
                      <!-- <div class="flex w-full items-center justify-center"> -->
                      <!-- <Field v-model="attachment" name="attachment"> -->
                      <!-- <label
                        for="files"
                        class="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-gray-300 bg-gray-50 hover:bg-gray-100"
                      >
                        <div
                          class="flex flex-col items-center justify-center py-2"
                        >
                          <svg
                            aria-hidden="true"
                            class="mb-3 h-10 w-10 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p class="text-sm text-gray-500">
                            {{ $t('forms.dragAndDrop') }}
                          </p>
                          <p class="text-sm text-gray-500">
                            {{ $t('forms.or') }}
                          </p>
                          <p class="text-sm font-semibold text-sky-500">
                            {{ $t('forms.browseFiles') }}
                          </p>
                        </div>
                        <input
                          id="files"
                          type="file"
                          class="hidden"
                          @change="fileInput"
                        />
                        <span v-if="attachment">{{ attachment.name }}</span>
                      </label>
                    </Field> -->
                      <!-- </div> -->
                      <!-- <div
                    v-if="errors.attachment !== undefined"
                    class="flex w-full pb-4"
                  >
                    <span class="pl-3 text-sm text-red-500">
                      {{ $t(errors.attachment) }}
                    </span>
                  </div> -->
                      <DropFile :show-label="false" @files="changeFiles" />
                    </div>

                    <div
                      v-if="mediationAgreementData.length > 0"
                      class="flex w-full flex-col space-y-2 px-4"
                    >
                      <p class="text-xs font-bold">
                        {{ $t('mediationAgreement.youCanChooseTemplate') }}
                      </p>

                      <div class="flex flex-wrap gap-2">
                        <FileItem
                          v-for="file in mediationAgreementData"
                          :key="file.id"
                          class="w-1/4"
                          :file="file"
                          :is-files-object="false"
                        />
                      </div>
                    </div>

                    <div class="flex w-full flex-col space-y-2 px-4">
                      <div
                        class="flex w-full cursor-pointer items-start text-justify"
                      >
                        <input
                          v-model="aggrementCheck"
                          type="checkbox"
                          class="form-input h-5 checked:bg-sky-500 focus:bg-sky-500"
                        />
                        <p class="pl-2 text-sm">
                          <span>{{ $t('newCase.bySubmit') }}</span>
                          <router-link
                            class="px-1 text-sm font-bold text-sky-500"
                            to="#"
                          >
                            {{ $t('newCase.terms') }}
                          </router-link>
                          <span>{{ $t('newCase.and') }}</span>
                          <router-link
                            class="px-1 text-sm font-bold text-sky-500"
                            to="#"
                          >
                            {{ $t('newCase.privacyPolicy') }}
                          </router-link>
                        </p>
                      </div>
                      <!-- errors.attachment === undefined -->
                      <button
                        class="btn text-md h-12 w-full rounded-md"
                        :class="[
                          mediationFiles.length > 0 && aggrementCheck
                            ? 'cursor-pointer bg-sky-500 text-white hover:bg-sky-600'
                            : 'cursor-not-allowed bg-gray-300 text-slate-100',
                        ]"
                        @click="createMediationAgreement"
                      >
                        {{ $t('action.next') }}
                      </button>
                    </div>
                  </template>
                  <template v-else>
                    <div class="flex w-full flex-col space-y-2 px-4">
                      <p class="text-xs font-bold">
                        {{ $t('mediationAgreement.aggreementDocument') }}
                      </p>
                      <div class="flex flex-wrap gap-2">
                        <FileItem
                          v-for="file in caseFileMapping"
                          :key="file.id"
                          class="w-1/4"
                          :file="file"
                        />
                      </div>
                    </div>

                    <div
                      v-if="mediationUsersAgreement.length > 0"
                      class="flex w-full justify-start space-x-4 px-4"
                    >
                      <div class="flex items-center">
                        <span class="mr-2 text-green-500">
                          {{ $t('chat.discussion.accepted') }}
                        </span>
                        <Avatars
                          :extract-user="true"
                          :parties="mediationUsersAgreementAccept"
                        />
                      </div>
                      <div class="flex items-center">
                        <span class="mr-2 text-red-500">
                          {{ $t('chat.discussion.rejected') }}
                        </span>
                        <Avatars
                          :extract-user="true"
                          :parties="mediationUsersAgreementReject"
                        />
                      </div>
                    </div>
                    <div v-else class="px-4 text-xs">
                      <p class="text-gray-400">
                        {{ $t('mediationAgreement.waitingMediator') }}
                      </p>
                    </div>

                    <div
                      v-if="
                        activeUserRole !== 'REDEK_MEDIATOR' &&
                        currentUserCanAgree
                      "
                      class="flex w-full flex-col space-y-2 px-4"
                    >
                      <button
                        class="btn text-md h-12 w-full cursor-pointer rounded-md bg-sky-500 text-white hover:bg-sky-600"
                        @click="actionAgreement(true)"
                      >
                        {{ $t('action.accept') }}
                      </button>
                      <button
                        class="btn text-md h-12 w-full cursor-pointer rounded-md border border-red-500 bg-white text-red-500 hover:border-red-700 hover:text-red-700"
                        @click="actionAgreement(false)"
                      >
                        {{ $t('action.decline') }}
                      </button>
                    </div>
                  </template>
                </template>
                <DualRingLoader v-else />
              </div>
              <!-- </Form> -->
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
// import { Form, Field } from 'vee-validate'
import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'

import Header from '@/partials/Header.vue'
import MediationAgreementUser from '@/components/user/MediationAgreementUser.vue'
import FileItem from '@/components/FileItem.vue'
import Avatars from '@/components/Avatars.vue'
import DropFile from '@/components/files/DropFile.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'

export default {
  name: 'MediationAgreementPage',
  components: {
    // Form,
    // Field,
    Header,
    MediationAgreementUser,
    FileItem,
    Avatars,
    DropFile,
    DualRingLoader,
  },
  mixins: [globalMixin, caseMixin],
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()
        const sidebarOpen = ref(false)
        const activeUserRole = ref(null)
        const activeUserId = ref(null)
        let activeUser = null;
        store.dispatch('auth/getActiveUser').then(response => {
            activeUser = response;
            if (!activeUser) {
                // router.push({ name: 'login' })
            } else {
                activeUserRole.value = activeUser.role.code
                activeUserId.value = activeUser.id
            }
        })

        return {
            t,
            n,
            store,
            toast,
            sidebarOpen,
            activeUserRole,
            activeUserId,
        }
    },
  data: () => ({
    // attachment: null,
    aggrementCheck: false,
    // userData: undefined,
    usersData: [],
    mediatorData: undefined,
    mediationAgreementData: [],
    filterDataBankFile: {
      page: 1,
      take: 20,
      is_active: true,
      order: 'DESC',
    },
  }),
  mounted() {
    this.getCaseDetail().then(() => {
      this.init()
    })
    this.getFileCase({ case_id: this.$route.params.slug, type: 'MEDIATION' })
  },
  methods: {
    getMediationAgreementInit() {
      this.getMediationAgreement().then(() => {
        if (!this.checkEmptyObject(this.mediationAgreement)) {
          this.mediationUsersAgreement = this.mediationAgreement.user_agreements
          if (this.mediationUsersAgreement.length > 0) {
            const currentUserAgreement = this.mediationUsersAgreement.find(
              (item) => item.user.id === this.activeUserId
            )
            const canCurrentUser = this.mediationUsersAgreement.find(
              (item) =>
                item.user.id === this.activeUserId && this.is_accept === null
            )

            this.currentUserCanAgree =
              currentUserAgreement !== undefined && canCurrentUser === undefined

            this.mediationUsersAgreementAccept =
              this.mediationUsersAgreement.filter(
                (item) => item.is_accept === true
              )
            this.mediationUsersAgreementReject =
              this.mediationUsersAgreement.filter(
                (item) => item.is_accept !== true && item.is_accept !== null
              )
          }
        } else {
          const bankId = this.activeCase.bank.id
          this.getAllBankFile(bankId)
        }
      })
    },
    init() {
      if (this.activeCase.stage !== 3) {
        this.toCaseStage()
      }

      this.getMediationAgreementInit()

      if (this.caseParties.length > 0) {
        this.mediatorData = this.caseParties.find(
          (item) => item.role === 'Mediator'
        )
        this.usersData = this.caseParties.filter(
          (item) => item.role !== 'Mediator'
        )
      }
    },
    async createMediationAgreement() {
      if (this.mediationFiles.length > 0 && this.aggrementCheck) {
        this.isLoading = true
        await this.store
          .dispatch('cases/createMediationAgreement', {
            case_id: this.$route.params.slug,
            result: null,
            notes: null,
          })
          .then((response) => {
            console.log(response)
            this.isLoading = false

            if (this.mediationFiles.length > 0) {
              let promises = []
              this.mediationFiles.forEach((item) => {
                promises.push(
                  this.uploadFileCase({
                    case_id: this.$route.params.slug,
                    type: 'MEDIATION',
                    attachment: item,
                  })
                )
              })
              Promise.all(promises).then(() => this.init())
            } else {
              this.init()
            }
          })
          .catch((error) => {
            this.isLoading = false
            this.toast.error(this.extractErrorResponse(error))
          })
      }
    },
    // fileInput(event) {
    //   this.attachment = event.target.files[0]
    // },
    changeFiles(event) {
      this.mediationFiles = event
    },
    actionAgreement(accept = true) {
      if (!this.checkEmptyObject(this.mediationAgreement)) {
        this.actionMediationAgreement({
          mediation_id: this.mediationAgreement.id,
          accept: accept,
        }).then(() => {
          this.init()
        })
      }
    },

    async getAllBankFile(bankId) {
      await this.store
        .dispatch('bank/getAllBankFile', {
          order: this.filterDataBankFile.order,
          page: this.filterDataBankFile.page,
          take: this.filterDataBankFile.take,
          is_active: this.filterDataBankFile.is_active,
          bank_id: bankId,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData.data) {
            this.mediationAgreementData = responseData.data
          }
          if (responseData.meta) {
            this.filterDataBankFile = responseData.meta
          }
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
  },
}
</script>
