<template>
    <div
        class="col-span-full flex cursor-pointer flex-col rounded-lg border border-slate-200 bg-white shadow-lg sm:col-span-6 xl:col-span-4"
        @click.stop="editNote"
    >
        <div class="px-5 pt-5">
            <header class="flex justify-between">
                <h1 class="line-clamp-1 text-lg font-bold text-black">
                    {{ note.title }}
                </h1>
                <PinIcon
                v-if="!$store.getters['cases/currentCaseWasClosed'] || $methods.hasPermissions(['cases_manage_private_notebook_create_although_case_closed'])"
                    class="w-4 cursor-pointer"
                    :class="
                        pinned
                            ? 'text-gray-600 hover:text-gray-900'
                            : 'text-gray-200 hover:text-gray-600'
                    "
                    @click.stop="pinItem"
                />
            </header>
            <div class="pt-4 pb-10 pr-4 text-xs font-semibold text-slate-400">
                <p v-html="note.content"></p>
            </div>
            <div class="flex justify-between pb-2">
                <p class="text-xs font-semibold">{{ date }}</p>
                <NoteIcon
                v-if="!$store.getters['cases/currentCaseWasClosed'] || $methods.hasPermissions(['cases_manage_private_notebook_create_although_case_closed'])"
                v-permission="'cases_manage_private_notebook_edit'"
                class="w-4 cursor-pointer text-gray-600 hover:text-gray-900"
                @click.stop="editNote"
                />
            </div>
        </div>
    </div>
</template>
<script>
import PinIcon from '@/assets/svg/pin.svg'
import NoteIcon from '@/assets/svg/note.svg'

export default {
    name: 'PrivateNotebookItemComponent',
    components: {
        PinIcon,
        NoteIcon,
    },
    props: {
        note: {
            type: Object,
            default: () => {
                return {
                    title: '',
                    content: '',
                }
            },
        },
        pinned: {
            type: Boolean,
            default: false,
        },
        date: {
            type: String,
            default: '12 July 2022',
        },
    },
    emits: ['edit-note', 'pin-item'],
    methods: {
        editNote() {
            this.$emit('edit-note', this.note)
        },
        pinItem() {
            const note = this.note
            note.is_pinned = !this.pinned
            this.$emit('pin-item', note)
        },
    },
}
</script>
