<template>
    <div class="flex w-full items-center bg-white relative">
        <div class="flex w-2/6 items-center justify-start">
            <div class="hidden px-4 md:block">
                <ImageLoader wrapper-class="mr-3 shrink-0 rounded-full" :src="user.avatar" width="60" height="60"
                    :alternate="user.first_name" />
            </div>
            <div class="line-clamp-2 pt-1">
                <span> {{ getName(user) }} </span>
            </div>
        </div>
        <div class="flex w-2/6 flex-col justify-center pt-1">
            <p :class="[
                'w-1/2 rounded-lg px-2 py-2 text-center text-base font-semibold',
                user.role.class_tailwind_color ?? 'bg-purple-100  text-purple-600'
            ]">
                {{ user.role.name }}
            </p>
        </div>
        <div class="flex w-2/6 px-2">
            <div class="flex w-full justify-between">
                <div class="pt-1">
                    <span>{{ user.email }}</span>
                </div>
                <div>
                    <EditMenu align="right" class="relative ml-2 inline-flex">
                        <li>
                            <span
                                class="flex cursor-pointer py-1 px-3 text-sm font-medium text-slate-600 hover:text-slate-800"
                                @click.stop="editUser()">
                                {{ $t('edit') }}
                            </span>
                        </li>
                        <li>
                            <span
                                class="flex cursor-pointer py-1 px-3 text-sm font-medium text-rose-500 hover:text-rose-600"
                                @click.stop="removeUser()">
                                {{ $t('remove') }}
                            </span>
                        </li>
                    </EditMenu>
                </div>
            </div>
        </div>
        <div
        v-if="statsActive && !statsVisible"
        class="absolute bottom-0 right-0">
            <button
            @click="$emit('show-stats')"
            class="
                bg-green-500 transition-all text-white py-1 px-3 rounded-tl-xl text-sm
                hover:bg-green-600 flex items-center justify-between gap-2
            ">
                <i class="fa-solid fa-chart-pie"></i>
                <span>
                    Show stats
                </span>
            </button>
        </div>
    </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'

import EditMenu from './DropdownEditMenu.vue'
import ImageLoader from '@/components/image/ImageLoader.vue'

export default {
    name: 'UserItemComponent',
    components: {
        EditMenu,
        ImageLoader,
    },
    mixins: [globalMixin],
    props: {
        user: {
            type: Object,
            default: () => {
                return {}
            },
        },
        statsActive: {
            type: Boolean,
            default: false,
        },
        statsVisible: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['remove-user', 'edit-user', 'show-stats'],
    methods: {
        editUser() {
            this.$emit('edit-user', {
                user: this.user,
            })
        },
        removeUser() {
            this.$emit('remove-user', {
                user: this.user,
            })
        },
    },
}
</script>
