<template>
    <div
    v-if="visible"
    class="fixed z-50 bg-slate-700 w-full h-11 text-white flex justify-end gap-10 pr-6">
        <button @click="print">
            <i class="fa-solid fa-print"></i>
        </button>
        <button @click="download">
            <i class="fa-solid fa-download"></i>
        </button>
        <button @click="close">
            <i class="fa-solid fa-arrow-right-from-bracket"></i>
        </button>
    </div>

    <vue-pdf-embed
    ref="pdfembedref"
    v-if="visible"
    :width="(screenWidth < 769) ? '' : 900"
    :textLayer="true"
    :annotationLayer="true"
    class="absolute top-0 z-40 w-full flex justity-center pt-14 pb-11 flex-col items-center gap-8 bg-gray-500/75"
    :source="source"/>

</template>
<script>
import VuePdfEmbed from 'vue-pdf-embed'
import DownloadIcon from '@/assets/svg/download.svg'

export default {
    data() {
        return {
            screenWidth: window.innerWidth,
            visible: false,
        }
    },
    watch: {
        isVisible (val) {
            this.visible = val
        }
    },
    emits: ['close'],
    props: {
        source: '',
        isVisible: false,
    },
    components: {
        VuePdfEmbed,
        DownloadIcon,
    },
    mounted() {
        this.visible = this.isVisible;
        window.addEventListener('resize', this.updateWindowS);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateWindowS);
    },
    methods: {
        updateWindowS() {
            this.screenWidth = window.innerWidth;
        },
        close () {
            this.visible = false;
            this.$emit('close')
        },
        download () {
            this.$refs.pdfembedref.download();
        },
        print () {
            this.$refs.pdfembedref.print();
        },
    },
}

</script>