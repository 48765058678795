<template>
    <div class="my-6 py-6">
        <div class="flex flex-row py-4">
            <img
            class="mr-3 shrink-0 rounded-full"
            :src="chat.user_created.avatar !== null ? chat.user_created.avatar : defaultImage"
            width="60"
            height="60"
            :alt="chat.user_created.first_name" />
            <div class="w-full pl-4">
                <div class="flex w-full items-center text-lg font-semibold text-slate-800">
                    <p class="line-clamp-1">
                        {{ chat.user_created.first_name }}
                        {{ chat.user_created.last_name }}
                    </p>
                    <span v-if="me && chat.user_created.id === me.id" class="pl-2">
                        {{ $t('you') }}
                    </span>
                    <div v-if="chat.user_created.role" class="ml-2 flex w-max rounded-lg py-1 px-4" :class="chat.user_created.role.name === 'Customer'
                            ? 'bg-red-100'
                            : 'bg-blue-100'
                        ">
                        <p class="text-xs font-bold" :class="chat.user_created.role.name === 'Customer'
                                ? 'text-red-500'
                                : 'text-blue-700'
                            ">
                            {{ chat.user_created.role.name }}
                        </p>
                    </div>
                </div>
                <p class="text-sm text-slate-500">
                    {{
                        formatDateDay(chat.created_at, {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                        })
                    }}
                </p>
            </div>
            <div class="flex w-2/6 justify-end">
                <p class="inline pr-2">Read by:</p>
                <Avatars :parties="chat.chat_reads" />
            </div>
        </div>
        <div class="flex items-start">
            <div class="grow">
                <div class="items-start justify-between border-t py-4 sm:flex">
                    <div class="mb-2 items-center space-y-4 text-left text-base sm:mb-0">
                        <h1 class="text-xl text-sky-500">{{ chat.title }}</h1>
                        <p v-if="chat.request">
                            {{ chat.request }}
                        </p>
                        <!-- {{ chat.content }} -->
                        <p v-html="chat.content"></p>
                        <div
                        v-if="chat.amount && chat.currency"
                        class="text-sm font-bold text-slate-500 mt-4">
                            {{ chat.amount }}
                            {{ chat.currency.code }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
        v-if="chat.content && chat.type === 'offer'"
        class="flex flex-col items-start border-b pb-4">
            <div class="grow">
                <div class="items-center space-y-4 text-left text-base sm:mb-0">
                    <button
                    @click="viewDocumentAgreement"
                    class="bg-yellow-200 py-1 px-4 rounded-md">
                        {{ $t('chat.discussion.offer.agreement.view') }}
                    </button>
                </div>
            </div>
        </div>
        <div v-if="chat.chat_attachments.length > 0" class="my-6 flex flex-col items-start space-y-4 text-slate-500">
            <p class="text-sm">
                {{
                    $t('general.totalAttachments', {
                        total: String(chat.chat_attachments.length),
                    })
                }}
            </p>
            <FileItem v-for="attachment in chat.chat_attachments" :key="attachment.id" :file="attachment.document" />
        </div>
        <div
        v-if="chat.chat_offers.length > 0"
        class="flex flex-col gap-2 mt-3 items-start w-full"
        >
            <div class="flex flex-col gap-3">
                <span class="mr-2 text-green-500">
                    {{ $t('chat.discussion.accepted') }}
                </span>
                <Avatars
                class="flex-col"
                :extract-user="true"
                :parties="accepted"
                :aditional-text="'reason'"
                />
            </div>
            <div class="flex flex-col gap-2">
                <span class="mr-2 text-red-500">
                    {{ $t('chat.discussion.rejected') }}
                </span>
                <Avatars
                class="flex-col"
                :extract-user="true"
                :parties="rejected"
                :aditional-text="'reason'"
                />
            </div>
        </div>
        <div
        class="bg-red-100 -mx-6 text-end -mb-6 py-2 px-6 text-red-500 text-sm rounded-b-lg"
        v-if="hasOfferToMe && new Date(chat.deadline_at) < currentTime"
        >
            {{ $t('chat.discussion.expiredOffer') }}
        </div>
        <div
        v-if="hasOfferToMe && new Date(chat.deadline_at) > currentTime"
        class="flex w-full items-center justify-end space-x-4"
        >
            <p class="text-xs text-red-500">
                {{
                    formatDateDay(chat.deadline_at, {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                    })
                }}
            </p>
            <button class="rounded-md bg-green-500 py-2 px-4 text-white" @click.stop="accept()">
                {{ $t('button.accept') }}
            </button>
            <button class="rounded-md bg-red-500 py-2 px-4 text-white" @click.stop="reject()">
                {{ $t('button.reject') }}
            </button>
        </div>
    </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import Avatars from '@/components/Avatars.vue'
import FileItem from '@/components/FileItem.vue'

export default {
    name: 'ChatDisscussionItemComponent',
    components: {
        Avatars,
        FileItem,
    },
    mixins: [globalMixin],
    inject: ['defaultImage'],
    props: {
        chat: {
            type: Object,
            default: () => {
                return {}
            },
        },
        me: {
            type: Object,
            default: () => {
                return {
                    id: '',
                }
            },
        },
        currentTime: {
            type: [Object, String],
            default: null
        },
    },
    emits: ['accept', 'reject', 'view-agreement'],
    computed: {
        hasOfferToMe() {
            if (this.chat.chat_offers.length > 0) {
                const hasOfferToMe = this.chat.chat_offers.some((offer) => {
                    return this.me && offer.user.id === this.me.id && offer.process_at === null
                })
                return hasOfferToMe
            } else {
                return false
            }
        },
        accepted() {
            if (this.chat.chat_offers.length > 0) {
                return this.chat.chat_offers.filter(item => item.is_accept === true && item.process_at !== null)
            } else {
                return []
            }
        },
        rejected() {
            if (this.chat.chat_offers.length > 0) {
                return this.chat.chat_offers.filter(item => item.is_accept === false && item.process_at !== null)
            } else {
                return []
            }
        },
    },
    methods: {
        reject() {
            this.$emit('reject', this.chat)
        },
        accept() {
            this.$emit('accept', this.chat)
        },
        viewDocumentAgreement () {
            this.$emit('view-agreement', this.chat)
        },
    },
}
</script>
