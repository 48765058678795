<template>
    <div class="flex h-screen overflow-hidden">
        <!-- Sidebar -->
        <Sidebar :sidebar-open="sidebarOpen" @close-sidebar="sidebarOpen = false" />
        <!-- Content area -->
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <main>
                <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
                    <div class="flex justify-between">
                        <div class="flex w-full flex-col">
                            <h1 class="text-2xl font-bold text-black">
                                {{ $t('admin.roles.detail.title') }}
                            </h1>
                            <p>{{ $t('admin.roles.detail.description', { codeRole: currentRole.code }) }}</p>
                        </div>
                        <DropdownNotifications align="right" class="md:mr-4" wrapper-class="bg-white rounded-lg" />
                    </div>
                    <div v-if="!isLoading" class="my-4 flex w-full">
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full mt-4 bg-white px-4 py-8 rounded-md shadow-md">
                            <Form
                            id="form_create_update_roles"
                            ref="form"
                            v-slot="{ errors }"
                            :validation-schema="rolesCreationSchema"
                            class="-mx-8 md:mx-0 flex flex-col justify-between"
                            @submit="submitForm">
                                <div class="px-5">
                                    <div class="space-y-3 px-5">
                                        <div>
                                            <label class="mb-1 block text-xs font-bold">
                                                {{ $t('admin.roles.modalCreateRole.code') }}
                                            </label>
                                            <Field v-slot="{ field }" v-model="code" name="code">
                                                <input
                                                v-bind="field"
                                                class="form-input w-full p-2"
                                                type="text"
                                                id="form_create_update_roles_code" required
                                                :placeholder="$t('admin.roles.modalCreateRole.code')"
                                                :class="[
                                                    {
                                                        'bg-slate-200': !currentRole.code_editable
                                                    }
                                                ]"
                                                :disabled="!currentRole.code_editable"
                                                />
                                            </Field>
                                            <div v-if="errors.code !== undefined" class="flex w-full">
                                                <span class="text-sm text-red-500">
                                                    {{ $t(errors.code) }}
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <label class="mb-1 block text-xs font-bold">
                                                {{ $t('admin.roles.modalCreateRole.name') }}
                                            </label>
                                            <Field v-slot="{ field }" v-model="name" name="name">
                                                <input v-bind="field" class="form-input w-full p-2" type="text"
                                                    id="form_create_update_roles_name" required
                                                    :placeholder="$t('admin.roles.modalCreateRole.name')" />
                                            </Field>
                                            <div v-if="errors.name !== undefined" class="flex w-full">
                                                <span class="text-sm text-red-500">
                                                    {{ $t(errors.name) }}
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <label class="mb-1 block text-xs font-bold">
                                                {{ $t('admin.roles.modalCreateRole.description') }}
                                            </label>
                                            <Field v-slot="{ field }" v-model="description" name="description">
                                                <input id="form_create_update_roles_description" v-bind="field"
                                                    class="form-input w-full p-2" type="text" required
                                                    :placeholder="$t('admin.roles.modalCreateRole.description')" />
                                            </Field>
                                            <div v-if="errors.description !== undefined" class="flex w-full">
                                                <span class="text-sm text-red-500">
                                                    {{ $t(errors.description) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="px-5 py-4">
                                    <div class="flex flex-wrap justify-center space-x-2 px-4">
                                        <button type="submit" id="form_create_update_roles_action_submit"
                                            class="btn-sm w-full whitespace-nowrap rounded-lg bg-sky-500 p-3 text-white hover:bg-sky-600"
                                            :class="[
                                                isLoading ? 'cursor-not-allowed' : 'cursor-pointer',
                                            ]">
                                            <span v-if="isLoading">
                                                {{ $t('general.loading') }}
                                            </span>
                                            <span v-else>
                                                {{ $t('admin.roles.modalCreateRole.save') }}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                            <div>
                                <div class="text-sm mb-2">
                                    <b>Permissions</b>
                                </div>
                                <span v-if="initializingPermissions">
                                    Initializing...
                                </span>
                                <Tree
                                v-else
                                id="permissions-tree-id"
                                class="bg-slate-100 rounded-md"
                                ref="permissions-tree"
                                :custom-options="TreeConfig.customOptions"
                                :custom-styles="TreeConfig.customStyles"
                                :nodes="builtPermissions"
                                />
                            </div>
                        </div>
                    </div>
                    <DualRingLoader v-else />
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import rolesMixin from '@/mixins/roles.js'
import { Form, Field } from 'vee-validate'

import Sidebar from '@/partials/Sidebar.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'
import NoData from '@/components/NoData.vue'
import DropdownNotifications from '@/components/DropdownNotifications.vue'
import Tree from "vuejs-tree";

export default {
    name: 'AdminRolesDetailPage',
    components: {
        Sidebar,
        DualRingLoader,
        NoData,
        DropdownNotifications,
        Form,
        Field,
        Tree,
    },
    mixins: [globalMixin, rolesMixin],
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()
        const sidebarOpen = ref(false)
        const currentRole = ref({})
        const isRoleEdit = ref(false)
        const initializingPermissions = ref(true);
        const builtPermissions = ref([]);

        return {
            t,
            store,
            toast,
            sidebarOpen,
            currentRole,
            isRoleEdit,
            initializingPermissions,
            builtPermissions,
        }
    },
    data: () => ({
        role_id: null,
        code: null,
        name: '',
        description: '',
    }),
    watch: {
        currentRole: function (val, old) {
            if (val) {
                this.role_id = val.id;
                this.code = val.code;
                this.name = val.name;
                this.description = val.description;
            }
        },
    },
    mounted() {
        // this.checkUserAccess('REDEK_ADMIN')
        this.getRoleById();
        this.getBuiltPermissions();
    },
    methods: {
        close() {
            this.$refs.form.resetForm()
            this.$emit('closed')
        },
        submitForm() {
            const formData = {
                role_id: this.role_id,
                code: this.code,
                name: this.name,
                description: this.description,
                permissions: this.$refs["permissions-tree"].getCheckedNodes('id'),
            }
            this.submitData(formData);
        },
        checkPermissionOnTree (nodeId, state) {
            const nodeChecked = this.$refs["permissions-tree"].findNode(nodeId);
            if (nodeChecked && nodeChecked.pattern_id) {
                const forCheck = state;
                this.searchPatternTreeRecursive(this.builtPermissions, nodeChecked.pattern_id, forCheck);
            }
        },
        searchPatternTreeRecursive (nodes, patternId, check = true) {
            nodes.forEach(item => {
                if (item.id == patternId) {
                    item.state.checked = check || item.nodes.some(node => node.state.checked);
                } else {
                    this.searchPatternTreeRecursive(item.nodes, patternId, check)
                    if (item.nodes.length) {
                        item.state.checked = item.nodes.some(node => node.state.checked)
                    }
                }
            })
        },
    },
}
</script>
<style>

.custom_tree_expand > span {
    position: relative;
}

.custom_tree_expand .expanded_icon {
    position: absolute;
    right: 5px;
    top: -3px;
}

.custom_tree_expand span {
    text-transform: none !important;
}

</style>