<template>
    <div class="flex h-screen overflow-hidden">
        <!-- Sidebar -->
        <Sidebar
            v-if="hasCase"
            :sidebar-open="sidebarOpen"
            :case-stage="$route.query.status"
            @close-sidebar="sidebarOpen = false"
        />

        <!-- Content area -->
        <div
            class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
        >
            <main>
                <div class="mx-auto w-full max-w-4xl px-4 py-8 sm:px-6 lg:px-8">
                    <div
                        v-if="!hasCase"
                        class="flex w-full cursor-pointer flex-row items-center py-4"
                        @click="back"
                    >
                        <ArrowBackIcon
                            class="h-7 w-7 fill-current text-slate-500"
                        />
                        <span
                            class="ml-2 text-xs text-slate-500 hover:text-sky-400"
                            >{{ $t('action.back') }}</span
                        >
                    </div>
                    <div class="flex w-full flex-col pb-4">
                        <h1 class="text-2xl font-bold text-black">
                            {{ $t('notification.title') }}
                        </h1>
                        <p>{{ $t('notification.description') }}</p>
                    </div>

                    <div class="mb-8 flex w-full items-center justify-between">
                        <SearchForm
                            :placeholder="$t('notification.searchPlaceholder')"
                            @search="searchNotification"
                        />
                    </div>

                    <template v-if="notifications.length > 0 && !isLoading">
                        <NotificationItem
                            :notifications="notifications"
                            bg-class="bg-white"
                            :is-admin="$store.getters['auth/getCurrentUser'].role.code === 'REDEK_ADMIN'"
                        />
                    </template>
                    <DualRingLoader v-else-if="isLoading" />
                    <NoData v-else />
                </div>
            </main>
        </div>

        <RightSidebar
            v-if="hasCase"
            :sidebar-open="sidebarOpen"
            @close-sidebar="sidebarOpen = false"
        />
    </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import notificationMixin from '@/mixins/notification.js'

import Sidebar from '@/partials/Sidebar.vue'
import RightSidebar from '@/partials/RightSidebar.vue'
import NotificationItem from '@/components/notification/NotificationItem.vue'
import SearchForm from '@/components/SearchForm.vue'
import NoData from '@/components/NoData.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'

import ArrowBackIcon from '@/assets/svg/arrow-back.svg'

export default {
    name: 'NotificationPage',
    components: {
        Sidebar,
        RightSidebar,
        NotificationItem,
        SearchForm,
        NoData,
        DualRingLoader,
        ArrowBackIcon,
    },
    mixins: [globalMixin, notificationMixin],
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()
        const sidebarOpen = ref(false)

        return {
            t,
            n,
            store,
            toast,
            sidebarOpen,
        }
    },
    mounted() {
        this.hasCase =
            typeof this.$route.params.slug !== 'undefined' &&
            this.$store.getters['auth/getCurrentUser'] !== null &&
            'role' in this.$store.getters['auth/getCurrentUser'] &&
            this.$store.getters['auth/getCurrentUser'].role.code !== 'REDEK_ADMIN'
        this.getAllNotification()
    },
    methods: {
        back() {
            if (
                'role' in this.$store.getters['auth/getCurrentUser'] &&
                this.$store.getters['auth/getCurrentUser'] !== null &&
                this.$store.getters['auth/getCurrentUser'].role.code === 'REDEK_ADMIN'
            ) {
                // this.$router.go(-1)
            } else {
                this.$router.push({
                    name: 'index',
                    query: {
                        lang: this.$route.query.lang,
                    },
                })
            }
        },
    },
}
</script>
