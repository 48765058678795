<template>
    <div class="flex h-screen overflow-hidden">
        <!-- Sidebar -->
        <Sidebar
        :sidebar-open="sidebarOpen"
        @close-sidebar="sidebarOpen = false"
        :get-the-case="true"
        />

        <!-- Content area -->
        <div
            class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
        >
            <!-- Site header -->
                    <!-- <Header
                :sidebarOpen="sidebarOpen"
                @toggle-sidebar="sidebarOpen = !sidebarOpen"
            /> -->

            <main>
                <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
                    <div class="flex w-full flex-col">
                        <h1 class="text-2xl font-bold text-black">
                            {{ $t('privateNotebook.title') }}
                        </h1>
                        <p>{{ $t('privateNotebook.description') }}</p>
                    </div>
                    <div class="my-4 flex w-full items-center justify-between">
                        <SearchForm
                            :placeholder="
                                $t('privateNotebook.searchPlaceholder')
                            "
                            @search="searchNotebook"
                        />
                        <ButtonAdd
                        v-permission="'cases_manage_private_notebook_create'"
                        v-if="
                            !isConcludedMode
                            &&
                            (!$store.getters['cases/currentCaseWasClosed'] || $methods.hasPermissions(['cases_manage_private_notebook_create_although_case_closed']))
                            &&
                            isAValidPartInCase($store.getters['auth/getCurrentUser'])
                        "
                        :title="$t('privateNotebook.buttonAdd')"
                        custom-class="rounded-xl"
                        @clicked="addNote"
                        />
                    </div>
                    <section
                        v-if="
                            !isLoading &&
                            (pinnedNotes.length > 0 || notes.length > 0)
                        "
                    >
                        <!-- Pinned -->
                        <div v-if="pinnedNotes.length > 0" class="w-full">
                            <div class="pb-4 pt-8">
                                <h1 class="text-xl font-semibold text-black">
                                    {{ $t('privateNotebook.pinned') }}
                                </h1>
                            </div>
                            <div class="grid grid-cols-12 gap-6">
                                <PrivateNotebokItem
                                    v-for="note in pinnedNotes"
                                    :key="note.id"
                                    :note="note"
                                    :pinned="note.is_pinned === true"
                                    :date="formatDateDay(note.updated_at)"
                                    @edit-note="editNote"
                                    @pin-item="pinItem"
                                />
                            </div>
                        </div>
                        <!-- All Notes -->
                        <div v-if="notes.length > 0" class="w-full">
                            <div class="pb-4 pt-8">
                                <h1 class="text-xl font-semibold text-black">
                                    {{ $t('privateNotebook.allNotes') }}
                                </h1>
                            </div>
                            <div class="grid grid-cols-12 gap-6">
                                <PrivateNotebokItem
                                    v-for="note in notes"
                                    :key="note.id"
                                    :note="note"
                                    :pinned="note.is_pinned === true"
                                    :date="formatDateDay(note.updated_at)"
                                    @edit-note="editNote"
                                    @pin-item="pinItem"
                                />
                            </div>
                        </div>
                    </section>
                    <DualRingLoader v-else-if="isLoading" />
                    <NoData v-else />
                </div>
                <ModalNote
                    :is-open="notesModalOpen"
                    :date="
                        updateNoteData.updated_at !== ''
                            ? formatDateDay(updateNoteData.updated_at)
                            : formatDateDay(new Date())
                    "
                    :pinned="updateNoteData.is_pinned === true"
                    :active-note="updateNoteData"
                    :is-load="isLoading"
                    @closed="notesModalOpen = false"
                    @new-note="newNote"
                    @edit-note="updateNote"
                    @delete-note="deleteNote"
                    @duplicate-note="duplicateNote"
                />
            </main>
        </div>

        <RightSidebar
            :sidebar-open="sidebarOpen"
            @close-sidebar="sidebarOpen = false"
        />
    </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'

import Sidebar from '@/partials/Sidebar.vue'
import RightSidebar from '@/partials/RightSidebar.vue'
import SearchForm from '@/components/SearchForm.vue'
import ButtonAdd from '@/components/ButtonAdd.vue'
import PrivateNotebokItem from '@/components/PrivateNotebokItem.vue'
import ModalNote from '@/components/ModalNote.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'
import NoData from '@/components/NoData.vue'

export default {
    name: 'PrivateNotebookPage',
    components: {
        Sidebar,
        RightSidebar,
        SearchForm,
        ButtonAdd,
        PrivateNotebokItem,
        ModalNote,
        DualRingLoader,
        NoData,
    },
    mixins: [globalMixin],
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()
        const sidebarOpen = ref(false)

        return {
            t,
            n,
            store,
            toast,
            sidebarOpen,
        }
    },
    data: () => ({
        filterDataNotes: {
            page: 1,
            take: 50,
            search: '',
            order: 'DESC',
        },
        metaDataNotes: {
            page: 1,
            take: 10,
            itemCount: 10,
            pageCount: 1,
            hasPreviousPage: false,
            hasNextPage: true,
        },
        notes: [],
        pinnedNotes: [],
        defaultNote: {
            title: '',
            content: '',
            pinned: false,
            updated_at: '',
        },
        updateNoteData: {},
        newNoteData: {},
        notesModalOpen: false,
    }),
    mounted() {
        this.getAllNotes()
    },
    methods: {
        editNote(event) {
            this.updateNoteData = event
            this.notesModalOpen = true
        },
        addNote() {
            this.updateNoteData = this.defaultNote
            this.notesModalOpen = true
        },
        newNote(event) {
            this.newNoteData = event
            this.createNote()
        },
        updateNote(event) {
            this.updateNoteData = event
            this.updateNoteAPI()
        },
        deleteNote(event) {
            this.deleteNoteAPI(event)
        },
        duplicateNote(event) {
            this.newNoteData = event
            this.createNote()
        },
        pinItem(event) {
            if (event.is_pinned === true) {
                this.notes = this.notes.filter((item) => item.id !== event.id)
                this.pinnedNotes.push(event)
            } else {
                this.pinnedNotes = this.pinnedNotes.filter(
                    (item) => item.id !== event.id
                )
                this.notes.push(event)
            }
            this.updateNoteData = event
            this.updateNoteAPI(false)
        },
        async deleteNoteAPI(id) {
            if (this.isLoading) return
            this.isLoading = true
            await this.store
                .dispatch('notes/deleteNote', { id: this.$methods.encrypt(id) })
                .then((response) => {
                    this.isLoading = false
                    this.getAllNotes()
                    this.toast.success(this.$t('privateNotebook.forms.delete.successfully'))
                    this.notesModalOpen = false
                })
                .catch((error) => {
                    this.notesModalOpen = true
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async updateNoteAPI(reload = true) {
            if (this.isLoading) return
            this.isLoading = true
            this.updateNoteData.id = this.$methods.encrypt(this.updateNoteData.id)
            await this.store
                .dispatch('notes/updateNote', {
                    case_id: this.$methods.decrypt(this.$route.params.slug),
                    ...this.updateNoteData
                })
                .then((response) => {
                    this.isLoading = false
                    if (reload === true) {
                        this.getAllNotes()
                        this.toast.success(this.$t('privateNotebook.forms.update.successfully'))
                        this.notesModalOpen = false
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    if (reload === true) this.notesModalOpen = true
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async createNote() {
            if (this.isLoading) return
            this.isLoading = true
            await this.store
                .dispatch('notes/createNote', {
                    case_id: this.$methods.decrypt(this.$route.params.slug),
                    ...this.newNoteData,
                })
                .then((response) => {
                    this.isLoading = false
                    this.toast.success(this.$t('privateNotebook.forms.create.successfully'))
                    this.notesModalOpen = false
                    this.getAllNotes()
                })
                .catch((error) => {
                    this.isLoading = false
                    this.notesModalOpen = true
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async getAllNotes() {
            this.isLoading = true
            let params = {}
            params.order = this.filterDataNotes.order
            params.page = this.filterDataNotes.page
            params.take = this.filterDataNotes.take
            params.q = this.filterDataNotes.search
            params.case_id = this.$route.params.slug

            await this.store
                .dispatch('notes/getAllNotes', params)
                .then((response) => {
                    this.isLoading = false
                    const responseData = response.data
                    if (responseData.data) {
                        this.pinnedNotes = responseData.data.filter(item => item.is_pinned);
                        this.notes       = responseData.data.filter(item => !item.is_pinned);
                    }
                    if (responseData.from) {
                        this.metaDataDocument = {
                            current_page: responseData.current_page,
                            from: responseData.from,
                            last_page: responseData.last_page,
                            links: responseData.links,
                            per_page: responseData.per_page,
                            to: responseData.to,
                            total: responseData.total,
                        }
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        searchNotebook(event) {
            this.filterDataNotes.search = event
            this.getAllNotes()
        },
    },
}
</script>
