import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
    getAllPermissions({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            let params = {}
            if (payload.page) params.page = payload.page
            if (payload.take) params.per_page = payload.take
            if (payload.order)             params.order  = 'created_at.' + payload.order
            if (payload.order_name)        params.order  = 'name.' + payload.order_name
            if (payload.order_description) params.order  = 'description.' + payload.order_description
            if (payload.order_reference)   params.order  = 'reference.' + payload.order_reference
            if (payload.order_status)      params.order  = 'status.' + payload.order_status
            if (payload.q)                 params.search = payload.q

            axios.get(`${routePathAPI()}/functionalities`, {
                params: params,
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            }).then(async (response) => {
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },
    createPermission({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {
                name: payload.name ?? '',
                description: payload.description ?? '',
                functionalities_id: payload.functionalities_id ?? '',
                reference: payload.reference ?? '',
                status: payload.status ?? '',
            }

            axios.post(`${routePathAPI()}/functionalities`, params, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            }).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },
    deletePermission({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            axios.delete(`${routePathAPI()}/functionalities/${payload.permission_id}`, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            }).then((response) => {
                const responseData = response.data
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: responseData,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: responseData,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    updatePermission({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            let params = {
                name: payload.name ?? '',
                description: payload.description ?? '',
                functionalities_id: payload.functionalities_id ?? '',
                reference: payload.reference ?? '',
                status: payload.status ?? '',
            }
            axios.put(`${routePathAPI()}/functionalities/${payload.permission_id}`, params, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            }).then((response) => {
                const responseData = response.data
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: responseData,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: responseData,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    checkReferenceExistence ({ commit, state }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        if (payload.reference) {
            const addToCheck = (payload.current) ? `/${payload.current}` : '';
            return new Promise((resolve, reject) => {
                axios.get(
                    `${routePathAPI()}/permissions/reference/checkExistence/${payload.reference}${addToCheck}`,
                    {
                        headers: {
                            'x-language-code': currentLanguageCode,
                            platform: import.meta.env.VITE_PLATFORM || null,
                            device: import.meta.env.VITE_DEVICE || null,
                        },
                    }
                ).then(async (response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    } else {
                        reject(response.data)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
            })
        }
    },
}

// mutations
const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
