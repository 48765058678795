<template>
    <div class="flex h-screen overflow-hidden">
        <!-- Sidebar -->
        <Sidebar :sidebar-open="sidebarOpen" @close-sidebar="sidebarOpen = false" />

        <!-- Content area -->
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <main>
                <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
                    <div class="flex justify-between">
                        <div class="flex w-full flex-col">
                            <h1 class="text-2xl font-bold text-black">
                                {{ $t('admin.permissions.title') }}
                            </h1>
                            <p>{{ $t('admin.permissions.description') }}</p>
                        </div>
                        <div class="mt-4 flex w-full items-center justify-end">
                            <DropdownLanguages class="mr-4"/>
                            <DropdownNotifications align="right" class="md:mr-4" wrapper-class="bg-white rounded-lg" />
                            <DropdownProfile align="right" mode="aesthetic" />
                        </div>
                    </div>
                    <div class="my-4 flex w-full justify-between">
                        <div class="flex items-center space-x-4">
                            <SearchForm :placeholder="$t('admin.permissions.searchPlaceholder')" @search="searchPermission" />
                            <div class="flex cursor-pointer items-start text-sm text-black">
                                <div class="flex">
                                    <p>{{ $t('formLabel.sortBy') }}</p>
                                </div>
                                <div class="flex">
                                    <select
                                    class="border-none bg-inherit py-0 font-bold focus:ring-0"
                                    @change="changeOrder">
                                        <option
                                        v-for="order in orderOptions"
                                        :key="order.value"
                                        :value="order.value"
                                        :selected="filterDataPermissions.order === order.value"
                                        >
                                            {{ $t(`${order.label}`) }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="text-sm flex items-center gap-2">
                                <select
                                @change="changeNRecords"
                                v-model="filterDataPermissions.take"
                                class="form-input w-16"
                                >
                                    <option
                                    v-for="item in [5, 10, 25, 50, 100]"
                                    :value="item"
                                    >
                                        {{ item }}
                                    </option>
                                </select>
                                <span>
                                    {{ $t('general.records') }}
                                </span>
                            </div>
                        </div>
                        <ButtonAdd :title="$t('admin.permissions.buttonAdd')" custom-class="rounded-xl" @clicked="newPermission()" />
                    </div>
                    <div v-if="!isLoading && permissions.length > 0" class="w-full mt-8">
                        <DataTable
                        headerClass="!p-4"
                        :headers="dataTable.headers"
                        :list="permissions"
                        >
                            <template v-slot:cell-pattern="cellPatternProps">
                                {{
                                    (
                                        cellPatternProps.item
                                        &&
                                        cellPatternProps.item.has_pattern
                                        &&
                                        cellPatternProps.item.has_pattern.functionality
                                    )
                                        ? cellPatternProps.item.has_pattern.functionality.reference
                                        : null
                                }}
                            </template>
                        </DataTable>
                        <div class="flex flex-col items-end my-4">
                            <div class="flex">
                                <button
                                v-for="(link, index) in metaDataPermissions.links"
                                @click="changePage(link, index)"
                                :class="[
                                    'text-sm px-2 flex items-center justify-center border-t border-b border-l',
                                    {
                                        'w-8 h-8': index != 0 && index < (metaDataPermissions.links.length - 1),
                                        'border-r rounded-r-md': index === (metaDataPermissions.links.length - 1),
                                        'rounded-l-md': index === 0
                                    },
                                    (link.active) ? 'bg-sky-500 text-white' : 'bg-white text-slate-600'
                                ]"
                                >
                                    <span v-html="link.label"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <DualRingLoader v-else-if="isLoading" />
                    <NoData v-else :search-keyword="filterDataPermissions.search" />
                </div>
            </main>
            <ModalDelete
            :id="currentPermission.id"
            :is-open="deletePermissionModalOpen"
            :title="$t('admin.permissions.modalRemovePermission.title')"
            :description="$t('admin.permissions.modalRemovePermission.description')"
            @closed="deletePermissionModalOpen = false"
            @delete="deletePermissionAPI($event)" />
            <ModalCreatePermission
            :is-open="newPermissionModalOpen"
            :is-edit="isPermissionEdit"
            :current-permission="currentPermission"
            :is-permission-loading="isLoading"
            :permissions="getPermissionsToCreateOrUpdate"
            @closed="modalPermissionClose"
            @submit-data="submitData"
            />
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import permissionsMixin from '@/mixins/permissions.js'

import Sidebar from '@/partials/Sidebar.vue'
import SearchForm from '@/components/SearchForm.vue'
import ButtonAdd from '@/components/ButtonAdd.vue'

import ModalCreatePermission from '@/components/ModalCreatePermission.vue'
import ModalDelete from '@/components/ModalDelete.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'
import NoData from '@/components/NoData.vue'
import DropdownNotifications from '@/components/DropdownNotifications.vue'
import DropdownProfile from '@/components/DropdownProfile.vue'
import DropdownLanguages from '@/components/DropdownLanguages.vue'
import DataTable from '@/components/DataTable.vue'

export default {
    name: 'AdminPermissionsPage',
    components: {
        Sidebar,
        SearchForm,
        ButtonAdd,
        ModalCreatePermission,
        ModalDelete,
        DualRingLoader,
        NoData,
        DropdownNotifications,
        DropdownProfile,
        DropdownLanguages,
        DataTable,
    },
    mixins: [globalMixin, permissionsMixin],
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()

        const sidebarOpen = ref(false)
        const newPermissionModalOpen = ref(false)
        const deletePermissionModalOpen = ref(false)
        const isPermissionEdit = ref(false)
        const currentPermission = ref({})

        return {
            t,
            store,
            toast,
            sidebarOpen,
            isPermissionEdit,
            currentPermission,
            deletePermissionModalOpen,
            newPermissionModalOpen,
        }
    },
    data: function () {
        return {
            dataTable: {
                headers: [
                    {
                        id: 'name',
                        label: this.$t('parameters.masterData.common.table.headers.name'),
                        column: {
                            key: 'name',
                        },
                    },
                    {
                        id: 'description',
                        label: this.$t('parameters.masterData.common.table.headers.description'),
                        column: {
                            key: 'description',
                        },
                        cellClass: '!text-left',
                    },
                    {
                        id: 'reference',
                        label: this.$t('admin.permissions.headerTable.reference'),
                        column: {
                            key: 'reference',
                        },
                    },
                    {
                        id: 'pattern',
                        label: this.$t('admin.permissions.headerTable.pattern'),
                        column: {
                            key: 'has_pattern',
                        },
                        isSlot: true,
                    },
                    {
                        id: 'created_at',
                        label: this.$t('parameters.masterData.common.table.headers.createdAt'),
                        column: {
                            funct: (value) => this.$filters.newDayjs(value).format('DD MMM YYYY - HH:mm'),
                            key: 'created_at',
                        }
                    },
                ]
            }
        }
    },
    mounted() {
        // this.checkUserAccess('REDEK_ADMIN')
        this.getAllPermissions()
    },
    methods: {
        changePage (link, index) {
            let newPage = null
            if (this.metaDataPermissions.links.length > 3) {
                if (index === 0 && this.metaDataPermissions.current_page != 1) {
                    /**
                     * Previous
                     */
                    newPage = this.metaDataPermissions.current_page - 1;
                } else if (
                    index === (this.metaDataPermissions.links.length - 1)
                    &&
                    this.metaDataPermissions.current_page != this.metaDataPermissions.last_page
                ) {
                    /**
                     * Next
                     */
                    newPage = this.metaDataPermissions.current_page + 1;
                } else if (link.label === '...') {
                    newPage = Number(this.metaDataPermissions.links[index - 1].label) + 1
                } else if (link.label && !isNaN(link.label)) {
                    newPage = Number(link.label)
                }
            }
            if (newPage) {
                this.filterDataPermissions.page = newPage
                this.getAllPermissions()
            }
        },
        changeNRecords (event) {
            this.filterDataPermissions.page = 1
            this.getAllPermissions()
        }
    },
    computed: {
        getPermissionsToCreateOrUpdate () {
            return this.permissions.filter(item => {
                if (
                    (
                        this.isPermissionEdit &&
                        this.currentPermission &&
                        item.id != this.currentPermission.id &&
                        (
                            !item.has_pattern ||
                            (
                                this.currentPermission && item.has_pattern.functionality.id != this.currentPermission.id
                            )
                        )
                    )
                    || !this.isPermissionEdit
                ) {
                    return item;
                }
            });
        }
    },
}
</script>
