<template>
    <div class="flex h-screen overflow-hidden">
        <!-- Sidebar -->
        <Sidebar
            :sidebar-open="sidebarOpen"
            :case-stage="
                activeCase.stage !== undefined
                    ? activeCase.stage
                    : $route.query.status
            "
            @close-sidebar="sidebarOpen = false"
        />

        <!-- Content area -->
        <div
            class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
        >
            <main>
                <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
                    <div class="flex w-full flex-col pb-4">
                        <h1 class="text-2xl font-bold text-black">
                            {{ $t('caseDetail.title') }}
                        </h1>
                        <p>{{ $t('caseDetail.description') }}</p>
                    </div>
                    <template v-if="!isLoading">
                        <!-- Case Details -->
                        <div class="rounded-lg bg-white px-6 shadow-sm">
                            <div class="py-6">
                                <div class="flex items-start">
                                    <div class="grow">
                                        <div
                                            class="items-start justify-between py-4 sm:flex"
                                        >
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                                            >
                                                <p>
                                                    {{
                                                        $t(
                                                            'caseDetail.details.caseName'
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                            <div
                                                class="mb-2 text-lg font-medium text-slate-800 sm:mb-0"
                                            >
                                                <p>{{ activeCase.name }}</p>
                                            </div>
                                        </div>

                                        <div
                                            v-if="
                                                activeCase.request !== null &&
                                                activeCase.request !== '' &&
                                                activeCase.request !== 'null'
                                            "
                                            class="items-start justify-between border-t py-4 sm:flex"
                                        >
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                                            >
                                                <p>
                                                    {{
                                                        $t(
                                                            'caseDetail.details.caseRequest'
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                            <div
                                                class="mb-2 text-lg font-medium text-slate-800 sm:mb-0"
                                            >
                                                <p>
                                                    {{ activeCase.request }}
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            v-if="activeCase.amount && activeCase.currency"
                                            class="items-start justify-between border-t py-4 sm:flex"
                                        >
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                                            >
                                                <p>
                                                    {{
                                                        $t(
                                                            'caseDetail.details.caseAmount'
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                            <div
                                            class="mb-2 text-lg font-medium text-slate-800 sm:mb-0"
                                            v-if="activeCase.amount && activeCase.currency"
                                            >
                                                <p>
                                                    <Currency
                                                        :total-in-string="
                                                            activeCase.amount
                                                        "
                                                        :with-prefix="false"
                                                        :leading-text="
                                                            activeCase.currency.code
                                                        "
                                                    />
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            class="items-start justify-between border-t py-4 sm:flex"
                                        >
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                                            >
                                                <p>
                                                    {{
                                                        $t(
                                                            'caseDetail.details.submissionDate'
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                            <div
                                                class="mb-2 text-lg font-medium text-slate-800 sm:mb-0"
                                            >
                                                <p>
                                                    {{
                                                        formatDateDay(
                                                            activeCase.time
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            class="items-start justify-between border-t py-4 sm:flex"
                                        >
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                                            >
                                                <p>
                                                    {{
                                                        $t(
                                                            'caseDetail.details.submittedBy'
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                            <div
                                                class="mb-2 text-lg font-medium text-slate-800 sm:mb-0"
                                            >
                                                <p
                                                    v-if="
                                                        activeCase.case_parties
                                                        &&
                                                        activeCase.case_parties.some(item => item.type === 'author')
                                                    "
                                                >
                                                    {{
                                                        getName(
                                                            activeCase.case_parties.find(item => item.type === 'author').user,
                                                            'name',
                                                            50
                                                        )
                                                    }}
                                                </p>
                                                <p v-else>-</p>
                                            </div>
                                        </div>

                                        <div
                                            class="items-center justify-between border-t py-4 sm:flex"
                                        >
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                                            >
                                                <p>
                                                    {{
                                                        $t(
                                                            'caseDetail.details.currentStage'
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                            <div
                                                class="mb-2 text-right text-lg font-medium text-yellow-500 sm:mb-0"
                                            >
                                                <p>{{ activeCase.status }}</p>

                                                <p
                                                    v-if="
                                                        lastDeadlineStage !==
                                                        null
                                                    "
                                                >
                                                    {{ lastDeadlineStage }}
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            class="items-center justify-between border-t py-4 sm:flex"
                                        >
                                            <div
                                                class="items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                                            >
                                                <p>
                                                    {{
                                                        $t(
                                                            'caseDetail.details.isWithAssistant'
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                            <div
                                                class="text-right text-lg font-medium sm:mb-0"
                                            >
                                                <p
                                                    v-if="
                                                        activeCase.with_assistant
                                                    "
                                                    class="text-green-500"
                                                >
                                                    {{ $t('yes') }}
                                                </p>
                                                <p v-else class="text-red-500">
                                                    {{ $t('no') }}
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            v-if="
                                                lastDeadlineTime !== null &&
                                                lastDeadlineStage !== null
                                            "
                                            class="items-start justify-between border-t py-4 sm:flex"
                                        >
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                                            >
                                                <p>
                                                    {{
                                                        $t(
                                                            'caseDetail.details.stageDuedate',
                                                            {
                                                                stage: lastDeadlineStage,
                                                            }
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                            <div
                                                class="mb-2 text-lg font-medium text-slate-800 sm:mb-0"
                                            >
                                                <p>
                                                    {{
                                                        formatDateDay(
                                                            lastDeadlineTime
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                        </div>

                                        <!-- <div
                                            v-if="activeCase.attachment"
                                            class="items-center justify-between border-t py-4 sm:flex"
                                            >
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                                            >
                                                <p>
                                                {{ $t('caseDetail.details.assignationDocument') }}
                                                </p>
                                            </div>
                                            <div
                                                class="mb-2 text-lg font-medium text-slate-800 sm:mb-0"
                                            >
                                                <button
                                                class="rounded-lg bg-sky-500 py-2 px-4 text-white"
                                                @click="openDocumentCase(activeCase.attachment)"
                                                >
                                                {{ $t('button.open') }}
                                                </button>
                                            </div>
                                        </div> -->

                                        <div
                                            class="items-start justify-between space-x-2 border-t py-4 sm:flex"
                                        >
                                            <div
                                                class="mb-2 items-center self-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                                            >
                                                <p>
                                                    {{
                                                        $t(
                                                            'caseDetail.details.caseDescription'
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                            <div
                                                class="mb-2 overflow-y-scroll text-right text-lg font-medium text-slate-800 sm:mb-0"
                                            >
                                                <p>
                                                    {{ activeCase.description }}
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            v-if="caseFileMapping.length > 0"
                                            class="w-full items-center justify-between border-t py-4 sm:flex"
                                        >
                                            <div
                                                class="mb-2 w-full items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                                            >
                                                <p>
                                                    {{
                                                        $t(
                                                            'caseDetail.details.assignationDocument'
                                                        )
                                                    }}
                                                </p>

                                                <div
                                                    class="mt-2 flex w-full max-w-sm space-x-2 overflow-x-scroll sm:max-w-lg md:max-w-xl lg:max-w-2xl xl:max-w-3xl"
                                                >
                                                    <FileItem
                                                        v-for="attachment in caseFileMapping"
                                                        :key="attachment.id"
                                                        :file="attachment"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Case Details -->

                        <!-- Parties -->
                        <div class="pt-10">
                            <h1 class="text-lg font-bold">
                                {{ $t('caseDetail.parties.title') }}
                            </h1>
                        </div>
                        <div
                            v-for="party in getPartiesCaseSorted"
                            :key="party.user.id"
                            class="mt-4 rounded-lg bg-white px-6 shadow-sm"
                        >
                            <div class="py-6">
                                <div class="flex flex-row py-4">
                                    <ImageLoader
                                        wrapper-class="mr-3 shrink-0 rounded-full"
                                        :src="party.user.avatar"
                                        width="60"
                                        height="60"
                                        alternate="partyImage"
                                    />
                                    <div class="pl-4">
                                        <p
                                            class="text-lg font-semibold text-slate-800"
                                        >
                                            {{ getName(party.user, 'name', 30) }}
                                        </p>
                                        <p class="text-sm text-slate-500">
                                            {{ party.user.email }}
                                        </p>
                                    </div>
                                </div>
                                <div class="flex items-start">
                                    <div class="grow">
                                        <div
                                            class="items-start justify-between py-4 sm:flex"
                                        >
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                                            >
                                                <p>
                                                    {{
                                                        $t(
                                                            'caseDetail.parties.role'
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                            <div
                                                class="mb-2 text-lg font-medium text-slate-800 sm:mb-0"
                                                v-if="party.type"
                                            >
                                                <p>{{ $t($store.getters['cases/getpartyTypes'].find(item => item.key === party.type).translateReference) }}</p>
                                            </div>
                                        </div>
                                        <div
                                            class="items-start justify-between border-t py-4 sm:flex"
                                        >
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                                            >
                                                <p>
                                                    {{
                                                        $t(
                                                            'caseDetail.parties.phoneNumber'
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                            <div
                                                class="mb-2 text-lg font-medium text-slate-800 sm:mb-0"
                                            >
                                                <p>{{ party.user.phone ?? '-' }}</p>
                                            </div>
                                        </div>
                                        <div
                                            class="items-start justify-between border-t py-4 sm:flex"
                                        >
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                                            >
                                                <p>
                                                    {{
                                                        $t(
                                                            'caseDetail.parties.email'
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                            <div
                                                class="mb-2 text-lg font-medium text-slate-800 sm:mb-0"
                                            >
                                                <p>{{ party.user.email ?? '-' }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                v-if="party.was_reassigned"
                                class="bg-yellow-100 py-2 px-6 -mx-6 -mb-6 rounded-b-lg flex flex-col gap-1">
                                    <div class="flex justify-between">
                                        <span class="font-bold text-slate-500">
                                            {{ $t('caseDetail.parties.reassignment.reassigned') }}
                                        </span>
                                        <span>
                                            {{ $filters.formattedDate(party.reassigned_at, 'DD MMMM YYYY - HH:mm') }}
                                        </span>
                                    </div>
                                    <div class="flex gap-2">
                                        <span>
                                            {{ $t('caseDetail.parties.reassignment.reason') }}:
                                        </span>
                                        <p>
                                            {{ party.reassignment_reason }}
                                        </p>
                                    </div>
                                    <div
                                    v-if="party.document_reassignment"
                                    class="flex gap-2">
                                        <span>
                                            {{ $t('caseDetail.parties.reassignment.attachment') }}:
                                        </span>
                                        <div v-if="party.document_reassignment.files.length">
                                            <a
                                            target="_blank"
                                            class="text-sky-500"
                                            :href="party.document_reassignment.files[0].file">
                                                {{ party.document_reassignment.name }}
                                            </a>
                                        </div>
                                    </div>
                                    <div
                                    v-if="party.user_reassigned && party.user_reassigned"
                                    class="flex items-center gap-2">
                                        <span>
                                            {{ $t('caseDetail.parties.reassignment.by') }}:
                                        </span>
                                        <div class="flex items-center">
                                            <ImageLoader
                                            wrapper-class="mr-3 shrink-0 rounded-full"
                                            :src="party.user_reassigned.avatar"
                                            width="30"
                                            height="30"
                                            alternate="partyImage"
                                            />
                                            <span>
                                                {{ party.user_reassigned.name }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex w-full justify-end">
                                    <div
                                    v-if="party.is_reassignment && !party.was_reassigned"
                                    class="bg-sky-500 -mx-6 -mb-6 rounded-br-lg rounded-tl-lg text-white py-1 px-2 w-24 text-center"
                                    >
                                        {{ $t('caseDetail.parties.reassignment.itis') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Parties -->

                        <!-- First offers -->
                        <div class="pt-10">
                            <h1 class="text-lg font-bold">
                                {{ $t('caseDetail.firstOffers.title') }}
                            </h1>
                        </div>
                        <div v-if="
                            activeCase.chats_with_first_offer
                            &&
                            activeCase.chats_with_first_offer.length
                        "
                        class="py-2"
                        >
                            <div
                            v-for="(chat, index) in activeCase.chats_with_first_offer"
                            :key="index"
                            :class="[
                                'p-6 rounded-md',
                                (chat.offers && chat.offers.length) ? 'block' : 'hidden',
                                {
                                    'bg-green-100': chat.stage == 2,
                                    'bg-yellow-100': chat.stage == 3,
                                    'bg-red-100': chat.stage == 4,
                                }
                            ]"
                            >
                                <div
                                v-if="chat.offers.length"
                                class="flex flex-col">
                                    <div class="flex justify-between">
                                        <span class="font-bold text-slate-500">
                                            {{ chat.title }}
                                        </span>
                                        <div class="flex flex-col items-end">
                                            <div class="flex items-center">
                                                <ImageLoader
                                                wrapper-class="mr-3 shrink-0 rounded-full"
                                                :src="chat.offers[0].user_created.avatar"
                                                width="25"
                                                height="25"
                                                alternate="partyImage"
                                                />
                                                <span>
                                                    {{ chat.offers[0].user_created.name }}
                                                </span>
                                            </div>
                                            <div class="italic text-sm">
                                                {{ $filters.formattedDate(chat.offers[0].created_at, 'DD MMMM YYYY - HH:mm') }}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <span class="font-bold text-slate-500 text-sm">
                                            {{ $t('caseDetail.firstOffers.offer.title') }}:
                                        </span>
                                        <span>
                                            {{ chat.offers[0].title }}
                                        </span>
                                    </div>
                                    <div>
                                        <span class="font-bold text-slate-500 text-sm">
                                            {{ $t('caseDetail.firstOffers.offer.content') }}:
                                        </span>
                                        <span>
                                            {{ chat.offers[0].content }}
                                        </span>
                                    </div>
                                    <div>
                                        <span class="font-bold text-slate-500 text-sm">
                                            {{ $t('caseDetail.firstOffers.offer.request') }}:
                                        </span>
                                        <span>
                                            {{ chat.offers[0].request }}
                                        </span>
                                    </div>
                                    <div>
                                        <span class="font-bold text-slate-500 text-sm">
                                            {{ $t('caseDetail.firstOffers.offer.amount') }}:
                                        </span>
                                        <span v-if="chat.offers[0].currency && chat.offers[0].amount">
                                            {{ chat.offers[0].currency.code + ' ' + chat.offers[0].amount }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <DualRingLoader v-else />
                </div>
            </main>
        </div>

        <RightSidebar
            :sidebar-open="false"
            @close-sidebar="sidebarOpen = false"
        />
    </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'

import Sidebar from '@/partials/Sidebar.vue'
import RightSidebar from '@/partials/RightSidebar.vue'
import FileItem from '@/components/FileItem.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'
import ImageLoader from '@/components/image/ImageLoader.vue'
import Currency from '@/components/currency/Currency.vue'

export default {
    name: 'CaseDetailPage',
    components: {
        Sidebar,
        RightSidebar,
        FileItem,
        DualRingLoader,
        ImageLoader,
        Currency,
    },
    mixins: [globalMixin, caseMixin],
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()
        const sidebarOpen = ref(false)
        const activeCase = ref({})

        return {
            t,
            n,
            store,
            toast,
            sidebarOpen,
            activeCase,
        }
    },
    mounted() {
        this.getCaseDetail()
        this.getFileCase({ case_id: this.$route.params.slug, type: 'INITIAL' })
    },
    computed: {
        getPartiesCaseSorted () {
            return this.activeCase && this.activeCase.case_parties
                ? this.activeCase.case_parties.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                : []
        }
    }
}
</script>
