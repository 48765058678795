<template>
    <div class="-m-1.5 flex flex-wrap items-center">
        <div class="m-1.5">
            <ModalBasic
            id="notes-modal"
            :modal-open="isOpen"
            :show-border-header="false"
            :title="$t('newCase.preCreated.partySelection.modal.title')"
            :subtitle="$t('newCase.preCreated.partySelection.modal.description', { party_type: getPartyName })"
            :backdrop="true"
            @close-modal="close"
            >
                <div class="px-5">
                    <VueMultiselect v-model="partySelected" :options="list" :multiple="false" :close-on-select="true"
                        :placeholder="$t('newCase.preCreated.claimant.placeholder')" label="name" track-by="name"
                        :allow-empty="true">
                        <template #singleLabel="props">
                            <span class="option__desc">
                                <span class="option__title">
                                    {{ props.option.name }}
                                </span>
                            </span>
                        </template>
                        <!-- <template #option="props">
                            <div class="option__desc space-x-2">
                                <div class="option__small inline rounded-lg px-2 py-1 text-xs font-bold">
                                    {{ props.option.identification }}
                                </div>
                                -
                                <span class="option__title">
                                    {{ props.option.name }}
                                </span>
                            </div>
                        </template> -->
                    </VueMultiselect>
                </div>
                <div
                v-if="current || partySelected"
                class="text-sm m-4 p-2 rounded bg-sky-100 flex justify-between"
                >
                    <div>
                        <span>
                            {{ $t('selected') }}:
                        </span>
                        <span v-if="!current && partySelected">
                            {{ partySelected.name }}
                        </span>
                        <span v-else-if="current && !partySelected && list.some(item => item.id === current)">
                            {{ list.find(item => item.id === current).name }}
                        </span>
                    </div>
                    <button
                    @click="removeCurrent"
                    class="bg-red-300 text-slate-500 font-bold text-xs p-1 rounded-lg"
                    >
                        {{ $t('remove') }}
                    </button>
                </div>
                <div class="py-4">
                    <div class="flex flex-wrap justify-center space-x-2 px-4">
                        <button @click="select" type="button"
                            class="btn-sm w-full whitespace-nowrap rounded-lg bg-sky-500 p-3 text-white hover:bg-sky-600">
                            {{ $t('button.accept') }}
                        </button>
                    </div>
                </div>
            </ModalBasic>
        </div>
    </div>
</template>
<script>
import ModalBasic from '@/components/ModalBasic.vue'
import { useToast } from 'vue-toastification'

export default {
    name: 'ModalChooseParty',
    components: {
        ModalBasic,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        partyType: {
            type: String,
            default: 'advocate'
        },
        required: {
            type: Boolean,
            default: true
        },
        current: {
            type: Object,
            default: null
        }
    },
    setup() {
        const toast = useToast()
        return {
            toast,
        }
    },
    emits: ['closed', 'select', 'remove-current'],
    data: function () {
        return {
            partySelected: null,
            list: [],
        }
    },
    watch: {
        isOpen(val) {
            if (!val) this.close()
        },
        partyType(val) {
            if (val) {
                this.getByType()
            }
        },
    },
    computed: {
        getPartyName() {
            const partiesName = {
                advocate: this.$t('general.advocate').toLowerCase(),
                bank_lawyer: this.$t('general.bankLawyer').toLowerCase(),
                mediator: this.$t('general.mediator').toLowerCase(),
                arbitrator: this.$t('general.arbitrator').toLowerCase(),
            };
            return partiesName[this.partyType];
        }
    },
    mounted() {
        this.getByType()
    },
    methods: {
        select() {
            if (this.required && !this.partySelected) {
                this.toast.error(this.$t('newCase.preCreated.errors.' + this.partyType))
                return;
            } else if (!this.partySelected) {
                this.partySelected = null
                this.$emit('select', null)
            } else {
                this.$emit('select', this.partySelected)
                this.partySelected = null
            }
        },
        getByType() {
            if (this.partyType) {
                if (this.partyType === 'advocate') this.getAllAdvocate()
                if (this.partyType === 'bank_lawyer') this.getAllBankLawyer()
                if (this.partyType === 'mediator') this.getAllMediator()
                if (this.partyType === 'arbitrator') this.getAllArbitrator()
            }
        },
        close() {
            this.$emit('closed')
        },
        getAllAdvocate() {
            this.$store.dispatch('advocate/getAllAdvocate', {
                order: 'desc',
                page: 1,
                take: 200,
                q: null,
            })
                .then((response) => {
                    const responseData = response.data
                    if (responseData.data) {
                        this.list = responseData.data
                    }
                })
                .catch((error) => {
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        getAllBankLawyer() {
            this.$store.dispatch('bank/getAllBankLawyer', {
                order: 'desc',
                page: 1,
                take: 200,
                q: null,
            })
                .then((response) => {
                    const responseData = response.data
                    if (responseData.data) {
                        this.list = responseData.data
                    }
                })
                .catch((error) => {
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        getAllMediator() {
            this.$store.dispatch('mediator/getAllMediator', {
                order: 'desc',
                page: 1,
                take: 200,
                q: null,
            })
                .then((response) => {
                    const responseData = response.data
                    if (responseData.data) {
                        this.list = responseData.data
                    }
                })
                .catch((error) => {
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        getAllArbitrator() {
            this.$store.dispatch('arbitrator/getAllArbitrator', {
                order: 'desc',
                page: 1,
                take: 200,
                q: null,
            })
                .then((response) => {
                    const responseData = response.data
                    if (responseData.data) {
                        this.list = responseData.data
                    }
                })
                .catch((error) => {
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        removeCurrent () {
            if (!this.current && this.partySelected) this.partySelected = null
            else if (this.current && !this.partySelected) this.$emit('remove-current')
        },
    },
}
</script>
