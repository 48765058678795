import CryptoJS from "crypto-js";

export default {
    install: (app, options) => {
        app.config.globalProperties.$methods = {
            decrypt(data) {
                if (import.meta.env.VITE_DECRYPT_ENABLE === "true") {
                    var Sha256 = CryptoJS.SHA256;
                    var Hex = CryptoJS.enc.Hex;
                    var Utf8 = CryptoJS.enc.Utf8;
                    var Base64 = CryptoJS.enc.Base64;
                    var AES = CryptoJS.AES;
                    let key = Sha256(import.meta.env.VITE_DECRYPT_KEY)
                        .toString()
                        .toString(Hex)
                        .substr(0, 32);
                    let iv = Sha256(import.meta.env.VITE_DECRYPT_IV)
                        .toString()
                        .toString(Hex)
                        .substr(0, 16);
                    // Encryption
                    try {
                        var output = AES.decrypt(atob(data), Utf8.parse(key), {
                            iv: Utf8.parse(iv),
                        }).toString(Utf8); // First Base64 encoding, by default (see 1.)
                        return JSON.parse(output);
                    } catch (error) {
                        return null;
                    }
                }
                return data;
            },
            encrypt(data) {
                if (import.meta.env.VITE_DECRYPT_ENABLE === "true") {
                    var Sha256 = CryptoJS.SHA256;
                    var Hex = CryptoJS.enc.Hex;
                    var Utf8 = CryptoJS.enc.Utf8;
                    var Base64 = CryptoJS.enc.Base64;
                    var AES = CryptoJS.AES;
                    let key = Sha256(import.meta.env.VITE_DECRYPT_KEY)
                        .toString()
                        .toString(Hex)
                        .substr(0, 32);
                    let iv = Sha256(import.meta.env.VITE_DECRYPT_IV)
                        .toString()
                        .toString(Hex)
                        .substr(0, 16);
                    // Encryption
                    var output = AES.encrypt(
                        JSON.stringify(data),
                        Utf8.parse(key),
                        {
                            iv: Utf8.parse(iv),
                        }
                    ).toString(); // First Base64 encoding, by default (see 1.)
                    var output2ndB64 = Utf8.parse(output).toString(Base64); // Second Base64 encoding (see 1.)
                    return output2ndB64;
                }
                return data;
            },
            hasPermissions (permissions = [], conjunction = false) {
                const redekPermissions = JSON.parse(atob(localStorage.getItem('redek.permissions')));
                let has = false;
                if (conjunction) {
                    permissions.map(item => {
                        if (!redekPermissions.some(elem => elem.functionality.reference === item)) {
                            has = false;
                        }
                    });
                } else {
                    has = permissions.some(item => redekPermissions.some(elem => elem.functionality.reference === item));
                }
                return has;
            },
            oneUserHasPermissions (user, permissions) {
                let has = false;
                if (user && user.role && user.role.permissions) {
                    has = permissions.some(item => user.role.permissions.some(elem => elem.functionality.reference === item));
                }
                return has;
            },
            extractErrorResponse (error, t = null) {
                if (error.response) {
                    if (
                        error.response.data &&
                        error.response.data.message &&
                        error.response.data.message.detail
                    ) {
                        if (Array.isArray(error.response.data.message.detail)) {
                            return error.response.data.message.detail[0]
                        } else {
                            return error.response.data.message.detail
                        }
                    } else if (error.response.data && error.response.data.message) {
                        return error.response.data.message
                    } else if (error.response.data && error.response.data.error) {
                        return error.response.data.error
                    } else if ('statusText' in error.response) {
                        return error.response.statusText
                    }
                } else if (t) {
                    return t('message.failed.ise')
                }
            }
        };
    },
};
