import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'
import { encrypt } from '../../utils/Utils.js'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
    getAllAdvocate({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {}
            if (payload.page) params.page = payload.page
            if (payload.take) params.per_page = payload.take
            if (payload.order) params.order = 'created_at.' + payload.order
            if (payload.q) params.search = payload.q
            params.reassignment = payload.reassignment
            const addStringRoute = (payload.reassignment) ? '/reassignment/' + payload.case_id : ''

            axios.get(`${routePathAPI()}/users/bySystemProfile/${ encrypt('advocate') }${ addStringRoute }`, {
                params: params,
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            }).then(async (response) => {
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
