<template>
    <div class="flex h-screen overflow-hidden">
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <!-- Site header -->
            <Header :sidebar-open="sidebarOpen" @toggle-sidebar="sidebarOpen = !sidebarOpen" />

            <main>
                <div class="w-full">
                    <div class="flex h-full flex-col justify-center bg-gray-100 md:mt-10">
                        <div class="w-full self-center px-2 py-5 text-left md:w-8/12 md:py-2 md:px-0">
                            <BreadcrumNewCase :active="2" :is-show="true" />
                        </div>
                        <div class="w-full self-center rounded-lg bg-white px-8 py-8 shadow-md md:w-8/12">
                            <h1 class="mb-2 text-3xl font-bold text-slate-800">
                                {{ $t('advocateRequirement.title') }}
                            </h1>
                            <p class="text-sm">
                                {{ $t('advocateRequirement.description') }}
                            </p>
                            <div
                                class="grid grid-cols-1 gap-4 md:px-4 md:gap-8 md:grid-cols-2 w-full border-gray-500 pt-6 text-center font-bold">
                                <div class="flex w-full">
                                    <div
                                    class="h-80 w-full cursor-pointer rounded-xl border p-6"
                                    :class="typeSelected === 1 ? 'border-sky-500' : ''"
                                    @click="typeSelected = 1"
                                    id="form_create_cases_advocate_req_opt_1_action"
                                        >
                                        <ProfileIcon class="mx-auto mb-8 w-52 text-sky-500" />
                                        {{ $t('advocateRequirement.letRedek') }}
                                    </div>
                                </div>
                                <div class="flex w-full">
                                    <div
                                    id="form_create_cases_advocate_req_opt_2_action"
                                    class="h-80 w-full cursor-pointer rounded-xl border p-6"
                                        :class="typeSelected === 2 ? 'border-sky-500' : ''" @click="typeSelected = 2">
                                        <PaperIcon class="mx-auto mb-8 w-52 text-sky-500" />
                                        {{ $t('advocateRequirement.pickOne') }}
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5 flex flex-row justify-end">
                                <div class="w-3/12">
                                    <router-link
                                    id="form_create_cases_advocate_req_back_action"
                                        class="btn text-md mt-6 h-12 w-full rounded-md text-slate-500 shadow-none hover:text-slate-700"
                                        to="/new-case">
                                        <svg class="mr-2 h-7 w-7 fill-current text-slate-500" viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
                                        </svg>
                                        {{ $t('action.back') }}
                                    </router-link>
                                </div>
                                <button
                                id="form_create_cases_advocate_req_next_action"
                                class="btn text-md mt-6 h-12 w-3/12 rounded-md bg-sky-500 text-white hover:bg-sky-600"
                                @click="nextPage">
                                    {{ $t('action.next') }}
                                </button>
                            </div>
                        </div>
                        <DualRingLoader v-if="isLoading" />
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import Header from '@/partials/Header.vue'
import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'

import BreadcrumNewCase from '@/components/case/BreadcrumNewCase.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'

import PaperIcon from '@/assets/svg/paper.svg'
import ProfileIcon from '@/assets/svg/profile.svg'

export default {
    name: 'AdvocateRequirementPage',
    components: {
        Header,
        BreadcrumNewCase,
        DualRingLoader,

        PaperIcon,
        ProfileIcon,
    },
    mixins: [globalMixin, caseMixin],
    setup() {
        const sidebarOpen = ref(false)
        const store = useStore()
        const toast = useToast()

        return {
            store,
            toast,
            sidebarOpen,
        }
    },
    data() {
        return {
            typeSelected: 2,
        }
    },
    mounted() {
        this.newCaseData = this.store.getters['cases/newCase']
        if (this.checkEmptyObject(this.newCaseData)) {
            return this.$router.push({ name: 'index' })
        }
    },
    methods: {
        nextPage() {
            if (this.typeSelected === 1) {
                if (!this.isLoading) {
                    this.newCaseData.advocate_initiate = true
                    this.newCaseData.advocate_user_id = ''
                    this.store.dispatch('cases/setNewCase', this.newCaseData)
                    this.store.dispatch('cases/setActiveNewCaseBreadcrum', 3)
                    this.$router.push({
                        name: 'review-application',
                        query: {
                            lang: this.$route.query.lang,
                            assist: this.$route.query.assist,
                        },
                    })
                }
            } else {
                return this.$router.push({ name: 'advocate-selection' })
            }
        },
    },
}
</script>
