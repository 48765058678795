export default {
	"appName": "Redek",
	"noData": "There is no data available for this time",
	"noPendingAction": "There are no pending actions",
	"noRecentAction": "You have not done anything yet",
	"noActivities": "There are no activities",
	"hourAgo": "hours ago",
	"you": "(You)",
	"updated": "Updated",
	"yes": "Yes",
	"no": "No",
	"system": {
		"documents": {
			"case_x": {
				"case_record": {
					"this": "Case Record",
					"requested_documents": {
						"this": "Requested Documents"
					},
					"reassignments": {
						"this": "Attachments for Party Reassignments"
					},
					"timeline": {
						"this": "Timeline"
					},
					"initial_stage": {
						"this": "Initial Stage"
					},
					"negotiation_stage": {
						"this": "Negotiation Stage"
					},
					"mediation_stage": {
						"this": "Mediation Stage"
					},
					"arbitration_stage": {
						"this": "Arbitration Stage"
					}
				},
				"case_call_recordings": {
					"this": "Case Call Recordings"
				}
			}
		}
	},
	"language": {
		"languages": "Languages",
		"change": "Change Language",
		"id": "ID",
		"en": "EN",
		"pt": "PT",
		"es": "ES",
		"bahasa": "Indonesia",
		"english": "English",
		"portuguese": "Portuguese",
		"spanish": "Spanish"
	},
	"menu": {
		"dashboard": "Dashboard",
        "parameters": "Parameters",
		"caseStage": "Case Stage",
		"privateNotebook": "Private Notebook",
		"communication": {
			"title": "Communication",
			"chats": "Chats"
		},
		"documents": "Documents",
		"timeline": "Timeline",
		"calendar": "Calendar",
		"caseDetails": "Case Details",
		"finalDecision": "Final Decision",
		"user": "Users",
		"security": {
			"title": "Security",
			"users": "Users",
			"roles": "Roles",
			"permissions": "Permissions"
		},
		"cases": {
			"title": "Cases",
			"allCase": "All Case",
			"pendingCase": "Pending Case",
			"ongoingCase": "Ongoing Case",
			"settledCase": "Settled Case"
		},
		"inbox": {
			"title": "Inbox",
			"allInbox": "All Inbox",
			"unanswered": "Unanswered",
			"answered": "Answered",
			"archived": "Archived"
		},
		"logout": "Logout"
	},
	"action": {
		"back": "Back",
		"previous": "Previous",
		"next": "Next",
		"finish": "Finish",
		"letsGo": "Let's go!",
		"startODR": "Start ODR",
		"accept": "Accept",
		"decline": "Decline"
	},
	"activity": {
		"all": "All activity",
		"external": "External",
		"mail": "Mail Activity",
		"stage": "Stage Activity"
	},
	"about": "About",
	"edit": "Edit",
	"traceability": "Traceability",
	"remove": "Remove",
    "selected": "Selected",
    "selectedParties": "Selected parts",
	"forms": {
		"uploadFile": "Upload file",
		"dragAndDrop": "",
		"or": "",
		"browseFiles": "Browse files"
	},
	"general": {
		"greeting": "Welcome!",
		"hello": "Hello",
		"share": "Share",
		"tnc": "Terms & Conditions",
		"privacyPolicy": "Privacy Policy",
		"optional": "optional",
        "user": "User",
		"advocate": "Advocate",
		"mediator": "Mediator",
		"arbitrator": "Arbitrator",
		"bankLawyer": "Bank Lawyer",
		"totalParties": "%{total} Parties",
		"totalAttachments": "%{total} Attachments",
		"loading": "Loading....",
		"pronouns": {
			"you": "You"
		},
		"agreement": "Agreement",
		"download": "Download",
		"enabled": "Enabled",
		"disabled": "Disabled",
		"meeting": "Meeting",
        "select": "Select",
        "columns": {
            "name": "Name",
            "ratings": "Ratings",
            "case_handled": "Case handled",
            "ongoing_cases": "Ongoing cases"
        },
        "all": "All",
        "search": "Search",
        "records": "Records",
	},
	"info": {
		"dataNotFound": "Data not found",
		"loginGreeting": "A few more clicks to sign in to your account.",
		"loginGreetingDetail": "Manage your accounts in one place",
		"currentVersion": "Current Version Dashboard",
		"pageMissing": "Oops. This page has gone missing",
		"pageMissingInstruction": "You may have mistyped the address or the page may have moved.",
		"successResendOTP": "Succesfully resend the OTP"
	},
	"confirmation": {
		"logout": "Are you sure want to logout?"
	},
	"button": {
		"submit": "Submit",
		"login": "Login",
		"signUp": "Sign Up",
		"logout": "Logout",
		"cancel": "Cancel",
		"register": "Register",
		"sendEmail": "Send Email",
		"sendOTP": "Send OTP",
		"resetPassword": "Reset Password",
		"forgotPassword": "Forgot Password?",
		"loginInstead": "Login Instead",
		"change": "Change",
		"saveChanges": "Save Changes",
		"connect": "Connect",
		"connected": "Connected",
		"backToHome": "Back to Home",
		"delete": "Delete",
		"remove": "Remove",
		"open": "Open",
		"assign": "Assign",
		"review": "Review",
		"resolveTheCase": "Resolve the case",
		"confirm": "Confirm",
		"pick": "Pick %{role}",
		"save": "Save",
		"accept": "Accept",
		"reject": "Reject",
		"shareToThisEmail": "Share to this email",
		"shareToBankLawyer": "Share to bank lawyer",
		"inspectCase": "Inspect Case",
		"endInspect": "End Inspect",
		"timerExtension": "Ask for extension time",
		"response": "Response",
		"import": "Import",
		"enable": "Enable",
		"disable": "Disable"
	},
	"badge": {
		"accepted": "Accepted",
		"rejected": "Rejected",
		"pending": "Pending"
	},
	"formInfo": {
		"notLogin": "You are not logged in",
		"registerSuccess": "You have successfully registered",
		"registerFailed": "Registration failed",
		"loginSuccess": "Login Success",
		"loginFailed": "Login Failed",
		"saveChangesSuccess": "Changes Saved Successfully",
		"saveChangesFailed": "Changes Failed to Save",
		"loginDesc": "Enter your email and password",
		"loginCheck": "By signin up, you agree to our"
	},
	"formLabel": {
		"name": "Nama",
		"firstName": "First Name",
		"lastName": "Last Name",
		"secondName": "Second Name",
		"fullName": "Full Name",
		"bussinessName": "Bussiness Name",
		"email": "Email Address",
		"phone": "Phone Number",
		"currentPassword": "Current Password",
		"password": "Password",
		"passwordConfirmation": "Confirm Password",
		"oldPassword": "Old Password",
		"newPassword": "New Password",
		"newPasswordConfirmation": "New Password Confirmation",
		"accessRight": "Access Right",
		"pin": "OTP",
		"description": "Description",
		"website": "Website",
		"province": "Province",
		"city": "City",
		"district": "District",
		"village": "Village",
		"postalCode": "Postal Code",
		"address": "Address",
		"select": {
			"province": "Select Province",
			"city": "Select City",
			"district": "Select District",
			"village": "Select Village",
			"none": "None"
		},
		"sortBy": "Sort By :"
	},
	"formInput": {
		"enterName": "Please enter your name",
		"enterFirstName": "Please enter your first name",
		"enterLastName": "Please enter your last name",
		"enterSecondName": "Please enter your second name",
		"enterFullName": "Please enter your full name",
		"enterBussinessName": "Please enter your bussiness name",
		"enterDescription": "Please enter desription",
		"enterWebsite": "Please enter website",
		"enterEmail": "Please enter your email",
		"enterPhone": "Please enter your phone number",
		"enterPassword": "Please enter your password",
		"enterConfirmPassword": "Please enter same as your password",
		"enterCurrentPassword": "Please enter your current password",
		"enterNewPassword": "Please enter your new password",
		"enterNewConfirmPassword": "Please enter same as your new password",
		"enterPIN": "Please enter your PIN",
		"enterAddress": "Please enter your address",
		"enterPostalCode": "Please enter postal code"
	},
	"formError": {
		"name": {
			"required": "Name is required"
		},
		"fullName": {
			"required": "Full Name is required"
		},
		"bussinessName": {
			"required": "Bussiness Name is required"
		},
		"tenantName": {
			"required": "Tenant Name is required"
		},
		"email": {
			"required": "Email is required",
			"validEmail": "Email must be valid email address"
		},
		"phone": {
			"required": "Phone number is required"
		},
		"pin": {
			"required": "PIN is required"
		},
		"address": {
			"required": "Address is required"
		},
		"postalCode": {
			"required": "Postal Code is required"
		},
		"password": {
			"required": "Password is required",
			"minLength": "Password at least 6 characters",
			"alphaNumeric": "Password only allowed alphanumeric and special characters"
		},
		"wrongEmailOrPassword": "Wrong email or password",
		"validEmailAddress": "This field must be valid email address",
		"validPhoneNumber": "Please enter valid WhatsApp number, min 10 digit numbers",
		"fieldAlphabet": "must only contain alphabetic characters",
		"fieldAlphaNumeric": "must only contain alphanumeric characters",
		"fieldNumeric": "must only contain numeric characters"
	},
	"login": {
		"title": "Login",
		"already": "If you already have an account, please fill the required form below. If you haven`t had an account yet",
		"signUp": "Signup Here",
		"enterOTP": "Enter OTP",
		"weSentOTPByPhone": "Enter the code we have sent you via your phone %{phone}",
		"weSentOTP": "Enter the code we have sent you via your E-Mail %{email}",
		"didNotReceive": "Didn’t receive the OTP?",
		"resendCode": "Resend Code",
		"doesntHaveRequiredPermissions": "You do not have required permissions to access",
		"loginSuccessfully": "You have successfully logged in"
	},
	"register": {
		"title": "Sign Up",
		"already": "Already have an account?",
		"signIn": "Sign in"
	},
	"forgotPassword": {
		"title": "Forgot Password",
		"description": "Please enter your email address to reset password"
	},
	"resetPassword": {
		"title": "Reset Password",
		"description": "Please enter your new password",
		"successEmailSend": "We have sent you an email with instructions to reset your password"
	},
	"verificationOTP": {
		"wait": "Wait %{timer}s to resend the code"
	},
	"case": {
		"title": "Case Selection",
		"caseSelection": "Case Selection",
		"allCases": "All Cases",
		"onPrepare": "On Prepare",
		"onGoing": "On Going",
		"solved": "Solved",
		"yourCase": "Your Cases",
		"shareToYou": "Share to You",
		"parties": "Parties",
		"newComplaint": "New Complaint",
		"applicationForm": "Application Form",
		"advocateSelect": "Advocate Select",
		"reviewApplication": "Review Application",
		"advocateRequirement": "Advocate Requirement",
		"advocateSelection": "Advocate Selection",
		"caseSubmitted": "Case Submitted",
		"highUrgency": "High Urgency",
		"mediumUrgency": "Medium Urgency",
		"lowUrgency": "Low Urgency",
		"cannotEnabledToAccess": "You are not enabled to access to this case",
		"import": {
			"modal": {
				"title": "Import cases",
				"description": "Provide the file with the data of the cases which will be imported.",
				"template": {
					"alert": "Template:"
				},
				"file": {
					"label": "File"
				},
				"pleaseSelectFile": "Please choose the file",
				"result": {
					"failed": {
						"title": "Case import canceled due to failures in the provided data.",
						"information1": "The row to which these errors correspond is row",
						"information2": "Please check them and try again."
					}
				},
				"params": {
					"alert": "These are the areas and types of cases in the system; please take them into account when filling out the information in the spreadsheet.",
					"caseAreas": "Case areas",
					"caseTypes": "Case types"
				}
			},
			"success": "Cases imported successfully"
		},
		"dueTime": "Stage due time",
		"setDateTime": "Set date and time",
		"backToCase": "Back to Case",
		"concludedCase": "Concluded Case",
		"canWeHelpYou": "Can we help you today?",
		"shareConcluded": "Share Concluded Case",
		"selectBankLawyer": "Select Bank Lawyer",
		"selectBankLawyerDesc": "Please select Bank Lawyer to share the case",
		"stages": {
			"on_prepare": "On prepare",
			"negotiation": "Negotiation",
			"mediation": "Mediation",
			"arbitration": "Arbitration",
			"solved": "Solved"
		},
		"partyReassignment": {
			"label": "Reason for reassignment",
			"placeholder": "Describe the reason for the reassignment",
			"error": "Please specify the reason for reassigning the party",
			"attachment": {
				"label": "Attachment for reassignment"
			}
		},
        "delete": {
            "success": "Case deleted successfully"
        }
	},
	"newCase": {
		"how": "How do you want to submit your complaint?",
		"doItMySelf": "I’ll do it myself",
		"iNeedAssistance": "I need assistance",
		"title": "New Complaint",
		"description": "Please describe your case by filling the form below",
		"caseName": "Please fill the name of your complaint",
		"caseNamePlaceholder": "Case Name",
		"caseFrom": "From which bank do you want to complaint?",
		"caseWhen": "When was the case happened?",
		"moneyIncluded": "Does this case include money?",
		"caseWhenPlaceholder": "Time",
		"caseHowMuch": "How much money do you want to claim?",
		"caseHowMuchPlaceholder": "Amount of money",
		"caseRequest": "What is your request?",
		"caseRequestPlaceholder": "Your Request",
		"bySubmit": "By submitting this form, you agree to be contacted regarding your request, and you are confirming you agree to our",
		"and": "and",
		"terms": "Terms of Use",
		"privacyPolicy": "Privacy Policy",
		"pleaseCheckAggreement": "Please check the agreement",
        "pleaseSelectCurrency": "Please select the currency",
		"pleaseSelectAdvocate": "Please select an Advocate",
		"pleaseSelectMediator": "Please select a Mediator",
		"pleaseSelectBankLawyer": "Please select a Bank Lawyer",
		"failedGetCurrent": "Failed to get current case",
		"typeOfPart": "Type Of Part",
		"caseArea": "Case Area",
		"caseDescription": "Please describe the case",
		"caseDescriptionPlaceholder": "Description",
		"caseNotification": "How would you like to receive notifications?",
		"caseAcceptNotifyCaseEmail": "I would like to receive notifications via email",
		"email": "Through Email",
		"system": "Through System",
		"odrDesc": "ODR Description",
		"amountOfProcess": "Amount of The Process",
		"requestOfProcess": "Request of The Process",
		"estimatedStartDate": "Estimated Start Date",
		"descriptionOnDemands": "Description of the demands",
		"partOfProcess": "Part of the Process",
		"partOfProcessDesc": "Summary regarding your case",
		"notificationMethod": "Notificaton Method",
		"applicationSummary": "Application Summary",
		"applicationSummaryDesc": "Please review your application before submitting them",
		"haveRequestQuestion": "Do you have a request?",
		"preCreated": {
			"applicationForm": "Create case",
			"description": "Please describe the case by filling the form below",
			"caseName": "Please fill the name of the complaint",
			"caseFrom": "Which bank does the complaint pertain to?",
			"button": "Pre created",
			"caseDescription": "Please describe the request",
			"moneyIncluded": "Did this case included money?",
			"caseHowMuch": "How much money did claimant requested?",
			"haveRequestQuestion": "Did claimant make a request?",
			"caseRequest": "What was the request?",
			"caseRequestPlaceholder": "The request",
            "negotiationStartTime": "Negotiation start date",
            "negotiationEndTime": "Negotiation end date",
            "mediationStartTime": "Mediation start date",
            "mediationEndTime": "Mediation end date",
            "arbitrationStartTime": "Arbitration start date",
            "arbitrationEndTime": "Arbitration end date",
            "solved": {
                "dateSolved": "Date when case was solved",
                "result": "Result of case resolution",
                "notes": "Notes of case resolution",
                "preCSolvedNotesPlaceholder": "Notes",
                "part": "Part who solved the case",
            },
			"claimant": {
				"label": "Claimant",
				"placeholder": "Select the claimant user"
			},
			"stage": {
				"label": "Current stage"
			},
			"stageCaseResolved": {
				"label": "Stage case resolved"
			},
			"partySelection": {
				"modal": {
					"title": "Party selection",
					"description": "Please select %{party_type}"
				}
			},
			"stages": {
				"initial": "On prepare",
				"negotiation": "Negotiation",
				"mediation": "Mediation",
				"arbitration": "Arbitration",
				"solved": "Case settled"
			},
			"choose": {
				"advocate": {
					"label": "Advocate selection",
					"button": "Choose advocate"
				},
				"bank_lawyer": {
					"label": "Bank lawyer selection",
					"button": "Choose bank lawyer"
				},
				"mediator": {
					"label": "Mediator selection",
					"button": "Choose mediator"
				},
				"arbitrator": {
					"label": "Arbitrator selection",
					"button": "Choose arbitrator"
				}
			},
			"errors": {
				"advocate": "You have to choose an advocate",
				"bank_lawyer": "You have to choose a bank lawyer",
				"mediator": "You have to choose a mediator",
				"arbitrator": "You have to choose an arbitrator",
                "stageCaseSolved": "You have to choose the stage when case was solved",
                "claimant": "You have to choose the claimant",
                "stage": "You have to choose the stage",
			}
		}
	},
	"advocateRequirement": {
		"title": "Advocate Requirement",
		"description": "How do you want to pick your advocate?",
		"letRedek": "Let REDEK pick one for me",
		"pickOne": "I will pick for one"
	},
	"advocateSelection": {
		"title": "Advocate Selection",
		"description": "Please pick your advocate for this case",
		"searchPlaceholder": "Search for advocate",
		"reassignment": {
			"add": "Reassignment",
			"description": "Please pick the advocate to reassign in this case"
		}
	},
	"caseSubmitted": {
		"title": "Case Submitted",
		"thanks": "Thank you!",
		"description": "Your case has been submitted for assignation.",
		"description2": "We will inform you when it's ready",
		"return": "Return to case selection"
	},
	"mediatorRequirement": {
		"title": "Mediator Requirement",
		"description": "How do you want to pick your mediator?",
		"letRedek": "Let REDEK pick one for me",
		"pickOne": "I will pick for one"
	},
	"mediatorSelection": {
		"title": "Mediator Selection",
		"description": "Please pick your mediator for this case",
		"searchPlaceholder": "Search for mediator"
	},
	"mediatorSubmitted": {
		"title": "Mediator Submitted",
		"thanks": "Thank you!",
		"description": "You have submitted your prefered mediator.",
		"description2": "You will be notified when the next stage has been started",
		"return": "Return to Case Dashboard"
	},
	"arbitratorRequirement": {
		"title": "Arbitrator Requirement",
		"description": "How do you want to pick your arbitrator?",
		"letRedek": "Let REDEK pick one for me",
		"pickOne": "I will pick for one"
	},
	"arbitratorSelection": {
		"title": "Arbitrator Selection",
		"description": "Please pick your arbitrator for this case",
		"searchPlaceholder": "Search for arbitrator"
	},
	"arbitratorSubmitted": {
		"title": "Arbitrator Submitted",
		"thanks": "Thank you!",
		"description": "You have submitted your prefered arbitrator.",
		"description2": "You will be notified when the next stage has been started",
		"return": "Return to Case Dashboard"
	},
	"bankLawyerSelection": {
		"title": "Bank Lawyer Selection",
		"description": "Please pick bank lawyer for this case",
		"searchPlaceholder": "Search for bank lawyer"
	},
	"dashboard": {
		"title": "Dashboard",
		"description": "Quick shortcuts to your important tools",
		"showTimeline": "Show timeline",
		"startedAt": "Started at",
		"recentActivities": "Recent Activities",
		"downloadPDF": "Download PDF",
		"sharePDF": "Share PDF",
        "share": {
            "success": "Case report sent successfully",
        },
		"semester": "Semester %{semester}"
	},
	"caseStage": {
		"title": "Case Stages",
		"description": "Case stage details and your pending actions",
		"pendingOffer": "Pending offers:",
		"actionToDo": "Action you need to do:",
		"onPrepareStage": "On Prepare",
		"onPrepareStageDesc": "Your case is still on prepare. We will tell let you know once it's assigned",
		"negotiationStage": "Negotiation",
		"negotiationStageDesc": "In this stage you can negotiate with bank in attempt to settle down the case",
		"participateNegotiationChat": "Participate in negotiation offer chats",
		"inititateMediation": "Initiate mediation stage",
		"inititateMediationDesc": "You will be assisted by our mediator during mediation stage.",
		"mediationStage": "Mediation Stage",
		"mediationStageDesc": "In this stage you will be assisted by our mediator to identify and assess options and negotiate an agreement to resolve your dispute.",
		"participateMediationChat": "Participate in mediation chats",
		"initiateMediationStage": "Initiate Mediation Stage",
		"initiateMediationStageDesc": "Please wait to Super Admin aggreement to Mediation stage",
		"inititateArbitration": "Initiate arbitration stage",
		"inititateArbitrationDesc": "Only arbitrator will be able to decide the final decision. All parties will only be able to submit the required documents during the next stage.",
		"arbitratorStage": "Arbitrator Stage",
		"arbitratorStageDesc": "In this stage our arbitrator will help both parties to get to the final decision",
		"initiateArbitrationStage": "Initiate Arbitration Stage",
		"initiateArbitrationStageDesc": "Please wait to Super Admin aggreement to Arbitration stage",
		"caseResolved": "Case Resolved",
		"caseResolvedDesc": "Your case has been resolved. You can access the final decision on the final decision page. Thank you!",
		"resolveTheCase": "Resolve the case",
		"resolve": "Resolve",
		"areYouSureToResolve": "Resolve the case?",
		"resolveCaseDesc": "All parties will be notified and the case will be closed",
		"accessFinalDecision": "Access Final Decision",
		"feelFreeReview": "Feel free to leave a review for your assistants!",
		"finalDecisionResult": "What is the result of the dispute?",
		"resultOverAll": "Overall Resolution",
		"resultEveryGood": "Everything is Good",
		"disputeClosureNote": "Dispute Closure Notes",
		"resolveCase": {
            "result": {
                "total": "Total resolution",
                "partial": "Partial resolution"
            },
			"offer": {
				"title": "Offer accepted"
			},
			"success": "Case solved successfully"
		},
		"successInitiate": "Success Initiate Case Stage",
		"giveRating": "Give Rating",
		"review": "Review",
		"writeReview": "Write your review",
		"leaveReview": "Leave Review",
		"responseToTheClaim": "Response To The Claim",
		"responseToTheClaimDesc": "Please describe your response by filling the form below",
		"doesTheOfferIncludeMoney": "Does the offer include money?",
		"waitingBankLawyer": "Waiting for bank lawyer assignment. We will tell let you know once it is assigned",
		"waitingMediator": "Waiting for mediator assignment. We will tell let you know once it is assigned",
		"waitingArbitrator": "Waiting for arbitrator assignment. We will tell let you know once it is assigned",
		"makeOffer": "Make offer",
		"thisWillBeOfferTo": "This will be offered to:",
		"complaintStage": "Complaint",
		"complaintStageDesc": "In this stage you should be required to upload a final version of the complaint filed, proof that the complaint was received and approved by the judge.",
		"initiateEvidenceStage": "Initiate Evidence Stage",
		"initiateEvidenceStageDesc": "Please wait to Super Admin aggreement to Evidence stage",
		"evidenceStage": "Evidence",
		"evidenceStageDesc": "In this stage you will be receiving information of the judge relating to the general management of the case.",
		"initiateHearingStage": "Initiate Hearing Stage",
		"initiateHearingStageDesc": "Please wait to Super Admin aggreement to Hearing stage",
		"hearingStage": "Hearing",
		"hearingStageDesc": "In this stage the judge is taking closing remarks on the case by the parties.",
		"judgementStage": "Judgement & Appeal",
		"judgementStageDesc": "In this stage the judge has made the final decision on the complaint.",
		"caseConcluded": "Case Concluded",
		"mediator": {
			"makeFirstOffer": "Make first offer to the parties",
			"makeFirstOfferDesc": "Please describe your first offer by filling the form below"
		},
		"arbitration": {
			"participateInChat": "Participate in arbitration chat",
            "generateDocument": "Generate document"
        },
		"chats": {
			"closed": {
				"reasons": {
					"negotiation": "This chat was closed because %{user} requested mediation.",
					"mediation": "This chat was closed because %{user} requested arbitration.",
					"caseSolved": "This chat was closed beause the case was solved.",
                    "deadline": "This chat was closed because the time limit for the stage has expired.",
                    "imported": "This chat was closed because the case was imported and the stage already expired",
                    "precreated": "This chat was closed because the case was pre-created and the stage already expired",
				}
			},
			"makeOffersUnabled": {
				"reasons": {
					"canBeResolved": "As there is an accepted offer, the case can be resolved."
				}
			},
			"doFromHere": "Do it from here"
		},
		"doReview": {
            "leavingReviewTo": "Leaving review to",
			"success": "Review done successfully"
		}
	},
	"timerExtension": {
		"title": "Ask for extension time?",
		"adminTitle": "Are you sure to confirm extension time?",
		"adminRejectTitle": "Are you sure to reject extension time request?",
		"description": "This case stage deadline will be extended for another 10 Days.",
		"remaining": "Extension time request remaining: %{remaining}",
		"dueDate": "Due Date: %{date}",
		"confirmTimerExtension": "Confirm Extend Due Date"
	},
	"notification": {
		"title": "Notification",
		"description": "View notifications of this platform",
		"searchPlaceholder": "Search for notification",
		"showAll": "Show All",
		"view": "View %{type}",
		"messages": {
			"case": {
				"view": "View case",
				"assigned": {
					"title": "Case assignment",
					"adminAssignYou": "%{user} has assigned you to a case as %{party_type}",
					"adminAssign": "%{user} has assigned to %{user_two} as %{party_type} on a case",
					"userAdvocate": "%{user} has chosen you as the advocate on a new case",
					"randomAdvocate": "You have been randomly selected for a new case"
				},
				"reassigned": {
					"adminAssign": "%{user} has reassigned to %{user_two} as %{party_type} on a case. Reason: %{reason}"
				},
				"stage": {
					"title": "Change of stage",
					"change": "A case has changed to the %{stage} stage"
				},
                "initiate": {
                    "title": "Change of stage request",
                    "subtitle": "%{user} has requested that the %{stage} stage begin. You have to assign the part.",
                    "deadline": {
                        "title": "Change of stage request",
                        "subtitle": "The %{stage} stage must begin for the case because the time limit for the stage has expired.",
                        "partsSubtitle": "The %{stage} stage will begin for the case because the time limit for the stage has expired.",
                        "arbitration": {
                            "title": "Arbitration deadline expired",
                            "subtitle": "The deadline for the arbitration stage has passed, and no actions have been taken to resolve the case by the assigned party.",
                        },
                    }
                },
			},
			"newCase": "New case",
			"caseSubmitted": "%{user} has submitted a new case",
			"stageRequested": "%{user} has requested ${stage} stage for a case",
			"stageSetted": "%{user} started the %{stage} stage",
			"chats": {
				"view": "Open chat",
				"new": "%{user} has created a new chat with you",
				"offer": {
					"title": "New offer",
					"arrival": "Updates on an offer",
					"subtitle": "%{user} has created a new offer in %{chat_name}",
					"accepted": "%{user} has accepted the offer created by %{user_two} in %{chat_name}",
					"rejected": "%{user} has rejected the offer created by %{user_two} in %{chat_name}"
				},
				"message": {
					"title": "New message",
					"subtitle": "%{user} has sent a new message in %{chat_name}"
				}
			},
			"documents": {
				"shared": {
					"folder": "%{user} has shared a folder with you",
					"file": "%{user} has shared a file with you"
				}
			},
			"calendar": {
                "view": "View document request",
				"requestDocuments": {
					"requested": {
						"title": "New document request",
						"subtitle": "%{user} has requested a new document"
					},
					"rejected": {
						"title": "Document request rejected",
						"subtitle": "%{user} has rejected a document request"
					},
					"provided": {
						"title": "Document uploaded",
						"subtitle": "%{user} has upload a document request"
					},
					"approved": {
						"title": "Document provided approved",
						"subtitle": "%{user} has approved your provided document"
					},
					"rectification": {
						"title": "New document rectification",
						"subtitle": "%{user} has determinated that the document provided on a document request needs rectifications"
					}
				},
				"meeting": {
					"title": "New calendar meeting"
				},
				"alert": {
					"title": "New calendar alert"
				}
			}
		}
	},
	"deadline": {
		"provideDocumentTo": "Provide document to %{to}",
		"references": {
			"stage": {
				"title": "%{stage} stage expiration",
				"description": "This stage will expire on %{date}"
			}
		}
	},
	"userActions": {
		"meetingVideo": {
			"name": "Upload meeting video",
			"message": "You must upload meeting video of - %{title} -"
		},
        "messages": {
            "documentRequest": {
                "provide": {
                    "name": "Provide document",
                    "message": "You must provide a new document to %{user}"
                },
                "provideNewly": {
                    "name": "Provide document newly",
                    "message": "You must provide newly the document required on the request document generated by %{user}. Reason: %{reason}"
                },
                "review": {
                    "name": "Review document",
                    "message": "You must to review a document uploaded by %{user}",
                },
            },
            "offer": {
                "respond": {
                    "name": "Respond to offer",
                    "message": "You must respond to an offer made by %{user}",
                },
            },
        },
	},
	"timeline": {
		"title": "Timeline",
		"description": "View activities done by all parties involved",
		"searchPlaceholder": "Search for timeline",
		"pendingAction": "Pending Actions",
		"noDeadlines": "No Deadlines",
		"deadlines": "Deadlines",
		"noPendingAction": "No Pending Actions",
		"create": {
			"success": "Timeline activity created successfully"
		},
		"item": {
			"document": "External attachment"
		}
	},
	"privateNotebook": {
		"title": "Private notebook",
		"description": "Your own private notes",
		"searchPlaceholder": "Search notes",
		"buttonAdd": "New note",
		"pinned": "Pinned",
		"allNotes": "All Notes",
		"forms": {
			"placeholders": {
				"title": "Title",
				"content": "Take a note.."
			},
			"menus": {
				"duplicate": "Duplicate",
				"delete": "Delete Note"
			},
			"create": {
				"successfully": "Note created successfully"
			},
			"update": {
				"successfully": "Note updated successfully"
			},
			"delete": {
				"successfully": "Note deleted successfully"
			}
		}
	},
	"caseDetail": {
		"title": "Case Details",
		"description": "More detailed information about the case",
		"details": {
			"caseName": "Case Name",
			"caseAmount": "Case Amount",
			"caseRequest": "Case Request",
			"caseDetails": "Case Details",
			"caseDescription": "Case Description",
			"urgency": "Urgency",
			"submissionDate": "Submission Date",
			"submittedBy": "Submitted By",
			"currentStage": "Current Stage",
			"assignationDocument": "Assignation Document",
			"stageDuedate": "%{stage} Due Date",
			"isWithAssistant": "With Assistant"
		},
		"parties": {
			"title": "Parties",
			"role": "Role",
			"phoneNumber": "Phone Number",
			"email": "E-mail",
			"reassignment": {
				"reassigned": "This part was reassigned",
				"reason": "Reason",
				"attachment": "Attachment",
				"by": "Reassigned by",
				"itis": "New"
			}
		},
		"firstOffers": {
			"title": "First offers on every stage",
			"offer": {
				"title": "Title",
				"content": "Possible agreement content",
				"request": "Request",
				"amount": "Amount"
			}
		}
	},
	"folder": {
		"title": "Folders",
		"description": "Access and manage files inside the folder"
	},
	"document": {
		"title": "Documents",
		"description": "Access and manage files regarding the case",
		"searchPlaceholder": "Search documents",
		"buttonAdd": "New",
		"newFile": "New File",
		"newFolder": "New Folder",
		"modalRemoveFile": {
			"title": "Are you sure to delete this document?",
			"description": "You won't be able to restore the document if you choose to delete it.",
			"successfullyFolder": "Folder removed successfully",
			"successfullyFile": "File removed successfully"
		},
		"modalEditFile": {
			"title": "Edit File",
			"titleFolder": "Edit Folder",
			"successfullyFolder": "Folder updated successfully",
			"successfullyFile": "File updated successfully"
		},
		"modalDocumentRequestInfo": {
			"title": "Document request traceability",
			"requestTitle": "Request title",
			"description": "Description",
			"requestedAt": "Requested at",
			"requestedBy": "Requested by",
			"requestedTo": "Requested to",
			"dueDate": "Due date",
			"attemps": "Attemps",
			"statusWord": "Status",
			"status": {
				"rejectedBy": "Rejected by: %{by}",
				"rejectedReason": "Reason: %{reason}"
			},
			"fileAttemp": {
				"result": "Result:",
				"rectification": {
					"alert": "This document needed rectification",
					"reason": "Reason: %{reason}"
				},
				"approved": {
					"alert": "This document was approved"
				}
			}
		},
		"modalCreateFile": {
			"title": "Upload New File",
			"titleFolder": "Create New Folder",
			"fileName": "File name",
			"folderName": "Folder name",
			"fileNamePlaceholder": "Settlement",
			"shareWith": "Share with",
			"fileType": "File type",
			"folder": "Folder",
			"withoutFolder": "-----Outside Folder------",
			"uploadFile": "Upload file",
			"updateFile": "Update file info",
			"createFolder": "Create folder",
			"updateFolder": "Update folder info",
			"successfullyFolder": "Folder created successfully",
			"successfullyFile": "File created successfully"
		},
		"modalInfo": {
			"title": "Document Info",
			"documentName": "Document Name",
			"owner": "Owner",
			"type": "Type",
			"shareWith": "Share with",
			"size": "Size"
		},
		"headerTable": {
			"name": "Name",
			"owner": "Owner",
			"type": "Type",
			"requestedDocuments": {
				"title": "Title",
				"stage": "Stage",
				"dueDate": "Due Date",
				"status": "Status",
				"requestedAt": "Requested at"
			}
		},
		"requestedDocuments": {
			"status": {
				"pending": "Pending",
				"on_review": "On review",
				"on_rectification": "On rectification",
				"rejected": "Rejected",
				"approved": "Approved"
			}
		}
	},
	"calendar": {
		"title": "Calendar",
		"newAgenda": "New Agenda",
		"monthly": "Monthly",
		"daily": "Daily",
		"buttonAdd": "New / Request",
		"documentRequest": "Document Request",
        "documentRequests": "Document Requests",
        "meetings": "Meetings",
        "alerts": "Alerts",
		"joinMeeting": "Join Meeting",
        "modalAgendaMethod": {
            "title": "What do you like to create?",
            "documentRequest": "Document Request",
            "meetingOrAlert": "Meeting Or Alert"
        },
		"modalRequestDocument": {
			"title": "Request Document",
			"requestTo": "Request To",
			"dueTime": "Due Time",
			"additionalNotes": "Notes",
			"requestAdditional": "%{from} has requested additional informations to %{to}",
			"shouldBeAccept": "Users who should accept",
			"requireAcceptEveryone": "Require acceptance from everyone",
			"successfully": "Request document generated successfully"
		},
		"modalNewAgenda": {
			"title": "New Agenda",
			"formTitle": "Title",
			"agendaType": "Agenda Type",
			"meetingType": "Meeting Type",
			"meetingPlatform": "Meeting Platform",
			"meetingURL": "Meeting URL",
            "inPersonAddress": "Meeting address",
			"meetingTime": "Date",
			"from": "From",
			"to": "To",
			"meetingInvitees": "Meeting Invitees",
			"agenda": "Agenda",
			"otherDetails": "Other Details",
			"otherDetailPlaceholder": "PIN/Password",
			"addEvent": "Add Event",
			"provideURL": "I will provide the URL",
			"redekVideoMeet": "Redek Video Meeting",
            "successfully": "Agenda generated successfully"
		},
		"modalConfirm": {
			"title": "Are you sure to make this agenda?",
			"description": "Invitees will be notified through the platform and their email. You can edit this agenda later.",
			"cancel": "Cancel",
			"confirm": "Confirm"
		},
		"modalInfo": {
			"provideDocument": "Provide document",
			"rejectRequest": "Reject request",
			"dueDate": "Due %{date} (%{totalDay} day)",
			"requestBy": "Request by %{by}",
			"requestTo": "Request to",
			"yourRespond": "Your response",
			"reason": "Reason",
			"acceptOrReject": "Document request responded successfully",
			"documentAlreadyProvided": "This document was provided by %{by} and it is on revision process",
			"documentNotProvidedYet": "This document has not been provided yet",
			"documentApproved": "This document was approved by",
			"currentDocument": {
				"title": "Current document provided"
			},
			"requestDocumentRejected": "This document request was closed because %{by} rejected it at %{at}. Reason: %{reason}",
			"approval": {
				"approve": "Approve",
				"rectify": {
					"title": "Does the document need to be rectified?"
				},
				"rectification": "Rectification is necessary",
				"confirmation": {
					"title": "Are you sure to accept this document?",
					"description": "This action will cause the request to close, and the document to be recorded as accepted."
				},
				"successfully": {
					"approve": "Document request approved successfully"
				},
				"feedback": {
					"title": "Feedback",
					"alert": "Document on rectification process",
					"alertProvider": "This document has to be provided newly"
				}
			},
			"meetingVideo": {
				"title": "Provide meeting recording",
				"upload": {
					"pleaseChooseFile": "Please attach a file",
					"pleaseChooseFileMp4": "Please attach a file of type mp4",
					"success": "Meeting video uploaded successfully"
				},
				"done": {
					"title": "Meeting video",
					"description": "You can find this video in the Case Call Recordings folder in documents."
				}
			}
		},
		"modalReject": {
			"title": "Reject Request?"
		}
	},
	"chat": {
		"group": {
			"title": "Chats rooms",
			"description": "Conversations between parties",
			"private": "Private discussion",
			"newChat": "New Chat Room",
			"groupName": "Group Name",
			"members": "Members",
			"privateChat": "Private Chat",
			"stageChat": "Stage Chat",
			"closed": "Closed"
		},
		"discussion": {
			"title": "Private discussion",
			"info": "Conversation Info",
			"closeChat": "Close Chat",
			"mediaAndDocuments": "Media and documents",
			"accepted": "Accepted:",
			"rejected": "Rejected:",
            "expiredOffer": "This offer can not be responded to because the deadline has already passed",
			"confirmAcceptance": {
				"title": "Are you sure to accept the offer?",
				"agreement": {
					"description": "This offer includes an agreement, so we suggest that you review the document generated in the offer. You can do so by taking the respective action in the offer details."
				}
			},
			"makeOffer": "Make Offer",
			"offerSubject": "Offer Subject",
			"offerSubjectPlaceholder": "Offer from fintech",
			"offerTo": "Offer To",
			"request": "Request",
			"description": "Description",
			"dueTime": "Due Time",
			"rejectOffer": "Reject Offer?",
			"searchForChat": "Search chat...",
			"create": {
				"successfully": "Successfully chat data created"
			},
			"newOffer": {
				"agreement": {
					"question": "Have agreement?",
					"label": "Agreement",
					"alert": "The generation of an agreement through an offer is based on defining the clauses and items that the document will contain. This document will be generated automatically. Please note that with this option, the case will proceed to be closed and/or resolved when the last party required to accept it does so."
				}
			},
			"offer": {
				"agreement": {
					"view": "View document agreement"
				}
			}
		}
	},
	"finalDecision": {
		"title": "Final Decision",
		"description": "Access the final decision of the case",
		"partyWhoResolved": "Party who resolved the case",
		"resolveDate": "Resolve Date",
		"disputeResult": "Dispute Result",
		"partialResolution": "Partial Resolution",
		"notes": "Notes",
		"byOfferAgreement": "This dispute was solved through an offer generated by some of parties.",
		"solutionByOffer": {
			"title": "Offer generated to case solution:",
			"offer": {
				"title": "Title",
				"content": "Content",
				"request": "Request",
				"amount": "Amount"
			}
		},
		"document": {
			"title": "Document generated to case solution:",
			"view": "View document"
		}
	},
	"admin": {
		"timeline": {
			"title": "Timeline & Activites",
			"description": "Manually input activities",
			"searchPlaceholder": "Search for activities",
			"buttonAdd": "New Activity",
			"modalRemoveTimeline": {
				"title": "Are you sure to delete this user?",
				"description": "You won’t be able to restore the file if you choose to delete it."
			},
			"modalCreateTimeline": {
				"title": "Manually create an activity",
				"editTitle": "Edit Activity",
				"type": "Activity Type",
				"case": "Activity Case",
				"user": "Activity User",
				"activityTitle": "Title",
				"subtitle": "Subtitle",
				"content": "Content",
				"extra": "Extra",
				"save": "Save"
			}
		},
		"user": {
			"title": "Users",
			"description": "Manage all users in the platform",
			"searchPlaceholder": "Search for user",
			"buttonAdd": "New user",
			"headerTable": {
				"name": "Name",
				"role": "Role",
				"email": "E-mail",
                "phone": "Phone",
				"action": "Actions"
			},
			"modalRemoveUser": {
				"title": "Are you sure to delete this user?",
				"description": "You won’t be able to restore the file if you choose to delete it."
			},
			"modalCreateUser": {
				"title": "Register a new user",
				"editTitle": "Edit user",
				"identification": "Identification",
                "identificationType": "Identification type",
				"role": "Role",
				"username": "Username",
				"firstName": "First Name",
				"secondName": "Second Name",
				"lastName": "Last Name",
				"email": "E-mail",
				"password": "Password",
				"phone": "Phone Number",
				"bank": "Bank",
				"confirmPassword": "Confirm Password",
				"save": "Save"
			}
		},
		"roles": {
			"title": "Roles",
			"description": "Manage all roles in the platform",
			"searchPlaceholder": "Search for role",
			"buttonAdd": "New role",
			"headerTable": {
				"code": "Code",
				"name": "Name",
				"description": "Description",
				"action": "Actions"
			},
			"modalRemoveRole": {
				"title": "Are you sure to delete this role?",
				"description": "You won’t be able to restore the file if you choose to delete it."
			},
			"modalCreateRole": {
				"title": "Register a new role",
				"editTitle": "Edit role",
				"role": "Role",
				"code": "Code",
				"name": "Name",
				"description": "Description",
				"save": "Save"
			},
			"detail": {
				"title": "Role detail",
				"description": "Managing role %{codeRole}"
			}
		},
		"permissions": {
			"title": "Permissions",
			"description": "Manage all permissions in the platform",
			"searchPlaceholder": "Search for permission",
			"buttonAdd": "New permission",
			"headerTable": {
				"name": "Name",
				"description": "Description",
				"pattern": "Pattern Permission",
				"reference": "Reference",
				"status": "Status"
			},
			"modalRemovePermission": {
				"title": "Are you sure to delete this permission?",
				"description": "You won’t be able to restore the file if you choose to delete it."
			},
			"modalCreatePermission": {
				"title": "Register a new permission",
				"editTitle": "Edit permission",
				"name": "Name",
				"description": "Description",
				"pattern": "Pattern Permission",
				"reference": "Reference",
				"status": "Status",
				"save": "Save"
			}
		},
		"dashboard": {
			"title": "Dashboard",
			"description": "Quick shortcuts to your important tools",
			"overview": "Overview",
			"pendingCase": "Pending Cases",
			"newUser": "New Users"
		},
        "parameters": {
			"title": "Parameters",
			"description": "Manage the essential parameters of the application",
		},
		"cases": {
			"title": {
				"all": "All case",
				"onprepare": "Pending cases",
				"ongoing": "Ongoing cases",
				"solved": "Settled cases"
			},
			"description": {
				"all": "Manage all cases in the platform",
				"onprepare": "Manage pending cases in the platform",
				"ongoing": "Manage ongoing cases in the platform",
				"solved": "Manage settled cases in the platform"
			},
			"cases": "Cases",
			"searchPlaceholder": "Search for cases",
			"cardTitle": {
				"all": "Total case",
				"pending": "Pending case",
				"ongoing": "Ongoing case",
				"settled": "Settled case"
			},
            "filter": {
                "byArea": {
                    "title": "Filter by area",
                    "select": "Select an area",
                }
            }
		}
	},
	"modal": {
		"close": "Close",
		"save": "Save",
		"attachment": "Attachment",
		"videoUpload": "Video Upload",
		"modalDelete": {
			"title": "Are you sure to delete?",
			"description": "You won't be able to restore if you choose to delete it."
		}
	},
	"validation": {
		"enterValidEmail": "Please enter a valid email",
		"isNotValidEmail": "%{email} is not a valid email",
		"enterValidPassword": "Please enter a password. Minimum 6 characters",
		"enterFirstName": "Please enter the first name",
		"enterIdentification": "Please enter the identification",
        "enterIdentificationType": "Please enter the identification type",
		"minimumFirstName": "Minimum 3 characters",
		"alphabetOnly": "Please enter alphabet only",
		"enterPhoneNumber": "Please enter the phone number",
		"enterValidPhoneNumber": "Please enter the valid phone number",
		"passwordMustMatch": "Passwords must match",
		"emailAlreadyInUse": "Email already is in use. Enter a different one",
		"phoneAlreadyInUse": "Phone number already is in use. Enter a different one",
		"identificationAlreadyInUse": "Identification already is in use.",
		"identificationFormat": "Identification should be only numbers",
		"enterActivityTitle": "Please enter the activity title",
		"enterNoteTitle": "Please enter your note title",
		"enterNoteContent": "Please enter your note content",
		"selectResultSolveCase": "Please select the result",
		"enterNote": "Please enter your note",
		"attachmentRequired": "Attachment is required",
		"selectRole": "Please select the role",
		"selectBank": "Please select the bank",
		"enterUserName": "Please enter the username",
		"selectMember": "Please select member",
        "selectCurrency": "Please select currency",
		"selectInvitees": "Please select invitees",
		"selectRequestTo": "Please select request to",
		"selectOfferTo": "Please select offer to",
		"selectShareWith": "Please select share with",
		"enterGroupName": "Please enter the group name",
		"enterFileName": "Please enter the file name",
		"enterFolderName": "Please enter the folder name",
		"chooseMeeting": "Please select meeting",
		"selectMeeting": "Select the meeting to which the file belongs.",
		"enterSubject": "Please enter the subject",
		"enterDescription": "Please enter a description",
		"enterRequest": "Please enter your request",
		"enterAmount": "Please enter amount",
		"enterAgendaTitle": "Please enter the agenda title",
		"enterMeetingURL": "Please enter the meeting URL",
		"enterAgenda": "Please enter the agenda",
		"selectMeetingPlatform": "Please select meeting platform",
		"selectMeetingType": "Please select meeting type",
        "selectIdentificationType": "Please select identification type",
		"selectAgendaType": "Please select agenda type",
		"enterCorrectURL": "Please enter the correct url",
		"selectTime": "Select time",
		"selectTimeFirst": "Please select the time from and to",
		"selectTimeInvalid": "The Time from cannot be greater than equal time to",
		"pleaseInsertRating": "Please choose star rating first",
		"pleaseDescribeReason": "Please describe the reason why",
		"pleaseDescribeEmail": "Please enter the email for share data (separated by comma)",
		"enterCaseName": "Please enter a case name",
		"enterCaseDescription": "Please enter a case description",
		"enterCaseRequest": "Please enter a request",
		"minimumCaseName": "Minimum 5 characters",
		"enterCaseAmount": "Please enter case amount",
		"minimumCaseAmount": "Amount must be greater than equal 1 USD",
		"pleaseSelectBankLawyer": "Please select Bank Lawyer",
		"roles": {
			"creation": {
				"enterCode": "Please enter a code",
				"enterName": "Please enter a role name"
			}
		},
		"permissions": {
			"creation": {
				"enterName": "Please enter a permission name",
				"enterReference": "Plese enter a permission reference",
				"referenceAlreadyInUse": "Reference already is in use. Enter a different one"
			}
		},
		"documents": {
			"common": {
				"nameAlreadyInUse": "Folder/File name already exists. Enter a different one"
			}
		},
		"calendar": {
			"form": {
				"shouldBeAccept": "Please select users who should accept the document provided"
			}
		},
		"passwordIncorrect": "The password provided is incorrect",
        "enterMeetingInPersonAddress": "Please enter the meeting in-person address",
	},
	"options": {
		"ordering": {
			"dateAsc": "Date Ascending",
			"dateDesc": "Date Descending"
		}
	},
	"profile": {
		"title": "Profile",
		"editProfile": "Edit Profile",
		"editProfileDesc": "Manage informations regarding your REDEK profile",
		"terms": "Terms and Conditions",
		"yourRating": "Your Rating and Reviews",
		"signOut": "Sign Out",
		"maximumSize": "Maximum size",
		"fileType": "File type",
		"uploadPicture": "Upload Picture",
		"identification": "Identification",
        "identificationType": "Identification type",
		"firstName": "First Name",
		"lastName": "Last Name",
		"email": "Email",
		"username": "Username",
		"phoneNumber": "Phone Number",
		"rating": {
			"title": "Your rating and reviews",
			"totalReviews": "(%{total} reviews)",
            "average": "Average rating"
		},
		"update": {
			"success": "Data updated successfully",
			"successPassword": "Password updated successfully"
		},
		"otp": {
			"title": "OTP Authentication",
			"alerts": {
				"info": "The OTP authentication adds an extra layer of security when logging into the platform. When you log in, you will need to enter a 6-digit code sent either via email or SMS (you choose the method).",
				"off": "We recommend activating this option for enhanced system security."
			},
			"modal": {
				"title": "%{action} the OTP authentication",
				"description": "As you will %{action} the OTP authentication, you need confirm your password first to be able to do it.",
				"password": {
					"label": "Password",
					"placeholder": "Enter your password"
				},
				"method": {
					"label": "OTP method"
				},
				"success": "OTP %{action} successfully"
			}
		}
	},
	"message": {
		"success": {
			"case": {
				"priority": "Success change case priority"
			},
			"auth": {
				"resetPassword": "Success Reset Password"
			}
		},
		"failed": {
			"ise": "Internal server error",
			"calendar": {
				"is_already_exist": "Agenda is already exist"
			},
			"auth": {
				"emailExpired": "Email for sent OTP is expired, please request again",
				"tokenExpired": "Token is expired, please request again",
				"fillOTP": "Please fill the OTP code",
				"userLoggingInExpired": "The user who is logging in has expired. Please return to the login form"
			}
		}
	},
	"report": {
		"redekCaseSemester": "Redek Case Report %{year} Semester %{semester}"
	},
	"guidance": {
		"boarding": {
			"title": "Do you need guidance?",
			"content": "Let's take a little tour of the key features that we offer!"
		},
		"caseDetail": {
			"title": "Case Details",
			"content": "This contains details about your case, you can see more info by clicking this card."
		},
		"currentStage": {
			"title": "Current Stage",
			"content": "This card shows you the current stage of the case. Which includes preparation, blind offer, mediation, and arbitration"
		},
		"recentActivities": {
			"title": "Recent Activities",
			"content": "This contains history of actions regarding the case."
		},
		"deadlines": {
			"title": "Deadlines",
			"content": "Quick shortcut so you don't miss on important tasks and events."
		},
		"caseStages": {
			"title": "Case Stages",
			"content": "Stage details and your pending actions."
		},
		"privateNotebook": {
			"title": "Private Notebook",
			"content": "Your very own private notes"
		},
		"meetingRoom": {
			"title": "Meeting Room",
			"content": "Online meeting room provided by REDEK"
		},
		"chats": {
			"title": "Chats",
			"content": "Communicate and share offers with other parties"
		},
		"documents": {
			"title": "Documents",
			"content": "Access and manage case files"
		},
		"timeline": {
			"title": "Timeline",
			"content": "Access and manage case files"
		},
		"calendar": {
			"title": "Calendar",
			"content": "See your case timeline and manage agendas"
		},
		"caseMoreDetail": {
			"title": "Case Details",
			"content": "More details according your case."
		}
	},
	"mediationAgreement": {
		"createMediation": "Create Mediation Agreement",
		"title": "Mediation Agreement",
		"show": "Show Mediation Agreement",
		"subtitle": "Aggreement document of your case",
		"description": "Submit your agreement document for the respondent",
		"convener": "CONVENER",
		"respondent": "RESPONDENT",
		"attachLabel": "Attach your agreement document here",
		"youCanChooseTemplate": "You can use this agreement template",
		"aggreementDesc": "Accept or decline agreement contract regarding your case",
		"aggreementDocument": "Agreement document",
		"waitingMediator": "Waiting mediator to upload mediation agreement"
	},
	"status": {
		"active": "Active",
		"inactive": "Inactive"
	},
	"observation": "Observation",
    "parameters": {
        "title": "Parameters",
        "menu": {
            "general": "General",
            "masterData": "Master data",
        },
        "general": {
            "cases": {
                "title": "Cases",
                "deadlines": {
                    "title": "Stage deadlines",
                    "description": "Set the deadline for the duration of each stage in days. Please note that the changes you make will be applied only to cases assigned a part after you click 'Save'.",
                    "inputs": {
                        "negotiation": {
                            "title": "Negotiation",
                        },
                        "mediation": {
                            "title": "Mediation",
                        },
                        "arbitration": {
                            "title": "Arbitration",
                        },
                        "commonPlaceholder": "N° days",
                    },
                    "validations": {
                        "negotiation": {
                            "required": "Deadline negotiation field is required",
                            "numeric": "Deadline negotiation field must only contain numeric characters"
                        },
                        "mediation": {
                            "required": "Deadline mediation field is required",
                            "numeric": "Deadline mediation field must only contain numeric characters"
                        },
                        "arbitration": {
                            "required": "Deadline arbitration field is required",
                            "numeric": "Deadline arbitration field must only contain numeric characters"
                        },
                    },
                },
                "systemOwner": {
                    "title": "Document System Owner",
                    "description": "Defines the owner of the folders in the file system of cases (case-record, case-call-recordings, etc.). This action allows the chosen user to change the access to directories belonging to the case's document system.",
                    "selectUser": "Select a user",
                    "validations": {
                        "required": "Document system owner field is required",
                    },
                }
            },
            "security": {
                "title": "Security",
                "registerRole": {
                    "title": "Role of register",
                    "description": "Defines the role that will be assigned to users when they register through the external form.",
                    "validations": {
                        "required": "Role of register field is required",
                    },
                },
            }
        },
        "masterData": {
            "currencies": {
                "title": "Currencies",
                "new": {
                    "modal": {
                        "title": "Create a new currency",
                        "titleEdit": "Edit currency"
                    },
                    "code": "Code",
                    "name": "Name",
                    "placeholderCode": "Type a currency code",
                    "placeholderName": "Type a currency name",
                    "validations": {
                        "code": {
                            "required": "Code field is required",
                            "minimum": "Minimum 3 characters",
                            "maximum": "Maximum 255 characters",
                        },
                        "name": {
                            "required": "Name field is required",
                            "maximum": "Maximum 255 characters",
                        }
                    },
                },
                "successCreated": "Currency created successfully",
                "successUpdated": "Currency updated successfully",
                "successDeleted": "Currency deleted successfully",
                "delete": {
                    "confirmation": {
                        "title": "Are you sure to delete this currency?",
                    }
                }
            },
            "caseTypes": {
                "title": "Case types",
                "new": {
                    "modal": {
                        "title": "Create a new case type",
                        "titleEdit": "Edit case type"
                    },
                    "name": "Name",
                    "description": "Description",
                    "placeholderName": "Type a case type name",
                    "placeholderDescription": "Type a case type description",
                    "validations": {
                        "name": {
                            "required": "Name field is required",
                            "maximum": "Maximum 255 characters",
                        },
                        "description": {
                            "maximum": "Maximum 255 characters",
                        }
                    },
                },
                "successCreated": "Case type created successfully",
                "successUpdated": "Case type updated successfully",
                "successDeleted": "Case type deleted successfully",
                "delete": {
                    "confirmation": {
                        "title": "Are you sure to delete this case type?",
                    }
                }
            },
            "identificationTypes": {
                "title": "Identification types",
                "new": {
                    "modal": {
                        "title": "Create a new identification type",
                        "titleEdit": "Edit identification type"
                    },
                    "name": "Name",
                    "nomenclature": "Nomenclature",
                    "placeholderName": "Type a identification type name",
                    "placeholderNomenclature": "Type a identification type nomenclature",
                    "validations": {
                        "name": {
                            "required": "Name field is required",
                            "maximum": "Maximum 255 characters",
                        },
                        "nomenclature": {
                            "required": "Name field is required",
                            "minimum": "Minimum 2 characters",
                            "maximum": "Maximum 255 characters",
                        }
                    },
                },
                "successCreated": "Identification type created successfully",
                "successUpdated": "Identification type updated successfully",
                "successDeleted": "Identification type deleted successfully",
                "delete": {
                    "confirmation": {
                        "title": "Are you sure to delete this identification type?",
                    }
                }
            },
            "areas": {
                "title": "Areas",
                "new": {
                    "modal": {
                        "title": "Create a new area",
                        "titleEdit": "Edit area"
                    },
                    "name": "Name",
                    "description": "Description",
                    "color": "Color",
                    "contrastToColor": "Contrast to color",
                    "placeholderName": "Type an area name",
                    "placeholderDescription": "Type an area description",
                    "validations": {
                        "name": {
                            "required": "Name field is required",
                            "maximum": "Maximum 255 characters",
                        },
                        "description": {
                            "maximum": "Maximum 255 characters",
                        }
                    },
                },
                "successCreated": "Area created successfully",
                "successUpdated": "Area updated successfully",
                "successDeleted": "Area deleted successfully",
                "delete": {
                    "confirmation": {
                        "title": "Are you sure to delete this area?",
                    }
                }
            },
            "common": {
                "table": {
                    "headers": {
                        "code": "Code",
                        "name": "Name",
                        "color": "Color",
                        "contrastToColor": "Contrast to color",
                        "nomenclature": "Nomenclature",
                        "description": "Description",
                        "createdAt": "Created at",
                        "status": "Status",
                    }
                },
                "form": {
                    "status": "Active"
                }
            }
        },
        "save": {
            "success": "Parameters saved successfully"
        }
    },
    "videoCall": "Video call",
    "inPersonMeeting": "In-person meeting",
    "mixed": "Mixed",
}