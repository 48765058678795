<template>
    <div class="flex w-full cursor-pointer bg-white" @click="clickDocument">
        <div class="flex w-1/6 justify-end gap-3">
            <div class="ml-2">
                <OtherIcon class="inline w-8 cursor-pointer text-yellow-500"/>
            </div>
            <div class="text-sm">
                {{ document.title }}
                <!-- <span>{{ (document.translate_ref) ? $t(document.translate_ref) : document.name }}</span> -->
            </div>
        </div>
        <div class="flex w-1/6 pt-1">
            <p :class="getClassStage">
                <span class="font-medium">
                    {{ getStage }}
                </span>
            </p>
        </div>
        <div class="flex w-1/6 pt-1 text-sm">
            {{ formattedDate(document.due_date, 'DD MMM YYYY HH:mm') }}
        </div>
        <div class="flex w-1/6 pt-1 text-xs font-bold text-center">
            <span
            :class="getClassStatus"
            >
                {{ getStatus }}
            </span>
        </div>
        <div class="flex w-1/6 pt-1 text-sm">
            {{ formattedDate(document.created_at, 'DD MMM YYYY HH:mm') }}
        </div>
        <div class="flex w-1/6 px-2">
            <div class="flex w-full items-center justify-end">
                <div
                v-permission="'cases_manage_documents_list_case_record_req_doc_view_trace'"
                >
                    <EditMenu align="right" class="relative ml-2 inline-flex" @click.stop>
                        <li
                        v-permission="'cases_manage_documents_list_case_record_req_doc_view_trace'"
                        >
                            <span
                            class="flex cursor-pointer py-1 px-3 text-sm font-medium text-slate-600 hover:text-slate-800"
                            @click="infoDocumentRequest">
                                {{ $t('traceability') }}
                            </span>
                        </li>
                    </EditMenu>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import EditMenu from './DropdownEditMenu.vue'
import OtherIcon from '@/assets/svg/file/other.svg'

import documentMixin from '@/mixins/document.js'
import globalMixin from '@/mixins/global.js'

export default {
    name: 'DocumentItemComponent',
    components: {
        EditMenu,
        OtherIcon,
    },
    props: {
        document: {
            type: Object,
            default: () => ({
                isFolder: false,
                name: '',
                owner: '',
                type: '',
            }),
        },
    },
    mixins: [globalMixin, documentMixin],
    emits: ['info-document'],
    computed: {
        fileType() {
            if (this.document.type !== 'Folder') {
                if (this.document.files[0] !== undefined) {
                    const fileName = this.document.files[0].file
                    return fileName.substring(fileName.lastIndexOf('.') + 1)
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        getStage () {
            const stagesName = {
                '2': this.$t('case.stages.negotiation'),
                '3': this.$t('case.stages.mediation'),
                '4': this.$t('case.stages.arbitration'),
            }
            return stagesName[this.document.stage];
        },
        getClassStage () {
            const stagesClass = {
                '2': 'bg-green-500',
                '3': 'bg-yellow-500',
                '4': 'bg-red-500',
            }
            return 'rounded-lg h-max text-xs font-semibold text-white p-1 ' + stagesClass[this.document.stage];
        },
        getStatus () {
            const statusTarget = {
                pending: this.$t('document.requestedDocuments.status.pending'),
                on_review: this.$t('document.requestedDocuments.status.on_review'),
                on_rectification: this.$t('document.requestedDocuments.status.on_rectification'),
                rejected: this.$t('document.requestedDocuments.status.rejected'),
                approved: this.$t('document.requestedDocuments.status.approved'),
            }
            return statusTarget[this.getDocumentRequestStatusIdentity(this.document)];
        },
        getClassStatus () {
            const statusClass = {
                pending: 'text-yellow-500',
                on_review: 'text-sky-500',
                on_rectification: 'text-yellow-500',
                rejected: 'text-red-500',
                approved: 'text-green-500',
            }
            return statusClass[this.getDocumentRequestStatusIdentity(this.document)]
        },
    },
    methods: {
        infoDocumentRequest () {
            this.$emit('info-document', {
                document: this.document,
            })
        },
    },
}
</script>
