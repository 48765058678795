import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
    createCaseReview({ state, commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {
                case_id: payload.case_id,
                party_id: payload.party_id,
                rating: payload.rating,
                review: payload.review,
            }

            axios
                .post(`${routePathAPI()}/cases/review`, params, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAllReview({ commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {}
            if (payload.page) params.page = payload.page
            if (payload.take) params.per_page = payload.take
            if (payload.order) params.order = 'created_at.' + payload.order
            // if (payload.q) params.query = payload.q
            // if (payload.rating) params.rating = payload.rating

            axios
                .get(`${routePathAPI()}/cases/review/allByUser`, {
                    params: params,
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    const responseData = response.data
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}

// mutations
const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
