<template>
    <div
        class="no-scrollbar min-w-60 flex flex-nowrap overflow-x-scroll border-b border-slate-200 px-3 py-6 md:block md:space-y-3 md:overflow-auto md:border-b-0 md:border-r"
    >
        <!-- Group 1 -->
        <div>
            <ul class="mr-3 flex flex-nowrap space-y-2 md:mr-0 md:block">
                <router-link
                v-slot="{ href, navigate, isExactActive }"
                to="/profile/account"
                custom
                >
                    <li
                    v-permission="'profile_edit'"
                    class="mr-0.5 md:mr-0 md:mb-0.5">
                        <a
                            class="flex items-center space-x-4 whitespace-nowrap px-2.5 py-2"
                            :class="
                                isExactActive && 'border-r-2 border-sky-400'
                            "
                            :href="href"
                            @click="navigate"
                        >
                            <ProfileIcon
                                class="w-6"
                                :class="isExactActive && 'text-sky-400'"
                            />

                            <span
                                class="text-sm font-medium text-slate-600"
                                :class="
                                    isExactActive
                                        ? 'text-sky-500'
                                        : 'hover:text-slate-700'
                                "
                            >
                                {{ $t('profile.editProfile') }}
                            </span>
                        </a>
                    </li>
                </router-link>

                <router-link
                v-if="false"
                v-slot="{ href, navigate, isExactActive }"
                to="/profile/terms"
                custom
                >
                    <li
                    class="mr-0.5 md:mr-0 md:mb-0.5">
                        <a
                            class="flex items-center space-x-4 whitespace-nowrap px-2.5 py-2"
                            :class="
                                isExactActive && 'border-r-2 border-sky-400'
                            "
                            :href="href"
                            @click="navigate"
                        >
                            <PaperProfileIcon
                                class="w-6"
                                :class="isExactActive && 'text-sky-400'"
                            />

                            <span
                                class="text-sm font-medium text-slate-600"
                                :class="
                                    isExactActive
                                        ? 'text-sky-500'
                                        : 'hover:text-slate-700'
                                "
                            >
                                {{ $t('profile.terms') }}
                            </span>
                        </a>
                    </li>
                </router-link>

                <router-link
                v-slot="{ href, navigate, isExactActive }"
                to="/profile/ratings"
                custom
                >
                    <li
                    v-permission="'profile_ratings_and_reviews'"
                    class="mr-0.5 md:mr-0 md:mb-0.5">
                        <a
                            class="flex items-center space-x-4 whitespace-nowrap px-2.5 py-2"
                            :class="
                                isExactActive && 'border-r-2 border-sky-400'
                            "
                            :href="href"
                            @click="navigate"
                        >
                            <StarProfileIcon
                                class="w-6"
                                :class="isExactActive && 'text-sky-400'"
                            />

                            <span
                                class="text-sm font-medium text-slate-600"
                                :class="
                                    isExactActive
                                        ? 'text-sky-500'
                                        : 'hover:text-slate-700'
                                "
                            >
                                {{ $t('profile.yourRating') }}
                            </span>
                        </a>
                    </li>
                </router-link>
            </ul>
        </div>
    </div>
</template>

<script>
import PaperProfileIcon from '@/assets/svg/paper-profile.svg'
import StarProfileIcon from '@/assets/svg/star-profile.svg'
import ProfileIcon from '@/assets/svg/profile.svg'
export default {
    name: 'SettingsSidebar',
    components: {
        StarProfileIcon,
        PaperProfileIcon,
        ProfileIcon,
    },
}
</script>
