<template>
    <div
    v-if="!isLoading"
    class="flex flex-col md:grid md:grid-cols-2 gap-3">
        <div
        v-permission="'parameters_list_general_cases'"
        class="p-2 flex flex-col gap-3"
        >
            <span class="font-bold text-lg">
                {{ $t('parameters.general.cases.title') }}
            </span>
            <div class="flex flex-col gap-5 text-sm">
                <div class="bg-gray-100 p-5 text-slate-500">
                    <span class="font-bold">
                        {{ $t('parameters.general.cases.deadlines.title') }}
                    </span>
                    <p>
                        {{ $t('parameters.general.cases.deadlines.description') }}
                    </p>
                    <div
                    v-if="parametersValue && parametersValue.general"
                    class="mt-4 flex flex-col md:grid md:grid-cols-2 gap-3"
                    >
                        <div class="flex flex-col gap-1">
                            <label>
                                {{ $t('parameters.general.cases.deadlines.inputs.negotiation.title') }}
                            </label>
                            <input
                            v-model="parametersValue.general.cases.duration.negotiation"
                            :placeholder="$t('parameters.general.cases.deadlines.inputs.commonPlaceholder')"
                            type="number"
                            class="form-input w-full rounded-md"
                            >
                        </div>
                        <div class="flex flex-col gap-1">
                            <label>
                                {{ $t('parameters.general.cases.deadlines.inputs.mediation.title') }}
                            </label>
                            <input
                            v-model="parametersValue.general.cases.duration.mediation"
                            :placeholder="$t('parameters.general.cases.deadlines.inputs.commonPlaceholder')"
                            type="number"
                            class="form-input w-full rounded-md"
                            >
                        </div>
                        <div class="col-span-2 flex flex-col gap-1">
                            <label>
                                {{ $t('parameters.general.cases.deadlines.inputs.arbitration.title') }}
                            </label>
                            <input
                            v-model="parametersValue.general.cases.duration.arbitration"
                            :placeholder="$t('parameters.general.cases.deadlines.inputs.commonPlaceholder')"
                            type="number"
                            class="form-input w-full rounded-md"
                            >
                        </div>
                    </div>
                </div>
                <div class="bg-gray-100 p-3">
                    <span class="font-bold text-slate-500">
                        {{ $t('parameters.general.cases.systemOwner.title') }}
                    </span>
                    <p>
                        {{ $t('parameters.general.cases.systemOwner.description') }}
                    </p>
                    <div
                    v-if="parametersValue && parametersValue.general"
                    class="mt-4"
                    >
                        <VueMultiselect
                        :options="users"
                        v-model="parametersValue.general.file_system_case.system_folders_owner"
                        :multiple="false"
                        :close-on-select="true"
                        :placeholder="$t('parameters.general.cases.systemOwner.selectUser')"
                        label="name"
                        track-by="id"
                        :allow-empty="true">
                        <template #singleLabel="props">
                                <span class="option__desc">
                                    <span class="option__title">
                                        {{ props.option.identification }} - {{ props.option.name }}
                                    </span>
                                </span>
                            </template>
                            <template #option="props">
                                <div class="option__desc space-x-2">
                                    <span class="option__title">
                                        {{ props.option.identification }} - {{ props.option.name }}
                                    </span>
                                    <div class="option__small inline rounded-lg px-2 py-1 text-xs font-bold">
                                        {{ props.option.role.name }}
                                    </div>
                                </div>
                            </template>
                        </VueMultiselect>
                    </div>
                </div>
            </div>
        </div>
        <div
        v-permission="'parameters_list_general_cases'"
        class="h-px bg-gray-300 md:-mr-6 md:-ml-6 block md:hidden"></div>
        <div
        :class="[
            'p-2 flex flex-col gap-3',
            {
                'md:border-l-2 md:pl-5 md:py-2 md:pr-2': $methods.hasPermissions(['parameters_list_general_cases'])
            }
        ]"
        v-permission="'parameters_list_general_security'"
        >
            <span class="font-bold text-lg">
                {{ $t('parameters.general.security.title') }}
            </span>
            <div class="flex flex-col gap-5 text-sm">
                <div class="bg-gray-100 p-3">
                    <span class="font-bold text-slate-500">
                        {{ $t('parameters.general.security.registerRole.title') }}
                    </span>
                    <p>
                        {{ $t('parameters.general.security.registerRole.description') }}
                    </p>
                    <div
                    v-if="parametersValue && parametersValue.general"
                    class="mt-4"
                    >
                        <select
                        v-model="parametersValue.general.security.register_role"
                        class="form-select w-full p-2"
                        >
                            <option
                            v-for="role in roles"
                            :key="role.id"
                            :value="role.id">
                                {{ role.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div
        v-permission="'parameters_save_general'"
        class="col-span-2 flex items-center justify-end"
        >
            <button
            @click="saveParameters"
            :class="[
                'btn bg-green-500 text-white w-36',
                {
                    'cursor-not-allowed': saving,
                }
            ]"
            >
                <span v-if="saving">
                    {{ $t('general.loading') }}
                </span>
                <span v-else>
                    {{ $t('button.save') }}
                </span>
            </button>
        </div>
    </div>
    <DualRingLoader v-if="isLoading" />
</template>
<script>
import DualRingLoader from '@/components/DualRingLoader.vue'

export default {
    components: {
        DualRingLoader
    },
    props: {
        toast: {
            type: Function,
            default: () => {}
        }
    },
    data: function () {
        return {
            parameters: [],
            parametersValue: {},
            isLoading: false,
            users: [],
            roles: [],
            promises: [],
            numbers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
            commonValidations: {
                numeric: (value) => (value && value.toString().split('').every(item => this.numbers.some(elem => elem == item))) || !value,
                required: (value) => value != '' && value != null && value != undefined
            },
            validations: [],
            saving: false,
        }
    },
    mounted () {
        this.setValidations()
        this.isLoading = true
        this.getAllParameters()
        this.getAllUsers()
        this.getAllRoles()
        Promise.all(this.promises).then(() => {
            this.isLoading = false
            this.parametersValue.general.file_system_case.system_folders_owner = this.users.find(
                item => item.id == this.parametersValue.general.file_system_case.system_folders_owner
            )
        })
    },
    methods: {
        getValueFromObject (obj, key) {
            let val = JSON.parse(JSON.stringify(obj))
            key.split('.').map(item => {
                if (val && val[item]) {
                    val = val[item]
                } else {
                    val = null
                }
            })
            return val
        },
        setValidations () {
            this.validations = [
                {
                    key: 'general.cases.duration.negotiation',
                    validation: this.commonValidations.numeric,
                    message: 'parameters.general.cases.deadlines.validations.negotiation.numeric'
                },
                {
                    key: 'general.cases.duration.negotiation',
                    validation: this.commonValidations.required,
                    message: 'parameters.general.cases.deadlines.validations.negotiation.required'
                },
                {
                    key: 'general.cases.duration.mediation',
                    validation: this.commonValidations.numeric,
                    message: 'parameters.general.cases.deadlines.validations.mediation.numeric'
                },
                {
                    key: 'general.cases.duration.mediation',
                    validation: this.commonValidations.required,
                    message: 'parameters.general.cases.deadlines.validations.mediation.required'
                },
                {
                    key: 'general.cases.duration.arbitration',
                    validation: this.commonValidations.numeric,
                    message: 'parameters.general.cases.deadlines.validations.arbitration.numeric'
                },
                {
                    key: 'general.cases.duration.arbitration',
                    validation: this.commonValidations.required,
                    message: 'parameters.general.cases.deadlines.validations.arbitration.required'
                },
                {
                    key: 'general.file_system_case.system_folders_owner',
                    validation: this.commonValidations.required,
                    message: 'parameters.general.cases.systemOwner.validations.required'
                },
                {
                    key: 'general.security.register_role',
                    validation: this.commonValidations.required,
                    message: 'parameters.general.security.registerRole.validations.required'
                },
            ]
        },
        getAllParameters () {
            const promise = this.$store.dispatch('parameters/getAllParameters')
            .then(response => {
                if (response.data) {
                    this.parameters = response.data
                    this.parametersValue = {
                        general: {
                            cases: {
                                duration: JSON.parse(JSON.stringify(response.data)).find(item => item.key === 'cases').value.duration
                            },
                            file_system_case: {
                                system_folders_owner: JSON.parse(JSON.stringify(response.data)).find(item => item.key === 'file_system_case').value.system_folders_owner
                            },
                            security: {
                                register_role: JSON.parse(JSON.stringify(response.data)).find(item => item.key === 'security').value.register_role
                            }
                        }
                    }
                }
            }).catch(error => {
                this.toast.error(this.$methods.extractErrorResponse(error, this.$t))
                this.isLoading = false
            })
            this.promises.push(promise)
        },
        getAllUsers () {
            const promise = this.$store.dispatch('user/getAllUsers', { take: 200 }).then(response => {
                if (response.data && response.data.data) {
                    this.users = response.data.data
                }
            }).catch(error => {
                this.toast.error(this.$methods.extractErrorResponse(error, this.$t))
                this.isLoading = false
            })
            this.promises.push(promise)
        },
        getAllRoles () {
            const promise = this.$store.dispatch('roles/getAllRoles', { take: 200 }).then(response => {
                if (response.data && response.data.data) {
                    this.roles = response.data.data
                }
            }).catch(error => {
                this.toast.error(this.$methods.extractErrorResponse(error, this.$t))
                this.isLoading = false
            })
            this.promises.push(promise)
        },
        saveParameters () {
            let error = false
            this.validations.map(item => {
                if (!item.validation(this.getValueFromObject(this.parametersValue, item.key))) {
                    error = true
                    this.toast.error(this.$t(item.message), { timeout: 5000 })
                }
            })
            if (!error && !this.saving) {
                this.saving = true;
                this.$store.dispatch('parameters/saveParameters', {
                    general: {
                        cases: this.parametersValue.general.cases,
                        file_system_case: {
                            system_folders_owner: this.parametersValue.general.file_system_case.system_folders_owner.id
                        },
                        security: this.parametersValue.general.security
                    }
                }).then(response => {
                    this.saving = false;
                    if (response.data) {
                        this.toast.success(this.$t('parameters.save.success'));
                    }
                }).catch(error => {
                    this.toast.error(this.$methods.extractErrorResponse(error, this.$t))
                    this.saving = false
                })
            }
        }
    }
}

</script>