<template>
    <div class="-m-1.5 flex flex-wrap items-center">
        <div class="m-1.5">
            <ModalBasic
                id="notes-modal"
                :backdrop="true"
                :modal-open="isOpen"
                :show-border-header="false"
                :title="$t('calendar.modalRequestDocument.title')"
                @close-modal="close"
            >
                <Form
                    ref="form"
                    v-slot="{ errors }"
                    :validation-schema="newRequestDocumentSchema"
                    @submit="confirm"
                >
                    <div class="px-5 pb-4">
                        <div class="space-y-5">
                            <div>
                                <label
                                    class="mb-1 block text-xs font-bold"
                                    for="title"
                                >
                                    {{
                                        $t('calendar.modalNewAgenda.formTitle')
                                    }}
                                </label>
                                <Field
                                    v-slot="{ field }"
                                    v-model="title"
                                    name="title"
                                >
                                    <input
                                        v-bind="field"
                                        id="title"
                                        class="form-input w-full p-2"
                                        type="text"
                                        required
                                        :placeholder="
                                            $t(
                                                'calendar.modalNewAgenda.formTitle'
                                            )
                                        "
                                    />
                                </Field>
                                <div
                                    v-if="errors.title !== undefined"
                                    class="flex w-full"
                                >
                                    <span class="text-sm text-red-500">
                                        {{ $t(errors.title) }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <label
                                    class="mb-1 block text-xs font-bold"
                                    for="requestTo"
                                >
                                    {{
                                        $t(
                                            'calendar.modalRequestDocument.requestTo'
                                        )
                                    }}
                                </label>
                                <VueMultiselect
                                v-model="party_ids"
                                :options="
                                    caseParties.filter(
                                        (item) => $store.getters['auth/getCurrentUser'] && item.id !== $store.getters['auth/getCurrentUser'].id && !item.was_reassigned
                                    )
                                "
                                :multiple="true"
                                :close-on-select="true"
                                :placeholder="
                                    $t('validation.selectRequestTo')
                                "
                                label="name"
                                track-by="id"
                                :allow-empty="true"
                                >
                                    <template #singleLabel="props">
                                        <span class="option__desc">
                                            <span class="option__title">
                                                {{ props.option.name }}
                                            </span>
                                        </span>
                                    </template>
                                    <template #option="props">
                                        <div class="option__desc space-x-2">
                                            <span class="option__title">
                                                {{ props.option.name }}
                                            </span>
                                            <div class="option__small inline rounded-lg px-2 py-1 text-xs font-bold">
                                                {{ props.option.role.name }}
                                            </div>
                                        </div>
                                    </template>
                                </VueMultiselect>
                            </div>
                            <div class="flex w-full flex-col">
                                <label
                                    class="mb-1 block text-xs font-bold"
                                    for="meetingTime"
                                >
                                    {{
                                        $t(
                                            'calendar.modalRequestDocument.dueTime'
                                        )
                                    }}
                                </label>
                                <Datepicker
                                    v-model="due_date"
                                    :max-today="false"
                                    :max-one-month="false"
                                    :min-today="false"
                                    :min-twoday="true"
                                    :show-icon="false"
                                    :date-only="true"
                                    :new-value="newDatePickerValue"
                                    wrap-class="form-input h-12 w-full rounded-md"
                                    mode="single"
                                    @change-date="changeDate"
                                />
                            </div>
                            <div class="flex w-full flex-col">
                                <label
                                    class="mb-1 block text-xs font-bold"
                                    for="notes"
                                >
                                    {{
                                        $t(
                                            'calendar.modalRequestDocument.additionalNotes'
                                        )
                                    }}
                                </label>

                                <Field
                                    v-slot="{ field }"
                                    v-model="notes"
                                    name="notes"
                                >
                                    <textarea
                                        v-bind="field"
                                        class="form-input w-full resize-y p-2"
                                        :placeholder="
                                            $t(
                                                'calendar.modalRequestDocument.additionalNotes'
                                            )
                                        "
                                    ></textarea>
                                </Field>
                                <div
                                    v-if="errors.notes !== undefined"
                                    class="flex w-full"
                                >
                                    <span class="text-sm text-red-500">
                                        {{ $t(errors.notes) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-5 py-4">
                        <div class="flex flex-wrap justify-center space-x-2">
                            <button
                                type="submit"
                                class="btn-sm w-full whitespace-nowrap rounded-lg bg-sky-500 p-3 text-white hover:bg-sky-600"
                            >
                                {{ $t('button.save') }}
                            </button>
                        </div>
                    </div>
                </Form>
            </ModalBasic>
        </div>
    </div>
</template>
<script>
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'
import ModalBasic from '@/components/ModalBasic.vue'
import Datepicker from '@/components/Datepicker.vue'

export default {
    name: 'ModalNewAgendaComponent',
    components: {
        Form,
        Field,
        ModalBasic,
        Datepicker,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        caseParties: {
            type: Array,
            default: () => {
                return []
            },
        },
        meetingTypes: {
            type: Array,
            default: () => {
                return []
            },
        },
        currentDatesSelected: {
            type: Object,
            default: null
        }
    },
    emits: ['closed', 'confirm', 'empty-member'],
    data: () => ({
        party_ids: [],
        parties_id_should_accept: [],
        require_acceptance_everyone: true,
        title: '',
        notes: '',
        due_date: null,
        newDatePickerValue: null,
        newRequestDocumentSchema: Yup.object().shape({
            title: Yup.string()
                .typeError('validation.enterAgendaTitle')
                .required('validation.enterAgendaTitle'),
            notes: Yup.string()
                .typeError('validation.enterAgenda')
                .required('validation.enterAgenda'),
        }),
        filterDataFileTypes: {
            page: 1,
            take: 50,
            search: '',
            order: 'DESC',
        },
        metaDataFileTypes: {
            page: 1,
            take: 10,
            itemCount: 10,
            pageCount: 1,
            hasPreviousPage: false,
            hasNextPage: true,
        },
    }),
    watch: {
        isOpen(val) {
            if (!val) this.close()
            else this.$refs.form.resetForm()
        },
        currentDatesSelected (val) {
            if (val) {
                this.newDatePickerValue = new Date(val.start.add(1, 'day').format('YYYY-MM-DD'));
            }
        }
    },
    methods: {
        close() {
            this.$refs.form.resetForm()
            this.party_ids = []
            this.$emit('closed')
        },
        confirm() {
            if (this.party_ids.length <= 0) return this.$emit('empty-member')

            const newRequestDocumentData = {}
            newRequestDocumentData.party_ids = this.party_ids.map((item) => item.id)
            newRequestDocumentData.parties_id_should_accept = this.parties_id_should_accept.map((item) => item.id)
            newRequestDocumentData.title = this.title
            newRequestDocumentData.description = this.notes
            newRequestDocumentData.due_date = this.due_date
            newRequestDocumentData.require_acceptance_everyone = this.require_acceptance_everyone
            this.$emit('confirm', newRequestDocumentData)
        },
        changeDate(val) {
            this.due_date = val
        },
    },
}
</script>
