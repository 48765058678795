<template>
    <table class="w-full rounded-lg text-sm">
        <thead class="rounded-lg sticky top-0">
            <tr class="rounded-lg">
                <td
                v-for="(header, index) in headers"
                :key="index"
                :class="[
                    headerClass,
                    'p-2 bg-sky-500 text-white text-center border-t-0',
                    {
                        'rounded-tl-xl': index === 0,
                        'rounded-tr-xl': index === (headers.length - 1),
                        'border-l-2 border-white': index > 0
                    }
                ]">
                    {{ header.label }}
                </td>
            </tr>
        </thead>
        <tbody>
            <template
            v-for="(item, index) in list"
            :key="index"
            >
                <tr
                :class="[
                    rowClass,
                    (index % 2 === 0) ? 'bg-white' : 'bg-sky-100'
                ]"
                >
                    <td
                    v-for="(header, index) in headers"
                    :key="index"
                    :class="[
                        'p-2 text-center',
                        header.cellClass
                    ]"
                    >
                        <slot
                        :item="item"
                        v-if="header.isSlot"
                        :name="`cell-${header.id}`"
                        ></slot>
                        <div v-if="!header.isSlot && !header.column.action">
                            {{
                                (header.column.funct)
                                    ? header.column.funct(item[header.column.key])
                                    : item[header.column.key]
                            }}
                        </div>
                        <div
                        v-if="!header.isSlot && header.column.action"
                        class="flex items-center justify-center"
                        >
                            <div class="flex gap-2">
                                <button
                                @click="$emit('edit', item)"
                                v-if="
                                    header.column.action.edit
                                    &&
                                    (
                                        (
                                            header.column.action.edit.permission
                                            &&
                                            this.$methods.hasPermissions([header.column.action.edit.permission])
                                        )
                                        ||
                                        !header.column.action.edit.permission
                                    )
                                "
                                class="btn bg-yellow-300 w-7 h-7"
                                >
                                    <i class="fa-solid fa-pen text-slate-600 text-xs"></i>
                                </button>
                                <button
                                @click="$emit('delete', item)"
                                v-if="
                                    header.column.action.delete
                                    &&
                                    (
                                        (
                                            header.column.action.delete.permission
                                            &&
                                            this.$methods.hasPermissions([header.column.action.delete.permission])
                                        )
                                        ||
                                        !header.column.action.delete.permission
                                    )
                                "
                                class="btn bg-red-500 w-7 h-7"
                                >
                                    <i class="fa-solid fa-trash text-white text-xs"></i>
                                </button>
                                <slot
                                v-if="showOtherActionsSlot"
                                :item="item"
                                name="other-actions"
                                ></slot>
                            </div>
                        </div>
                    </td>
                </tr>
                <slot
                :item="item"
                v-if="showAdditionalRowSlot"
                name="additional-row"
                ></slot>
            </template>
        </tbody>
    </table>
</template>
<script>

export default {
    props: {
        list: {
            type: Array,
            default: []
        },
        headers: {
            type: Array,
            default: []
        },
        headerClass: {
            type: String,
            default: null
        },
        rowClass: {
            type: String,
            default: null
        },
        showAdditionalRowSlot: {
            type: Boolean,
            default: false
        },
        showOtherActionsSlot: {
            type: Boolean,
            default: false
        },
    },
    emits: ['edit', 'delete']
}

</script>