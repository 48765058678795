import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'
import { toBase64 } from '../../utils/Utils.js'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
    createAgenda({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            let params = {
                title: payload.title ? payload.title : null,
                party_ids: payload.party_ids ? payload.party_ids : [],
                type: payload.type ? payload.type : null,
                description: payload.description ? payload.description : null,
                detail: payload.detail ? payload.detail : null,
                due_date: payload.due_date ? payload.due_date : null,
                meeting_date: payload.meeting_date ? payload.meeting_date : null,
                meeting_url: payload.meeting_url ? payload.meeting_url : null,
                meeting_in_person_address: payload.meeting_in_person_address ? payload.meeting_in_person_address : null,
                meeting_type: payload.meeting_type ? payload.meeting_type : null,
                meeting_time_from: payload.meeting_time_from ? payload.meeting_time_from : null,
                meeting_time_to: payload.meeting_time_to ? payload.meeting_time_to : null,
                case_id: payload.case_id ? payload.case_id : null,
                parties_id_should_accept: payload.parties_id_should_accept ? payload.parties_id_should_accept : null,
                require_acceptance_everyone: payload.require_acceptance_everyone ? payload.require_acceptance_everyone : null,
            }

            axios
                .post(`${routePathAPI()}/calendarsagendas`, params, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200 || response.status === 201) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    approveOrRequestRectificationDocument({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        const config = {
            headers: {
                'x-language-code': currentLanguageCode,
            },
        }
        return new Promise((resolve, reject) => {
            axios.post(
                `${routePathAPI()}/calendarsagendas/request/approval/action`,
                payload,
                config
            )
            .then((response) => {
                const responseData = response.data
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: responseData,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: responseData,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    acceptOrRejectRequest({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        const config = {
            headers: {
                'x-language-code': currentLanguageCode,
            },
        }
        return new Promise((resolve, reject) => {
            let newPayload = JSON.parse(JSON.stringify(payload))
            toBase64(payload.attachment).then(result => {
                newPayload.attachment = result
                    ? {
                        cont: result.split(',').pop(),
                        name: payload.attachment.name.split('.')[0],
                        ext: payload.attachment.name.split('.').pop(),
                        size: payload.attachment.size
                    }
                    : null
                newPayload.agenda_id = payload.id
                axios
                .post(
                    `${routePathAPI()}/calendarsagendas/request/action`,
                    newPayload,
                    config
                )
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200 && responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
            })
        })
    },
    getAgendaDetail({ commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            axios
                .get(`${routePathAPI()}/calendar/detail/${payload.id}`, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    const responseData = response.data
                    if (response.status === 200 && responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAllAgenda({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            let params = {}
            if (payload.page) params.page = payload.page
            if (payload.take) params.per_page = payload.take
            if (payload.order) params.order = 'created_at.' + payload.order
            if (payload.q) params.search = payload.q
            if (payload.type) params.type = payload.type // Meeting, Alert
            if (payload.date) params.date = payload.date
            if (payload.dateRange) params.dateRange = payload.dateRange

            axios
                .get(`${routePathAPI()}/calendarsagendas/byCase/${payload.case_id}`, {
                    params: params,
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    const responseData = response.data
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAllDateline({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {}
            params.page = payload.page ? payload.page : 1
            params.per_page = payload.take ? payload.take : 10
            params.order = 'created_at.' + (payload.order ? payload.order : 'DESC')

            axios.get(`${routePathAPI()}/calendarsagendas/deadlines/byCase/${payload.case_id}`, {
                params: params,
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            })
            .then(async (response) => {
                const responseData = response.data
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: responseData,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: responseData,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    uploadMeetingVideo({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        const config = {
            headers: {
                'x-language-code': currentLanguageCode,
            },
        }
        return new Promise((resolve, reject) => {
            let newPayload = JSON.parse(JSON.stringify(payload))
            toBase64(payload.attachment).then(result => {
                newPayload.attachment = result
                    ? {
                        cont: result.split(',').pop(),
                        name: payload.attachment.name.split('.')[0],
                        ext: payload.attachment.name.split('.').pop(),
                        size: payload.attachment.size
                    }
                    : null
                newPayload.agenda_id = payload.id
                axios
                .post(
                    `${routePathAPI()}/calendarsagendas/meetingvideo/upload`,
                    newPayload,
                    config
                )
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
            })
        })
    },
    getForSelectUser({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {}
            params.type = payload.type ? payload.type : null

            axios.get(`${routePathAPI()}/calendarsagendas/getForSelect/userIsOnIt`, {
                params: params,
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            })
            .then(async (response) => {
                const responseData = response.data
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: responseData,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: responseData,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
}

// mutations
const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
