<template>
  <div class="flex flex-row items-center">
    <img
      class="mr-3 shrink-0 rounded-full"
      :src="userData.avatar !== null ? userData.avatar : defaultImage"
      width="40"
      height="40"
      :alt="userData.name"
    />
    <div class="w-full pl-4">
      <div class="flex w-full text-xs font-semibold text-slate-400">
        <span v-if="userData.role === 'Mediator'">
          {{ $t('mediationAgreement.convener') }}
        </span>
        <span v-else>
          {{ $t('mediationAgreement.respondent') }}
        </span>
      </div>
      <div class="flex w-full text-xs font-semibold text-slate-800">
        <p class="line-clamp-1">
          {{ getName(userData, 'name', 25) }}
        </p>
        <span v-if="userData.id === activeUserId" class="pl-2">
          {{ $t('you') }}
        </span>
        <div
          v-if="userData.role"
          class="ml-2 flex w-max rounded-lg px-2"
          :class="userData.role === 'Mediator' ? 'bg-red-100' : 'bg-blue-100'"
        >
          <p
            class="text-xs font-bold"
            :class="
              userData.role === 'Mediator' ? 'text-red-500' : 'text-blue-700'
            "
          >
            {{ userData.role }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
import globalMixin from '@/mixins/global.js'
import { useStore } from 'vuex'

export default {
  name: 'MediationAgreementUserComponent',
  mixins: [globalMixin],
  inject: ['defaultImage'],
  props: {
    userData: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  setup() {
    const store = useStore()
    const activeUser = store.getters['auth/activeUser']
    const activeUserId = ref(null)
    activeUserId.value = activeUser.id

    return {
      activeUserId,
    }
  },
}
</script>
