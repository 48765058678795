<template>
    <div>
        <!-- Sidebar backdrop (mobile only) -->
        <div class="fixed inset-0 z-40 bg-white bg-opacity-30 transition-opacity duration-200 lg:z-auto lg:hidden"
            :class="sidebarOpen ? 'opacity-100' : 'pointer-events-none opacity-0'" aria-hidden="true"></div>

        <!-- Sidebar -->
        <div
        id="sidebar"
        ref="sidebar"
        class="no-scrollbar lg:sidebar-expanded:!w-64 absolute left-0 top-0 z-40 flex h-screen w-64 shrink-0 transform flex-col overflow-y-scroll bg-white p-4 transition-all duration-200 ease-in-out lg:static lg:left-auto lg:top-auto lg:w-20 lg:translate-x-0 lg:overflow-y-auto 2xl:!w-64"
        :class="sidebarOpen ? 'translate-x-0' : '-translate-x-64'">
            <!-- Sidebar header -->
            <div class="mb-10 flex flex-col pr-3 sm:px-2">
                <div
                v-if="$route.meta.isForCaseSidebar"
                class="flex w-full cursor-pointer flex-row items-center"
                >
                    <router-link :to="{ name: 'index' }" class="flex items-center">
                        <ArrowBackIcon class="h-7 w-7 fill-current text-slate-500" :class="sidebarExpanded && 'w-3'" />
                        <span v-if="sidebarExpanded" class="ml-2 text-xs text-slate-500 hover:text-sky-400">
                            {{ $t('action.back') }}
                        </span>
                    </router-link>
                </div>
                <div id="logo" class="mt-12 flex w-full justify-center">
                    <!-- Logo -->
                    <!-- v-if="sidebarExpanded === true" -->
                    <template v-if="sidebarExpanded === true">
                        <router-link :to="{ name: 'index' }">
                            <img class="w-36 object-cover object-center" src="/src/assets/images/logo.png" alt="Redek Logo" />
                        </router-link>
                    </template>
                    <template v-else>
                        <router-link :to="{ name: 'index' }">
                            <img class="w-8 object-cover object-center" src="/src/assets/images/icon.png" alt="Redek Icon" />
                        </router-link>
                    </template>
                </div>
            </div>

            <!-- Links -->
            <div>
                <div v-if="$route.meta.isForCaseSidebar">
                    <!-- Pages group -->

                    <ul>
                        <!-- Dashboard -->
                        <li
                        v-permission="'cases_manage_dashboard'"
                        class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                        :class="sidebarExpanded && 'px-4'"
                        >
                            <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                @click="openLink('dashboard')">
                                <div class="flex items-center">
                                    <i class="fa-solid fa-house text-xl" :class="currentRoute.fullPath.includes('dashboard') && 'text-sky-400'"></i>
                                    <span
                                    class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                    :class="currentRoute.fullPath.includes('dashboard') && 'text-sky-400'">
                                        {{ $t('menu.dashboard') }}
                                    </span>
                                </div>
                            </a>
                        </li>

                        <!-- Final Decision -->
                        <li v-if="parseInt(caseStageSidebar) === 5"
                            class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                            :class="sidebarExpanded && 'px-4'">
                            <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                @click="openLink('final-decision')">
                                <div class="flex items-center">
                                    <i class="fa-solid fa-circle-check text-xl" :class="currentRoute.fullPath.includes('final-decision') && 'text-sky-400'"></i>
                                    <span
                                        class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                        :class="currentRoute.fullPath.includes('final-decision') &&
                                            'text-sky-400'
                                            ">
                                        {{ $t('menu.finalDecision') }}
                                    </span>
                                </div>
                            </a>
                        </li>

                        <!-- Case Stage -->
                        <li
                        v-permission="'cases_manage_case_stage'"
                        id="caseStages"
                        class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                            :class="sidebarExpanded && 'px-4'">
                            <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                @click="openLink('case-stage')">
                                <div class="flex items-center">
                                    <i class="fa-solid fa-compass text-xl" :class="currentRoute.fullPath.includes('case-stage') && 'text-sky-500'"></i>
                                    <span
                                        class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                        :class="currentRoute.fullPath.includes('case-stage') &&
                                            'text-sky-400'
                                            ">
                                        {{ $t('menu.caseStage') }}
                                    </span>
                                </div>
                            </a>
                        </li>

                        <!-- PrivateNotebook -->
                        <li
                        v-permission="'cases_manage_private_notebook_list'"
                        id="privateNotebook"
                        class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                        :class="sidebarExpanded && 'px-4'">
                            <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                @click="openLink('private-notebook')">
                                <div class="flex items-center">
                                    <i class="fa-solid fa-pencil text-xl" :class="currentRoute.fullPath.includes('private-notebook') && 'text-sky-500'"></i>
                                    <span
                                        class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                        :class="currentRoute.fullPath.includes('private-notebook') &&
                                            'text-sky-400'
                                            ">
                                        {{ $t('menu.privateNotebook') }}
                                    </span>
                                </div>
                            </a>
                        </li>
                    </ul>

                    <!-- Chat -->
                    <div class="mt-5">
                        <h3 class="pl-2 text-xs font-semibold uppercase text-slate-500">
                            <span class="lg:sidebar-expanded:block lg:hidden 2xl:block">
                                {{ $t('menu.communication.title') }}
                            </span>
                        </h3>
                        <ul class="mt-3">
                            <li
                            id="chats"
                            v-permission="'cases_manage_chats_list'"
                            class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                                :class="sidebarExpanded && 'px-4'">
                                <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                    @click="openLink('chat-group')">
                                    <div class="flex items-center">
                                        <i class="fa-solid fa-comment-dots text-xl" :class="currentRoute.fullPath.includes('chats') && 'text-sky-500'"></i>
                                        <span
                                            class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                            :class="currentRoute.fullPath.includes('chats') &&
                                                'text-sky-400'
                                                ">
                                            {{ $t('menu.communication.chats') }}
                                        </span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <!-- <ul class="mt-3" :class="sidebarExpanded && 'pl-2'">
                            <li
                            class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                            :class="sidebarExpanded && 'px-4'"
                            >
                            <a
                                class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                @click="openLink('customer-service-chats')"
                            >
                                <div class="flex items-center">

                                <ChatIcon
                                    class="h-6 w-6 shrink-0"
                                    :class="
                                    currentRoute.fullPath.includes('customer-service') && '!text-sky-500'
                                    "
                                />

                                <span
                                    class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                    :class="
                                    currentRoute.fullPath.includes('customer-service') &&
                                    'text-sky-400'
                                    "
                                    >Customer service chat</span
                                >
                                </div>
                            </a>
                            </li>
                        </ul> -->
                    </div>

                    <div>
                        <ul>
                            <!-- Documents -->
                            <li
                            v-permission="'cases_manage_documents'"
                            id="documents"
                            class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                            :class="sidebarExpanded && 'px-4'">
                                <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                    @click="openLink('documents')">
                                    <div class="flex items-center">
                                        <i class="fa-solid fa-file text-xl" :class="currentRoute.fullPath.includes('documents') && 'text-sky-500'"></i>
                                        <span
                                            class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                            :class="currentRoute.fullPath.includes('documents') &&
                                                'text-sky-400'
                                                ">{{ $t('menu.documents') }}</span>
                                    </div>
                                </a>
                            </li>

                            <!-- Timelines -->
                            <li
                            id="timeline"
                            v-permission="'cases_manage_timeline'"
                            class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                            :class="sidebarExpanded && 'px-4'">
                                <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                    @click="openLink('timeline')">
                                    <div class="flex items-center">
                                        <i class="fa-solid fa-clock text-xl" :class="currentRoute.fullPath.includes('timeline') && 'text-sky-500'"></i>
                                        <span
                                            class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                            :class="currentRoute.fullPath.includes('timeline') &&
                                                'text-sky-400'
                                                ">{{ $t('menu.timeline') }}</span>
                                    </div>
                                </a>
                            </li>

                            <!-- Calendar -->
                            <li
                            v-permission="'cases_manage_calendar'"
                            id="calendar"
                            class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                            :class="sidebarExpanded && 'px-4'">
                                <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                    @click="openLink('calendar')">
                                    <div class="flex items-center">
                                        <i class="fa-solid fa-calendar-week text-xl" :class="currentRoute.fullPath.includes('calendar') && 'text-sky-500'"></i>
                                        <span
                                            class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                            :class="currentRoute.fullPath.includes('calendar') &&
                                                'text-sky-400'
                                                ">{{ $t('menu.calendar') }}</span>
                                    </div>
                                </a>
                            </li>

                            <!-- Case Detail -->
                            <li
                            v-permission="'cases_manage_case_details'"
                            id="caseMoreDetail"
                            class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                            :class="sidebarExpanded && 'px-4'">
                                <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                    @click="openLink('case-detail')">
                                    <div class="flex items-center">
                                        <i class="fa-solid fa-circle-info text-xl" :class="currentRoute.fullPath.includes('case-detail') && 'text-sky-500'"></i>
                                        <span
                                            class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                            :class="currentRoute.fullPath.includes('case-detail') &&
                                                'text-sky-400'
                                                ">{{ $t('menu.caseDetails') }}</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div v-else>
                    <div
                    v-permission="'dashboard_admin'"
                    class="mt-10">
                        <ul>
                            <!-- Dashboard -->
                            <li class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                                :class="sidebarExpanded && 'px-4'">
                                <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                    @click="openLink('admin-dashboard')">
                                    <div class="flex items-center">
                                        <i class="fa-solid fa-chart-line text-xl" :class="currentRouteName === 'admin-dashboard' && 'text-sky-500'"></i>
                                        <span
                                            class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                            :class="currentRouteName === 'admin-dashboard' && 'text-sky-400'">
                                            {{ $t('menu.dashboard') }}
                                        </span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div
                    v-permission="'parameters_list'">
                        <ul>
                            <!-- Dashboard -->
                            <li class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                                :class="sidebarExpanded && 'px-4'">
                                <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                    @click="openLink('admin-parameters')">
                                    <div class="flex items-center">
                                        <i class="fa-solid fa-gears text-xl" :class="currentRouteName === 'admin-parameters' && 'text-sky-500'"></i>
                                        <span
                                            class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                            :class="currentRouteName === 'admin-parameters' && 'text-sky-400'">
                                            {{ $t('menu.parameters') }}
                                        </span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div
                    v-permission="'security'"
                    class="mt-5">
                        <h3 class="pl-2 text-xs font-semibold uppercase text-slate-500" :class="sidebarExpanded && 'pl-4'">
                            <span class="lg:sidebar-expanded:block lg:hidden 2xl:block">
                                {{ $t('menu.security.title') }}
                            </span>
                        </h3>
                        <ul class="mt-3" :class="sidebarExpanded && 'pl-2'">
                            <!-- Users -->
                            <li
                            v-permission="'security_users_management_list'"
                            class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                                :class="sidebarExpanded && 'px-4'">
                                <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                    @click="openLink('admin-users')">
                                    <div class="flex items-center">
                                        <i class="fa-solid fa-users text-xl" :class="currentRouteName === 'admin-users' && 'text-sky-500'"></i>
                                        <span
                                            class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                            :class="currentRouteName === 'admin-users' && 'text-sky-400'">
                                            {{ $t('menu.security.users') }}
                                        </span>
                                    </div>
                                </a>
                            </li>
                            <!-- Roles -->
                            <li
                            v-permission="'security_roles_management_list'"
                            class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                                :class="sidebarExpanded && 'px-4'">
                                <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                    @click="openLink('admin-roles')">
                                    <div class="flex items-center">
                                        <i class="fa-solid fa-user-shield text-xl" :class="currentRouteName === 'admin-roles' && 'text-sky-500'"></i>
                                        <span
                                            class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                            :class="currentRouteName === 'admin-roles' && 'text-sky-400'">
                                            {{ $t('menu.security.roles') }}
                                        </span>
                                    </div>
                                </a>
                            </li>
                            <!-- Permissions -->
                            <li
                            v-permission="'security_permissions_management_list'"
                            class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                                :class="sidebarExpanded && 'px-4'">
                                <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                    @click="openLink('admin-permissions')">
                                    <div class="flex items-center">
                                        <i class="fa-solid fa-shield-halved text-xl" :class="currentRouteName === 'admin-permissions' && 'text-sky-500'"></i>
                                        <span
                                            class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                            :class="currentRouteName === 'admin-permissions' && 'text-sky-400'">
                                            {{ $t('menu.security.permissions') }}
                                        </span>
                                    </div>
                                </a>
                            </li>
                            <!-- <li
                            class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                            :class="sidebarExpanded && 'px-4'"
                            >
                                <a
                                class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                @click="openLink('admin-activities')"
                                >
                                    <div class="flex items-center">
                                        <TimelineIcon
                                        class="h-6 w-6 shrink-0"
                                        :class="
                                            currentRouteName === 'admin-activities' &&
                                            'text-sky-300'
                                        "
                                        />
                                        <span
                                        class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                        :class="
                                            currentRouteName === 'admin-activities' &&
                                            'text-sky-400'
                                        "
                                        >
                                            {{ $t('menu.timeline') }}
                                        </span>
                                    </div>
                                </a>
                            </li> -->
                        </ul>
                    </div>
                    <div
                    v-permission="'cases'"
                    class="mt-5">
                        <h3 class="pl-2 text-xs font-semibold uppercase text-slate-500" :class="sidebarExpanded && 'pl-4'">
                            <span class="lg:sidebar-expanded:block lg:hidden 2xl:block">
                                {{ $t('menu.cases.title') }}
                            </span>
                        </h3>
                        <ul class="mt-3" :class="sidebarExpanded && 'pl-2'">
                            <li
                            v-permission="'cases_list_status_all'"
                            class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                                :class="sidebarExpanded && 'px-4'">
                                <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                    @click="openLink('all-cases')">
                                    <div class="flex items-center">
                                        <i class="fa-solid fa-briefcase text-xl" :class="currentRouteName === 'all-cases' && 'text-sky-500'"></i>
                                        <span
                                            class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                            :class="currentRouteName === 'all-cases' && 'text-sky-400'">
                                            {{ $t('menu.cases.allCase') }}
                                        </span>
                                    </div>
                                </a>
                            </li>
                            <li
                            v-permission="'cases_list_status_pending'"
                            class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                                :class="sidebarExpanded && 'px-4'">
                                <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                    @click="openLink('pending-cases')">
                                    <div class="flex items-center">
                                        <i class="fa-solid fa-clock text-xl" :class="currentRouteName === 'pending-cases' && 'text-sky-500'"></i>
                                        <span
                                            class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                            :class="currentRouteName === 'pending-cases' && 'text-sky-400'">
                                            {{ $t('menu.cases.pendingCase') }}
                                        </span>
                                    </div>
                                </a>
                            </li>
                            <li
                            v-permission="'cases_list_status_ongoing'"
                            class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                                :class="sidebarExpanded && 'px-4'">
                                <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                    @click="openLink('ongoing-cases')">
                                    <div class="flex items-center">
                                        <i class="fa-solid fa-users-line text-xl" :class="currentRouteName === 'ongoing-cases' && 'text-sky-500'"></i>
                                        <span
                                            class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                            :class="currentRouteName === 'ongoing-cases' && 'text-sky-400'">
                                            {{ $t('menu.cases.ongoingCase') }}
                                        </span>
                                    </div>
                                </a>
                            </li>
                            <li
                            v-permission="'cases_list_status_solved'"
                            class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                                :class="sidebarExpanded && 'px-4'">
                                <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                    @click="openLink('settled-cases')">
                                    <div class="flex items-center">
                                        <i class="fa-solid fa-circle-check text-xl" :class="currentRouteName === 'settled-cases' && 'text-sky-500'"></i>
                                        <span
                                            class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                            :class="currentRouteName === 'settled-cases' && 'text-sky-400'">
                                            {{ $t('menu.cases.settledCase') }}
                                        </span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="mt-2">
                        <ul>
                            <!-- Logout -->
                            <li class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                                :class="sidebarExpanded && 'px-4'">
                                <span class="block truncate text-red-400 transition duration-150 hover:text-sky-500"
                                    @click="logout">
                                    <div class="flex items-center">
                                        <LogoutIcon class="h-6 w-6 shrink-0 text-red-700" />
                                        <span
                                            class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium text-red-400 duration-200 lg:opacity-0 2xl:opacity-100">
                                            {{ $t('menu.logout') }}
                                        </span>
                                    </div>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>


                <!-- PCC (Menu) -->
                <!-- <template v-if="activeUserRole === 'REDEK_CS'"> -->
                <template v-if="false">
                    <div class="mt-5">
                        <h3 class="pl-2 text-xs font-semibold uppercase text-slate-500" :class="sidebarExpanded && 'pl-4'">
                            <span class="lg:sidebar-expanded:block lg:hidden 2xl:block">
                                {{ $t('menu.inbox.title') }}
                            </span>
                        </h3>
                        <ul class="mt-3" :class="sidebarExpanded && 'pl-2'">
                            <li class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                                :class="sidebarExpanded && 'px-4'">
                                <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                    @click="openLink('all-cs-chat')">
                                    <div class="flex items-center">
                                        <MessageIcon class="h-6 w-6 shrink-0" :class="currentRouteName === 'all-cs-chat' && 'text-sky-500'
                                            " />

                                        <span
                                            class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                            :class="currentRouteName === 'all-cs-chat' && 'text-sky-400'
                                                ">
                                            {{ $t('menu.inbox.allInbox') }}
                                        </span>
                                    </div>
                                </a>
                            </li>
                            <li class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                                :class="sidebarExpanded && 'px-4'">
                                <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                    @click="openLink('unanswered-cs-chat')">
                                    <div class="flex items-center">
                                        <InfoIcon class="h-6 w-6 shrink-0" :class="currentRouteName === 'unanswered-cs-chat' &&
                                            '!text-sky-500'
                                            " />

                                        <span
                                            class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                            :class="currentRouteName === 'unanswered-cs-chat' &&
                                                'text-sky-400'
                                                ">
                                            {{ $t('menu.inbox.unanswered') }}
                                        </span>
                                    </div>
                                </a>
                            </li>
                            <li class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                                :class="sidebarExpanded && 'px-4'">
                                <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                    @click="openLink('answered-cs-chat')">
                                    <div class="flex items-center">
                                        <UserGroupIcon class="h-6 w-6 shrink-0" :class="currentRouteName === 'answered-cs-chat' &&
                                            '!text-sky-500'
                                            " />

                                        <span
                                            class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                            :class="currentRouteName === 'answered-cs-chat' &&
                                                'text-sky-400'
                                                ">
                                            {{ $t('menu.inbox.answered') }}
                                        </span>
                                    </div>
                                </a>
                            </li>
                            <li class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                                :class="sidebarExpanded && 'px-4'">
                                <a class="block truncate text-slate-400 transition duration-150 hover:text-sky-500"
                                    @click="openLink('archived-cs-chat')">
                                    <div class="flex items-center">
                                        <CaseDetailIcon class="h-6 w-6 shrink-0" :class="currentRouteName === 'archived-cs-chat' &&
                                            '!text-sky-500'
                                            " />

                                        <span
                                            class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                                            :class="currentRouteName === 'archived-cs-chat' &&
                                                'text-sky-400'
                                                ">
                                            {{ $t('menu.inbox.archived') }}
                                        </span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="mt-2">
                        <ul>
                            <!-- Dashboard -->
                            <li class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                                :class="sidebarExpanded && 'px-4'">
                                <span class="block truncate text-red-400 transition duration-150 hover:text-sky-500"
                                    @click="logout">
                                    <div class="flex items-center">
                                        <LogoutIcon class="h-6 w-6 shrink-0 text-red-700" />
                                        <span
                                            class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium text-red-400 duration-200 lg:opacity-0 2xl:opacity-100">
                                            {{ $t('menu.logout') }}
                                        </span>
                                    </div>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <!-- End of PCC Menu -->
                </template>
            </div>

            <!-- Expand / collapse button -->
            <div class="mt-auto hidden justify-between pt-3 lg:inline-flex 2xl:hidden">
                <div class="flex justify-start pl-2" :class="!sidebarExpanded && 'hidden'">
                </div>
                <div class="flex py-2">
                    <button @click.prevent="sidebarExpanded = !sidebarExpanded">
                        <span class="sr-only">Expand / collapse sidebar</span>
                        <svg class="h-10 w-12" :class="!sidebarExpanded && 'rotate-180'" viewBox="0 0 50 42" fill="none">
                            <path d="M33.0645 15.0566L28.2258 19.8114L33.0645 24.5661" stroke="#6F6C99"
                                stroke-width="2.4" />
                            <path d="M23.3871 16.6416L20.1613 19.8114L23.3871 22.9812" stroke="#6F6C99"
                                stroke-width="2.4" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import Languages from '@/components/DropdownLanguages.vue'

import FinalIcon from '@/assets/svg/final.svg'
import DashboardIcon from '@/assets/svg/dashboard.svg'
import CaseStageIcon from '@/assets/svg/case_stage.svg'
import PrivateNoteIcon from '@/assets/svg/private_note.svg'
import ChatIcon from '@/assets/svg/chat.svg'
import DocumentIcon from '@/assets/svg/document.svg'
import TimelineIcon from '@/assets/svg/timeline.svg'
import CalendarIcon from '@/assets/svg/calendar.svg'
import CaseDetailIcon from '@/assets/svg/case_detail.svg'
import UserIcon from '@/assets/svg/profile.svg'
import UserGroupIcon from '@/assets/svg/user_group.svg'
import MessageIcon from '@/assets/svg/message.svg'
import InfoIcon from '@/assets/svg/info.svg'
import LogoutIcon from '@/assets/svg/logout.svg'
import ArrowBackIcon from '@/assets/svg/arrow-back.svg'
import PermissionsIcon from '@/assets/svg/permissions.svg'
import RolesIcon from '@/assets/svg/roles.svg'

export default {
    name: 'SidebarPartials',
    components: {
        Languages,
        FinalIcon,
        DashboardIcon,
        CaseStageIcon,
        PrivateNoteIcon,
        ChatIcon,
        DocumentIcon,
        TimelineIcon,
        CalendarIcon,
        CaseDetailIcon,
        UserIcon,
        UserGroupIcon,
        MessageIcon,
        InfoIcon,
        LogoutIcon,
        ArrowBackIcon,
        PermissionsIcon,
        RolesIcon,
    },
    watch: {
        caseStage: function (val) {
            if (val) {
                this.caseStageSidebar = val;
            }
        }
    },
    mounted () {
        if (this.getTheCase) {
            this.$store
            .dispatch('cases/getCaseDetail', {
                id: this.$route.params.slug,
            }).then((response) => {
                this.caseStageSidebar = response.data.stage;
                // this.$store.dispatch('cases/setActiveCaseVuex', response.data);
            })
            .catch((error) => {
                this.toast.error(this.extractErrorResponse(error))
            })
        }
    },
    props: ['sidebarOpen', 'caseStage', 'getTheCase'],
    setup(props, { emit }) {
        const store = useStore()
        const route = useRoute()
        const router = useRouter()
        const currentRoute = router.currentRoute.value
        const currentRouteName = route.name
        const trigger = ref(null)
        const sidebar = ref(null)
        const caseStageSidebar = ref(null)

        const storedSidebarExpanded = localStorage.getItem('sidebar-expanded')
        const sidebarExpanded = ref(
            storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
        )

        // close on click outside
        const clickHandler = ({ target }) => {
            if (!sidebar.value || !trigger.value) return
            if (
                !props.sidebarOpen ||
                sidebar.value.contains(target) ||
                trigger.value.contains(target)
            )
                return
            emit('close-sidebar')
        }

        // close if the esc key is pressed
        const keyHandler = ({ keyCode }) => {
            if (!props.sidebarOpen || keyCode !== 27) return
            emit('close-sidebar')
        }

        const openLink = (routeName) => {
            router.push({
                name: routeName,
                params: { slug: route.params.slug },
                query: {
                    name: route.query.name,
                    status: route.query.status,
                    sub_status: route.query.sub_status,
                    lang: route.query.lang,
                },
            })
        }

        const logout = () => {
            store.dispatch('auth/logout')
            router.push({ name: 'login' })
        }

        onMounted(() => {
            document.addEventListener('click', clickHandler)
            document.addEventListener('keydown', keyHandler)
        })

        onUnmounted(() => {
            document.removeEventListener('click', clickHandler)
            document.removeEventListener('keydown', keyHandler)
        })

        watch(sidebarExpanded, () => {
            localStorage.setItem('sidebar-expanded', sidebarExpanded.value)
            if (sidebarExpanded.value) {
                document.querySelector('body').classList.add('sidebar-expanded')
            } else {
                document.querySelector('body').classList.remove('sidebar-expanded')
            }
        })

        return {
            store,
            router,
            trigger,
            sidebar,
            sidebarExpanded,
            currentRoute,
            currentRouteName,
            openLink,
            logout,
            caseStageSidebar,
        }
    },
}
</script>
