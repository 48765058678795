import resolveConfig from 'tailwindcss/resolveConfig'
import CryptoJS from "crypto-js";

export const tailwindConfig = () => {
    // Tailwind config
    return resolveConfig('./src/css/tailwind.config.js')
}

export const hexToRGB = (h) => {
    let r = 0
    let g = 0
    let b = 0
    if (h.length === 4) {
        r = `0x${h[1]}${h[1]}`
        g = `0x${h[2]}${h[2]}`
        b = `0x${h[3]}${h[3]}`
    } else if (h.length === 7) {
        r = `0x${h[1]}${h[2]}`
        g = `0x${h[3]}${h[4]}`
        b = `0x${h[5]}${h[6]}`
    }
    return `${+r},${+g},${+b}`
}

export const formatValue = (value) =>
    Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumSignificantDigits: 3,
        notation: 'compact',
    }).format(value)

export const formatThousands = (value) =>
    Intl.NumberFormat('en-US', {
        maximumSignificantDigits: 3,
        notation: 'compact',
    }).format(value)

export const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        } else {
            resolve(file)
        }
    })
}

export const encrypt = (data) => {
    if (import.meta.env.VITE_DECRYPT_ENABLE === "true") {
        var Sha256 = CryptoJS.SHA256;
        var Hex = CryptoJS.enc.Hex;
        var Utf8 = CryptoJS.enc.Utf8;
        var Base64 = CryptoJS.enc.Base64;
        var AES = CryptoJS.AES;
        let key = Sha256(import.meta.env.VITE_DECRYPT_KEY)
            .toString()
            .toString(Hex)
            .substr(0, 32);
        let iv = Sha256(import.meta.env.VITE_DECRYPT_IV)
            .toString()
            .toString(Hex)
            .substr(0, 16);
        // Encryption
        var output = AES.encrypt(
            JSON.stringify(data),
            Utf8.parse(key),
            {
                iv: Utf8.parse(iv),
            }
        ).toString(); // First Base64 encoding, by default (see 1.)
        var output2ndB64 = Utf8.parse(output).toString(Base64); // Second Base64 encoding (see 1.)
        return output2ndB64;
    }
    return data;
}

export const decrypt = (data) => {
    if (import.meta.env.VITE_DECRYPT_ENABLE === "true") {
        var Sha256 = CryptoJS.SHA256;
        var Hex = CryptoJS.enc.Hex;
        var Utf8 = CryptoJS.enc.Utf8;
        var Base64 = CryptoJS.enc.Base64;
        var AES = CryptoJS.AES;
        let key = Sha256(import.meta.env.VITE_DECRYPT_KEY)
            .toString()
            .toString(Hex)
            .substr(0, 32);
        let iv = Sha256(import.meta.env.VITE_DECRYPT_IV)
            .toString()
            .toString(Hex)
            .substr(0, 16);
        // Encryption
        try {
            var output = AES.decrypt(atob(data), Utf8.parse(key), {
                iv: Utf8.parse(iv),
            }).toString(Utf8); // First Base64 encoding, by default (see 1.)
            return JSON.parse(output);
        } catch (error) {
            return null;
        }
    }
    return data;
}