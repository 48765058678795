import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router.js'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
    // Admin Request Time
    getAllRequestTime({ commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            axios
                .get(`${routePathAPI()}/case/request-time/${payload.case_id}`, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    const responseData = response.data
                    if (response.status === 200 && responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getActiveRequestTime({ commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            axios
                .get(`${routePathAPI()}/case/request-time/active/${payload.case_id}`, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    const responseData = response.data
                    if (response.status === 200 && responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    actionRequestTime({ state, commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {
                request_time_id: payload.request_time_id,
                accept: payload.accept,
                reason: payload.reason ? payload.reason : '',
            }

            axios
                .post(
                    `${routePathAPI()}/case/request-time/action/${payload.case_id}`,
                    params,
                    {
                        headers: {
                            'x-language-code': currentLanguageCode,
                        },
                    }
                )
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200 && responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    //   User Request Time
    getRequestTimeQuota({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            axios
                .get(`${routePathAPI()}/case/request-time/quota/${payload.case_id}`, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    const responseData = response.data
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    createRequestTime({ state, commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            axios
                .post(`${routePathAPI()}/case/request-time/${payload.case_id}`, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200 && responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}

// mutations
const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
