import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'

const state = () => ({

})

const getters = {}

const actions = {
    getAllParameters({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            axios.get(`${routePathAPI()}/preferences`, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            }).then(async (response) => {
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    saveParameters({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            axios.put(`${routePathAPI()}/preferences/customUpdate`, payload)
                .then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: response.data,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: response.data,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
