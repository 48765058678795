const state = () => ({
    reloadUserActions: false,
    reloadDeadlines: false,
})

const getters = {
    getReloadUserActions(state) {
        return state.reloadUserActions;
    },
    getReloadDeadlines(state) {
        return state.reloadDeadlines;
    },
}

const actions = {
    reload({ commit, state }, payload) {
        state[payload.prop] = !state[payload.prop];
    },
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
