<template>
    <div class="flex h-screen overflow-hidden">
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <!-- Site header -->
            <Header :sidebar-open="sidebarOpen" @toggle-sidebar="sidebarOpen = !sidebarOpen" />
            <main>
                <div class="w-full">
                    <div class="flex h-full flex-col justify-center bg-gray-100 md:mt-10">
                        <div class="w-full self-center px-2 py-5 text-left md:w-5/6 md:py-2 md:px-0">
                            <BreadcrumNewCase :active="3" :is-show="true" :is-assistance="needAssistance" />
                        </div>
                        <div
                            class="grid w-full grid-cols-2 gap-2 divide-x-2 self-center rounded-lg bg-white px-8 py-8 shadow-md md:w-5/6">
                            <div class="space-y-8 px-2">
                                <div class="space-y-2">
                                    <h1 class="text-3xl font-bold text-slate-800">
                                        {{ $t('newCase.applicationSummary') }}
                                    </h1>
                                    <p class="text-sm">
                                        {{ $t('newCase.applicationSummaryDesc') }}
                                    </p>
                                </div>

                                <div class="space-y-2">
                                    <h2 class="text-xl font-bold text-slate-800">
                                        {{ $t('newCase.odrDesc') }}
                                    </h2>
                                    <p class="text-sm text-gray-500">
                                        {{ newCaseData.description }}
                                    </p>
                                </div>

                                <div v-if="caseType" class="space-y-2">
                                    <h2 class="text-xl font-bold text-slate-800">
                                        {{ $t('newCase.typeOfPart') }}
                                    </h2>
                                    <p class="text-sm text-gray-500">{{ caseType.name }}</p>
                                </div>

                                <div v-if="caseArea" class="space-y-2">
                                    <h2 class="text-xl font-bold text-slate-800">
                                        {{ $t('newCase.caseArea') }}
                                    </h2>
                                    <p class="text-sm text-gray-500">
                                        {{ caseArea.name }}
                                    </p>
                                </div>

                                <div v-if="newCaseData.amount !== null" class="space-y-2">
                                    <h2 class="text-xl font-bold text-slate-800">
                                        {{ $t('newCase.amountOfProcess') }}
                                    </h2>
                                    <Currency
                                    :total-in-string="String(newCaseData.amount)"
                                    :with-prefix="false"
                                    :leading-text="newCaseData.currencyLabel"
                                    class="text-sm text-gray-500"
                                    />
                                </div>

                                <div v-if="newCaseData.request !== null" class="space-y-2">
                                    <h2 class="text-xl font-bold text-slate-800">
                                        {{ $t('newCase.requestOfProcess') }}
                                    </h2>
                                    <p class="text-sm text-gray-500">
                                        {{ newCaseData.request }}
                                    </p>
                                </div>

                                <div class="space-y-2">
                                    <h2 class="text-xl font-bold text-slate-800">
                                        {{ $t('newCase.estimatedStartDate') }}
                                    </h2>
                                    <p class="text-sm text-gray-500">
                                        {{ formatDateDay(estimatedTimeCase()) }}
                                    </p>
                                </div>
                            </div>
                            <div class="flex flex-col justify-center pl-4 pb-10">
                                <div class="space-y-2">
                                    <h1 class="text-3xl font-bold text-slate-800">
                                        {{ $t('newCase.partOfProcess') }}
                                    </h1>
                                    <p class="text-sm">
                                        {{ $t('newCase.partOfProcessDesc') }}
                                    </p>
                                </div>
                                <div class="mt-4 rounded-lg border border-gray-300 p-4">
                                    <div class="flex justify-between border-b border-gray-300 pb-3">
                                        <h3 class="text-base font-bold text-slate-800">
                                            {{ $t('newCase.caseNamePlaceholder') }}
                                        </h3>
                                        <p class="text-sm text-gray-500">
                                            {{ newCaseData.name }}
                                        </p>
                                    </div>
                                    <div v-if="caseType" class="flex justify-between border-b border-gray-300 py-3">
                                        <h3 class="text-base font-bold text-slate-800">
                                            {{ $t('newCase.typeOfPart') }}
                                        </h3>
                                        <p class="text-sm text-gray-500">
                                            {{ caseType.name }}
                                        </p>
                                    </div>
                                    <div class="flex justify-between border-b border-gray-300 py-3">
                                        <h3 class="text-base font-bold text-slate-800">
                                            {{ $t('newCase.notificationMethod') }}
                                        </h3>
                                        <p v-if="newCaseData.notif_via" class="text-sm text-gray-500">
                                            {{ $t(`newCase.${newCaseData.notif_via}`) }}
                                        </p>
                                    </div>
                                    <div class="flex flex-wrap space-y-2 py-3">
                                        <h3 class="flex w-full text-base font-bold text-slate-800">
                                            {{ $t('newCase.descriptionOnDemands') }}
                                        </h3>
                                        <p class="flex w-full text-sm text-gray-500">
                                            {{ newCaseData.description }}
                                        </p>
                                    </div>
                                </div>

                                <div class="mt-4">
                                    <AdvocateItem v-if="!checkEmptyObject(newCaseData.advocate)"
                                        :advocate="newCaseData.advocate" />
                                </div>

                                <button
                                id="form_create_cases_start_odr"
                                class="btn text-md mt-6 h-12 w-full rounded-md bg-sky-500 text-white hover:bg-sky-600"
                                @click="startCase">
                                    {{ $t('action.startODR') }}
                                </button>
                            </div>
                        </div>
                        <DualRingLoader
                        class="fixed_loader"
                        v-if="isLoading" />
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'

import Header from '@/partials/Header.vue'
import BreadcrumNewCase from '@/components/case/BreadcrumNewCase.vue'
import Currency from '@/components/currency/Currency.vue'
import AdvocateItem from '@/components/AdvocateItem.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'

export default {
    name: 'AdvocateSelectionPage',
    components: {
        Header,
        BreadcrumNewCase,
        Currency,
        AdvocateItem,
        DualRingLoader,
    },
    mixins: [globalMixin, caseMixin],
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()
        const sidebarOpen = ref(false)

        return {
            t,
            n,
            store,
            toast,
            sidebarOpen,
        }
    },
    data: () => ({
        caseType: {},
        caseArea: {},
    }),
    mounted() {
        this.newCaseData = this.store.getters['cases/newCase']
        this.caseFiles = this.store.getters['cases/caseFiles']
        if (this.checkEmptyObject(this.newCaseData)) {
            return this.$router.push({
                name: 'new-case',
                query: { lang: this.$route.query.lang },
            })
        }
        this.getAllAreas().then((response) => {
            this.caseArea = this.caseAreas.find(
                (item) => item.id === this.newCaseData.case_area_id
            )
        })
        this.getAllTypes().then((response) => {
            this.caseType = this.caseTypes.find(
                (item) => item.id === this.newCaseData.case_type_id
            )
        })
    },
    methods: {
        startCase() {
            if (!this.isLoading) {
                this.createCase()
            }
        },
        async createCase() {
            this.isLoading = true
            await this.store.dispatch('cases/createCase', this.newCaseData)
                .then((response) => {
                    const responseData = response.data;
                    const activeCaseId = responseData.id;
                    this.store.dispatch('cases/resetActiveCase')
                    this.store.dispatch('cases/resetNewCase')
                    this.store.dispatch('cases/resetActiveNewCaseBreadcrum')
                    this.toast.success(responseData.message ?? 'Successfully created case data')

                    if (this.caseFiles.length > 0) {
                        let promises = []
                        if (activeCaseId !== null && activeCaseId !== undefined) {
                            this.caseFiles.forEach((item) => {
                                promises.push(
                                    this.uploadFileCase({
                                        case_id: this.$methods.encrypt(activeCaseId),
                                        type: 'INITIAL',
                                        attachment: item,
                                    })
                                )
                            })
                        }
                        Promise.all(promises).then(() => {
                            this.$router.push({ name: 'case-submitted' })
                            this.isLoading = false
                        })
                    } else {
                        this.isLoading = false
                        return this.$router.push({ name: 'case-submitted' })
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
    },
}
</script>
<style scoped>

.fixed_loader {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

</style>