<template>
    <div class="-m-1.5 flex flex-wrap items-center">
        <div class="m-1.5">
            <ModalBasic
            id="document-info-modal"
            :modal-open="isOpen"
            :show-border-header="false"
            :backdrop="true"
            :title="getTitleModal"
            @close-modal="close">
                <div class="space-y-5 px-5 pb-5">
                    <div v-if="activeAgenda.document_file_type" class="-mt-5 text-sm">
                        {{ activeAgenda.document_file_type.name }}
                    </div>
                    <div class="flex items-center justify-start space-x-3">
                        <div>
                            <i class="fa-solid fa-calendar"></i>
                        </div>
                        <template v-if="activeAgenda.due_date !== null">
                            <div :class="[
                                diffDateFromNow(activeAgenda.due_date) <= 0
                                    ? 'text-red-500'
                                    : '',
                            ]">
                                {{
                                    $t('calendar.modalInfo.dueDate', {
                                        date: formattedDate(activeAgenda.due_date),
                                        totalDay: diffDateFromNow(activeAgenda.due_date),
                                    })
                                }}
                            </div>
                        </template>
                        <template v-else-if="activeAgenda.meeting_date !== null">
                            <p>
                                {{
                                    formattedDate(activeAgenda.meeting_date, 'dddd, DD MMMM YYYY')
                                }}
                                {{ activeAgenda.meeting_time_from }} -
                                {{ activeAgenda.meeting_time_to }}
                            </p>
                        </template>
                    </div>

                    <div v-if="activeAgenda.owner" class="flex items-center justify-start space-x-3">
                        <div>
                            <i class="fa-solid fa-user"></i>
                        </div>
                        <div class="flex items-center space-x-2">
                            <div class="space-x-2">
                                <p v-if="activeAgenda.type === 'Document'">
                                    {{
                                        $t('calendar.modalInfo.requestBy', {
                                            by: `${activeAgenda.owner.first_name} ${activeAgenda.owner.last_name}`,
                                        })
                                    }}
                                </p>
                                <p v-else>
                                    {{
                                        `${activeAgenda.owner.first_name} ${activeAgenda.owner.last_name}`
                                    }}
                                    <span v-if="activeAgenda.owner.id === $store.getters['auth/getCurrentUser'].id">{{
                                        $t('you')
                                    }}</span>
                                </p>
                            </div>
                            <div v-if="activeAgenda.owner.role.name" class="flex justify-start">
                                <p class="rounded-lg bg-gray-100 px-2 text-center text-xs font-semibold text-gray-500">
                                    {{ activeAgenda.owner.role.name }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                    v-if="activeAgenda.invitations && activeAgenda.invitations.length > 0"
                    class="flex items-start justify-start space-x-3">
                        <div class="pt-1">
                            <i class="fa-solid fa-users"></i>
                        </div>
                        <div class="flex flex-col items-start justify-start space-y-2">
                            <div v-for="invitation in activeAgenda.invitations" :key="invitation.id"
                                class="flex items-center space-x-2">
                                <span>
                                    {{ invitation.user.first_name }} {{ invitation.user.last_name }}
                                </span>
                                <div v-if="invitation.user.role.name" class="flex justify-start">
                                    <p
                                    :class="[
                                        'rounded-lg px-2 text-center text-xs font-semibold',
                                        randomClass(invitation)
                                    ]">
                                        {{ invitation.user.role.name }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="activeAgenda.meeting_url" class="flex items-center justify-start space-x-3">
                        <div>
                            <i class="fa-solid fa-video"></i>
                        </div>
                        <a :href="activeAgenda.meeting_url" target="_blank" class="hover:text-sky-500">
                            {{ activeAgenda.meeting_url }}
                        </a>
                    </div>

                    <div v-if="activeAgenda.meeting_in_person_address" class="flex items-center justify-start space-x-3">
                        <div>
                            <i class="fa-solid fa-map-location-dot"></i>
                        </div>
                        <a :href="activeAgenda.meeting_in_person_address" target="_blank" class="hover:text-sky-500">
                            {{ activeAgenda.meeting_in_person_address }}
                        </a>
                    </div>

                    <div v-if="activeAgenda.description" class="flex items-center justify-start space-x-3">
                        <div>
                            <i class="fa-solid fa-file"></i>
                        </div>
                        <div>
                            {{
                                isAParsedReferenceTranslate(activeAgenda.description)
                                ? $t(...getParsedReferenceTranslate(activeAgenda.description))
                                : activeAgenda.description
                            }}
                        </div>
                    </div>

                    <div v-if="activeAgenda.other_details" class="flex items-center justify-start space-x-3">
                        <div>
                            <i class="fa-solid fa-key"></i>
                        </div>
                        <div>
                            {{ activeAgenda.other_details }}
                        </div>
                    </div>

                    <div v-if="activeAgenda.type === 'Meeting'">
                        <Form
                        v-if="!activeAgenda.meeting_document"
                        ref="form" v-slot="{ errors }" :validation-schema="uploadMeetingVideoSchema" @submit="uploadMeetingVideo">
                            <div class="flex flex-col gap-4">
                                <span class="text-sm font-bold">
                                    {{ $t('calendar.modalInfo.meetingVideo.title') }}:
                                </span>
                                <Field name="uploadMeetingVideoFile">
                                    <label
                                    for="filesModal"
                                        class="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-gray-300 bg-gray-50 hover:bg-gray-100">
                                        <div class="flex flex-col items-center justify-center py-2">
                                            <svg aria-hidden="true" class="mb-3 h-10 w-10 text-gray-400" fill="none"
                                                stroke="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12">
                                                </path>
                                            </svg>
                                            <p class="text-sm text-gray-500">
                                                {{ $t('forms.dragAndDrop') }}
                                            </p>
                                            <p class="text-sm text-gray-500">
                                                {{ $t('forms.or') }}
                                            </p>
                                            <p class="text-sm font-semibold text-sky-500">
                                                {{ $t('forms.browseFiles') }}
                                            </p>
                                        </div>
                                        <input accept=".mp4, .avi, .mkv, .mov, .wmv, .flv, .mpg, .mpeg, .webm, .3gp, .ogg" id="filesModal" type="file" class="hidden" @change="fileInputModalMeetingVideo" />
                                        <span v-if="uploadMeetingVideoFile">{{
                                            uploadMeetingVideoFile.name
                                        }}</span>
                                    </label>
                                </Field>
                                <div v-if="errors.uploadMeetingVideoFile !== undefined" class="flex w-full">
                                    <span class="text-sm text-red-500">
                                        {{ $t(errors.uploadMeetingVideoFile) }}
                                    </span>
                                </div>
                                <button
                                type="submit"
                                class="btn-sm w-full cursor-pointer bg-sky-500 hover:bg-sky-600 whitespace-nowrap rounded-lg p-3 text-white">
                                    <span v-if="!loadingMeetingVideoUpload">
                                        {{ $t('button.save') }}
                                    </span>
                                    <span v-if="loadingMeetingVideoUpload">
                                        {{ $t('general.loading') }}
                                    </span>
                                </button>
                            </div>
                        </Form>
                        <div
                        class="flex flex-col gap-2"
                        v-if="activeAgenda.meeting_document">
                            <span class="text-sm">
                                {{ $t('calendar.modalInfo.meetingVideo.done.title') }}:
                            </span>
                            <a
                            target="_blank"
                            :href="activeAgenda.meeting_document.files[0].file"
                            class="p-3 bg-green-100 flex items-center gap-3 rounded-lg">
                                <i class="fa-brands fa-youtube text-2xl"></i>
                                <span
                                class="text-slate-500 text-sm font-bold"
                                >
                                    {{ activeAgenda.meeting_document.name }}
                                </span>
                            </a>
                            <p class="text-sm italic">
                                {{ $t('calendar.modalInfo.meetingVideo.done.description') }}
                            </p>
                        </div>
                    </div>


                    <div v-if="activeAgenda.type === 'Document'">
                        <div
                        v-if="requestToMe"
                        class="flex flex-col gap-2">

                            <div
                            v-if="activeAgenda.request.last_document_provided"
                            class="flex flex-col gap-2">
                                <span class="text-sm font-bold text-gray-600">
                                    {{ $t('calendar.modalInfo.currentDocument.title') }}:
                                </span>
                                <FileItem
                                :file="activeAgenda.request.last_document_provided"
                                wrapper-class="min-w-72 p-3"
                                />
                                <div class="flex items-center justify-between gap-2">
                                    <div class="flex items-center gap-2">
                                        <ImageLoader
                                        wrapper-class="shrink-0 rounded-full"
                                        :src="activeAgenda.request.last_document_provided.owner.avatar"
                                        width="30"
                                        height="30"
                                        :alternate="activeAgenda.request.last_document_provided.owner.first_name" />
                                        <span class="text-sm">
                                            {{ activeAgenda.request.last_document_provided.owner.first_name }}
                                            {{ activeAgenda.request.last_document_provided.owner.last_name }}
                                        </span>
                                    </div>
                                    <span class="text-xs">
                                        {{ formattedDate(activeAgenda.request.last_document_provided.created_at, 'dddd, DD MMMM YYYY') }}
                                    </span>
                                </div>
                            </div>

                            <hr v-if="!alreadyApproved" class="-mx-5 my-2">

                            <div
                            v-if="currentApprovalResult && !currentApprovalResult.is_accept"
                            class="flex flex-col"
                            >
                                <span class="text-sm text-yellow-600 italic">
                                    {{ $t('calendar.modalInfo.approval.feedback.alert') }}
                                </span>
                                <div class="my-3 rounded-lg bg-gray-100 p-2 flex flex-col gap-2">
                                    <div class="flex justify-between">
                                        <span class="text-sm font-bold text-gray-600">
                                            {{ $t('calendar.modalInfo.approval.feedback.title') }}
                                        </span>
                                        <span class="text-xs text-slate-500">
                                            {{ formattedDate(currentApprovalResult.created_at, 'dddd, DD MMMM YYYY') }}
                                        </span>
                                    </div>
                                    <div class="flex items-start gap-2">
                                        <ImageLoader
                                        wrapper-class="shrink-0 rounded-full"
                                        :src="currentApprovalResult.approver.user.avatar"
                                        width="30"
                                        height="30"
                                        :alternate="currentApprovalResult.approver.user.first_name" />
                                        <div class="flex flex-col">
                                            <span class="text-sm">
                                                {{ currentApprovalResult.approver.user.first_name }}
                                            </span>
                                            <span class="text-sm text-slate-500">
                                                {{ currentApprovalResult.reason }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button
                            v-if="!onRevisionProcess && !alreadyApproved && !requestRejectedBy"
                            class="btn-sm w-full whitespace-nowrap rounded-lg p-3 text-white"
                            :class="[
                                diffDateFromNow(activeAgenda.due_date) <= 0 || providedBy
                                    ? 'cursor-not-allowed bg-gray-300'
                                    : 'cursor-pointer bg-sky-500 hover:bg-sky-600',
                            ]"
                            @click.stop="acceptRequest">
                                {{ $t('calendar.modalInfo.provideDocument') }}
                            </button>

                            <button
                            v-if="!isLastDocumentProvided && !alreadyApproved && !requestRejectedBy"
                            class="btn-sm w-full whitespace-nowrap rounded-lg p-3"
                            :class="[
                                diffDateFromNow(activeAgenda.due_date) <= 0 || providedBy
                                    ? 'cursor-not-allowed bg-gray-300 text-white'
                                    : 'cursor-pointer border-red-500 text-red-500 hover:bg-red-500 hover:text-white',
                            ]" @click.stop="rejectRequest">
                                {{ $t('calendar.modalInfo.rejectRequest') }}
                            </button>
                            <span
                            v-if="providedBy && !alreadyApproved"
                            class="text-sm italic">
                                {{ $t('calendar.modalInfo.documentAlreadyProvided', {by: getWhoProvided}) }}
                            </span>
                        </div>

                        <div
                        v-else-if="
                            activeAgenda.owner
                            &&
                            $store.getters['auth/getCurrentUser'].id === activeAgenda.owner.id
                            &&
                            activeAgenda.type === 'Document'
                        "
                        class="space-y-5 px-1">
                            <div
                            v-if="
                                activeAgenda.request
                                &&
                                activeAgenda.request.last_document_provided
                                &&
                                activeAgenda.request.last_document_provided
                            "
                            class="flex flex-col w-full flex-wrap space-y-3 bg-white"
                            >

                                <div
                                class="flex flex-col w-full flex-wrap gap-2"
                                v-if="activeAgenda.request.last_document_provided"
                                >
                                    <div
                                    v-if="activeAgenda.request.last_document_provided.owner"
                                    class="flex items-center justify-start gap-2">
                                        <div class="flex">
                                            <ImageLoader
                                            wrapper-class="shrink-0 rounded-full"
                                            :src="activeAgenda.request.last_document_provided.owner.avatar"
                                            width="30"
                                            height="30"
                                            :alternate="activeAgenda.request.last_document_provided.owner.first_name" />
                                        </div>
                                        <div
                                        class="flex items-center font-medium gap-2">
                                            <span class="text-sm">
                                                {{ activeAgenda.request.last_document_provided.owner.first_name }}
                                                {{ activeAgenda.request.last_document_provided.owner.last_name }}
                                            </span>
                                            <div class="flex justify-start">
                                                <p class="rounded-lg bg-green-100 py-1 px-2 text-center text-xs text-green-600">
                                                    {{ $t('badge.accepted') }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                    v-if="
                                        activeAgenda.request.last_document_provided.files
                                        &&
                                        activeAgenda.request.last_document_provided.files.length
                                    "
                                    class="w-full"
                                    >
                                        <FileItem
                                        :file="activeAgenda.request.last_document_provided"
                                        wrapper-class="min-w-72 p-3"
                                        />
                                    </div>
                                    <div
                                    v-if="currentApprovalResult && !currentApprovalResult.is_accept"
                                    >
                                        <span class="text-yellow-600 italic text-sm">
                                            {{ $t('calendar.modalInfo.approval.feedback.alertProvider') }}
                                        </span>
                                        <hr class="-mx-6 my-2">
                                        <div class="mt-2 rounded-lg bg-gray-100 p-2 flex flex-col gap-2">
                                            <div class="flex justify-between">
                                                <span class="text-sm font-bold text-gray-600">
                                                    {{ $t('calendar.modalInfo.approval.feedback.title') }}
                                                </span>
                                                <span class="text-xs text-slate-500">
                                                    {{ formattedDate(currentApprovalResult.created_at, 'dddd, DD MMMM YYYY') }}
                                                </span>
                                            </div>
                                            <div class="flex items-start gap-2">
                                                <ImageLoader
                                                wrapper-class="shrink-0 rounded-full"
                                                :src="currentApprovalResult.approver.user.avatar"
                                                width="30"
                                                height="30"
                                                :alternate="currentApprovalResult.approver.user.first_name" />
                                                <div class="flex flex-col">
                                                    <span class="text-sm">
                                                        {{ currentApprovalResult.approver.user.first_name }}
                                                    </span>
                                                    <span class="text-sm text-slate-500">
                                                        {{ currentApprovalResult.reason }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                v-if="approvalAllowed"
                                class="flex flex-col gap-2">
                                    <button
                                    class="btn-sm w-full whitespace-nowrap rounded-lg p-3 text-white cursor-pointer bg-sky-500 hover:bg-sky-600"
                                    @click.stop="approveDocument"
                                    >
                                        {{ $t('calendar.modalInfo.approval.approve') }}
                                    </button>
                                    <button
                                    class="btn-sm w-full whitespace-nowrap rounded-lg p-3 cursor-pointer border-yellow-500 text-yellow-500 hover:bg-yellow-500 hover:text-white"
                                    @click.stop="rectifyDocument"
                                    >
                                        {{ $t('calendar.modalInfo.approval.rectification') }}
                                    </button>
                                </div>
                            </div>

                            <span
                            v-else-if="!providedBy && !requestRejectedBy"
                            class="text-sm italic text-yellow-600"
                            >
                                {{ $t('calendar.modalInfo.documentNotProvidedYet') }}
                            </span>
                        </div>

                        <span
                        v-if="!approvalAllowed && alreadyApproved"
                        class="text-sm text-sky-600 italic">
                            {{ $t('calendar.modalInfo.documentApproved') }} {{ getUserWhoApproved }}
                        </span>

                        <span
                        v-if="requestRejectedBy"
                        class="text-sm italic">
                            {{ $t('calendar.modalInfo.requestDocumentRejected', {
                                by: requestRejectedBy.user.first_name,
                                at: formattedDate(requestRejectedBy.process_at, 'MMMM DD YYYY'),
                                reason: requestRejectedBy.reason
                            }) }}
                        </span>
                    </div>
                </div>
            </ModalBasic>
        </div>
    </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import ModalBasic from '@/components/ModalBasic.vue'
import CalendarRequestItem from '@/components/CalendarRequestItem.vue'
import ImageLoader from '@/components/image/ImageLoader.vue'
import FileItem from '@/components/FileItem.vue'
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'
import { useToast } from 'vue-toastification'

export default {
    name: 'ModalCalendarInfoComponent',
    components: {
        ModalBasic,
        CalendarRequestItem,
        ImageLoader,
        FileItem,
        Form,
        Field,
    },
    setup() {
        const toast = useToast()

        return {
            toast,
        }
    },
    mixins: [globalMixin],
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        isRequestDocument: {
            type: Boolean,
            default: false,
        },
        activeAgenda: {
            type: Object,
            required: true,
        },
    },
    emits: ['closed', 'reject-request', 'accept-request', 'approve-document', 'rectify-document', 'provide-meetingvideo'],
    data: function () {
        return {
            rolesClasses: [
                'bg-orange-100 text-orange-500',
                'bg-violet-200 text-violet-500',
                'bg-green-200 text-green-500',
                'bg-gray-100 text-gray-500',
            ],
            uploadMeetingVideoSchema: Yup.object().shape({

            }),
            uploadMeetingVideoFile: null,
            loadingMeetingVideoUpload: false,
        }
    },
    computed: {
        requestToMe() {
            if (
                this.activeAgenda &&
                this.activeAgenda.request &&
                this.activeAgenda.request.providers
            ) {
                return this.activeAgenda.request.providers.some(item => this.$store.getters['auth/getCurrentUser'] && item.user.id === this.$store.getters['auth/getCurrentUser'].id);
            }
            return false;
        },
        providedBy () {
            return (this.activeAgenda && this.activeAgenda.request)
                ? this.activeAgenda.request.providers.find(item => item.process_at && item.is_accept)
                : null;
        },
        getWhoProvided () {
            if (this.providedBy) {
                return this.$store.getters['auth/getCurrentUser'] && this.providedBy.user.id != this.$store.getters['auth/getCurrentUser'].id ? this.providedBy.user.first_name : this.$t('general.pronouns.you').toLowerCase();
            }
        },
        currentApprovalResult () {
            if (this.isLastDocumentProvided) {
                return this.activeAgenda.request.last_document_provided.request_result;
            }
            return null;
        },
        alreadyApproved () {
            const approved = this.providedBy
                &&
                this.activeAgenda
                &&
                this.activeAgenda.request
                &&
                this.activeAgenda.request.last_document_provided
                &&
                this.activeAgenda.request.last_document_provided
                &&
                this.activeAgenda.request.last_document_provided.request_result
                &&
                this.activeAgenda.request.last_document_provided.request_result.is_accept

            return approved;
        },
        approvalAllowed () {
            const allowed = this.providedBy
                &&
                this.isLastDocumentProvided
                &&
                !this.activeAgenda.request.last_document_provided.request_result

            return allowed;
        },
        getUserWhoApproved () {
            if (
                this.activeAgenda
                &&
                this.currentApprovalResult.approver
                &&
                this.currentApprovalResult.approver.user
            ) {
                return this.currentApprovalResult.approver.user.first_name;
            } else {
                return null;
            }
        },
        onRevisionProcess () {
            return this.isLastDocumentProvided && !this.currentApprovalResult
        },
        isLastDocumentProvided () {
            const valid =
                this.activeAgenda
                &&
                this.activeAgenda.request
                &&
                this.activeAgenda.request.last_document_provided
                &&
                this.activeAgenda.request.last_document_provided
            return valid;
        },
        requestRejectedBy () {
            if (this.activeAgenda && this.activeAgenda.request) {
                return this.activeAgenda.request.providers.find(item => item.process_at != null && !item.is_accept);
            } else {
                return null;
            }
        },
        randomClass () {
            return (item) => {
                return this.rolesClasses[Math.floor(Math.random() * 4)];
            }
        },
        getTitleModal () {
            if (this.activeAgenda && this.activeAgenda.title) {
                if (this.isRequestDocument) {
                    return this.$t('calendar.modalRequestDocument.title')
                } else if (this.isAParsedReferenceTranslate(this.activeAgenda.title)) {
                    return this.$t(...this.getParsedReferenceTranslate(this.activeAgenda.title))
                } else {
                    return this.activeAgenda.title
                }
            } else {
                return null
            }
        }
    },
    methods: {
        close() {
            this.$emit('closed')
        },
        rejectRequest() {
            if (this.diffDateFromNow(this.activeAgenda.due_date) > 0 && !this.providedBy && this.requestToMe) {
                this.$emit('reject-request')
            }
        },
        acceptRequest() {
            if (this.diffDateFromNow(this.activeAgenda.due_date) > 0 && !this.providedBy && this.requestToMe) {
                this.$emit('accept-request')
            }
        },
        approveDocument () {
            if (this.approvalAllowed) {
                this.$emit('approve-document')
            }
        },
        rectifyDocument () {
            if (this.approvalAllowed) {
                this.$emit('rectify-document')
            }
        },
        fileInputModalMeetingVideo(event) {
            // if (event.target.files[0].type === 'video/mp4') {
            this.uploadMeetingVideoFile = event.target.files[0]
            // } else {
                // this.toast.error(this.$t('calendar.modalInfo.meetingVideo.upload.pleaseChooseFileMp4'))
                // event.target.value = ''
            // }
        },
        uploadMeetingVideo () {
            if (this.uploadMeetingVideoFile && !this.loadingMeetingVideoUpload) {
                this.loadingMeetingVideoUpload = true
                this.$store.dispatch('calendar/uploadMeetingVideo', {
                    attachment: this.uploadMeetingVideoFile,
                    id: this.activeAgenda.id,
                    case_id: this.$methods.decrypt(this.$route.params.slug),
                }).then(response => {
                    this.loadingMeetingVideoUpload = false
                    this.$emit('provide-meetingvideo')
                    this.toast.success(this.$t('calendar.modalInfo.meetingVideo.upload.success'))
                }).catch((error) => {
                    this.toast.error(this.extractErrorResponse(error))
                })
            } else {
                this.toast.error(this.$t('calendar.modalInfo.meetingVideo.upload.pleaseChooseFile'))
            }
        },
    },
}
</script>
