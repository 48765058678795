<template>
    <div class="flex h-screen overflow-hidden">
        <!-- Sidebar -->
        <Sidebar :sidebar-open="sidebarOpen" @close-sidebar="sidebarOpen = false" />

        <!-- Content area -->
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <main>
                <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
                    <div class="flex justify-between">
                        <div class="flex w-full flex-col">
                            <h1 class="text-2xl font-bold text-black">
                                {{ $t('admin.roles.title') }}
                            </h1>
                            <p>{{ $t('admin.roles.description') }}</p>
                        </div>
                        <div class="mt-4 flex w-full items-center justify-end">
                            <DropdownLanguages class="mr-4"/>
                            <DropdownNotifications align="right" class="md:mr-4" wrapper-class="bg-white rounded-lg" />
                            <DropdownProfile align="right" mode="aesthetic" />
                        </div>
                    </div>
                    <div class="my-4 flex w-full justify-between">
                        <div class="flex items-center space-x-4">
                            <SearchForm :placeholder="$t('admin.roles.searchPlaceholder')" @search="searchRole" />
                            <div class="flex cursor-pointer items-start text-sm text-black">
                                <div class="flex">
                                    <p>{{ $t('formLabel.sortBy') }}</p>
                                </div>
                                <div class="flex">
                                    <select
                                    class="border-none bg-inherit py-0 font-bold focus:ring-0"
                                    @change="changeOrder">
                                        <option
                                        v-for="order in orderOptions"
                                        :key="order.value"
                                        :value="order.value"
                                        :selected="filterDataRoles.order === order.value"
                                        >
                                            {{ $t(`${order.label}`) }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="text-sm flex items-center gap-2">
                                <select
                                @change="changeNRecords"
                                v-model="filterDataRoles.take"
                                class="form-input w-16"
                                >
                                    <option
                                    v-for="item in [5, 10, 25, 50, 100]"
                                    :value="item"
                                    >
                                        {{ item }}
                                    </option>
                                </select>
                                <span>
                                    {{ $t('general.records') }}
                                </span>
                            </div>
                        </div>
                        <ButtonAdd :title="$t('admin.roles.buttonAdd')" custom-class="rounded-xl" @clicked="newRole()" />
                    </div>

                    <div v-if="!isLoading && roles.length > 0" class="w-full mt-8">
                        <DataTable
                        headerClass="!p-4"
                        :headers="dataTable.headers"
                        :list="roles"
                        @edit="(event) => $router.push({ name: 'admin-roles-detail', params: { id: $methods.encrypt(event.id) } })"
                        @delete="(event) => deleteRole({role: event})"
                        >
                            <template v-slot:cell-name="cellNameProps">
                                <p :class="[
                                    'rounded-lg px-2 w-full py-1 text-center text-base font-semibold',
                                    cellNameProps.item.class_tailwind_color ?? 'bg-purple-100  text-purple-600'
                                ]">
                                    {{ cellNameProps.item.name }}
                                </p>
                            </template>
                        </DataTable>
                        <div class="flex flex-col items-end my-4">
                            <div class="flex">
                                <button
                                v-for="(link, index) in metaDataRoles.links"
                                @click="changePage(link, index)"
                                :class="[
                                    'text-sm px-2 flex items-center justify-center border-t border-b border-l',
                                    {
                                        'w-8 h-8': index != 0 && index < (metaDataRoles.links.length - 1),
                                        'border-r rounded-r-md': index === (metaDataRoles.links.length - 1),
                                        'rounded-l-md': index === 0
                                    },
                                    (link.active) ? 'bg-sky-500 text-white' : 'bg-white text-slate-600'
                                ]"
                                >
                                    <span v-html="link.label"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <DualRingLoader v-else-if="isLoading" />
                    <NoData v-else :search-keyword="filterDataRoles.search" />
                </div>
            </main>
            <ModalDelete
            :id="currentRole.id"
            :is-open="deleteRoleModalOpen"
            :title="$t('admin.roles.modalRemoveRole.title')"
            :description="$t('admin.roles.modalRemoveRole.description')"
            @closed="deleteRoleModalOpen = false"
            @delete="deleteRoleAPI($event)" />

            <ModalCreateRole
            :is-open="newRoleModalOpen"
            :is-edit="isRoleEdit"
            :current-role="currentRole"
            :is-role-loading="isLoading"
            @closed="modalRoleClose"
            @submit-data="submitData"
            />
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import rolesMixin from '@/mixins/roles.js'

import Sidebar from '@/partials/Sidebar.vue'
import SearchForm from '@/components/SearchForm.vue'
import ButtonAdd from '@/components/ButtonAdd.vue'

import ModalCreateRole from '@/components/ModalCreateRole.vue'
import ModalDelete from '@/components/ModalDelete.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'
import NoData from '@/components/NoData.vue'
import DropdownNotifications from '@/components/DropdownNotifications.vue'
import DropdownProfile from '@/components/DropdownProfile.vue'
import DropdownLanguages from '@/components/DropdownLanguages.vue'
import DataTable from '@/components/DataTable.vue'

export default {
    name: 'AdminRolesPage',
    components: {
        Sidebar,
        SearchForm,
        ButtonAdd,
        ModalCreateRole,
        ModalDelete,
        DualRingLoader,
        NoData,
        DropdownNotifications,
        DropdownProfile,
        DropdownLanguages,
        DataTable
    },
    mixins: [globalMixin, rolesMixin],
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()

        const sidebarOpen = ref(false)
        const newRoleModalOpen = ref(false)
        const deleteRoleModalOpen = ref(false)
        const isRoleEdit = ref(false)
        const currentRole = ref({})
        const headerData = ref([
            {
                name: t('admin.roles.headerTable.code'),
                colspan: 1,
                sort: 'ASC',
            },
            {
                name: t('admin.roles.headerTable.name'),
                colspan: 1,
                sort: 'ASC',
            },
            {
                name: t('admin.roles.headerTable.description'),
                colspan: 1,
                sort: 'ASC',
            },
        ])

        return {
            t,
            store,
            toast,
            sidebarOpen,
            isRoleEdit,
            currentRole,
            deleteRoleModalOpen,
            newRoleModalOpen,
            headerData,
        }
    },
    data: function () {
        return {
            dataTable: {
                headers: [
                    {
                        id: 'code',
                        label: this.$t('admin.roles.headerTable.code'),
                        column: {
                            key: 'code',
                        },
                    },
                    {
                        id: 'name',
                        label: this.$t('admin.roles.headerTable.name'),
                        column: {
                            key: 'name',
                        },
                        isSlot: true,
                    },
                    {
                        id: 'description',
                        label: this.$t('admin.roles.headerTable.description'),
                        column: {
                            key: 'description',
                        },
                    },
                    {
                        id: 'created_at',
                        label: this.$t('parameters.masterData.common.table.headers.createdAt'),
                        column: {
                            funct: (value) => this.$filters.newDayjs(value).format('DD MMM YYYY - HH:mm'),
                            key: 'created_at',
                        }
                    },
                    {
                        id: 'actions',
                        label: '',
                        column: {
                            key: '',
                            action: {
                                edit: {
                                    permission: 'security_roles_management_edit'
                                },
                                delete: {
                                    permission: 'security_roles_management_delete'
                                }
                            }
                        }
                    }
                ]
            }
        }
    },
    mounted() {
        // this.checkUserAccess('REDEK_ADMIN')
        this.getAllRoles()
    },
    methods: {
        changePage (link, index) {
            let newPage = null
            if (this.metaDataRoles.links.length > 3) {
                if (index === 0 && this.metaDataRoles.current_page != 1) {
                    /**
                     * Previous
                     */
                    newPage = this.metaDataRoles.current_page - 1;
                } else if (
                    index === (this.metaDataRoles.links.length - 1)
                    &&
                    this.metaDataRoles.current_page != this.metaDataRoles.last_page
                ) {
                    /**
                     * Next
                     */
                    newPage = this.metaDataRoles.current_page + 1;
                } else if (link.label === '...') {
                    newPage = Number(this.metaDataRoles.links[index - 1].label) + 1
                } else if (link.label && !isNaN(link.label)) {
                    newPage = Number(link.label)
                }
            }
            if (newPage) {
                this.filterDataRoles.page = newPage
                this.getAllRoles()
            }
        },
        changeNRecords (event) {
            this.filterDataRoles.page = 1
            this.getAllRoles()
        }
    }
}
</script>
