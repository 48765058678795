<template>
    <div v-for="(item, key, index) in activityDate" :key="key" class="flex w-full justify-start space-x-2">
        <div class="pt-4 text-center font-semibold">
            {{ formattedDate(key, 'DD MMM') }}
        </div>
        <ActivityItem :activities="item" :wrapper-class="wrapperClass" />
    </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import ActivityItem from '@/components/ActivityItem.vue'

export default {
    name: 'ActivityItemDateComponent',
    components: {
        ActivityItem,
    },
    mixins: [globalMixin],
    props: {
        activityDate: {
            type: Object,
            default: () => {
                return {}
            },
        },
        wrapperClass: {
            type: String,
            default: '',
        },
    },
}
</script>
