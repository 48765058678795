<template>
    <div class="relative inline-flex">
        <button
            ref="trigger"
            class="flex h-8 w-8 items-center justify-center transition duration-150 hover:bg-slate-200"
            :class="[
                dropdownOpen ? 'bg-slate-200' : '',
                wrapperClass !== '' ? wrapperClass : '',
            ]"
            aria-haspopup="true"
            :aria-expanded="dropdownOpen"
            @click.prevent="dropdownOpen = !dropdownOpen"
        >
            <span class="sr-only">{{ $t('notification.title') }}</span>
            <svg
                width="20"
                height="25"
                viewBox="0 0 25 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.52191 17.3828V17.0909C1.56341 16.227 1.83162 15.39 2.29889 14.6662C3.07666 13.7966 3.60909 12.7311 3.84031 11.5813C3.84031 10.6927 3.84031 9.79136 3.9155 8.90272C4.30399 4.62458 8.40191 1.66669 12.4497 1.66669H12.55C16.5978 1.66669 20.6957 4.62458 21.0967 8.90272C21.1719 9.79136 21.0967 10.6927 21.1594 11.5813C21.3937 12.7338 21.9256 13.8026 22.7008 14.6789C23.1716 15.3963 23.4402 16.2302 23.4778 17.0909V17.3702C23.5057 18.5307 23.1185 19.6624 22.3875 20.5565C21.4215 21.602 20.1107 22.2524 18.7031 22.3846C14.5757 22.8416 10.4114 22.8416 6.28402 22.3846C4.87807 22.2467 3.56911 21.5972 2.59965 20.5565C1.87993 19.6618 1.4979 18.5368 1.52191 17.3828Z"
                    stroke="#2C2B30"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M9.3418 26.8024C9.98669 27.638 10.9337 28.1787 11.9733 28.305C13.0129 28.4313 14.0593 28.1327 14.8809 27.4752C15.1336 27.2808 15.361 27.0547 15.5576 26.8024"
                    stroke="#2C2B30"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
            <div
                v-if="hasUnread"
                class="absolute top-0 right-0 h-4 w-4 rounded-full border-2 border-white bg-rose-500"
            ></div>
        </button>

        <transition
            enter-active-class="transition ease-out duration-200 transform"
            enter-from-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-200"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="dropdownOpen"
                class="min-w-80 absolute top-full z-50 -mr-48 mt-1 origin-top-right overflow-hidden rounded border border-slate-200 bg-white py-1.5 shadow-lg sm:mr-0"
                :class="align === 'right' ? 'right-0' : 'left-0'"
            >
                <div
                    class="px-4 py-1.5 text-xs font-semibold uppercase text-slate-400"
                >
                    {{ $t('notification.title') }}
                </div>
                <ul
                    ref="dropdown"
                    @focusin="dropdownOpen = true"
                    @focusout="dropdownOpen = false"
                >
                    <template v-if="notifications.length > 0 && !isLoading">
                        <NotificationItem
                        :notifications="notifications"
                        />
                    </template>
                    <NoData v-else text-class="text-xs" wrapper-class="py-2" />
                    <li
                        class="cursor-pointer py-2 text-center hover:bg-slate-50"
                        @click="toNotificationPage"
                    >
                        {{ $t('notification.showAll') }}
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import notificationMixin from '@/mixins/notification'

import NotificationItem from '@/components/notification/NotificationItem.vue'
import NoData from '@/components/NoData.vue'

export default {
    name: 'DropdownNotifications',
    components: {
        NotificationItem,
        NoData,
    },
    mixins: [notificationMixin],
    props: {
        align: {
            type: String,
            default: 'left',
        },
        wrapperClass: {
            type: String,
            default: '',
        },
    },
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()

        const dropdownOpen = ref(false)
        const trigger = ref(null)
        const dropdown = ref(null)

        // close on click outside
        const clickHandler = ({ target }) => {
            if (
                !dropdownOpen.value ||
                dropdown.value.contains(target) ||
                trigger.value.contains(target)
            )
                return
            dropdownOpen.value = false
        }

        // close if the esc key is pressed
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen.value || keyCode !== 27) return
            dropdownOpen.value = false
        }

        onMounted(() => {
            document.addEventListener('click', clickHandler)
            document.addEventListener('keydown', keyHandler)
        })

        onUnmounted(() => {
            document.removeEventListener('click', clickHandler)
            document.removeEventListener('keydown', keyHandler)
        })

        return {
            t,
            n,
            store,
            toast,
            dropdownOpen,
            trigger,
            dropdown,
        }
    },
    mounted() {
        this.filterDataNotification.take = 4
        this.hasCase = typeof this.$route.params.slug !== 'undefined'
        this.getAllNotification()
        // this.getTotalUnreadNotification()
    },
    created () {
        if (this.$echo) {
            this.$echo
                .channel("Notification")
                .listen(".NotificationEvent", (event) => {
                    if (
                        this.$store.getters['auth/getCurrentUser']
                        &&
                        event
                        &&
                        event.data
                        &&
                        event.data.notification
                        &&
                        event.data.notification.user_id == this.$store.getters['auth/getCurrentUser'].id
                    ) {
                        // try {
                            // var audio = new Audio(
                            //     "../../src/assets/audio/notification-sound.mp3"
                            // );
                            // audio.play();
                        // } catch (error) {

                        // }
                        this.notifications.unshift(event.data.notification);
                    }
                });
        }
    },
    computed: {
        hasUnread () {
            return this.notifications.some(item => !item.is_read)
        }
    }
}
</script>
