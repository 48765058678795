<template>
    <div class="flex h-screen overflow-hidden">
        <!-- Sidebar -->
        <Sidebar
        :sidebar-open="sidebarOpen"
        :case-stage="activeCase.stage !== undefined ? activeCase.stage : $route.query.status"
        @close-sidebar="sidebarOpen = false" />

        <!-- Content area -->
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <main>
                <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
                    <div class="flex justify-between">
                        <div class="flex w-full flex-col pb-4">
                            <h1 class="text-2xl font-bold text-black">
                                {{ $t('caseDetail.title') }}
                            </h1>
                            <p>{{ $t('caseDetail.description') }}</p>
                        </div>
                        <div class="flex w-full items-center justify-end">
                            <DropdownLanguages class="mr-4"/>
                            <DropdownNotifications align="right" class="md:mr-4" wrapper-class="bg-white rounded-lg" />
                            <UserMenu align="right" mode="aesthetic" />
                        </div>
                    </div>
                    <section v-if="!isLoading" class="space-y-2">
                        <!-- Case Details -->
                        <div class="rounded-lg bg-white px-6 shadow-sm">
                            <div class="py-6">
                                <div class="flex items-start">
                                    <div class="grow">
                                        <div class="items-start justify-between py-4 sm:flex">
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                                <p>{{ $t('caseDetail.details.caseName') }}</p>
                                            </div>
                                            <div class="mb-2 text-lg font-medium text-slate-800 sm:mb-0">
                                                <p>{{ activeCase.name }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="rounded-lg bg-white px-6 shadow-sm">
                            <div class="py-6">
                                <div class="flex items-start">
                                    <div class="grow">
                                        <!-- <div class="items-start justify-between py-4 sm:flex">
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                                            >
                                                <p>{{ $t('caseDetail.details.caseDetails') }}</p>
                                            </div>
                                            <div
                                                class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                                            >
                                                <p></p>
                                            </div>
                                            </div> -->

                                        <div v-if="activeCase.request !== null &&
                                            activeCase.request !== '' &&
                                            activeCase.request !== 'null'
                                            " class="items-start justify-between border-t py-4 sm:flex">
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                                <p>{{ $t('caseDetail.details.caseRequest') }}</p>
                                            </div>
                                            <div class="mb-2 text-lg font-medium text-slate-800 sm:mb-0">
                                                <p>
                                                    {{ activeCase.request }}
                                                </p>
                                            </div>
                                        </div>

                                        <div v-if="activeCase.amount" class="items-start justify-between py-4 sm:flex">
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                                <p>{{ $t('caseDetail.details.caseAmount') }}</p>
                                            </div>
                                            <div
                                            v-if="activeCase.currency && activeCase.amount"
                                            class="mb-2 text-lg font-medium text-slate-800 sm:mb-0"
                                            >
                                                <p>
                                                    <Currency
                                                    :total-in-string="activeCase.amount"
                                                    :with-prefix="false"
                                                    :leading-text="activeCase.currency.code"
                                                    />
                                                </p>
                                            </div>
                                        </div>

                                        <div class="items-center justify-between border-t py-4 sm:flex">
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                                <p>{{ $t('caseDetail.details.currentStage') }}</p>
                                            </div>
                                            <div
                                                class="mb-2 text-right text-lg font-medium text-yellow-500 sm:mb-0">
                                                <p>{{ activeCase.status }}</p>
                                                <p v-if="lastDeadlineStage !== null">
                                                    {{ lastDeadlineStage }}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="items-center justify-between border-t py-4 sm:flex">
                                            <div
                                                class="items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                                <p>{{ $t('caseDetail.details.isWithAssistant') }}</p>
                                            </div>
                                            <div class="text-right text-lg font-medium sm:mb-0">
                                                <p v-if="activeCase.with_assistant" class="text-green-500">
                                                    {{ $t('yes') }}
                                                </p>
                                                <p v-else class="text-red-500">{{ $t('no') }}</p>
                                            </div>
                                        </div>

                                        <div v-if="lastDeadlineTime !== null && lastDeadlineStage !== null
                                            " class="items-start justify-between border-t py-4 sm:flex">
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                                <p>
                                                    {{
                                                        $t('caseDetail.details.stageDuedate', {
                                                            stage: lastDeadlineStage,
                                                        })
                                                    }}
                                                </p>
                                            </div>
                                            <div class="mb-2 text-lg font-medium sm:mb-0" :class="[
                                                        caseExpired ? 'text-red-500' : 'text-slate-800',
                                                    ]">
                                                <p>{{ formatDateDay(lastDeadlineTime) }}</p>
                                            </div>
                                        </div>
                                        <div class="items-start justify-between border-t py-4 sm:flex">
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                                <p>{{ $t('caseDetail.details.urgency') }}</p>
                                            </div>
                                            <div class="mb-2 text-lg font-medium text-slate-800 sm:mb-0">
                                                <select class="border-none focus:ring-0"
                                                    :class="priorityClass(activeCase.priority)" @change="changePriority">
                                                    <option v-for="casePriority in casePriorities" :key="casePriority.id"
                                                        :value="casePriority.id"
                                                        :selected="activeCase.priority === casePriority.id">
                                                        {{ casePriority.name }}
                                                    </option>
                                                    <option v-if="activeCase.priority === null" :selected="true">
                                                        -
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="items-start justify-between border-t py-4 sm:flex">
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                                <p>{{ $t('caseDetail.details.submissionDate') }}</p>
                                            </div>
                                            <div class="mb-2 text-lg font-medium text-slate-800 sm:mb-0">
                                                <p>{{ formatDateDay(activeCase.time) }}</p>
                                            </div>
                                        </div>

                                        <div class="items-start justify-between border-t py-4 sm:flex">
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                                <p>{{ $t('caseDetail.details.submittedBy') }}</p>
                                            </div>
                                            <div class="mb-2 text-lg font-medium text-slate-800 sm:mb-0">
                                                <p v-if="activeCase.user_created">
                                                    {{ getName(activeCase.user_created, 'name', 50) }}
                                                </p>
                                                <p v-else>-</p>
                                            </div>
                                        </div>
                                        <div
                                        v-for="party in getPartiesActiveCase"
                                        :key="party.user.id"
                                        class="items-start justify-between border-t py-4 sm:flex">
                                            <div class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                                <p>{{ $t($store.getters['cases/getpartyTypes'].find(item => item.key === party.type).translateReference) }}</p>
                                            </div>
                                            <div class="mb-2 text-lg font-medium text-slate-800 sm:mb-0">
                                                <p>
                                                    {{ party.user.name }}
                                                    <span
                                                    v-permission="getPermissionEditParty(party.type)"
                                                    v-if="party.type != 'author' && !$store.getters['cases/currentCaseWasClosed']"
                                                    class="cursor-pointer text-sm bg-sky-500 text-white px-3 py-1 rounded"
                                                    @click="pick(party.type.replace('_', '-'), true)"
                                                    >
                                                        {{ $t('edit') }}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <!-- <section v-for="neutral in threeNeutral" :key="neutral"> -->
                                        <div v-if="neutral !== ''"
                                            class="items-center justify-between border-t py-4 sm:flex">
                                            <div
                                                class="mb-2 flex items-center justify-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                                <p>{{ $t(`general.${snakeToCamel(neutral)}`) }}</p>
                                            </div>
                                            <div class="mb-2 text-lg font-medium sm:mb-0">
                                                <button
                                                v-permission="getPermissionPick"
                                                class="rounded-lg bg-sky-500 py-2 px-4 capitalize text-white"
                                                @click="pick(neutral)">
                                                    {{
                                                        $t('button.pick', {
                                                            role: `${replaceStr(neutral, '-', ' ')}`,
                                                        })
                                                    }}
                                                </button>
                                            </div>
                                        </div>
                                        <!-- </section> -->

                                        <div class="items-start justify-between space-x-2 border-t py-4 sm:flex">
                                            <div
                                                class="mb-2 items-center self-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                                <p>{{ $t('caseDetail.details.caseDescription') }}</p>
                                            </div>
                                            <div
                                                class="mb-2 overflow-y-scroll text-right text-lg font-medium text-slate-800 sm:mb-0">
                                                <p>
                                                    {{ activeCase.description }}
                                                </p>
                                            </div>
                                        </div>

                                        <div v-if="caseFileMapping.length > 0"
                                            class="w-full items-center justify-between border-t py-4 sm:flex">
                                            <div
                                                class="mb-2 w-full items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                                <p>
                                                    {{ $t('caseDetail.details.assignationDocument') }}
                                                </p>

                                                <div
                                                    class="mt-2 flex w-full max-w-sm space-x-2 overflow-x-scroll sm:max-w-lg md:max-w-xl lg:max-w-2xl xl:max-w-3xl">
                                                    <FileItem
                                                    v-for="attachment in caseFileMapping"
                                                    :key="attachment.id"
                                                    :file="attachment"
                                                    />
                                                </div>
                                            </div>
                                            <!-- <div
                                                class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                                            >
                                                <button
                                                class="rounded-lg bg-sky-500 py-2 px-4 text-white"
                                                @click="openDocumentCase(activeCase.attachment)"
                                                >
                                                {{ $t('button.open') }}
                                                </button>
                                            </div> -->
                                        </div>

                                        <div v-if="!checkEmptyObject(activeRequestTime)"
                                            class="items-center justify-between border-t py-4 sm:flex">
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                                <p>
                                                    {{ $t('timerExtension.confirmTimerExtension') }}
                                                </p>
                                            </div>
                                            <div
                                                class="mb-2 space-x-2 text-lg font-medium text-slate-800 sm:mb-0">
                                                <button class="rounded-lg bg-sky-500 py-2 px-4 text-white"
                                                    @click.stop="confirmRequestTime">
                                                    {{ $t('button.confirm') }}
                                                </button>
                                                <button class="rounded-lg bg-red-500 py-2 px-4 text-white"
                                                    @click.stop="rejectRequestTime">
                                                    {{ $t('button.reject') }}
                                                </button>
                                            </div>
                                        </div>

                                        <div class="items-start justify-between border-t py-4 sm:flex">
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                            </div>
                                            <div
                                                class="mb-2 space-x-4 text-lg font-medium text-slate-800 sm:mb-0">
                                                <button
                                                v-permission="'cases_manage'"
                                                class="rounded-lg border border-sky-500 bg-white py-2 px-4 text-sky-500 hover:text-sky-600"
                                                @click="inspectCase">
                                                    {{ $t('button.inspectCase') }}
                                                </button>
                                                <button
                                                v-permission="'cases_delete'"
                                                v-if="activeCase.stage === 1"
                                                class="rounded-lg bg-red-500 py-2 px-4 text-white"
                                                @click.stop="deleteCaseModalOpen = true">
                                                    {{ $t('button.remove') }}
                                                </button>
                                                <button
                                                v-if="canAssignCase"
                                                class="rounded-lg bg-sky-500 py-2 px-4 text-white"
                                                @click="assignCase"
                                                >
                                                    {{ $t('button.assign') }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Case Details -->
                    </section>
                    <DualRingLoader v-else />
                </div>
            </main>
        </div>

        <ModalDelete
        :is-open="deleteCaseModalOpen"
        :title="$t('modal.modalDelete.title')"
        :description="$t('modal.modalDelete.description')"
        @closed="deleteCaseModalOpen = false"
        @delete="removeCase"
        />

        <ModalAssignCaseDate :is-open="assignCaseOpen" @closed="assignCaseOpen = false" @confirm="assignCaseDate" />

        <ModalRejectExtenstionTime :id="activeRequestTime.id" :is-open="rejectRequestTimeOpen"
            :is-reject-loading="isLoading" @closed="rejectRequestTimeOpen = false" @reject="actionRejectRequestTime" />

        <ModalConfirmExtenstionTime :is-open="confirmRequestTimeOpen" @close="confirmRequestTimeOpen = false"
            @confirm="actionConfirmRequestTime" />
    </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'

import Sidebar from '@/partials/Sidebar.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'
import ModalDelete from '@/components/ModalDelete.vue'
import FileItem from '@/components/FileItem.vue'
import Currency from '@/components/currency/Currency.vue'
import ModalAssignCaseDate from '@/components/modal/case/ModalAssignCaseDate.vue'
import ModalRejectExtenstionTime from '@/components/modal/case/ModalRejectExtenstionTime.vue'
import ModalConfirmExtenstionTime from '@/components/modal/case/ModalConfirmExtenstionTime.vue'
import DropdownNotifications from '@/components/DropdownNotifications.vue'
import UserMenu from '@/components/DropdownProfile.vue'
import DropdownLanguages from '@/components/DropdownLanguages.vue'

export default {
    name: 'CaseDetailPage',
    components: {
        Sidebar,
        DualRingLoader,
        ModalDelete,
        FileItem,
        Currency,
        ModalAssignCaseDate,
        ModalRejectExtenstionTime,
        ModalConfirmExtenstionTime,
        DropdownNotifications,
        UserMenu,
        DropdownLanguages,
    },
    mixins: [globalMixin, caseMixin],
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()
        const sidebarOpen = ref(false)
        const assignCaseOpen = ref(false)
        const confirmRequestTimeOpen = ref(false)
        const rejectRequestTimeOpen = ref(false)
        const activeCase = ref({
            parties: [],
        })

        return {
            t,
            n,
            store,
            toast,
            sidebarOpen,
            assignCaseOpen,
            confirmRequestTimeOpen,
            rejectRequestTimeOpen,
            activeCase,
        }
    },
    data: () => ({
        deleteCaseModalOpen: false,
        priority: '',
        threeNeutral: ['bank-lawyer', 'advocate', 'mediator', 'arbitrator'],
        neutral: '',
    }),
    mounted() {
        // this.checkUserAccess('REDEK_ADMIN')
        this.getCaseDetailAdmin()
        this.getActiveRequestTime()
        this.getFileCase({ case_id: this.$route.params.slug, type: 'INITIAL' })
    },
    methods: {
        changePriority(event) {
            const priority = this.validPriority.includes(event.target.value)
                ? event.target.value
                : this.validPriority[0]

            this.priority = priority
            this.changeCasePriority()
        },
        assignCase() {
            this.assignCaseOpen = true
        },
        assignCaseDate(dueDate) {
            const params = { dueDate, id: this.$route.params.slug }
            this.caseDuteDateAPI(params)
        },
        pick(neutral, isReassignment = false) {
            this.store.dispatch('cases/setActiveCase', this.$route.params.slug)
            const query = {
                lang: this.$route.query.lang,
            }
            if (isReassignment) {
                query.reassignment = 1
            }

            this.$router.push({
                name: `${neutral}-selection`,
                params: {
                    slug: this.$route.params.slug,
                },
                query: query,
            })
        },
        confirmRequestTime() {
            this.confirmRequestTimeOpen = true
        },
        actionConfirmRequestTime() {
            if (!this.isLoading && !this.checkEmptyObject(this.activeRequestTime)) {
                const payload = {
                    id: this.activeRequestTime.id,
                    accept: true,
                }

                this.actionRequestTime(payload, true)
            }
        },
        rejectRequestTime() {
            this.rejectRequestTimeOpen = true
        },
        actionRejectRequestTime(events) {
            const payload = events
            payload.accept = false

            this.actionRequestTime(payload, true)
        },
    },
    computed: {
        canAssignCase () {
            return (
                this.activeCase.stage === 1 &&
                this.activeCase.advocate_time === null
            )
            ||
            (
                this.activeCase.stage === 2 &&
                this.activeCase.mediator_time === null
            )
            ||
            (
                this.activeCase.stage === 3 &&
                this.activeCase.arbitrator_time === null
            );
        },
        getPermissionPick () {
            const wayPermissions = {
                'bank-lawyer': 'cases_edit_pick_parties_bank_lawyer',
                'mediator': 'cases_edit_pick_parties_mediator',
                'arbitrator': 'cases_edit_pick_parties_arbitrator',
            }
            return wayPermissions[this.neutral];
        },
        getPermissionEditParty () {
            return (partyType) => {
                const wayPermissions = {
                    advocate: 'cases_edit_pick_parties_advocate',
                    bank_lawyer: 'cases_edit_pick_parties_bank_lawyer',
                    mediator: 'cases_edit_pick_parties_mediator',
                    arbitrator: 'cases_edit_pick_parties_arbitrator',
                }
                return wayPermissions[partyType]
            }
        },
        getPartiesActiveCase () {
            return (this.activeCase && this.activeCase.case_parties)
                ? this.activeCase.case_parties.filter(item => !item.was_reassigned)
                : []
        },
    }
}
</script>
