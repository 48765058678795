<template>
    <div class="flex w-full flex-wrap space-y-5 bg-white">
        <div v-if="request.user" class="flex items-center justify-start space-x-2">
            <div class="flex px-4">
                <ImageLoader wrapper-class="shrink-0 rounded-full" :src="request.user.avatar" width="30" height="30"
                    :alternate="request.user.first_name" />
            </div>
            <div>
                <div class="flex items-center space-x-2 font-medium">
                    <span>{{ request.user.first_name }} {{ request.user.last_name }}</span>
                    <div class="flex justify-start">
                        <p v-if="request.is_accept"
                            class="rounded-lg bg-green-100 py-1 px-2 text-center text-xs text-green-600">
                            {{ $t('badge.accepted') }}
                        </p>
                        <p v-else-if="request.is_accept === null"
                            class="rounded-lg bg-yellow-100 py-1 px-2 text-center text-xs text-yellow-600">
                            {{ $t('badge.pending') }}
                        </p>
                        <p v-else class="rounded-lg bg-red-100 py-1 px-2 text-center text-xs text-red-600">
                            {{ $t('badge.rejected') }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="request.reason" class="line-clamp-4 flex w-full px-5 text-justify">
            <span class="font-bold">{{ $t('calendar.modalInfo.reason') }} </span>:
            {{ request.reason }}
        </div>
        <div v-if="request.document" class="flex w-full px-5">
            <FileItem :file="request.document" wrapper-class="min-w-72 p-4" />
        </div>
    </div>
</template>
<script>
import ImageLoader from '@/components/image/ImageLoader.vue'
import FileItem from '@/components/FileItem.vue'

export default {
    name: 'CalendarRequestItemComponent',
    components: {
        ImageLoader,
        FileItem,
    },
    props: {
        request: {
            type: Object,
            required: true,
        },
    },
}
</script>
