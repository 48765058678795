<template>
  <ModalBlank id="mediator-modal" :modal-open="isOpen" @close-modal="close()">
    <div class="flex flex-col p-5">
      <!-- Modal header -->
      <div class="mb-2 flex w-full justify-center">
        <div class="text-xl font-semibold text-slate-800">
          {{ $t(`timerExtension.adminTitle`) }}
        </div>
      </div>
      <!-- Modal content -->
      <div class="mb-10 flex w-full flex-col justify-center space-y-2 text-sm">
        <p class="text-center">
          {{ $t(`timerExtension.description`) }}
        </p>
      </div>
      <!-- Modal footer -->
      <div class="flex flex-row justify-between space-x-2">
        <button
          class="btn w-full rounded-lg bg-slate-100 py-3 px-6 text-slate-800 hover:bg-slate-200"
          @click.stop="close()"
        >
          {{ $t('button.cancel') }}
        </button>
        <button
          class="btn w-full rounded-lg bg-sky-500 py-3 px-6 text-white hover:bg-sky-600"
          @click="confirm()"
        >
          {{ $t('button.confirm') }}
        </button>
      </div>
    </div>
  </ModalBlank>
</template>
<script>
import ModalBlank from '@/components/ModalBlank.vue'

export default {
  name: 'ModalConfirmExtenstionTime',
  components: { ModalBlank },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['confirm', 'close'],
  methods: {
    close() {
      this.$emit('close')
    },
    confirm() {
      this.$emit('confirm')
    },
  },
}
</script>
