import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'

const state = () => ({})

const getters = {}

const actions = {
    create ({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            let params = {
                name: payload.name,
                color: payload.color,
                contrast_to_color: payload.contrast_to_color,
                description: payload.description,
                status: payload.status,
            }
            axios.post(`${routePathAPI()}/casesareas`, params, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            })
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    update ({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            let params = {
                name: payload.name,
                color: payload.color,
                contrast_to_color: payload.contrast_to_color,
                description: payload.description,
                status: payload.status,
            }
            axios.put(`${routePathAPI()}/casesareas/${ payload.id }`, params, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            })
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    delete ({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(`${routePathAPI()}/casesareas/${ payload.id }`)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
