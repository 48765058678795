<template>
    <div class="flex h-screen overflow-hidden">
        <VOnboardingWrapper v-show="showGuide" ref="wrapper" :steps="steps" :options="options" />
        <!-- Sidebar -->
        <Sidebar
        :sidebar-open="sidebarOpen"
        :case-stage="activeCase.stage !== undefined ? activeCase.stage : $route.query.status"
        @close-sidebar="sidebarOpen = false"
        />

        <!-- Content area -->
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <main>
                <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
                    <div class="flex w-full flex-col">
                        <h1 class="text-2xl font-bold text-black">
                            {{ $t('dashboard.title') }}
                        </h1>
                        <p>
                            {{ $t('dashboard.description') }}
                        </p>
                    </div>

                    <template v-if="!isLoading">
                        <div class="mt-2 flex">
                            <div id="caseDetail"
                                class="my-4 mr-4 flex w-2/3 cursor-pointer items-center justify-center rounded-lg bg-white px-8 py-6 text-sm text-black"
                                @click="
                                    $router.push({
                                        name: 'case-stage',
                                        params: { slug: $route.params.slug },
                                        query: {
                                            name: $route.query.name,
                                            status: $route.query.status,
                                            sub_status: $route.query.sub_status,
                                            lang: $route.query.lang,
                                        },
                                    })
                                    ">
                                <div class="flex w-full flex-col">
                                    <div class="mb-1 flex text-sm text-slate-500">
                                        {{ t('dashboard.startedAt') }}
                                        {{ formatDateDay(activeCase.time) }}
                                    </div>
                                    <div class="flex font-semibold">
                                        {{ activeCase.name }}
                                    </div>
                                </div>
                                <div class="flex items-center justify-center">
                                    <ArrowPrevIcon />
                                </div>
                            </div>

                            <!-- on prepare -->
                            <div v-if="activeCase.stage === 1" id="currentStage"
                                class="my-4 flex w-1/3 cursor-pointer items-center justify-center rounded-lg bg-gray-500 px-4 py-6 text-sm text-white">
                                <div class="ml-4 flex w-full flex-col">
                                    <div class="mb-1 flex text-sm">{{ $t('caseDetail.details.currentStage') }}</div>
                                    <div class="flex font-bold">{{ $t('case.onPrepare') }}</div>
                                </div>
                            </div>
                            <!-- on going -->
                            <div v-else-if="activeCase.stage === 2" id="currentStage"
                                class="my-4 flex w-1/3 cursor-pointer items-center justify-center rounded-lg bg-blue-500 px-4 py-6 text-sm text-white">
                                <div class="ml-4 flex w-full flex-col">
                                    <div class="mb-1 flex text-sm">{{ $t('caseDetail.details.currentStage') }}</div>
                                    <div class="flex font-bold">{{ $t('case.stages.negotiation') }}</div>
                                </div>
                            </div>
                            <!-- mediation -->
                            <div v-else-if="activeCase.stage === 3" id="currentStage"
                                class="my-4 flex w-1/3 cursor-pointer items-center justify-center rounded-lg bg-yellow-500 px-4 py-6 text-sm text-white">
                                <div class="ml-4 flex w-full flex-col">
                                    <div class="mb-1 flex text-sm">{{ $t('caseDetail.details.currentStage') }}</div>
                                    <div class="flex font-bold">{{ $t('case.stages.mediation') }}</div>
                                </div>
                            </div>
                            <!-- arbitration -->
                            <div v-else-if="activeCase.stage === 4" id="currentStage"
                                class="my-4 flex w-1/3 cursor-pointer items-center justify-center rounded-lg bg-red-500 px-4 py-6 text-sm text-white">
                                <div class="ml-4 flex w-full flex-col">
                                    <div class="mb-1 flex text-sm">{{ $t('caseDetail.details.currentStage') }}</div>
                                    <div class="flex font-bold">{{ $t('case.stages.arbitration') }}</div>
                                </div>
                            </div>
                            <!-- solved -->
                            <div v-else-if="activeCase.stage === 5" id="currentStage"
                                class="my-4 flex w-1/3 cursor-pointer items-center justify-center rounded-lg bg-green-500 px-4 py-6 text-sm text-white">
                                <div class="ml-4 flex w-full flex-col">
                                    <div class="mb-1 flex text-sm">{{ $t('caseDetail.details.currentStage') }}</div>
                                    <div class="flex font-bold">{{ $t('case.stages.solved') }}</div>
                                </div>
                            </div>
                        </div>

                        <!-- Dashboard actions -->
                        <div id="recentActivities" class="mb-8 mt-8 sm:flex sm:items-center sm:justify-between">
                            <!-- Left: Avatars -->
                            <div class="flex w-full flex-col">
                                <h1 class="text-xl font-bold text-black">
                                    {{ $t('dashboard.recentActivities') }}
                                </h1>
                                <p v-if="activeTimelines.length > 0">
                                    {{ $t('updated') }} {{ timeAgo(activeTimelines[0].modified) }}
                                </p>
                            </div>

                            <!-- Right: Actions -->
                            <div class="grid grid-flow-col justify-start gap-2 sm:auto-cols-max sm:justify-end">
                                <div
                                v-if="!$store.getters['cases/currentCaseWasClosed'] && isAValidPartInCase($store.getters['auth/getCurrentUser'])"
                                v-permission="'cases_manage_timeline_create'"
                                class="my-2 flex w-full cursor-pointer items-center justify-center rounded-lg bg-sky-500 px-8 py-3 text-sm text-white"
                                @click.stop="newActivityModalOpen = true"
                                >
                                    <div class="flex w-full">
                                        <div class="flex">{{ $t('admin.timeline.buttonAdd') }}</div>
                                    </div>
                                </div>
                                <div
                                v-permission="'cases_manage_timeline_list'"
                                class="my-2 flex w-full cursor-pointer items-center justify-center rounded-lg bg-white px-8 py-3 text-sm text-black"
                                @click="
                                    $router.push({
                                        name: 'timeline',
                                        params: { slug: $route.params.slug }
                                    })
                                ">
                                    <div class="flex w-full">
                                        <div class="flex">{{ $t('dashboard.showTimeline') }}</div>
                                    </div>
                                    <div class="ml-2 flex items-center justify-center">
                                        <TimeIcon />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="relative mb-8">
                            <ul
                                class="no-scrollbar relative -mx-4 flex flex-nowrap overflow-x-scroll text-sm font-medium sm:-mx-6 lg:-mx-8">
                                <li
                                v-for="activity in typeActivity"
                                :key="activity.id"
                                class="mr-8 first:pl-4 last:mr-0 last:pr-4 sm:first:pl-6 sm:last:pr-6 lg:first:pl-8 lg:last:pr-8"
                                v-permission="activity.permission"
                                >
                                    <a class="block cursor-pointer whitespace-nowrap pb-3" :class="activePage === activity.type
                                        ? 'border-b-3 border-slate-900 font-bold text-slate-800'
                                        : 'text-slate-500 hover:text-slate-600'
                                        " @click="changeActivePage(activity.type)">
                                        {{ $t(activity.name) }}
                                    </a>
                                </li>
                                <select
                                id="types"
                                class="form-select w-36 p-2"
                                v-model="filterTimelineStage"
                                @change="changeActivePage('stage')"
                                >
                                    <option
                                    v-for="(stage, index) in stages"
                                    :key="index"
                                    :value="stage.key"
                                    >
                                        {{ stage.label }}
                                    </option>
                                </select>
                            </ul>
                        </div>
                        <template v-if="activeTimelinesArr.length > 0 && !isLoading">
                            <ActivityItemDate :activity-date="activeTimelines" wrapper-class="bg-white" />
                        </template>
                        <div v-else-if="!isLoading">
                            <!-- <NoData /> -->
                            <div class="flex w-full flex-col items-center text-center text-sm">
                                <TimeFilledIcon class="h-20 w-20" />
                                <span class="text-violet">
                                    {{ $t('noActivities') }}
                                </span>
                            </div>
                        </div>
                        <DualRingLoader v-else />
                    </template>
                    <DualRingLoader v-else />
                </div>
            </main>
        </div>

        <ModalCreateActivity
        :is-open="newActivityModalOpen"
        :active-activity="activeActivity"
        :is-create-loading="isModalLoading"
        @closed="newActivityModalOpen = false"
        @submit-data="saveTimeline"
        />

        <RightSidebar :sidebar-open="sidebarOpen" @close-sidebar="sidebarOpen = false" />
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { VOnboardingWrapper, useVOnboarding } from 'v-onboarding'
import 'v-onboarding/dist/style.css'
import globalMixin from '@/mixins/global.js'
import profileMixin from '@/mixins/profile.js'
import timelineMixin from '@/mixins/timeline.js'

import Sidebar from '@/partials/Sidebar.vue'
import RightSidebar from '@/partials/RightSidebar.vue'
import ActivityItemDate from '@/components/ActivityItemDate.vue'
// import NoData from '@/components/NoData.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'
import ModalCreateActivity from '@/components/ModalCreateActivity.vue'

import ArrowPrevIcon from '@/assets/svg/arrow-prev.svg'
import TimeIcon from '@/assets/svg/time.svg'
import TimeFilledIcon from '@/assets/svg/filled/Time-filled.svg'

export default {
    name: 'DashboardPage',
    components: {
        VOnboardingWrapper,
        Sidebar,
        RightSidebar,
        ActivityItemDate,
        // NoData,
        DualRingLoader,
        ModalCreateActivity,
        ArrowPrevIcon,
        TimeIcon,
        TimeFilledIcon,
    },
    mixins: [globalMixin, profileMixin, timelineMixin],
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()
        const sidebarOpen = ref(false)
        const activeCase = ref({})
        const newActivityModalOpen = ref(false)
        const deleteActivityModalOpen = ref(false)
        const activeActivity = ref({})
        const stages = [
            { key: '1', label: t('case.stages.on_prepare') },
            { key: '2', label: t('case.stages.negotiation') },
            { key: '3', label: t('case.stages.mediation') },
            { key: '4', label: t('case.stages.arbitration') },
            { key: '5', label: t('case.stages.solved') },
        ]

        const wrapper = ref(null)
        const { start, goToStep, finish } = useVOnboarding(wrapper)
        const steps = [
            {
                attachTo: { element: '#logo' },
                content: {
                    title: t('guidance.boarding.title'),
                    description: t('guidance.boarding.content'),
                },
            },
            {
                attachTo: { element: '#caseDetail' },
                content: {
                    title: t('guidance.caseDetail.title'),
                    description: t('guidance.caseDetail.content'),
                },
            },
            {
                attachTo: { element: '#currentStage' },
                content: {
                    title: t('guidance.currentStage.title'),
                    description: t('guidance.currentStage.content'),
                },
            },
            {
                attachTo: { element: '#recentActivities' },
                content: {
                    title: t('guidance.recentActivities.title'),
                    description: t('guidance.recentActivities.content'),
                },
            },
            {
                attachTo: { element: '#deadlines' },
                content: {
                    title: t('guidance.deadlines.title'),
                    description: t('guidance.deadlines.content'),
                },
            },
            {
                attachTo: { element: '#caseStages' },
                content: {
                    title: t('guidance.caseStages.title'),
                    description: t('guidance.caseStages.content'),
                },
            },
            {
                attachTo: { element: '#privateNotebook' },
                content: {
                    title: t('guidance.privateNotebook.title'),
                    description: t('guidance.privateNotebook.content'),
                },
            },
            // {
            //   attachTo: { element: '#meetingRoom' },
            //   content: {
            //     title: t('guidance.meetingRoom.title'),
            //     description: t('guidance.meetingRoom.content'),
            //   },
            // },
            {
                attachTo: { element: '#chats' },
                content: {
                    title: t('guidance.chats.title'),
                    description: t('guidance.chats.content'),
                },
            },
            {
                attachTo: { element: '#documents' },
                content: {
                    title: t('guidance.documents.title'),
                    description: t('guidance.documents.content'),
                },
            },
            {
                attachTo: { element: '#timeline' },
                content: {
                    title: t('guidance.timeline.title'),
                    description: t('guidance.timeline.content'),
                },
            },
            {
                attachTo: { element: '#calendar' },
                content: {
                    title: t('guidance.calendar.title'),
                    description: t('guidance.calendar.content'),
                },
            },
            {
                attachTo: { element: '#caseMoreDetail' },
                content: {
                    title: t('guidance.caseMoreDetail.title'),
                    description: t('guidance.caseMoreDetail.content'),
                },
            },
        ]
        const options = {
            popper: {
                placement: 'bottom-start',
            },
            overlay: {
                enabled: true,
                padding: 10,
                borderRadius: 10,
            },
            scrollToStep: {
                enabled: true,
                options: {
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                },
            },
            hideButtons: {
                previous: false,
                next: false,
            },
            labels: {
                previousButton: t('action.previous'),
                nextButton: t('action.next'),
                finishButton: t('action.finish'),
            },
        }

        onMounted(() => start())

        return {
            t,
            n,
            store,
            toast,
            sidebarOpen,
            activeCase,
            activeActivity,
            newActivityModalOpen,
            deleteActivityModalOpen,
            wrapper,
            steps,
            options,
            stages,
        }
    },
    data: () => ({
        activePage: 'all',
        typeActivity: [
            { type: 'all', name: 'activity.all', permission: 'cases_manage_timeline_list_all' },
            { type: 'external', name: 'activity.external', permission: 'cases_manage_timeline_list_external' },
            { type: 'stage', name: 'activity.stage', permission: 'cases_manage_timeline_list_stage' },
        ],
        filterDataTimeline: {
            page: 1,
            take: 50,
            search: '',
            order: 'DESC',
        },
        metaDataTimeline: {
            page: 1,
            take: 10,
            itemCount: 10,
            pageCount: 1,
            hasPreviousPage: false,
            hasNextPage: true,
        },
        timelines: [],
        activeTimelinesArr: [],
        activeTimelines: {},
        filterTimelineStage: 1,
    }),
    mounted() {
        this.getCaseDetail();
        if (this.$echo) {
            this.$echo.channel("CaseStage")
            .listen(".CaseStageEvent", (event) => {
                if (
                    event
                    &&
                    event.data
                    &&
                    event.data.emitted_by
                    &&
                    event.data.case_id
                    &&
                    this.activeCase
                    &&
                    this.activeCase.id == event.data.case_id
                ) {
                    this.getCaseDetail();
                }
            });
        }
        this.getAllTimeline()
    },
    methods: {
        changeActivePage(type) {
            this.activePage = type
            const dataTimelines = this.timelines.filter(item => {
                if (
                    type === 'all'
                    ||
                    (
                        (
                            type === 'stage'
                            &&
                            item.stage == this.filterTimelineStage
                        )
                        ||
                        (
                            type != 'stage'
                            &&
                            type === item.type
                        )
                    )
                ) {
                    return item
                }
            });

            this.activeTimelinesArr = dataTimelines
            const mappingDateTimeline = {}
            dataTimelines.forEach((item) => {
                if (item.updated_at !== '') {
                    const date = item.updated_at.split('T')[0]
                    if (mappingDateTimeline.hasOwnProperty(date)) {
                        mappingDateTimeline[date].push(item)
                    } else {
                        mappingDateTimeline[date] = [item]
                    }
                }
            })
            this.activeTimelines = mappingDateTimeline
        },
        async getAllTimeline() {
            this.isLoading = true
            await this.store
                .dispatch('timeline/getAllTimeline', {
                    order: this.filterDataTimeline.order,
                    page: this.filterDataTimeline.page,
                    take: this.filterDataTimeline.take,
                    q: this.filterDataTimeline.search,
                    case_id: this.$route.params.slug,
                })
                .then((response) => {
                    this.isLoading = false
                    const responseData = response.data
                    if (responseData.data) {
                        this.timelines = responseData.data;
                        this.changeActivePage('all')
                    }
                    if (responseData.from) {
                        this.metaDataTimeline = {
                            current_page: responseData.current_page,
                            from: responseData.from,
                            last_page: responseData.last_page,
                            links: responseData.links,
                            per_page: responseData.per_page,
                            to: responseData.to,
                            total: responseData.total,
                        }
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async getCaseDetail() {
            this.isLoading = true
            await this.store
                .dispatch('cases/getCaseDetail', {
                    id: this.$route.params.slug,
                })
                .then((response) => {
                    this.isLoading = false
                    const responseData = response.data;
                    this.activeCase = responseData
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
    },
}
</script>

<style>
:root {
    --v-onboarding-step-arrow-size: 14px;
    --v-onboarding-step-z: 30;
}

.v-onboarding-item__header-title,
.v-onboarding-item__description {
    font-size: 1rem !important;
}

.v-onboarding-btn-primary,
.v-onboarding-btn-secondary {
    font-size: 0.8rem !important;
}

.v-onboarding-btn-primary {
    background: rgb(14 165 233) !important;
}

div[data-popper-placement] {
    margin-top: 20px !important;
}
</style>
