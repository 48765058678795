<template>
    <main>
        <div class="relative flex py-10">
            <!-- Content -->
            <div class="w-full">
                <div
                    class="flex h-full min-h-screen flex-col justify-center bg-gray-100"
                >
                    <div class="mb-8 flex justify-center">
                        <img
                            class="h-14 object-cover object-center"
                            src="../assets/images/logo.png"
                            alt="Redek Logo"
                        />
                    </div>
                    <div
                        class="w-2/4 self-center rounded-lg bg-white px-8 py-8 shadow-md lg:w-1/3"
                    >
                        <h1 class="mb-2 text-3xl font-bold text-slate-800">
                            {{ $t('register.title') }}
                        </h1>
                        <p class="text-sm">
                            {{ $t('register.already') }}
                            <router-link
                                id="form-signup-action-signin"
                                class="text-sm font-bold text-sky-500"
                                to="/login"
                            >
                                {{ $t('register.signIn') }}
                            </router-link>
                        </p>
                        <!-- Form -->
                        <Form
                            id="form-signup"
                            v-slot="{ errors }"
                            :validation-schema="registerSchema"
                            @submit="doRegister"
                        >
                            <div class="mt-4 space-y-4">
                                <div>
                                    <label
                                        class="mb-1 block text-sm font-medium"
                                        for="firstName"
                                    >
                                        {{ $t('profile.identificationType') }}
                                        <span class="text-rose-500">*</span>
                                    </label>
                                    <Field
                                        v-slot="{ field }"
                                        v-model="identification_type_id"
                                        name="identification_type_id"
                                    >
                                        <select
                                        id="form-signup-identification-type-id"
                                        v-bind="field"
                                        class="form-select w-full p-2">
                                            <option
                                            v-for="identificationType in identificationTypes"
                                            :key="identificationType.id"
                                            :value="identificationType.id">
                                                {{ identificationType.name.slice(0, 1) + identificationType.name.slice(1, identificationType.length).toLowerCase() }}
                                            </option>
                                        </select>
                                    </Field>
                                    <span class="text-sm text-red-500">
                                        <template
                                            v-if="
                                                errors.identification_type_id !== undefined
                                            "
                                        >
                                            {{ $t(errors.identification_type_id) }}
                                        </template>
                                    </span>
                                </div>
                                <div>
                                    <label
                                        class="mb-1 block text-sm font-medium"
                                        for="firstName"
                                    >
                                        {{ $t('admin.user.modalCreateUser.identification') }}
                                        <span class="text-rose-500">*</span>
                                    </label>
                                    <Field
                                        v-slot="{ field }"
                                        v-model="identification"
                                        name="identification"
                                    >
                                        <input
                                            id="form-signup-identification"
                                            v-bind="field"
                                            class="form-input h-12 w-full rounded-md"
                                            type="text"
                                            :placeholder="
                                                $t('admin.user.modalCreateUser.identification')
                                            "
                                            required="true"
                                        />
                                    </Field>
                                    <span class="text-sm text-red-500">
                                        <template
                                            v-if="
                                                errors.identification !== undefined
                                            "
                                        >
                                            {{ $t(errors.identification) }}
                                        </template>
                                    </span>
                                </div>
                                <div>
                                    <label
                                        class="mb-1 block text-sm font-medium"
                                        for="firstName"
                                    >
                                        {{ $t('formLabel.firstName') }}
                                        <span class="text-rose-500">*</span>
                                    </label>
                                    <Field
                                        v-slot="{ field }"
                                        v-model="firstName"
                                        name="firstName"
                                    >
                                        <input
                                            id="form-signup-firstname"
                                            v-bind="field"
                                            class="form-input h-12 w-full rounded-md"
                                            type="text"
                                            :placeholder="
                                                $t('formInput.enterFirstName')
                                            "
                                            required="true"
                                        />
                                    </Field>
                                    <span class="text-sm text-red-500">
                                        <template
                                            v-if="
                                                errors.firstName !== undefined
                                            "
                                        >
                                            {{ $t(errors.firstName) }}
                                        </template>
                                    </span>
                                </div>
                                <div>
                                    <label
                                        class="mb-1 block text-sm font-medium"
                                        for="second-name"
                                    >
                                        {{ $t('formLabel.lastName') }}
                                    </label>
                                    <Field
                                        v-slot="{ field }"
                                        v-model="secondName"
                                        name="secondName"
                                    >
                                        <input
                                            id="form-signup-lastname"
                                            v-bind="field"
                                            class="form-input h-12 w-full rounded-md"
                                            type="text"
                                            :placeholder="
                                                $t('formInput.enterLastName')
                                            "
                                        />
                                    </Field>
                                    <span class="text-sm text-red-500">
                                        <template
                                            v-if="
                                                errors.secondName !== undefined
                                            "
                                        >
                                            {{ $t(errors.secondName) }}
                                        </template>
                                    </span>
                                </div>
                                <div>
                                    <label
                                        class="mb-1 block text-sm font-medium"
                                        for="email"
                                    >
                                        {{ $t('formLabel.email') }}
                                        <span class="text-rose-500"
                                            >*</span
                                        ></label
                                    >
                                    <Field
                                        v-slot="{ field }"
                                        v-model="email"
                                        name="email"
                                    >
                                        <input
                                            id="form-signup-email"
                                            v-bind="field"
                                            class="form-input h-12 w-full rounded-md"
                                            type="email"
                                            autocomplete="new-email"
                                            :placeholder="
                                                $t('formInput.enterEmail')
                                            "
                                        />
                                    </Field>
                                    <span class="text-sm text-red-500">
                                        <template
                                            v-if="errors.email !== undefined"
                                        >
                                            {{ $t(errors.email) }}
                                        </template>
                                    </span>
                                </div>
                                <div>
                                    <label
                                        class="mb-1 block text-sm font-medium"
                                        for="phoneNumber"
                                    >
                                        {{ $t('formLabel.phone') }}
                                        <span class="text-rose-500"
                                            >*</span
                                        ></label
                                    >
                                    <Field
                                        v-slot="{ field }"
                                        v-model="phoneNumber"
                                        name="phoneNumber"
                                    >
                                        <input
                                            id="form-signup-phone"
                                            v-bind="field"
                                            class="form-input h-12 w-full rounded-md"
                                            type="text"
                                            :placeholder="
                                                $t('formInput.enterPhone')
                                            "
                                            required="true"
                                        />
                                    </Field>
                                    <span class="text-sm text-red-500">
                                        <template
                                            v-if="
                                                errors.phoneNumber !== undefined
                                            "
                                        >
                                            {{ $t(errors.phoneNumber) }}
                                        </template>
                                    </span>
                                </div>
                                <div>
                                    <label
                                        class="mb-1 block text-sm font-medium"
                                        for="password"
                                    >
                                        {{ $t('formLabel.password') }}
                                        <span class="text-rose-500"
                                            >*</span
                                        ></label
                                    >
                                    <Field
                                        v-slot="{ field }"
                                        v-model="password"
                                        name="password"
                                    >
                                        <div class="relative">
                                            <input
                                                id="form-signup-password"
                                                v-bind="field"
                                                autocomplete="new-password"
                                                class="form-input h-12 w-full rounded-md"
                                                :type="
                                                    showPassword
                                                        ? 'text'
                                                        : 'password'
                                                "
                                                :placeholder="
                                                    $t(
                                                        'formInput.enterPassword'
                                                    )
                                                "
                                            />
                                            <div
                                                class="absolute inset-0 left-auto flex cursor-pointer items-center"
                                            >
                                                <span
                                                    class="px-3 text-sm font-medium text-slate-400"
                                                >
                                                    <EyeStrikeIcon
                                                        v-if="showPassword"
                                                        class="h-6 w-6"
                                                        @click="
                                                            showPassword = false
                                                        "
                                                    />
                                                    <EyeIcon
                                                        v-else
                                                        class="h-6 w-6"
                                                        @click="
                                                            showPassword = true
                                                        "
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </Field>
                                    <span class="text-sm text-red-500">
                                        <template
                                            v-if="errors.password !== undefined"
                                        >
                                            {{ $t(errors.password) }}
                                        </template>
                                    </span>
                                </div>
                                <div>
                                    <label
                                        class="mb-1 block text-sm font-medium"
                                        for="confirmPassword"
                                    >
                                        {{
                                            $t('formLabel.passwordConfirmation')
                                        }}
                                        <span class="text-rose-500"
                                            >*</span
                                        ></label
                                    >
                                    <Field
                                        v-slot="{ field }"
                                        v-model="confirmPassword"
                                        name="confirmPassword"
                                    >
                                        <div class="relative">
                                            <input
                                                id="form-signup-password-confirm"
                                                v-bind="field"
                                                class="form-input h-12 w-full rounded-md"
                                                autocomplete="new-password"
                                                :type="
                                                    showConfirmPassword
                                                        ? 'text'
                                                        : 'password'
                                                "
                                                :placeholder="
                                                    $t(
                                                        'formInput.enterConfirmPassword'
                                                    )
                                                "
                                                required="true"
                                            />
                                            <div
                                                class="absolute inset-0 left-auto flex cursor-pointer items-center"
                                            >
                                                <span
                                                    class="px-3 text-sm font-medium text-slate-400"
                                                >
                                                    <EyeStrikeIcon
                                                        v-if="
                                                            showConfirmPassword
                                                        "
                                                        class="h-6 w-6"
                                                        @click="
                                                            showConfirmPassword = false
                                                        "
                                                    />
                                                    <EyeIcon
                                                        v-else
                                                        class="h-6 w-6"
                                                        @click="
                                                            showConfirmPassword = true
                                                        "
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </Field>
                                    <span class="text-sm text-red-500">
                                        <template
                                            v-if="
                                                errors.confirmPassword !==
                                                undefined
                                            "
                                        >
                                            {{ $t(errors.confirmPassword) }}
                                        </template>
                                    </span>
                                </div>
                            </div>
                            <div class="mt-2 flex flex-col">
                                <button
                                    id="form-signup-action-submit"
                                    class="btn text-md mt-6 h-12 w-full rounded-md"
                                    :class="[
                                        errors.firstName === undefined &&
                                        errors.email === undefined &&
                                        errors.password === undefined &&
                                        errors.phoneNumber === undefined &&
                                        errors.confirmPassword === undefined &&
                                        firstName !== '' &&
                                        email !== '' &&
                                        phoneNumber !== '' &&
                                        password !== '' &&
                                        confirmPassword !== ''
                                            ? 'bg-sky-500 text-white hover:bg-sky-600'
                                            : 'bg-gray-300 text-slate-100',
                                        isLoading
                                            ? 'cursor-not-allowed'
                                            : 'cursor-pointer',
                                    ]"
                                >
                                    <span v-if="isLoading">
                                        {{ $t('general.loading') }}
                                    </span>
                                    <span v-else>
                                        {{ $t('button.signUp') }}
                                    </span>
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { useToast } from 'vue-toastification'
import { Form, Field } from 'vee-validate'
import globalMixin from '@/mixins/global.js'

import EyeIcon from '@/assets/svg/eye.svg'
import EyeStrikeIcon from '@/assets/svg/eye-strike.svg'

export default {
    name: 'RegisterPage',
    components: {
        Form,
        Field,
        EyeIcon,
        EyeStrikeIcon,
    },
    mixins: [globalMixin],
    setup() {
        const toast = useToast()
        return { toast }
    },
    data: () => ({
        identification: '',
        identification_type_id: null,
        firstName: '',
        secondName: '',
        email: '',
        phoneNumber: '',
        password: '',
        showPassword: false,
        confirmPassword: '',
        showConfirmPassword: false,
        identificationTypes: []
    }),
    mounted() {
        this.getAllIdentificationTypes()
    },
    methods: {
        doRegister() {
            if (this.isLoading) return

            this.isLoading = true
            const payload = {
                identification_type_id: this.identification_type_id,
                identification: this.identification,
                first_name: this.firstName,
                last_name: this.secondName,
                email: this.email,
                phone: this.phoneNumber,
                password: this.password,
            }
            this.$store
                .dispatch('auth/register', payload)
                .then((responseRegister) => {
                    this.isLoading = false
                    localStorage.setItem('token', responseRegister.access_token)
                    this.$store
                        .dispatch('auth/user')
                        .then((userResponse) => {
                            localStorage.setItem(
                                'redek.temp.activeUser',
                                JSON.stringify(userResponse)
                            )
                            if (userResponse.is_active_otp) {
                                /**
                                 * Send OTP
                                 */
                                this.$store
                                    .dispatch('auth/sendOtp')
                                    .catch((error) => {
                                        this.toast.error(
                                            this.extractErrorResponse(error)
                                        )
                                    })
                                this.$router.push({
                                    name: 'verification-otp',
                                    params: {
                                        type: 'register',
                                    },
                                    query: {
                                        lang: this.$route.query.lang,
                                    },
                                })
                            } else {
                            }
                        })
                        .catch((errorUser) => {
                            this.isLoading = false
                            this.toast.error(
                                this.extractErrorResponse(errorUser)
                            )
                        })
                })
                .catch((error) => {
                    this.isLoading = false

                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        getAllIdentificationTypes() {
            this.$store
                .dispatch('identificationtypes/getAll', { take: 300})
                .then((response) => {
                    if (response.data && response.data.data) {
                        this.identificationTypes = response.data.data
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
    },
}
</script>
