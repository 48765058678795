<template>
    <div class="-m-1.5 flex flex-wrap items-center">
        <div class="m-1.5">
            <ModalBasic
            id="notes-modal"
            :modal-open="isOpen"
            :show-header="true"
            title="New Message"
            @close-modal="close"
            :backdrop="true"
            >
                <div class="px-5 py-4">
                    <div class="flex pr-2">
                        <div class="flex w-3/6 justify-end">
                            <EditMenu v-if="activeChat.id" align="right" class="relative ml-2 inline-flex">
                                <li>
                                    <span
                                        class="flex cursor-pointer py-1 px-3 text-sm font-medium text-rose-500 hover:text-rose-600"
                                        @click="deleteChat">
                                        {{ $t('privateNotebook.forms.menus.delete') }}
                                    </span>
                                </li>
                            </EditMenu>
                        </div>
                    </div>
                    <div class="space-y-3">
                        <div class="h-max-full w-full overflow-ellipsis">
                            <Editor :initial-content="content" :active-buttons="[
                                'bold',
                                'italic',
                                'strike',
                                'underline',
                                // 'code',
                                // 'h1',
                                // 'h2',
                                // 'h3',
                                // 'bulletList',
                                // 'orderedList',
                                // 'blockquote',
                                // 'codeBlock',
                                'horizontalRule',
                                'undo',
                                'redo',
                                // 'document',
                            ]" @update="updateContent" @attach-icon="$refs.files.click()" />
                            <label for="files" class="-mt-10 inline cursor-pointer rounded-lg">
                                <input id="files" ref="files" type="file" class="hidden" @change="fileInput" />
                                <FileItem
                                v-if="attachment"
                                :file="attachment"
                                :show-download="false"
                                />
                            </label>
                            <div v-if="attachment" class="flex justify-end">
                                <button @click="deleteAttach" class="text-red-500 text-sm">
                                    {{ $t('remove').toLowerCase() }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="px-5 py-4">
                    <div class="flex flex-wrap justify-end space-x-4">
                        <span
                            class="cursor-pointer rounded-lg bg-sky-600 py-2 px-8 text-sm text-white shadow-lg hover:text-sky-900 hover:shadow-xl"
                            @click.stop="saveChat">
                            {{ $t('modal.save') }}
                        </span>
                    </div>
                </div>
            </ModalBasic>
        </div>
    </div>
</template>
<script>
import ModalBasic from './ModalBasic.vue'
import EditMenu from './DropdownEditMenu.vue'
import Editor from '@/components/editor/Editor.vue'

// import AttachIcon from '@/assets/svg/attach.svg'
import FileItem from '@/components/FileItem.vue'

export default {
    name: 'ModalChatComponent',
    components: {
        ModalBasic,
        EditMenu,
        Editor,
        FileItem,
        // AttachIcon,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        date: {
            type: String,
            default: '',
        },
        activeChat: {
            type: Object,
            default: () => {
                return {
                    title: '',
                    content: '',
                }
            },
        },
    },
    emits: ['closed', 'delete-chat', 'edit-chat', 'new-chat'],
    data: () => ({
        title: '',
        content: '',
        attachment: null,
    }),
    methods: {
        close() {
            this.$emit('closed')
        },
        saveChat() {
            let params = {}
            params.content = this.content
            params.case_id = this.$route.params.slug
            params.chat_room_id = this.$route.params.id
            params.type = 'other'
            params.title = ''
            params.amount = null
            params.currency_id = null
            params.deadline_at = null
            params.attachment = this.attachment
            params.party_ids = ''
            if (this.activeChat.id) {
                params.id = this.activeChat.id
                this.$emit('edit-chat', params)
            } else {
                this.$emit('new-chat', params)
                this.content = ''
            }
        },
        deleteChat() {
            if (this.activeChat.id) {
                this.$emit('delete-chat', this.activeChat.id)
            } else {
                return
            }
        },
        fileInput(event) {
            this.attachment = event.target.files[0]
        },
        updateContent(event) {
            this.content = event
        },
        deleteAttach () {
            this.attachment = null
            this.$refs.files.value = ''
        }
    },
}
</script>
