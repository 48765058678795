<template>
    <div class="flex h-screen overflow-hidden">
        <!-- Sidebar -->
        <Sidebar :sidebar-open="sidebarOpen" @close-sidebar="sidebarOpen = false" />

        <!-- Content area -->
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <main>
                <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
                    <div class="flex justify-start">
                        <div class="flex w-full flex-col">
                            <h1 class="text-2xl font-bold text-black">
                                {{ $t(`admin.cases.title.${status}`) }}
                            </h1>
                            <p>{{ $t(`admin.cases.description.${status}`) }}</p>
                        </div>
                        <div class="mt-4 flex w-full items-center justify-end">
                            <DropdownLanguages class="mr-4"/>
                            <DropdownNotifications align="right" class="md:mr-4" wrapper-class="bg-white rounded-lg" />
                            <DropdownProfile align="right" mode="aesthetic" />
                        </div>
                    </div>
                    <div

                    class="my-4 flex justify-end"
                    >
                        <div
                        v-permission="['cases_create', 'cases_import']"
                        v-if="!showNewCaseModal" class="flex gap-3">
                            <ButtonAdd
                            v-permission="'cases_create'"
                            :title="$t('case.newComplaint')"
                            custom-class="rounded-3xl px-6"
                            @clicked="showNewCaseModal = true"
                            />
                            <button
                            v-permission="'cases_import'"
                            @click.stop="showImportCaseModal = true"
                            class="rounded-3xl text-green-700 btn px-6 bg-green-300 flex items-center gap-1">
                                <FileTypeIcon file-type="xlsx" icon-class="inline h-7 cursor-pointer" />
                                <span>
                                    {{ $t('button.import') }}
                                </span>
                            </button>
                        </div>
                        <section v-if="showNewCaseModal" class="relative">
                            <div class="space-y-2 rounded-lg bg-white p-6 text-sm">
                                <span
                                class="absolute right-2 top-2 cursor-pointer"
                                @click="showNewCaseModal = false"
                                >
                                    <XIcon class="h-3 w-3 text-gray-300 hover:text-gray-500"/>
                                </span>
                                <div class="flex w-full">
                                    <p class="font-bold">{{ $t('newCase.how') }}</p>
                                </div>
                                <div class="flex w-full justify-center gap-2">
                                    <button
                                    v-permission="'cases_create_alone'"
                                    class="rounded-full border border-sky-400 bg-white py-2 px-4 text-sky-400 hover:bg-sky-400 hover:text-white"
                                    @click="toNewCase(false)"
                                    >
                                        {{ $t('newCase.doItMySelf') }}
                                    </button>
                                    <button
                                    v-permission="'cases_create_assistance'"
                                    class="rounded-full border border-sky-400 bg-white py-2 px-4 text-sky-400 hover:bg-sky-400 hover:text-white"
                                    @click="toNewCase(true)"
                                    >
                                        {{ $t('newCase.iNeedAssistance') }}
                                    </button>
                                    <button
                                    v-permission="'cases_create_precreated'"
                                    class="rounded-full border border-green-500 bg-white py-2 px-4 text-green-500 hover:bg-green-500 hover:text-white"
                                    @click="toPreCreatedCase()"
                                    >
                                        {{ $t('newCase.preCreated.button') }}
                                    </button>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div class="my-4 flex w-full justify-between">
                        <div class="flex items-center space-x-4">
                            <SearchForm :placeholder="$t('admin.cases.searchPlaceholder')" @search="searchCase" />
                            <div class="flex items-center">
                                <div class="min-w-[130px]">
                                    {{ $t('admin.cases.filter.byArea.title') }}
                                </div>
                                <select
                                @change="changeArea"
                                class="form-select p-2 min-w-[190px]"
                                >
                                    <option value="none">
                                        {{ $t('admin.cases.filter.byArea.select') }}...
                                    </option>
                                    <option
                                    v-for="area in caseAreasToImport"
                                    :key="area.id"
                                    :value="area.id">
                                        {{ area.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="flex cursor-pointer items-start text-sm text-black">
                                <div class="flex">
                                    <p>{{ $t('formLabel.sortBy') }}</p>
                                </div>
                                <div class="flex">
                                    <select class="border-none bg-inherit py-0 font-bold focus:ring-0"
                                        @change="changeOrder">
                                        <option v-for="order in orderOptions" :key="order.value" :value="order.value"
                                            :selected="filterDataCases.order === order.value">
                                            {{ $t(`${order.label}`) }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="text-sm flex items-center gap-2">
                                <select
                                @change="changeNRecords"
                                v-model="filterDataCases.take"
                                class="form-input w-16"
                                >
                                    <option
                                    v-for="item in [5, 10, 25, 50, 100]"
                                    :value="item"
                                    >
                                        {{ item }}
                                    </option>
                                </select>
                                <span>
                                    {{ $t('general.records') }}
                                </span>
                            </div>
                        </div>
                        <div class="flex space-x-2">
                            <div class="cursor-pointer" :class="[caseListType === 'grid' ? 'text-black' : '']"
                                @click="caseListType = 'grid'">
                                <GridIcon />
                            </div>
                            <div class="cursor-pointer" :class="[caseListType !== 'grid' ? 'text-black' : '']"
                                @click="caseListType = 'list'">
                                <ListIcon />
                            </div>
                        </div>
                    </div>

                    <div class="w-full py-5">
                        <div v-if="!isLoading && cases.length > 0 && caseListType === 'grid'"
                            class="grid grid-cols-12 gap-6">
                            <CaseCard
                            :cases="cases"
                            @click-case="toCaseDetail($event, true)"
                            />
                        </div>
                        <div v-else-if="!isLoading && cases.length > 0 && caseListType !== 'grid'">
                            <CaseList :cases="cases"
                            @click-case="toCaseDetail($event, true)"
                            />
                        </div>
                        <div v-if="!isLoading" class="flex flex-col items-end my-4">
                            <div class="flex">
                                <button
                                v-for="(link, index) in metaDataCases.links"
                                @click="changePage(link, index)"
                                :class="[
                                    'text-sm px-2 flex items-center justify-center border-t border-b border-l',
                                    {
                                        'w-8 h-8': index != 0 && index < (metaDataCases.links.length - 1),
                                        'border-r rounded-r-md': index === (metaDataCases.links.length - 1),
                                        'rounded-l-md': index === 0
                                    },
                                    (link.active) ? 'bg-sky-500 text-white' : 'bg-white text-slate-600'
                                ]"
                                >
                                    <span v-html="link.label"></span>
                                </button>
                            </div>
                        </div>
                        <NoData v-else />
                    </div>
                </div>
            </main>
        </div>

        <DualRingLoader v-if="isLoading" />

        <ModalBasic
        id="notes-modal"
        :modal-open="showImportCaseModal"
        :show-border-header="false"
        :title="$t('case.import.modal.title')"
        :subtitle="$t('case.import.modal.description')"
        @close-modal="close">
            <Form ref="form" v-slot="{ errors }" :validation-schema="importSchema" @submit="importCases">
                <div class="px-5">
                    <div class="flex flex-col gap-3">
                        <div>
                            <span class="font-bold">
                                {{ $t('case.import.modal.template.alert') }}
                            </span>
                            <a
                            :href="casesImportTemplate"
                            target="_blank"
                            class="bg-gray-100 flex items-center cursor-pointer gap-3 p-2 rounded-md hover:bg-gray-200 transition-all">
                                <FileTypeIcon file-type="xlsx" icon-class="inline w-8 cursor-pointer" />
                                <span>
                                    {{ $t('general.download') }}
                                </span>
                            </a>
                        </div>
                        <p class="text-sm text-slate-500">
                            {{ $t('case.import.modal.params.alert') }}
                        </p>
                        <div class="grid grid-cols-2 gap-3">
                            <div>
                                <span class="font-bold">
                                    {{ $t('case.import.modal.params.caseAreas') }}:
                                </span>
                                <div class="flex flex-col gap-1 text-sm bg-gray-100 p-2 rounded-lg max-h-[150px] overflow-y-auto">
                                    <span
                                    v-for="(area, index) in caseAreasToImport"
                                    :key="index">
                                        {{ area.name }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <span class="font-bold">
                                    {{ $t('case.import.modal.params.caseTypes') }}:
                                </span>
                                <div class="flex flex-col gap-1 text-sm bg-gray-100 p-2 rounded-lg max-h-[150px] overflow-y-auto">
                                    <span
                                    v-for="(type, index) in caseTypesToImport"
                                    :key="index"
                                    >
                                        {{ type.name }}
                                    </span>
                                </div>
                            </div>

                        </div>
                        <div>
                            <label class="mb-1 block text-xs font-bold" for="filesModal">
                                {{ $t('case.import.modal.file.label') }}
                            </label>
                            <div class="flex w-full items-center justify-center">
                                <Field v-model="importAttachment" name="attachment">
                                    <label
                                    for="filesModal"
                                        class="flex h-52 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-gray-300 bg-gray-50 hover:bg-gray-100">
                                        <div class="flex flex-col items-center justify-center py-2">
                                            <svg aria-hidden="true" class="mb-3 h-10 w-10 text-gray-400" fill="none"
                                                stroke="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12">
                                                </path>
                                            </svg>
                                            <p class="text-sm text-gray-500">
                                                {{ $t('forms.dragAndDrop') }}
                                            </p>
                                            <p class="text-sm text-gray-500">
                                                {{ $t('forms.or') }}
                                            </p>
                                            <p class="text-sm font-semibold text-sky-500">
                                                {{ $t('forms.browseFiles') }}
                                            </p>
                                        </div>
                                        <input id="filesModal" type="file" class="hidden" @change="importInputModal" />
                                        <span v-if="importAttachment">{{
                                            importAttachment.name
                                        }}</span>
                                    </label>
                                </Field>
                            </div>
                            <div v-if="errors.importAttachment !== undefined" class="flex w-full">
                                <span class="text-sm text-red-500">
                                    {{ $t(errors.importAttachment) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                v-if="errorReportImportCases && errorReportImportCases.length"
                class="m-4 p-3 bg-red-200 rounded-md text-red-700">
                    <div class="text-sm mb-3">
                        {{ $t('case.import.modal.result.failed.title') }}
                    </div>
                    <ul
                    class="list-disc px-4 text-sm">
                        <li
                        v-for="(error, index) in errorReportImportCases[1]"
                        :key="index"
                        >
                            {{ error }}
                        </li>
                    </ul>
                    <p
                    class="text-sm mt-3">
                        {{ $t('case.import.modal.result.failed.information1') }} <span class="font-bold">{{ errorReportImportCases[0] }}</span>.
                        {{ $t('case.import.modal.result.failed.information2') }}
                    </p>
                </div>
                <div class="py-4">
                    <div class="flex flex-wrap justify-center space-x-2 px-4">
                        <button
                        type="submit"
                        class="btn-sm w-full whitespace-nowrap rounded-lg bg-sky-500 p-3 text-white hover:bg-sky-600"
                        :class="[
                            isImportLoading ? 'cursor-not-allowed' : 'cursor-pointer',
                        ]"
                        >
                            <span v-if="isImportLoading">
                                {{ $t('general.loading') }}
                            </span>
                            <span v-else>
                                {{ $t('button.submit') }}
                            </span>
                        </button>
                    </div>
                </div>
            </Form>
        </ModalBasic>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'

import Sidebar from '@/partials/Sidebar.vue'
import SearchForm from '@/components/SearchForm.vue'
import CaseCard from '@/components/CaseCard.vue'
import CaseList from '@/components/CaseList.vue'
import NoData from '@/components/NoData.vue'

import GridIcon from '@/assets/svg/view-grid.svg'
import ListIcon from '@/assets/svg/view-list.svg'

import DualRingLoader from '@/components/DualRingLoader.vue'
import DropdownNotifications from '@/components/DropdownNotifications.vue'

import ButtonAdd from '@/components/ButtonAdd.vue'
import XIcon from '@/assets/svg/x.svg'
import DropdownProfile from '@/components/DropdownProfile.vue'

import ModalBasic from '@/components/ModalBasic.vue'
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'
import FileTypeIcon from '@/components/FileTypeIcon.vue'
import DropdownLanguages from '@/components/DropdownLanguages.vue'

export default {
    name: 'AdminCasesPage',
    components: {
        Sidebar,
        SearchForm,
        CaseCard,
        CaseList,
        NoData,
        DualRingLoader,
        GridIcon,
        ListIcon,
        DropdownNotifications,
        ButtonAdd,
        XIcon,
        DropdownProfile,
        ModalBasic,
        Form,
        Field,
        FileTypeIcon,
        DropdownLanguages,
    },
    mixins: [globalMixin, caseMixin],
    props: {
        status: {
            type: [String, Number],
            required: true,
        },
    },
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()
        const sidebarOpen = ref(false)
        const showNewCaseModal = ref(false)
        const showImportCaseModal = ref(false)

        return {
            t,
            n,
            store,
            toast,
            sidebarOpen,
            showNewCaseModal,
            showImportCaseModal,
        }
    },
    data: () => ({
        cases: [],
        allCases: [],
        filterDataCases: {
            page: 1,
            take: 10,
            search: '',
            order: 'DESC',
            area: null,
        },
        metaDataCases: {
            page: 1,
            take: 10,
            itemCount: 10,
            pageCount: 1,
            hasPreviousPage: false,
            hasNextPage: true,
        },
        importAttachment: null,
        importSchema: Yup.object().shape({
            // importAttachment: Yup.mixed().required('validation.attachmentRequired'),
        }),
        casesImportTemplate: null,
        errorReportImportCases: null,
        isImportLoading: false,
        caseAreasToImport: [],
        caseTypesToImport: [],
    }),
    mounted() {
        this.getAllCases()
        this.store.dispatch('cases/getCasesImportTemplate').then(response => {
            if (response.data) {
                this.casesImportTemplate = response.data;
            }
            this.getAreas()
        })
    },
    methods: {
        getAreas () {
            this.store.dispatch('cases/getAllAreas', {
                page: 1,
                take: 200,
                order: 'DESC',
            }).then((response) => {
                if (response.data) this.caseAreasToImport = response.data.data
                this.getTypes()
            })
            .catch((error) => {
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        getTypes () {
            this.store.dispatch('cases/getAllTypes', {
                page: 1,
                take: 50,
                order: 'DESC',
            }).then((response) => {
                if (response.data) this.caseTypesToImport = response.data.data
            }).catch((error) => {
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        async getAllCases() {
            this.isLoading = true
            await this.store.dispatch('cases/getAllCasesAdmin', {
                order: this.filterDataCases.order,
                page: this.filterDataCases.page,
                take: this.filterDataCases.take,
                q: this.filterDataCases.search,
                area: this.filterDataCases.area,
                status: this.caseStatusFilter(this.status),
            }).then((response) => {
                this.isLoading = false
                const responseData = response.data;
                if (responseData.data) {
                    let mappedDataCase = []
                    responseData.data.forEach((item) => {
                        item.status = this.stripAndLower(item.status)
                        mappedDataCase.push(item)
                    })
                    this.cases = mappedDataCase
                    this.allCases = mappedDataCase
                }
                if (responseData.from) {
                    this.metaDataCases = {
                        current_page: responseData.current_page,
                        from: responseData.from,
                        last_page: responseData.last_page,
                        links: responseData.links,
                        per_page: responseData.per_page,
                        to: responseData.to,
                        total: responseData.total,
                    }
                }
            })
                .catch((error) => {
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        changeOrder(event) {
            this.filterDataCases.order = ['DESC', 'ASC'].includes(event.target.value)
                ? event.target.value
                : 'DESC'
            this.getAllCases()
        },
        changeArea (event) {
            this.filterDataCases.area = event.target.value !== 'none' ? event.target.value : null
            this.getAllCases()
        },
        searchCase(event) {
            this.filterDataCases.search = event
            this.getAllCases()
        },
        toNewCase(withAssistance = false) {
            this.store.dispatch('cases/resetNewCase');
            this.$router.push({
                name: 'new-case',
                query: {
                    assist: withAssistance,
                },
            })
        },
        toPreCreatedCase () {
            this.store.dispatch('cases/resetNewCase');
            this.$router.push({
                name: 'new-case-precreated',
            });
        },
        importInputModal(event) {
            this.importAttachment = event.target.files[0]
        },
        close() {
            this.$refs.form.resetForm()
            this.showImportCaseModal = false
        },
        importCases () {
            if (this.isImportLoading) return;
            if (!this.importAttachment) {
                this.toast.error(this.$t('case.import.modal.pleaseSelectFile'))
                return;
            }
            this.errorReportImportCases = null;
            this.isImportLoading = true;
            this.store.dispatch('cases/importCases', { attachment: this.importAttachment })
            .then(response => {
                this.getAllCases()
                this.isImportLoading = false;
                this.showImportCaseModal = false;
                this.importAttachment = null;
                this.toast.success(this.$t('case.import.success'));
            }).catch(error => {
                this.isImportLoading = false;
                if (
                    error.response
                    &&
                    error.response.data
                    &&
                    error.response.data.errors
                    &&
                    error.response.data.errors.error_report
                ) {
                    this.errorReportImportCases = error.response.data.errors.error_report;
                }
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        changePage (link, index) {
            let newPage = null
            if (this.metaDataCases.links.length > 3) {
                if (index === 0 && this.metaDataCases.current_page != 1) {
                    /**
                     * Previous
                     */
                    newPage = this.metaDataCases.current_page - 1;
                } else if (
                    index === (this.metaDataCases.links.length - 1)
                    &&
                    this.metaDataCases.current_page != this.metaDataCases.last_page
                ) {
                    /**
                     * Next
                     */
                    newPage = this.metaDataCases.current_page + 1;
                } else if (link.label === '...') {
                    newPage = Number(this.metaDataCases.links[index - 1].label) + 1
                } else if (link.label && !isNaN(link.label)) {
                    newPage = Number(link.label)
                }
            }
            if (newPage) {
                this.filterDataCases.page = newPage
                this.getAllCases()
            }
        },
        changeNRecords (event) {
            this.filterDataCases.page = 1
            this.getAllCases()
        }
    },
}
</script>
