<template>
    <div class="p-2 flex flex-col gap-3">
        <div class="md:grid grid-cols-3 items-center gap-3">
            <div class="flex items-center gap-3 col-span-2">
                <i class="fa-solid fa-sterling-sign text-lg"></i>
                <span class="font-bold text-lg">
                    {{ $t('parameters.masterData.currencies.title') }}
                </span>
                <button
                v-permission="'parameters_master_data_create_currencies'"
                @click="openNewCurrency"
                class="btn bg-sky-500 text-white"
                >
                    <i class="fa-solid fa-plus"></i>
                </button>
            </div>
            <input
            v-model="search"
            type="text"
            :placeholder="$t('general.search') + '...'"
            class="form-input w-full rounded-md"
            >
        </div>
        <div>
            <div class="max-h-[300px] overflow-y-auto beauty-scroll">
                <DataTable
                :headers="dataTable.headers"
                :list="getSearchableList"
                @edit="openEditCurrency"
                @delete="openDeleteCurrency"
                >
                    <template v-slot:cell-status="cell3Props">
                        <div :class="[
                            'w-full flex flex-col items-center',
                            (cell3Props.item.status) ? 'text-green-500' : 'text-red-500'
                        ]">
                            <div class="flex items-center gap-1">
                                <i
                                class="fa-solid fa-power-off"></i>
                                {{ $t((cell3Props.item.status) ? 'status.active' : 'status.inactive') }}
                            </div>
                        </div>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>

    <ModalDelete
    :backdrop="true"
    :id="currentCurrency.id"
    :is-open="deleteCurrencyModalOpen"
    :title="$t('parameters.masterData.currencies.delete.confirmation.title')"
    :description="''"
    @closed="deleteCurrencyModalOpen = false"
    @delete="deleteCurrency"
    />

    <ModalNewCurrency
    :is-open="newCurrencyModalOpen"
    :is-edit="isCurrencyEdit"
    :current-currency="currentCurrency"
    :saving="saving"
    @closed="modalCurrencyClose"
    @submit-data="submitData"
    />
</template>
<script>
import DataTable from '@/components/DataTable.vue'
import ModalNewCurrency from '@/partials/parameters/master-data/currencies/ModalNewCurrency.vue'
import ModalDelete from '@/components/ModalDelete.vue'

export default {
    components: {
        DataTable,
        ModalNewCurrency,
        ModalDelete
    },
    emits: ['reload'],
    props: {
        list: {
            type: Array,
            default: [],
        },
        toast: {
            type: Function,
            default: () => {}
        }
    },
    data: function () {
        return {
            newCurrencyModalOpen: false,
            deleteCurrencyModalOpen: false,
            isCurrencyEdit: false,
            currentCurrency: {},
            saving: false,
            search: null,
            dataTable: {
                headers: [
                    {
                        id: 'code',
                        label: this.$t('parameters.masterData.common.table.headers.code'),
                        column: {
                            key: 'code',
                        }
                    },
                    {
                        id: 'name',
                        label: this.$t('parameters.masterData.common.table.headers.name'),
                        column: {
                            key: 'name',
                        }
                    },
                    {
                        id: 'created_at',
                        label: this.$t('parameters.masterData.common.table.headers.createdAt'),
                        column: {
                            funct: (value) => this.$filters.newDayjs(value).format('DD MMM YYYY - HH:mm'),
                            key: 'created_at',
                        }
                    },
                    {
                        id: 'status',
                        label: this.$t('parameters.masterData.common.table.headers.status'),
                        column: {
                            funct: (value) => value ? this.$t('status.active') : this.$t('status.inactive'),
                            key: 'status',
                        },
                        isSlot: true,
                    },
                    {
                        id: 'actions',
                        label: '',
                        column: {
                            key: '',
                            action: {
                                edit: {
                                    permission: 'parameters_master_data_edit_currencies'
                                },
                                delete: {
                                    permission: 'parameters_master_data_delete_currencies'
                                }
                            }
                        }
                    }
                ]
            }
        }
    },
    methods: {
        modalCurrencyClose () {
            this.newCurrencyModalOpen = false
        },
        openNewCurrency () {
            this.newCurrencyModalOpen = true
            this.isCurrencyEdit = false
        },
        openEditCurrency (event) {
            this.newCurrencyModalOpen = true
            this.currentCurrency = event
            this.isCurrencyEdit = true
        },
        openDeleteCurrency (event) {
            this.deleteCurrencyModalOpen = true
            this.currentCurrency = event
        },
        submitData (event) {
            if (event.id) {
                this.update(event)
            } else {
                this.create(event)
            }
        },
        create (event) {
            if (!this.saving) {
                this.saving = true
                this.$store.dispatch('currencies/create', event).then(response => {
                    if (response.data) {
                        this.$emit('reload');
                        this.newCurrencyModalOpen = false
                        this.currentCurrency = {}
                        this.toast.success(this.$t('parameters.masterData.currencies.successCreated'))
                    }
                    this.saving = false
                }).catch(error => {
                    this.toast.error(this.$methods.extractErrorResponse(error, this.$t))
                    this.saving = false
                })
            }
        },
        update (event) {
            const formData = JSON.parse(JSON.stringify(event))
            formData.id = this.$methods.encrypt(event.id);
            if (!this.saving) {
                this.saving = true
                this.$store.dispatch('currencies/update', formData).then(response => {
                    if (response.data) {
                        this.$emit('reload');
                        this.newCurrencyModalOpen = false
                        this.currentCurrency = {}
                        this.toast.success(this.$t('parameters.masterData.currencies.successUpdated'))
                    }
                    this.saving = false
                }).catch(error => {
                    this.toast.error(this.$methods.extractErrorResponse(error, this.$t))
                    this.saving = false
                })
            }
        },
        deleteCurrency (event) {
            this.$store.dispatch('currencies/delete', { id: this.$methods.encrypt(event.id) }).then(response => {
                if (response.data) {
                    this.$emit('reload');
                    this.currentCurrency = {}
                    this.deleteCurrencyModalOpen = false
                    this.toast.success(this.$t('parameters.masterData.currencies.successDeleted'))
                }
                this.saving = false
            }).catch(error => {
                this.toast.error(this.$methods.extractErrorResponse(error, this.$t))
                this.saving = false
            })
        }
    },
    computed: {
        getSearchableList () {
            return this.list.filter(
                item => Object.values(item).some(elem => {
                    const that = elem ? elem : ''
                    const search = this.search ? this.search : ''
                    return that.toString().toLowerCase().includes(search.toLowerCase())
                })
            )
        }
    }
}

</script>