<template>
    <div class="flex h-screen overflow-hidden">
        <!-- Sidebar -->
        <Sidebar
        :sidebar-open="sidebarOpen"
        :case-stage="$route.query.status"
        @close-sidebar="sidebarOpen = false"
        :get-the-case="true"
        />

        <!-- Content area -->
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <main>
                <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
                    <div class="flex w-full flex-col pb-4">
                        <h1 class="text-2xl font-bold text-black">
                            {{ $t('timeline.title') }}
                        </h1>
                        <p>{{ $t('timeline.description') }}</p>
                    </div>

                    <div class="mb-6 flex w-full items-center justify-between">
                        <div class="flex flex-col space-y-4">
                            <SearchForm :placeholder="$t('timeline.searchPlaceholder')" @search="searchTimeline" />
                            <div class="flex cursor-pointer items-start text-sm text-black">
                                <div class="flex">
                                    <p>{{ $t('formLabel.sortBy') }}</p>
                                </div>
                                <div class="flex">
                                    <select class="border-none bg-inherit py-0 font-bold focus:ring-0"
                                        @change="changeOrder">
                                        <option v-for="order in orderOptions" :key="order.value" :value="order.value"
                                            :selected="filterDataTimeline.order === order.value">
                                            {{ $t(`${order.label}`) }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div
                        v-if="!$store.getters['cases/currentCaseWasClosed'] && isAValidPartInCase($store.getters['auth/getCurrentUser'])"
                        class="grid grid-flow-col justify-start gap-2 sm:auto-cols-max sm:justify-end">
                            <div class="my-2 flex w-full cursor-pointer items-center justify-center rounded-lg bg-sky-500 px-8 py-3 text-sm text-white"
                                @click.stop="newActivityModalOpen = true">
                                <div class="flex w-full">
                                    <div class="flex">{{ $t('admin.timeline.buttonAdd') }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Timeline -->
                    <div class="relative mb-8">
                        <ul
                            class="no-scrollbar relative -mx-4 flex flex-nowrap overflow-x-scroll text-sm font-medium sm:-mx-6 lg:-mx-8">
                            <li
                            v-for="activity in typeActivity"
                            :key="activity.type"
                            v-permission="activity.permission"
                            class="mr-8 first:pl-4 last:mr-0 last:pr-4 sm:first:pl-6 sm:last:pr-6 lg:first:pl-8 lg:last:pr-8"
                            >
                                <a class="block cursor-pointer whitespace-nowrap pb-3" :class="activePage === activity.type
                                    ? 'border-b-3 border-slate-900 font-bold text-slate-800'
                                    : 'text-slate-500 hover:text-slate-600'
                                "
                                @click="changeActivePage(activity.type)">
                                    {{ $t(activity.name) }}
                                </a>
                            </li>
                            <select
                            id="types"
                            class="form-select w-36 p-2"
                            v-model="filterTimelineStage"
                            @change="changeActivePage('stage')"
                            >
                                <option
                                v-for="(stage, index) in stages"
                                :key="index"
                                :value="stage.key"
                                >
                                    {{ stage.label }}
                                </option>
                            </select>
                        </ul>
                    </div>
                    <template v-if="activeTimelinesArr.length > 0 && !isLoading">
                        <ActivityItemDate :activity-date="activeTimelines" wrapper-class="bg-white" />
                    </template>
                    <div v-else-if="!isLoading">
                        <!-- <NoData /> -->
                        <div class="flex w-full flex-col items-center text-center text-sm">
                            <TimeFilledIcon class="h-20 w-20" />
                            <span class="text-violet">
                                {{ $t('noActivities') }}
                            </span>
                        </div>
                    </div>
                    <DualRingLoader v-else />
                </div>
            </main>
        </div>

        <ModalCreateActivity
        :is-open="newActivityModalOpen"
        :active-activity="activeActivity"
        :is-create-loading="isModalLoading"
        @closed="newActivityModalOpen = false"
        @submit-data="saveTimeline"
        />

        <RightSidebar :sidebar-open="sidebarOpen" @close-sidebar="sidebarOpen = false" />
    </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import timelineMixin from '@/mixins/timeline.js'

import Sidebar from '@/partials/Sidebar.vue'
import RightSidebar from '@/partials/RightSidebar.vue'
import ActivityItemDate from '@/components/ActivityItemDate.vue'
import SearchForm from '@/components/SearchForm.vue'
import ModalCreateActivity from '@/components/ModalCreateActivity.vue'
// import NoData from '@/components/NoData.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'

import TimeFilledIcon from '@/assets/svg/filled/Time-filled.svg'

export default {
    name: 'TimelinePage',
    components: {
        Sidebar,
        RightSidebar,
        ActivityItemDate,
        SearchForm,
        ModalCreateActivity,
        // NoData,
        DualRingLoader,
        TimeFilledIcon,
    },
    mixins: [globalMixin, timelineMixin],
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()
        const sidebarOpen = ref(false)
        const newActivityModalOpen = ref(false)
        const deleteActivityModalOpen = ref(false)
        const activeActivity = ref({})
        const stages = [
            { key: '1', label: t('case.stages.on_prepare') },
            { key: '2', label: t('case.stages.negotiation') },
            { key: '3', label: t('case.stages.mediation') },
            { key: '4', label: t('case.stages.arbitration') },
            { key: '5', label: t('case.stages.solved') },
        ]

        return {
            t,
            n,
            store,
            toast,
            stages,
            sidebarOpen,
            activeActivity,
            newActivityModalOpen,
            deleteActivityModalOpen,
        }
    },
    data: function () {
        return {
            activePage: 'all',
            typeActivity: [
                { type: 'all', name: 'activity.all', permission: 'cases_manage_timeline_list_all' },
                { type: 'external', name: 'activity.external', permission: 'cases_manage_timeline_list_external' },
                { type: 'stage', name: 'activity.stage', permission: 'cases_manage_timeline_list_stage' },
            ],
            filterDataTimeline: {
                page: 1,
                take: 50,
                search: '',
                order: 'DESC',
            },
            metaDataTimeline: {
                page: 1,
                take: 10,
                itemCount: 10,
                pageCount: 1,
                hasPreviousPage: false,
                hasNextPage: true,
            },
            timelines: [],
            activeTimelinesArr: [],
            activeTimelines: {},
            filterTimelineStage: 1,
        }
    },
    mounted() {
        this.getAllTimeline()
    },
    methods: {
        changeActivePage(type) {
            this.activePage = type
            const dataTimelines = this.timelines.filter(item => {
                if (
                    type === 'all'
                    ||
                    (
                        (
                            type === 'stage'
                            &&
                            item.stage == this.filterTimelineStage
                        )
                        ||
                        (
                            type != 'stage'
                            &&
                            type === item.type
                        )
                    )
                ) {
                    return item
                }
            });

            this.activeTimelinesArr = dataTimelines
            const mappingDateTimeline = {}
            dataTimelines.forEach((item) => {
                if (item.updated_at !== '') {
                    const date = item.updated_at.split('T')[0]
                    if (mappingDateTimeline.hasOwnProperty(date)) {
                        mappingDateTimeline[date].push(item)
                    } else {
                        mappingDateTimeline[date] = [item]
                    }
                }
            })
            this.activeTimelines = mappingDateTimeline
        },
        async getAllTimeline() {
            this.isLoading = true
            await this.store
                .dispatch('timeline/getAllTimeline', {
                    order: this.filterDataTimeline.order,
                    page: this.filterDataTimeline.page,
                    take: this.filterDataTimeline.take,
                    q: this.filterDataTimeline.search,
                    case_id: this.$route.params.slug,
                })
                .then((response) => {
                    this.isLoading = false
                    const responseData = response.data
                    if (responseData.data) {
                        this.timelines = responseData.data
                        this.changeActivePage(this.$methods.hasPermissions(['cases_manage_timeline_list_all']) ? 'all' : 'stage')
                    }
                    if (responseData.from) {
                        this.metaDataTimeline = {
                            current_page: responseData.current_page,
                            from: responseData.from,
                            last_page: responseData.last_page,
                            links: responseData.links,
                            per_page: responseData.per_page,
                            to: responseData.to,
                            total: responseData.total,
                        }
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        searchTimeline(event) {
            this.filterDataTimeline.search = event
            this.getAllTimeline()
        },
        changeOrder(event) {
            this.filterDataTimeline.order = ['DESC', 'ASC'].includes(
                event.target.value
            )
                ? event.target.value
                : 'DESC'
            this.getAllTimeline()
        },
    },
}
</script>
