<template>
    <div class="flex h-screen overflow-hidden">
        <div
            class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
        >
            <!-- Site header -->
            <Header
                :sidebar-open="sidebarOpen"
                @toggle-sidebar="sidebarOpen = !sidebarOpen"
            />

            <main>
                <div class="w-full">
                    <div
                        class="flex h-full flex-col justify-center bg-gray-100 md:mt-10 "
                    >
                        <div
                            v-if="!$route.params.slug"
                            class="w-full self-center px-2 py-5 text-left md:w-5/6 md:py-2 md:px-0"
                        >
                            <BreadcrumNewCase :active="2" />
                        </div>
                        <div
                        v-if="$route.params.slug"
                        class="w-full self-center py-2 text-left md:w-2/3"
                        >
                            <div
                            id="advocate_selection_back_1_action"
                            class="flex w-full cursor-pointer flex-row items-center"
                            @click="back"
                            >
                                <svg class="h-7 w-4 fill-current text-slate-500" viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
                                </svg>
                                <span class="ml-2 text-xs text-slate-500 hover:text-sky-400">{{
                                    $t('action.back')
                                }}</span>
                            </div>
                        </div>
                        <div
                        :class="($route.params.slug) ? 'md:w-2/3' : 'md:w-5/6'"
                            class="w-full self-center rounded-lg bg-white px-8 py-8 shadow-md"
                        >
                            <h1 class="mb-2 text-3xl font-bold text-slate-800">
                                {{ $t('advocateSelection.title') }}
                            </h1>
                            <p class="text-sm">
                                {{ (isReassignment) ? $t('advocateSelection.reassignment.description') : $t('advocateSelection.description') }}
                            </p>

                            <div class="mt-10">
                                <AdvocateTable
                                    :advocates="advocates"
                                    @change-selection="changeSelection"
                                />
                            </div>

                            <div
                            v-if="isReassignment"
                            class="my-8 grid grid-cols-1 md:grid-cols-2 gap-3">
                                <div class="flex flex-col gap-2">
                                    <label class="font-bold">
                                        {{ $t('case.partyReassignment.label') }}
                                    </label>
                                    <textarea
                                    id="advocate_selection_reassign_reason"
                                    v-model="reassignmentReason"
                                    :placeholder="$t('case.partyReassignment.placeholder')"
                                    class="form-input w-full h-full p-2"
                                    ></textarea>
                                </div>
                                <div class="flex flex-col gap-2">
                                    <label class="font-bold">
                                        {{ $t('case.partyReassignment.attachment.label') }}
                                    </label>
                                    <label for="advocate_selection_reassign_file"
                                        class="flex h-48 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-gray-300 bg-gray-50 hover:bg-gray-100">
                                        <div class="flex flex-col items-center justify-center py-2">
                                            <svg aria-hidden="true" class="mb-3 h-10 w-10 text-gray-400" fill="none"
                                                stroke="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12">
                                                </path>
                                            </svg>
                                            <p class="text-sm text-gray-500">
                                                {{ $t('forms.dragAndDrop') }}
                                            </p>
                                            <p class="text-sm text-gray-500">
                                                {{ $t('forms.or') }}
                                            </p>
                                            <p class="text-sm font-semibold text-sky-500">
                                                {{ $t('forms.browseFiles') }}
                                            </p>
                                        </div>
                                        <input
                                        id="advocate_selection_reassign_file"
                                        type="file"
                                        class="hidden" @change="fileReassignmentInput" />
                                        <span v-if="attachment">
                                            {{ attachment.name }}
                                        </span>
                                    </label>
                                </div>
                            </div>

                            <div class="mt-5 flex flex-row justify-end">
                                <div class="w-3/12">
                                    <span
                                        class="btn text-md mt-6 h-12 w-full cursor-pointer rounded-md text-slate-500 shadow-none hover:text-slate-700"
                                        @click="back"
                                        id="advocate_selection_back_2_action"
                                    >
                                        <svg
                                            class="mr-2 h-7 w-7 fill-current text-slate-500"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z"
                                            />
                                        </svg>
                                        {{ $t('action.back') }}
                                    </span>
                                </div>
                                <button
                                id="advocate_selection_next_action"
                                class="btn text-md mt-6 h-12 w-2/12 rounded-md bg-sky-500 text-white hover:bg-sky-600"
                                @click="selectAdvocate"
                                >
                                    {{ $t('action.next') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'

import Header from '@/partials/Header.vue'
import AdvocateTable from '@/components/AdvocateTable.vue'
import BreadcrumNewCase from '@/components/case/BreadcrumNewCase.vue'

export default {
    name: 'AdvocateSelectionPage',
    components: {
        Header,
        AdvocateTable,
        BreadcrumNewCase,
    },
    mixins: [globalMixin, caseMixin],
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()
        const sidebarOpen = ref(false)

        return {
            t,
            n,
            store,
            toast,
            sidebarOpen,
        }
    },
    data: () => ({
        headerData: [
            {
                name: '',
                colspan: 1,
            },
            {
                name: 'Name',
                colspan: 1,
            },
            {
                name: 'Ratings',
                colspan: 1,
            },
            {
                name: 'Cases handled',
                colspan: 1,
            },
            {
                name: 'Ongoing cases',
                colspan: 1,
            },
        ],
        advocates: [],
        filterDataAdvocate: {
            page: 1,
            take: 50,
            search: '',
            order: 'DESC',
        },
        metaDataAdvocate: {
            page: 1,
            take: 10,
            itemCount: 10,
            pageCount: 1,
            hasPreviousPage: false,
            hasNextPage: true,
        },
        advocateId: '',
        advocate: {},
        reassignmentReason: null,
        attachment: null,
    }),
    async mounted() {
        this.activeCase = this.store.getters['cases/activeCase']
        this.newCaseData = this.store.getters['cases/newCase']
        if (this.checkEmptyObject(this.newCaseData) && !this.$route.params.slug) {
            return this.$router.push({
                name: 'new-case',
                query: { lang: this.$route.query.lang },
            })
        }
        this.getAllAdvocate()
    },
    methods: {
        selectAdvocate() {
            if (this.advocateId === '') {
                this.toast.error(this.t('newCase.pleaseSelectAdvocate'))
                return
            }

            if (!this.$route.params.slug) {
                this.newCaseData.advocate = this.advocate
                this.newCaseData.advocate_user_id = this.advocate.id
                this.store.dispatch('cases/setNewCase', this.newCaseData)
                this.store.dispatch('cases/setActiveNewCaseBreadcrum', 3)
                this.$router.push({
                    name: 'review-application',
                    query: {
                        lang: this.$route.query.lang,
                    },
                });
            } else {
                if (!this.reassignmentReason) {
                    this.toast.error(this.$t('case.partyReassignment.error'))
                    return;
                }
                this.caseAssignAPI({
                    id: this.$route.params.slug,
                    user_id: this.advocate.id,
                    bank_lawyer_id: this.advocateId,
                    type: 'advocate',
                    reassignment: true,
                    reassignment_reason: this.reassignmentReason,
                    attachment: this.attachment,
                })
            }
        },
        async getAllAdvocate() {
            await this.store
                .dispatch('advocate/getAllAdvocate', {
                    order: this.filterDataAdvocate.order,
                    page: this.filterDataAdvocate.page,
                    take: this.filterDataAdvocate.take,
                    q: this.filterDataAdvocate.search,
                    reassignment: this.isReassignment,
                    case_id: this.$route.params.slug ?? null,
                })
                .then((response) => {
                    const responseData = response.data
                    if (responseData.data) {
                        this.advocates = responseData.data
                    }
                    if (responseData.meta) {
                        this.metaDataAdvocate = {
                            current_page: responseData.current_page,
                            from: responseData.from,
                            last_page: responseData.last_page,
                            links: responseData.links,
                            per_page: responseData.per_page,
                            to: responseData.to,
                            total: responseData.total,
                        }
                    }
                })
                .catch((error) => {
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        changeSelection(item) {
            if (typeof item[0] !== 'undefined') {
                this.advocate = item[0]
                this.advocateId = item[0].id
            }
        },
        back() {
            if (this.$route.params.slug) {
                this.$router.push({
                    name: 'case-detail-admin',
                    params: this.$route.params.slug,
                })
            } else {
                this.$router.push({
                    name: 'advocate-requirement',
                    query: { lang: this.$route.query.lang },
                })
            }
        },
        fileReassignmentInput (event) {
            this.attachment = event.target.files[0]
        }
    },
    computed: {
        isReassignment () {
            return this.$route.query.reassignment ? true : false
        }
    }
}
</script>
