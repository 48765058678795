<template>
    <div class="flex h-screen overflow-hidden">
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <!-- Site header -->
            <Header :sidebar-open="sidebarOpen" @toggle-sidebar="sidebarOpen = !sidebarOpen" />

            <main>
                <div class="w-full">
                    <div class="mt-10 flex h-full flex-col justify-center bg-gray-100">

                        <BreadcrumNewCase
                        :active="1"
                        :is-show="true"
                        :is-assistance="needAssistance && !isPrecreatedCase"
                        :show-review-application="!isPrecreatedCase"
                        :is-precreated-case="isPrecreatedCase"
                        />

                        <div class="w-full self-center rounded-lg bg-white px-8 py-8 shadow-md md:w-4/5">
                            <h1 class="mb-2 text-3xl font-bold text-slate-800">
                                {{ $t('newCase.title') }}
                            </h1>
                            <p class="text-sm">
                                {{ (isPrecreatedCase) ? $t('newCase.preCreated.description') : $t('newCase.description') }}
                            </p>
                            <Form v-slot="{ errors }" :validation-schema="newCaseSchema" @submit="createComplain">
                                <div class="mt-4 space-y-6">
                                    <div>
                                        <label class="mb-1 block text-sm font-medium" for="caseName">
                                            {{ (isPrecreatedCase) ? $t('newCase.preCreated.caseName') : $t('newCase.caseName') }}
                                        </label>
                                        <Field v-slot="{ field }" v-model="caseName" name="caseName">
                                            <input id="form_create_cases_name" v-bind="field" class="form-input h-12 w-full rounded-md"
                                                :class="[
                                                    errors.caseName !== undefined
                                                        ? 'focus:border-red-500'
                                                        : '',
                                                ]" type="text" :placeholder="$t('newCase.caseNamePlaceholder')" />
                                        </Field>
                                        <span class="text-sm text-red-500">
                                            <template v-if="errors.caseName !== undefined">
                                                {{ $t(errors.caseName) }}
                                            </template>
                                        </span>
                                    </div>

                                    <div v-if="isPrecreatedCase">
                                        <label class="mb-1 block text-sm font-medium" for="caseName">
                                            {{ $t('newCase.preCreated.claimant.label') }}
                                        </label>

                                        <VueMultiselect
                                        v-model="preCClaimant"
                                        :options="caseClaimants"
                                        :multiple="false"
                                        :close-on-select="true"
                                        :placeholder="$t('newCase.preCreated.claimant.placeholder')"
                                        label="identification"
                                        :track-by="'identification'"
                                        :allow-empty="true"
                                        >
                                            <template #singleLabel="props">
                                                <span class="option__desc">
                                                    <span class="option__title">
                                                        {{ props.option.name }}
                                                    </span>
                                                </span>
                                            </template>
                                            <template #option="props">
                                                <div class="option__desc space-x-2">
                                                    <div class="option__small inline rounded-lg px-2 py-1 text-xs font-bold">
                                                        {{ props.option.identification }}
                                                    </div>
                                                    -
                                                    <span class="option__title">
                                                        {{ props.option.name }}
                                                    </span>
                                                </div>
                                            </template>
                                        </VueMultiselect>

                                        <span class="text-sm text-red-500">
                                            <template v-if="errors.preCClaimant !== undefined">
                                                {{ $t(errors.preCClaimant) }}
                                            </template>
                                        </span>
                                    </div>

                                    <div
                                    v-if="isPrecreatedCase"
                                    class="grid grid-cols-1 gap-2 md:grid-cols-6 items-end"
                                    >
                                        <div
                                        :class="getPreCStageClass"
                                        >
                                            <label class="mb-1 block text-sm font-medium" for="caseName">
                                                {{ $t('newCase.preCreated.stage.label') }}
                                            </label>
                                            <select
                                            id="form_create_cases_precreated_stage"
                                            v-model="preCStage"
                                            class="form-select w-full p-2"
                                            >
                                                <option
                                                v-for="caseStage in caseStages"
                                                :key="caseStage.id"
                                                :value="caseStage.id">
                                                    {{ caseStage.label }}
                                                </option>
                                            </select>
                                            <span class="text-sm text-red-500">
                                                <template v-if="errors.preCClaimant !== undefined">
                                                    {{ $t(errors.preCClaimant) }}
                                                </template>
                                            </span>
                                        </div>

                                        <div v-if="showOptionStagePrec('stageCaseResolved')">
                                            <label class="mb-1 block text-sm font-medium" for="caseName">
                                                {{ $t('newCase.preCreated.stageCaseResolved.label') }}
                                            </label>
                                            <select
                                            id="form_create_cases_precreated_stage_case_solved"
                                            v-model="preCStageCaseSolved"
                                            class="form-select w-full p-2"
                                            >
                                                <option
                                                v-for="caseStage in caseStages.filter(item => [2, 3, 4].some(elem => elem === item.id))"
                                                :key="caseStage.id"
                                                :value="caseStage.id">
                                                    {{ caseStage.label }}
                                                </option>
                                            </select>
                                            <span class="text-sm text-red-500">
                                                <template v-if="errors.preCClaimant !== undefined">
                                                    {{ $t(errors.preCClaimant) }}
                                                </template>
                                            </span>
                                        </div>

                                        <div class="h-full">
                                            <div class="h-full">
                                                <button
                                                id="form_create_cases_precreated_choose_advocate_action"
                                                @click="selectPreCParty('advocate')"
                                                class="btn w-full h-full flex gap-3 bg-green-100">
                                                    <span>
                                                        {{ $t('newCase.preCreated.choose.advocate.button') }}
                                                    </span>
                                                    <div
                                                    v-if="preCAdvocate"
                                                    class="bg-green-500 rounded-full h-5 w-5 flex items-center justify-center"
                                                    >
                                                        <i class="fas fa-check text-xs text-white"></i>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>

                                        <div class="h-full">
                                            <div
                                            v-if="showOptionStagePrec('bankLawyer')"
                                            class="h-full"
                                            >
                                                <button
                                                id="form_create_cases_precreated_choose_bank_lawyer_action"
                                                @click="selectPreCParty('bank_lawyer')"
                                                class="btn h-full flex gap-3 bg-sky-100 text-gray-600 w-full">
                                                    <span>
                                                        {{ $t('newCase.preCreated.choose.bank_lawyer.button') }}
                                                    </span>
                                                    <div
                                                    v-if="preCBanklawyer"
                                                    class="bg-green-500 rounded-full h-5 w-5 flex items-center justify-center"
                                                    >
                                                        <i class="fas fa-check text-xs text-white"></i>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>

                                        <div class="h-full">
                                            <div
                                            v-if="showOptionStagePrec('mediator')"
                                            class="h-full"
                                            >
                                                <button
                                                @click="selectPreCParty('mediator')"
                                                id="form_create_cases_precreated_choose_mediator_action"
                                                class="btn flex gap-3 bg-yellow-100 text-gray-600 w-full h-full">
                                                    <span>
                                                        {{ $t('newCase.preCreated.choose.mediator.button') }}
                                                    </span>
                                                    <div
                                                    v-if="preCMediator"
                                                    class="bg-green-500 rounded-full h-5 w-5 flex items-center justify-center"
                                                    >
                                                        <i class="fas fa-check text-xs text-white"></i>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>

                                        <div class="h-full">
                                            <div
                                            v-if="showOptionStagePrec('arbitrator')"
                                            class="h-full"
                                            >
                                                <button
                                                @click="selectPreCParty('arbitrator')"
                                                id="form_create_cases_precreated_choose_arbitrator_action"
                                                class="btn h-full flex gap-3 bg-red-100 text-gray-600 w-full">
                                                    <span>
                                                        {{ $t('newCase.preCreated.choose.arbitrator.button') }}
                                                    </span>
                                                    <div
                                                    v-if="preCArbitrator"
                                                    class="bg-green-500 rounded-full h-5 w-5 flex items-center justify-center"
                                                    >
                                                        <i class="fas fa-check text-xs text-white"></i>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                    v-if="isPrecreatedCase && [preCAdvocate, preCBanklawyer, preCMediator, preCArbitrator].some(item => item != null)"
                                    class="flex flex-col gap-2"
                                    >
                                        <span>
                                            {{ $t('selectedParties') }}:
                                        </span>
                                        <div class="grid grid-cols-1 gap-3 md:grid-cols-2">
                                            <div
                                            class="bg-green-100 rounded-lg px-4 py-2 flex items-center justify-between gap-3 text-sm"
                                            v-if="preCAdvocate"
                                            >
                                                <span class="font-bold text-slate-500">
                                                    {{ $t('general.advocate') }}:
                                                </span>
                                                <div class="flex items-center gap-2">
                                                    <ImageLoader
                                                    wrapper-class="h-7 w-7 rounded-full"
                                                    :src="preCAdvocate.avatar"
                                                    alternate="profileImage"
                                                    />
                                                    <span>
                                                        {{ preCAdvocate.name }}
                                                    </span>
                                                    <button @click="removePart('advocate')">
                                                        <i class="fas fa-times text-red-500 text-lg"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div
                                            class="bg-sky-100 rounded-lg px-4 py-2 flex items-center justify-between gap-3 text-sm"
                                            v-if="preCBanklawyer"
                                            >
                                                <span class="font-bold text-slate-500">
                                                    {{ $t('general.bankLawyer') }}:
                                                </span>
                                                <div class="flex items-center gap-2">
                                                    <ImageLoader
                                                    wrapper-class="h-7 w-7 rounded-full"
                                                    :src="preCBanklawyer.avatar"
                                                    alternate="profileImage"
                                                    />
                                                    <span>
                                                        {{ preCBanklawyer.name }}
                                                    </span>
                                                    <button @click="removePart('bank_lawyer')">
                                                        <i class="fas fa-times text-red-500 text-lg"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div
                                            class="bg-yellow-100 rounded-lg px-4 py-2 flex items-center justify-between gap-3 text-sm"
                                            v-if="preCMediator"
                                            >
                                                <span class="font-bold text-slate-500">
                                                    {{ $t('general.mediator') }}:
                                                </span>
                                                <div class="flex items-center gap-2">
                                                    <ImageLoader
                                                    wrapper-class="h-7 w-7 rounded-full"
                                                    :src="preCMediator.avatar"
                                                    alternate="profileImage"
                                                    />
                                                    <span>
                                                        {{ preCMediator.name }}
                                                    </span>
                                                    <button @click="removePart('mediator')">
                                                        <i class="fas fa-times text-red-500 text-lg"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div
                                            class="bg-red-100 rounded-lg px-4 py-2 flex items-center justify-between gap-3 text-sm"
                                            v-if="preCArbitrator"
                                            >
                                                <span class="font-bold text-slate-500">
                                                    {{ $t('general.arbitrator') }}:
                                                </span>
                                                <div class="flex items-center gap-2">
                                                    <ImageLoader
                                                    wrapper-class="h-7 w-7 rounded-full"
                                                    :src="preCArbitrator.avatar"
                                                    alternate="profileImage"
                                                    />
                                                    <span>
                                                        {{ preCArbitrator.name }}
                                                    </span>
                                                    <button @click="removePart('arbitrator')">
                                                        <i class="fas fa-times text-red-500 text-lg"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                    v-if="(preCStage >= 2 && preCStage <= 4) || preCStageCaseSolved >= 2"
                                    class="grid grid-cols-1 md:grid-cols-2 gap-3"
                                    >
                                        <div>
                                            <label class="mb-1 block text-sm font-medium" for="caseWhen">
                                                {{ $t('newCase.preCreated.negotiationStartTime') }}
                                            </label>
                                            <Datepicker
                                            v-model="preCNegotiationStartTime"
                                            wrap-class="form-input h-12 w-full rounded-md pl-9"
                                            mode="single"
                                            :date-only="true"
                                            @change-date="(val) => changeDatePrecreated(val, 'preCNegotiationStartTime')"
                                            />
                                        </div>
                                        <div>
                                            <label class="mb-1 block text-sm font-medium" for="caseWhen">
                                                {{ $t('newCase.preCreated.negotiationEndTime') }}
                                            </label>
                                            <Datepicker
                                            v-model="preCNegotiationEndTime"
                                            wrap-class="form-input h-12 w-full rounded-md pl-9"
                                            mode="single"
                                            :max-today="false"
                                            :max-one-month="true"
                                            :min-twoday="false"
                                            :date-only="true"
                                            @change-date="(val) => changeDatePrecreated(val, 'preCNegotiationEndTime')"
                                            />
                                        </div>
                                    </div>

                                    <div
                                    v-if="(preCStage >= 3 && preCStage <= 4) || preCStageCaseSolved >= 3"
                                    class="grid grid-cols-1 md:grid-cols-2 gap-3"
                                    >
                                        <div>
                                            <label class="mb-1 block text-sm font-medium" for="caseWhen">
                                                {{ $t('newCase.preCreated.mediationStartTime') }}
                                            </label>
                                            <Datepicker
                                            v-model="preCMediationStartTime"
                                            wrap-class="form-input h-12 w-full rounded-md pl-9"
                                            mode="single"
                                            :date-only="true"
                                            @change-date="(val) => changeDatePrecreated(val, 'preCMediationStartTime')"
                                            />
                                        </div>
                                        <div>
                                            <label class="mb-1 block text-sm font-medium" for="caseWhen">
                                                {{ $t('newCase.preCreated.mediationEndTime') }}
                                            </label>
                                            <Datepicker
                                            v-model="preCMediationEndTime"
                                            wrap-class="form-input h-12 w-full rounded-md pl-9"
                                            mode="single"
                                            :max-today="false"
                                            :max-one-month="true"
                                            :min-twoday="false"
                                            :date-only="true"
                                            @change-date="(val) => changeDatePrecreated(val, 'preCMediationEndTime')"
                                            />
                                        </div>
                                    </div>

                                    <div
                                    v-if="preCStage == 4 || preCStageCaseSolved >= 4"
                                    class="grid grid-cols-1 md:grid-cols-2 gap-3"
                                    >
                                        <div>
                                            <label class="mb-1 block text-sm font-medium" for="caseWhen">
                                                {{ $t('newCase.preCreated.arbitrationStartTime') }}
                                            </label>
                                            <Datepicker
                                            v-model="preCArbitrationStartTime"
                                            wrap-class="form-input h-12 w-full rounded-md pl-9"
                                            mode="single"
                                            :date-only="true"
                                            @change-date="(val) => changeDatePrecreated(val, 'preCArbitrationStartTime')"
                                            />
                                        </div>
                                        <div>
                                            <label class="mb-1 block text-sm font-medium" for="caseWhen">
                                                {{ $t('newCase.preCreated.arbitrationEndTime') }}
                                            </label>
                                            <Datepicker
                                            v-model="preCArbitrationEndTime"
                                            wrap-class="form-input h-12 w-full rounded-md pl-9"
                                            mode="single"
                                            :max-today="false"
                                            :max-one-month="true"
                                            :min-twoday="false"
                                            :date-only="true"
                                            @change-date="(val) => changeDatePrecreated(val, 'preCArbitrationEndTime')"
                                            />
                                        </div>
                                    </div>

                                    <div
                                    v-if="isPrecreatedCase && preCStage == 5"
                                    >
                                        <div class="h-px w-full my-6">
                                            <div class="bg-gray-300 -mr-8 -ml-8 h-px"></div>
                                        </div>

                                        <div>
                                            <label class="mb-1 block text-sm font-medium" for="preCSolvedResult">
                                                {{ $t('newCase.preCreated.solved.result') }}
                                            </label>
                                            <Field v-slot="{ field }" v-model="preCSolvedResult" name="preCSolvedResult">
                                                <textarea
                                                v-bind="field"
                                                class="form-input h-20 w-full rounded-md"
                                                :placeholder="$t('newCase.preCreated.solved.preCSolvedNotesPlaceholder')"></textarea>
                                            </Field>
                                        </div>

                                        <div
                                        class="grid grid-cols-1 md:grid-cols-7 gap-3"
                                        >
                                            <div class="col-span-3">
                                                <label class="mb-1 block text-sm font-medium" for="preCSolvedNotes">
                                                    {{ $t('newCase.preCreated.solved.notes') }}
                                                </label>
                                                <Field v-slot="{ field }" v-model="preCSolvedNotes" name="preCSolvedNotes">
                                                    <textarea
                                                    v-bind="field"
                                                    class="form-input h-20 w-full rounded-md"
                                                    :placeholder="$t('newCase.preCreated.solved.preCSolvedNotesPlaceholder')"></textarea>
                                                </Field>
                                            </div>
                                            <div class="col-span-2">
                                                <label class="mb-1 block text-sm font-medium">
                                                    {{ $t('newCase.preCreated.solved.dateSolved') }}
                                                </label>
                                                <Datepicker
                                                v-model="preCSolvedDate"
                                                wrap-class="form-input h-12 w-full rounded-md pl-9"
                                                mode="single"
                                                :max-today="false"
                                                :max-one-month="true"
                                                :min-twoday="false"
                                                :date-only="true"
                                                @change-date="(val) => changeDatePrecreated(val, 'preCSolvedDate')"
                                                />
                                            </div>
                                            <div class="col-span-2">
                                                <label class="mb-1 block text-sm font-medium">
                                                    {{ $t('newCase.preCreated.solved.part') }}
                                                </label>
                                                <select
                                                v-model="preCSolvedPart"
                                                class="form-select w-full p-2">
                                                    <option
                                                    v-for="part in getPartsCase"
                                                    :key="part.id"
                                                    :value="part.id"
                                                    >
                                                        {{ part.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="h-px w-full my-6">
                                            <div class="bg-gray-300 -mr-8 -ml-8 h-px"></div>
                                        </div>
                                    </div>

                                    <div class="grid grid-cols-2 gap-2">
                                        <div>
                                            <label class="mb-1 block text-sm font-medium" for="typeOfPart">
                                                {{ $t('newCase.typeOfPart') }}
                                            </label>
                                            <select
                                            id="form_create_cases_party_type"
                                            v-model="typeOfPart"
                                            class="form-select w-full p-2">
                                                <option v-for="caseType in caseTypes" :key="caseType.id"
                                                    :value="caseType.id">
                                                    {{ caseType.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div>
                                            <label class="mb-1 block text-sm font-medium" for="caseArea">
                                                {{ $t('newCase.caseArea') }}
                                            </label>
                                            <select id="form_create_cases_area" v-model="caseArea" class="form-select w-full p-2">
                                                <option v-for="caseArea in caseAreas" :key="caseArea.id"
                                                    :value="caseArea.id">
                                                    {{ caseArea.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div>
                                        <label class="mb-1 block text-sm font-medium" for="caseFrom">
                                            {{ (isPrecreatedCase) ? $t('newCase.preCreated.caseFrom') : $t('newCase.caseFrom') }}
                                        </label>
                                        <select id="form_create_cases_bank" v-model="caseFrom" class="form-select w-full p-2">
                                            <option v-for="from in caseFroms" :key="from.id" :value="from.id">
                                                {{ from.name }}
                                            </option>
                                        </select>
                                    </div>

                                    <div>
                                        <label class="mb-1 block text-sm font-medium" for="caseWhen">
                                            {{ $t('newCase.caseWhen') }}
                                        </label>
                                        <Datepicker
                                        v-model="caseDate"
                                        wrap-class="form-input h-12 w-full rounded-md pl-9"
                                        mode="single"
                                        id="form_create_cases_date"
                                        @change-date="changeDate" />
                                    </div>

                                    <div>
                                        <label class="mb-1 block text-sm font-medium" for="caseDescription">
                                            {{ (isPrecreatedCase) ? $t('newCase.preCreated.caseDescription') : $t('newCase.caseDescription') }}
                                        </label>
                                        <Field v-slot="{ field }" v-model="caseDescription" name="caseDescription">
                                            <textarea id="form_create_cases_description" v-bind="field"
                                                class="form-input h-20 w-full rounded-md" :class="[
                                                    errors.caseDescription !== undefined
                                                        ? 'focus:border-red-500'
                                                        : '',
                                                ]" :placeholder="$t('newCase.caseDescriptionPlaceholder')"></textarea>
                                        </Field>
                                        <span class="text-sm text-red-500">
                                            <template v-if="errors.caseDescription !== undefined">
                                                {{ $t(errors.caseDescription) }}
                                            </template>
                                        </span>
                                    </div>

                                    <Field v-model="isCurrency" name="isCurrency">
                                        <div class="flex w-full cursor-pointer text-justify">
                                            <input v-model="isCurrency" type="checkbox"
                                            id="form_create_cases_is_currency"
                                                class="form-input h-5 checked:bg-sky-500 focus:bg-sky-500" />
                                            <p class="pl-2">
                                                {{ (isPrecreatedCase) ? this.$t('newCase.preCreated.moneyIncluded') : this.$t('newCase.moneyIncluded') }}
                                            </p>
                                        </div>
                                    </Field>

                                    <div class="w-full" v-if="isCurrency">
                                        <label class="mb-1 block text-sm font-medium" for="caseHowMuch">
                                            {{ (isPrecreatedCase) ? $t('newCase.preCreated.caseHowMuch') : $t('newCase.caseHowMuch') }}
                                        </label>
                                        <div class="w-full grid grid-cols-1 md:grid-cols-3">
                                            <div class="col-span-2 pr-4">
                                                <Field v-slot="{ field }" v-model="caseAmount" name="caseAmount">
                                                    <input id="form_create_cases_amount" v-bind="field"
                                                        class="form-input h-12 w-full rounded-md" :class="[
                                                            errors.caseAmount !== undefined
                                                                ? 'focus:border-red-500'
                                                                : '',
                                                        ]" type="number" :placeholder="$t('newCase.caseHowMuchPlaceholder')" />
                                                </Field>
                                                <span class="text-sm text-red-500">
                                                    <template v-if="errors.caseAmount !== undefined">
                                                        {{ $t(errors.caseAmount) }}
                                                    </template>
                                                </span>
                                            </div>
                                            <div class="col-span-1">
                                                <VueMultiselect
                                                v-model="caseCurrency"
                                                :options="currencies"
                                                :multiple="false"
                                                :close-on-select="true"
                                                :placeholder="$t('validation.selectCurrency')"
                                                label="name"
                                                track-by="id"
                                                :allow-empty="true"
                                                class="multiselect-currency"
                                                >
                                                    <template #singleLabel="props">
                                                        <span class="option__desc">
                                                            <span class="option__title"
                                                                >{{ props.option.code }}
                                                            </span>
                                                        </span>
                                                    </template>
                                                    <template #option="props">
                                                        <div class="option__desc space-x-2">
                                                            <span class="option__title">{{
                                                                props.option.name
                                                            }}</span>
                                                            <div
                                                                class="option__small inline rounded-lg px-2 py-1 text-xs font-bold"
                                                            >
                                                                {{ props.option.code }}
                                                            </div>
                                                        </div>
                                                    </template>
                                                </VueMultiselect>
                                            </div>
                                        </div>
                                    </div>

                                    <Field v-model="hasRequest" name="hasRequest">
                                        <div class="flex w-full cursor-pointer text-justify">
                                            <input id="form_create_cases_has_request" v-model="hasRequest" type="checkbox"
                                                class="form-input h-5 checked:bg-sky-500 focus:bg-sky-500" />
                                            <p class="pl-2">
                                                {{ (isPrecreatedCase) ? $t('newCase.preCreated.haveRequestQuestion') : $t('newCase.haveRequestQuestion') }}
                                            </p>
                                        </div>
                                    </Field>

                                    <div v-if="hasRequest">
                                        <label class="mb-1 block text-sm font-medium" for="caseRequest">
                                            {{ (isPrecreatedCase) ? $t('newCase.preCreated.caseRequest') : $t('newCase.caseRequest') }}
                                        </label>
                                        <div class="flex w-full">
                                            <div class="w-full pr-4">
                                                <Field v-slot="{ field }" v-model="caseRequest" name="caseRequest">
                                                    <input id="form_create_cases_request" v-bind="field"
                                                        class="form-input h-12 w-full rounded-md" :class="[
                                                            errors.caseRequest !== undefined
                                                                ? 'focus:border-red-500'
                                                                : '',
                                                        ]" type="text" :placeholder="(isPrecreatedCase) ? $t('newCase.preCreated.caseRequestPlaceholder') : $t('newCase.caseRequestPlaceholder')" />
                                                </Field>
                                                <span class="text-sm text-red-500">
                                                    <template v-if="errors.caseRequest !== undefined">
                                                        {{ $t(errors.caseRequest) }}
                                                    </template>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <DropFile
                                    id="form_create_cases_files"
                                    @files="changeFiles"
                                    />
                                </div>
                                <div class="mt-5 flex flex-col gap-4">
                                    <div
                                    v-if="!isPrecreatedCase"
                                    class="flex w-full cursor-pointer text-justify">
                                        <input
                                        v-model="caseNotifyViaEmail"
                                        type="checkbox"
                                        id="form_create_cases_notify_via_email"
                                        class="form-input h-5 checked:bg-sky-500 focus:bg-sky-500" />

                                        <p class="pl-2">
                                            <span>{{ $t('newCase.caseAcceptNotifyCaseEmail') }}</span>
                                        </p>
                                    </div>
                                    <div
                                    v-if="!isPrecreatedCase"
                                    class="flex w-full cursor-pointer text-justify">
                                        <input
                                        v-model="caseCheck"
                                        id="form_create_cases_accept_terms"
                                        type="checkbox"
                                        class="form-input h-5 checked:bg-sky-500 focus:bg-sky-500" />

                                        <p class="pl-2">
                                            <span>{{ $t('newCase.bySubmit') }}</span>
                                            <router-link class="px-1 text-sm font-bold text-sky-500" to="#">
                                                {{ $t('newCase.terms') }}
                                            </router-link>
                                            <span>{{ $t('newCase.and') }}</span>
                                            <router-link class="px-1 text-sm font-bold text-sky-500" to="#">
                                                {{ $t('newCase.privacyPolicy') }}
                                            </router-link>
                                        </p>
                                    </div>
                                    <button
                                    id="form_create_cases_next_action"
                                    :class="[
                                        'btn text-md mt-6 h-12 w-full rounded-md',
                                        (isValidFormNewCase(errors))
                                            ? 'cursor-pointer bg-sky-500 text-white hover:bg-sky-600'
                                            : 'cursor-not-allowed bg-gray-300 text-slate-100'
                                    ]">
                                        {{ (isPrecreatedCase) ? $t('button.save') : $t('action.next') }}
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </main>
        </div>
        <ModalChooseParty
        :party-type="modalPrecPartyType"
        :is-open="modalPreCPartyOpen"
        :required="false"
        :current="currentPartSelectedToModal"
        @select="selectParty"
        @closed="modalPreCPartyOpen = false"
        @remove-current="removeCurrentPart"
        />
        <DualRingLoader
        class="fixed_loader"
        v-if="isLoading" />
    </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { Form, Field } from 'vee-validate'
import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'
import preventRefreshMixin from '@/mixins/prevent-refresh.js'

import Header from '@/partials/Header.vue'
import Datepicker from '@/components/Datepicker.vue'
import BreadcrumNewCase from '@/components/case/BreadcrumNewCase.vue'
import DropFile from '@/components/files/DropFile.vue'
import ModalChooseParty from '@/components/modal/case/newcase/precreated/ModalChooseParty.vue'
import ImageLoader from '@/components/image/ImageLoader.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'

export default {
    name: 'NewCasePage',
    components: {
        Form,
        Field,
        Header,
        Datepicker,
        BreadcrumNewCase,
        DropFile,
        ModalChooseParty,
        ImageLoader,
        DualRingLoader,
    },
    mixins: [globalMixin, caseMixin, preventRefreshMixin],
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()
        const sidebarOpen = ref(false)
        const caseCurrencies = [
            {
                id: 'USD',
                name: 'USD',
            },
        ]

        return {
            t,
            n,
            store,
            toast,
            sidebarOpen,
            caseCurrencies,
        }
    },
    data() {
        return {
            caseFroms: [],
            caseNotifications: [
                {
                    id: 'system',
                    name: 'system',
                },
                {
                    id: 'email',
                    name: 'email',
                },
            ],
            caseName: '',
            caseDescription: '',
            caseNotification: 'system',
            caseFrom: null,
            caseAmount: '',
            caseDate: new Date(),
            caseCurrency: null,
            caseRequest: '',
            caseWithAssistance: true,
            caseCheck: false,
            caseNotifyViaEmail: true,
            isCurrency: true,
            hasRequest: true,
            attachment: null,

            preCClaimant: null,
            preCStage: 1,
            preCStageCaseSolved: null,
            preCAdvocate: null,
            preCBanklawyer: null,
            preCMediator: null,
            preCArbitrator: null,
            preCNegotiationStartTime: null,
            preCNegotiationEndTime: null,
            preCMediationStartTime: null,
            preCMediationEndTime: null,
            preCArbitrationStartTime: null,
            preCArbitrationEndTime: null,
            preCSolvedNotes: null,
            preCSolvedDate: null,
            preCSolvedPart: null,
            preCSolvedResult: null,

            caseClaimants: [],
            currencies: [],
            caseStages: [
                {id: 1, label: this.$t('newCase.preCreated.stages.initial')},
                {id: 2, label: this.$t('newCase.preCreated.stages.negotiation')},
                {id: 3, label: this.$t('newCase.preCreated.stages.mediation')},
                {id: 4, label: this.$t('newCase.preCreated.stages.arbitration')},
                {id: 5, label: this.$t('newCase.preCreated.stages.solved')},
            ],
            modalPreCPartyOpen: false,
            modalPrecPartyType: null,
            currentPartSelectedToModal: null,
            dataRefsPartyType: {
                advocate: 'preCAdvocate',
                bank_lawyer: 'preCBanklawyer',
                mediator: 'preCMediator',
                arbitrator: 'preCArbitrator',
            }
        }
    },
    watch: {
        hasRequest: function (val, old) {
            if (!val) {
                this.isCurrency = true;
            }
        },
        isCurrency: function (val, old) {
            if (!val) {
                this.hasRequest = true;
            }
        },
        isPrecreatedCase (val) {
            if (val) {
                this.getClaimants()
            }
        },
        currencies (val) {
            if (val) {
                this.caseCurrency = val.find(item => item.code === 'USD')
            }
        },
        preCStage (val) {
            if (val !== 5) {
                this.preCStageCaseSolved = null
            }
            if (val == 1 || val == 5) {
                this.preCBanklawyer = null
                this.preCMediator = null
                this.preCArbitrator = null
            }
            if (val == 2) {
                this.preCMediator = null
                this.preCArbitrator = null
            }
            if (val == 3) {
                this.preCArbitrator = null
            }
        },
        preCStageCaseSolved (val) {
            if (val == 2) {
                this.preCMediator = null
                this.preCArbitrator = null
            }
            if (val == 3) {
                this.preCArbitrator = null
            }
        }
    },
    mounted() {

        if (this.isPrecreatedCase) {
            this.getClaimants()
        }

        this.getAllBank()
        this.getAllAreas()
        this.getAllTypes()
        this.getAllCurrencies()

        const newCaseData = this.$store.getters['cases/newCase']
        if (!this.checkEmptyObject(newCaseData)) {
            this.caseName = newCaseData.name
            this.typeOfPart = newCaseData.case_type_id
            this.caseArea = newCaseData.case_area_id
            this.caseDescription = newCaseData.description
            this.caseNotification = newCaseData.notif_via
            this.caseFrom = newCaseData.bank_id
            this.caseAmount = newCaseData.amount
            this.caseDate = newCaseData.time
            this.caseCheck = newCaseData.caseCheck
            this.attachment = newCaseData.attachment
            this.caseRequest = newCaseData.request
            this.caseWithAssistance = newCaseData.with_assistant
            this.isCurrency = newCaseData.is_currency
            this.hasRequest = newCaseData.has_request
            this.caseNotifyViaEmail = newCaseData.notif_via_email
            this.needAssistance = newCaseData.with_assistant
            this.preCClaimant = newCaseData.preCClaimant ?? null
            this.preCStage = newCaseData.preCStage ?? null
            this.preCStageCaseSolved = newCaseData.preCStageCaseSolved ?? null
            this.preCAdvocate = newCaseData.preCAdvocate ?? null
            this.preCBanklawyer = newCaseData.preCBanklawyer ?? null
            this.preCMediator = newCaseData.preCMediator ?? null
            this.preCArbitrator = newCaseData.preCArbitrator ?? null
            this.preCNegotiationStartTime = newCaseData.preCNegotiationStartTime ?? null
            this.preCNegotiationEndTime = newCaseData.preCNegotiationEndTime ?? null
            this.preCMediationStartTime = newCaseData.preCMediationStartTime ?? null
            this.preCMediationEndTime = newCaseData.preCMediationEndTime ?? null
            this.preCArbitrationStartTime = newCaseData.preCArbitrationStartTime ?? null
            this.preCArbitrationEndTime = newCaseData.preCArbitrationEndTime ?? null
            this.preCSolvedNotes = newCaseData.preCSolvedNotes ?? null
            this.preCSolvedDate = newCaseData.preCSolvedDate ?? null
            this.preCSolvedPart = newCaseData.preCSolvedPart ?? null
            this.preCSolvedResult = newCaseData.preCSolvedResult ?? null
        }
    },
    methods: {
        openLink(id) {
            this.$router.push({
                name: 'dashboard',
                params: { slug: id },
                query: {
                    name: this.$route.query.name,
                    status: this.$route.query.status,
                    sub_status: this.$route.query.sub_status,
                    lang: this.$route.query.lang,
                },
            })
        },
        async getAllBank() {
            await this.store.dispatch('bank/getAllBank')
                .then((response) => {
                    const responseData = response.data
                    if (responseData.data) {
                        this.caseFroms = responseData.data
                        if (responseData.data.length > 0) this.caseFrom = responseData.data[0].id
                    }
                }).catch((error) => {
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        createComplain() {
            if (!this.isPrecreatedCase && !this.caseCheck) {
                this.toast.error(this.t('newCase.pleaseCheckAggreement'))
                return
            }
            if (this.isCurrency && !this.caseCurrency) {
                this.toast.error(this.t('newCase.pleaseSelectCurrency'))
                return
            }
            if (this.isPrecreatedCase) {
                if (!this.preCStage) {
                    this.toast.error(this.t('newCase.preCreated.errors.stage'));
                    return
                }
                if (!this.preCClaimant) {
                    this.toast.error(this.t('newCase.preCreated.errors.claimant'));
                    return
                }
                if (((this.preCStage >= 2 && this.preCStage <= 4) || this.preCStageCaseSolved >= 2) && !this.preCBanklawyer) {
                    this.toast.error(this.t('newCase.preCreated.errors.bank_lawyer'));
                    return
                }
                if (((this.preCStage >= 3 && this.preCStage <= 4) || this.preCStageCaseSolved >= 3) && !this.preCMediator) {
                    this.toast.error(this.t('newCase.preCreated.errors.mediator'));
                    return
                }
                if (((this.preCStage == 4) || this.preCStageCaseSolved >= 4) && !this.preCArbitrator) {
                    this.toast.error(this.t('newCase.preCreated.errors.arbitrator'));
                    return
                }
                if (this.preCStage == 5 && !this.preCStageCaseSolved) {
                    this.toast.error(this.t('newCase.preCreated.errors.stageCaseSolved'));
                    return
                }
            }
            const newCaseData = {
                name: this.caseName,
                case_type_id: this.typeOfPart,
                case_area_id: this.caseArea,
                description: this.caseDescription,
                notif_via: this.caseNotification,
                bank_id: this.caseFrom,
                amount: this.isCurrency ? parseInt(this.caseAmount) : null,
                time: this.caseDate,
                currency: this.isCurrency ? this.caseCurrency.id : null,
                currencyLabel: this.isCurrency ? this.caseCurrency.code : null,
                caseCheck: this.caseCheck,
                request: this.hasRequest ? this.caseRequest : null,
                notif_via_email: this.caseNotifyViaEmail,
                with_assistant: this.needAssistance,
                is_currency: this.isCurrency,
                has_request: this.hasRequest,
                is_precreated: this.isPrecreatedCase,
                preCClaimant: this.preCClaimant ?? null,
                preCStage: this.preCStage ?? null,
                preCStageCaseSolved: this.preCStageCaseSolved ?? null,
                preCAdvocate: this.preCAdvocate ?? null,
                preCBanklawyer: this.preCBanklawyer ?? null,
                preCMediator: this.preCMediator ?? null,
                preCArbitrator: this.preCArbitrator ?? null,
                preCNegotiationStartTime: this.preCNegotiationStartTime ?? null,
                preCNegotiationEndTime: this.preCNegotiationEndTime ?? null,
                preCMediationStartTime: this.preCMediationStartTime ?? null,
                preCMediationEndTime: this.preCMediationEndTime ?? null,
                preCArbitrationStartTime: this.preCArbitrationStartTime ?? null,
                preCArbitrationEndTime: this.preCArbitrationEndTime ?? null,
                preCSolvedNotes: this.preCSolvedNotes ?? null,
                preCSolvedDate: this.preCSolvedDate ?? null,
                preCSolvedPart: this.preCSolvedPart ?? null,
                preCSolvedResult: this.preCSolvedResult ?? null,
            }
            this.store.dispatch('cases/setNewCase', newCaseData)
            this.store.dispatch('cases/setFiles', this.caseFiles)
            this.store.dispatch('cases/setActiveNewCaseBreadcrum', 2)

            if (!this.isPrecreatedCase && this.needAssistance) {
                this.$router.push({
                    name: 'advocate-requirement',
                    query: {
                        lang: this.$route.query.lang,
                        assist: this.$route.query.assist,
                    },
                })
            } else if (!this.isPrecreatedCase) {
                this.$router.push({
                    name: 'review-application',
                    query: {
                        lang: this.$route.query.lang,
                        assist: this.$route.query.assist,
                    },
                })
            } else if (this.isPrecreatedCase) {
                this.createComplainPreCreated(newCaseData)
            }
        },
        createComplainPreCreated (newCaseData) {
            if (this.isLoading) return
            this.isLoading = true
            this.$store.dispatch('cases/createCase', newCaseData)
                .then((response) => {
                    const responseData = response.data;
                    const activeCaseId = responseData.id;
                    this.$store.dispatch('cases/resetActiveCase')
                    this.$store.dispatch('cases/resetNewCase')
                    this.$store.dispatch('cases/resetActiveNewCaseBreadcrum')
                    this.toast.success(responseData.message ?? 'Successfully created case data')

                    if (this.caseFiles.length > 0) {
                        let promises = []
                        if (activeCaseId !== null && activeCaseId !== undefined) {
                            this.caseFiles.forEach((item) => {
                                promises.push(
                                    this.uploadFileCase({
                                        case_id: this.$methods.encrypt(activeCaseId),
                                        type: 'INITIAL',
                                        attachment: item,
                                    })
                                )
                            })
                        }
                        Promise.all(promises).then(() => {
                            this.$router.push({ name: 'index' })
                            this.isLoading = false
                        })
                    } else {
                        this.isLoading = false
                        return this.$router.push({ name: 'index' })
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        fileInput(event) {
            this.attachment = event.target.files[0]
        },
        changeDate(val) {
            this.caseDate = val
        },
        changeFiles(event) {
            this.caseFiles = event
        },
        getClaimants () {
            this.store.dispatch('user/getClaimants').then(response => {
                if (response.data) {
                    this.caseClaimants = response.data
                }
            });
        },
        selectPreCParty (item) {
            if (this[this.dataRefsPartyType[item]]) this.currentPartSelectedToModal = this[this.dataRefsPartyType[item]]
            this.modalPrecPartyType = item
            this.modalPreCPartyOpen = true
        },
        selectParty (partySelected) {
            if (partySelected) this[this.dataRefsPartyType[this.modalPrecPartyType]] = partySelected
            else this[this.dataRefsPartyType[this.modalPrecPartyType]] = null
            this.modalPreCPartyOpen = false
        },
        removeCurrentPart () {
            this.currentPartSelectedToModal = null
            if (this.modalPrecPartyType) this[this.dataRefsPartyType[this.modalPrecPartyType]] = null
        },
        removePart (partType) {
            this[this.dataRefsPartyType[partType]] = null
        },
        changeDatePrecreated (val, dateType) {
            this[dateType] = val
        }
    },
    computed: {
        isValidFormNewCase () {
            return (errors) => {
                const probablyErrors = [
                    errors.caseName,
                    errors.caseDescription,
                    errors.caseRequest,
                    errors.caseAmount,
                ];
                const conditions = probablyErrors.every(item => !item)
                    &&
                    this.caseName !== ''
                    &&
                    this.caseDescription !== ''
                    &&
                    (this.caseAmount !== '' || !this.isCurrency)
                    &&
                    (this.caseRequest !== '' || !this.hasRequest)

                return conditions;
            }
        },
        isPrecreatedCase () {
            return this.$route.name === 'new-case-precreated'
        },
        getPreCStageClass () {
            let stageClass = '';
            if (this.preCStage === 1) {
                stageClass = 'md:col-span-5';
            } else if (this.preCStage === 2) {
                stageClass = 'md:col-span-4';
            } else if (this.preCStage === 3) {
                stageClass = 'md:col-span-3';
            } else if (this.preCStage === 4) {
                stageClass = 'md:col-span-2';
            } else if (this.preCStage === 5) {
                if (this.preCStageCaseSolved === 2) {
                    stageClass = 'md:col-span-3';
                } else if (this.preCStageCaseSolved === 3) {
                    stageClass = 'md:col-span-2';
                } else if (this.preCStageCaseSolved === 4) {
                    stageClass = 'md:col-span-1';
                } else {
                    stageClass = 'md:col-span-4';
                }
            }
            return stageClass;
        },
        showOptionStagePrec () {
            return (option) => {
                const validadors = {
                    stageCaseResolved: this.preCStage === 5,
                    bankLawyer: (this.preCStage >= 2 && this.preCStage <= 4) || (this.preCStage === 5 && this.preCStageCaseSolved >= 2),
                    mediator: (this.preCStage >= 3 && this.preCStage <= 4) || (this.preCStage === 5 && this.preCStageCaseSolved >= 3),
                    arbitrator: (this.preCStage === 4) || (this.preCStage === 5 && this.preCStageCaseSolved === 4),
                }
                return validadors[option]
            }
        },
        getPartsCase () {
            const parts = []
            if (this.preCAdvocate) parts.push(this.preCAdvocate)
            if (this.preCBanklawyer) parts.push(this.preCBanklawyer)
            if (this.preCMediator) parts.push(this.preCMediator)
            if (this.preCArbitrator) parts.push(this.preCArbitrator)
            return parts;
        }
    }
}
</script>
<style>

    .multiselect-currency > .multiselect__select, .multiselect__tags {
        height: 3rem;
    }

    .fixed_loader {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

</style>