<template>
    <ModalBlank id="danger-modal" :modal-open="isOpen" @close-modal="close">
        <div class="flex space-x-4 p-5 text-center">
            <!-- Content -->
            <div class="w-full">
                <!-- Modal header -->
                <div class="mb-2">
                    <div class="text-lg font-semibold text-slate-800">
                        {{ $t('document.modalInfo.title') }}
                    </div>
                </div>
                <!-- Modal content -->
                <div class="mb-10 text-sm">
                    <div class="space-y-2">
                        <div class="flex justify-between">
                            <div class="font-bold">
                                {{ $t('document.modalInfo.documentName') }}
                            </div>
                            <div>{{ document.name }}</div>
                        </div>
                        <div v-if="document.owner" class="flex justify-between">
                            <div class="font-bold">{{ $t('document.modalInfo.owner') }}</div>
                            <div>
                                {{ document.owner.first_name }} {{ document.owner.last_name }}
                            </div>
                        </div>
                        <div class="flex justify-between">
                            <div class="font-bold">{{ $t('document.modalInfo.type') }}</div>
                            <div>
                                <p>
                                    {{ document.type }}
                                    <span v-if="fileType !== ''" class="text-sm font-medium">
                                        (.{{ fileType }})
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div v-if="document.type !== 'Folder'" class="flex justify-between">
                            <div class="font-bold">{{ $t('document.modalInfo.size') }}</div>
                            <div>
                                {{ formatBytes(documentSize) }}
                            </div>
                        </div>
                        <div class="flex justify-between">
                            <div class="font-bold">
                                {{ $t('document.modalInfo.shareWith') }}
                            </div>
                            <div v-if="document.shares && document.shares.length > 0">
                                {{ document.shares.map(item => item.user.name).join(', ') }}
                            </div>
                            <div v-else>
                                <span>-</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="flex flex-wrap justify-end space-x-2">
                    <button class="btn-lg cursor-pointer bg-red-400 text-white hover:bg-red-500" @click.stop="close()">
                        {{ $t('button.cancel') }}
                    </button>
                </div>
            </div>
        </div>
    </ModalBlank>
</template>
<script>
import ModalBlank from '@/components/ModalBlank.vue'
import globalMixin from '@/mixins/global.js'

export default {
    name: 'ModalDeleteComponent',
    components: {
        ModalBlank,
    },
    mixins: [globalMixin],
    props: {
        document: {
            type: Object,
            required: true,
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['closed', 'delete'],
    computed: {
        documentSize() {
            if (this.document.type !== 'Folder' && 'files' in this.document) {
                if (this.document.files[0] !== undefined)
                    return this.document.files[0].size
                else return 0
            } else {
                return 0
            }
        },
        fileType() {
            if (this.document.type !== 'Folder' && 'files' in this.document) {
                if (this.document.files[0] !== undefined) {
                    const fileName = this.document.files[0].file
                    return fileName.substring(fileName.lastIndexOf('.') + 1)
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
    },
    methods: {
        close() {
            this.$emit('closed')
        },
    },
}
</script>
