import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'
import { toBase64 } from '../../utils/Utils.js'

// initial state
const state = () => ({
    timeline: [],
})

// getters
const getters = {
    currentTimeline() {
        return localStorage.getItem('redek.currentTimeline') !== null
            ? JSON.parse(localStorage.getItem('redek.currentTimeline'))
            : {}
    },
}

// actions
const actions = {
    setTimeline({ state, commit }, payload) {
        commit('SET_TIMELINE', payload)
    },
    resetTimeline({ state, commit }, payload) {
        commit('RESET_TIMELINE')
    },
    createTimeline({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            let newPayload = JSON.parse(JSON.stringify(payload))
            toBase64(payload.attachment).then(result => {
                newPayload.attachment = result
                    ? {
                        cont: result.split(',').pop(),
                        name: payload.attachment.name.split('.')[0],
                        ext: payload.attachment.name.split('.').pop(),
                        size: payload.attachment.size
                    }
                    : null
                newPayload.manually = true

                axios.post(`${routePathAPI()}/timelines`, newPayload)
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200 || response.status === 201) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
            })
        })
    },
    getTimelineDetail({ commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            axios
                .get(`${routePathAPI()}/timeline/detail/${payload.id}`, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    const responseData = response.data
                    if (response.status === 200 && responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAllTimeline({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {}
            params.page = payload.page ? payload.page : 1;
            params.per_page = payload.take ? payload.take : 50;
            params.order = 'created_at.' + (payload.order ? payload.order : 'DESC');
            if (payload.q) params.search = payload.q
            // if (payload.type) params.type = payload.type
            // if (payload.types) params.types = payload.types

            axios.get(`${routePathAPI()}/timelines/byCase/${payload.case_id}`, {
                params: params,
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            })
                .then(async (response) => {
                    const responseData = response.data
                    if (response.status === 200) {
                        commit('SET_TIMELINE', responseData.data)
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}

// mutations
const mutations = {
    SET_TIMELINE(state, payload) {
        localStorage.setItem('redek.currentTimeline', JSON.stringify(payload))
    },
    RESET_TIMELINE(state) {
        localStorage.removeItem('redek.currentTimeline')
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
