<template>
  <label v-if="showLabel" class="mb-1 block text-sm font-medium" for="files">
    {{ $t('modal.attachment') }}
    ({{ $t('general.optional') }})
  </label>
  <div class="main w-full">
    <div
      class="dropzone-container w-full items-center text-center"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        id="fileInput"
        ref="file"
        type="file"
        multiple
        name="file"
        class="hidden-input"
        accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.mp4,.mp3"
        @change="onChange"
      />

      <svg
        aria-hidden="true"
        class="mx-auto h-10 w-10 text-gray-400"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
        ></path>
      </svg>

      <label for="fileInput" class="file-label">
        <div v-if="isDragging">Release to drop files here.</div>
        <div v-else>Drop files here or <u>click here</u> to upload.</div>
      </label>

      <div v-if="files.length" class="preview-container mt-4">
        <div v-for="file in files" :key="file.name" class="preview-card">
          <div>
            <img class="preview-img" :src="generateThumbnail(file)" />
            <p :title="file.name">
              {{ makeName(file.name) }} -
              {{ Math.round(file.size / 1000) + 'kb' }}
            </p>
          </div>
          <div>
            <button
              class="ml-2"
              type="button"
              title="Remove file"
              @click="remove(files.indexOf(file))"
            >
              <b>&times;</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showLabel: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['files'],
  data() {
    return {
      isDragging: false,
      files: [],
    }
  },
  methods: {
    onChange() {
      if (this.files.length > 0) {
        this.files.push(...this.$refs.file.files)
      } else {
        this.files = [...this.$refs.file.files]
      }
      this.$emit('files', this.files)
    },

    generateThumbnail(file) {
      let fileSrc = URL.createObjectURL(file)
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc)
      }, 1000)
      return fileSrc
    },

    makeName(name) {
      return (
        name.split('.')[0].substring(0, 3) +
        '...' +
        name.split('.')[name.split('.').length - 1]
      )
    },

    remove(i) {
      this.files.splice(i, 1)
      this.$emit('files', this.files)
    },

    dragover(e) {
      e.preventDefault()
      this.isDragging = true
    },

    dragleave() {
      this.isDragging = false
    },

    drop(e) {
      e.preventDefault()
      this.$refs.file.files = e.dataTransfer.files
      this.onChange()
      this.isDragging = false
    },
  },
}
</script>

<style>
.main {
  display: flex;
  flex-grow: 1;
  align-items: center;
  /* justify-content: center;
  text-align: center; */
}
.dropzone-container {
  padding: 4rem;
  background: #f7fafc;
  border: 1px solid #e2e8f0;
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  font-size: 20px;
  display: block;
  cursor: pointer;
}
.preview-container {
  display: flex;
  margin-top: 2rem;
}
.preview-card {
  display: flex;
  border: 1px solid #a2a2a2;
  padding: 5px;
  margin-left: 5px;
}
.preview-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}
</style>
