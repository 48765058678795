<template>
  <div class="flex w-full flex-wrap bg-white">
    <div class="flex justify-start space-x-2">
      <div class="flex px-4">
        <ImageLoader
          wrapper-class="shrink-0 rounded-full"
          :src="user.avatar"
          width="60"
          height="60"
          alternate="userAvatar"
        />
      </div>
      <div class="space-y-1">
        <div class="space-x-2 font-medium">
          <span>
            {{ getName(user) }}
          </span>
          <span v-if="me.id === user.id">{{ $t('you') }}</span>
        </div>
        <div v-if="user.role" class="flex justify-start">
          <p
            class="rounded-lg bg-purple-100 px-2 py-1 text-center text-xs font-semibold text-purple-600"
          >
            {{ user.role.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import ImageLoader from '@/components/image/ImageLoader.vue'

export default {
  name: 'MemberItemComponent',
  components: { ImageLoader },
  mixins: [globalMixin],
  props: {
    user: {
      type: Object,
      default: () => {
        return {}
      },
    },
    me: {
      type: Object,
      default: () => {
        return {
          id: '',
        }
      },
    },
  },
  methods: {},
}
</script>
