import { createRouter, createWebHistory } from 'vue-router'
import store from '@/stores/vuex/index'

// import Campaigns from './pages/Campaigns.vue'
// import TasksKanban from './pages/tasks/TasksKanban.vue'
// import TasksList from './pages/tasks/TasksList.vue'
// import Inbox from './pages/Inbox.vue'
// import Notifications from './pages/settings/Notifications.vue'
// import Apps from './pages/settings/Apps.vue'
// import Plans from './pages/settings/Plans.vue'
// import Billing from './pages/settings/Billing.vue'
// import Feedback from './pages/settings/Feedback.vue'
// import Changelog from './pages/utility/Changelog.vue'
// import Roadmap from './pages/utility/Roadmap.vue'
// import Faqs from './pages/utility/Faqs.vue'
// import EmptyState from './pages/utility/EmptyState.vue'
// import KnowledgeBase from './pages/utility/KnowledgeBase.vue'
// import ButtonPage from './pages/component/ButtonPage.vue'
// import FormPage from './pages/component/FormPage.vue'
// import DropdownPage from './pages/component/DropdownPage.vue'
// import AlertPage from './pages/component/AlertPage.vue'
// import ModalPage from './pages/component/ModalPage.vue'
// import PaginationPage from './pages/component/PaginationPage.vue'
// import TabsPage from './pages/component/TabsPage.vue'
// import BreadcrumbPage from './pages/component/BreadcrumbPage.vue'
// import BadgePage from './pages/component/BadgePage.vue'
// import AvatarPage from './pages/component/AvatarPage.vue'
// import TooltipPage from './pages/component/TooltipPage.vue'
// import AccordionPage from './pages/component/AccordionPage.vue'
// import IconsPage from './pages/component/IconsPage.vue'

import Account from './pages/settings/Account.vue'
import ProfileRating from './pages/settings/Rating.vue'
import PageNotFound from './pages/utility/PageNotFound.vue'
import PageAccessDenied from './pages/utility/PageAccessDenied.vue'
import Login from './pages/Login.vue'
import VerificationOTP from './pages/VerificationOTP.vue'
import Register from './pages/Register.vue'
import ForgotPassword from './pages/ForgotPassword.vue'
import ResetPassword from './pages/ResetPassword.vue'
import Case from './pages/Case.vue'
import NewCase from './pages/NewCase.vue'
import Dashboard from './pages/Dashboard.vue'
import AdvocateRequirement from './pages/AdvocateRequirement.vue'
import AdvocateSelection from './pages/AdvocateSelection.vue'
import ReviewApplication from './pages/ReviewApplication.vue'
import CaseSubmitted from './pages/CaseSubmitted.vue'
import MediationAgreement from './pages/MediationAgreement.vue'
import MediatorRequirement from './pages/MediatorRequirement.vue'
import MediatorSelection from './pages/MediatorSelection.vue'
import MediatorSubmitted from './pages/MediatorSubmitted.vue'
import ArbitratorRequirement from './pages/ArbitratorRequirement.vue'
import ArbitratorSelection from './pages/ArbitratorSelection.vue'
import ArbitratorSubmitted from './pages/ArbitratorSubmitted.vue'
import BankLawyerSelection from './pages/BankLawyerSelection.vue'
import CaseStagePage from './pages/CaseStage.vue'
import CaseStageConcludedPage from './pages/CaseStageConcluded.vue'
import PrivateNotebookPage from './pages/PrivateNotebook.vue'
import Messages from './pages/Messages.vue'
import TimelinePage from './pages/Timeline.vue'
import NotificationPage from './pages/Notification.vue'
import Calendar from './pages/Calendar.vue'
import CaseDetailPage from './pages/CaseDetail.vue'
import FinalDecision from './pages/FinalDecision.vue'
import DocumentPage from './pages/Document.vue'

import AdminUserPage from './pages/admin/User.vue'
import AdminRolesPage from './pages/admin/Roles.vue'
import AdminRolesDetailPage from './pages/admin/roles/Detail.vue'
import AdminPermissionsPage from './pages/admin/Permissions.vue'
import AdminActivitiesPage from './pages/admin/Activities.vue'
import AdminDashboard from './pages/admin/Dashboard.vue'
import AdminParameters from './pages/admin/Parameters.vue'
import AdminCases from './pages/admin/Cases.vue'
import AdminCaseDetail from './pages/admin/CaseDetail.vue'

import ChatGroup from './pages/ChatGroup.vue'
import ChatDiscussion from './pages/ChatDiscussion.vue'

const routerHistory = createWebHistory()

const router = createRouter({
    history: routerHistory,
    routes: [
        /**
         * Modified
         */
        {
            path: '/',
            name: 'index',
            meta: { layout: 'default', permissions: ['cases_list_status_all'] },
            component: AdminCases,
            props: { status: 'all' },
        },
        {
            path: '/all-cases',
            name: 'all-cases',
            meta: { layout: 'default', permissions: ['cases_list_status_all'] },
            component: AdminCases,
            props: { status: 'all' },
        },
        {
            path: '/pending-cases',
            name: 'pending-cases',
            meta: { layout: 'default', permissions: ['cases_list_status_pending'] },
            component: AdminCases,
            props: { status: 'onprepare' },
        },
        {
            path: '/ongoing-cases',
            name: 'ongoing-cases',
            meta: { layout: 'default', permissions: ['cases_list_status_ongoing'] },
            component: AdminCases,
            props: { status: 'ongoing' },
        },
        {
            path: '/settled-cases',
            name: 'settled-cases',
            meta: { layout: 'default', permissions: ['cases_list_status_solved'] },
            component: AdminCases,
            props: { status: 'solved' },
        },
        {
            path: '/new-case',
            name: 'new-case',
            meta: { layout: 'default', permissions: ['cases_create'] },
            component: NewCase,
        },
        {
            path: '/new-case/precreated',
            name: 'new-case-precreated',
            meta: { layout: 'default', permissions: ['cases_create_precreated'] },
            component: NewCase,
        },
        {
            path: '/advocate-requirement',
            name: 'advocate-requirement',
            meta: { layout: 'default', permissions: ['cases_create'] },
            component: AdvocateRequirement,
        },
        {
            path: '/advocate-selection/:slug?',
            name: 'advocate-selection',
            meta: { layout: 'default', permissions: ['cases_create', 'cases_edit_pick_parties_advocate'] },
            component: AdvocateSelection,
        },
        {
            path: '/review-application',
            name: 'review-application',
            meta: { layout: 'default', permissions: ['cases_create'] },
            component: ReviewApplication,
        },
        {
            path: '/case-submitted',
            name: 'case-submitted',
            meta: { layout: 'default', permissions: ['cases_create'] },
            component: CaseSubmitted,
        },
        {
            path: '/dashboard/:slug',
            name: 'dashboard',
            meta: { layout: 'default', permissions: ['cases_manage_dashboard', 'cases_manage_access_event_reassigned'], isForCaseSidebar: true, getCase: true },
            component: Dashboard,
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
        },
        {
            path: '/verification/:type',
            name: 'verification-otp',
            component: VerificationOTP,
        },
        {
            path: '/register',
            name: 'register',
            component: Register,
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: ForgotPassword,
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            component: ResetPassword,
        },


        // Admin routes
        {
            path: '/admin',
            name: 'admin',
            meta: { title: 'Admin', layout: 'default' },
            // component: Sidebar,
            children: [
                {
                    path: 'dashboard',
                    name: 'admin-dashboard',
                    meta: { layout: 'default', permissions: ['dashboard_admin'] },
                    component: AdminDashboard,
                },
                {
                    path: 'parameters',
                    name: 'admin-parameters',
                    meta: { layout: 'default', permissions: ['parameters_list'] },
                    component: AdminParameters,
                },
                {
                    path: 'user',
                    name: 'admin-users',
                    meta: { layout: 'default', permissions: ['security_users_management_list'] },
                    component: AdminUserPage,
                },
                {
                    path: 'roles',
                    name: 'admin-roles',
                    meta: { layout: 'default', permissions: ['security_roles_management_list'] },
                    component: AdminRolesPage,
                },
                {
                    path: 'roles/:id',
                    name: 'admin-roles-detail',
                    meta: { layout: 'default', permissions: ['security_roles_management_edit'] },
                    component: AdminRolesDetailPage,
                },
                {
                    path: 'permissions',
                    name: 'admin-permissions',
                    meta: { layout: 'default', permissions: ['security_permissions_management_list'] },
                    component: AdminPermissionsPage,
                },
                {
                    path: 'activities',
                    name: 'admin-activities',
                    meta: { layout: 'default' },
                    component: AdminActivitiesPage,
                },
                {
                    path: 'case-detail/:slug',
                    name: 'case-detail-admin',
                    meta: { layout: 'default', permissions: ['cases_edit'], getCase: true, },
                    component: AdminCaseDetail,
                },
            ],
        },
        {
            path: '/case-stage/:slug',
            name: 'case-stage',
            meta: {
                layout: 'default',
                permissions: ['cases_manage_case_stage_list', 'cases_manage_access_event_reassigned'],
                isForCaseSidebar: true,
                getCase: true,
            },
            component: CaseStagePage,
        },
        {
            path: '/case-stage-concluded/:slug',
            name: 'case-stage-concluded',
            meta: { layout: 'default' },
            component: CaseStageConcludedPage,
        },
        {
            path: '/mediation-agreement/:slug',
            name: 'mediation-agreement',
            component: MediationAgreement,
        },
        {
            path: '/case-stage/:slug/mediator-requirement',
            name: 'mediator-requirement',
            component: MediatorRequirement,
        },
        {
            path: '/case-stage/:slug/mediator-selection',
            name: 'mediator-selection',
            component: MediatorSelection,
            meta: { layout: 'default', permissions: ['cases_edit_pick_parties_mediator', 'cases_manage_access_event_reassigned'] },
        },
        {
            path: '/case-stage/:slug/mediator-submitted',
            name: 'mediator-submitted',
            component: MediatorSubmitted,
        },
        {
            path: '/case-stage/:slug/arbitrator-requirement',
            name: 'arbitrator-requirement',
            component: ArbitratorRequirement,
        },
        {
            path: '/case-stage/:slug/arbitrator-selection',
            name: 'arbitrator-selection',
            component: ArbitratorSelection,
            meta: {
                layout: 'default',
                permissions: ['cases_edit_pick_parties_arbitrator', 'cases_manage_access_event_reassigned'],
                getCase: true,
            },
        },
        {
            path: '/case-stage/:slug/arbitrator-submitted',
            name: 'arbitrator-submitted',
            component: ArbitratorSubmitted,
        },
        {
            path: '/case-stage/:slug/bank-lawyer-selection',
            name: 'bank-lawyer-selection',
            meta: {
                layout: 'default',
                permissions: ['cases_edit_pick_parties_bank_lawyer', 'cases_manage_access_event_reassigned'],
                getCase: true,
            },
            component: BankLawyerSelection,
        },
        {
            path: '/private-notebook/:slug',
            name: 'private-notebook',
            meta: {
                layout: 'default',
                permissions: ['cases_manage_private_notebook_list', 'cases_manage_access_event_reassigned'],
                isForCaseSidebar: true,
                getCase: true,
            },
            component: PrivateNotebookPage,
        },
        {
            path: '/chats/group/:slug',
            name: 'chat-group',
            meta: {
                layout: 'default',
                permissions: ['cases_manage_chats_list', 'cases_manage_access_event_reassigned'],
                isForCaseSidebar: true,
                getCase: true,
            },
            component: ChatGroup,
        },
        {
            path: '/chats/:id/:slug',
            name: 'chats',
            meta: {
                layout: 'default',
                permissions: ['cases_manage_chats_edit_view', 'cases_manage_access_event_reassigned'],
                isForCaseSidebar: true,
                getCase: true,
            },
            component: ChatDiscussion,
        },
        {
            path: '/documents/:slug/:id?',
            name: 'documents',
            meta: {
                layout: 'default',
                permissions: ['cases_manage_documents_list', 'cases_manage_access_event_reassigned'],
                isForCaseSidebar: true,
                getCase: true,
            },
            component: DocumentPage,
        },
        {
            path: '/timeline/:slug',
            name: 'timeline',
            meta: {
                layout: 'default',
                permissions: ['cases_manage_timeline_list', 'cases_manage_access_event_reassigned'],
                isForCaseSidebar: true,
                getCase: true,
            },
            component: TimelinePage,
        },
        {
            path: '/notification',
            name: 'notification',
            meta: { layout: 'default' },
            component: NotificationPage,
        },
        {
            path: '/notification/:slug',
            name: 'notification-case',
            meta: { layout: 'default' },
            component: NotificationPage,
        },
        {
            path: '/calendar/:slug',
            name: 'calendar',
            meta: {
                layout: 'default',
                permissions: ['cases_manage_calendar_list', 'cases_manage_access_event_reassigned'],
                isForCaseSidebar: true,
                getCase: true,
            },
            component: Calendar,
        },
        {
            path: '/case-detail/:slug',
            name: 'case-detail',
            meta: {
                layout: 'default',
                permissions: ['cases_manage_case_details', 'cases_manage_access_event_reassigned'],
                isForCaseSidebar: true,
                getCase: true,
            },
            component: CaseDetailPage,
        },
        {
            path: '/final-decision/:slug',
            name: 'final-decision',
            meta: {
                layout: 'default',
                permissions: ['cases_manage_case_stage_stages_solved_final_decision', 'cases_manage_access_event_reassigned'],
                isForCaseSidebar: true,
                getCase: true,
            },
            component: FinalDecision,
        },
        // Customer Service Route
        {
            path: '/customer-service',
            name: 'customer-service',
            meta: { title: 'Customer Service', layout: 'default' },
            // component: Sidebar,
            children: [
                {
                    path: 'all',
                    name: 'all-cs-chat',
                    meta: { layout: 'default' },
                    component: Messages,
                    props: { status: 'all' },
                },
                {
                    path: 'unanswered',
                    name: 'unanswered-cs-chat',
                    meta: { layout: 'default' },
                    component: Messages,
                    props: { status: 'unanswered' },
                },
                {
                    path: 'answered',
                    name: 'answered-cs-chat',
                    meta: { layout: 'default' },
                    component: Messages,
                    props: { status: 'answered' },
                },
                {
                    path: 'archived',
                    name: 'archived-cs-chat',
                    meta: { layout: 'default' },
                    component: Messages,
                    props: { status: 'archived' },
                },
            ],
        },
        {
            path: '/profile/account',
            component: Account,
            meta: { layout: 'default', permissions: ['profile_edit'] },
        },
        {
            path: '/profile/ratings',
            component: ProfileRating,
            meta: { layout: 'default', permissions: ['profile_ratings_and_reviews'] },
        },
        {
            path: '/access-denied',
            name: 'access-denied',
            component: PageAccessDenied,
        },
        {
            path: '/:pathMatch(.*)*',
            component: PageNotFound,
        },
    ],
})

const setCurrentUser = (to) => {
    store.dispatch('auth/getActiveUser', { case_id: to.params.slug ?? null });
}

const setCurrentCase = (to) => {
    if (to.meta.getCase && to.params.slug) {
        store.dispatch('cases/setCurrentCase', { case_id: to.params.slug })
    }
}

const checkAccess = (to, from, next) => {
    const redekPermissions = localStorage.getItem('redek.permissions')
        ? JSON.parse(atob(localStorage.getItem('redek.permissions')))
        : null;
    const authenticate = localStorage.getItem('redek.accessToken');
    const routesExceptLogin = [
        'login',
        'register',
        'reset-password',
        'forgot-password',
        'verification-otp',
        'access-denied',
    ];
    if (!routesExceptLogin.some(item => item === to.name)) {
        if (authenticate && redekPermissions) {
            if (
                !to.meta.permissions
                ||
                (
                    to.meta.permissions
                    &&
                    redekPermissions.some(item => to.meta.permissions.some(elem => elem === item.functionality.reference))
                )
            ) {
                if (
                    to.meta.permissions
                    &&
                    to.meta.permissions.some(item => item === 'cases_manage_access_event_reassigned')
                    &&
                    !redekPermissions.some(item => item.functionality.reference === 'cases_manage_access_event_reassigned')
                ) {
                    store.dispatch('cases/isAValidCaseParty', {
                        case_id: to.params.slug
                    }).then(response => {
                        if (response.data) {
                            setCurrentUser(to);
                            setCurrentCase(to);
                            next();
                        } else {
                            next({ name: 'access-denied' });
                        }
                    })
                } else {
                    setCurrentUser(to);
                    setCurrentCase(to);
                    next();
                }
            } else {
                next({ name: 'access-denied' });
            }
        } else {
            next({ name: 'login' });
        }
    } else {
        next();
    }
}

router.beforeEach(function (to, from, next) {
    checkAccess(to, from, next);
});

export default router
