<template>
    <div class="relative rounded-sm border border-slate-200 bg-white shadow-lg">
        <div>
            <!-- Table -->
            <div class="overflow-x-auto">
                <table class="w-full table-auto">
                    <!-- Table header -->
                    <thead
                    class="border-t border-b border-slate-200 bg-slate-50 text-xs font-semibold uppercase text-slate-500"
                    >
                        <tr>
                            <th
                            class="w-px whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                            ></th>
                            <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left font-semibold">
                                    {{ $t('general.columns.name') }}
                                </div>
                            </th>
                            <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left font-semibold">
                                    {{ $t('general.columns.ratings') }}
                                </div>
                            </th>
                            <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left font-semibold">
                                    {{ $t('general.columns.case_handled') }}
                                </div>
                            </th>
                            <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="font-semibold">
                                    {{ $t('general.columns.ongoing_cases') }}
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <!-- Table body -->
                    <tbody class="divide-y divide-slate-200 text-sm">
                        <Advocate
                        v-for="advocate in advocates"
                        :key="advocate.id"
                        v-model:selected="selected"
                        :advocate="advocate"
                        :value="advocate"
                        />
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue'
import Advocate from '@/components/AdvocateTableItem.vue'

export default {
    name: 'AdvocateTable',
    components: {
        Advocate,
    },
    props: ['selectedItems', 'advocates'],
    emits: ['change-selection'],
    setup(props, { emit }) {
        const selectAll = ref(false)
        const selected = ref([])

        const checkAll = () => {
            selected.value = []
            if (!selectAll.value) {
                selected.value = props.advocates.map((customer) => customer.id)
            }
        }

        watch(selected, () => {
            selectAll.value =
                props.advocates.length === selected.value.length ? true : false
            emit('change-selection', selected.value)
        })

        return {
            selectAll,
            selected,
            checkAll,
        }
    },
}
</script>
