<template>
    <div class="editor">
        <editor-content
        class="editor__content"
        :class="editorContentClass"
        :editor="editor" />

        <div class="menubar pt-4">
            <span v-for="actionName in activeButtons" :key="actionName">
                <button v-if="actionName === 'bold'" class="menubar__button"
                    :class="{ 'is-active': editor.isActive('bold') }" @click="editor.chain().focus().toggleBold().run()">
                    <icon name="bold" />
                </button>
                <button v-if="actionName === 'italic'" class="menubar__button"
                    :class="{ 'is-active': editor.isActive('italic') }"
                    @click="editor.chain().focus().toggleItalic().run()">
                    <icon name="italic" />
                </button>

                <button v-if="actionName === 'strike'" class="menubar__button"
                    :class="{ 'is-active': editor.isActive('strike') }"
                    @click="editor.chain().focus().toggleStrike().run()">
                    <icon name="strike" />
                </button>

                <button v-if="actionName === 'underline'" class="menubar__button"
                    :class="{ 'is-active': editor.isActive('underline') }"
                    @click="editor.chain().focus().toggleUnderline().run()">
                    <icon name="underline" />
                </button>

                <button v-if="actionName === 'code'" class="menubar__button"
                    :class="{ 'is-active': editor.isActive('code') }" @click="editor.chain().focus().toggleCode().run()">
                    <icon name="code" />
                </button>

                <button v-if="actionName === 'h1'" class="menubar__button"
                    :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
                    @click="editor.chain().focus().toggleHeading({ level: 1 }).run()">
                    H1
                </button>

                <button v-if="actionName === 'h2'" class="menubar__button"
                    :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
                    @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
                    H2
                </button>

                <button v-if="actionName === 'h3'" class="menubar__button"
                    :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
                    @click="editor.chain().focus().toggleHeading({ level: 3 }).run()">
                    H3
                </button>

                <button v-if="actionName === 'bulletList'" class="menubar__button"
                    :class="{ 'is-active': editor.isActive('bulletList') }"
                    @click="editor.chain().focus().toggleBulletList().run()">
                    <icon name="ul" />
                </button>

                <button v-if="actionName === 'orderedList'" class="menubar__button"
                    :class="{ 'is-active': editor.isActive('orderedList') }"
                    @click="editor.chain().focus().toggleOrderedList().run()">
                    <icon name="ol" />
                </button>

                <button v-if="actionName === 'blockquote'" class="menubar__button"
                    :class="{ 'is-active': editor.isActive('blockquote') }"
                    @click="editor.chain().focus().toggleBlockquote().run()">
                    <icon name="quote" />
                </button>

                <button v-if="actionName === 'codeBlock'" class="menubar__button"
                    :class="{ 'is-active': editor.isActive('codeBlock') }"
                    @click="editor.chain().focus().toggleCodeBlock().run()">
                    <icon name="code" />
                </button>

                <button v-if="actionName === 'horizontalRule'" class="menubar__button"
                    @click="editor.chain().focus().setHorizontalRule().run()">
                    <icon name="hr" />
                </button>

                <button v-if="actionName === 'undo'" class="menubar__button" @click="editor.chain().focus().undo().run()">
                    <icon name="undo" />
                </button>

                <button v-if="actionName === 'redo'" class="menubar__button" @click="editor.chain().focus().redo().run()">
                    <icon name="redo" />
                </button>
            </span>
            <AttachIcon v-if="showAttach" class="inline cursor-pointer" @click="$emit('attach-icon')" />
        </div>
    </div>
</template>

<script>
import Icon from '@/components/editor/Icon.vue'
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Focus from '@tiptap/extension-focus'
import AttachIcon from '@/assets/svg/attach.svg'

export default {
    name: 'EditorComponent',
    components: {
        EditorContent,
        Icon,
        AttachIcon,
    },
    props: {
        showAttach: {
            type: Boolean,
            default: true,
        },
        initialContent: {
            type: String,
            required: true,
            default: '',
        },
        activeButtons: {
            type: Array,
            validator: function (list) {
                for (let el of list) {
                    // The value must match one of these strings
                    if (
                        [
                            'bold',
                            'italic',
                            'strike',
                            'underline',
                            'code',
                            'h1',
                            'h2',
                            'h3',
                            'bulletList',
                            'orderedList',
                            'blockquote',
                            'codeBlock',
                            'horizontalRule',
                            'undo',
                            'redo',
                        ].indexOf(el) === -1
                    ) {
                        return -1
                    }
                }
                return 1
            },
            default: () => ['bold', 'italic'],
        },
        editorContentClass: {
            type: String,
            default: '',
        }
    },
    emits: ['update', 'attach-icon'],
    data() {
        return {
            html: '',
            json: '',
            editor: null,
        }
    },
    watch: {
        initialContent(val) {
            let element = undefined
            const elements = document.getElementsByClassName('ProseMirror')
            if (elements.length > 0) {
                element = elements[0]
            }
            let focused = false
            if (element !== undefined) {
                focused = this.hasClass(element, 'ProseMirror-focused')
            }
            if (!focused) {
                this.editor.commands.setContent(val)
            }
        },
    },
    created() {
        this.editor = new Editor({
            autofocus: true,
            extensions: [
                StarterKit,
                Underline,
                Focus.configure({
                    className: 'has-focus',
                    mode: 'all',
                }),
            ],
            editorProps: {
                attributes: {
                    class: 'p-2 min-h-20',
                },
            },
        })

        this.html = this.editor.getHTML()
        this.json = this.editor.getJSON()

        this.editor.on('update', () => {
            this.html = this.editor.getHTML()
            this.json = this.editor.getJSON()
            this.$emit('update', this.html)
        })
    },
    beforeUnmount() {
        this.editor.destroy()
    },
    methods: {
        hasClass(element, clsName) {
            return (' ' + element.className + ' ').indexOf(' ' + clsName + ' ') > -1
        },
    },
}
</script>

<style lang="sass" scoped>
@import '../src/assets/css/additional-styles/editor/main.scss'
</style>
<style>
/* set */
.ProseMirror {
    min-height: 300px;
    max-height: 800px;
    overflow: scroll;
}
</style>
