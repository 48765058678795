<template>
    <div class="p-2 flex flex-col gap-3">
        <div class="md:grid grid-cols-3 items-center gap-3">
            <div class="flex items-center gap-3 col-span-2">
                <i class="fa-solid fa-link text-lg"></i>
                <span class="font-bold text-lg">
                    {{ $t('parameters.masterData.areas.title') }}
                </span>
                <button
                v-permission="'parameters_master_data_create_areas'"
                @click="openNewArea"
                class="btn bg-sky-500 text-white"
                >
                    <i class="fa-solid fa-plus"></i>
                </button>
            </div>
            <input
            v-model="search"
            type="text"
            :placeholder="$t('general.search') + '...'"
            class="form-input w-full rounded-md"
            >
        </div>
        <div>
            <div class="max-h-[300px] overflow-y-auto beauty-scroll">
                <DataTable
                :headers="dataTable.headers"
                :list="getSearchableList"
                @edit="openEditArea"
                @delete="openDeleteArea"
                >
                    <template v-slot:cell-contrast_to_color="cellContrastToColorProps">
                        <div class="flex flex-col w-full items-center">
                            <div
                            class="h-4 w-2/3 rounded-lg"
                            :style="{
                                'background-color': cellContrastToColorProps.item.contrast_to_color
                            }">

                            </div>
                        </div>
                    </template>
                    <template v-slot:cell-color="cellColorProps">
                        <div class="flex flex-col w-full items-center">
                            <div
                            class="h-4 w-2/3 rounded-lg"
                            :style="{
                                'background-color': cellColorProps.item.color
                            }">

                            </div>
                        </div>
                    </template>
                    <template v-slot:cell-status="cellStatusProps">
                        <div :class="[
                            'w-full flex flex-col items-center',
                            (cellStatusProps.item.status) ? 'text-green-500' : 'text-red-500'
                        ]">
                            <div class="flex items-center gap-1">
                                <i
                                class="fa-solid fa-power-off"></i>
                                {{ $t((cellStatusProps.item.status) ? 'status.active' : 'status.inactive') }}
                            </div>
                        </div>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>

    <ModalDelete
    :backdrop="true"
    :id="currentArea.id"
    :is-open="deleteAreaModalOpen"
    :title="$t('parameters.masterData.areas.delete.confirmation.title')"
    :description="''"
    @closed="deleteAreaModalOpen = false"
    @delete="deleteArea"
    />

    <ModalAreas
    :is-open="newAreaModalOpen"
    :is-edit="isAreaEdit"
    :current-area="currentArea"
    :saving="saving"
    @closed="modalAreaClose"
    @submit-data="submitData"
    />
</template>
<script>
import DataTable from '@/components/DataTable.vue'
import ModalAreas from '@/partials/parameters/master-data/areas/ModalAreas.vue'
import ModalDelete from '@/components/ModalDelete.vue'

export default {
    components: {
        DataTable,
        ModalAreas,
        ModalDelete
    },
    emits: ['reload'],
    props: {
        list: {
            type: Array,
            default: [],
        },
        toast: {
            type: Function,
            default: () => {}
        }
    },
    data: function () {
        return {
            newAreaModalOpen: false,
            deleteAreaModalOpen: false,
            isAreaEdit: false,
            currentArea: {},
            saving: false,
            search: null,
            dataTable: {
                headers: [
                    {
                        id: 'name',
                        label: this.$t('parameters.masterData.common.table.headers.name'),
                        column: {
                            key: 'name',
                        },
                    },
                    {
                        id: 'description',
                        label: this.$t('parameters.masterData.common.table.headers.description'),
                        column: {
                            key: 'description',
                        },
                    },
                    {
                        id: 'color',
                        label: this.$t('parameters.masterData.common.table.headers.color'),
                        column: {
                            key: 'color',
                        },
                        isSlot: true,
                    },
                    {
                        id: 'contrast_to_color',
                        label: this.$t('parameters.masterData.common.table.headers.contrastToColor'),
                        column: {
                            key: 'contrast_to_color',
                        },
                        isSlot: true,
                    },
                    {
                        id: 'created_at',
                        label: this.$t('parameters.masterData.common.table.headers.createdAt'),
                        column: {
                            funct: (value) => this.$filters.newDayjs(value).format('DD MMM YYYY - HH:mm'),
                            key: 'created_at',
                        }
                    },
                    {
                        id: 'status',
                        label: this.$t('parameters.masterData.common.table.headers.status'),
                        column: {
                            funct: (value) => value ? this.$t('status.active') : this.$t('status.inactive'),
                            key: 'status',
                        },
                        isSlot: true,
                    },
                    {
                        id: 'actions',
                        label: '',
                        column: {
                            key: '',
                            action: {
                                edit: {
                                    permission: 'parameters_master_data_edit_areas'
                                },
                                delete: {
                                    permission: 'parameters_master_data_delete_areas'
                                }
                            }
                        }
                    }
                ]
            }
        }
    },
    methods: {
        modalAreaClose () {
            this.newAreaModalOpen = false
        },
        openNewArea () {
            this.newAreaModalOpen = true
            this.isAreaEdit = false
        },
        openEditArea (event) {
            this.newAreaModalOpen = true
            this.currentArea = event
            this.isAreaEdit = true
        },
        openDeleteArea (event) {
            this.deleteAreaModalOpen = true
            this.currentArea = event
        },
        submitData (event) {
            if (event.id) {
                this.update(event)
            } else {
                this.create(event)
            }
        },
        create (event) {
            if (!this.saving) {
                this.saving = true
                this.$store.dispatch('caseareas/create', event).then(response => {
                    if (response.data) {
                        this.$emit('reload');
                        this.newAreaModalOpen = false
                        this.currentArea = {}
                        this.toast.success(this.$t('parameters.masterData.areas.successCreated'))
                    }
                    this.saving = false
                }).catch(error => {
                    this.toast.error(this.$methods.extractErrorResponse(error, this.$t))
                    this.saving = false
                })
            }
        },
        update (event) {
            const formData = JSON.parse(JSON.stringify(event))
            formData.id = this.$methods.encrypt(event.id);
            if (!this.saving) {
                this.saving = true
                this.$store.dispatch('caseareas/update', formData).then(response => {
                    if (response.data) {
                        this.$emit('reload');
                        this.newAreaModalOpen = false
                        this.currentArea = {}
                        this.toast.success(this.$t('parameters.masterData.areas.successUpdated'))
                    }
                    this.saving = false
                }).catch(error => {
                    this.toast.error(this.$methods.extractErrorResponse(error, this.$t))
                    this.saving = false
                })
            }
        },
        deleteArea (event) {
            this.$store.dispatch('caseareas/delete', { id: this.$methods.encrypt(event.id) }).then(response => {
                if (response.data) {
                    this.$emit('reload');
                    this.currentArea = {}
                    this.deleteAreaModalOpen = false
                    this.toast.success(this.$t('parameters.masterData.areas.successDeleted'))
                }
                this.saving = false
            }).catch(error => {
                this.toast.error(this.$methods.extractErrorResponse(error, this.$t))
                this.saving = false
            })
        }
    },
    computed: {
        getSearchableList () {
            return this.list.filter(
                item => Object.values(item).some(elem => {
                    const that = elem ? elem : ''
                    const search = this.search ? this.search : ''
                    return that.toString().toLowerCase().includes(search.toLowerCase())
                })
            )
        }
    }
}

</script>