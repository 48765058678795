<template>
    <div class="flex h-screen overflow-hidden">
        <!-- Sidebar -->
        <Sidebar :sidebar-open="sidebarOpen" @close-sidebar="sidebarOpen = false" />

        <!-- Content area -->
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <main>
                <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
                    <div class="flex justify-between">
                        <div class="flex w-full flex-col">
                            <h1 class="text-2xl font-bold text-black">
                                {{ $t('admin.parameters.title') }}
                            </h1>
                            <p>{{ $t('admin.parameters.description') }}</p>
                        </div>
                        <div class="mt-4 flex w-full items-center justify-end">
                            <DropdownLanguages class="mr-4"/>
                            <DropdownNotifications align="right" class="md:mr-4" wrapper-class="bg-white rounded-lg" />
                            <DropdownProfile align="right" mode="aesthetic" />
                        </div>
                    </div>
                    <div
                    class="my-12 w-full flex flex-col gap-8"
                    >
                        <div class="w-full flex flex-col md:grid md:grid-cols-2 gap-3">
                            <div
                            v-for="(item, index) in menu"
                            :key="index"
                            v-permission="item.permission"
                            @click="changeMenu(item)"
                            :class="[
                                'flex items-center justify-center bg-white p-4 rounded-lg shadow-lg',
                                'hover:bg-sky-500 hover:text-white transition-all cursor-pointer',
                                {
                                    '!bg-sky-500 text-white': menuActive === item.key
                                }
                            ]">
                                {{ item.label }}
                            </div>
                        </div>
                        <div
                        v-permission="'parameters_list'"
                        class="bg-white shadow-lg rounded-lg px-3 py-5 md:px-6"
                        >
                            <div v-permission="'parameters_list_general'">
                                <General
                                v-if="menuActive === 'general'"
                                :toast="toast"
                                />
                            </div>
                            <div v-permission="'parameters_master_data_list'">
                                <MasterData :toast="toast" v-if="menuActive === 'masterData'"/>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import Sidebar from '@/partials/Sidebar.vue'
import DropdownNotifications from '@/components/DropdownNotifications.vue'
import DropdownProfile from '@/components/DropdownProfile.vue'
import DropdownLanguages from '@/components/DropdownLanguages.vue'
import General from '@/partials/parameters/General.vue'
import MasterData from '@/partials/parameters/MasterData.vue'
import { useToast } from 'vue-toastification'

export default {
    name: 'AdminParametersPage',
    components: {
        Sidebar,
        DropdownNotifications,
        DropdownProfile,
        DropdownLanguages,
        General,
        MasterData,
    },
    mixins: [],
    data: function () {
        return {
            sidebarOpen: false,
            menu: [
                { key: 'general', label: this.$t('parameters.menu.general'), permission: 'parameters_list_general' },
                { key: 'masterData', label: this.$t('parameters.menu.masterData'), permission: 'parameters_master_data_list' }
            ],
            menuActive: 'general',
            toast: null,
        }
    },
    mounted() {
        if (this.$methods.hasPermissions(['parameters_list_general'])) {
            this.menuActive = 'general'
        } else if (this.$methods.hasPermissions(['parameters_master_data_list'])) {
            this.menuActive = 'masterData'
        }
        this.toast = useToast()
    },
    methods: {
        changeMenu (item) {
            this.menuActive = item.key
        }
    },
}
</script>
