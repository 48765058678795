<template>
    <ul v-if="extractUser" class="mb-8 -ml-px flex flex-wrap justify-center -space-x-3 sm:mb-0 sm:justify-start">
        <li v-for="item in parties" :key="item.id">
            <div class="flex items-center gap-2">
                <Tooltip bg="dark" :position="tooltipPosition" :custom-image="true"
                    :image="item.user.avatar !== null ? item.user.avatar : defaultImage">
                    <div v-if="item.user.first_name && item.user.last_name" class="text-xs text-slate-200">
                        {{ getName(item.user) }}
                    </div>
                    <div v-else class="text-xs text-slate-200">
                        {{ getName(item.user, 'name') }}
                    </div>
                </Tooltip>
                <span
                class="text-sm text-slate-500 italic"
                v-if="aditionalText"
                >
                    {{ item[aditionalText] }}
                </span>
            </div>
        </li>
    </ul>
    <ul v-else class="mb-8 -ml-px flex flex-wrap justify-center -space-x-3 sm:mb-0 sm:justify-start">
        <li v-for="item in parties" :key="item.id">
            <router-link class="block" to="#0">
                <Tooltip bg="dark" :position="tooltipPosition" :custom-image="true"
                    :image="item.avatar !== null ? item.avatar : defaultImage">
                    <div v-if="item.first_name && item.last_name" class="text-xs text-slate-200">
                        {{ getName(item) }}
                    </div>
                    <div v-else class="text-xs text-slate-200">
                        {{ getName(item, 'name') }}
                    </div>
                </Tooltip>
            </router-link>
        </li>
    </ul>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import Tooltip from '@/components/Tooltip.vue'

export default {
    name: 'AvatarsComponent',
    components: {
        Tooltip,
    },
    mixins: [globalMixin],
    inject: ['defaultImage'],
    props: {
        parties: {
            type: Array,
            default: () => {
                return []
            },
        },
        extractUser: {
            type: Boolean,
            default: false,
        },
        tooltipPosition: {
            type: String,
            default: 'top',
        },
        aditionalText: {
            type: String,
            default: '',
        }
    },
}
</script>
