export default {
	"appName": "Redek",
	"noData": "No hay datos disponibles para este momento",
	"noPendingAction": "No hay acciones pendientes",
	"noRecentAction": "Todavía no has hecho nada",
	"noActivities": "No hay actividades",
	"hourAgo": "horas atrás",
	"you": "(Tú)",
	"updated": "Actualizado",
	"yes": "Si",
	"no": "No",
	"system": {
		"documents": {
			"case_x": {
				"case_record": {
					"this": "Expediente del caso",
					"requested_documents": {
						"this": "Documentos solicitados"
					},
					"reassignments": {
						"this": "Adjuntos para reasignaciones de las partes"
					},
                    "timeline": {
						"this": "Línea de tiempo"
					},
					"initial_stage": {
						"this": "Etapa inicial"
					},
					"negotiation_stage": {
						"this": "Etapa de negociación"
					},
					"mediation_stage": {
						"this": "Etapa de mediación"
					},
					"arbitration_stage": {
						"this": "Etapa de arbitraje"
					}
				},
				"case_call_recordings": {
					"this": "Grabaciones de reuniones"
				}
			}
		}
	},
	"language": {
		"languages": "Idiomas",
		"change": "Cambiar idioma",
		"id": "ID",
		"en": "EN",
		"pt": "PT",
		"es": "ES",
		"bahasa": "Indonesia",
		"english": "Ingles",
		"portuguese": "Portugues",
		"spanish": "Español"
	},
	"menu": {
		"dashboard": "Tablero",
        "parameters": "Parámetros",
		"caseStage": "Etapa del caso",
		"privateNotebook": "Cuaderno privado",
		"communication": {
			"title": "Comunicación",
			"chats": "Chats"
		},
		"documents": "Documentos",
		"timeline": "Cronología",
		"calendar": "Calendario",
		"caseDetails": "Detalles del caso",
		"finalDecision": "Decisión final",
		"user": "Usuarios",
		"security": {
			"title": "Seguridad",
			"users": "Usuarios",
			"roles": "Roles",
			"permissions": "Permisos"
		},
		"cases": {
			"title": "Casos",
			"allCase": "Todos los casos",
			"pendingCase": "Casos pendiente",
			"ongoingCase": "Casos en curso",
			"settledCase": "Casos resuelto"
		},
		"inbox": {
			"title": "Bandeja de entrada",
			"allInbox": "Todos los correos electrónicos recibidos",
			"unanswered": "Sin responder",
			"answered": "Respondido",
			"archived": "Archivado"
		},
		"logout": "Cerrar sesión"
	},
	"action": {
		"back": "Volver",
		"previous": "Anterior",
		"next": "Siguiente",
		"finish": "Finalizar",
		"letsGo": "¡Vamos!",
		"startODR": "Iniciar ODR",
		"accept": "Aceptar",
		"decline": "Rechazar"
	},
	"activity": {
		"all": "Toda la actividad",
		"external": "Externo",
		"mail": "Actividad de correo electrónico",
		"stage": "Actividad de etapa"
	},
	"about": "Sobre",
	"edit": "Editar",
	"traceability": "Trazabilidad",
	"remove": "Eliminar",
    "selected": "Seleccionado",
    "selectedParties": "Partes seleccionadas",
	"forms": {
		"uploadFile": "Subir archivo",
		"dragAndDrop": "",
		"or": "",
		"browseFiles": "Explorar archivos"
	},
	"general": {
		"greeting": "¡Bienvenido/a!",
		"hello": "Hola",
		"share": "Compartir",
		"tnc": "Términos y Condiciones",
		"privacyPolicy": "Política de privacidad",
		"optional": "Opcional",
        "user": "Usuario",
		"advocate": "Defensor",
		"mediator": "Mediador",
		"arbitrator": "Árbitro",
		"bankLawyer": "Abogado bancario",
		"totalParties": "%{total} Partes",
		"totalAttachments": "%{total} Adjuntos",
		"loading": "Cargando....",
		"pronouns": {
			"you": "Tú"
		},
		"agreement": "Acuerdo",
		"download": "Descargar",
		"enabled": "Habilitado",
		"disabled": "Deshabilitado",
		"meeting": "Reunión",
        "select": "Seleccionar",
        "columns": {
            "name": "Nombre",
            "ratings": "Calificación",
            "case_handled": "Casos manejados",
            "ongoing_cases": "Casos en proceso"
        },
        "all": "Todo",
        "search": "Buscar",
        "records": "Registros",
	},
	"info": {
		"dataNotFound": "Datos no encontrados",
		"loginGreeting": "Unos clics más para iniciar sesión en tu cuenta.",
		"loginGreetingDetail": "Administra tus cuentas en un solo lugar",
		"currentVersion": "Panel de versión actual",
		"pageMissing": "¡Ups! Esta página no se encuentra",
		"pageMissingInstruction": "Puede que hayas escrito la dirección incorrectamente o que la página se haya movido.",
		"successResendOTP": "Reenvío exitoso del OTP"
	},
	"confirmation": {
		"logout": "¿Estás seguro/a de que quieres cerrar sesión?"
	},
	"button": {
		"submit": "Enviar",
		"login": "Iniciar sesión",
		"signUp": "Registrarse",
		"logout": "Cerrar sesión",
		"cancel": "Cancelar",
		"register": "Registrar",
		"sendEmail": "Enviar correo electrónico",
		"sendOTP": "Enviar OTP",
		"resetPassword": "Restablecer contraseña",
		"forgotPassword": "¿Olvidaste tu contraseña?",
		"loginInstead": "Iniciar sesión en su lugar",
		"change": "Cambiar",
		"saveChanges": "Guardar cambios",
		"connect": "Conectar",
		"connected": "Conectado",
		"backToHome": "Volver a inicio",
		"delete": "Eliminar",
		"remove": "Quitar",
		"open": "Abrir",
		"assign": "Asignar",
		"review": "Revisar",
		"resolveTheCase": "Resolver el caso",
		"confirm": "Confirmar",
		"pick": "Elegir %{role}",
		"save": "Guardar",
		"accept": "Aceptar",
		"reject": "Rechazar",
		"shareToThisEmail": "Compartir a este correo electrónico",
		"shareToBankLawyer": "Compartir con el abogado del banco",
		"inspectCase": "Inspeccionar caso",
		"endInspect": "Finalizar inspección",
		"timerExtension": "Solicitar una prórroga de tiempo",
		"response": "Respuesta",
		"import": "Importar",
		"enable": "Habilitar",
		"disable": "Deshabilitar"
	},
	"badge": {
		"accepted": "Aceptado",
		"rejected": "Rechazado",
		"pending": "Pendiente"
	},
	"formInfo": {
		"notLogin": "No has iniciado sesión",
		"registerSuccess": "Te has registrado exitosamente",
		"registerFailed": "Error en el registro",
		"loginSuccess": "Inicio de sesión exitoso",
		"loginFailed": "Fallo en el inicio de sesión",
		"saveChangesSuccess": "Cambios guardados exitosamente",
		"saveChangesFailed": "No se pudieron guardar los cambios",
		"loginDesc": "Ingresa tu correo electrónico y contraseña",
		"loginCheck": "Al registrarte, aceptas nuestros"
	},
	"formLabel": {
		"name": "Nombre",
		"firstName": "Nombre",
		"lastName": "Apellido",
		"secondName": "Segundo nombre",
		"fullName": "Nombre completo",
		"bussinessName": "Nombre de la empresa",
		"email": "Dirección de correo electrónico",
		"phone": "Número de teléfono",
		"currentPassword": "Contraseña actual",
		"password": "Contraseña",
		"passwordConfirmation": "Confirmar contraseña",
		"oldPassword": "Contraseña antigua",
		"newPassword": "Nueva contraseña",
		"newPasswordConfirmation": "Confirmación de nueva contraseña",
		"accessRight": "Derecho de acceso",
		"pin": "OTP",
		"description": "Descripción",
		"website": "Sitio web",
		"province": "Provincia",
		"city": "Ciudad",
		"district": "Distrito",
		"village": "Pueblo",
		"postalCode": "Código postal",
		"address": "Dirección",
		"select": {
			"province": "Seleccionar provincia",
			"city": "Seleccionar ciudad",
			"district": "Seleccionar distrito",
			"village": "Seleccionar pueblo",
			"none": "Ninguno"
		},
		"sortBy": "Ordenar por :"
	},
	"formInput": {
		"enterName": "Por favor introduce tu nombre",
		"enterFirstName": "Por favor ingresa tu primer nombre",
		"enterLastName": "Por favor ingresa tu apellido",
		"enterSecondName": "Por favor ingresa tu segundo nombre",
		"enterFullName": "Por favor ingresa tu nombre completo",
		"enterBussinessName": "Por favor ingresa el nombre de tu negocio",
		"enterDescription": "Por favor ingresa la descripción",
		"enterWebsite": "Por favor ingresa el sitio web",
		"enterEmail": "Por favor ingresa tu correo electrónico",
		"enterPhone": "Por favor ingresa tu número de teléfono",
		"enterPassword": "Por favor ingresa tu contraseña",
		"enterConfirmPassword": "Por favor ingresa tu contraseña",
		"enterCurrentPassword": "Por favor ingresa tu contraseña actual",
		"enterNewPassword": "Por favor ingresa tu nueva contraseña",
		"enterNewConfirmPassword": "Por favor ingresa tu nueva contraseña",
		"enterPIN": "Por favor ingresa tu PIN",
		"enterAddress": "Por favor ingresa tu dirección",
		"enterPostalCode": "Por favor ingresa el código posta"
	},
	"formError": {
		"name": {
			"required": "El nombre es obligatorio"
		},
		"fullName": {
			"required": "Se requiere el nombre completo"
		},
		"bussinessName": {
			"required": "Se requiere el nombre de la empresa"
		},
		"tenantName": {
			"required": "Tenant Name is required"
		},
		"email": {
			"required": "El correo electrónico es obligatorio",
			"validEmail": "El correo electrónico debe ser una dirección de correo válida"
		},
		"phone": {
			"required": "Se requiere el número de teléfono"
		},
		"pin": {
			"required": "Se requiere el PIN"
		},
		"address": {
			"required": "Se requiere la dirección"
		},
		"postalCode": {
			"required": "Se requiere el código postal"
		},
		"password": {
			"required": "Se requiere la contraseña",
			"minLength": "La contraseña debe tener al menos 6 caracteres",
			"alphaNumeric": "La contraseña solo permite caracteres alfanuméricos y especiales"
		},
		"wrongEmailOrPassword": "Correo electrónico o contraseña incorrectos",
		"validEmailAddress": "Este campo debe ser una dirección de correo electrónico válida",
		"validPhoneNumber": "Por favor ingresa un número de WhatsApp válido, con un mínimo de 10 dígitos",
		"fieldAlphabet": "debe contener solo caracteres alfabéticos",
		"fieldAlphaNumeric": "debe contener solo caracteres alfanuméricos",
		"fieldNumeric": "debe contener solo caracteres numéricos"
	},
	"login": {
		"title": "Iniciar sesión",
		"already": "Si ya tienes una cuenta, por favor completa el formulario requerido a continuación. Si aún no tienes una cuenta",
		"signUp": "Registro aquí",
		"enterOTP": "Ingresar OTP",
		"weSentOTPByPhone": "Ingrese el código que le hemos enviado a través de su teléfono %{phone}",
		"weSentOTP": "Ingrese el código que le hemos enviado por correo electrónico %{email}",
		"didNotReceive": "¿No recibiste el OTP?",
		"resendCode": "Volver a enviar código",
		"doesntHaveRequiredPermissions": "No tienes los permisos necesarios para acceder",
		"loginSuccessfully": "Has iniciado sesión correctamente"
	},
	"register": {
		"title": "Registrarse",
		"already": "¿Ya tienes una cuenta?",
		"signIn": "Iniciar sesión"
	},
	"forgotPassword": {
		"title": "¿Olvidaste tu contraseña?",
		"description": "Por favor ingresa tu dirección de correo electrónico para restablecer la contraseña."
	},
	"resetPassword": {
		"title": "Restablecer contraseña",
		"description": "Por favor ingresa tu nueva contraseña",
		"successEmailSend": "Te hemos enviado un correo electrónico con instrucciones para restablecer tu contraseña"
	},
	"verificationOTP": {
		"wait": "Espera %{timer}s para reenviar el código"
	},
	"case": {
		"title": "Selección de caso",
		"caseSelection": "Selección de caso",
		"allCases": "Todos los casos",
		"onPrepare": "En preparación",
		"onGoing": "En curso",
		"solved": "Resuelto",
		"yourCase": "Tus casos",
		"shareToYou": "Compartir contigo",
		"parties": "Partes",
		"newComplaint": "Queja nueva",
		"applicationForm": "Formulario de solicitud",
		"advocateSelect": "Selección de abogado",
		"reviewApplication": "Revisión de solicitud",
		"advocateRequirement": "Requisito de abogado",
		"advocateSelection": "Selección de abogado",
		"caseSubmitted": "Caso enviado",
		"highUrgency": "Urgencia alta",
		"mediumUrgency": "Urgencia media",
		"lowUrgency": "Urgencia baja",
		"cannotEnabledToAccess": "No tienes permiso para acceder a este caso",
		"import": {
			"modal": {
				"title": "Importar casos",
				"description": "Proporciona el archivo con los datos de los casos que se importarán.",
				"template": {
					"alert": "Plantilla:"
				},
				"file": {
					"label": "Archivo"
				},
				"pleaseSelectFile": "Por favor selecciona el archivo",
				"result": {
					"failed": {
						"title": "Importación del caso cancelado debido a fallas en los datos proporcionados.",
						"information1": "La fila a la que corresponden estos errores es la fila",
						"information2": "Por favor revísalos e intenta nuevamente."
					}
				},
				"params": {
					"alert": "Estas son las áreas y tipos de casos en el sistema; por favor, tenlas en cuenta al completar la información en la hoja de cálculo.",
					"caseAreas": "Áreas de casos",
					"caseTypes": "Tipos de casos"
				}
			},
			"success": "Los casos se importaron exitosamente"
		},
		"dueTime": "Tiempo debido de la etapa",
		"setDateTime": "Establecer fecha y hora",
		"backToCase": "Volver al caso",
		"concludedCase": "Caso concluido",
		"canWeHelpYou": "¿Podemos ayudarte hoy?",
		"shareConcluded": "Compartir caso concluido",
		"selectBankLawyer": "Seleccionar abogado bancario",
		"selectBankLawyerDesc": "Por favor selecciona un abogado bancario para compartir el caso",
		"stages": {
			"on_prepare": "En preparación",
			"negotiation": "Negociación",
			"mediation": "Mediación",
			"arbitration": "Arbitraje",
			"solved": "Resuelto"
		},
		"partyReassignment": {
			"label": "Motivo de reasignación",
			"placeholder": "Describe el motivo de la reasignación",
			"error": "Por favor especifica el motivo para reasignar la parte involucrada",
			"attachment": {
				"label": "Adjunto para reasignación"
			}
		},
        "delete": {
            "success": "Caso eliminado exitosamente"
        }
	},
	"newCase": {
		"how": "¿Cómo desea presentar su queja?",
		"doItMySelf": "Lo haré yo mismo",
		"iNeedAssistance": "Necesito asistencia",
		"title": "Nueva queja",
		"description": "Por favor describe tu caso llenando el formulario a continuación",
		"caseName": "Por favor completa el nombre de tu queja",
		"caseNamePlaceholder": "Nombre del caso",
		"caseFrom": "¿Contra qué banco deseas presentar la queja?",
		"caseWhen": "¿Cuándo ocurrió el caso?",
		"moneyIncluded": "¿Este caso involucra dinero?",
		"caseWhenPlaceholder": "Tiempo",
		"caseHowMuch": "¿Cuánto dinero deseas reclamar?",
		"caseHowMuchPlaceholder": "Cantidad de dinero",
		"caseRequest": "¿Cuál es tu solicitud?",
		"caseRequestPlaceholder": "Tu solicitud",
		"bySubmit": "Al enviar este formulario, aceptas ser contactado con respecto a tu solicitud y confirmas tu acuerdo con nuestros términos y condiciones",
		"and": "y",
		"terms": "Términos de uso",
		"privacyPolicy": "Política de privacidad",
		"pleaseCheckAggreement": "Por favor revisa el acuerdo",
        "pleaseSelectCurrency": "Por favor selecciona la moneda",
		"pleaseSelectAdvocate": "Por favor selecciona un abogado",
		"pleaseSelectMediator": "Por favor selecciona un mediador",
		"pleaseSelectBankLawyer": "Por favor selecciona un abogado bancario",
		"failedGetCurrent": "No se pudo obtener el caso actual",
		"typeOfPart": "Tipo de parte",
		"caseArea": "Área del caso",
		"caseDescription": "Por favor describe tu reclamación",
		"caseDescriptionPlaceholder": "Descripción",
		"caseNotification": "¿Cómo te gustaría recibir notificaciones?",
		"caseAcceptNotifyCaseEmail": "Me gustaría recibir notificaciones por correo electrónico.",
		"email": "A través de correo electrónico",
		"system": "A través del sistema",
		"odrDesc": "Descripción del ODR",
		"amountOfProcess": "Monto del proceso",
		"requestOfProcess": "Solicitud del proceso",
		"estimatedStartDate": "Fecha de inicio estimada",
		"descriptionOnDemands": "Descripción de las demandas",
		"partOfProcess": "Parte del Proceso",
		"partOfProcessDesc": "Resumen sobre su caso",
		"notificationMethod": "Método de Notificación",
		"applicationSummary": "Resumen de la Aplicación",
		"applicationSummaryDesc": "Por favor revisa tu solicitud antes de enviarla",
		"haveRequestQuestion": "¿Tienes una solicitud?",
		"preCreated": {
			"applicationForm": "Crear caso",
			"description": "Por favor describe el caso llenando el formulario a continuación",
			"caseName": "Por favor completa el nombre de la queja",
			"caseFrom": "¿A qué banco se refiere la queja?",
			"button": "Pre-creado",
			"caseDescription": "Por favor describe la solicitud",
			"moneyIncluded": "¿Este caso incluyó dinero?",
			"caseHowMuch": "¿Cuánto dinero solicitó el reclamante?",
			"haveRequestQuestion": "¿El reclamante hizo una solicitud?",
			"caseRequest": "¿Cuál fue la solicitud?",
			"caseRequestPlaceholder": "La solicitud",
            "negotiationStartTime": "Fecha inicio negociación",
            "negotiationEndTime": "Fecha fin negociación",
            "mediationStartTime": "Fecha inicio mediación",
            "mediationEndTime": "Fecha fin mediación",
            "arbitrationStartTime": "Fecha inicio arbitraje",
            "arbitrationEndTime": "Fecha fin arbitraje",
            "solved": {
                "dateSolved": "Fecha de la solución",
                "result": "Resultado de la solución",
                "notes": "Notas de la solución",
                "preCSolvedNotesPlaceholder": "Notas",
                "part": "Parte que resolvió el caso",
            },
			"claimant": {
				"label": "Reclamante",
				"placeholder": "Seleccionar al usuario reclamante"
			},
			"stage": {
				"label": "Etapa actual"
			},
			"stageCaseResolved": {
				"label": "Etapa del caso resuelto"
			},
			"partySelection": {
				"modal": {
					"title": "Selección de parte",
					"description": "Por favor selecciona %{party_type}"
				}
			},
			"stages": {
				"initial": "En preparación",
				"negotiation": "Negociación",
				"mediation": "Mediación",
				"arbitration": "Arbitraje",
				"solved": "Caso resuelto"
			},
			"choose": {
				"advocate": {
					"label": "Selección del abogado",
					"button": "Elige un abogado"
				},
				"bank_lawyer": {
					"label": "Selección del abogado del banco",
					"button": "Elige al abogado del banco"
				},
				"mediator": {
					"label": "Selección del mediador",
					"button": "Elige al mediador"
				},
				"arbitrator": {
					"label": "Selección del árbitro",
					"button": "Elige al árbitro"
				}
			},
			"errors": {
				"advocate": "Debes elegir a un abogado",
				"bank_lawyer": "Debes elegir a un abogado del banco",
				"mediator": "Debes elegir a un mediador",
				"arbitrator": "Debes elegir a un árbitro",
                "stageCaseSolved": "Debes elegir la etapa en la que se resolvió el caso",
                "claimant": "Debes elegir el reclamante",
                "stage": "Debes elegir la etapa",
			}
		}
	},
	"advocateRequirement": {
		"title": "Requisitos del abogado",
		"description": "¿Cómo quieres elegir a tu abogado?",
		"letRedek": "Dejar que REDEK elija uno por mí",
		"pickOne": "Elegiré uno yo mismo"
	},
	"advocateSelection": {
		"title": "Selección de abogado",
		"description": "Por favor elige a tu abogado para este caso",
		"searchPlaceholder": "Buscar abogado",
		"reassignment": {
			"add": "Reasignación",
			"description": "Por favor elige al abogado para reasignar en este caso"
		}
	},
	"caseSubmitted": {
		"title": "Caso presentado",
		"thanks": "¡Gracias! ",
		"description": "Tu caso ha sido enviado para asignación.",
		"description2": "Te informaremos cuando esté listo",
		"return": "Volver a la selección de casos"
	},
	"mediatorRequirement": {
		"title": "Requisitos del mediador",
		"description": "¿Cómo deseas elegir a tu mediador?",
		"letRedek": "Permitir que REDEK elija uno por mí",
		"pickOne": "Elegiré uno por mí mismo"
	},
	"mediatorSelection": {
		"title": "Selección de mediador",
		"description": "Por favor elige a tu mediador para este caso",
		"searchPlaceholder": "Buscar mediador"
	},
	"mediatorSubmitted": {
		"title": "Mediador enviado",
		"thanks": "¡Gracias!",
		"description": "Has enviado a tu mediador preferido.",
		"description2": "Serás notificado cuando la siguiente etapa haya comenzado",
		"return": "Volver al panel de casos"
	},
	"arbitratorRequirement": {
		"title": "Requisito de árbitro",
		"description": "¿Cómo deseas seleccionar a tu árbitro?",
		"letRedek": "Deja que REDEK elija uno por mí",
		"pickOne": "Yo elegiré uno"
	},
	"arbitratorSelection": {
		"title": "Selección de árbitro",
		"description": "Por favor elige tu árbitro para este caso",
		"searchPlaceholder": "Buscar árbitro"
	},
	"arbitratorSubmitted": {
		"title": "Árbitro enviado",
		"thanks": "¡Gracias!",
		"description": "Has enviado tu árbitro preferido.",
		"description2": "Serás notificado cuando se inicie la siguiente etapa",
		"return": "Volver al panel de casos"
	},
	"bankLawyerSelection": {
		"title": "Selección de abogado del banco",
		"description": "Por favor elige el abogado del banco para este caso",
		"searchPlaceholder": "Buscar abogado del banco"
	},
	"dashboard": {
		"title": "Tablero",
		"description": "Atajos rápidos a tus herramientas importantes",
		"showTimeline": "Mostrar cronología",
		"startedAt": "Comenzó en",
		"recentActivities": "Actividades recientes",
		"downloadPDF": "Descargar PDF",
		"sharePDF": "Compartir PDF",
		"semester": "Semestre %{semester}",
        "share": {
            "success": "Reporte de casos enviado exitosamente",
        },
	},
	"caseStage": {
		"title": "Etapas del caso",
		"description": "Detalles de la etapa del caso y tus acciones pendientes",
		"pendingOffer": "Ofertas pendientes:",
		"actionToDo": "Acción que necesitas realizar:",
		"onPrepareStage": "En Preparación",
		"onPrepareStageDesc": "Tu caso aún está en preparación. Te avisaremos una vez que esté asignado",
		"negotiationStage": "Negociación",
		"negotiationStageDesc": "En esta etapa, puedes negociar con el banco en un intento de resolver el caso",
		"participateNegotiationChat": "Participar en las conversaciones de ofertas de negociación",
		"inititateMediation": "Iniciar etapa de mediación",
		"inititateMediationDesc": "Durante la etapa de mediación, serás asistido por nuestro mediador.",
		"mediationStage": "Etapa de Mediación",
		"mediationStageDesc": "En esta etapa, serás asistido por nuestro mediador para identificar y evaluar opciones, así como para negociar un acuerdo para resolver tu disputa.",
		"participateMediationChat": "Participar en las conversaciones de mediación",
		"initiateMediationStage": "Iniciar Etapa de Mediación",
		"initiateMediationStageDesc": "Por favor espera la aprobación del Super Administrador para la etapa de mediación",
		"inititateArbitration": "Iniciar etapa de arbitraje",
		"inititateArbitrationDesc": "Solo el árbitro podrá tomar la decisión final. Todas las partes solo podrán presentar los documentos requeridos durante la siguiente etapa.",
		"arbitratorStage": "Etapa del árbitro",
		"arbitratorStageDesc": "En esta etapa, nuestro árbitro ayudará a ambas partes a llegar a una decisión final",
		"initiateArbitrationStage": "Iniciar etapa de arbitraje",
		"initiateArbitrationStageDesc": "Por favor espera la aprobación del Super Administrador para la etapa de arbitraje",
		"caseResolved": "Caso resuelto",
		"caseResolvedDesc": "Tu caso ha sido resuelto. Puedes acceder a la decisión final en la página de decisión final. ¡Gracias!",
		"resolveTheCase": "Resolver el caso",
		"resolve": "Resolver",
		"areYouSureToResolve": "¿Resolver el caso?",
		"resolveCaseDesc": "Se notificará a todas las partes y el caso se cerrará",
		"accessFinalDecision": "Acceder a la decisión final",
		"feelFreeReview": "¡Siéntete libre de dejar una reseña para tus asistentes!",
		"finalDecisionResult": "¿Cuál es el resultado de la disputa?",
		"resultOverAll": "Resolución global",
		"resultEveryGood": "Todo está bien",
		"disputeClosureNote": "Notas de cierre de la disputa",
		"resolveCase": {
            "result": {
                "total": "Solución total",
                "partial": "Solución parcial"
            },
			"offer": {
				"title": "Oferta aceptada"
			},
			"success": "Caso resuelto con éxito"
		},
		"successInitiate": "Iniciar etapa del caso exitosamente",
		"giveRating": "Dar calificación",
		"review": "Reseña",
		"writeReview": "Escribe tu reseña",
		"leaveReview": "Dejar reseña",
		"responseToTheClaim": "Respuesta a la reclamación",
		"responseToTheClaimDesc": "Por favor describe tu respuesta completando el formulario a continuación",
		"doesTheOfferIncludeMoney": "¿La oferta incluye dinero?",
		"waitingBankLawyer": "Esperando asignación del abogado del banco. Te informaremos una vez que esté asignado.",
		"waitingMediator": "Esperando asignación del mediador. Te informaremos una vez que esté asignado",
		"makeOffer": "Hacer oferta",
		"thisWillBeOfferTo": "Esto se ofrecerá a:",
		"complaintStage": "Queja",
		"complaintStageDesc": "En esta etapa, es probable que se requiera cargar una versión final de la queja presentada, evidencia de que la queja fue recibida y aprobada por el juez.",
		"initiateEvidenceStage": "Iniciar etapa de evidencia",
		"initiateEvidenceStageDesc": "Por favor espera la aprobación del Super Administrador para la etapa de evidencia",
		"evidenceStage": "Evidencia",
		"evidenceStageDesc": "En esta etapa, recibirás información del juez relacionada con la gestión general del caso.",
		"initiateHearingStage": "Iniciar etapa de audiencia",
		"initiateHearingStageDesc": "Por favor espere la aprobación del Superadministrador para la etapa de audiencia",
		"hearingStage": "Audiencia",
		"hearingStageDesc": "En esta etapa, el juez está tomando las conclusiones finales sobre el caso por parte de las partes involucradas.",
		"judgementStage": "Sentencia y apelación",
		"judgementStageDesc": "En esta etapa, el juez ha tomado la decisión final sobre la queja.",
		"caseConcluded": "Caso concluido",
		"mediator": {
			"makeFirstOffer": "Haz la primera oferta a las partes",
			"makeFirstOfferDesc": "Por favor describe tu primera oferta completando el formulario a continuación"
		},
		"arbitration": {
			"participateInChat": "Participar en el chat de arbitraje",
            "generateDocument": "Generar documento"
		},
		"chats": {
			"closed": {
				"reasons": {
					"negotiation": "Este chat fue cerrado porque %{user} solicitó mediación.",
					"mediation": "Este chat fue cerrado porque %{user} solicitó arbitraje.",
					"caseSolved": "Este chat fue cerrado porque el caso fue resuelto.",
                    "deadline": "Este chat fue cerrado porque el tiempo límite de la etapa expiró.",
                    "imported": "Este chat fue cerrado porque el caso fue importado y la etapa ya expiró.",
                    "precreated": "Este chat fue cerrado porque el caso fue pre-creado y la etapa ya expiró.",
				}
			},
			"makeOffersUnabled": {
				"reasons": {
					"canBeResolved": "Dado que hay una oferta aceptada, el caso puede ser resuelto."
				}
			},
			"doFromHere": "Realízalo desde aquí"
		},
		"doReview": {
            "leavingReviewTo": "Calificando a",
			"success": "Revisión completada exitosamente"
		}
	},
	"timerExtension": {
		"title": "¿Solicitar una extensión de tiempo?",
		"adminTitle": "¿Estás seguro de confirmar la extensión de tiempo?",
		"adminRejectTitle": "¿Estás seguro de rechazar la solicitud de extensión de tiempo?",
		"description": "El plazo de esta etapa del caso se extenderá por otros 10 días.",
		"remaining": "Tiempo restante para solicitar una extensión: %{remaining}",
		"dueDate": "Fecha límite: %{date}",
		"confirmTimerExtension": "Confirmar extensión de la fecha límite"
	},
	"notification": {
		"title": "Notificación",
		"description": "Ver notificaciones de esta plataforma",
		"searchPlaceholder": "Buscar notificación",
		"showAll": "Mostrar todo",
		"view": "Ver %{type}",
		"messages": {
			"case": {
				"view": "Ver caso",
				"assigned": {
					"title": "Asignación de caso",
					"adminAssignYou": "%{user} te ha asignado a un caso como %{party_type}",
					"adminAssign": "%{user} ha asignado a %{user_two} como %{party_type} en un caso",
					"userAdvocate": "%{user} te ha elegido como el abogado en un nuevo caso",
					"randomAdvocate": "Has sido seleccionado al azar para un nuevo caso"
				},
				"reassigned": {
					"adminAssign": "%{user} ha reasignado a %{user_two} como %{party_type} en un caso. Motivo: %{reason}"
				},
				"stage": {
					"title": "Cambio de etapa",
					"change": "Un caso ha cambiado a la etapa de %{stage}"
				},
                "initiate": {
                    "title": "Solicitud de cambio de etapa",
                    "subtitle": "%{user} ha solicitado que la etapa de %{stage} comience. Debes asignar la parte.",
                    "deadline": {
                        "title": "Cambio de etapa",
                        "subtitle": "La etapa de %{stage} debe iniciar para un caso debido a que el tiempo límite de la anterior expiró.",
                        "partsSubtitle": "La etapa de %{stage} va a iniciar para un caso debido a que el tiempo límite de la anterior expiró.",
                        "arbitration": {
                            "title": "La fecha límite de arbitraje expiró",
                            "subtitle": "La fecha límite para la etapa de arbitraje expiró y no realizaron acciones para resolver el caso.",
                        },
                    }
                },
			},
			"newCase": "Nuevo caso",
			"caseSubmitted": "%{user} ha presentado un nuevo caso",
			"stageRequested": "%{user} ha solicitado la etapa ${stage} para un caso",
			"stageSetted": "%{user}  comenzó la etapa %{stage}",
			"chats": {
				"view": "Abrir chat",
				"new": "%{user} ha creado un nuevo chat contigo",
				"offer": {
					"title": "Nueva oferta",
					"arrival": "Novedades sobre una oferta",
					"subtitle": "%{user} ha creado una nueva oferta en %{chat_name}",
					"accepted": "%{user} ha aceptado la oferta creada por %{user_two} en %{chat_name}",
					"rejected": "%{user} ha rechazado la oferta creada por %{user_two} en %{chat_name}"
				},
				"message": {
					"title": "Nuevo mensaje",
					"subtitle": "%{user} ha enviado un nuevo mensaje en %{chat_name}"
				}
			},
			"documents": {
				"shared": {
					"folder": "%{user} ha compartido una carpeta contigo",
					"file": "%{user} ha compartido un archivo contigo"
				}
			},
			"calendar": {
                "view": "Ver solicitud",
				"requestDocuments": {
					"requested": {
						"title": "Nueva solicitud de documento",
						"subtitle": "%{user} ha solicitado un documento"
					},
					"rejected": {
						"title": "Solicitud de documento rechazada",
						"subtitle": "%{user} ha rechazado la solicitud de documento"
					},
					"provided": {
						"title": "Documento cargado",
						"subtitle": "%{user} ha cargado un documento"
					},
					"approved": {
						"title": "Documento cargado aprobado",
						"subtitle": "%{user} ha aprobado tu documento cargado"
					},
					"rectification": {
						"title": "Nuevas rectificaciones de documento",
						"subtitle": "%{user} ha determinado que el documento cargado en una solicitud de documento requiere rectificación"
					}
				},
				"meeting": {
					"title": "New calendar meeting"
				},
				"alert": {
					"title": "New calendar alert"
				}
			}
		}
	},
	"deadline": {
		"provideDocumentTo": "Proporcionar documento a %{to}",
		"references": {
			"stage": {
				"title": "Vencimiento de la etapa %{stage}",
				"description": "Esta etapa expirará el %{date}"
			}
		}
	},
	"userActions": {
		"meetingVideo": {
			"name": "Subir video de la reunión",
			"message": "Has subido el video de la reunión de - %{title} -"
		},
        "messages": {
            "documentRequest": {
                "provide": {
                    "name": "Proveer documento",
                    "message": "Debes proveer un nuevo documento a %{user}"
                },
                "provideNewly": {
                    "name": "Proveer documento nuevamente",
                    "message": "Debes proveer nuevamente el documento requerido en la solicitud de documento generada por %{user}. Motivo: %{reason}"
                },
                "review": {
                    "name": "Revisar documento",
                    "message": "Debes revisar un documento cargado por %{user}",
                },
            },
            "offer": {
                "respond": {
                    "name": "Responder a oferta",
                    "message": "Debes responder a una oferta realizada por %{user}",
                },
            },
        },
	},
	"timeline": {
		"title": "Línea de tiempo",
		"description": "Ver actividades realizadas por todas las partes involucradas",
		"searchPlaceholder": "Buscar línea de tiempo",
		"pendingAction": "Acciones pendientes",
		"noDeadlines": "Sin fechas límite",
		"deadlines": "Fechas límite",
		"noPendingAction": "No hay acciones pendientes",
        "create": {
			"success": "Actividad creada existosamente"
		},
		"item": {
			"document": "Adjunto externo"
		}
	},
	"privateNotebook": {
		"title": "Cuaderno privado",
		"description": "Tus propias notas privadas",
		"searchPlaceholder": "Buscar notas",
		"buttonAdd": "Nueva nota",
		"pinned": "Fijado",
		"allNotes": "Todas las notas",
		"forms": {
			"placeholders": {
				"title": "Título",
				"content": "Tomar una nota.."
			},
			"menus": {
				"duplicate": "Duplicar",
				"delete": "Eliminar nota"
			},
			"create": {
				"successfully": "Nota creada correctamente"
			},
			"update": {
				"successfully": "Nota actualizada correctamente"
			},
			"delete": {
				"successfully": "Nota eliminada correctamente"
			}
		}
	},
	"caseDetail": {
		"title": "Detalles del caso",
		"description": "Más información detallada sobre el caso",
		"details": {
			"caseName": "Nombre del caso",
			"caseAmount": "Monto del caso",
			"caseRequest": "Solicitud del caso",
			"caseDetails": "Detalles del caso",
			"caseDescription": "Descripción del caso",
			"urgency": "Urgencia",
			"submissionDate": "Fecha de presentación",
			"submittedBy": "Presentado por",
			"currentStage": "Etapa actual",
			"assignationDocument": "Documento de asignación",
			"stageDuedate": "%{stage} fecha límite",
			"isWithAssistant": "Con asistente"
		},
		"parties": {
			"title": "Partes",
			"role": "Rol",
			"phoneNumber": "Número telefónico",
			"email": "Correo electrónico",
			"reassignment": {
				"reassigned": "Esta parte fue reasignada",
				"reason": "Motivo",
				"attachment": "Adjunto",
				"by": "Reasignado por",
				"itis": "Nuevo"
			}
		},
        "firstOffers": {
			"title": "Primera oferta de cada etapa",
			"offer": {
				"title": "Título",
				"content": "Posible acuerdo",
				"request": "Solicitud",
				"amount": "Monto"
			}
		}
	},
	"folder": {
		"title": "Carpetas",
		"description": "Acceder y gestionar archivos dentro de la carpeta"
	},
	"document": {
		"title": "Documentos",
		"description": "Acceder y gestionar archivos relacionados con el caso",
		"searchPlaceholder": "Buscar documentos",
		"buttonAdd": "Nuevo",
		"newFile": "Nuevo archivo",
		"newFolder": "Nueva carpeta",
		"modalRemoveFile": {
			"title": "¿Estás seguro de eliminar este documento?",
			"description": "No podrás restaurar el documento si decides eliminarlo.",
			"successfullyFolder": "Carpeta eliminada exitosamente",
			"successfullyFile": "Archivo eliminado exitosamente"
		},
		"modalEditFile": {
			"title": "Editar archivo",
			"titleFolder": "Editar carpeta",
			"successfullyFolder": "Carpeta actualizada correctamente",
			"successfullyFile": "Archivo actualizado correctamente"
		},
		"modalDocumentRequestInfo": {
			"title": "Trazabilidad de solicitudes de documentos",
			"requestTitle": "Título de la solicitud",
			"description": "Descripción",
			"requestedAt": "Fecha de solicitud",
			"requestedBy": "Solicitado por",
			"requestedTo": "Solicitado a",
			"dueDate": "Fecha límite",
			"attemps": "Intentos",
			"statusWord": "Estado",
			"status": {
				"rejectedBy": "Rechazado por: %{by}",
				"rejectedReason": "Motivo: %{reason}"
			},
			"fileAttemp": {
				"result": "Resultado:",
				"rectification": {
					"alert": "Este documento necesitaba rectificación",
					"reason": "Motivo: %{reason}"
				},
				"approved": {
					"alert": "Este documento fue aprobado"
				}
			}
		},
		"modalCreateFile": {
			"title": "Subir nuevo archivo",
			"titleFolder": "Crear nueva carpeta",
			"fileName": "Nombre del archivo",
			"folderName": "Nombre de la carpeta",
			"fileNamePlaceholder": "Nombre del archivo",
			"shareWith": "Compartir con",
			"fileType": "Tipo de archivo",
			"folder": "Carpeta",
			"withoutFolder": "-----Fuera de la carpeta------",
			"uploadFile": "Subir archivo",
			"updateFile": "Actualizar información del archivo",
			"createFolder": "Crear carpeta",
			"updateFolder": "Actualizar información de la carpeta",
			"successfullyFolder": "Carpeta creada exitosamente",
			"successfullyFile": "Archivo creado exitosamente"
		},
		"modalInfo": {
			"title": "Información del documento",
			"documentName": "Nombre del documento",
			"owner": "Propietario",
			"type": "Tipo",
			"shareWith": "Compartir con",
			"size": "Tamaño"
		},
		"headerTable": {
			"name": "Nombre",
			"owner": "Propietario",
			"type": "Tipo",
			"requestedDocuments": {
				"title": "Título",
				"stage": "Estapa",
				"dueDate": "Fecha límite",
				"status": "Estado",
				"requestedAt": "Solicitado en"
			}
		},
		"requestedDocuments": {
			"status": {
				"pending": "Pendiente",
				"on_review": "En revisión",
				"on_rectification": "En rectificación",
				"rejected": "Rechazado",
				"approved": "Aprobado"
			}
		}
	},
	"calendar": {
		"title": "Calendario",
		"newAgenda": "Nueva agenda",
		"monthly": "Mensual",
		"daily": "Diario",
		"buttonAdd": "Nuevo / Solicitud",
		"documentRequest": "Solicitud de documento",
        "documentRequests": "Solicitudes de documentos",
        "meetings": "Reuniones",
        "alerts": "Alertas",
		"joinMeeting": "Unirse a la reunión",
        "modalAgendaMethod": {
            "title": "¿Qué te gustaría crear?",
            "documentRequest": "Solicitud de documento",
            "meetingOrAlert": "Reunión o Alerta"
        },
		"modalRequestDocument": {
			"title": "Solicitar documento",
			"requestTo": "Solicitud para",
			"dueTime": "Hora de vencimiento",
			"additionalNotes": "Notas",
			"requestAdditional": "%{from} ha solicitado información adicional a %{to}",
			"shouldBeAccept": "Usuarios que deben aceptar",
			"requireAcceptEveryone": "Requiere aceptación de todos",
			"successfully": "Solicitud de documento generada exitosamente"
		},
		"modalNewAgenda": {
			"title": "Nueva agenda",
			"formTitle": "Título",
			"agendaType": "Tipo de agenda",
			"meetingType": "Tipo de reunión",
			"meetingPlatform": "Plataforma de la reunión",
			"meetingURL": "URL de la reunión",
            "inPersonAddress": "Dirección de la reunión",
			"meetingTime": "Fecha",
			"from": "De",
			"to": "Para",
			"meetingInvitees": "Invitados de la reunión",
			"agenda": "Agenda",
			"otherDetails": "Otros detalles",
			"otherDetailPlaceholder": "PIN/Constraseña",
			"addEvent": "Añadir evento",
			"provideURL": "Proporcionaré la URL",
			"redekVideoMeet": "Reunión de video de Redek",
            "successfully": "Agenda generada exitosamente"
		},
		"modalConfirm": {
			"title": "¿Estás seguro de crear esta agenda?",
			"description": "Los invitados serán notificados a través de la plataforma y su correo electrónico. Puedes editar esta agenda más tarde.",
			"cancel": "Cancelar",
			"confirm": "Confirmar"
		},
		"modalInfo": {
			"provideDocument": "Proporcionar documento",
			"rejectRequest": "Rechazar solicitud",
			"dueDate": "Vencimiento %{date} (%{totalDay} día)",
			"requestBy": "Solicitado por %{by}",
			"requestTo": "Solicitud a",
			"yourRespond": "Tu respuesta",
			"reason": "Motivo",
			"acceptOrReject": "La solicitud de documento ha sido respondida exitosamente",
			"documentAlreadyProvided": "Este documento fue proporcionado por %{by} y está en proceso de revisión",
			"documentNotProvidedYet": "Este documento aún no ha sido proporcionado",
			"documentApproved": "Este documento fue aprobado por",
			"currentDocument": {
				"title": "Documento actual proporcionado"
			},
			"requestDocumentRejected": "Esta solicitud de documento fue cerrada porque %{by} lo rechazó en %{at}. Motivo: %{reason}",
			"approval": {
				"approve": "Aprobar",
				"rectify": {
					"title": "¿El documento necesita ser rectificado?"
				},
				"rectification": "Es necesaria una rectificación",
				"confirmation": {
					"title": "¿Estás seguro de aceptar este documento?",
					"description": "Esta acción hará que la solicitud se cierre y el documento se registre como aceptado."
				},
				"successfully": {
					"approve": "Solicitud de documento aprobada exitosamente"
				},
				"feedback": {
					"title": "Retroalimentación",
					"alert": "Documento sobre el proceso de rectificación",
					"alertProvider": "Este documento debe ser proporcionado de nuevo"
				}
			},
			"meetingVideo": {
				"title": "Proporcionar la grabación de la reunión",
				"upload": {
					"pleaseChooseFile": "Por favor adjunta un archivo",
					"pleaseChooseFileMp4": "Por favor adjunta un archivo de tipo mp4",
					"success": "Video de la reunión subido exitosamente"
				},
				"done": {
					"title": "Video de la reunión",
					"description": "Puedes encontrar este video en la carpeta de grabaciones de llamadas del caso en documentos."
				}
			}
		},
		"modalReject": {
			"title": "¿Rechazar solicitud?"
		}
	},
	"chat": {
		"group": {
			"title": "Salas de chat",
			"description": "Conversaciones entre las partes",
			"private": "Discusión privada",
			"newChat": "Nueva sala de chat",
			"groupName": "Nombre del grupo",
			"members": "Miembros",
			"privateChat": "Chat privado",
			"stageChat": "Chat de etapa",
			"closed": "Cerrado"
		},
		"discussion": {
			"title": "Discusión privada",
			"info": "Información de la conversación",
			"closeChat": "Cerrar chat",
			"mediaAndDocuments": "Medios y documentos",
			"accepted": "Aceptado:",
			"rejected": "Rechazado:",
            "expiredOffer": "Esta oferta no puede ser respondida porque la fecha límite ya ha vencido",
			"confirmAcceptance": {
				"title": "¿Estás seguro de aceptar la oferta?",
				"agreement": {
					"description": "Esta oferta incluye un acuerdo, por lo que te sugerimos que revises el documento generado en la oferta. Puedes hacerlo tomando la acción respectiva en los detalles de la oferta."
				}
			},
			"makeOffer": "Hacer oferta",
			"offerSubject": "Tema de la oferta",
			"offerSubjectPlaceholder": "Oferta de fintech",
			"offerTo": "Oferta para",
			"request": "Solicitud",
			"description": "Descripción",
			"dueTime": "Hora de vencimiento",
			"rejectOffer": "¿Rechazar oferta?",
			"searchForChat": "Buscar chat...",
			"create": {
				"successfully": "Datos de chat creados exitosamente"
			},
			"newOffer": {
				"agreement": {
					"question": "¿Tienes un acuerdo?",
					"label": "Acuerdo",
					"alert": "La generación de un acuerdo a través de una oferta se basa en definir las cláusulas y elementos que contendrá el documento. Este documento se generará automáticamente. Ten en cuenta que con esta opción, el caso procederá a cerrarse y/o resolverse cuando la última parte requerida lo acepte."
				}
			},
			"offer": {
				"agreement": {
					"view": "Ver acuerdo del documento"
				}
			}
		}
	},
	"finalDecision": {
		"title": "Decisión final",
		"description": "Acceder a la decisión final del caso",
		"partyWhoResolved": "Parte que resolvió el caso",
		"resolveDate": "Fecha de resolución",
		"disputeResult": "Resultado de la disputa",
		"partialResolution": "Resolución parcial",
		"notes": "Notas",
		"byOfferAgreement": "Esta disputa se resolvió mediante una oferta generada por algunas de las partes.",
		"solutionByOffer": {
			"title": "Oferta generada para la solución del caso:",
			"offer": {
				"title": "Título",
				"content": "Contenido",
				"request": "Solicitud",
				"amount": "Cantidad"
			}
		},
		"document": {
			"title": "Documento generado para la solución del caso:",
			"view": "Ver documento"
		}
	},
	"admin": {
		"timeline": {
			"title": "Cronograma y actividades",
			"description": "Ingresar actividades manualmente",
			"searchPlaceholder": "Buscar actividades",
			"buttonAdd": "Nueva actividad",
			"modalRemoveTimeline": {
				"title": "¿Estás seguro de eliminar a este usuario?",
				"description": "No podrás restaurar el archivo si decides eliminarlo."
			},
			"modalCreateTimeline": {
				"title": "Crear actividad manualmente",
				"editTitle": "Editar actividad",
				"type": "Tipo de actividad",
				"case": "Actividad del caso",
				"user": "Actividad del usuario",
				"activityTitle": "Título",
				"subtitle": "Subtítulo",
				"content": "Contenido",
				"extra": "Extra",
				"save": "Guardar"
			}
		},
		"user": {
			"title": "Usuarios",
			"description": "Gestionar todos los usuarios en la plataforma",
			"searchPlaceholder": "Buscar usuario",
			"buttonAdd": "Nuevo usuario",
			"headerTable": {
				"name": "Nombre",
				"role": "Rol",
				"email": "Correo electrónico",
                "phone": "Teléfono",
				"action": "Acciones"
			},
			"modalRemoveUser": {
				"title": "¿Estás seguro de eliminar a este usuario?",
				"description": "No podrás restaurar el archivo si decides eliminarlo."
			},
			"modalCreateUser": {
				"title": "Registrar un nuevo usuario",
				"editTitle": "Editar usuario",
				"identification": "Identificación",
                "identificationType": "Tipo de identificación",
				"role": "Rol",
				"username": "Nombre de usuario",
				"firstName": "Primer nombre",
				"secondName": "Segundo nombre",
				"lastName": "Apellido",
				"email": "Correo electrónico",
				"password": "Contraseña",
				"phone": "Número telefónico",
				"bank": "Banco",
				"confirmPassword": "Confirmar contraseña",
				"save": "Guardar"
			}
		},
		"roles": {
			"title": "Roles",
			"description": "Gestionar todos los roles en la plataforma",
			"searchPlaceholder": "Buscar rol",
			"buttonAdd": "Nuevo rol",
			"headerTable": {
				"code": "Código",
				"name": "Nombre",
				"description": "Descripción",
				"action": "Acciones"
			},
			"modalRemoveRole": {
				"title": "¿Estás seguro de eliminar este rol?",
				"description": "No podrás restaurar el archivo si decides eliminarlo."
			},
			"modalCreateRole": {
				"title": "Registrar un nuevo rol",
				"editTitle": "Editar rol",
				"role": "Rol",
				"code": "Código",
				"name": "Nombre",
				"description": "Descripción",
				"save": "Guardar"
			},
			"detail": {
				"title": "Detalle del rol",
				"description": "Administración del rol %{codeRole}"
			}
		},
		"permissions": {
			"title": "Permisos",
			"description": "Gestionar todos los permisos en la plataforma",
			"searchPlaceholder": "Buscar permisos",
			"buttonAdd": "Nuevo permiso",
			"headerTable": {
				"name": "Nombre",
				"description": "Descripción",
				"pattern": "Permiso padre",
				"reference": "Referencia",
				"status": "Estado"
			},
			"modalRemovePermission": {
				"title": "¿Estás seguro de eliminar este permiso?",
				"description": "No podrás restaurar el permiso si decides eliminarlo."
			},
			"modalCreatePermission": {
				"title": "Registrar un nuevo permiso",
				"editTitle": "Editar permiso",
				"name": "Nombre",
				"description": "Description",
				"pattern": "Permiso padre",
				"reference": "Referencia",
				"status": "Estado",
				"save": "Guardar"
			}
		},
		"dashboard": {
			"title": "Tablero",
			"description": "Atajos rápidos para tus herramientas importantes",
			"overview": "Visión general",
			"pendingCase": "Casos pendiente",
			"newUser": "Nuevos usuarios"
		},
        "parameters": {
			"title": "Parámetros",
			"description": "Gestiona los parámetros esenciales de la plataforma.",
		},
		"cases": {
			"title": {
				"all": "Todos los casos",
				"onprepare": "Casos pendiente",
				"ongoing": "Casos en curso",
				"solved": "Casos resueltos"
			},
			"description": {
				"all": "Gestionar todos los casos en la plataforma",
				"onprepare": "Gestionar casos pendientes en la plataforma",
				"ongoing": "Gestionar casos en curso en la plataforma",
				"solved": "Gestionar casos resueltos en la plataforma"
			},
			"cases": "Casos",
			"searchPlaceholder": "Buscar casos",
			"cardTitle": {
				"all": "Total de casos",
				"pending": "Caso pendiente",
				"ongoing": "Caso en curso",
				"settled": "Caso resuelto"
			},
            "filter": {
                "byArea": {
                    "title": "Filtrar por área",
                    "select": "Selecciona un área",
                }
            }
		}
	},
	"modal": {
		"close": "Cerrar",
		"save": "Guardar",
		"attachment": "Adjunto",
		"videoUpload": "Carga de video",
		"modalDelete": {
			"title": "¿Estás seguro de eliminar?",
			"description": "No podrás restaurar si eliges eliminarlo."
		}
	},
	"validation": {
		"enterValidEmail": "Por favor ingresa un correo electrónico válido",
		"isNotValidEmail": "%{email} no es un correo electrónico válido",
		"enterValidPassword": "Por favor ingresa una contraseña. Mínimo 6 caracteres",
		"enterFirstName": "Por favor ingresa el primer nombre",
		"enterIdentification": "Por favor ingresa el número de identificación",
        "enterIdentificationType": "Por favor selecciona el tipo de identificación",
		"minimumFirstName": "Mínimo 3 caracteres",
		"alphabetOnly": "Por favor ingresa solo letras del alfabeto",
		"enterPhoneNumber": "Por favor ingresa el número de teléfono",
		"enterValidPhoneNumber": "Por favor ingresa un número de teléfono válido",
		"passwordMustMatch": "Las contraseñas deben coincidir",
		"emailAlreadyInUse": "El correo electrónico ya está en uso. Ingresa uno diferente",
		"phoneAlreadyInUse": "El número de teléfono ya está en uso. Ingresa uno diferente",
		"identificationAlreadyInUse": "El número de identificación ya está en uso.",
		"identificationFormat": "La identificación debe contener solo números",
		"enterActivityTitle": "Por favor ingresa el título de la actividad",
		"enterNoteTitle": "Por favor ingresa el título de tu nota",
		"enterNoteContent": "Por favor ingresa el contenido de tu nota",
		"selectResultSolveCase": "Por favor selecciona el resultado",
		"enterNote": "Por favor ingresa tu nota",
		"attachmentRequired": "Se requiere adjunto",
		"selectRole": "Por favor selecciona el rol",
		"selectBank": "Por favor selecciona el banco",
		"enterUserName": "Por favor ingresa el nombre de usuario.",
		"selectMember": "Por favor selecciona al miembro",
        "selectCurrency": "Por favor selecciona la moneda",
		"selectInvitees": "Por favor selecciona a los invitados",
		"selectRequestTo": "Por favor selecciona la solicitud para",
		"selectOfferTo": "Por favor selecciona la oferta para",
		"selectShareWith": "Por favor selecciona compartir con",
		"enterGroupName": "Por favor ingresa el nombre del grupo",
		"enterFileName": "Por favor ingresa el nombre del archivo",
		"enterFolderName": "Por favor ingresa el nombre de la carpeta",
		"chooseMeeting": "Por favor selecciona la reunión",
		"selectMeeting": "Selecciona la reunión a la que pertenece el archivo.",
		"enterSubject": "Por favor ingresa el asunto",
		"enterDescription": "Por favor ingresa una descripción",
		"enterRequest": "Por favor ingresa tu solicitud",
		"enterAmount": "Por favor ingresa la cantidad",
		"enterAgendaTitle": "Por favor ingresa el título de la agenda",
		"enterMeetingURL": "Por favor ingresa la URL de la reunión",
		"enterAgenda": "Por favor ingresa la agenda",
		"selectMeetingPlatform": "Por favor selecciona la plataforma de reuniones",
		"selectMeetingType": "Por favor selecciona el tipo de reunión",
        "selectIdentificationType": "Por favor selecciona el tipo de identificación",
		"selectAgendaType": "Por favor selecciona el tipo de agenda",
		"enterCorrectURL": "Por favor ingresa la URL correcta",
		"selectTime": "Seleccionar hora",
		"selectTimeFirst": "Por favor selecciona la hora de inicio y fin",
		"selectTimeInvalid": "La hora desde no puede ser mayor o igual a la hora hasta",
		"pleaseInsertRating": "Por favor elige la calificación primero",
		"pleaseDescribeReason": "Por favor describe la razón por qué",
		"pleaseDescribeEmail": "Por favor ingresa el correo electrónico para compartir los datos (separados por coma)",
		"enterCaseName": "Por favor ingresa un nombre para el caso",
		"enterCaseDescription": "Por favor ingresa una descripción para el caso",
		"enterCaseRequest": "Por favor ingresa una solicitud",
		"minimumCaseName": "Mínimo 5 caracteres",
		"enterCaseAmount": "Por favor ingresa el monto del caso",
		"minimumCaseAmount": "El monto debe ser mayor o igual a 1 USD",
		"pleaseSelectBankLawyer": "Por favor selecciona abogado del banco",
		"roles": {
			"creation": {
				"enterCode": "Por favor ingresa un código",
				"enterName": "Por favor ingresa un nombre de rol"
			}
		},
		"permissions": {
			"creation": {
				"enterName": "Por favor ingresa un nombre de permiso",
				"enterReference": "Por favor ingresa una referencia de permiso",
				"referenceAlreadyInUse": "La referencia ya está en uso. Ingresa una diferente"
			}
		},
		"documents": {
			"common": {
				"nameAlreadyInUse": "El nombre de carpeta/archivo ya existe. Ingresa uno diferente"
			}
		},
		"calendar": {
			"form": {
				"shouldBeAccept": "Por favor selecciona usuarios que deben aceptar el documento proporcionado"
			}
		},
		"passwordIncorrect": "La contraseña proporcionada es incorrecta",
        "enterMeetingInPersonAddress": "Por favor ingresa la dirección de la reunión presencial",
	},
	"options": {
		"ordering": {
			"dateAsc": "Fecha ascendente",
			"dateDesc": "Fecha descendente"
		}
	},
	"profile": {
		"title": "Perfil",
		"editProfile": "Editar perfil",
		"editProfileDesc": "Gestiona la información relacionada con tu perfil de REDEK",
		"terms": "Términos y condiciones",
		"yourRating": "Tu calificación y reseñas",
		"signOut": "Salir",
		"maximumSize": "Tamaño máximo",
		"fileType": "Tipo de archivo",
		"uploadPicture": "Subir imagen",
		"identification": "Identificación",
        "identificationType": "Tipo de identificación",
		"firstName": "Nombre",
		"lastName": "Apellido",
		"email": "Correo electrónico",
		"username": "Nombre de usuario",
		"phoneNumber": "Número telefónico",
		"rating": {
			"title": "Tu calificación y reseñas",
			"totalReviews": "(%{total} reseñas)",
            "average": "Promedio de calificación"
		},
		"update": {
			"success": "Datos actualizados exitosamente",
			"successPassword": "Contraseña actualizada exitosamente"
		},
		"otp": {
			"title": "Autenticación OTP",
			"alerts": {
				"info": "La autenticación OTP agrega una capa adicional de seguridad al iniciar sesión en la plataforma. Cuando accedes, necesitarás ingresar un código de 6 dígitos enviado ya sea por correo electrónico o mensaje de texto (tú eliges el método).",
				"off": "Recomendamos activar esta opción para mejorar la seguridad del sistema."
			},
			"modal": {
				"title": "%{action} la autenticación OTP",
				"description": "Como vas a %{action} la autenticación OTP, necesitas confirmar tu contraseña primero para poder hacerlo.",
				"password": {
					"label": "Contraseña",
					"placeholder": "Ingresa tu contraseña"
				},
				"method": {
					"label": "Método OTP"
				},
				"success": "OTP %{action} exitoso"
			}
		}
	},
	"message": {
		"success": {
			"case": {
				"priority": "Éxito al cambiar la prioridad del caso"
			},
			"auth": {
				"resetPassword": "Contraseña restablecida con éxito"
			}
		},
		"failed": {
			"ise": "Error interno del servidor",
			"calendar": {
				"is_already_exist": "La agenda ya existe"
			},
			"auth": {
				"emailExpired": "El correo electrónico para el OTP enviado ha caducado, por favor solicita de nuevo",
				"tokenExpired": "El token ha caducado, por favor solicita nuevamente",
				"fillOTP": "Por favor ingresa el código OTP",
				"userLoggingInExpired": "El usuario que está intentando acceder ha caducado. Por favor regrese al formulario de inicio de sesión"
			}
		}
	},
	"report": {
		"redekCaseSemester": "Informe de casos de REDEK %{year} semestre %{semester}"
	},
	"guidance": {
		"boarding": {
			"title": "¿Necesitas orientación?",
			"content": "¡Tomemos un pequeño recorrido por las funciones clave que ofrecemos!"
		},
		"caseDetail": {
			"title": "Detalles del caso",
			"content": "Esto contiene detalles sobre tu caso, puedes ver más información haciendo clic en esta tarjeta."
		},
		"currentStage": {
			"title": "Etapa actual",
			"content": "Esta tarjeta muestra la etapa actual del caso, que incluye la preparación, la oferta ciega, la mediación y el arbitraje."
		},
		"recentActivities": {
			"title": "Actividades recientes",
			"content": "Esto contiene el historial de acciones relacionadas con el caso."
		},
		"deadlines": {
			"title": "Fechas límite",
			"content": "Atajo rápido para que no te pierdas tareas y eventos importantes."
		},
		"caseStages": {
			"title": "Etapas del caso",
			"content": "Detalles de la etapa y tus acciones pendientes."
		},
		"privateNotebook": {
			"title": "Cuaderno privado",
			"content": "Tus propias notas privadas"
		},
		"meetingRoom": {
			"title": "Sala de reuniones",
			"content": "Sala de reuniones en línea proporcionada por REDEK"
		},
		"chats": {
			"title": "Chats",
			"content": "Comunicarse y compartir ofertas con otras partes"
		},
		"documents": {
			"title": "Documentos",
			"content": "Acceder y gestionar archivos del caso"
		},
		"timeline": {
			"title": "Línea de tiempo",
			"content": "Acceder y gestionar archivos del caso"
		},
		"calendar": {
			"title": "Calendario",
			"content": "Ver la línea de tiempo de tu caso y gestionar agendas"
		},
		"caseMoreDetail": {
			"title": "Detalles del caso",
			"content": "Mas detalles según tu caso."
		}
	},
	"mediationAgreement": {
		"createMediation": "Crear acuerdo de mediación",
		"title": "Acuerdo de mediación",
		"show": "Mostrar acuerdo de mediación",
		"subtitle": "Documento de acuerdo de tu caso",
		"description": "Envía tu documento de acuerdo al demandado",
		"convener": "CONVOCANTE",
		"respondent": "DEMANDADO",
		"attachLabel": "Adjunta tu documento de acuerdo aquí",
		"youCanChooseTemplate": "Puedes utilizar esta plantilla de acuerdo",
		"aggreementDesc": "Aceptar o rechazar el contrato de acuerdo con respecto a tu caso",
		"aggreementDocument": "Documento de acuerdo",
		"waitingMediator": "Esperando que el mediador cargue el acuerdo de mediación"
	},
	"status": {
		"active": "Activo",
		"inactive": "Inactivo"
	},
	"observation": "Observación",
    "parameters": {
        "title": "Parámetros",
        "menu": {
            "general": "General",
            "masterData": "Datos maestros",
        },
        "general": {
            "cases": {
                "title": "Casos",
                "deadlines": {
                    "title": "Tiempos límite de etapas",
                    "description": "Establece el tiempo límite que debe durar cada etapa en días. Ten en cuenta que los cambios que realices serán aplicados solo a los casos que se les asigne una parte despues de que des clic en 'Guardar'",
                    "inputs": {
                        "negotiation": {
                            "title": "Negociación",
                        },
                        "mediation": {
                            "title": "Mediación",
                        },
                        "arbitration": {
                            "title": "Arbitraje",
                        },
                        "commonPlaceholder": "N° días",
                    },
                    "validations": {
                        "negotiation": {
                            "required": "El campo tiempo límite de negociación es requerido",
                            "numeric": "El campo tiempo límite de negociación debe contener solo caracteres numéricos"
                        },
                        "mediation": {
                            "required": "El campo tiempo límite de mediación es requerido",
                            "numeric": "El campo tiempo límite de mediación debe contener solo caracteres numéricos"
                        },
                        "arbitration": {
                            "required": "El campo tiempo límite de arbitraje es requerido",
                            "numeric": "El campo tiempo límite de arbitraje debe contener solo caracteres numéricos"
                        },
                    },
                },
                "systemOwner": {
                    "title": "Propietario del sistema de documentos",
                    "description": "Define el propietario de las carpetas del sistema de archivos de los casos (expediente, grabaciones de reuniones, etc.). Esta acción le permitirá al usuario elegido cambiar el acceso a dichos directorios que son pertenecientes al sistema de documentos del caso.",
                    "selectUser": "Selecciona un usuario",
                    "validations": {
                        "required": "El campo propietario del sistema de documentos es requerido",
                    },
                }
            },
            "security": {
                "title": "Seguridad",
                "registerRole": {
                    "title": "Rol de registro",
                    "description": "Define el rol que se asignará a los usuarios cuando se registren mediante el formulario externo.",
                    "validations": {
                        "required": "El campo rol de registro es requerido",
                    },
                },
            }
        },
        "masterData": {
            "currencies": {
                "title": "Monedas",
                "new": {
                    "modal": {
                        "title": "Crear una nueva moneda",
                        "titleEdit": "Editar moneda"
                    },
                    "code": "Code",
                    "name": "Name",
                    "placeholderCode": "Escribe un código de moneda",
                    "placeholderName": "Escribe un nombre de moneda",
                    "validations": {
                        "code": {
                            "required": "El campo código es requerido",
                            "minimum": "Mínimo 4 caracteres",
                            "maximum": "Máximo 255 caracteres",
                        },
                        "name": {
                            "required": "El campo nombre es requerido",
                            "maximum": "Máximo 255 caracteres",
                        }
                    },
                },
                "successCreated": "Moneda creada exitosamente",
                "successUpdated": "Moneda actualizada exitosamente",
                "successDeleted": "Moneda borrada exitosamente",
                "delete": {
                    "confirmation": {
                        "title": "¿Estás seguro de eliminar esta moneda?",
                    }
                }
            },
            "caseTypes": {
                "title": "Tipos de casos",
                "new": {
                    "modal": {
                        "title": "Crear un nuevo tipo de caso",
                        "titleEdit": "Editar un tipo de caso"
                    },
                    "name": "Nombre",
                    "description": "Descripción",
                    "placeholderName": "Escribe un nombre de tipo de caso",
                    "placeholderDescription": "Escribe una descripción de tipo de caso",
                    "validations": {
                        "name": {
                            "required": "El campo nombre es requerido",
                            "maximum": "Máximo 255 caracteres",
                        },
                        "description": {
                            "maximum": "Máximo 255 caracteres",
                        }
                    },
                },
                "successCreated": "Tipo de caso creado exitosamente",
                "successUpdated": "Tipo de caso actualizado exitosamente",
                "successDeleted": "Tipo de caso borrado exitosamente",
                "delete": {
                    "confirmation": {
                        "title": "¿Estás seguro de eliminar este tipo de caso?",
                    }
                }
            },
            "identificationTypes": {
                "title": "Tipos de identificación",
                "new": {
                    "modal": {
                        "title": "Crear un nuevo tipo de identificación",
                        "titleEdit": "Editar tipo de identificación"
                    },
                    "name": "Nombre",
                    "nomenclature": "Nomenclatura",
                    "placeholderName": "Escribe un nombre de tipo de identificación",
                    "placeholderNomenclature": "Escribe una nomenclatura de tipo de identificación",
                    "validations": {
                        "name": {
                            "required": "El campo nombre es requerido",
                            "maximum": "Máximo 255 caracteres",
                        },
                        "nomenclature": {
                            "required": "El campo nombre es requerido",
                            "minimum": "Mínimo 2 caracteres",
                            "maximum": "Máximo 2 caracteres",
                        }
                    },
                },
                "successCreated": "Tipo de identificación creada exitosamente",
                "successUpdated": "Tipo de identificación actualizada exitosamente",
                "successDeleted": "Tipo de identificación borrada exitosamente",
                "delete": {
                    "confirmation": {
                        "title": "¿Estás seguro de eliminar este tipo de identificación?",
                    }
                }
            },
            "areas": {
                "title": "Areas",
                "new": {
                    "modal": {
                        "title": "Crear una nueva área",
                        "titleEdit": "Editar área"
                    },
                    "name": "Nombre",
                    "description": "Descripción",
                    "color": "Color",
                    "contrastToColor": "Contraste al color",
                    "placeholderName": "Escribe un nombre de área",
                    "placeholderDescription": "Escribe una descripción de área",
                    "validations": {
                        "name": {
                            "required": "El campo nombre es requerido",
                            "maximum": "Máximo 255 caracteres",
                        },
                        "description": {
                            "maximum": "Máximo 255 caracteres",
                        }
                    },
                },
                "successCreated": "Area creada exitosamente",
                "successUpdated": "Area actualizada exitosamente",
                "successDeleted": "Area borrada exitosamente",
                "delete": {
                    "confirmation": {
                        "title": "¿Estás seguro de eliminar esta área?",
                    }
                }
            },
            "common": {
                "table": {
                    "headers": {
                        "code": "Código",
                        "name": "Nombre",
                        "color": "Color",
                        "contrastToColor": "Contraste al color",
                        "nomenclature": "Nomenclatura",
                        "description": "Descripción",
                        "createdAt": "Fecha de creación",
                        "status": "Estado",
                    }
                },
                "form": {
                    "status": "Activo"
                }
            }
        },
        "save": {
            "success": "Parámetros guardados exitosamente"
        }
    },
    "videoCall": "Videoconferencia",
    "inPersonMeeting": "Reunión presencial",
    "mixed": "Mixto",
}