<template>
    <div
    v-for="notification in notifications"
    :key="notification.id"
    class="flex w-full cursor-pointer items-center justify-center space-x-5 text-sm text-black p-2 px-4 border-b border-slate-200"
    :class="[
        (!notification.is_read) ? 'bg-sky-100 hover:bg-sky-200' : 'bg-slate-50 hover:bg-slate-100',
    ]"
    >
        <div class="flex items-center justify-center">
            <div
            :class="[
                'bg-gradient-to-r w-10 h-10 rounded-full flex items-center justify-center text-white',
                {
                    'from-purple-400 via-purple-500 to-purple-700': notification.type === 'case',
                    'from-cyan-400 via-cyan-500 to-cyan-700': notification.type === 'stage',
                    'from-red-400 via-red-500 to-red-700': notification.type === 'doc',
                    'from-sky-400 via-sky-500 to-sky-700': notification.type === 'calendar',
                    'from-pink-400 via-pink-500 to-pink-700': notification.type === 'external',
                    'from-green-400 via-green-500 to-green-700': notification.type === 'offer',
                }
            ]"
            >
                <i
                :class="{
                    'far fa-folder-open': notification.type === 'case',
                    'fas fa-flag': notification.type === 'stage',
                    'fas fa-file': notification.type === 'doc',
                    'fas fa-calendar-day': notification.type === 'calendar',
                    'fas fa-link': notification.type === 'external',
                    'fas fa-comments': notification.type === 'offer',
                }"
                ></i>
            </div>
        </div>
        <div class="flex w-full flex-col">
            <div class="line-clamp-1 flex flex-col">
                <span class="font-bold text-lg text-slate-600">
                    {{
                        isAParsedReferenceTranslate(notification.title)
                            ? $t(
                                  ...getParsedReferenceTranslate(
                                      notification.title
                                  )
                              )
                            : notification.title
                    }}
                </span>
                <span
                    v-if="notification.subtitle"
                    class="text-slate-500"
                >
                    {{
                        isAParsedReferenceTranslate(notification.subtitle)
                            ? $t(
                                  ...getParsedReferenceTranslate(
                                      notification.subtitle
                                  )
                              )
                            : notification.subtitle
                    }}
                </span>
            </div>
            <div class="line-clamp-2 flex">{{ notification.content }}</div>
            <div class="flex text-sm text-slate-500">
                {{ timeAgo(notification.created_at) }}
            </div>
            <div class="flex justify-end text-sm text-blue-500 underline">
                <BasicSpinner v-if="readingNotification" />

                <button
                    v-if="!readingNotification"
                    class="hover:text-blue-700"
                    @click="view(notification)"
                >
                    {{
                        isAParsedReferenceTranslate(notification.extra)
                            ? $t(
                                  ...getParsedReferenceTranslate(
                                      notification.extra
                                  )
                              )
                            : notification.extra
                    }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import BasicSpinner from '@/components/BasicSpinner.vue'

export default {
    name: 'NotificationItemComponent',
    components: {
        BasicSpinner,
    },
    mixins: [globalMixin],
    props: {
        notifications: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    data: function () {
        return {
            readingNotification: false,
        }
    },
    methods: {
        view(notification) {
            if (!notification.is_read) {
                this.readingNotification = true
                this.$store
                    .dispatch('notification/readNotification', {
                        id: this.$methods.encrypt(notification.id),
                        case_id: notification.case_id,
                        user_id: notification.user_id,
                        type: notification.type,
                        title: notification.title,
                    })
                    .then((response) => {
                        this.readingNotification = false
                        this.$router.push(notification.href);
                    })
            } else {
                this.$router.push(notification.href);
            }
        },
    },
}
</script>
