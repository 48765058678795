<template>
    <div class="flex h-screen overflow-hidden">
        <!-- Sidebar -->
        <Sidebar :sidebar-open="sidebarOpen" @close-sidebar="sidebarOpen = false" />

        <!-- Content area -->
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <main>
                <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
                    <div class="flex justify-between">
                        <div class="flex w-full flex-col">
                            <h1 class="text-2xl font-bold text-black">
                                {{ $t('admin.dashboard.title') }}
                            </h1>
                            <p>{{ $t('admin.dashboard.description') }}</p>
                        </div>
                        <div class="mt-4 flex w-full items-center justify-end">
                            <DropdownLanguages class="mr-4"/>
                            <DropdownNotifications align="right" class="md:mr-4" wrapper-class="bg-white rounded-lg" />
                            <DropdownProfile align="right" mode="aesthetic" />
                        </div>
                    </div>

                    <!-- Overview -->
                    <div class="mt-5">
                        <div class="flex justify-between">
                            <h1 class="pt-10 text-2xl font-bold text-black">
                                {{ $t('admin.dashboard.overview') }}
                            </h1>
                            <div class="flex justify-between space-x-4">
                                <button
                                v-permission="'dashboard_admin_report_download'"
                                class="group flex h-12 items-center justify-between space-x-4 rounded-lg border border-gray-100 bg-white px-5 shadow-sm"
                                @click="downloadPDF">
                                    <div class="text-sm text-slate-800">
                                        {{ $t('dashboard.downloadPDF') }}
                                    </div>
                                    <DualRingLoader v-if="isDownloadLoading" :size="10" />
                                    <DownloadIcon
                                    v-else
                                    class="shrink-0 fill-current text-slate-400 group-hover:text-slate-500" />
                                </button>
                                <button
                                v-permission="'dashboard_admin_report_share'"
                                class="group flex h-12 items-center justify-between rounded-lg border border-gray-100 bg-white px-5 shadow-sm"
                                @click.stop="shareModalOpen = true">
                                    <div class="text-sm text-slate-800">
                                        {{ $t('dashboard.sharePDF') }}
                                    </div>
                                </button>
                                <select
                                v-model="semesterSelected"
                                class="h-12 rounded-lg border border-gray-100 bg-white px-8 shadow-sm"
                                @change="changeSemester">
                                    <option v-if="semesterOptions.length > 1" value="all">{{ $t('general.all') }}</option>
                                    <option v-for="opt in semesterOptions" :key="opt.id" :value="opt.id">
                                        {{
                                            $t('dashboard.semester', {
                                                semester: `${opt.year}-${opt.semester}`,
                                            })
                                        }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="flex justify-between space-x-2">
                            <div class="flex w-2/6 flex-col items-center justify-center py-10">
                                <div ref="generalPie" v-if="hasOverviewChart" class="w-full">
                                    <PieChart :data="chartData" width="389" height="270" />
                                </div>
                                <DualRingLoader v-else-if="caseAreaLoading" />
                                <NoData v-else />
                            </div>
                            <div class="w-4/6 py-5">
                                <div v-if="!caseOverviewLoading && overviewData.length > 0" class="grid grid-cols-12 gap-6">
                                    <OverviewCard :overviews="overviewData" @clicked="openLinkOverview" />
                                </div>
                                <DualRingLoader v-else-if="caseOverviewLoading" />
                                <NoData v-else />
                            </div>
                        </div>
                    </div>
                    <!-- End of Overview -->

                    <!-- Bar Chart -->
                    <div class="w-full rounded-lg bg-white pb-5">
                        <section v-if="!caseAreaLoading">
                            <div class="flex w-full px-5 py-5">
                                <div
                                v-for="area in caseAreaOverviews.filter(item => item.total > 0)"
                                :key="area.id"
                                class="py-4 text-center text-xl font-bold first:rounded-l-lg last:rounded-r-lg"
                                :style="[
                                    `width: ${ area.total * (100 / totalCaseSum) }%`,
                                    'background-color: ' + area.color,
                                    'color: ' + area.contrast_to_color
                                ]"
                                >
                                    {{ (area.total * (100 / totalCaseSum)).toFixed(1) + '%' }}
                                </div>
                            </div>
                            <div class="grid grid-cols-12 gap-6 px-5">
                                <div
                                v-for="area in caseAreaOverviews"
                                :key="area.id"
                                class="
                                    col-span-12 flex transform cursor-pointer flex-col rounded-2xl border bg-white
                                    shadow-sm transition duration-500 hover:scale-105 sm:col-span-6 lg:col-span-4
                                    xl:col-span-3"
                                >
                                    <div class="flex h-full flex-col justify-between px-5 py-5">
                                        <div clas="flex w-full space-x-2 items-center">
                                            <span
                                            class="mr-2 px-2"
                                            :style="'background-color: ' + area.color"></span>
                                            <span class="text-xl font-bold">{{ area.name }}</span>
                                        </div>

                                        <div class="ng mt-8 flex w-full flex-row justify-between">
                                            <p>
                                                <span class="pr-2 text-2xl font-bold">
                                                    {{ area.total }}
                                                </span>
                                                <span>{{ $t('admin.cases.cases') }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <DualRingLoader v-else-if="caseAreaLoading" />
                    </div>
                    <!-- End of Bar Chart -->

                    <!-- New Case -->
                    <div v-if="allCases.length > 0" class="w-full space-y-5 py-10">
                        <h1 class="text-2xl font-bold text-black">
                            {{ $t('admin.dashboard.pendingCase') }}
                        </h1>
                        <div v-if="!caseLoading && allCases.length > 0" class="grid grid-cols-12 gap-6">
                            <CaseCard :cases="allCases" @click-case="toCaseDetail($event, true)" />
                        </div>
                        <DualRingLoader v-else-if="caseLoading" />
                        <NoData v-else />
                    </div>
                    <!-- End of New Case -->

                    <!-- Users -->
                    <div v-if="users.length > 0" class="w-full space-y-5 py-10">
                        <div class="flex items-center gap-3">
                            <h1 class="text-2xl font-bold text-black">
                                {{ $t('admin.dashboard.newUser') }}
                            </h1>
                            <div class="text-sm flex items-center gap-2">
                                <select
                                @change="changeNRecords"
                                v-model="filterDataUser.take"
                                class="form-input w-16"
                                >
                                    <option
                                    v-for="item in [5, 10, 25, 50, 100]"
                                    :value="item"
                                    >
                                        {{ item }}
                                    </option>
                                </select>
                                <span>
                                    {{ $t('general.records') }}
                                </span>
                            </div>
                        </div>
                        <section v-if="!isUserLoading">
                            <DataTable
                            headerClass="!p-4"
                            :headers="dataTable.headers"
                            :list="users"
                            :show-additional-row-slot="true"
                            :show-other-actions-slot="true"
                            @edit="(event) => editUser({user: event})"
                            @delete="(event) => deleteUser({user: event})"
                            >
                                <template v-slot:cell-avatar="cellAvatarProps">
                                    <div class="flex justify-center">
                                        <ImageLoader
                                        wrapper-class="shrink-0 rounded-full"
                                        :src="cellAvatarProps.item.avatar"
                                        width="45"
                                        height="45"
                                        :alternate="cellAvatarProps.item.first_name"
                                        />
                                    </div>
                                </template>
                                <template v-slot:cell-role="cellRoleProps">
                                    <p :class="[
                                        'rounded-lg px-2 w-full py-1 text-center text-base font-semibold',
                                        cellRoleProps.item.role.class_tailwind_color ?? 'bg-purple-100  text-purple-600'
                                    ]">
                                        {{ cellRoleProps.item.role.name }}
                                    </p>
                                </template>
                                <template v-slot:additional-row="additRowProps">
                                    <tr v-show="additRowProps.item.show_stats">
                                        <td colspan="7">
                                            <div>
                                                <div class="h-px w-full bg-gray-200"></div>
                                                <div class="bg-white p-4 relative py-12">
                                                    <div class="flex items-center flex-col lg:grid gap-8 lg:grid-cols-3">
                                                        <div
                                                        v-if="additRowProps.item.stats_case_areas"
                                                        class="w-full h-full flex flex-col items-center justify-center">
                                                            <PieChart
                                                            v-if="additRowProps.item.stats_case_areas.some(item => item.total > 0)"
                                                            :data="getDataPieUser(additRowProps.item)"
                                                            width="189"
                                                            height="70"
                                                            pie-class="w-48 h-48"
                                                            />
                                                            <NoData class="text-sm text-slate-400" v-else />
                                                        </div>
                                                        <div
                                                        v-if="additRowProps.item.stats_case_areas"
                                                        class="flex lg:hidden h-8 bg-gray-100 rounded-lg col-span-2">
                                                            <div
                                                            v-for="(statsCaseAreaUser, index) in additRowProps.item.stats_case_areas.filter(item => item.total > 0)"
                                                            :key="index"
                                                            class="h-full flex first:rounded-l-lg last:rounded-r-lg items-center justify-center"
                                                            :style="[
                                                                'background-color: ' + statsCaseAreaUser.color,
                                                                `width: ${ statsCaseAreaUser.total * (100 / additRowProps.item.stats_total_cases) }%`,
                                                                'color: ' + statsCaseAreaUser.contrast_to_color
                                                            ]"
                                                            >
                                                                <span class="text-sm font-bold">
                                                                    {{ (statsCaseAreaUser.total * (100 / additRowProps.item.stats_total_cases)).toFixed(1) + '%' }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="grid gap-3">
                                                            <div
                                                            v-if="additRowProps.item.stats_case_areas"
                                                            class="flex flex-col gap-3">
                                                                <div
                                                                v-for="(statsCaseAreaUser, index) in additRowProps.item.stats_case_areas"
                                                                :key="index"
                                                                class="
                                                                    transform cursor-pointer rounded-2xl bg-slate-100 shadow-sm transition
                                                                    duration-500 hover:scale-105
                                                                ">
                                                                    <div class="flex gap-1 justify-between px-6 py-2">
                                                                        <p class="font-bold text-sm flex gap-2 items-center">
                                                                            <div
                                                                            class="w-3 h-3"
                                                                            :style="[
                                                                                'background-color: ' + statsCaseAreaUser.color
                                                                            ]"></div>
                                                                            <span>
                                                                                {{ statsCaseAreaUser.name }}
                                                                            </span>
                                                                        </p>
                                                                        <div>
                                                                            {{ statsCaseAreaUser.total }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="flex flex-col lg:grid lg:grid-cols-2 gap-3">
                                                            <div
                                                            v-for="(statsCaseStatusUser, index) in statsCaseStatusesUser"
                                                            :key="index"
                                                            class="
                                                                transform cursor-pointer rounded-2xl bg-slate-100 shadow-sm transition
                                                                duration-500 hover:scale-105
                                                            ">
                                                                <div class="flex h-full flex-col gap-1 justify-between px-3 py-3">
                                                                    <div clas="flex">
                                                                        <p class="font-bold">
                                                                            {{ $t(`admin.cases.cardTitle.${ statsCaseStatusUser.title }`) }}
                                                                        </p>
                                                                    </div>

                                                                    <div class="flex w-full flex-row justify-between">
                                                                        <p>
                                                                            <span
                                                                            :class="[
                                                                                'pr-2 text-lg font-bold',
                                                                                statsCaseStatusUser.color
                                                                            ]">
                                                                                {{ additRowProps.item[statsCaseStatusUser.key] }}
                                                                            </span>
                                                                            <span>
                                                                                {{ $t('admin.cases.cases') }}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                        v-if="additRowProps.item.stats_case_areas && additRowProps.item.stats_total_cases"
                                                        class="hidden lg:flex h-8 bg-gray-100 rounded-lg col-span-2">
                                                            <div
                                                            v-for="(statsCaseAreaUser, index) in additRowProps.item.stats_case_areas.filter(item => item.total > 0)"
                                                            :key="index"
                                                            class="h-full flex first:rounded-l-lg last:rounded-r-lg items-center justify-center"
                                                            :style="[
                                                                'background-color: ' + statsCaseAreaUser.color,
                                                                `width: ${ statsCaseAreaUser.total * (100 / additRowProps.item.stats_total_cases) }%`,
                                                                'color: ' + statsCaseAreaUser.contrast_to_color
                                                            ]"
                                                            >
                                                                <span class="text-sm font-bold">
                                                                    {{ (statsCaseAreaUser.total * (100 / additRowProps.item.stats_total_cases)).toFixed(1) + '%' }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="flex justify-between items-center bg-slate-100 rounded-lg p-4">
                                                            <div class="font-bold text-sm">
                                                                {{ $t('profile.rating.average') }}
                                                            </div>
                                                            <div class="flex items-center gap-3">
                                                                <div class="flex flex-row">
                                                                    <svg
                                                                    v-for="index in 5"
                                                                    :key="index"
                                                                    :class="[
                                                                        'h-4 w-4 shrink-0 fill-current',
                                                                        (additRowProps.item.average_rating && additRowProps.item.average_rating >= index)
                                                                        ? 'text-amber-400'
                                                                        : 'text-slate-300'
                                                                    ]"
                                                                    >
                                                                        <path
                                                                        d="M8 0L6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934h-6L8 0z"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                                <span>
                                                                    ({{ additRowProps.item.average_rating }})
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                    v-if="userStatsHeight[index]"
                                                    class="absolute bottom-0 right-0">
                                                        <button
                                                        @click="userStatsHeight[index] = false"
                                                        class="
                                                            bg-sky-500 transition-all text-white py-1 px-3 rounded-tl-xl text-sm
                                                            hover:bg-sky-600 flex items-center justify-between gap-2
                                                        ">
                                                            <i class="fa-solid fa-chart-pie"></i>
                                                            <span>
                                                                Hide stats
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                <template v-slot:other-actions="otherActProps">
                                    <button
                                    @click="showStats(otherActProps.item)"
                                    class="btn bg-green-500 w-7 h-7"
                                    >
                                        <i class="fa-solid fa-chart-column text-white text-xs"></i>
                                    </button>
                                </template>
                            </DataTable>
                            <div class="flex flex-col items-end my-4">
                                <div class="flex">
                                    <button
                                    v-for="(link, index) in metaDataUser.links"
                                    @click="changePage(link, index)"
                                    :class="[
                                        'text-sm px-2 flex items-center justify-center border-t border-b border-l',
                                        {
                                            'w-8 h-8': index != 0 && index < (metaDataUser.links.length - 1),
                                            'border-r rounded-r-md': index === (metaDataUser.links.length - 1),
                                            'rounded-l-md': index === 0
                                        },
                                        (link.active) ? 'bg-sky-500 text-white' : 'bg-white text-slate-600'
                                    ]"
                                    >
                                        <span v-html="link.label"></span>
                                    </button>
                                </div>
                            </div>
                        </section>
                        <DualRingLoader v-else-if="isUserLoading" />
                        <NoData v-else />
                    </div>
                    <!-- End of Users -->
                </div>
            </main>

            <ModalDelete
            :id="currentUser.id"
            :is-open="deleteUserModalOpen"
            :title="$t('admin.user.modalRemoveUser.title')"
            :description="$t('admin.user.modalRemoveUser.description')" @closed="deleteUserModalOpen = false"
            @delete="deleteUserAPI($event)"
            />

            <ModalCreateUser
            :is-open="newUserModalOpen"
            :roles="roles"
            :banks="banks"
            :is-edit="isUserEdit"
            :current-user="currentUser"
            :is-user-loading="isLoading"
            @closed="modalUserClose"
            @submit-data="submitData"
            :identification-types="identificationTypes"
            />

            <ModalShareEmail
            :is-open="shareModalOpen"
            :is-loading="isDownloadLoading"
            @share="shareEmail"
            @closed="shareModalOpen = false"
            />
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { tailwindConfig } from '@/utils/Utils'

import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'

import Sidebar from '@/partials/Sidebar.vue'
import Table from '@/components/Table.vue'
import UserItem from '@/components/UserItem.vue'

import PieChart from '@/charts/PieChart.vue'
import ModalCreateUser from '@/components/ModalCreateUser.vue'
import ModalShareEmail from '@/components/modal/ModalShareEmail.vue'
import ModalDelete from '@/components/ModalDelete.vue'
import CaseCard from '@/components/CaseCard.vue'
import OverviewCard from '@/components/OverviewCard.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'
import NoData from '@/components/NoData.vue'
import DropdownNotifications from '@/components/DropdownNotifications.vue'
import DropdownProfile from '@/components/DropdownProfile.vue'
import DropdownLanguages from '@/components/DropdownLanguages.vue'
import DownloadIcon from '@/assets/svg/download-round.svg'

import DataTable from '@/components/DataTable.vue'
import ImageLoader from '@/components/image/ImageLoader.vue'

import html2canvas from 'html2canvas';

export default {
    name: 'AdminDashboardPage',
    components: {
        Sidebar,
        Table,
        ModalCreateUser,
        ModalShareEmail,
        ModalDelete,
        UserItem,
        CaseCard,
        OverviewCard,
        DualRingLoader,
        PieChart,
        NoData,
        DropdownNotifications,
        DownloadIcon,
        DropdownProfile,
        DropdownLanguages,
        DataTable,
        ImageLoader
    },
    mixins: [globalMixin, caseMixin],
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()

        const sidebarOpen = ref(false)
        const newUserModalOpen = ref(false)
        const shareModalOpen = ref(false)
        const deleteUserModalOpen = ref(false)
        const isUserLoading = ref(false)
        const caseOverviewLoading = ref(false)
        const caseLoading = ref(false)
        const isUserEdit = ref(false)
        const isDownloadLoading = ref(false)
        const currentUser = ref({})
        const headerData = ref([
            {
                name: t('admin.user.headerTable.name'),
                colspan: 1,
            },
            {
                name: t('admin.user.headerTable.role'),
                colspan: 1,
            },
            {
                name: t('admin.user.headerTable.email'),
                colspan: 1,
            },
        ])

        return {
            t,
            store,
            toast,
            sidebarOpen,
            currentUser,
            isUserEdit,
            isUserLoading,
            caseOverviewLoading,
            deleteUserModalOpen,
            caseLoading,
            newUserModalOpen,
            isDownloadLoading,
            shareModalOpen,
            headerData,
        }
    },
    data: function () {
        return {
            allCases: [],
            overviewData: [],
            roles: [],
            users: [],
            filterDataUser: {
                page: 1,
                take: 10,
                search: '',
                order: 'ASC',
            },
            metaDataUser: {
                page: 1,
                take: 10,
                itemCount: 10,
                pageCount: 1,
                hasPreviousPage: false,
                hasNextPage: true,
            },
            filterDataCases: {
                page: 1,
                take: 10,
                search: '',
                order: 'DESC',
                status: 'On prepare',
            },
            metaDataCases: {
                page: 1,
                take: 10,
                itemCount: 10,
                pageCount: 1,
                hasPreviousPage: false,
                hasNextPage: true,
            },
            formData: {
                first_name: '',
                last_name: '',
                username: '',
                email: '',
                phone: '',
                password: '',
                role_id: '',
            },
            banks: [],
            caseAreaOverviews: [],
            semesterOptions: [],
            chartData: {},
            semesterSelected: null,
            identificationTypes: [],
            userStatsHeight: [],
            statsCaseStatusesUser: [
                { title: 'all', color: 'text-sky-500', key: 'stats_total_cases' },
                { title: 'pending', color: 'text-red-500', key: 'stats_pending_cases' },
                { title: 'ongoing', color: 'text-orange-500', key: 'stats_ongoing_cases' },
                { title: 'settled', color: 'text-green-500', key: 'stats_solved_cases' },
            ],
            pdfUrl: null,
            dataTable: {
                headers: [
                    {
                        id: 'avatar',
                        label: null,
                        column: {
                            key: 'avatar',
                        },
                        isSlot: true,
                    },
                    {
                        id: 'name',
                        label: this.$t('admin.user.headerTable.name'),
                        column: {
                            key: 'name',
                        },
                    },
                    {
                        id: 'role',
                        label: this.$t('admin.user.headerTable.role'),
                        column: {
                            key: 'role',
                        },
                        isSlot: true,
                    },
                    {
                        id: 'email',
                        label: this.$t('admin.user.headerTable.email'),
                        column: {
                            key: 'email',
                        },
                    },
                    {
                        id: 'phone',
                        label: this.$t('admin.user.headerTable.phone'),
                        column: {
                            key: 'phone',
                        },
                    },
                    {
                        id: 'created_at',
                        label: this.$t('parameters.masterData.common.table.headers.createdAt'),
                        column: {
                            funct: (value) => this.$filters.newDayjs(value).format('DD MMM YYYY - HH:mm'),
                            key: 'created_at',
                        }
                    },
                    {
                        id: 'actions',
                        label: '',
                        column: {
                            key: '',
                            action: {
                                edit: {
                                    permission: 'security_users_management_edit'
                                },
                                delete: {
                                    permission: 'security_users_management_delete'
                                }
                            }
                        }
                    }
                ]
            }
        }
    },
    computed: {
        totalCaseSum() {
            return this.caseAreaOverviews.reduce((total, element) => total + element.total, 0)
        },
        hasOverviewChart() {
            return this.caseAreaOverviews.some(item => item.total > 0)
        },
        getDataPieUser () {
            return (user) => {
                let chartDataUser = {}
                if (user.stats_case_areas && user.stats_case_areas.some(item => item.total > 0)) {
                    chartDataUser = {
                        labels: user.stats_case_areas.map(item => item.name),
                        datasets: [
                            {
                                label: 'Case by area on user',
                                data: user.stats_case_areas.map(item => item.total),
                                backgroundColor: user.stats_case_areas.map(item => item.color),
                                hoverBackgroundColor: [tailwindConfig().theme.colors.rose[100]],
                                borderWidth: 0,
                            }
                        ]
                    }
                }
                return chartDataUser
            }
        },
    },
    beforeMount() {
        this.init()
    },
    mounted() {
        this.getAllAreas()
        this.getAllIdentificationTypes()
    },
    beforeUnmount () {
        URL.revokeObjectURL(this.pdfUrl);
    },
    methods: {
        init() {
            this.setSemesterOption()
            this.getAllRoles()
            this.getAllCases()
            this.getAllBanks()
        },
        setChartData() {
            if (this.hasOverviewChart) {
                this.chartData = {}
                this.chartData.labels = this.caseAreaOverviews.map(item => item.name)
                this.chartData.datasets = [
                    {
                        label: 'Case by Area',
                        data: this.caseAreaOverviews.map(item => item.total),
                        backgroundColor: this.caseAreaOverviews.map(item => item.color),
                        hoverBackgroundColor: [tailwindConfig().theme.colors.rose[100]],
                        borderWidth: 0,
                    },
                ]
            }
        },
        editUser(event) {
            this.newUserModalOpen = true
            this.isUserEdit = true
            this.currentUser = event.user
        },
        deleteUser(event) {
            this.deleteUserModalOpen = true
            this.isUserEdit = false
            this.currentUser = event.user
        },
        getAllRoles() {
            this.store.dispatch('user/getAllRoles')
                .then((response) => {
                    const responseData = response.data
                    if (responseData.data) {
                        this.roles = responseData.data
                    }
                })
                .catch((error) => {
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        getAllUsers() {
            this.isUserLoading = true
            const isSomeSemesterOption = this.semesterOptions.some(item => item.id === this.semesterSelected)
            this.store.dispatch('user/getAllUsers', {
                order: this.filterDataUser.order,
                page: this.filterDataUser.page,
                take: this.filterDataUser.take,
                q: this.filterDataUser.search,
                stats: true,
                year: (isSomeSemesterOption)
                    ? this.semesterOptions.find(item => item.id === this.semesterSelected).year
                    : null,
                semester: (isSomeSemesterOption)
                    ? this.semesterOptions.find(item => item.id === this.semesterSelected).semester
                    : null
            })
                .then((response) => {
                    this.isUserLoading = false
                    const responseData = response.data
                    if (responseData.data) {
                        this.users = responseData.data
                    }
                    if (responseData.from) {
                        this.metaDataUser = {
                            current_page: responseData.current_page,
                            from: responseData.from,
                            last_page: responseData.last_page,
                            links: responseData.links,
                            per_page: responseData.per_page,
                            to: responseData.to,
                            total: responseData.total,
                        }
                    }
                })
                .catch((error) => {
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        submitData(formData) {
            if (this.isLoading) return

            this.formData = formData
            if (formData.user_id === null) {
                this.createUser()
            } else {
                this.updateUser()
            }
        },
        modalUserClose() {
            this.newUserModalOpen = false
            this.currentUser = {}
        },
        async updateUser() {
            if (this.isLoading) return
            this.isLoading = true

            await this.store
                .dispatch('user/updateUser', {
                    identification_type_id:  this.formData.identification_type_id,
                    identification: this.formData.identification,
                    first_name: this.formData.first_name,
                    last_name: this.formData.last_name,
                    username: this.formData.username,
                    email: this.formData.email,
                    phone: this.formData.phone,
                    password: this.formData.password,
                    user_id: this.$methods.encrypt(this.formData.user_id),
                    role_id: this.formData.role_id,
                })
                .then((response) => {
                    this.isLoading = false
                    if (response.data.statusCode === 200) {
                        const message = response.data.message
                        this.toast.success(message)
                        this.newUserModalOpen = false
                        this.getAllUsers()
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async createUser() {
            if (this.isLoading) return
            this.isLoading = true
            await this.store
                .dispatch('user/createUser', {
                    first_name: this.formData.first_name,
                    last_name: this.formData.last_name,
                    username: this.formData.username,
                    email: this.formData.email,
                    phone: this.formData.phone,
                    password: this.formData.password,
                    role_id: this.formData.role_id,
                    bank_id: this.formData.bank_id,
                })
                .then((response) => {
                    this.isLoading = false

                    if (response.data.statusCode === 200) {
                        const message = response.data.message
                        this.toast.success(message)
                        this.newUserModalOpen = false
                        this.getAllUsers()
                    }
                })
                .catch((error) => {
                    this.isLoading = false

                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async deleteUserAPI(events) {
            if (this.isLoading) return
            this.isLoading = true
            await this.store
                .dispatch('user/deleteUser', {
                    user_id: events.id,
                })
                .then((response) => {
                    this.isLoading = false

                    if (response.data.statusCode === 200) {
                        const message = response.data.message
                        this.toast.success(message)
                        this.deleteUserModalOpen = false
                        this.getAllUsers()
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        getCaseOverview() {
            const isSomeSemesterOption = this.semesterOptions.some(item => item.id === this.semesterSelected)
            if (
                isSomeSemesterOption
                ||
                this.semesterSelected === 'all'
            ) {
                const params = {}
                params.year = (isSomeSemesterOption)
                    ? this.semesterOptions.find(item => item.id === this.semesterSelected).year
                    : null
                params.semester = (isSomeSemesterOption)
                    ? this.semesterOptions.find(item => item.id === this.semesterSelected).semester
                    : null
                this.caseOverviewLoading = true
                this.store.dispatch('cases/getCaseOverview', params)
                .then((response) => {
                    this.caseOverviewLoading = false
                    const responseData = response.data
                    if (responseData) {
                        let overviewData = []
                        responseData.forEach((item) => {
                            item.status = this.setStatusByName(item.name)
                            overviewData.push(item)
                        })
                        this.overviewData = overviewData
                    }
                }).catch((error) => {
                    this.toast.error(this.extractErrorResponse(error))
                })
            }
        },
        getCaseAreaOverview() {
            const isSomeSemesterOption = this.semesterOptions.some(item => item.id === this.semesterSelected)
            if (
                isSomeSemesterOption
                ||
                this.semesterSelected === 'all'
            ) {
                const params = {}
                params.year = (isSomeSemesterOption)
                    ? this.semesterOptions.find(item => item.id === this.semesterSelected).year
                    : null
                params.semester = (isSomeSemesterOption)
                    ? this.semesterOptions.find(item => item.id === this.semesterSelected).semester
                    : null

                this.caseAreaLoading = true
                this.store.dispatch('cases/getCaseAreaOverview', params)
                    .then((response) => {
                        this.caseAreaLoading = false
                        if (response.data) {
                            this.caseAreaOverviews = response.data
                            this.setChartData()
                        }
                    })
                    .catch((error) => {
                        this.toast.error(this.extractErrorResponse(error))
                    })
            }
        },
        getAllCases() {
            this.caseLoading = true
            this.store
                .dispatch('cases/getAllCasesAdmin', {
                    order: this.filterDataCases.order,
                    page: this.filterDataCases.page,
                    take: this.filterDataCases.take,
                    q: this.filterDataCases.search,
                    status: this.filterDataCases.status,
                })
                .then((response) => {
                    this.caseLoading = false
                    const responseData = response.data
                    if (responseData.data) {
                        let mappedDataCase = []
                        responseData.data.forEach((item) => {
                            item.status = this.stripAndLower(item.status)
                            mappedDataCase.push(item)
                        })
                        this.cases = mappedDataCase
                        this.allCases = mappedDataCase
                    }
                    if (responseData.from) {
                        this.metaDataCases = {
                            current_page: responseData.current_page,
                            from: responseData.from,
                            last_page: responseData.last_page,
                            links: responseData.links,
                            per_page: responseData.per_page,
                            to: responseData.to,
                            total: responseData.total,
                        }
                    }
                })
                .catch((error) => {
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        openLinkOverview(item) {
            this.$router.push({
                name: `${item.status}-cases`,
            })
        },
        async getAllBanks() {
            await this.store
                .dispatch('bank/getAllBank')
                .then((response) => {
                    const responseData = response.data.data
                    this.banks = responseData
                })
                .catch((error) => {
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        setSemesterOption() {
            this.$store.dispatch('cases/getAvailableYearsCases').then(response => {
                if (response.data) {
                    const years = response.data.map(item => parseInt(item.month_year.split('-')[0]))
                    if (years && years.length) {
                        const minYear = Math.min(...years)
                        const maxYear = Math.max(...years)
                        let i = minYear
                        while (i <= maxYear) {
                            if (
                                response.data.some(item =>
                                    parseInt(item.month_year.split('-')[0]) === i
                                    &&
                                    item.month_year <= (i + '-06')
                                )
                            ) {
                                this.semesterOptions.push({
                                    id: (Math.random() + 1).toString(36).substring(7),
                                    semester: 1,
                                    year: i,
                                });
                            }
                            if (
                                response.data.some(item =>
                                    parseInt(item.month_year.split('-')[0]) === i
                                    &&
                                    item.month_year >= (i + '-07')
                                )
                            ) {
                                this.semesterOptions.push({
                                    id: (Math.random() + 1).toString(36).substring(7),
                                    semester: 2,
                                    year: i,
                                });
                            }
                            i++;
                        }
                    }
                    if (this.semesterOptions && this.semesterOptions.length) {
                        this.semesterSelected = JSON.parse(JSON.stringify(this.semesterOptions[this.semesterOptions.length - 1])).id
                    }
                    this.getCaseAreaOverview()
                    this.getCaseOverview()
                    this.getAllUsers()
                }
            });
        },
        changeSemester() {
            this.userStatsHeight = []
            this.getCaseAreaOverview()
            this.getCaseOverview()
            this.getAllUsers()
        },
        sendPDFCaseAPI(to) {
            const isSomeSemesterOption = this.semesterOptions.some(item => item.id == this.semesterSelected)
            if (
                !this.isDownloadLoading
                &&
                this.semesterSelected
                &&
                (
                    isSomeSemesterOption
                    ||
                    this.semesterSelected === 'all'
                )
            ) {
                this.isDownloadLoading = true
                const generalPie = this.$refs.generalPie;
                html2canvas(generalPie, { backgroundColor: null }).then((canvas) => {
                    const imageUrl = canvas.toDataURL('image/png');
                    this.store
                    .dispatch('cases/sendPDFCase', {
                        year: (isSomeSemesterOption)
                            ? this.semesterOptions.find(item => item.id == this.semesterSelected).year
                            : null,
                        semester: (isSomeSemesterOption)
                            ? this.semesterOptions.find(item => item.id == this.semesterSelected).semester
                            : null,
                        image_url: imageUrl,
                        to: to,
                    }).then((response) => {
                        this.isDownloadLoading = false
                        if (response.status === 200) {
                            this.shareModalOpen = false
                            this.toast.success(this.$t('dashboard.share.success'))
                        }
                    }).catch((error) => {
                        this.shareModalOpen = true
                        this.isDownloadLoading = false
                        this.toast.error(this.extractErrorResponse(error))
                    })
                });
            }
        },
        downloadPDF() {
            const isSomeSemesterOption = this.semesterOptions.some(item => item.id == this.semesterSelected)
            if (
                !this.isDownloadLoading
                &&
                this.semesterSelected
                &&
                (
                    isSomeSemesterOption
                    ||
                    this.semesterSelected === 'all'
                )
            ) {
                this.isDownloadLoading = true
                const generalPie = this.$refs.generalPie;
                html2canvas(generalPie, { backgroundColor: null }).then((canvas) => {
                    const imageUrl = canvas.toDataURL('image/png');
                    this.store.dispatch('cases/generatePDFCase', {
                        year: (isSomeSemesterOption)
                            ? this.semesterOptions.find(item => item.id == this.semesterSelected).year
                            : null,
                        semester: (isSomeSemesterOption)
                            ? this.semesterOptions.find(item => item.id == this.semesterSelected).semester
                            : null,
                        image_url: imageUrl,
                    })
                    .then((response) => {
                        const byteCharacters = atob(response.data.pdf);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const blob = new Blob([byteArray], { type: 'application/pdf' });
                        this.pdfUrl = URL.createObjectURL(blob);
                        window.open(this.pdfUrl)
                        this.isDownloadLoading = false
                    })
                    .catch((error) => {
                        this.isDownloadLoading = false
                        this.toast.error(this.extractErrorResponse(error))
                    })
                });
            } else {
                this.isDownloadLoading = false
            }
        },
        shareEmail(event) {
            this.sendPDFCaseAPI(event.email)
        },
        getAllIdentificationTypes() {
            this.store
                .dispatch('user/getAllIdentificationTypes')
                .then((response) => {
                    if (response.data && response.data.data) {
                        this.identificationTypes = response.data.data
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        changePage (link, index) {
            let newPage = null
            if (this.metaDataUser.links.length > 3) {
                if (index === 0 && this.metaDataUser.current_page != 1) {
                    /**
                     * Previous
                     */
                    newPage = this.metaDataUser.current_page - 1;
                } else if (
                    index === (this.metaDataUser.links.length - 1)
                    &&
                    this.metaDataUser.current_page != this.metaDataUser.last_page
                ) {
                    /**
                     * Next
                     */
                    newPage = this.metaDataUser.current_page + 1;
                } else if (link.label === '...') {
                    newPage = Number(this.metaDataUser.links[index - 1].label) + 1
                } else if (link.label && !isNaN(link.label)) {
                    newPage = Number(link.label)
                }
            }
            if (newPage) {
                this.filterDataUser.page = newPage
                this.getAllUsers()
            }
        },
        changeNRecords (event) {
            this.filterDataUser.page = 1
            this.getAllUsers()
        },
        showStats (item) {
            const isSomeSemesterOption = this.semesterOptions.some(item => item.id == this.semesterSelected)
            this.$store.dispatch('user/getStatsCaseAreasByUser', {
                user_id: item.id,
                year: (isSomeSemesterOption)
                    ? this.semesterOptions.find(item => item.id == this.semesterSelected).year
                    : null,
                semester: (isSomeSemesterOption)
                    ? this.semesterOptions.find(item => item.id == this.semesterSelected).semester
                    : null,
            }).then(response => {
                if (response.data) {
                    this.users.map(user => {
                        if (user.id === item.id) {
                            user.stats_case_areas = response.data
                        }
                    })
                }
            })
            this.users.map(elem => {
                if (elem.id === item.id) {
                    elem.show_stats = (elem.show_stats) ? false : true
                }
            });
        }
    },
}
</script>
