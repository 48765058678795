<template>
    <div class="-m-1.5 flex flex-wrap items-center">
        <div class="m-1.5">
            <ModalBasic id="notes-modal" :backdrop="true" :modal-open="isOpen" :show-border-header="false"
                :title="$t('calendar.modalNewAgenda.title')" @close-modal="close">
                <Form ref="form" v-slot="{ errors }" :validation-schema="newAgendaSchema" @submit="confirm">
                    <div class="px-5 pb-4">
                        <div class="space-y-5">
                            <div>
                                <label class="mb-1 block text-xs font-bold" for="title">
                                    {{ $t('calendar.modalNewAgenda.formTitle') }}
                                </label>
                                <Field v-slot="{ field }" v-model="title" name="title">
                                    <input v-bind="field" id="title" class="form-input w-full p-2" type="text" required
                                        :placeholder="$t('calendar.modalNewAgenda.formTitle')" />
                                </Field>
                                <div v-if="errors.title !== undefined" class="flex w-full">
                                    <span class="text-sm text-red-500">
                                        {{ $t(errors.title) }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <label class="mb-1 block text-xs font-bold" for="agendaTypes">
                                    {{ $t('calendar.modalNewAgenda.agendaType') }}
                                </label>
                                <Field v-slot="{ field }" v-model="typeOfAgenda" name="typeOfAgenda">
                                    <select id="agendaTypes" v-bind="field" class="form-select w-full p-2"
                                        @change="changeType">
                                        <option
                                        v-for="agendaType in agendaTypes"
                                        :key="agendaType.id"
                                        :value="agendaType.id">
                                            {{ agendaType.name }}
                                        </option>
                                    </select>
                                </Field>
                                <div v-if="errors.typeOfAgenda !== undefined" class="flex w-full">
                                    <span class="text-sm text-red-500">
                                        {{ $t(errors.typeOfAgenda) }}
                                    </span>
                                </div>
                            </div>
                            <div v-if="isMeeting">
                                <label class="mb-1 block text-xs font-bold" for="meetingTypes">
                                    {{ $t('calendar.modalNewAgenda.meetingType') }}
                                </label>
                                <select id="meetingTypes" v-model="meeting_type" class="form-select w-full p-2">
                                    <option v-for="meetingType in meetingTypes" :key="meetingType.id"
                                        :value="meetingType.id">
                                        {{ meetingType.name }}
                                    </option>
                                </select>
                            </div>
                            <div v-if="meeting_type === 'video' || meeting_type === 'mixed'">
                                <label class="mb-1 block text-xs font-bold" for="meeting_url">
                                    {{ $t('calendar.modalNewAgenda.meetingURL') }}
                                </label>
                                <Field v-slot="{ field }" v-model="meeting_url" name="meeting_url">
                                    <input
                                    v-bind="field"
                                    id="meeting_url"
                                    class="form-input w-full p-2"
                                    type="text"
                                    required
                                    :placeholder="$t('calendar.modalNewAgenda.meetingURL')"
                                    />
                                </Field>
                                <div v-if="errors.meeting_url !== undefined" class="flex w-full">
                                    <span class="text-sm text-red-500">
                                        {{ $t(errors.meeting_url) }}
                                    </span>
                                </div>
                            </div>
                            <div v-if="meeting_type === 'in-person' || meeting_type === 'mixed'">
                                <label class="mb-1 block text-xs font-bold" for="meeting_in_person_address">
                                    {{ $t('calendar.modalNewAgenda.inPersonAddress') }}
                                </label>
                                <Field v-slot="{ field }" v-model="meeting_in_person_address" name="meeting_in_person_address">
                                    <input
                                    v-bind="field"
                                    id="meeting_in_person_address"
                                    class="form-input w-full p-2"
                                    type="text"
                                    required
                                    :placeholder="$t('calendar.modalNewAgenda.inPersonAddress')"
                                    />
                                </Field>
                                <div v-if="errors.meeting_in_person_address !== undefined" class="flex w-full">
                                    <span class="text-sm text-red-500">
                                        {{ $t(errors.meeting_in_person_address) }}
                                    </span>
                                </div>
                            </div>
                            <div class="flex justify-between">
                                <div class="flex w-2/6 flex-col pr-4">
                                    <label class="mb-1 block text-xs font-bold" for="meetingTime">
                                        {{ $t('calendar.modalNewAgenda.meetingTime') }}
                                    </label>
                                    <Datepicker
                                    v-model="meeting_date"
                                    :max-today="false"
                                    :max-one-month="false"
                                    :min-today="true"
                                    :show-icon="false"
                                    :date-only="true"
                                    :new-value="newDatePickerValue"
                                    wrap-class="form-input h-11 w-full rounded-md" mode="single"
                                    @change-date="changeDate"
                                    />
                                </div>
                                <div class="flex w-2/6 flex-col pr-4">
                                    <label class="mb-1 block text-xs font-bold" for="from">
                                        {{ $t('calendar.modalNewAgenda.from') }}
                                    </label>
                                    <VueDatepicker
                                    :min-time="getMinMeetingTimeFrom"
                                    :max-time="getMaxMeetingTimeFrom"
                                    v-model="meeting_time_from"
                                    time-picker
                                    input-class-name="form-input h-11 w-full pl-10 rounded-md" />
                                </div>
                                <div class="flex w-2/6 flex-col pr-4">
                                    <label class="mb-1 block text-xs font-bold" for="to">
                                        {{ $t('calendar.modalNewAgenda.to') }}
                                    </label>
                                    <VueDatepicker
                                    :min-time="getMinMeetingTimeTo"
                                    v-model="meeting_time_to"
                                    time-picker
                                    input-class-name="form-input h-11 w-full pl-10 rounded-md"
                                    />
                                </div>
                            </div>
                            <div v-if="isMeeting">
                                <label class="mb-1 block text-xs font-bold" for="meetingInvitees">
                                    {{ $t('calendar.modalNewAgenda.meetingInvitees') }}
                                </label>
                                <VueMultiselect
                                v-model="party_ids"
                                :options="meetingInvitees.filter((item) => $store.getters['auth/getCurrentUser'] && item.id !== $store.getters['auth/getCurrentUser'].id)"
                                :multiple="true"
                                :close-on-select="true"
                                :placeholder="$t('validation.selectInvitees')" label="name"
                                track-by="id"
                                :allow-empty="true">
                                <template #singleLabel="props">
                                        <span class="option__desc">
                                            <span class="option__title">
                                                {{ props.option.name }}
                                            </span>
                                        </span>
                                    </template>
                                    <template #option="props">
                                        <div class="option__desc space-x-2">
                                            <span class="option__title">
                                                {{ props.option.name }}
                                            </span>
                                            <div class="option__small inline rounded-lg px-2 py-1 text-xs font-bold">
                                                {{ props.option.role.name }}
                                            </div>
                                        </div>
                                    </template>
                                </VueMultiselect>
                            </div>
                            <div class="flex justify-between">
                                <div class="flex w-3/6 flex-col pr-4">
                                    <label class="mb-1 block text-xs font-bold" for="description">
                                        {{ $t('calendar.modalNewAgenda.agenda') }}
                                    </label>

                                    <Field v-slot="{ field }" v-model="description" name="description">
                                        <textarea v-bind="field" class="form-input w-full resize-y p-2"
                                            :placeholder="$t('calendar.modalNewAgenda.agenda')"></textarea>
                                    </Field>
                                    <div v-if="errors.description !== undefined" class="flex w-full">
                                        <span class="text-sm text-red-500">
                                            {{ $t(errors.description) }}
                                        </span>
                                    </div>
                                </div>

                                <div class="flex w-3/6 flex-col pr-4">
                                    <label class="mb-1 block text-xs font-bold" for="otherDetails">
                                        {{ $t('calendar.modalNewAgenda.otherDetails') }}
                                    </label>
                                    <textarea v-model="detail" :placeholder="$t('calendar.modalNewAgenda.otherDetailPlaceholder')
                                        " class="form-input w-full p-2"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-5 py-4">
                        <div class="flex flex-wrap justify-center space-x-2">
                            <button type="submit"
                                class="btn-sm w-full whitespace-nowrap rounded-lg bg-sky-500 p-3 text-white hover:bg-sky-600"
                                :class="[isLoading ? 'cursor-not-allowed' : 'cursor-pointer']">
                                <span v-if="isLoading">
                                    {{ $t('general.loading') }}
                                </span>
                                <span v-else>
                                    {{ $t('calendar.modalNewAgenda.addEvent') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </Form>
            </ModalBasic>
        </div>
    </div>
</template>
<script>
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'
import ModalBasic from '@/components/ModalBasic.vue'
import Datepicker from '@/components/Datepicker.vue'
import VueDatepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    inject: ['dayjs'],
    name: 'ModalNewAgendaComponent',
    components: {
        Form,
        Field,
        ModalBasic,
        Datepicker,
        VueDatepicker,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        meetingInvitees: {
            type: Array,
            default: () => {
                return []
            },
        },
        meetingTypes: {
            type: Array,
            default: () => {
                return []
            },
        },
        agendaTypes: {
            type: Array,
            default: () => {
                return []
            },
        },
        meetingPlatforms: {
            type: Array,
            default: () => {
                return []
            },
        },
        toast: {
            type: Function,
            default: () => {}
        },
        currentDatesSelected: {
            type: Object,
            default: null
        }
    },
    emits: ['closed', 'confirm', 'empty-member', 'empty-time', 'invalid-time'],
    data: function () {
        return {
            party_ids: [],
            title: null,
            typeOfAgenda: 'Meeting',
            description: '',
            detail: '',
            meeting_date: null,
            meeting_url: '',
            meeting_type: 'video',
            meeting_time_from: null,
            meeting_time_to: null,
            meeting_in_person_address: null,
            newDatePickerValue: null,
            newAgendaSchema: Yup.object().shape({
                typeOfAgenda: Yup.string().required('validation.selectAgendaType'),
                title: Yup.string()
                    .typeError('validation.enterAgendaTitle')
                    .required('validation.enterAgendaTitle'),
                description: Yup.string()
                    .typeError('validation.enterAgenda')
                    .required('validation.enterAgenda'),
                meeting_url: Yup.string().url('validation.enterCorrectURL'),
            }),
        }
    },
    watch: {
        isOpen(val) {
            if (!val) this.close()
            else this.$refs.form.resetForm()
        },
        currentDatesSelected (val) {
            if (val) {
                this.newDatePickerValue = new Date(val.start.add(1, 'day').format('YYYY-MM-DD'));
                if (val.start.format('YYYY-MM-DD') === val.end.format('YYYY-MM-DD')) {
                    this.meeting_time_from = {
                        hours: val.start.format('HH'),
                        minutes: val.start.format('mm')
                    }
                    this.meeting_time_to = {
                        hours: val.end.format('HH'),
                        minutes: val.end.format('mm')
                    }
                }
            }
        }
    },
    methods: {
        close() {
            this.$refs.form.resetForm()
            this.party_ids = []
            this.meeting_time_from = ''
            this.meeting_time_to = ''
            this.$emit('closed')
        },
        confirm() {
            if (this.party_ids.length <= 0 && this.typeOfAgenda === 'Meeting') {
                return this.$emit('empty-member')
            }

            if (
                this.meeting_time_from === '' ||
                this.meeting_time_from === null ||
                this.meeting_time_to === '' ||
                this.meeting_time_to === null
            ) {
                return this.$emit('empty-time')
            }

            if (this.checkTime()) {
                return this.$emit('invalid-time')
            }

            if ((this.meeting_type === 'video' || this.meeting_type === 'mixed') && !this.meeting_url) {
                this.toast.error(this.$t('validation.enterMeetingURL'))
                return
            }

            if ((this.meeting_type === 'in-person' || this.meeting_type === 'mixed') && !this.meeting_in_person_address) {
                this.toast.error(this.$t('validation.enterMeetingInPersonAddress'))
                return
            }

            const newAgendaData = {}
            newAgendaData.party_ids = this.party_ids.map((item) => item.id)
            newAgendaData.title = this.title
            newAgendaData.type = this.typeOfAgenda
            newAgendaData.description = this.description
            newAgendaData.detail = this.detail
            newAgendaData.meeting_date = this.meeting_date
            newAgendaData.due_date = this.meeting_date
            newAgendaData.meeting_url = this.meeting_url
            newAgendaData.meeting_in_person_address = this.meeting_in_person_address
            newAgendaData.meeting_type = this.meeting_type
            newAgendaData.meeting_time_from = this.meeting_time_from.hours + ':' + this.meeting_time_from.minutes
            newAgendaData.meeting_time_to = this.meeting_time_to.hours + ':' + this.meeting_time_to.minutes

            this.$emit('confirm', newAgendaData)
        },
        changeDate(val) {
            this.meeting_date = val
        },
        checkTime() {
            const timeFrom = this.meeting_time_from
            const timeTo = this.meeting_time_to

            let hourFrom = 0
            let minuteFrom = 0
            let hourTo = 0
            let minuteTo = 0
            if (timeFrom !== null) {
                hourFrom = timeFrom.hours
                minuteFrom = timeFrom.minutes
                // const timeFromArr = timeFrom.id.split(':')
                // hourFrom = parseInt(timeFromArr[0])
                // minuteFrom = parseInt(timeFromArr[1])
            }
            if (timeTo !== null) {
                // const timeToArr = timeTo.id.split(':')
                hourTo = timeTo.hours
                minuteTo = timeTo.minutes
                // hourTo = parseInt(timeToArr[0])
                // minuteTo = parseInt(timeToArr[1])
            }

            if (hourTo === hourFrom) {
                if (minuteFrom >= minuteTo) {
                    return true
                }
            }

            if (hourFrom > hourTo) {
                return true
            }

            return false
        },
    },
    computed: {
        getMinMeetingTimeFrom () {
            const now = this.dayjs()
            let limit = null
            if (
                this.meeting_date
                &&
                this.dayjs(this.meeting_date).format('YYYY-MM-DD') === now.format('YYYY-MM-DD')
            ) {
                limit = { hours: now.format('HH'), minutes: now.format('mm') }
            }
            return limit
        },
        getMaxMeetingTimeFrom () {
            let limit = null
            if (
                this.meeting_time_to
            ) {
                const completedDate = this.dayjs(
                    this.dayjs(
                        this.meeting_date
                    ).format('YYYY-MM-DD')
                    + ' '
                    + this.meeting_time_to.hours + ':' + this.meeting_time_to.minutes
                ).subtract(5, 'minute')
                limit = { hours: completedDate.format('HH'), minutes: completedDate.format('mm') }
            }
            return limit
        },
        getMinMeetingTimeTo () {
            const now = this.dayjs()
            let limit = null
            if (
                this.meeting_time_from
            ) {
                const completedDate = this.dayjs(
                    this.dayjs(
                        this.meeting_date
                    ).format('YYYY-MM-DD')
                    + ' '
                    + this.meeting_time_from.hours + ':' + this.meeting_time_from.minutes
                ).add(5, 'minute')
                limit = { hours: completedDate.format('HH'), minutes: completedDate.format('mm') }
            } else if (
                this.meeting_date
                &&
                this.dayjs(this.meeting_date).format('YYYY-MM-DD') === now.format('YYYY-MM-DD')
            ) {
                limit = { hours: now.add(5, 'minute').format('HH'), minutes: now.add(5, 'minute').format('mm') }
            }
            return limit
        },
        isMeeting () {
            return this.typeOfAgenda && this.typeOfAgenda.toLowerCase() === 'meeting'
        }
    },
}
</script>
