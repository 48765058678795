<template>
    <div class="grow">
        <!-- Panel body -->
        <div class="space-y-4 p-6">
            <h2 class="text-2xl font-bold text-slate-800">
                {{ $t('profile.rating.title') }}
            </h2>
            <div class="flex items-center space-x-2">
                <div class="flex flex-row">
                    <svg
                    v-for="index in 5"
                    :key="index"
                    :class="[
                        'h-4 w-4 shrink-0 fill-current',
                        (getAverage && getAverage >= index)
                        ? 'text-amber-400'
                        : 'text-slate-300'
                    ]"
                    >
                        <path
                        d="M8 0L6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934h-6L8 0z"
                        />
                    </svg>
                </div>
                <span class="text-sm text-gray-500">{{
                    $t('profile.rating.totalReviews', { total: allReviews.length })
                }}</span>
            </div>
            <div class="space-y-4 py-4">
                <RatingItem v-for="review in allReviews" :key="review.id" :rating-data="review" />
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixins from '@/mixins/global.js'
import profileMixins from '@/mixins/profile.js'

import RatingItem from '@/components/RatingItem.vue'

export default {
    name: 'RatingPanel',
    components: {
        RatingItem,
    },
    mixins: [globalMixins, profileMixins],
    setup() {
        const store = useStore()
        const toast = useToast()

        return {
            store,
            toast,
        }
    },
    data: () => ({
        allReviews: [],
        filterDataReview: {
            page: 1,
            take: 300,
            search: '',
            order: 'DESC',
        },
        metaDataReview: {
            page: 1,
            take: 300,
            itemCount: 0,
            pageCount: 1,
            hasPreviousPage: false,
            hasNextPage: true,
        },
    }),
    mounted() {
        this.getAllReview()
    },
    methods: {
        async getAllReview() {
            this.isLoading = true
            await this.store
                .dispatch('review/getAllReview', {
                    page: this.filterDataReview.page,
                    take: this.filterDataReview.take,
                    order: this.filterDataReview.order,
                })
                .then((response) => {
                    this.isLoading = false
                    if (response.data) {
                        if (response.data.data) {
                            this.allReviews = response.data.data
                        }
                        if (response.data.from) {
                            this.metaDataReview = {
                                current_page: response.data.current_page,
                                from: response.data.from,
                                last_page: response.data.last_page,
                                links: response.data.links,
                                per_page: response.data.per_page,
                                to: response.data.to,
                                total: response.data.total,
                            }
                        }
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
    },
    computed: {
        getAverage () {
            return this.$store.getters['auth/getCurrentUser']
                ? this.$store.getters['auth/getCurrentUser'].average_rating
                : 0;
        }
    }
}
</script>
