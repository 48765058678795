<template>
    <main class="bg-white">
        <div class="relative flex">
            <!-- Content -->
            <div class="w-full">
                <div
                    class="flex h-full min-h-screen flex-col justify-center bg-gray-100"
                >
                    <div class="mb-8 flex justify-center">
                        <img
                            class="h-14 object-cover object-center"
                            src="../assets/images/logo.png"
                            alt="Redek Logo"
                        />
                    </div>
                    <div
                        class="w-full self-center rounded-lg bg-white px-8 py-8 shadow-md lg:w-1/3"
                    >
                        <h1 class="mb-2 text-3xl font-bold text-slate-800">
                            {{ $t('resetPassword.title') }}
                        </h1>
                        <p class="text-justify text-sm">
                            {{ $t('resetPassword.description') }}
                        </p>
                        <Form
                            ref="form"
                            v-slot="{ errors }"
                            :validation-schema="resetPasswordSchema"
                            @submit="resetPassword"
                        >
                            <div class="mt-4 space-y-4">
                                <div>
                                    <label
                                        class="mb-1 block text-sm font-medium"
                                        for="password"
                                    >
                                        {{ $t('formLabel.newPassword') }}
                                        <span class="text-rose-500"
                                            >*</span
                                        ></label
                                    >
                                    <Field
                                        v-slot="{ field }"
                                        v-model="password"
                                        name="password"
                                    >
                                        <div class="relative">
                                            <input
                                                id="password"
                                                v-bind="field"
                                                autocomplete="new-password"
                                                class="form-input h-12 w-full rounded-md"
                                                :type="
                                                    showPassword
                                                        ? 'text'
                                                        : 'password'
                                                "
                                                :placeholder="
                                                    $t(
                                                        'formInput.enterNewPassword'
                                                    )
                                                "
                                            />
                                            <div
                                                class="absolute inset-0 left-auto flex cursor-pointer items-center"
                                            >
                                                <span
                                                    class="px-3 text-sm font-medium text-slate-400"
                                                >
                                                    <EyeStrikeIcon
                                                        v-if="showPassword"
                                                        class="h-6 w-6"
                                                        @click="
                                                            showPassword = false
                                                        "
                                                    />
                                                    <EyeIcon
                                                        v-else
                                                        class="h-6 w-6"
                                                        @click="
                                                            showPassword = true
                                                        "
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </Field>
                                    <span class="text-sm text-red-500">
                                        <template
                                            v-if="errors.password !== undefined"
                                        >
                                            {{ $t(errors.password) }}
                                        </template>
                                    </span>
                                </div>
                                <div>
                                    <label
                                        class="mb-1 block text-sm font-medium"
                                        for="confirmPassword"
                                    >
                                        {{
                                            $t(
                                                'formLabel.newPasswordConfirmation'
                                            )
                                        }}
                                        <span class="text-rose-500"
                                            >*</span
                                        ></label
                                    >
                                    <Field
                                        v-slot="{ field }"
                                        v-model="confirmPassword"
                                        name="confirmPassword"
                                    >
                                        <div class="relative">
                                            <input
                                                id="confirmPassword"
                                                v-bind="field"
                                                class="form-input h-12 w-full rounded-md"
                                                autocomplete="new-password"
                                                :type="
                                                    showConfirmPassword
                                                        ? 'text'
                                                        : 'password'
                                                "
                                                :placeholder="
                                                    $t(
                                                        'formInput.enterNewConfirmPassword'
                                                    )
                                                "
                                                required="true"
                                            />
                                            <div
                                                class="absolute inset-0 left-auto flex cursor-pointer items-center"
                                            >
                                                <span
                                                    class="px-3 text-sm font-medium text-slate-400"
                                                >
                                                    <EyeStrikeIcon
                                                        v-if="
                                                            showConfirmPassword
                                                        "
                                                        class="h-6 w-6"
                                                        @click="
                                                            showConfirmPassword = false
                                                        "
                                                    />
                                                    <EyeIcon
                                                        v-else
                                                        class="h-6 w-6"
                                                        @click="
                                                            showConfirmPassword = true
                                                        "
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </Field>
                                    <span class="text-sm text-red-500">
                                        <template
                                            v-if="
                                                errors.confirmPassword !==
                                                undefined
                                            "
                                        >
                                            {{ $t(errors.confirmPassword) }}
                                        </template>
                                    </span>
                                </div>
                            </div>
                            <div class="mt-1 flex flex-col">
                                <button
                                    class="btn text-md mt-2 h-12 w-full rounded-md"
                                    :class="[
                                        confirmPassword === '' ||
                                        password === '' ||
                                        errors.confirmPassword !== undefined ||
                                        errors.password !== undefined
                                            ? 'bg-gray-200 text-gray-50'
                                            : 'bg-sky-500 text-white hover:bg-sky-600',
                                        isLoading
                                            ? 'cursor-not-allowed'
                                            : 'cursor-pointer',
                                    ]"
                                    type="submit"
                                >
                                    <span v-if="isLoading">
                                        {{ $t('general.loading') }}
                                    </span>
                                    <span v-else>
                                        {{ $t('button.resetPassword') }}
                                    </span>
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { useToast } from 'vue-toastification'
import { Form, Field } from 'vee-validate'
import globalMixin from '@/mixins/global.js'

import EyeIcon from '@/assets/svg/eye.svg'
import EyeStrikeIcon from '@/assets/svg/eye-strike.svg'

export default {
    name: 'ForgotPasswordPage',
    components: {
        Form,
        Field,
        EyeIcon,
        EyeStrikeIcon,
    },
    mixins: [globalMixin],
    setup() {
        const toast = useToast()
        return { toast }
    },
    data: () => ({
        password: '',
        showPassword: false,
        confirmPassword: '',
        showConfirmPassword: false,
        token: null,
        email: null,
    }),
    mounted() {
        const token =
            typeof this.$route.query.token !== 'undefined'
                ? this.$route.query.token
                : null
        const email =
            typeof this.$route.query.email !== 'undefined'
                ? this.$route.query.email
                : null

        if (token !== null && token !== '' && email !== null && email !== '') {
            this.token = token
            this.email = email
        } else {
            this.toast.error(this.$t('message.failed.auth.tokenExpired'))
            return this.$router.push({
                name: 'forgot-password',
                query: {
                    lang: this.$route.query.lang,
                },
            })
        }
    },
    methods: {
        resetPassword() {
            this.isLoading = true
            const payload = {
                token: this.token,
                email: this.email,
                password: this.password,
                password_confirmation: this.confirmPassword,
            }
            this.$store
                .dispatch('auth/resetPassword', payload)
                .then((resetPasswordResponse) => {
                    this.isLoading = false
                    this.toast.success(
                        this.$t('message.success.auth.resetPassword')
                    )
                    localStorage.setItem(
                        'token',
                        resetPasswordResponse.access_token
                    )
                    this.$store
                        .dispatch('auth/user')
                        .then((userResponse) => {
                            localStorage.setItem(
                                'redek.temp.activeUser',
                                JSON.stringify(userResponse)
                            )
                            if (userResponse.is_active_otp) {
                                /**
                                 * Send OTP
                                 */
                                this.$store
                                    .dispatch('auth/sendOtp')
                                    .catch((error) => {
                                        this.toast.error(
                                            this.extractErrorResponse(error)
                                        )
                                    })
                                this.$router.push({
                                    name: 'verification-otp',
                                    params: {
                                        type: 'login',
                                    },
                                    query: {
                                        lang: this.$route.query.lang,
                                    },
                                })
                            } else {
                                this.$store
                                    .dispatch('auth/loginWhenOtpIsOff', {
                                        access_token:
                                        resetPasswordResponse.access_token,
                                    })
                                    .then((response) => {
                                        this.$store.dispatch(
                                            'auth/resetActiveUserTemp'
                                        )
                                        this.verifyRedirectWhenLogin(userResponse);
                                    })
                                    .catch((error) => {
                                        if (error.response.data.message.code)
                                            this.toast.error(
                                                this.$t(
                                                    'error.response.data.message.code'
                                                )
                                            )
                                    })
                            }
                        })
                        .catch((errorUser) => {
                            this.isLoading = false
                            this.toast.error(
                                this.extractErrorResponse(errorUser)
                            )
                        })
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
    },
}
</script>
