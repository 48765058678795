<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Sidebar -->
    <Sidebar
      :sidebar-open="sidebarOpen"
      :case-stage="
        activeCase.stage !== undefined ? activeCase.stage : $route.query.status
      "
      @close-sidebar="sidebarOpen = false"
    />

    <!-- Content area -->
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <main>
        <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
          <div class="flex w-full flex-col pb-4">
            <h1 class="text-2xl font-bold text-black">
              {{ $t('caseStage.title') }}
            </h1>
            <p>{{ $t('caseStage.description') }}</p>
          </div>
          <!-- Case Stage -->
          <div v-if="!isLoading" class="flex h-full flex-col">
            <div class="flex-1">
              <!-- Progress bar -->
              <div class="px-4 pt-12 pb-8">
                <div class="w-full">
                  <!-- on prepare -->
                  <div v-if="activeCase.stage === 1" class="relative">
                    <div
                      class="absolute left-0 top-1/2 -mt-px h-0.5 w-full bg-slate-200"
                      aria-hidden="true"
                    ></div>
                    <ul class="relative flex w-full justify-between">
                      <li>
                        <div
                          class="mdxt-xs flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-sky-500 font-semibold text-white"
                        >
                          1
                        </div>
                      </li>
                      <li>
                        <div
                          class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-2 border-slate-300 bg-slate-100 font-semibold text-slate-500"
                        >
                          2
                        </div>
                      </li>
                      <li>
                        <div
                          class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-2 border-slate-300 bg-slate-100 font-semibold text-slate-500"
                        >
                          3
                        </div>
                      </li>
                      <li>
                        <div
                          class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-2 border-slate-300 bg-slate-100 font-semibold text-slate-500"
                        >
                          4
                        </div>
                      </li>
                      <li>
                        <div
                          class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-2 border-slate-300 bg-slate-100 font-semibold text-slate-500"
                        >
                          5
                        </div>
                      </li>
                    </ul>
                  </div>
                  <!-- on going -->
                  <div
                    v-if="[2, 3, 4].includes(activeCase.stage)"
                    class="relative"
                  >
                    <div
                      v-if="
                        activeCase.stage === 2 && !activeCase.mediator_initiate
                      "
                    >
                      <div class="absolute top-1/2 w-full">
                        <div class="flex w-full">
                          <div
                            class="flex h-0.5 w-1/4 bg-sky-300"
                            aria-hidden="true"
                          ></div>
                          <div
                            class="flex h-0.5 w-3/4 bg-slate-200"
                            aria-hidden="true"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        (activeCase.stage === 3 ||
                          activeCase.mediator_initiate) &&
                        !activeCase.arbitrator_initiate
                      "
                    >
                      <div class="absolute top-1/2 w-full">
                        <div class="flex w-full">
                          <div
                            class="flex h-0.5 w-1/4 bg-green-300"
                            aria-hidden="true"
                          ></div>
                          <div
                            class="flex h-0.5 w-1/4"
                            :class="[
                              activeCase.mediator_initiate &&
                              activeCase.stage !== 3
                                ? 'bg-orange-300'
                                : 'bg-sky-300',
                            ]"
                            aria-hidden="true"
                          ></div>
                          <div
                            class="flex h-0.5 w-2/4 bg-slate-200"
                            aria-hidden="true"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        activeCase.stage === 4 || activeCase.arbitrator_initiate
                      "
                    >
                      <div class="absolute top-1/2 w-full">
                        <div class="flex w-full">
                          <div
                            class="flex h-0.5 w-2/4 bg-green-300"
                            aria-hidden="true"
                          ></div>
                          <div
                            class="flex h-0.5 w-1/4"
                            :class="[
                              activeCase.arbitrator_initiate &&
                              activeCase.stage !== 4
                                ? 'bg-orange-300'
                                : 'bg-sky-300',
                            ]"
                            aria-hidden="true"
                          ></div>
                          <div
                            class="flex h-0.5 w-1/4 bg-slate-200"
                            aria-hidden="true"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <ul
                      v-if="
                        activeCase.stage === 2 && !activeCase.mediator_initiate
                      "
                      class="relative flex w-full justify-between"
                    >
                      <li>
                        <div
                          class="mdxt-xs flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white"
                        >
                          1
                        </div>
                      </li>
                      <li>
                        <div
                          class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-sky-500 font-semibold text-white"
                        >
                          2
                        </div>
                      </li>
                      <li>
                        <div
                          class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-2 border-slate-300 bg-slate-100 font-semibold text-slate-500"
                        >
                          3
                        </div>
                      </li>
                      <li>
                        <div
                          class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-2 border-slate-300 bg-slate-100 font-semibold text-slate-500"
                        >
                          4
                        </div>
                      </li>
                      <li>
                        <div
                          class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-2 border-slate-300 bg-slate-100 font-semibold text-slate-500"
                        >
                          5
                        </div>
                      </li>
                    </ul>
                    <ul
                      v-else-if="
                        (activeCase.stage === 3 ||
                          activeCase.mediator_initiate) &&
                        !activeCase.arbitrator_initiate
                      "
                      class="relative flex w-full justify-between"
                    >
                      <li>
                        <div
                          class="mdxt-xs flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white"
                        >
                          1
                        </div>
                      </li>
                      <li>
                        <div
                          class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white"
                        >
                          2
                        </div>
                      </li>
                      <li>
                        <div
                          class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full font-semibold text-white"
                          :class="[
                            activeCase.mediator_initiate &&
                            activeCase.stage !== 3
                              ? 'bg-orange-500'
                              : 'bg-sky-500',
                          ]"
                        >
                          3
                        </div>
                      </li>
                      <li>
                        <div
                          class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-2 border-slate-300 bg-slate-100 font-semibold text-slate-500"
                        >
                          4
                        </div>
                      </li>
                      <li>
                        <div
                          class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-2 border-slate-300 bg-slate-100 font-semibold text-slate-500"
                        >
                          5
                        </div>
                      </li>
                    </ul>
                    <ul
                      v-else-if="
                        activeCase.stage === 4 || activeCase.arbitrator_initiate
                      "
                      class="relative flex w-full justify-between"
                    >
                      <li>
                        <div
                          class="mdxt-xs flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white"
                        >
                          1
                        </div>
                      </li>
                      <li>
                        <div
                          class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white"
                        >
                          2
                        </div>
                      </li>
                      <li>
                        <div
                          class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white"
                        >
                          3
                        </div>
                      </li>
                      <li>
                        <div
                          class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full font-semibold text-white"
                          :class="[
                            activeCase.arbitrator_initiate &&
                            activeCase.stage !== 4
                              ? 'bg-orange-500'
                              : 'bg-sky-500',
                          ]"
                        >
                          4
                        </div>
                      </li>
                      <li>
                        <div
                          class="text-md flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-2 border-slate-300 bg-slate-100 font-semibold text-slate-500"
                        >
                          5
                        </div>
                      </li>
                    </ul>
                  </div>
                  <!-- solved -->
                  <div v-if="activeCase.stage === 5" class="relative">
                    <div
                      class="absolute left-0 top-1/2 -mt-px h-0.5 w-full bg-green-300"
                      aria-hidden="true"
                    ></div>
                    <ul class="relative flex w-full justify-between">
                      <li>
                        <div
                          class="mdxt-xs flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white"
                        >
                          1
                        </div>
                      </li>
                      <li>
                        <div
                          class="mdxt-xs flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white"
                        >
                          2
                        </div>
                      </li>
                      <li>
                        <div
                          class="mdxt-xs flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white"
                        >
                          3
                        </div>
                      </li>
                      <li>
                        <div
                          class="mdxt-xs flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white"
                        >
                          4
                        </div>
                      </li>
                      <li>
                        <div
                          class="mdxt-xs flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-green-500 font-semibold text-white"
                        >
                          5
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="relative px-4 py-8">
              <!-- On Prepare Stage -->
              <div
                v-if="activeCase.stage === 1"
                class="flex w-full cursor-pointer items-center justify-center rounded-lg bg-white px-8 py-8 text-sm text-black"
              >
                <div class="flex w-full flex-col">
                  <div class="mb-1 flex items-center justify-between">
                    <p class="text-lg font-bold text-sky-500">
                      {{ $t('caseStage.onPrepareStage') }}
                    </p>
                    <p
                      class="space-x-1 text-xs"
                      :class="[caseExpired ? 'text-red-400' : 'text-black']"
                    >
                      <span v-if="startDeadlineTime !== null">
                        {{ formatDateDay(startDeadlineTime) }}
                      </span>
                      <span>-</span>
                      <span v-if="lastDeadlineTime !== null">
                        {{ formatDateDay(lastDeadlineTime) }}
                      </span>
                    </p>
                  </div>

                  <div class="flex">
                    {{ $t('caseStage.onPrepareStageDesc') }}
                  </div>

                  <div class="mt-8 flex flex-col">
                    <p class="text-sm text-black">
                      {{ $t('caseStage.actionToDo') }}
                    </p>

                    <div
                      v-if="
                        ['BANK_LAWYER'].includes(activeUserRole) ||
                        (!checkEmptyObject(firstInitialChatRoom) &&
                          !checkEmptyObject(
                            firstInitialChatRoom.member.find(
                              (item) => item.user.id === activeUserId
                            )
                          ))
                      "
                      class="mt-4 flex w-full cursor-pointer items-center justify-center rounded-lg border bg-white px-6 py-6 text-sm text-black"
                      :class="[
                        initialChatRoom.length > 0 &&
                        ['BANK_LAWYER'].includes(activeUserRole)
                          ? 'border-green-400'
                          : '',
                      ]"
                      @click.stop="checkResponseToTheClaim"
                    >
                      <div class="flex items-center justify-center">
                        <ChatIcon />
                      </div>
                      <div class="ml-4 flex w-full flex-col">
                        <div class="flex">
                          {{ $t('caseStage.responseToTheClaim') }}
                        </div>
                      </div>
                      <div
                        v-if="
                          initialChatRoom.length > 0 &&
                          ['BANK_LAWYER'].includes(activeUserRole)
                        "
                        class="ml-4 flex flex-col"
                      >
                        <div class="flex">
                          <ChecklistIcon />
                        </div>
                      </div>
                      <div
                        v-else-if="
                          !checkEmptyObject(firstInitialChatRoom) &&
                          !checkEmptyObject(
                            firstInitialChatRoom.member.find(
                              (item) => item.user.id === activeUserId
                            )
                          )
                        "
                        class="ml-4 flex items-center"
                      >
                        <button
                          class="btn rounded-lg bg-sky-500 py-2 px-6 text-white hover:bg-sky-600"
                          @click.stop="checkResponseToTheClaim"
                        >
                          {{ $t('button.open') }}
                        </button>
                      </div>
                      <div v-else class="ml-4 flex items-center">
                        <button
                          class="btn rounded-lg bg-sky-500 py-2 px-6 text-white hover:bg-sky-600"
                          @click.stop="responseToTheClaim"
                        >
                          {{ $t('button.open') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End of On Prepare Stage -->

              <!-- Complaint Stage -->
              <div
                v-else-if="
                  [2, 3, 4].includes(activeCase.stage) && activeCase.stage === 2
                "
                class="flex w-full cursor-pointer items-center justify-center rounded-lg bg-white px-8 py-8 text-sm text-black"
                @click="toChatDiscussion(activeChat)"
              >
                <div class="flex w-full flex-col">
                  <div
                    v-if="!activeCase.mediator_initiate"
                    class="mb-8 flex flex-col"
                  >
                    <div class="mb-1 flex items-center justify-between">
                      <p class="text-lg font-bold text-sky-500">
                        {{ $t('caseStage.complaintStage') }}
                      </p>
                      <p
                        class="space-x-1 text-xs"
                        :class="[caseExpired ? 'text-red-400' : 'text-black']"
                      >
                        <span v-if="startDeadlineTime !== null">
                          {{ formatDateDay(startDeadlineTime) }}
                        </span>
                        <span>-</span>
                        <span v-if="lastDeadlineTime !== null">
                          {{ formatDateDay(lastDeadlineTime) }}
                        </span>
                      </p>
                    </div>

                    <p class="text-sm text-slate-500">
                      {{ $t('caseStage.complaintStageDesc') }}
                    </p>
                  </div>
                  <div v-else class="mb-8 flex flex-col">
                    <p class="text-lg font-bold text-orange-500">
                      {{ $t('caseStage.initiateEvidenceStage') }}
                    </p>
                    <p class="text-sm text-slate-500">
                      {{ $t('caseStage.initiateEvidenceStageDesc') }}
                    </p>
                  </div>

                  <div class="mb-8 flex flex-col">
                    <p class="text-sm text-black">
                      {{ $t('caseStage.actionToDo') }}
                    </p>

                    <div
                      v-if="
                        ['BANK_LAWYER'].includes(activeUserRole) ||
                        (!checkEmptyObject(firstInitialChatRoom) &&
                          !checkEmptyObject(
                            firstInitialChatRoom.member.find(
                              (item) => item.user.id === activeUserId
                            )
                          ))
                      "
                      class="mt-4 flex w-full cursor-pointer items-center justify-center rounded-lg border bg-white px-6 py-6 text-sm text-black"
                      :class="[
                        initialChatRoom.length > 0 &&
                        ['BANK_LAWYER'].includes(activeUserRole)
                          ? 'border-green-400'
                          : '',
                      ]"
                      @click.stop="checkResponseToTheClaim"
                    >
                      <div class="flex items-center justify-center">
                        <ChatIcon />
                      </div>
                      <div class="ml-4 flex w-full flex-col">
                        <div class="flex">
                          {{ $t('caseStage.responseToTheClaim') }}
                        </div>
                      </div>
                      <div
                        v-if="
                          initialChatRoom.length > 0 &&
                          ['BANK_LAWYER'].includes(activeUserRole)
                        "
                        class="ml-4 flex flex-col"
                      >
                        <div class="flex">
                          <ChecklistIcon />
                        </div>
                      </div>
                      <div
                        v-else-if="
                          !checkEmptyObject(firstInitialChatRoom) &&
                          !checkEmptyObject(
                            firstInitialChatRoom.member.find(
                              (item) => item.user.id === activeUserId
                            )
                          )
                        "
                        class="ml-4 flex items-center"
                      >
                        <button
                          class="btn rounded-lg bg-sky-500 py-2 px-6 text-white hover:bg-sky-600"
                          @click.stop="checkResponseToTheClaim"
                        >
                          {{ $t('button.open') }}
                        </button>
                      </div>
                      <div v-else class="ml-4 flex items-center">
                        <button
                          class="btn rounded-lg bg-sky-500 py-2 px-6 text-white hover:bg-sky-600"
                          @click.stop="responseToTheClaim"
                        >
                          {{ $t('button.open') }}
                        </button>
                      </div>
                    </div>

                    <div
                      class="mt-4 flex w-full cursor-pointer items-center justify-center rounded-lg border bg-white px-6 py-6 text-sm text-black"
                      :class="[isCurrentUserJoined ? 'border-green-400' : '']"
                      @click="toChatDiscussion(activeChat)"
                    >
                      <div class="flex items-center justify-center">
                        <ChatIcon />
                      </div>
                      <div class="ml-4 flex w-full flex-col">
                        <div class="flex">
                          {{ $t('caseStage.participateNegotiationChat') }}
                        </div>
                      </div>
                      <div
                        v-if="isCurrentUserJoined"
                        class="ml-4 flex flex-col"
                      >
                        <div class="flex">
                          <ChecklistIcon />
                        </div>
                      </div>
                      <div v-else class="ml-4 flex items-center">
                        <button
                          class="btn rounded-lg bg-sky-500 py-2 px-6 text-white hover:bg-sky-600"

                        >
                          {{ $t('button.open') }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-if="chatData.length > 0" class="mb-8 flex flex-col">
                    <p class="text-sm text-black">
                      {{ $t('caseStage.pendingOffer') }}
                    </p>
                    <div
                      v-for="chat in chatData"
                      :key="chat.id"
                      class="mt-4 flex w-full cursor-pointer items-center justify-center rounded-lg border bg-white px-6 py-6 text-sm text-black"
                      :class="[
                        chat.chat_offers[0].is_accept
                          ? 'hidden border-green-400'
                          : '',
                      ]"
                    >
                      <div class="flex items-center justify-center">
                        <CalendarIcon />
                      </div>
                      <div class="ml-4 flex w-full flex-col">
                        <div class="flex">{{ chat.content }}</div>
                        <div class="flex text-sm text-slate-500">
                          {{ formatDateDay(chat.deadline_at) }}
                        </div>
                      </div>
                      <div class="ml-4 flex flex-col">
                        <div v-if="chat.chat_offers[0].is_accept" class="flex">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12 2.75C17.109 2.75 21.25 6.892 21.25 12C21.25 17.108 17.109 21.25 12 21.25C6.892 21.25 2.75 17.108 2.75 12C2.75 6.892 6.892 2.75 12 2.75Z"
                              stroke="#19DE15"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.5 12L10.5008 15L16.5 9"
                              stroke="#19DE15"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <template v-if="hasBankLawyer">
                    <div
                      v-if="activeUserRole === 'REDEK_ADVOCATE'"
                      class="flex flex-row justify-end"
                    >
                      <button
                        v-if="!activeCase.mediator_initiate"
                        class="btn rounded-lg py-3 px-6 text-white"
                        :class="[
                          isCurrentUserJoined
                            ? 'cursor-pointer bg-sky-500 hover:bg-sky-600'
                            : 'cursor-not-allowed bg-gray-500',
                        ]"
                        @click.stop="
                          isCurrentUserJoined
                            ? (initiateMediatorModalOpen = true)
                            : ''
                        "
                      >
                        {{ $t('caseStage.inititateMediation') }}
                      </button>
                      <button
                        class="btn ml-4 rounded-lg py-3 px-6 text-white"
                        :class="[
                          isCurrentUserJoined
                            ? 'cursor-pointer bg-green-500 hover:bg-green-600'
                            : 'cursor-not-allowed bg-gray-500',
                        ]"
                        @click.stop="
                          isCurrentUserJoined
                            ? (resolveCaseModalOpen = true)
                            : ''
                        "
                      >
                        {{ $t('button.resolveTheCase') }}
                      </button>
                    </div>
                    <div
                      v-if="
                        caseExpired &&
                        ['BANK_LAWYER', 'REDEK_ADVOCATE'].includes(
                          activeUserRole
                        )
                      "
                      class="mt-2 flex w-full justify-end"
                    >
                      <button
                        class="hover:text-sky-800"
                        @click.stop="showExtentionModal"
                      >
                        {{ $t('button.timerExtension') }}
                      </button>
                    </div>
                  </template>
                  <div v-else>
                    <p class="text-red-400">
                      {{ $t('caseStage.waitingBankLawyer') }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- End of Complaint Stage -->

              <!-- Evidence Stage -->
              <div
                v-else-if="
                  [2, 3, 4].includes(activeCase.stage) && activeCase.stage === 3
                "
                class="flex w-full cursor-pointer items-center justify-center rounded-lg bg-white px-8 py-8 text-sm text-black"
              >
                <div class="flex w-full flex-col">
                  <div
                    v-if="!activeCase.arbitrator_initiate"
                    class="mb-8 flex flex-col"
                  >
                    <div class="flex items-center justify-between">
                      <p class="text-lg font-bold text-sky-500">
                        {{ $t('caseStage.evidenceStage') }}
                      </p>
                      <p
                        class="space-x-1 text-xs"
                        :class="[caseExpired ? 'text-red-400' : 'text-black']"
                      >
                        <span v-if="startDeadlineTime !== null">
                          {{ formatDateDay(startDeadlineTime) }}
                        </span>
                        <span>-</span>
                        <span v-if="lastDeadlineTime !== null">
                          {{ formatDateDay(lastDeadlineTime) }}
                        </span>
                      </p>
                    </div>

                    <p class="text-sm text-slate-500">
                      {{ $t('caseStage.evidenceStageDesc') }}
                    </p>
                  </div>
                  <div v-else class="mb-8 flex flex-col">
                    <p class="text-lg font-bold text-orange-500">
                      {{ $t('caseStage.initiateHearingStage') }}
                    </p>
                    <p class="text-sm text-slate-500">
                      {{ $t('caseStage.initiateHearingStageDesc') }}
                    </p>
                  </div>

                  <div class="mb-8 flex flex-col">
                    <p class="text-sm text-black">
                      {{ $t('caseStage.actionToDo') }}
                    </p>
                    <div
                      class="mt-4 flex w-full cursor-pointer items-center justify-center rounded-lg border bg-white px-6 py-6 text-sm text-black"
                      :class="[isCurrentUserJoined ? 'border-green-400' : '']"
                      @click="toChatDiscussion(activeChat)"
                    >
                      <div class="flex items-center justify-center">
                        <ChatIcon />
                      </div>
                      <div class="ml-4 flex w-full flex-col">
                        <div class="flex">
                          {{ $t('caseStage.participateMediationChat') }}
                        </div>
                      </div>
                      <div
                        v-if="isCurrentUserJoined"
                        class="ml-4 flex flex-col"
                      >
                        <div class="flex">
                          <ChecklistIcon />
                        </div>
                      </div>
                      <div v-else class="ml-4 flex items-center">
                        <button
                          class="btn rounded-lg bg-sky-500 py-2 px-6 text-white hover:bg-sky-600"

                        >
                          {{ $t('button.open') }}
                        </button>
                      </div>
                    </div>
                  </div>

                  <section
                    v-if="activeUserRole === 'REDEK_MEDIATOR'"
                    class="space-y-4"
                  >
                    <div class="flex flex-row justify-end">
                      <button
                        v-if="!activeCase.arbitrator_initiate"
                        class="btn rounded-lg py-3 px-6 text-white"
                        :class="[
                          isCurrentUserJoined
                            ? 'cursor-pointer bg-sky-500 hover:bg-sky-600'
                            : 'cursor-not-allowed bg-gray-500',
                        ]"
                        @click.stop="
                          isCurrentUserJoined
                            ? (initiateArbitratorModalOpen = true)
                            : ''
                        "
                      >
                        {{ $t('caseStage.initiateHearingStage') }}
                      </button>
                      <button
                        class="btn ml-4 rounded-lg py-3 px-6 text-white"
                        :class="[
                          isCurrentUserJoined
                            ? 'cursor-pointer bg-green-500 hover:bg-green-600'
                            : 'cursor-not-allowed bg-gray-500',
                        ]"
                        @click.stop="
                          isCurrentUserJoined
                            ? (resolveCaseModalOpen = true)
                            : ''
                        "
                      >
                        {{ $t('button.resolveTheCase') }}
                      </button>
                    </div>
                    <div class="flex flex-row justify-end">
                      <button
                        class="btn ml-4 cursor-pointer rounded-lg bg-orange-500 py-3 px-6 text-white hover:bg-orange-600"
                        @click="toMediationAgreement()"
                      >
                        {{ $t('mediationAgreement.createMediation') }}
                      </button>
                    </div>
                  </section>
                  <div v-else class="flex flex-row justify-end">
                    <button
                      class="btn ml-4 cursor-pointer rounded-lg bg-orange-500 py-3 px-6 text-white hover:bg-orange-600"
                      @click="toMediationAgreement()"
                    >
                      {{ $t('mediationAgreement.show') }}
                    </button>
                  </div>

                  <div
                    v-if="
                      caseExpired &&
                      ['BANK_LAWYER', 'REDEK_MEDIATOR'].includes(activeUserRole)
                    "
                    class="mt-2 flex w-full justify-end"
                  >
                    <button
                      class="hover:text-sky-800"
                      @click.stop="showExtentionModal"
                    >
                      {{ $t('button.timerExtension') }}
                    </button>
                  </div>
                </div>
              </div>
              <!-- End of Evidence Stage -->

              <!-- Hearing Stage -->
              <div
                v-else-if="
                  [2, 3, 4].includes(activeCase.stage) && activeCase.stage === 4
                "
                class="flex w-full cursor-pointer items-center justify-center rounded-lg bg-white px-8 py-8 text-sm text-black"
              >
                <div class="flex w-full flex-col">
                  <div class="mb-8 flex flex-col">
                    <div class="flex items-center justify-between">
                      <p class="text-lg font-bold text-sky-500">
                        {{ $t('caseStage.hearingStage') }}
                      </p>
                      <p
                        class="space-x-1 text-xs"
                        :class="[caseExpired ? 'text-red-400' : 'text-black']"
                      >
                        <span v-if="startDeadlineTime !== null">
                          {{ formatDateDay(startDeadlineTime) }}
                        </span>
                        <span>-</span>
                        <span v-if="lastDeadlineTime !== null">
                          {{ formatDateDay(lastDeadlineTime) }}
                        </span>
                      </p>
                    </div>

                    <p class="text-sm text-slate-500">
                      {{ $t('caseStage.hearingStageDesc') }}
                    </p>
                  </div>

                  <div
                    v-if="activeUserRole === 'REDEK_ARBITRATOR'"
                    class="flex flex-row justify-end"
                  >
                    <button
                      class="btn ml-4 rounded-lg bg-green-500 py-3 px-6 text-white hover:bg-green-600"
                      @click.stop="resolveCaseModalOpen = true"
                    >
                      {{ $t('button.resolveTheCase') }}
                    </button>
                  </div>
                  <div
                    v-if="
                      caseExpired &&
                      ['BANK_LAWYER', 'REDEK_ARBITRATOR'].includes(
                        activeUserRole
                      )
                    "
                    class="mt-2 flex w-full justify-end"
                  >
                    <button
                      class="hover:text-sky-800"
                      @click.stop="showExtentionModal"
                    >
                      {{ $t('button.timerExtension') }}
                    </button>
                  </div>
                </div>
              </div>
              <!-- End of Hearing Stage -->

              <!-- Judgement Stage -->
              <div
                v-else-if="activeCase.stage === 5"
                class="flex w-full cursor-pointer items-center justify-center rounded-lg bg-white px-8 py-8 text-sm text-black"
              >
                <div class="flex w-full flex-col">
                  <div class="mb-1 flex text-lg font-bold text-green-500">
                    {{ $t('caseStage.caseResolved') }}
                  </div>
                  <div class="mb-8 flex">
                    {{ $t('caseStage.caseResolvedDesc') }}
                  </div>
                  <div class="mb-8 flex flex-col">
                    <p class="text-sm text-black">
                      {{ $t('caseStage.actionToDo') }}
                    </p>
                    <div
                      class="mt-4 flex w-full cursor-pointer items-center justify-center rounded-lg bg-slate-50 px-6 py-6 text-sm text-black"
                    >
                      <div class="flex items-center justify-center">
                        <DocumentIcon />
                      </div>
                      <div class="ml-4 flex w-full flex-col">
                        <div class="flex">
                          {{ $t('caseStage.accessFinalDecision') }}
                        </div>
                      </div>
                      <div class="ml-4 flex items-center">
                        <button
                          class="btn rounded-lg bg-sky-500 py-2 px-6 text-white hover:bg-sky-600"
                          @click="
                            $router.push({
                              name: 'final-decision',
                              params: { slug: $route.params.slug },
                              query: {
                                name: $route.query.name,
                                status: $route.query.status,
                                sub_status: $route.query.sub_status,
                                lang: $route.query.lang,
                              },
                            })
                          "
                        >
                          {{ $t('button.open') }}
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Review -->
                  <div v-if="roleCode === 'USER'" class="mb-8 flex flex-col">
                    <p class="text-sm text-black">
                      {{ $t('caseStage.feelFreeReview') }}
                    </p>
                    <div
                      v-for="user in activeCase.case_parties.filter(
                        (item) =>
                          item.id !== loggedinUser.id &&
                          item.role.toUpperCase() !== 'BANK LAWYER'
                      )"
                      :key="user.id"
                      class="mt-4 flex w-full cursor-pointer flex-col items-start rounded-lg bg-slate-50 px-6 py-6 text-sm text-black"
                    >
                      <div class="flex w-full justify-between">
                        <div
                          class="flex w-full items-center space-x-4 self-center"
                        >
                          <Tooltip
                            class="w-8"
                            bg="dark"
                            :custom-image="true"
                            image="https://miro.medium.com/max/720/1*W35QUSvGpcLuxPo3SRTH4w.png"
                          >
                            <div class="text-xs text-slate-200">
                              {{ user.name }}
                            </div>
                          </Tooltip>
                          <div class="flex">{{ user.name }}</div>
                          <div
                            class="ml-1 flex items-center rounded-lg bg-purple-100 px-1 text-xs text-purple-600"
                          >
                            {{ user.role }}
                          </div>
                        </div>
                        <div
                          class="flex w-full items-center justify-end self-center"
                        >
                          <button
                            v-if="canReviewCase(user.role)"
                            class="btn rounded-lg bg-sky-500 py-2 px-6 text-white hover:bg-sky-600"
                            @click.stop="doReview(user)"
                          >
                            {{ $t('button.review') }}
                          </button>
                          <div v-else class="flex flex-row">
                            <svg
                              v-for="index in 5"
                              :key="index"
                              class="h-4 w-4 shrink-0 fill-current"
                              :class="
                                index <=
                                parseInt(getCaseReview(user.role).rating)
                                  ? 'text-amber-500'
                                  : 'text-slate-300'
                              "
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M8 0L6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934h-6L8 0z"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div class="h-0 w-0 basis-full"></div>
                      <div
                        v-if="!canReviewCase(user.role)"
                        class="flex px-2 pt-5"
                      >
                        {{ getCaseReview(user.role).review }}
                      </div>
                    </div>
                  </div>
                  <!-- End of Review -->
                </div>
              </div>
              <!-- End of Judgement Stage -->
            </div>
          </div>
          <DualRingLoader v-else />
        </div>
      </main>
    </div>

    <ModalInitiateConfirm
      type="mediation"
      :is-open="initiateMediatorModalOpen"
      @close="initiateMediatorModalOpen = false"
      @confirm="caseInititate"
    />

    <ModalInitiateConfirm
      type="arbitration"
      :is-open="initiateArbitratorModalOpen"
      @close="initiateArbitratorModalOpen = false"
      @confirm="caseInititate"
    />

    <ModalBasic
      id="resolve-case-modal"
      :modal-open="resolveCaseModalOpen"
      :show-border-header="false"
      :title="$t('caseStage.resolveTheCase')"
      @close-modal="resolveCaseModalOpen = false"
    >
      <!-- Modal content -->
      <Form
        ref="form"
        v-slot="{ errors }"
        :validation-schema="resolveCaseSchema"
        @submit="confirmResolveCaseModalOpen = true"
      >
        <div class="px-5 pt-2">
          <div class="space-y-3">
            <div>
              <label class="mb-1 block text-sm font-medium" for="result">
                {{ $t('caseStage.finalDecisionResult') }}
              </label>
              <Field v-slot="{ field }" v-model="result" name="result">
                <select id="result" v-bind="field" class="form-select w-full">
                  <option
                    v-for="caseResult in caseResults"
                    :key="caseResult"
                    :value="caseResult"
                  >
                    {{ caseResult }}
                  </option>
                </select>
              </Field>
              <div class="flex w-full pb-4">
                <span
                  v-if="errors.result !== undefined"
                  class="pl-3 text-sm text-red-500"
                >
                  {{ $t(errors.result) }}
                </span>
              </div>
            </div>
            <div>
              <label class="mb-1 block text-sm font-medium" for="notes">
                {{ $t('caseStage.disputeClosureNote') }}
              </label>
              <Field v-slot="{ field }" v-model="notes" name="notes">
                <textarea
                  id="notes"
                  v-bind="field"
                  class="form-textarea w-full px-2 py-1"
                  rows="4"
                ></textarea>
              </Field>
              <div class="flex w-full pb-4">
                <span
                  v-if="errors.notes !== undefined"
                  class="pl-3 text-sm text-red-500"
                >
                  {{ $t(errors.notes) }}
                </span>
              </div>
            </div>
            <div>
              <label class="mb-1 block text-sm font-medium" for="attachment">
                {{ $t('modal.attachment') }}
              </label>

              <div class="flex w-full items-center justify-center">
                <Field v-model="attachment" name="attachment">
                  <label
                    for="files"
                    class="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-gray-300 bg-gray-50 hover:bg-gray-100"
                  >
                    <div class="flex flex-col items-center justify-center py-2">
                      <svg
                        aria-hidden="true"
                        class="mb-3 h-10 w-10 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p class="text-sm text-gray-500">
                        {{ $t('forms.dragAndDrop') }}
                      </p>
                      <p class="text-sm text-gray-500">
                        {{ $t('forms.or') }}
                      </p>
                      <p class="text-sm font-semibold text-sky-500">
                        {{ $t('forms.browseFiles') }}
                      </p>
                    </div>
                    <input
                      id="files"
                      type="file"
                      class="hidden"
                      @change="fileInput"
                    />
                    <span v-if="attachment">
                      {{ attachment.name }}
                    </span>
                  </label>
                </Field>
              </div>
              <div
                v-if="errors.attachment !== undefined"
                class="flex w-full pb-4"
              >
                <span class="pl-3 text-sm text-red-500">
                  {{ $t(errors.attachment) }}
                </span>
              </div>
            </div>
            <div>
              <label class="mb-1 block text-sm font-medium" for="video">
                {{ $t('modal.videoUpload') }}
              </label>

              <div class="flex w-full items-center justify-center">
                <Field v-model="video" name="video">
                  <label
                    for="videos"
                    class="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-gray-300 bg-gray-50 hover:bg-gray-100"
                  >
                    <div class="flex flex-col items-center justify-center py-2">
                      <svg
                        aria-hidden="true"
                        class="mb-3 h-10 w-10 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p class="text-sm text-gray-500">
                        {{ $t('forms.dragAndDrop') }}
                      </p>
                      <p class="text-sm text-gray-500">
                        {{ $t('forms.or') }}
                      </p>
                      <p class="text-sm font-semibold text-sky-500">
                        {{ $t('forms.browseFiles') }}
                      </p>
                    </div>
                    <input
                      id="videos"
                      type="file"
                      class="hidden"
                      @change="fileInputVideo"
                    />
                    <span v-if="video">
                      {{ video.name }}
                    </span>
                  </label>
                </Field>
              </div>
              <div v-if="errors.video !== undefined" class="flex w-full pb-4">
                <span class="pl-3 text-sm text-red-500">
                  {{ $t(errors.video) }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="px-5 py-4">
          <button
            class="btn w-full rounded-lg bg-sky-500 py-3 px-6 text-white hover:bg-sky-600"
            type="submit"
          >
            {{ $t('caseStage.resolve') }}
          </button>
        </div>
      </Form>
    </ModalBasic>

    <ModalBlank
      id="confirmResolveCaseModal"
      :modal-open="confirmResolveCaseModalOpen"
      @close-modal="confirmResolveCaseModalOpen = false"
    >
      <div class="flex flex-col p-5">
        <!-- Modal header -->
        <div class="mb-2 flex w-full justify-center">
          <div class="text-xl font-semibold text-slate-800">
            {{ $t('caseStage.areYouSureToResolve') }}
          </div>
        </div>
        <!-- Modal content -->
        <div class="mb-10 flex w-full justify-center text-base">
          <p class="text-center">
            {{ $t('caseStage.resolveCaseDesc') }}
          </p>
        </div>
        <!-- Modal footer -->
        <div class="flex flex-row justify-between space-x-2">
          <button
            class="btn w-full rounded-lg bg-slate-100 py-3 px-6 text-slate-800 hover:bg-slate-200"
            @click.stop="confirmResolveCaseModalOpen = false"
          >
            {{ $t('button.cancel') }}
          </button>
          <button
            class="btn w-full rounded-lg bg-sky-500 py-3 px-6 text-white hover:bg-sky-600"
            @click="resolveCase"
          >
            {{ $t('button.confirm') }}
          </button>
        </div>
      </div>
    </ModalBlank>

    <ModalBasic
      id="review-modal"
      :modal-open="reviewModalOpen"
      :show-border-header="false"
      title="Leave Review"
      @close-modal="reviewModalOpen = false"
    >
      <!-- Modal content -->
      <div class="px-5 pt-2">
        <div class="space-y-3">
          <div>
            <label class="mb-1 block text-sm font-medium" for="conclusion">
              {{ $t('caseStage.giveRating') }}
            </label>
            <StarRating
              :value="reviewRating"
              :total-rating="5"
              @input="($event) => (reviewRating = $event)"
            />
          </div>
          <div class="mt-2">
            <label class="mb-1 block text-sm font-medium" for="notes">
              {{ $t('caseStage.review') }}
            </label>
            <textarea
              id="review"
              v-model="review"
              class="form-textarea w-full px-2 py-1"
              rows="4"
              required
              :placeholder="$t('caseStage.writeReview')"
            ></textarea>
          </div>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="px-5 py-4">
        <button
          class="btn w-full rounded-lg bg-sky-500 py-3 px-6 text-white hover:bg-sky-600"
          @click.stop="saveReview"
        >
          {{ $t('caseStage.leaveReview') }}
        </button>
      </div>
    </ModalBasic>

    <ModalExtenstionTime
      :is-open="extenstionTimeModalOpen"
      :quota="extensionTimeQuota"
      :has-quota="hasExtensionQuota"
      @close="extenstionTimeModalOpen = false"
      @confirm="setExtenstionTime"
    />

    <ModalResponseToTheClaim
      :is-open="responseToTheClaimOpen"
      :chat-members="caseParties"
      :is-modal-loading="isLoading"
      @empty-member="emptyMember('selectOfferTo')"
      @closed="responseToTheClaimOpen = false"
      @save="createResponseToTheClaim"
    />

    <RightSidebar
      :sidebar-open="sidebarOpen"
      @close-sidebar="sidebarOpen = false"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { Form, Field } from 'vee-validate'
import globalMixin from '@/mixins/global.js'
import chatMixin from '@/mixins/chat.js'
import caseMixin from '@/mixins/case.js'

import Sidebar from '@/partials/Sidebar.vue'
import RightSidebar from '@/partials/RightSidebar.vue'
import ModalBlank from '@/components/ModalBlank.vue'
import ModalBasic from '@/components/ModalBasic.vue'
import ModalInitiateConfirm from '@/components/modal/case/ModalInitiateConfirm.vue'
import ModalExtenstionTime from '@/components/modal/case/ModalExtenstionTime.vue'
import ModalResponseToTheClaim from '@/components/modal/case/ModalResponseToTheClaim.vue'
import Tooltip from '@/components/Tooltip.vue'
import StarRating from '@/components/StarRating.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'

import ChecklistIcon from '@/assets/svg/checklist.svg'
import CalendarIcon from '@/assets/svg/calendar-icon.svg'
import DocumentIcon from '@/assets/svg/document-icon.svg'
import ChatIcon from '@/assets/svg/chat-icon.svg'

export default {
  name: 'CaseStagePage',
  components: {
    Sidebar,
    RightSidebar,
    Form,
    Field,
    ModalBlank,
    ModalBasic,
    ModalInitiateConfirm,
    ModalExtenstionTime,
    ModalResponseToTheClaim,
    Tooltip,
    StarRating,
    DualRingLoader,
    ChecklistIcon,
    DocumentIcon,
    CalendarIcon,
    ChatIcon,
  },
  mixins: [globalMixin, chatMixin, caseMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const toast = useToast()
    const sidebarOpen = ref(false)
    const activeCase = ref({ id: '' })

    const resolveCaseModalOpen = ref(false)
    const initiateMediatorModalOpen = ref(false)
    const initiateArbitratorModalOpen = ref(false)
    const confirmResolveCaseModalOpen = ref(false)
    const extenstionTimeModalOpen = ref(false)
    const responseToTheClaimOpen = ref(false)
    const reviewModalOpen = ref(false)
    const activeParty = ref(null)
    const review = ref('')
    const reviewRating = ref(null)

    const caseResults = ref([
      t('caseStage.resultOverAll'),
      t('caseStage.resultEveryGood'),
    ])

    const result = ref(null)
    result.value = caseResults.value[0]
    const notes = ref(null)
    const attachment = ref(null)
    const video = ref(null)
    const activeUserRole = ref(null)
    const activeUserId = ref(null)
    let activeUser = null;
    store.dispatch('auth/getActiveUser').then(response => {
        activeUser = response;
        if (!activeUser) {
            // router.push({ name: 'login' })
        } else {
            activeUserRole.value = activeUser.role.code
            activeUserId.value = activeUser.id
        }
    })

    return {
      t,
      n,
      store,
      toast,
      sidebarOpen,
      resolveCaseModalOpen,
      initiateMediatorModalOpen,
      initiateArbitratorModalOpen,
      confirmResolveCaseModalOpen,
      extenstionTimeModalOpen,
      responseToTheClaimOpen,
      reviewModalOpen,
      reviewRating,
      activeCase,
      result,
      notes,
      attachment,
      video,
      activeUserRole,
      activeUserId,
      activeUser,
      caseResults,
      activeParty,
      review,
    }
  },
  mounted() {
    if (this.$store.getters['auth/adminModeGetter'] === 'admin') {
    //   this.checkUserAccess('USER', true, false, false, true)
    }
    this.getCaseDetail(true).then(() => {
      this.hasBankLawyer = this.activeCase.bank_lawyer_id != null;
    })
    this.getRequestTimeQuota()
    this.getAllRoom(true)
  },
  methods: {
    toFinalDecision() {
      return this.$router.push({
        name: 'final-decision',
        params: {
          slug: this.$route.params.slug,
        },
      })
    },
    async resolveCaseVideo() {
      this.isLoading = true
      await this.store
        .dispatch('cases/resolveCase', {
          video: this.video,
        })
        .then((response) => {
          this.isLoading = false
          this.toFinalDecision()
          return response
        })
        .catch((error) => {
          this.isLoading = false

          this.resolveCaseModalOpen = true
          this.confirmResolveCaseModalOpen = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async resolveCase() {
      this.isLoading = true
      await this.store
        .dispatch('cases/resolveCase', {
          result: this.result,
          notes: this.notes,
          attachment: this.attachment,
          case_id: this.activeCase.id,
        })
        .then((response) => {
          const responseData = response.data
          this.toast.success(responseData.message)
          this.resolveCaseModalOpen = false
          this.confirmResolveCaseModalOpen = false
          if (this.video !== null) {
            this.resolveCaseVideo()
          } else {
            this.toFinalDecision()
          }
          // this.getCaseDetail(true)
        })
        .catch((error) => {
          this.isLoading = false

          this.resolveCaseModalOpen = true
          this.confirmResolveCaseModalOpen = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async createCaseReview(params) {
      this.isLoading = true

      await this.store
        .dispatch('review/createCaseReview', params)
        .then((response) => {
          this.isLoading = false

          const responseData = response.data
          this.toast.success(responseData.message)
          this.reviewModalOpen = false
          this.getCaseDetail(false)
        })
        .catch((error) => {
          this.isLoading = false

          this.reviewModalOpen = true
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    saveReview() {
      if (this.reviewRating === null) {
        return this.emptyMember('pleaseInsertRating')
      }

      if (!this.isLoading) {
        const params = {
          case_id: this.$route.params.slug,
          party_id: this.activeParty,
          rating: this.reviewRating,
          review: this.review,
        }
        this.createCaseReview(params)
      }
    },
    doReview(user) {
      this.reviewModalOpen = true
      this.activeParty = user.id
    },
    fileInput(event) {
      this.attachment = event.target.files[0]
    },
    fileInputVideo(event) {
      this.video = event.target.files[0]
    },
    showExtentionModal() {
      this.extenstionTimeModalOpen = true
    },
    setExtenstionTime() {
      if (this.hasExtensionQuota && !this.isLoading) {
        this.createRequestTime()
      }
    },
    checkResponseToTheClaim() {
      if (!this.checkEmptyObject(this.firstInitialChatRoom)) {
        this.toChatRoom(this.firstInitialChatRoom.id)
      } else {
        this.responseToTheClaim()
      }
    },
    responseToTheClaim() {
      this.responseToTheClaimOpen = true
    },
    createResponseToTheClaim(events) {
      this.responseClaimData = events
      this.responseClaimAPI()
    },
  },
}
</script>
