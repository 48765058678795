<template>
    <div class="flex w-full flex-wrap">
        <div
            v-for="activity in activities"
            :key="activity.id"
            class="mt-4 flex w-full cursor-pointer items-center justify-center rounded-lg px-4 py-4 text-sm text-black"
            :class="[wrapperClass]"
        >
            <div class="flex flex-col gap-3 items-center justify-center">
                <div
                :class="[
                    'bg-gradient-to-r w-10 h-10 rounded-full flex items-center justify-center text-white',
                    {
                        'from-purple-400 via-purple-500 to-purple-700': activity.type === 'case' && !activity.manually,
                        'from-cyan-400 via-cyan-500 to-cyan-700': activity.type === 'stage' && !activity.manually,
                        'from-red-400 via-red-500 to-red-700': activity.type === 'doc' && !activity.manually,
                        'from-sky-400 via-sky-500 to-sky-700': activity.type === 'calendar' && !activity.manually,
                        'from-pink-400 via-pink-500 to-pink-700': activity.type === 'external',
                        'from-green-400 via-green-500 to-green-700': activity.type === 'offer' && !activity.manually,
                        'from-orange-400 via-orange-500 to-orange-700': activity.type === 'stage' && activity.manually,
                    }
                ]"
                >
                    <i
                    :class="{
                        'far fa-folder-open': activity.type === 'case' && !activity.manually,
                        'fas fa-flag': activity.type === 'stage' && !activity.manually,
                        'fas fa-file': activity.type === 'doc' && !activity.manually,
                        'fas fa-calendar-day': activity.type === 'calendar' && !activity.manually,
                        'fas fa-link': activity.type === 'external',
                        'fas fa-comments': activity.type === 'offer' && !activity.manually,
                        'fa-regular fa-pen-to-square': activity.type === 'stage' && activity.manually,
                    }"
                    ></i>
                </div>
            </div>
            <div class="ml-4 flex gap-1 w-full flex-col">
                <div class="flex flex-col">
                    <span class="font-bold text-slate-600">
                        {{ activity.title }}
                    </span>
                    <span>
                        {{ activity.subtitle }}
                    </span>
                    <span class="text-sm">
                        {{ activity.content }}
                    </span>
                </div>
                <div class="flex items-center justify-between">
                    <div v-if="
                        activity.type === 'external'
                        &&
                        activity.document
                        &&
                        activity.document.files
                        &&
                        activity.document.files[0]
                    ">
                        <span>
                            {{ $t('timeline.item.document') }}:
                        </span>
                        <a
                        class="text-sky-500"
                        target="_blank"
                        :href="activity.document.files[0].file"
                        >
                            {{ activity.document.name }}
                        </a>
                    </div>
                    <div
                    v-if="activity.user"
                    class="flex items-center gap-2">
                        <div class="flex items-center">
                            <ImageLoader
                            wrapper-class="mr-3 shrink-0 rounded-full"
                            :src="activity.user.avatar"
                            width="30"
                            height="30"
                            alternate="partyImage"
                            />
                            <span>
                                {{ activity.user.name }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col items-end">
                    <div class="flex text-sm text-slate-500">
                        {{ timeAgo(activity.modified) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import StageIcon from '@/assets/svg/stage-icon.svg'
import ChatIcon from '@/assets/svg/chat-icon.svg'
import ImageLoader from '@/components/image/ImageLoader.vue'

export default {
    name: 'ActivityItemComponent',
    components: {
        StageIcon,
        ChatIcon,
        ImageLoader,
    },
    mixins: [globalMixin],
    props: {
        activities: {
            type: Array,
            default: () => {
                return []
            },
        },
        wrapperClass: {
            type: String,
            default: '',
        },
    },
}
</script>
