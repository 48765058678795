<template>
    <div class="flex flex-col bg-white">
        <div class="flex w-full cursor-pointer items-center" @click="clickDocument">
            <div class="flex w-2/6 justify-start">
                <div class="px-4">
                    <FolderIcon v-if="document.type === 'Folder'" class="inline w-8 cursor-pointer" />
                    <FileTypeIcon v-else :file-type="fileType" icon-class="inline w-8 cursor-pointer" />
                </div>
                <div class="pt-1">
                    <span>{{ (document.translate_ref) ? $t(document.translate_ref) : document.name }}</span>
                </div>
            </div>
            <div class="flex w-2/6 pt-1">
                <span v-if="document.owner">
                    {{ document.owner.first_name }} {{ document.owner.last_name }}
                </span>
                <span v-else>-</span>
            </div>
            <div class="flex w-2/6 px-2">
                <div class="flex w-full items-center justify-between">
                    <div class="pt-1">
                        <p class="rounded-lg px-2 py-1 text-sm font-semibold" :class="[
                            document.type === 'File'
                                ? 'bg-orange-50 text-orange-800'
                                : 'bg-gray-100 text-gray-800',
                        ]">
                            {{ document.type }}
                            <span v-if="fileType !== ''" class="text-sm font-medium">
                                (.{{ fileType }})
                            </span>
                        </p>
                    </div>
                    <div>
                        <EditMenu align="right" class="relative ml-2 inline-flex" @click.stop>
                            <li>
                                <span
                                    class="flex cursor-pointer py-1 px-3 text-sm font-medium text-slate-600 hover:text-slate-800"
                                    @click="infoDocument">
                                    {{ $t('about') }}
                                </span>
                            </li>
                            <template v-if="
                                document.owner
                                &&
                                (!$store.getters['cases/currentCaseWasClosed'] || $methods.hasPermissions(['cases_manage_documents_create_although_case_closed']))
                                &&
                                isaValidPartInCase
                            ">
                                <li
                                v-permission="'cases_manage_documents_edit'"
                                v-if="(
                                        $store.getters['auth/getCurrentUser']
                                        &&
                                        document.owner.id === $store.getters['auth/getCurrentUser'].id
                                        // &&
                                        // !document.is_read_only
                                    )
                                ">
                                    <span
                                        class="flex cursor-pointer py-1 px-3 text-sm font-medium text-slate-600 hover:text-slate-800"
                                        @click.stop="editDocument">
                                        {{ $t('edit') }}
                                    </span>
                                </li>
                                <li
                                v-permission="'cases_manage_documents_delete'"
                                v-if="(
                                    $store.getters['auth/getCurrentUser']
                                    &&
                                    document.owner.id === $store.getters['auth/getCurrentUser'].id
                                    &&
                                    !document.is_read_only
                                )">
                                    <span
                                        class="flex cursor-pointer py-1 px-3 text-sm font-medium text-rose-500 hover:text-rose-600"
                                        @click.stop="removeDocument">
                                        {{ $t('remove') }}
                                    </span>
                                </li>
                            </template>
                        </EditMenu>
                    </div>
                </div>
            </div>
        </div>
        <div
        class="bg-sky-100 rounded-b p-3 mt-3 -mb-6 text-sm"
        v-if="document.meeting"
        >
            <div>
                <span class="font-bold text-slate-500">
                    {{ $t('general.meeting') }}:
                </span>
                <span>
                    {{ document.meeting.title }}
                </span>
                -
                <span>
                    {{
                        $filters.formattedDate(document.meeting.due_date, 'DD MMMM YYYY') +
                        ' (' + $filters.formattedDate(document.meeting.meeting_time_from, 'HH:mm') + ' - ' +
                        $filters.formattedDate(document.meeting.meeting_time_to, 'HH:mm') + ')'
                    }}
                </span>
            </div>
            <div class="text-slate-400">
                {{ document.meeting.description }}
            </div>
        </div>
    </div>
</template>
<script>
import EditMenu from './DropdownEditMenu.vue'
import FileTypeIcon from '@/components/FileTypeIcon.vue'

import FolderIcon from '@/assets/svg/folder.svg'

export default {
    name: 'DocumentItemComponent',
    components: {
        EditMenu,
        FileTypeIcon,

        FolderIcon,
    },
    props: {
        document: {
            type: Object,
            default: () => ({
                isFolder: false,
                name: '',
                owner: '',
                type: '',
            }),
        },
        directory: {
            type: Array,
            default: () => ([
                { label: '/', folderId: null }
            ])
        },
        isaValidPartInCase: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['edit-document', 'remove-document', 'info-document', 'change-folder', 'get-requested-documents'],

    computed: {
        fileType() {
            if (this.document.type !== 'Folder') {
                if (this.document.files[0] !== undefined) {
                    const fileName = this.document.files[0].file
                    return fileName.substring(fileName.lastIndexOf('.') + 1)
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
    },
    methods: {
        editDocument() {
            this.$emit('edit-document', {
                document: this.document,
            })
        },
        infoDocument() {
            this.$emit('info-document', {
                document: this.document,
            })
        },
        removeDocument() {
            this.$emit('remove-document', {
                document: this.document,
            })
        },
        clickDocument() {

            if (this.document.identity === 'case-.case_record.requested_documents') {
                const labelDir = (this.document.translate_ref)
                    ? this.$t(this.document.translate_ref)
                    : this.document.name;
                this.directory.push({ label: labelDir, folderId: this.document.id });
                this.$emit('get-requested-documents', {
                    folder: this.document,
                });
            } else if (this.document.type !== 'Folder') {
                let url = ''
                if (this.document.files[0] !== undefined)
                    url = this.document.files[0].file
                if (url !== '') window.open(url, '_blank').focus()
            } else {
                const labelDir = (this.document.translate_ref)
                    ? this.$t(this.document.translate_ref)
                    : this.document.name;
                this.directory.push({ label: labelDir, folderId: this.document.id });
                this.$emit('change-folder', {
                    folder: this.document,
                });
            }
        },
    },
}
</script>
