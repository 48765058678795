<template>
    <div class="flex h-screen overflow-hidden">
        <!-- Sidebar -->
        <Sidebar :sidebar-open="sidebarOpen" @close-sidebar="sidebarOpen = false" />

        <!-- Content area -->
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <main>
                <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
                    <div class="flex justify-between">
                        <div class="flex w-full flex-col">
                            <h1 class="text-2xl font-bold text-black">
                                {{ $t('admin.user.title') }}
                            </h1>
                            <p>{{ $t('admin.user.description') }}</p>
                        </div>
                        <div class="mt-4 flex w-full items-center justify-end">
                            <DropdownLanguages class="mr-4"/>
                            <DropdownNotifications align="right" class="md:mr-4" wrapper-class="bg-white rounded-lg" />
                            <DropdownProfile align="right" mode="aesthetic" />
                        </div>
                    </div>
                    <div class="my-4 flex w-full justify-between">
                        <div class="flex items-center space-x-4">
                            <SearchForm :placeholder="$t('admin.user.searchPlaceholder')" @search="searchUser" />
                            <div class="flex cursor-pointer items-start text-sm text-black">
                                <div class="flex">
                                    <p>{{ $t('formLabel.sortBy') }}</p>
                                </div>
                                <div class="flex">
                                    <select class="border-none bg-inherit py-0 font-bold focus:ring-0"
                                        @change="changeOrder">
                                        <option v-for="order in orderOptions" :key="order.value" :value="order.value"
                                            :selected="filterDataUser.order === order.value">
                                            {{ $t(`${order.label}`) }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="text-sm flex items-center gap-2">
                                <select
                                @change="changeNRecords"
                                v-model="filterDataUser.take"
                                class="form-input w-16"
                                >
                                    <option
                                    v-for="item in [5, 10, 25, 50, 100]"
                                    :value="item"
                                    >
                                        {{ item }}
                                    </option>
                                </select>
                                <span>
                                    {{ $t('general.records') }}
                                </span>
                            </div>
                        </div>

                        <ButtonAdd v-permission="'security_users_management_create'" :title="$t('admin.user.buttonAdd')"
                            custom-class="rounded-xl" @clicked="newUser()" />
                    </div>

                    <div v-if="!isLoading && users.length > 0" class="w-full mt-8">
                        <DataTable
                        headerClass="!p-4"
                        :headers="dataTable.headers"
                        :list="users"
                        @edit="(event) => editUser({user: event})"
                        @delete="(event) => deleteUser({user: event})"
                        >
                            <template v-slot:cell-avatar="cellAvatarProps">
                                <div class="flex justify-center">
                                    <ImageLoader
                                    wrapper-class="shrink-0 rounded-full"
                                    :src="cellAvatarProps.item.avatar"
                                    width="45"
                                    height="45"
                                    :alternate="cellAvatarProps.item.first_name"
                                    />
                                </div>
                            </template>
                            <template v-slot:cell-role="cellRoleProps">
                                <p :class="[
                                    'rounded-lg px-2 w-full py-1 text-center text-base font-semibold',
                                    cellRoleProps.item.role.class_tailwind_color ?? 'bg-purple-100  text-purple-600'
                                ]">
                                    {{ cellRoleProps.item.role.name }}
                                </p>
                            </template>
                        </DataTable>
                        <div class="flex flex-col items-end my-4">
                            <div class="flex">
                                <button
                                v-for="(link, index) in metaDataUser.links"
                                @click="changePage(link, index)"
                                :class="[
                                    'text-sm px-2 flex items-center justify-center border-t border-b border-l',
                                    {
                                        'w-8 h-8': index != 0 && index < (metaDataUser.links.length - 1),
                                        'border-r rounded-r-md': index === (metaDataUser.links.length - 1),
                                        'rounded-l-md': index === 0
                                    },
                                    (link.active) ? 'bg-sky-500 text-white' : 'bg-white text-slate-600'
                                ]"
                                >
                                    <span v-html="link.label"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <DualRingLoader v-else-if="isLoading" />
                    <NoData v-else :search-keyword="filterDataUser.search" />
                </div>
            </main>

            <ModalDelete :id="currentUser.id" :is-open="deleteUserModalOpen" :title="$t('admin.user.modalRemoveUser.title')"
                :description="$t('admin.user.modalRemoveUser.description')" @closed="deleteUserModalOpen = false"
                @delete="deleteUserAPI($event)" />

            <ModalCreateUser
            :is-open="newUserModalOpen"
            :roles="roles"
            :banks="banks"
            :is-edit="isUserEdit"
            :current-user="currentUser"
            :is-user-loading="isLoading"
            @closed="modalUserClose"
            @submit-data="submitData"
            :identification-types="identificationTypes"
            />
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import userMixin from '@/mixins/user.js'

import Sidebar from '@/partials/Sidebar.vue'
import SearchForm from '@/components/SearchForm.vue'
import ButtonAdd from '@/components/ButtonAdd.vue'

import ModalCreateUser from '@/components/ModalCreateUser.vue'
import ModalDelete from '@/components/ModalDelete.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'
import NoData from '@/components/NoData.vue'
import DropdownNotifications from '@/components/DropdownNotifications.vue'
import DropdownProfile from '@/components/DropdownProfile.vue'
import DropdownLanguages from '@/components/DropdownLanguages.vue'
import DataTable from '@/components/DataTable.vue'
import ImageLoader from '@/components/image/ImageLoader.vue'

export default {
    name: 'AdminUserPage',
    components: {
        Sidebar,
        SearchForm,
        ButtonAdd,
        ModalCreateUser,
        ModalDelete,
        DualRingLoader,
        NoData,
        DropdownNotifications,
        DropdownProfile,
        DropdownLanguages,
        DataTable,
        ImageLoader
    },
    mixins: [globalMixin, userMixin],
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()

        const sidebarOpen = ref(false)
        const newUserModalOpen = ref(false)
        const deleteUserModalOpen = ref(false)
        const isUserEdit = ref(false)
        const currentUser = ref({})

        return {
            t,
            store,
            toast,
            sidebarOpen,
            isUserEdit,
            currentUser,
            deleteUserModalOpen,
            newUserModalOpen,
        }
    },
    data: function () {
        return {
            dataTable: {
                headers: [
                    {
                        id: 'avatar',
                        label: null,
                        column: {
                            key: 'avatar',
                        },
                        isSlot: true,
                    },
                    {
                        id: 'name',
                        label: this.$t('admin.user.headerTable.name'),
                        column: {
                            key: 'name',
                        },
                    },
                    {
                        id: 'role',
                        label: this.$t('admin.user.headerTable.role'),
                        column: {
                            key: 'role',
                        },
                        isSlot: true,
                    },
                    {
                        id: 'email',
                        label: this.$t('admin.user.headerTable.email'),
                        column: {
                            key: 'email',
                        },
                    },
                    {
                        id: 'phone',
                        label: this.$t('admin.user.headerTable.phone'),
                        column: {
                            key: 'phone',
                        },
                    },
                    {
                        id: 'created_at',
                        label: this.$t('parameters.masterData.common.table.headers.createdAt'),
                        column: {
                            funct: (value) => this.$filters.newDayjs(value).format('DD MMM YYYY - HH:mm'),
                            key: 'created_at',
                        }
                    },
                    {
                        id: 'actions',
                        label: '',
                        column: {
                            key: '',
                            action: {
                                edit: {
                                    permission: 'security_users_management_edit'
                                },
                                delete: {
                                    permission: 'security_users_management_delete'
                                }
                            }
                        }
                    }
                ]
            }
        }
    },
    mounted() {
        // this.checkUserAccess('REDEK_ADMIN')
        this.getAllRoles()
        this.getAllUsers()
        this.getAllBanks()
        this.getAllIdentificationTypes()
    },
    methods: {
        changePage (link, index) {
            let newPage = null
            if (this.metaDataUser.links.length > 3) {
                if (index === 0 && this.metaDataUser.current_page != 1) {
                    /**
                     * Previous
                     */
                    newPage = this.metaDataUser.current_page - 1;
                } else if (
                    index === (this.metaDataUser.links.length - 1)
                    &&
                    this.metaDataUser.current_page != this.metaDataUser.last_page
                ) {
                    /**
                     * Next
                     */
                    newPage = this.metaDataUser.current_page + 1;
                } else if (link.label === '...') {
                    newPage = Number(this.metaDataUser.links[index - 1].label) + 1
                } else if (link.label && !isNaN(link.label)) {
                    newPage = Number(link.label)
                }
            }
            if (newPage) {
                this.filterDataUser.page = newPage
                this.getAllUsers()
            }
        },
        changeNRecords (event) {
            this.filterDataUser.page = 1
            this.getAllUsers()
        }
    }
}
</script>
