<template>
    <ModalBlank
    id="agenda-method-chooser"
    :modal-open="isOpen"
    :otherElementsWillCloseModal="[$refs.btnDocumentRequestChoose, $refs.btnMeetingOrAlertChoose]"
    @close-modal="close"
    >
        <div class="p-8 flex flex-col gap-3">
            <span class="text-center">
                {{ $t('calendar.modalAgendaMethod.title') }}
            </span>
            <div class="flex justify-around gap-3">
                <button ref="btnDocumentRequestChoose" @click="$emit('choose-document')" class="w-1/2 p-3 bg-yellow-300 rounded-xl hover:bg-yellow-400 transition-all">
                    {{ $t('calendar.modalAgendaMethod.documentRequest') }}
                </button>
                <button ref="btnMeetingOrAlertChoose" @click="$emit('choose-meeting-or-alert')" class="w-1/2 p-3 bg-sky-300 rounded-xl hover:bg-sky-400 transition-all">
                    {{ $t('calendar.modalAgendaMethod.meetingOrAlert') }}
                </button>
            </div>
        </div>
    </ModalBlank>
</template>
<script>
import ModalBlank from '@/components/ModalBlank.vue'

export default {
    components: {
        ModalBlank
    },
    emits: ['close', 'choose-document', 'choose-meeting-or-alert'],
    watch: {
        isOpen(val) {
            if (!val) this.close()
        },
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        close() {
            this.$emit('close')
        },
    },
}

</script>