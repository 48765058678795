<template>
    <main>
        <div class="relative flex">
            <!-- Content -->
            <div class="w-full">
                <div
                    class="relative flex h-full min-h-screen flex-col justify-center bg-gray-100"
                >
                    <div class="mb-8 flex justify-center">
                        <img
                            class="h-14 object-cover object-center"
                            src="../assets/images/logo.png"
                            alt="Redek Logo"
                        />
                    </div>
                    <div
                        class="w-10/12 self-center rounded-lg bg-white px-8 py-8 shadow-md lg:w-1/2"
                    >
                        <div
                            class="flex w-full cursor-pointer flex-row items-center pb-4"
                            id="form-otp-auth-action-back"
                            @click="
                                $router.push({
                                    name: $route.params.type,
                                    query: {
                                        lang: $route.query.lang,
                                    },
                                })
                            "
                        >
                            <ArrowBackIcon
                                class="h-5 w-5 fill-current text-slate-500"
                            />
                            <span
                                class="ml-2 text-xs text-slate-500 hover:text-sky-400"
                                >{{ $t('action.back') }}</span
                            >
                        </div>
                        <h1 class="mb-2 text-3xl font-bold text-slate-800">
                            {{ $t('login.enterOTP') }}
                        </h1>
                        <p v-if="activeUser" class="text-justify text-sm">
                            {{
                                activeUser.otp_type === 'email'
                                    ? $t('login.weSentOTP', {
                                          email: activeUser.email,
                                      })
                                    : $t('login.weSentOTPByPhone', {
                                          phone: activeUser.phone,
                                      })
                            }}
                        </p>
                        <!-- Form -->
                        <form id="form-otp-auth" class="space-y-4">
                            <div>
                                <v-otp-input
                                    ref="otpInput"
                                    input-classes="w-full h-20 lg:h-24 text-3xl text-center rounded-md  border-gray-300 outline-none focus:border-sky-500 focus:outline-none focus:ring-0 focus:ring-sky-500 focus:ring-opacity-50"
                                    separator=" "
                                    class="mt-4 grid grid-cols-2 justify-center gap-2 text-center"
                                    :num-inputs="6"
                                    :should-auto-focus="true"
                                    :is-input-num="true"
                                    :conditionalClass="[
                                        'form-otp-auth-class-1',
                                        'form-otp-auth-class-2',
                                        'form-otp-auth-class-3',
                                        'form-otp-auth-class-4',
                                        'form-otp-auth-class-5',
                                        'form-otp-auth-class-6',
                                    ]"
                                    @on-complete="OTPComplete"
                                />
                            </div>
                            <div class="flex flex-col">
                                <div
                                    class="flex w-full items-center space-x-2 text-sm"
                                >
                                    <span>{{ $t('login.didNotReceive') }}</span>
                                    <span
                                        id="form-otp-auth-action-resend"
                                        class="cursor-pointer font-bold"
                                        :class="[
                                            canResend
                                                ? 'text-sky-500'
                                                : 'text-gray-200',
                                        ]"
                                        @click="resendOTP"
                                    >
                                        {{ $t('login.resendCode') }}
                                    </span>
                                    <span
                                        v-if="!canResend"
                                        class="text-xs font-bold text-sky-500"
                                    >
                                        {{
                                            $t('verificationOTP.wait', {
                                                timer: resendTimer,
                                            })
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="flex flex-col">
                                <button
                                    id="form-otp-auth-action-submit"
                                    class="btn text-md h-12 w-full rounded-md"
                                    type="submit"
                                    :class="[
                                        OTPCode.length < 6
                                            ? ' bg-gray-200 text-gray-50'
                                            : ' bg-sky-500 text-white hover:bg-sky-600',
                                        isLoading
                                            ? 'cursor-not-allowed'
                                            : 'cursor-pointer',
                                    ]"
                                    @click="verifyOTPClick"
                                >
                                    <span v-if="isLoading">
                                        {{ $t('general.loading') }}
                                    </span>
                                    <span v-else>
                                        {{ $t('button.submit') }}
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <DualRingLoader v-if="isLoading" class="absolute" />
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import DualRingLoader from '@/components/DualRingLoader.vue'

import ArrowBackIcon from '@/assets/svg/arrow-back.svg'

export default {
    name: 'VerificationOTPPage',
    components: {
        DualRingLoader,
        ArrowBackIcon,
    },
    mixins: [globalMixin],
    setup() {
        const toast = useToast()
        return { toast }
    },
    data: () => ({
        OTPCode: '',
        activeUser: null,
        canResend: false,
        resendTimer: 30,
        type: '',
        typeValid: ['forgot-password', 'register', 'login'],
        typeAuth: ['register', 'login'],
        OTPCodeSent: false,
    }),
    created() {
        this.countDownTimer()
    },
    mounted() {
        if (this.typeValid.includes(this.$route.params.type)) {
            this.type = this.$route.params.type
        } else {
            // this.checkUserAccess('', true);
        }

        this.$store.dispatch('auth/getActiveUserTemp').then((response) => {
            const user = response
            if (user) {
                this.activeUser = user
            } else {
                this.toast.error(
                    this.$t('message.failed.auth.userLoggingInExpired')
                )
                return this.$router.push({
                    name: this.type,
                    query: {
                        lang: this.$route.query.lang,
                    },
                })
            }
        })
        this.setIdsToEachInputOTP()
    },
    methods: {
        setIdsToEachInputOTP() {
            const input1 = document.querySelector('.form-otp-auth-class-1')
            const input2 = document.querySelector('.form-otp-auth-class-2')
            const input3 = document.querySelector('.form-otp-auth-class-3')
            const input4 = document.querySelector('.form-otp-auth-class-4')
            const input5 = document.querySelector('.form-otp-auth-class-5')
            const input6 = document.querySelector('.form-otp-auth-class-6')
            if (input1) input1.id = 'form-otp-auth-1'
            if (input2) input2.id = 'form-otp-auth-2'
            if (input3) input3.id = 'form-otp-auth-3'
            if (input4) input4.id = 'form-otp-auth-4'
            if (input5) input5.id = 'form-otp-auth-5'
            if (input6) input6.id = 'form-otp-auth-6'
        },
        countDownTimer() {
            if (this.resendTimer > 0) {
                setTimeout(() => {
                    this.resendTimer -= 1
                    this.countDownTimer()
                }, 1000)
            } else {
                this.canResend = true
                this.resendTimer = ''
            }
        },
        verifyOTPClick() {
            if (!this.isLoading) {
                if (this.checkOTP()) this.verifyOTP()
            }
        },
        OTPComplete(code) {
            this.OTPCode = code
            if (!this.isLoading) {
                if (this.checkOTP()) this.verifyOTP()
            }
        },
        checkOTP() {
            let isValid = true
            if (this.OTPCode.length < 6) {
                this.toast.error(this.$t('message.failed.auth.fillOTP'))
                isValid = false
            }
            return isValid
        },
        verifyOTP(forwarded = false) {
            this.isLoading = true
            const payload = {
                otp: this.OTPCode,
            }
            this.$store
                .dispatch('auth/verifyOTP', payload)
                .then((response) => {
                    this.isLoading = false
                    if (response.status === 200) {
                        if (forwarded)
                            this.toast.success(this.$t('info.successResendOTP'))
                        this.resetState()
                        this.verifyRedirectWhenLogin(response);
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        resendOTP() {
            if (this.isLoading || !this.canResend) {
                return
            } else {
                if (this.typeAuth.includes(this.type)) {
                    this.isLoading = true
                    this.$store
                        .dispatch('auth/sendOtp')
                        .then((response) => {
                            this.isLoading = false
                            this.toast.success(this.$t('info.successResendOTP'))
                        })
                        .catch((error) => {
                            this.isLoading = false
                            this.toast.error(this.extractErrorResponse(error))
                        })
                }
            }
        },
        resetState() {
            this.$store.dispatch('auth/resetActiveUserTemp')
        },
    },
}
</script>
