<template>
    <tr>
        <td class="w-px whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
            <div class="flex items-center">
                <label class="inline-flex">
                <span class="sr-only">{{ $t('general.select') }}</span>
                <input
                    :id="advocate.id"
                    class="form-radio"
                    type="radio"
                    :value="value"
                    :checked="checked"
                    @change="check"
                />
                </label>
            </div>
        </td>
        <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
            <div class="flex items-center">
                <div class="mr-2 h-10 w-10 shrink-0 sm:mr-3">
                <ImageLoader
                    wrapper-class="rounded-full"
                    :src="advocate.avatar"
                    width="40"
                    height="40"
                    :alternate="advocate.first_name"
                />
                </div>
                <div class="font-medium text-slate-800">
                    {{ advocate.first_name }} {{ advocate.last_name }}
                </div>
            </div>
        </td>
        <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
            <div class="flex flex-row">
                <svg
                v-for="index in 5"
                :key="index"
                class="h-4 w-4 shrink-0 fill-current"
                :class="
                    index <= advocate.average_rating ? 'text-amber-500' : 'text-slate-300'
                "
                viewBox="0 0 16 16"
                >
                <path
                    d="M8 0L6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934h-6L8 0z"
                />
                </svg>
            </div>
        </td>
        <td class="px-2 py-3 first:pl-5 last:pr-5">
            <p
            class="w-2/3 rounded-lg bg-green-100 p-2 text-center text-base font-semibold text-green-600"
            >
                {{ advocate.case_handled }} {{ $t('admin.cases.cases').toLowerCase() }}
            </p>
        </td>
        <td class="flex justify-center px-2 py-3 first:pl-5 last:pr-5">
            <p
            class="w-2/3 rounded-lg bg-orange-100 p-2 text-center text-base font-semibold text-orange-600"
            >
                {{ advocate.ongoing_case }} {{ $t('admin.cases.cases').toLowerCase() }}
            </p>
        </td>
    </tr>
</template>

<script>
import { computed } from 'vue'
import ImageLoader from '@/components/image/ImageLoader.vue'

export default {
    name: 'AdvocateTableItem',
    components: { ImageLoader },
    props: ['advocate', 'value', 'selected'],
    setup(props, context) {
        const checked = computed(() => props.selected.includes(props.value))

        function check() {
            let updatedSelected = props.selected
            if (this.checked) {
                updatedSelected = []
                // updatedSelected.splice(updatedSelected.indexOf(props.value), 1)
                updatedSelected.push(props.value)
            } else {
                updatedSelected = []
                updatedSelected.push(props.value)
                // updatedSelected.splice(updatedSelected.indexOf(props.value), 1)
            }
            context.emit('update:selected', updatedSelected)
        }

        return {
            check,
            checked,
        }
    },
}
</script>
