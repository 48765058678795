<template>
    <div class="relative flex flex-col">

        <!-- <div
        v-if="date"
        > -->
            <flat-pickr
            v-model="date"
            :class="wrapClass !== ''
                    ? wrapClass
                    : 'form-input w-60 pl-9 font-medium text-slate-500 hover:text-slate-600 focus:border-slate-300'
                " :config="config">
            </flat-pickr>
        <!-- </div> -->

        <div v-if="showIcon" class="pointer-events-none absolute inset-0 right-auto flex items-center">
            <svg class="ml-3 h-4 w-4 fill-current text-slate-500" viewBox="0 0 16 16">
                <path
                    d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
            </svg>
        </div>
    </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment';
import { ref } from 'vue'

export default {
    name: 'DatepickerComponent',
    components: {
        flatPickr,
    },
    setup () {
        const now = ref(moment().format('YYYY-MM-DD'));
        return {
            now
        }
    },
    props: {
        wrapClass: {
            type: String,
            default: '',
        },
        align: {
            type: String,
            default: 'left',
        },
        mode: {
            type: String,
            default: 'range', //'range' / 'single'
        },
        maxToday: {
            type: Boolean,
            default: true,
        },
        maxOneMonth: {
            type: Boolean,
            default: true,
        },
        maxOneWeek: {
            type: Boolean,
            default: false,
        },
        minToday: {
            type: Boolean,
            default: false,
        },
        minTwoday: {
            type: Boolean,
            default: false,
        },
        dateOnly: {
            type: Boolean,
            default: false,
        },
        initiateDefault: {
            type: Boolean,
            default: true,
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
        newValue: null
    },
    watch: {
        newValue (val) {
            if (val) {
                this.date = val;
            }
        }
    },
    emits: ['change-date'],
    data(props) {
        return {
            date: null, // refer to https://github.com/ankurk91/vue-flatpickr-component
            config: {
                mode: props.mode,
                static: true,
                monthSelectorType: 'static',
                dateFormat: 'M j, Y',
                defaultDate:
                    props.mode === 'range'
                        ? props.initiateDefault
                            ? [new Date().setDate(new Date().getDate() - 6), new Date()]
                            : []
                        : props.minTwoday
                            ? [new Date().setDate(new Date().getDate() + 1)]
                            : [new Date()],
                maxDate: props.maxToday
                    ? new Date()
                    : props.maxOneMonth
                        ? new Date(new Date().setMonth(new Date().getMonth() + 1))
                        : props.maxOneWeek
                            ? new Date(new Date().setDate(new Date().getDate() + 7))
                            : '',
                minDate: props.minToday
                    ? new Date()
                    : props.minTwoday
                        ? new Date().setDate(new Date().getDate() + 1)
                        : '',
                prevArrow:
                    '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
                nextArrow:
                    '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
                onReady: (selectedDates, dateStr, instance) => {
                    instance.element.value = dateStr.replace('to', '-')
                    if (props.dateOnly) {
                        if (props.mode === 'range') {
                            if (instance.element.value.length > 0) {
                                const date = instance.element.value.split('-')
                                const dates = []
                                date.forEach((item) => {
                                    dates.push(this.toDate(new Date(item)))
                                })
                                this.$emit('change-date', dates)
                            }
                        } else {
                            this.$emit(
                                'change-date',
                                this.toDate(new Date(instance.element.value))
                            )
                        }
                    } else {
                        this.$emit(
                            'change-date',
                            new Date((instance.element.value !== '') ? instance.element.value : this.now).toISOString()
                        )
                    }
                    const customClass = props.align ? props.align : ''
                    instance.calendarContainer.classList.add(`flatpickr-${customClass}`)
                },
                onChange: (selectedDates, dateStr, instance) => {
                    instance.element.value = dateStr.replace('to', '-')
                    if (props.dateOnly) {
                        if (props.mode === 'range') {
                            if (instance.element.value.length > 0) {
                                const date = instance.element.value.split('-')
                                const dates = []
                                date.forEach((item) => {
                                    dates.push(this.toDate(new Date(item)))
                                })
                                this.$emit('change-date', dates)
                            }
                        } else {
                            this.$emit(
                                'change-date',
                                this.toDate(new Date(instance.element.value))
                            )
                        }
                    } else {
                        this.$emit(
                            'change-date',
                            new Date(instance.element.value).toISOString()
                        )
                    }
                },
            },
        }
    },
    methods: {
        toDate(date) {
            const year = date.getFullYear()
            const month = ('0' + (date.getMonth() + 1)).slice(-2)
            const day = ('0' + date.getDate()).slice(-2)

            return year + '-' + month + '-' + day
        },
    },
}
</script>
