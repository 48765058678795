<template>
    <div class="-m-1.5 flex flex-wrap items-center">
        <div class="m-1.5">
            <ModalBasic
            id="notes-modal"
            :modal-open="isOpen"
            :show-border-header="false"
            :title="isEdit ? $t('admin.permissions.modalCreatePermission.editTitle') : $t('admin.permissions.modalCreatePermission.title')"
            @close-modal="close">
                <Form
                id="form_create_update_permissions"
                ref="form"
                v-slot="{ errors }"
                :validation-schema="permissionsCreationSchema"
                @submit="submitForm"
                >
                    <div class="px-5">
                        <div class="space-y-3 px-5">
                            <Field v-slot="{ field }" name="isEdit">
                                <input type="checkbox" name="isEdit" class="hidden" v-bind="field" :checked="isEdit" />
                            </Field>
                            <div>
                                <label class="mb-1 block text-xs font-bold">
                                    {{ $t('admin.permissions.modalCreatePermission.name') }}
                                </label>
                                <Field v-slot="{ field }" v-model="name" name="name">
                                    <input
                                    v-bind="field"
                                    class="form-input w-full p-2"
                                    type="text"
                                    id="form_create_update_permissions_name"
                                    required
                                    :placeholder="$t('admin.permissions.modalCreatePermission.name')" />
                                </Field>
                                <div v-if="errors.name !== undefined" class="flex w-full">
                                    <span class="text-sm text-red-500">
                                        {{ $t(errors.name) }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <label class="mb-1 block text-xs font-bold">
                                    {{ $t('admin.permissions.modalCreatePermission.description') }}
                                </label>
                                <Field v-slot="{ field }" v-model="description" name="description">
                                    <input
                                    v-bind="field"
                                    class="form-input w-full p-2"
                                    type="text"
                                    id="form_create_update_permissions_description"
                                    required
                                    :placeholder="$t('admin.permissions.modalCreatePermission.description')" />
                                </Field>
                                <div v-if="errors.description !== undefined" class="flex w-full">
                                    <span class="text-sm text-red-500">
                                        {{ $t(errors.description) }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <label class="mb-1 block text-xs font-bold" for="roles">
                                    {{ $t('admin.permissions.modalCreatePermission.pattern') }}
                                </label>
                                <Field
                                v-slot="{ field }"
                                v-model="functionalities_id"
                                name="functionalities_id">
                                    <select
                                    id="form_create_update_permissions_pattern_permission"
                                    v-bind="field"
                                    class="form-select w-full p-2">
                                        <option>
                                            {{ $t('formLabel.select.none') }}
                                        </option>
                                        <option
                                        v-for="permission in permissions"
                                        :key="permission.id"
                                        :value="permission.id"
                                        >
                                            {{ permission.name }}
                                        </option>
                                    </select>
                                </Field>
                                <div v-if="errors.role_id !== undefined" class="flex w-full">
                                    <span class="text-sm text-red-500">
                                        {{ $t(errors.role_id) }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <label class="mb-1 block text-xs font-bold">
                                    {{ $t('admin.permissions.modalCreatePermission.reference') }}
                                </label>
                                <Field v-slot="{ field }" v-model="reference" name="reference">
                                    <input
                                    v-bind="field"
                                    :class="[
                                        'form-input w-full p-2',
                                        {
                                            'bg-slate-200': isEdit
                                        }
                                    ]"
                                    type="text"
                                    required
                                    :disabled="isEdit"
                                    id="form_create_update_permissions_reference"
                                    :placeholder="$t('admin.permissions.modalCreatePermission.reference')" />
                                </Field>
                                <div v-if="errors.reference !== undefined" class="flex w-full">
                                    <span class="text-sm text-red-500">
                                        {{ $t(errors.reference) }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <label class="mb-1 block text-xs font-bold">
                                    {{ $t('admin.permissions.modalCreatePermission.status') }}
                                </label>
                                <Field v-slot="{ field }" name="status">
                                    <input
                                    id="form_create_update_permissions_status"
                                    type="checkbox"
                                    name="status"
                                    v-bind="field"
                                    v-model="status"
                                    />
                                </Field>
                                <div v-if="errors.status !== undefined" class="flex w-full">
                                    <span class="text-sm text-red-500">
                                        {{ $t(errors.status) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-5 py-4">
                        <div class="flex flex-wrap justify-center space-x-2 px-4">
                            <button
                            type="submit"
                            id="form_create_update_permissions_action_submit"
                            class="btn-sm w-full whitespace-nowrap rounded-lg bg-sky-500 p-3 text-white hover:bg-sky-600"
                            :class="[
                                isPermissionLoading ? 'cursor-not-allowed' : 'cursor-pointer',
                            ]">
                                <span v-if="isPermissionLoading">
                                    {{ $t('general.loading') }}
                                </span>
                                <span v-else>
                                    {{ $t('admin.permissions.modalCreatePermission.save') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </Form>
            </ModalBasic>
        </div>
    </div>
</template>
<script>
import ModalBasic from '@/components/ModalBasic.vue'
import { Form, Field } from 'vee-validate'
import globalMixin from '@/mixins/global.js'

export default {
    name: 'ModalCreateRoleComponent',
    components: {
        ModalBasic,
        Form,
        Field,
    },
    mixins: [globalMixin],
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        isPermissionLoading: {
            type: Boolean,
            default: false,
        },
        currentPermission: {
            type: Object,
            default: () => {
                return {
                    id: '',
                    first_name: '',
                    last_name: '',
                    email: '',
                }
            },
        },
        permissions: {
            type: Array,
            required: true,
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['closed', 'submitData'],
    data: () => ({
        permission_id: null,
        name: null,
        description: '',
        functionalities_id: null,
        reference: '',
        status: '',
    }),
    watch: {
        currentPermission(val) {
            this.permission_id = val.id ?? null
            this.name = val.name;
            this.description = val.description;
            this.functionalities_id = (val && val.has_pattern && val.has_pattern.functionality) ? val.has_pattern.functionality.id : null;
            this.reference = val.reference;
            this.status = val.status;
        },
        isOpen(val) {
            if (!val) this.close()
            // else this.$refs.form.resetForm()
        },
    },
    methods: {
        close() {
            this.$refs.form.resetForm()

            this.$emit('closed')
        },
        submitForm() {
            const formData = {
                permission_id: this.permission_id,
                name: this.name,
                description: this.description,
                functionalities_id: this.functionalities_id,
                reference: this.reference,
                status: this.status,
            }
            this.$emit('submitData', formData)
        },
    },
}
</script>
