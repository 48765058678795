<template>
    <div v-for="item in overviews" :key="item.id"
        class="col-span-12 flex transform cursor-pointer flex-col rounded-2xl bg-white shadow-sm transition duration-500 hover:scale-105 sm:col-span-6 lg:col-span-6 xl:col-span-6"
        @click="buttonClicked(item)">
        <div class="flex h-full flex-col justify-between px-5 py-5">
            <div clas="flex">
                <p class="text-xl font-bold">
                    {{ $t(`admin.cases.cardTitle.${item.status}`) }}
                </p>
            </div>

            <div class="mt-8 flex w-full flex-row justify-between">
                <p>
                    <span class="pr-2 text-2xl font-bold" :class="colorByStatus(item.status)">{{ item.total }}</span>
                    <span>{{ $t('admin.cases.cases') }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OverviewCard',
    props: {
        overviews: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    emits: ['clicked'],
    methods: {
        colorByStatus(status) {
            switch (status) {
                case 'all':
                    return 'text-sky-500'
                case 'pending':
                    return 'text-red-500'
                case 'ongoing':
                    return 'text-orange-500'
                case 'settled':
                    return 'text-green-500'
            }
        },
        buttonClicked(overviewData) {
            this.$emit('clicked', overviewData)
        },
    },
}
</script>
