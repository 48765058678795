<template>
    <div v-if="$store.getters['auth/getCurrentUser'] && isaValidPartInCase" class="flex space-x-4">
        <!-- v-if="me.role.code === 'BANK_LAWYER'" -->
        <div
        v-if="showMakeOffer && !$store.getters['cases/currentCaseWasClosed']"
        class="cursor relative flex cursor-pointer flex-wrap"
        @click.stop="makeOffer">
            <div class="flex w-full justify-end">
                <div class="rounded-full bg-sky-500 py-4 px-8 text-white shadow-lg hover:shadow-xl">
                    {{ $t('chat.discussion.makeOffer') }}
                </div>
            </div>
        </div>
        <div
        v-if="!$store.getters['cases/currentCaseWasClosed'] || $methods.hasPermissions(['cases_manage_chats_create_although_case_closed'])"
        v-permission="'cases_manage_chats_edit_new_message'"
        class="relative flex cursor-pointer flex-wrap"
        @click.stop="newChat">
            <div class="flex w-full justify-end">
                <div class="text-light rounded-full bg-sky-500 p-4 shadow-lg hover:shadow-xl">
                    <div class="flex justify-between text-sm">
                        <NewChatIcon class="inline h-5 w-5" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NewChatIcon from '@/assets/svg/new_chat.svg'

export default {
    name: 'FloatingNewChat',
    components: {
        NewChatIcon,
    },
    props: {
        showMakeOffer: {
            type: Boolean,
            default: true,
        },
        isaValidPartInCase: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['make-offer', 'new-chat'],
    methods: {
        makeOffer() {
            this.$emit('make-offer')
        },
        newChat() {
            this.$emit('new-chat')
        },
    },
}
</script>
