<template>
    <div class="-m-1.5 flex flex-wrap items-center">
        <div class="m-1.5">
            <ModalBasic v-if="activeChat.hasOwnProperty('member') &&
                activeChat.hasOwnProperty('title')
                " id="chat-info-modal" :modal-open="isOpen" :show-border-header="false" :backdrop="true"
                :title="$t('chat.discussion.info')" @close-modal="close">
                <div class="space-y-5 px-5 pb-5">
                    <!-- Chat General Info -->
                    <div class="flex items-center justify-between">
                        <div class="space-y-2">
                            <h1 class="text-lg font-semibold">{{ activeChat.title }}</h1>
                            <p>
                                {{
                                    $t('general.totalParties', {
                                        total: String(activeChat.member.length),
                                    })
                                }}
                            </p>
                        </div>
                        <div>
                            <button v-if="creatorId === activeUserId" class="rounded-lg bg-red-100 p-3 text-red-500"
                                @click="closeChat">
                                {{ $t('chat.discussion.closeChat') }}
                            </button>
                        </div>
                    </div>
                    <!-- Media And Document -->
                    <div class="flex cursor-pointer items-center justify-between border-b border-t py-5 text-sky-500"
                        @click.stop="showMedia()">
                        <p>{{ $t('chat.discussion.mediaAndDocuments') }}</p>
                        <svg class="mx-3 h-4 w-4 fill-current" viewBox="0 0 16 16">
                            <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
                        </svg>
                    </div>
                    <!-- Chat Member -->
                    <div class="flex flex-col space-y-4">
                        <h1 class="text-base font-semibold">
                            {{ $t('chat.group.members') }}
                        </h1>
                        <div v-for="member in activeChat.member" :key="member.id">
                            <MemberItem :user="member.user" :me="activeUser" />
                        </div>
                    </div>
                </div>
            </ModalBasic>
        </div>
    </div>
</template>
<script>
import ModalBasic from '@/components/ModalBasic.vue'
import MemberItem from '@/components/MemberItem.vue'

export default {
    name: 'ModalChatInfoComponent',
    components: {
        ModalBasic,
        MemberItem,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        activeChat: {
            type: Object,
            default: () => {
                return {}
            },
        },
        activeUser: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    emits: ['closed', 'show-media', 'close-chat'],
    data: () => ({}),
    computed: {
        creatorId() {
            if ('created_by' && this.activeChat) {
                return this.activeChat.created_by.id
            } else {
                return 'creatorId'
            }
        },
        activeUserId() {
            if ('id' && this.activeUser) {
                return this.activeUser.id
            } else {
                return 'activeUserId'
            }
        },
    },
    methods: {
        close() {
            this.$emit('closed')
        },
        showMedia() {
            this.$emit('show-media')
        },
        closeChat() {
            this.$emit('close-chat')
        },
    },
}
</script>
