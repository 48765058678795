<template>
  <ModalBlank :backdrop="true" id="danger-modal" :modal-open="isOpen" @close-modal="close">
    <div class="flex space-x-4 p-5 text-center">
      <!-- Content -->
      <div class="w-full">
        <!-- Modal header -->
        <div class="mb-2">
          <div class="text-lg font-semibold text-slate-800">
            {{ title }}
          </div>
        </div>
        <!-- Modal content -->
        <div class="mb-10 text-sm">
          <div class="space-y-2">
            <p>
              {{ description }}
            </p>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="flex flex-wrap justify-center space-x-2">
          <button
            class="btn-lg w-5/12 border-slate-200 text-slate-600 hover:border-slate-300"
            @click.stop="close()"
          >
            {{ $t('button.cancel') }}
          </button>
          <button
            class="btn-lg w-5/12 bg-rose-500 text-white hover:bg-rose-600"
            @click.stop="doDelete()"
          >
            {{ $t('button.delete') }}
          </button>
        </div>
      </div>
    </div>
  </ModalBlank>
</template>
<script>
import ModalBlank from './ModalBlank.vue'

export default {
  name: 'ModalDeleteComponent',
  components: {
    ModalBlank,
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closed', 'delete'],
  methods: {
    close() {
      this.$emit('closed')
    },
    doDelete() {
      this.$emit('delete', { id: this.id })
    },
  },
}
</script>
