import Echo from "laravel-echo";
import io from "socket.io-client";

let authorization = null;
let hosting = import.meta.env.VITE_API_URL + ':' + import.meta.env.VITE_WEBSOCKET_PORT;
const token = localStorage.getItem("redek.accessToken");
if (token) {
    if (token) {
        let auth = JSON.parse(token);
        authorization = "Bearer " + auth;
    }
}

var echo = null;
if (import.meta.env.VITE_WEBSOCKET_ENABLED == "true" || import.meta.env.VITE_WEBSOCKET_ENABLED == "TRUE") {
    echo = new Echo({
        broadcaster: "socket.io",
        host: hosting,
        client: io,
        auth: {
            headers: {
                Authorization: authorization
            }
        },
        transports: ['websocket', 'polling', 'flashsocket'],
    });
}

export default {
    install: (app, options) => {
        app.config.globalProperties.$echo = echo;
    }
}

