<template>
    <main>
        <div class="relative flex">
            <!-- Content -->
            <div class="w-full">
                <div class="flex h-full min-h-screen flex-col justify-center bg-gray-100">
                    <div class="mb-8 flex justify-center">
                        <img class="h-14 object-cover object-center" src="../assets/images/logo.png" alt="Redek Logo" />
                    </div>
                    <div class="w-2/4 self-center rounded-lg bg-white px-8 py-8 shadow-md lg:w-1/3">
                        <h1 class="mb-2 text-3xl font-bold text-slate-800">
                            {{ $t('login.title') }}
                        </h1>
                        <p class="text-justify text-sm">
                            {{ $t('login.already') }}
                            <router-link
                            id="form-login-action-signup"
                            class="text-sm font-bold text-sky-500"
                            to="/register">
                                {{ $t('login.signUp') }}
                            </router-link>
                        </p>
                        <!-- Form -->

                        <Form id="form-login" v-slot="{ errors }" :validation-schema="loginSchema" @submit="doLogin">
                            <div class="mt-4 space-y-4">
                                <div>
                                    <label class="mb-1 block text-sm font-medium" for="email">
                                        {{ $t('formLabel.email') }}
                                    </label>
                                    <Field v-slot="{ field }" v-model="email" name="email">
                                        <input
                                        :placeholder="$t('formInput.enterEmail')"
                                        class="form-input h-12 w-full rounded-md"
                                        id="form-login-email"
                                        v-bind="field"
                                        type="email"
                                        autofocus
                                        />
                                    </Field>
                                    <span class="text-sm text-red-500">
                                        <template v-if="errors.email !== undefined">
                                            {{ $t(errors.email) }}
                                        </template>
                                    </span>
                                </div>
                                <div>
                                    <label class="mb-1 block text-sm font-medium" for="password">
                                        {{ $t('formLabel.password') }}
                                    </label>
                                    <Field v-slot="{ field }" v-model="password" name="password">
                                        <div class="relative">
                                            <input
                                            :placeholder="$t('formInput.enterPassword')"
                                            class="form-input h-12 w-full rounded-md"
                                            :type="showPassword ? 'text' : 'password'"
                                            autocomplete="new-password"
                                            id="form-login-password"
                                            required="true"
                                            v-bind="field"
                                            />
                                            <div class="absolute inset-0 left-auto flex cursor-pointer items-center">
                                                <span class="px-3 text-sm font-medium text-slate-400">
                                                    <EyeStrikeIcon v-if="showPassword" class="h-6 w-6"
                                                        @click="showPassword = false" />
                                                    <EyeIcon v-else class="h-6 w-6" @click="showPassword = true" />
                                                </span>
                                            </div>
                                        </div>
                                    </Field>
                                    <span class="text-sm text-red-500">
                                        <template v-if="errors.password !== undefined">
                                            {{ $t(errors.password) }}
                                        </template>
                                    </span>
                                </div>
                            </div>
                            <div class="mt-2 flex flex-col">
                                <div class="flex w-full">
                                    <router-link
                                    id="form-login-action-forgot-password"
                                    class="text-sm font-bold text-sky-500"
                                    to="/forgot-password">
                                        {{ $t('button.forgotPassword') }}
                                    </router-link>
                                </div>
                                <button
                                id="form-login-action-submit"
                                class="btn text-md mt-6 h-12 w-full rounded-md" :class="[
                                    errors.email === undefined && errors.password === undefined
                                        ? 'bg-sky-500 text-white hover:bg-sky-600'
                                        : 'bg-gray-300 text-slate-100',
                                    isLoading ? 'cursor-not-allowed' : 'cursor-pointer',
                                ]">
                                    <span v-if="isLoading">
                                        {{ $t('general.loading') }}
                                    </span>
                                    <span v-else>
                                        {{ $t('button.login') }}
                                    </span>
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { useToast } from 'vue-toastification'
import { Form, Field } from 'vee-validate'
import globalMixin from '@/mixins/global.js'

import EyeIcon from '@/assets/svg/eye.svg'
import EyeStrikeIcon from '@/assets/svg/eye-strike.svg'
import axios, { routePathAPI } from '../plugins/axios.js'

export default {
    name: 'LoginPage',
    components: {
        Form,
        Field,
        EyeIcon,
        EyeStrikeIcon,
    },
    mixins: [globalMixin],
    setup() {
        const toast = useToast()
        return { toast }
    },
    data: () => ({
        email: '',
        password: '',
        showPassword: false,
        activeUser: {},
    }),
    mounted() {
    },
    methods: {
        doLogin() {
            if (this.isLoading) return
            this.isLoading = true
            const payload = {
                username: this.email.toLowerCase(),
                password: this.password,
            }
            this.$store.dispatch('auth/login', payload)
            .then((OAuthResponse) => {
                this.isLoading = false;
                localStorage.setItem('token', OAuthResponse.access_token);
                this.$store.dispatch('auth/user')
                .then((userResponse) => {
                    localStorage.setItem('redek.temp.activeUser', JSON.stringify(userResponse));
                    if (userResponse.is_active_otp) {
                        /**
                         * Send OTP
                         */
                        this.$store.dispatch('auth/sendOtp')
                        .catch((error) => {
                            this.toast.error(this.extractErrorResponse(error))
                        });
                        this.$router.push({
                            name: 'verification-otp',
                            params: {
                                type: 'login',
                            },
                            query: {
                                lang: this.$route.query.lang,
                            },
                        });
                    } else {
                        this.$store.dispatch('auth/loginWhenOtpIsOff', { access_token: OAuthResponse.access_token })
                        .then(response => {
                            this.$store.dispatch('auth/resetActiveUserTemp');
                            this.verifyRedirectWhenLogin(response);
                        }).catch(error => {
                            if (
                                error.response &&
                                error.response.data &&
                                error.response.data.message &&
                                error.response.data.message.code
                            ) {
                                this.toast.error(this.$t('error.response.data.message.code'));
                            }
                        })
                    }
                }).catch((errorUser) => {
                    console.log(errorUser)
                    this.isLoading = false;
                    this.toast.error(this.extractErrorResponse(errorUser));
                });
            }).catch((error) => {
                this.isLoading = false;
                this.toast.error(this.extractErrorResponse(error));
            });
        }
    },
}
</script>
