export default {
    install: (app, options) => {
        app.directive("permission", {
            mounted: function (el, binding, vnode, old) {
                const permissions = localStorage.getItem('redek.permissions')
                    ? JSON.parse(atob(localStorage.getItem('redek.permissions')))
                    : null;
                let can = false;
                if (permissions) {
                    if (Array.isArray(binding.value)) {
                        can = binding.value.some(item => permissions.some(elem => elem.functionality.reference === item));
                    } else {
                        can = permissions.some(elem => elem.functionality.reference === binding.value);
                    }
                }
                if (!can) vnode.el.parentElement.removeChild(vnode.el);
            },
        });
    },
};
