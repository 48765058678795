<template>
    <div class="relative inline-flex">
        <button
            ref="trigger"
            class="flex items-center justify-center transition duration-150"
            :class="{ 'bg-slate-200': dropdownOpen }"
            aria-haspopup="true"
            :aria-expanded="dropdownOpen"
            @click.prevent="dropdownOpen = !dropdownOpen"
        >
            <div class="flex">
                <img
                v-if="selectedLanguage === 'en'"
                class="h-8 w-8 rounded-full"
                src="/src/assets/images/flags/en.png"
                width="28"
                height="28"
                />

                <img
                v-if="selectedLanguage === 'es'"
                class="h-8 w-8 rounded-full"
                src="/src/assets/images/flags/es.png"
                width="28"
                height="28"
                />
            </div>

            <div class="flex items-center">
                <span
                    class="ml-2 truncate text-sm font-medium group-hover:text-slate-800"
                >
                    {{ $t(`language.${selectedLanguage}`) }}
                </span>
                <svg
                    class="ml-2 h-3 w-3 shrink-0 fill-current text-slate-400"
                    viewBox="0 0 12 12"
                >
                    <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                </svg>
            </div>
        </button>
        <transition
            enter-active-class="transition ease-out duration-200 transform"
            enter-from-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-200"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="dropdownOpen"
                class="min-w-54 absolute z-10 -mr-48 mt-1 origin-top-right overflow-hidden rounded border border-slate-200 bg-white py-1.5 shadow-lg sm:mr-0"
                :class="[
                    align === 'right' ? 'right-0' : 'left-0',
                    direction === 'top' ? 'bottom-full' : 'top-full ',
                ]"
            >
                <ul
                    ref="dropdown"
                    @focusin="dropdownOpen = true"
                    @focusout="dropdownOpen = false"
                >
                    <li class="border-b border-slate-200 last:border-0">
                        <button
                        class="flex flex-row justify-between py-2 px-4 hover:bg-slate-50"
                        @click="chooseLang('en')"
                        >
                            <div class="flex">
                                <span
                                    class="ml-2 truncate text-sm font-medium group-hover:text-slate-800"
                                >
                                    {{ $t('language.en') }}
                                </span>
                            </div>
                            <div class="ml-2 flex h-6 w-6">
                                <img
                                class="h-6 w-6 rounded-full"
                                src="/src/assets/images/flags/en.png"
                                width="28"
                                height="28"
                                />
                            </div>
                        </button>
                    </li>
                    <li class="border-b border-slate-200 last:border-0">
                        <button
                        class="flex flex-row justify-between py-2 px-4 hover:bg-slate-50"
                        @click="chooseLang('es')"
                        >
                            <div class="flex">
                                <span
                                    class="ml-2 truncate text-sm font-medium group-hover:text-slate-800"
                                >
                                    {{ $t('language.es') }}
                                </span>
                            </div>
                            <div class="ml-2 flex h-6 w-6">
                                <img
                                class="h-6 w-6 rounded-full"
                                src="/src/assets/images/flags/es.png"
                                width="28"
                                height="28"
                                />
                            </div>
                        </button>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'

export default {
    name: 'DropdownLanguages',
    inject: ['getImgUrl'],
    props: {
        align: null,
        direction: null,
    },
    setup(props) {
        const dropdownOpen = ref(false)
        const trigger = ref(null)
        const dropdown = ref(null)

        // close on click outside
        const clickHandler = ({ target }) => {
            if (
                !dropdownOpen.value ||
                dropdown.value.contains(target) ||
                trigger.value.contains(target)
            )
                return
            dropdownOpen.value = false
        }

        // close if the esc key is pressed
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen.value || keyCode !== 27) return
            dropdownOpen.value = false
        }

        const selectedLanguage = ref('en')
        // const languages = ref([
        //     { key: 'en', flag: '/src/assets/images/flags/en.png', },
        //     { key: 'es', flag: '/src/assets/images/flags/es.png', },
        //     { key: 'id', flag: '' },
        //     { key: 'pt', flag: '' }
        // ])
        const enabledLanguages = ref(['en', 'es'])

        onMounted(() => {
            document.addEventListener('click', clickHandler)
            document.addEventListener('keydown', keyHandler)
        })

        onUnmounted(() => {
            document.removeEventListener('click', clickHandler)
            document.removeEventListener('keydown', keyHandler)
        })

        return {
            dropdownOpen,
            trigger,
            dropdown,
            selectedLanguage,
            enabledLanguages,
        }
    },
    mounted () {
        const lang = localStorage.getItem('redek.lang')
        if (this.enabledLanguages.some(item => item === lang)) {
            this.selectedLanguage = lang
        } else {
            this.selectedLanguage = 'en'
        }
    },
    methods: {
        chooseLang (lang) {
            localStorage.setItem('redek.lang', lang)
            this.selectedLanguage = lang
            this.dropdownOpen = false
            this.$i18n.locale = lang
        },
    },
}
</script>
