<template>
  <div
    class="icon"
    :class="[`icon--${name}`, `icon--${size}`, { 'has-align-fix': fixAlign }]"
  >
    <!-- <img :src="getImageUrl(`${name}.svg`)" :alt="`${name}`" /> -->
    <BoldIcon v-if="name === 'bold'" />
    <ItalicIcon v-if="name === 'italic'" />
    <StrikeIcon v-if="name === 'strike'" />
    <UnderlineIcon v-if="name === 'underline'" />
    <CodeIcon v-if="name === 'code'" />
    <ULIcon v-if="name === 'ul'" />
    <OLIcon v-if="name === 'ol'" />
    <QuoteIcon v-if="name === 'quote'" />
    <HRIcon v-if="name === 'hr'" />
    <UndoIcon v-if="name === 'undo'" />
    <RedoIcon v-if="name === 'redo'" />
  </div>
</template>

<script>
import BoldIcon from '@/assets/svg/icons/bold.svg'
import ItalicIcon from '@/assets/svg/icons/italic.svg'
import StrikeIcon from '@/assets/svg/icons/strike.svg'
import UnderlineIcon from '@/assets/svg/icons/underline.svg'
import CodeIcon from '@/assets/svg/icons/code.svg'
import ULIcon from '@/assets/svg/icons/ul.svg'
import OLIcon from '@/assets/svg/icons/ol.svg'
import QuoteIcon from '@/assets/svg/icons/quote.svg'
import HRIcon from '@/assets/svg/icons/hr.svg'
import UndoIcon from '@/assets/svg/icons/undo.svg'
import RedoIcon from '@/assets/svg/icons/redo.svg'

export default {
  components: {
    BoldIcon,
    ItalicIcon,
    StrikeIcon,
    UnderlineIcon,
    CodeIcon,
    ULIcon,
    OLIcon,
    QuoteIcon,
    HRIcon,
    UndoIcon,
    RedoIcon,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'normal',
    },
    modifier: {
      type: Object,
      default: null,
    },
    fixAlign: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getImageUrl(name) {
      return new URL(`../../assets/svg/icons/${name}`, import.meta.url).href
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../src/assets/css/additional-styles/editor/main.scss'
</style>
