<template>
    <div class="flex items-center justify-center">
        <div
        :class="spinClass"
        class="animate-spin rounded-full h-5 w-5 border-t-2 border-blue-500 border-solid"></div>
    </div>
</template>
<script>

export default {
    props: {
        spinClass: {
            type: String,
            default: '',
        }
    }
}

</script>
