<template>
    <div class="-m-1.5 flex flex-wrap items-center">
        <div class="m-1.5">
            <ModalBasic
            id="notes-modal"
            :modal-open="isOpen"
            wrapper-class="max-w-4xl"
            :show-border-header="false"
            :title="activeActivity && Object.keys(activeActivity).length !== 0
                ? $t('admin.timeline.modalCreateTimeline.editTitle')
                : $t('admin.timeline.modalCreateTimeline.title')
            "
            @close-modal="close">
                <Form ref="form" v-slot="{ errors }" :validation-schema="newActivitySchema" @submit="submitForm">
                    <div class="px-5">
                        <div class="space-y-3 px-5">
                            <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                                <div>
                                    <label class="mb-1 block text-xs font-bold" for="types">
                                        {{ $t('admin.timeline.modalCreateTimeline.type') }}
                                    </label>
                                    <select id="types-modal" v-model="type_id" class="form-select w-full p-2">
                                        <option v-for="activityType in types" :key="activityType.id" :value="activityType.id"
                                            :selected="activeActivity.activityType
                                                    ? activeActivity.activityType.name === activityType.name
                                                    : ''
                                                ">
                                            {{ activityType.name }}
                                        </option>
                                    </select>
                                </div>
                                <div>
                                    <label class="mb-1 block text-xs font-bold" for="title">
                                        {{ $t('admin.timeline.modalCreateTimeline.activityTitle') }}
                                    </label>
                                    <Field v-slot="{ field }" v-model="title" name="title">
                                        <input id="title" v-bind="field" class="form-input w-full p-2" type="text" required
                                            :placeholder="$t('admin.timeline.modalCreateTimeline.activityTitle')
                                                " />
                                    </Field>
                                    <div v-if="errors.title !== undefined" class="flex w-full">
                                        <span class="text-sm text-red-500">
                                            {{ $t(errors.title) }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                                <div class="flex flex-col">
                                    <label class="mb-1 block text-xs font-bold" for="meetingTime">
                                        {{ $t('calendar.modalNewAgenda.meetingTime') }}
                                    </label>
                                    <Datepicker v-model="date" :max-today="true" :max-one-month="false" :min-today="false"
                                        :show-icon="true" :date-only="true" wrap-class="form-input pl-10 h-11 w-full rounded-md"
                                        mode="single" @change-date="changeDate" />
                                </div>
                                <div>
                                    <label class="mb-1 block text-xs font-bold" for="subtitle">
                                        {{ $t('admin.timeline.modalCreateTimeline.subtitle') }}
                                    </label>
                                    <input id="subtitle" v-model="subtitle" class="form-input w-full p-2" type="text" required
                                        :placeholder="$t('admin.timeline.modalCreateTimeline.subtitle')
                                            " />
                                </div>
                            </div>
                            <div
                            v-if="type_id === 'external'"
                            class="flex flex-col gap-2">
                                <label class="mb-1 block text-xs font-bold">
                                    {{ $t('modal.attachment') }}
                                </label>
                                <label for="files"
                                    class="flex h-48 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-gray-300 bg-gray-50 hover:bg-gray-100">
                                    <div class="flex flex-col items-center justify-center py-2">
                                        <svg aria-hidden="true" class="mb-3 h-10 w-10 text-gray-400" fill="none"
                                            stroke="currentColor" viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12">
                                            </path>
                                        </svg>
                                        <p class="text-sm text-gray-500">
                                            {{ $t('forms.dragAndDrop') }}
                                        </p>
                                        <p class="text-sm text-gray-500">
                                            {{ $t('forms.or') }}
                                        </p>
                                        <p class="text-sm font-semibold text-sky-500">
                                            {{ $t('forms.browseFiles') }}
                                        </p>
                                    </div>
                                    <input id="files" type="file" class="hidden" @change="fileExternalInput" />
                                    <span v-if="attachment">
                                        {{ attachment.name }}
                                    </span>
                                </label>
                            </div>
                            <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                                <div>
                                    <label class="mb-1 block text-xs font-bold" for="content">
                                        {{ $t('admin.timeline.modalCreateTimeline.content') }}
                                    </label>
                                    <textarea id="content" v-model="content" class="form-input w-full p-2" required
                                        :placeholder="$t('admin.timeline.modalCreateTimeline.content')
                                            "></textarea>
                                </div>
                                <div>
                                    <label class="mb-1 block text-xs font-bold" for="extra">
                                        {{ $t('admin.timeline.modalCreateTimeline.extra') }}
                                    </label>
                                    <textarea id="extra" v-model="extra" class="form-input w-full p-2" required
                                        :placeholder="$t('admin.timeline.modalCreateTimeline.extra')"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-5 py-4">
                        <div class="flex flex-wrap justify-center space-x-2 px-4">
                            <button type="submit"
                                class="btn-sm w-full whitespace-nowrap rounded-lg bg-sky-500 p-3 text-white hover:bg-sky-600"
                                :class="[
                                    isCreateLoading ? 'cursor-not-allowed' : 'cursor-pointer',
                                ]">
                                <span v-if="isCreateLoading">
                                    {{ $t('general.loading') }}
                                </span>
                                <span v-else>
                                    {{ $t('admin.timeline.modalCreateTimeline.save') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </Form>
            </ModalBasic>
        </div>
    </div>
</template>
<script>
import ModalBasic from './ModalBasic.vue'
import { Form, Field } from 'vee-validate'
import globalMixin from '@/mixins/global.js'
import Datepicker from '@/components/Datepicker.vue'

export default {
    name: 'ModalCreateActivityComponent',
    components: {
        ModalBasic,
        Form,
        Field,
        Datepicker,
    },
    mixins: [globalMixin],

    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        isCreateLoading: {
            type: Boolean,
            default: false,
        },
        activeActivity: {
            type: Object,
            default: () => {
                return {
                    user_id: '',
                    type_id: '',
                    case_id: '',
                    title: '',
                    subtitle: '',
                    content: '',
                    extra: '',
                    date: '',
                }
            },
        },
    },
    emits: ['closed', 'submitData'],
    data: () => ({
        user_id: '',
        type_id: 'stage',
        case_id: '',
        title: '',
        subtitle: '',
        content: '',
        extra: '',
        date: '',
        types: [
            { id: 'stage', name: 'Stage' },
            { id: 'external', name: 'External comunication' },
        ],
        attachment: null,
    }),
    watch: {
        isOpen(val) {
            if (!val) this.close()
            else this.resetForm()
        },
        type_id(val) {
            if (val != 'external') {
                this.attachment = null;
            }
        }
    },
    mounted() {
        this.user_id = this.activeActivity.user_id
        this.type_id = this.activeActivity.type_id
            ? this.activeActivity.type_id
            : 'stage'
        this.case_id = this.activeActivity.case_id
        this.title = this.activeActivity.title
        this.subtitle = this.activeActivity.subtitle
        this.content = this.activeActivity.content
        this.extra = this.activeActivity.extra
        this.date = this.activeActivity.date
    },
    methods: {
        resetForm() {
            this.$refs.form.resetForm()
            this.user_id = ''
            this.type_id = 'stage'
            this.case_id = ''
            this.subtitle = ''
            this.content = ''
            this.extra = ''
            this.date = ''
            this.attachment = null
        },
        close() {
            this.resetForm()
            this.$emit('closed')
        },
        submitForm() {
            const formData = {
                user_id: this.user_id,
                type_id: this.type_id,
                case_id: this.case_id,
                title: this.title,
                subtitle: this.subtitle,
                content: this.content,
                extra: this.extra,
                date: this.date,
                attachment: this.attachment,
            }
            this.$emit('submitData', formData)
        },
        changeDate(val) {
            this.date = val
        },
        fileExternalInput (event) {
            this.attachment = event.target.files[0]
        },
    },
}
</script>
