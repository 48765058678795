<template>
  <div class="relative rounded-sm">
    <div>
      <div class="overflow-x-auto">
        <table class="w-full table-auto divide-y divide-slate-200">
          <thead
            class="bg-transparent text-xs font-semibold uppercase text-slate-500"
          >
            <tr>
              <th
                v-for="(header, index) in headerData"
                :key="index"
                class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                :colspan="header.colspan"
              >
                <div
                  class="space-x-2 text-left font-semibold"
                  :class="[
                    typeof header.sort !== 'undefined' ? 'cursor-pointer' : '',
                  ]"
                  @click="
                    typeof header.sort !== 'undefined'
                      ? changeSort(header.name, header.sort)
                      : ''
                  "
                >
                  <span>
                    {{ header.name }}
                  </span>
                  <template v-if="typeof header.sort !== 'undefined'">
                    <ChevronUpIcon
                      v-if="header.sort === 'ASC'"
                      class="inline w-4"
                    />
                    <ChevronDownIcon v-else class="inline w-4" />
                  </template>
                </div>
              </th>
            </tr>
          </thead>
          <tbody :class="tbodyClass">
            <slot></slot>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/vue/24/solid'

export default {
  name: 'TableComponent',
  components: {
    ChevronUpIcon,
    ChevronDownIcon,
  },
  props: {
    headerData: {
      type: Array,
      default: () => {
        return []
      },
    },
    tbodyClass: {
      type: String,
      default: '',
    },
  },
  emits: ['change-sort'],
  methods: {
    changeSort(name, sort) {
      const newSort = sort === 'ASC' ? 'DESC' : 'ASC'
      this.$emit('change-sort', {
        name: name,
        sort: newSort,
      })
    },
  },
}
</script>
