<template>
    <div class="-m-1.5 flex flex-wrap items-center">
        <div class="m-1.5">
            <ModalBasic
            id="notes-modal"
            :modal-open="isOpen"
            :show-border-header="false" :title="!isEdit
                ? $t('parameters.masterData.caseTypes.new.modal.title')
                : $t('parameters.masterData.caseTypes.new.modal.titleEdit')
            "
            @close-modal="close"
            wrapper-class="max-w-md"
            :backdrop="true"
            >
                <Form
                ref="form"
                v-slot="{ errors }"
                :validation-schema="newCaseTypeSchema"
                @submit="submitForm">
                    <div class="flex flex-col gap-3 pb-4 px-6">
                        <div class="flex flex-col gap-2">
                            <label class="font-bold text-sm">
                                {{ $t('parameters.masterData.caseTypes.new.name') }}
                            </label>
                            <Field
                            name="name"
                            v-model="name"
                            v-slot="{ field }"
                            >
                                <input
                                v-bind="field"
                                :placeholder="$t('parameters.masterData.caseTypes.new.placeholderName')"
                                type="text"
                                class="form-input w-full rounded-md"
                                >
                            </Field>
                            <div v-if="errors.name !== undefined" class="flex w-full">
                                <span class="text-sm text-red-500">
                                    {{ $t(errors.name) }}
                                </span>
                            </div>
                        </div>
                        <div class="flex flex-col gap-2">
                            <label class="font-bold text-sm">
                                {{ $t('parameters.masterData.caseTypes.new.description') }}
                            </label>
                            <Field
                            name="description"
                            v-model="description"
                            v-slot="{ field }"
                            >
                                <input
                                v-bind="field"
                                :placeholder="$t('parameters.masterData.caseTypes.new.placeholderDescription')"
                                type="text"
                                class="form-input w-full rounded-md"
                                >
                            </Field>
                            <div v-if="errors.description !== undefined" class="flex w-full">
                                <span class="text-sm text-red-500">
                                    {{ $t(errors.description) }}
                                </span>
                            </div>
                        </div>
                        <div class="flex items-center gap-2">
                            <input
                            v-model="status"
                            type="checkbox"
                            class="form-input rounded-md"
                            >
                            <label class="font-bold text-sm">
                                {{ $t('parameters.masterData.common.form.status') }}
                            </label>
                        </div>
                        <button
                        type="submit"
                        class="btn-sm w-full whitespace-nowrap rounded-lg bg-sky-500 p-3 text-white hover:bg-sky-600"
                        :class="[
                            saving ? 'cursor-not-allowed' : 'cursor-pointer',
                        ]">
                            <span v-if="saving">
                                {{ $t('general.loading') }}
                            </span>
                            <span v-else>
                                {{ $t('button.save') }}
                            </span>
                        </button>
                    </div>
                </Form>
            </ModalBasic>
        </div>
    </div>
</template>
<script>
import ModalBasic from '@/components/ModalBasic.vue'
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'

export default {
    name: 'ModalNewCurrencyComponent',
    components: {
        ModalBasic,
        Form,
        Field,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
        saving: {
            type: Boolean,
            default: false,
        },
        currentCaseType:  {
            type: Object,
            default: null,
        },
    },
    emits: ['closed', 'submitData'],
    data: function () {
        return {
            id: null,
            name: null,
            description: null,
            status: true,
            newCaseTypeSchema: Yup.object().shape({
                name: Yup.string()
                .typeError('parameters.masterData.caseTypes.new.validations.name.required')
                .required('parameters.masterData.caseTypes.new.validations.name.required')
                .max(255, 'parameters.masterData.caseTypes.new.validations.name.maximum'),
                description: Yup.string()
                .typeError('parameters.masterData.caseTypes.new.validations.description.maximum')
                .max(255, 'parameters.masterData.caseTypes.new.validations.description.maximum'),
            })
        }
    },
    watch: {
        currentCaseType(val) {
            if (val) {
                this.id = val.id ?? null
                this.name = val.name ?? null
                this.description = val.description ?? null
                this.status = val.status
            }
        },
        isOpen(val) {
            if (!val) this.close()
            // else this.$refs.form.resetForm()
        },
    },
    methods: {
        close() {
            this.$refs.form.resetForm()
            this.$emit('closed')
        },
        submitForm() {
            this.$emit('submitData', {
                id: this.id,
                name: this.name,
                description: this.description,
                status: this.status,
            })
        },
    },
}
</script>
