<template>
    <div class="-m-1.5 flex flex-wrap items-center">
        <div class="m-1.5">
            <ModalBasic
            id="notes-modal"
            :modal-open="isOpen"
            :show-border-header="false" :title="isEdit
                ? $t('admin.user.modalCreateUser.editTitle')
                : $t('admin.user.modalCreateUser.title')
            "
            @close-modal="close"
            wrapper-class="max-w-3xl"
            :backdrop="true"
            >
                <Form
                ref="form"
                id="form_create_update_users"
                v-slot="{ errors }"
                :validation-schema="adminRegisterSchema"
                @submit="submitForm">
                    <div class="px-5">
                        <div class="space-y-3 px-5">
                            <Field v-slot="{ field }" name="isEdit">
                                <input type="checkbox" name="isEdit" class="hidden" v-bind="field" :checked="isEdit" />
                            </Field>
                            <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                                <div>
                                    <label class="mb-1 block text-xs font-bold" for="roles">
                                        {{ $t('admin.user.modalCreateUser.identificationType') }}
                                    </label>
                                    <Field
                                    v-slot="{ field }"
                                    v-model="identification_type_id"
                                    name="identification_type_id">
                                        <select
                                        id="form_create_update_users_identification_type_id"
                                        v-bind="field"
                                        class="form-select w-full p-2">
                                            <option
                                            v-for="identificationType in identificationTypes"
                                            :key="identificationType.id"
                                            :value="identificationType.id">
                                                {{ identificationType.name.slice(0, 1) + identificationType.name.slice(1, identificationType.length).toLowerCase() }}
                                            </option>
                                        </select>
                                    </Field>
                                    <div v-if="errors.identification_type_id !== undefined" class="flex w-full">
                                        <span class="text-sm text-red-500">
                                            {{ $t(errors.identification_type_id) }}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <label class="mb-1 block text-xs font-bold" for="roles">
                                        {{ $t('admin.user.modalCreateUser.identification') }}
                                    </label>
                                    <Field
                                    v-slot="{ field }"
                                    v-model="identification"
                                    name="identification">
                                        <input
                                        id="form_create_update_users_identification"
                                        v-bind="field"
                                        class="form-input w-full p-2"
                                        type="text"
                                        required
                                        :placeholder="$t('admin.user.modalCreateUser.identification')"
                                        />
                                    </Field>
                                    <div v-if="errors.identification !== undefined" class="flex w-full">
                                        <span class="text-sm text-red-500">
                                            {{ $t(errors.identification) }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <Field v-slot="{ field }" name="isEdit">
                                <input type="checkbox" name="isEdit" class="hidden" v-bind="field" :checked="isEdit" />
                            </Field>

                            <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                                <div>
                                    <label class="mb-1 block text-xs font-bold" for="roles">
                                        {{ $t('admin.user.modalCreateUser.role') }}
                                    </label>
                                    <Field
                                    v-slot="{ field }"
                                    v-model="role_id"
                                    name="role_id">
                                        <select
                                        id="form_create_update_users_role"
                                        v-bind="field"
                                        class="form-select w-full p-2">
                                            <option v-for="role in roles" :key="role.id" :value="role.id" :selected="currentUser.role
                                                    ? currentUser.role.name === role.name
                                                    : ''
                                                ">
                                                {{ role.name }}
                                            </option>
                                        </select>
                                    </Field>
                                    <div v-if="errors.role_id !== undefined" class="flex w-full">
                                        <span class="text-sm text-red-500">
                                            {{ $t(errors.role_id) }}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <label class="mb-1 block text-xs font-bold" for="username">
                                        {{ $t('admin.user.modalCreateUser.username') }}
                                    </label>
                                    <Field v-slot="{ field }" v-model="username" name="username">
                                        <input id="form_create_update_users_username" v-bind="field" class="form-input w-full p-2" type="text"
                                            autocomplete="new" required
                                            :placeholder="$t('admin.user.modalCreateUser.username')" />
                                    </Field>
                                    <div v-if="errors.username !== undefined" class="flex w-full">
                                        <span class="text-sm text-red-500">
                                            {{ $t(errors.username) }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div v-if="role_id === 'e10a2333-5ba6-42ee-8960-481f1a469409'">
                                <label class="mb-1 block text-xs font-bold" for="banks">
                                    {{ $t('admin.user.modalCreateUser.bank') }}
                                </label>
                                <Field v-slot="{ field }" v-model="bank_id" name="bank_id">
                                    <select id="banks" v-bind="field" class="form-select w-full p-2">
                                        <option v-for="bank in banks" :key="bank.id" :value="bank.id" :selected="true">
                                            {{ bank.name }}
                                        </option>
                                    </select>
                                </Field>
                                <div v-if="errors.bank_id !== undefined" class="flex w-full">
                                    <span class="text-sm text-red-500">
                                        {{ $t(errors.bank_id) }}
                                    </span>
                                </div>
                            </div> -->

                            <div class="grid grid cols-1 md:grid-cols-2 gap-3">
                                <div>
                                    <label class="mb-1 block text-xs font-bold" for="first_name">
                                        {{ $t('admin.user.modalCreateUser.firstName') }}
                                    </label>
                                    <Field v-slot="{ field }" v-model="first_name" name="first_name">
                                        <input id="form_create_update_users_firstname" v-bind="field" class="form-input w-full p-2" type="text" required
                                            :placeholder="$t('admin.user.modalCreateUser.firstName')" />
                                    </Field>
                                    <div v-if="errors.first_name !== undefined" class="flex w-full">
                                        <span class="text-sm text-red-500">
                                            {{ $t(errors.first_name) }}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <label class="mb-1 block text-xs font-bold" for="last_name">
                                        {{ $t('admin.user.modalCreateUser.lastName') }}
                                    </label>
                                    <Field v-slot="{ field }" v-model="last_name" name="last_name">
                                        <input id="form_create_update_users_lastname" v-bind="field" class="form-input w-full p-2" type="text" required
                                            :placeholder="$t('admin.user.modalCreateUser.lastName')" />
                                    </Field>
                                    <div v-if="errors.last_name !== undefined" class="flex w-full">
                                        <span class="text-sm text-red-500">
                                            {{ $t(errors.last_name) }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                                <div>
                                    <label class="mb-1 block text-xs font-bold" for="email">
                                        {{ $t('admin.user.modalCreateUser.email') }}
                                    </label>
                                    <Field v-slot="{ field }" v-model="email" name="email">
                                        <input id="form_create_update_users_email" v-bind="field" class="form-input w-full p-2" type="email"
                                            autocomplete="new" required :placeholder="$t('admin.user.modalCreateUser.email')" />
                                    </Field>
                                    <div v-if="errors.email !== undefined" class="flex w-full">
                                        <span class="text-sm text-red-500">
                                            {{ $t(errors.email) }}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <label class="mb-1 block text-xs font-bold" for="phone">
                                        {{ $t('admin.user.modalCreateUser.phone') }}
                                    </label>
                                    <Field v-slot="{ field }" v-model="phone" name="phone">
                                        <input id="form_create_update_users_phone" v-bind="field" class="form-input w-full p-2" type="phone" required
                                            :placeholder="$t('admin.user.modalCreateUser.phone')" />
                                    </Field>
                                    <div v-if="errors.phone !== undefined" class="flex w-full">
                                        <span class="text-sm text-red-500">
                                            {{ $t(errors.phone) }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label class="mb-1 block text-xs font-bold" for="password">
                                    {{ $t('admin.user.modalCreateUser.password') }}
                                </label>
                                <Field v-slot="{ field }" v-model="password" name="password">
                                    <input id="form_create_update_users_password" v-bind="field" class="form-input w-full p-2" type="password"
                                        autocomplete="new-password" required
                                        :placeholder="$t('admin.user.modalCreateUser.password')" />
                                </Field>
                                <div v-if="errors.password !== undefined" class="flex w-full">
                                    <span class="text-sm text-red-500">
                                        {{ $t(errors.password) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-5 py-4">
                        <div class="flex flex-wrap justify-center space-x-2 px-4">
                            <button
                            type="submit"
                            class="btn-sm w-full whitespace-nowrap rounded-lg bg-sky-500 p-3 text-white hover:bg-sky-600"
                            id="form_create_update_users_action_submit"
                            :class="[
                                isUserLoading ? 'cursor-not-allowed' : 'cursor-pointer',
                            ]">
                                <span v-if="isUserLoading">
                                    {{ $t('general.loading') }}
                                </span>
                                <span v-else>
                                    {{ $t('admin.user.modalCreateUser.save') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </Form>
            </ModalBasic>
        </div>
    </div>
</template>
<script>
import ModalBasic from '@/components/ModalBasic.vue'
import { Form, Field } from 'vee-validate'
import globalMixin from '@/mixins/global.js'

export default {
    name: 'ModalCreateUserComponent',
    components: {
        ModalBasic,
        Form,
        Field,
    },
    mixins: [globalMixin],
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        isUserLoading: {
            type: Boolean,
            default: false,
        },
        currentUser: {
            type: Object,
            default: () => {
                return {
                    role_id: '',
                    first_name: '',
                    last_name: '',
                    email: '',
                }
            },
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
        roles: {
            type: Array,
            required: true,
        },
        banks: {
            type: Array,
            required: true,
        },
        identificationTypes: {
            type: Array,
            required: true,
        },
    },
    emits: ['closed', 'submitData'],
    data: () => ({
        user_id: null,
        identification_type_id: null,
        identification: null,
        role_id: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '********',
        bank_id: null,
        phone: '',
        username: '',
    }),
    watch: {
        currentUser(val) {
            this.identification_type_id = val.identification_type_id ?? null
            this.identification = val.identification ?? null
            this.user_id = val.id ?? null
            this.role_id = val.role_id
            this.first_name = val.first_name
            this.last_name = val.last_name
            this.email = val.email
            this.bank_id = val.bank_id
            this.phone = val.phone
            this.username = val.username
            if (val && val.id) this.password = '********'
        },
        isOpen(val) {
            if (!val) this.close()
            // else this.$refs.form.resetForm()
        },
    },
    methods: {
        close() {
            this.$refs.form.resetForm()

            this.$emit('closed')
        },
        submitForm() {
            const formData = {
                identification_type_id: this.identification_type_id,
                identification: this.identification,
                user_id: this.user_id,
                role_id: this.role_id,
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                password: this.password,
                bank_id: this.bank_id,
                phone: this.phone,
                username: this.username,
            }

            this.$emit('submitData', formData)
        },
    },
}
</script>
