<template>
    <div class="flex h-screen overflow-hidden">
        <Sidebar
        :sidebar-open="sidebarOpen"
        :case-stage="$route.query.status"
        @close-sidebar="sidebarOpen = false"
        :get-the-case="true"
        />

        <div
        class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden p-4 lg:p-8"
        >
            <div class="flex justify-between items-center py-4">
                <div class="flex flex-col gap-1 text-sm">
                    <div class="flex items-center gap-3">
                        <div class="w-2 h-2 bg-yellow-500"></div>
                        <span>
                            {{ $t('calendar.documentRequests') }}
                        </span>
                    </div>
                    <div class="flex items-center gap-3">
                        <div class="w-2 h-2 bg-green-500"></div>
                        <span>
                            {{ $t('calendar.meetings') }}
                        </span>
                    </div>
                    <div class="flex items-center gap-3">
                        <div class="w-2 h-2 bg-sky-500"></div>
                        <span>
                            {{ $t('calendar.alerts') }}
                        </span>
                    </div>
                </div>
                <EditMenu
                v-permission="'cases_manage_calendar_create'"
                v-if="!$store.getters['cases/currentCaseWasClosed'] && isAValidPartInCase($store.getters['auth/getCurrentUser'])"
                align="right"
                class="relative inline-flex"
                custom-class="rounded-xl !bg-sky-500 !text-white !hover:bg-sky-600 p-2 h-fit"
                :show-text="true"
                :show-dots="true"
                :text="$t('calendar.buttonAdd')"
                >
                    <li
                    v-permission="'cases_manage_calendar_create_request'"
                    >
                        <span
                            class="flex cursor-pointer py-1 px-3 text-sm font-medium text-slate-600 hover:text-slate-800"
                            @click.stop="
                                newRequestDocumentModalOpen = true
                            "
                        >
                            {{ $t('calendar.documentRequest') }}
                        </span>
                    </li>
                    <li
                    v-permission="'cases_manage_calendar_create_agenda'"
                    >
                        <span
                            class="flex cursor-pointer py-1 px-3 text-sm font-medium text-slate-600 hover:text-slate-800"
                            @click.stop="newAgendaModalOpen = true"
                        >
                            {{ $t('calendar.newAgenda') }}
                        </span>
                    </li>
                </EditMenu>
            </div>

            <FullCalendar
            ref="calendar"
            :options="calendarOptions"
            @dateClick="handleDateClick"
            />

        </div>

        <RightSidebar
        :sidebar-open="sidebarOpen"
        @close-sidebar="sidebarOpen = false"
        />

        <ModalNewAgenda
        :is-open="newAgendaModalOpen"
        :agenda-types="agendaTypes"
        :meeting-invitees="caseParties"
        :meeting-types="meetingTypes"
        :meeting-platforms="meetingPlatforms"
        :is-loading="isLoading"
        :toast="toast"
        :current-dates-selected="currentDatesSelected"
        @closed="newAgendaModalOpen = false"
        @confirm="createNewAgenda"
        @empty-member="emptyMember('selectInvitees')"
        @empty-time="emptyMember('selectTimeFirst')"
        @invalid-time="emptyMember('selectTimeInvalid')"
        />

        <ModalRequestDocument
        :is-open="newRequestDocumentModalOpen"
        :case-parties="caseParties"
        :current-dates-selected="currentDatesSelected"
        @closed="newRequestDocumentModalOpen = false"
        @confirm="createNewRequestDocument"
        @empty-member="emptyMember('selectRequestTo')"
        />

        <ModalCalendarInfo
        :is-open="calendarDetailAgendaOpen"
        :active-agenda="activeAgenda"
        :is-request-document="activeAgenda.type === 'Document'"
        @closed="calendarDetailAgendaOpen = false"
        @reject-request="rejectRequestDocumentModalOpen = true"
        @accept-request="acceptRequestDocumentModalOpen = true"
        @approve-document="acceptDocumentModalActionOpen = true"
        @rectify-document="rectifyRequestDocumentModalOpen = true"
        @provide-meetingvideo="meetingVideoProvided()"
        />

        <ModalProvideDocument
        :is-open="acceptRequestDocumentModalOpen"
        :active-agenda="activeAgenda"
        @closed="acceptRequestDocumentModalOpen = false"
        @confirm="accepetRequestDocumentAction"
        />

        <ModalConfirmation
        :is-open="newAgendaModalActionOpen"
        :title="$t('calendar.modalConfirm.title')"
        :description="$t('calendar.modalConfirm.description')"
        @closed="newAgendaModalActionOpen = false"
        @confirmed="createAgenda"
        />

        <!-- Confirmation of approve document -->
        <ModalConfirmation
        :is-open="acceptDocumentModalActionOpen"
        :title="$t('calendar.modalInfo.approval.confirmation.title')"
        :description="$t('calendar.modalInfo.approval.confirmation.description')"
        @closed="acceptDocumentModalActionOpen = false"
        @confirmed="acceptDocumentConfirmed"
        />

        <!-- Rectification of document -->
        <ModalRejectOffer
        :id="activeAgenda.id"
        :is-open="rectifyRequestDocumentModalOpen"
        :title="$t('calendar.modalInfo.approval.rectify.title')"
        :is-loading="isLoading"
        @reject="rectifyRequestDocumentAction"
        @closed="rectifyRequestDocumentModalOpen = false"
        />

        <ModalRejectOffer
        :id="activeAgenda.id"
        :is-open="rejectRequestDocumentModalOpen"
        :title="$t('calendar.modalReject.title')"
        :is-loading="isLoading"
        @reject="rejectRequestDocumentAction"
        @closed="rejectRequestDocumentModalOpen = false"
        />

        <ModalAgendaMethod
        :is-open="chooseAgendaMethodModalOpen"
        @close="chooseAgendaMethodModalOpen = false"
        @choose-document="newRequestDocumentModalOpen = true"
        @choose-meeting-or-alert="newAgendaModalOpen = true"
        />

    </div>
</template>
<script>
/**
 * COMPONENTS
 */
import Sidebar from '@/partials/Sidebar.vue'
import RightSidebar from '@/partials/RightSidebar.vue'
import EditMenu from '@/components/DropdownEditMenu.vue'
import ModalNewAgenda from '@/components/ModalNewAgenda.vue'
import ModalRequestDocument from '@/components/modal/calendar/ModalRequestDocument.vue'
import ModalConfirmation from '@/components/ModalConfirmation.vue'
import ModalCalendarInfo from '@/components/modal/calendar/ModalCalendarInfo.vue'
import ModalRejectOffer from '@/components/ModalRejectOffer.vue'
import ModalProvideDocument from '@/components/modal/document/ModalProvideDocument.vue'
import ModalAgendaMethod from '../components/ModalAgendaMethod.vue'

/**
 * MIXINX
 */
import globalMixin from '@/mixins/global.js'

/**
 * VUE CALENDAR
 */
import '@fullcalendar/core/vdom';
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import googleCalendarPlugin from "@fullcalendar/google-calendar";
import esLocale from "@fullcalendar/core/locales/es";

/**
 * UTILS
 */
import { useToast } from 'vue-toastification'

export default {
    components: {
        Sidebar,
        RightSidebar,
        FullCalendar,
        EditMenu,
        ModalNewAgenda,
        ModalRequestDocument,
        ModalConfirmation,
        ModalCalendarInfo,
        ModalRejectOffer,
        ModalProvideDocument,
        ModalAgendaMethod
    },
    setup () {
        const toast = useToast()

        return {
            toast
        }
    },
    mixins: [globalMixin],
    data () {
        return {
            sidebarOpen: false,
            newRequestDocumentModalOpen: false,
            newAgendaModalOpen: false,
            isLoading: false,
            caseParties: [],
            newAgendaData: {},
            newAgendaModalActionOpen: false,
            calendarDetailAgendaOpen: false,
            activeAgenda: {},
            acceptRequestDocumentModalOpen: false,
            rejectRequestDocumentModalOpen: false,
            acceptDocumentModalActionOpen: false,
            rectifyRequestDocumentModalOpen: false,
            chooseAgendaMethodModalOpen: false,
            agendas: [],
            currentDatesSelected: {},
            colorsForEveryAgendaType: {
                'Alert': 'rgb(14 165 233)',
                'Meeting': 'rgb(34 197 94)',
                'Document': 'rgb(234 179 8)'
            },
            agendaTypes: [
                {
                    id: 'Meeting',
                    name: 'Meeting',
                },
                {
                    id: 'Alert',
                    name: 'Alert',
                },
            ],
            meetingPlatforms: [
                { id: 'other', name: 'Other' },
                // { id: 'redek', name: 'Redek' },
            ],
            meetingTypes: [
                {
                    id: 'video',
                    name: this.$t('videoCall'),
                },
                {
                    id: 'in-person',
                    name: this.$t('inPersonMeeting'),
                },
                {
                    id: 'mixed',
                    name: this.$t('mixed'),
                },
            ],
            currentDateRange: {
                start: (new Date()).getFullYear()
                    + '-'
                    + ((((new Date()).getMonth() + 1) < 10) ? '0' : '')
                    + ((new Date()).getMonth() + 1)
                    + '-'
                    + ((((new Date()).getDate() + 1) < 10) ? '0' : '')
                    + '01',
                end: (new Date()).getFullYear()
                    + '-'
                    + ((((new Date()).getMonth() + 2) < 10) ? '0' : '')
                    + ((new Date()).getMonth() + 2)
                    + '-'
                    + ((((new Date()).getDate() + 1) < 10) ? '0' : '')
                    + '01',
            },
            filterDataAgenda: {
                page: 1,
                take: 50,
                search: '',
                order: 'DESC',
                date: '',
            },
            metaDataAgenda: {
                page: 1,
                take: 1000,
                itemCount: 10,
                pageCount: 1,
                hasPreviousPage: false,
                hasNextPage: true,
            },
            calendarOptions: {
                selectable: true,
                editable: false,
                plugins: [
                    dayGridPlugin,
                    interactionPlugin,
                    timeGridPlugin,
                    listPlugin,
                    googleCalendarPlugin,
                ],
                events: [],
                locales: [esLocale],
                locale: 'en',
                initialView: "dayGridMonth",
                select: this.handleDateClick,
                eventClick: this.handleEventClick,
                eventAdd: this.handleDateClick,
                datesSet: this.handleDatesSet,
                weekends: true,
                headerToolbar: {
                    left: "prev, next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,listWeek",
                },
                selectConstraint: {
                    start: new Date().toISOString().split('T')[0]
                },
            },
        }
    },
    watch: {
        "$i18n.locale": function (val, old) {
            if (val) {
                this.calendarOptions.locale = val;
            }
        }
    },
    mounted () {
        if (this.$i18n && this.$i18n.locale) {
            this.calendarOptions.locale = this.$i18n.locale;
        }
        this.getAllAgenda();
        this.getCaseParties();
    },
    methods: {
        getAllAgenda() {
            this.isLoading = true
            this.$store
                .dispatch('calendar/getAllAgenda', {
                    order: this.filterDataAgenda.order,
                    page: this.filterDataAgenda.page,
                    take: this.filterDataAgenda.take,
                    q: this.filterDataAgenda.search,
                    case_id: this.$route.params.slug,
                    dateRange: this.currentDateRange.start + ',' + this.currentDateRange.end,
                })
                .then((response) => {
                    this.isLoading = false
                    const responseData = response.data
                    if (responseData.data) {
                        const agendas = responseData.data;
                        this.agendas = agendas;
                        this.calendarOptions.events = [];
                        agendas.map(agenda => {
                            let timeFrom = null;
                            let timeTo = null;
                            if (agenda.meeting_time_from && agenda.meeting_time_to && agenda.meeting_date) {
                                timeFrom = this.newDayJs(agenda.meeting_date + ' ' + agenda.meeting_time_from).format('YYYY-MM-DD HH:mm:ss');
                                timeTo = this.newDayJs(agenda.meeting_date + ' ' + agenda.meeting_time_to).format('YYYY-MM-DD HH:mm:ss');
                            } else if (agenda.due_date) {
                                timeFrom = this.newDayJs(agenda.due_date).format('YYYY-MM-DD') + ' 00:00:00';
                                timeTo = this.newDayJs(agenda.due_date).format('YYYY-MM-DD') + ' 23:59:59';
                            }
                            if (timeFrom && timeTo) {
                                this.calendarOptions.events.push(
                                    {
                                        id: agenda.id,
                                        title: this.isAParsedReferenceTranslate(agenda.title)
                                            ? this.$t(...this.getParsedReferenceTranslate(agenda.title))
                                            : agenda.title,
                                        start: timeFrom,
                                        end: timeTo,
                                        allDay: !agenda.meeting_time_from || !agenda.meeting_time_to,
                                        classNames: ["cursor-pointer"],
                                        backgroundColor: this.colorsForEveryAgendaType[agenda.type],
                                        borderColor: this.colorsForEveryAgendaType[agenda.type],
                                    },
                                );
                            }
                        })
                    }
                    if (responseData.from) {
                        this.metaDataAgenda = {
                            current_page: responseData.current_page,
                            from: responseData.from,
                            last_page: responseData.last_page,
                            links: responseData.links,
                            per_page: responseData.per_page,
                            to: responseData.to,
                            total: responseData.total,
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        getCaseParties() {
            this.$store
                .dispatch('cases/getCaseParties', {
                    case_id: this.$route.params.slug,
                })
                .then((response) => {
                    const responseData = response.data
                    this.caseParties = responseData.filter(item => !item.was_reassigned).map(item => item.user)
                })
                .catch((error) => {
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        handleDateClick (arg) {
            if (this.newDayJs(arg.startStr).format('YYYY-MM-DD') === this.nowDayJs().format('YYYY-MM-DD')) {
                this.newAgendaModalOpen = true;
            } else {
                this.chooseAgendaMethodModalOpen = true;
            }
            this.currentDatesSelected = {
                start: this.newDayJs(arg.startStr),
                end: this.newDayJs(arg.endStr)
            };
        },
        handleEventClick (arg) {
            const agenda = this.agendas.find(item => item.id == arg.event.id)
            if (agenda) {
                this.activeAgenda = agenda
                this.calendarDetailAgendaOpen = true
            }
        },
        handleDatesSet (arg) {
            const currentStart = arg.view.currentStart.getFullYear() +
                '-' +
                (
                    ((arg.view.currentStart.getMonth() + 1) < 10)
                        ? ('0' + (arg.view.currentStart.getMonth() + 1))
                        : (arg.view.currentStart.getMonth() + 1)
                ) +
                '-' +
                (
                    ((arg.view.currentStart.getDate() + 1) < 10)
                        ? ('0' + arg.view.currentStart.getDate())
                        : arg.view.currentStart.getDate()
                );

            const currentEnd = arg.view.currentEnd.getFullYear() +
                '-' +
                (
                    ((arg.view.currentEnd.getMonth() + 1) < 10)
                        ? ('0' + (arg.view.currentEnd.getMonth() + 1))
                        : (arg.view.currentEnd.getMonth() + 1)
                ) +
                '-' +
                (
                    ((arg.view.currentEnd.getDate() + 1) < 10)
                        ? ('0' + arg.view.currentEnd.getDate())
                        : arg.view.currentEnd.getDate()
                );

            if (currentStart != this.currentDateRange.start && currentEnd != this.currentDateRange.end) {
                this.currentDateRange = {
                    start: currentStart,
                    end: currentEnd
                };
                this.getAllAgenda();
            }
        },
        createNewAgenda(event) {
            const newAgendaData = {}
            newAgendaData.title = event.title
            newAgendaData.party_ids = event.party_ids
            newAgendaData.type = event.type
            newAgendaData.description = event.description
            newAgendaData.detail = event.detail
            newAgendaData.meeting_date = event.meeting_date
            newAgendaData.meeting_url = event.meeting_url
            newAgendaData.meeting_in_person_address = event.meeting_in_person_address
            newAgendaData.meeting_type = event.meeting_type
            newAgendaData.due_date = event.due_date
            newAgendaData.meeting_platform = event.meeting_platform
            newAgendaData.meeting_time_from = event.meeting_time_from
            newAgendaData.meeting_time_to = event.meeting_time_to
            newAgendaData.case_id = this.$methods.decrypt(this.$route.params.slug)

            this.newAgendaData = newAgendaData
            this.newAgendaModalActionOpen = true
        },
        createNewRequestDocument(event) {
            const newAgendaData = {}
            newAgendaData.title = event.title
            newAgendaData.party_ids = event.party_ids
            newAgendaData.type = 'Document'
            newAgendaData.description = event.description
            newAgendaData.due_date = event.due_date
            newAgendaData.meeting_date = event.due_date
            newAgendaData.case_id = this.$methods.decrypt(this.$route.params.slug)
            newAgendaData.parties_id_should_accept = event.parties_id_should_accept
            newAgendaData.require_acceptance_everyone = event.require_acceptance_everyone

            this.newAgendaData = newAgendaData
            this.newAgendaModalActionOpen = true
        },
        createAgenda() {
            if (this.isLoading) return
            this.isLoading = true

            this.$store
                .dispatch('calendar/createAgenda', this.newAgendaData)
                .then((response) => {
                    this.isLoading = false
                    if (this.newAgendaData.type === 'Document') {
                        this.toast.success(this.$t('calendar.modalRequestDocument.successfully'))
                    } else {
                        this.toast.success(this.$t('calendar.modalNewAgenda.successfully'))
                    }
                    this.newAgendaModalOpen = false
                    this.newRequestDocumentModalOpen = false
                    this.newAgendaModalActionOpen = false
                    this.getAllAgenda()
                })
                .catch((error) => {
                    this.isLoading = false
                    this.newAgendaModalActionOpen = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        meetingVideoProvided () {
            this.getAllAgenda()
            this.calendarDetailAgendaOpen = false
        },
        acceptDocumentConfirmed () {
            if (this.isLoading) return
            if (
                this.activeAgenda
                &&
                this.activeAgenda.id
                &&
                this.activeAgenda.request
                &&
                this.activeAgenda.request.last_document_provided
            ) {
                const formDataApproval = {
                    accept: true,
                    reason: '',
                    agenda_id: this.activeAgenda.id,
                    request_id: this.activeAgenda.request.id,
                    document_id: this.activeAgenda.request.last_document_provided.id,
                    case_id: this.$methods.decrypt(this.$route.params.slug),
                };
                this.isLoading = true
                this.$store.dispatch('calendar/approveOrRequestRectificationDocument', formDataApproval)
                .then(response => {
                    // this.store.dispatch('rightsidebar/reload', { prop: 'reloadDeadlines' });
                    // this.store.dispatch('rightsidebar/reload', { prop: 'reloadUserActions' });
                    this.isLoading = false
                    this.toast.success(this.$t('calendar.modalInfo.approval.successfully.approve'))
                    this.calendarDetailAgendaOpen = false
                    this.acceptDocumentModalActionOpen = false
                    this.getAllAgenda()
                })
            }
        },
        rectifyRequestDocumentAction (event) {
            if (this.isLoading) return
            const formDataApproval = {
                accept: false,
                reason: event.reason,
                agenda_id: this.activeAgenda.id,
                request_id: this.activeAgenda.request.id,
                document_id: this.activeAgenda.request.last_document_provided.id,
                case_id: this.$methods.decrypt(this.$route.params.slug),
            };
            this.isLoading = true
            this.store.dispatch('calendar/approveOrRequestRectificationDocument', formDataApproval)
            .then((response) => {
                // this.store.dispatch('rightsidebar/reload', { prop: 'reloadDeadlines' });
                // this.store.dispatch('rightsidebar/reload', { prop: 'reloadUserActions' });
                this.isLoading = false
                this.toast.success(this.$t('calendar.modalInfo.approval.successfully.approve'))
                this.calendarDetailAgendaOpen = false
                this.rectifyRequestDocumentModalOpen = false
                this.getAllAgenda()
            })
            .catch((error) => {
                this.isLoading = false

                this.calendarDetailAgendaOpen = false
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        rejectRequestDocumentAction(event) {
            this.acceptRejectRequestData.id = event.id
            this.acceptRejectRequestData.reason = event.reason
            this.acceptRejectRequestData.accept = false
            this.acceptOrRejectRequest()
        },
        accepetRequestDocumentAction(event) {
            this.acceptRejectRequestData.id = event.id
            this.acceptRejectRequestData.file_name = event.file_name
            this.acceptRejectRequestData.attachment = event.attachment
            this.acceptRejectRequestData.accept = true
            this.acceptOrRejectRequest()
        },
        acceptOrRejectRequest() {
            if (this.isLoading) return
            this.acceptRejectRequestData.case_id = this.$methods.decrypt(this.$route.params.slug)
            this.isLoading = true
            this.store
                .dispatch(
                    'calendar/acceptOrRejectRequest',
                    this.acceptRejectRequestData
                )
                .then((response) => {
                    // this.store.dispatch('rightsidebar/reload', { prop: 'reloadDeadlines' });
                    // this.store.dispatch('rightsidebar/reload', { prop: 'reloadUserActions' });

                    this.isLoading = false
                    this.toast.success(this.$t('calendar.modalInfo.acceptOrReject'))
                    this.calendarDetailAgendaOpen = false
                    this.acceptRequestDocumentModalOpen = false
                    this.rejectRequestDocumentModalOpen = false
                    this.getAllAgenda()
                })
                .catch((error) => {
                    this.isLoading = false

                    this.calendarDetailAgendaOpen = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
    }
}

</script>
<style>

.canceled {
    text-decoration: line-through !important;
}

.fc {
    background-color: rgb(255 255 255);
    padding: 2rem 1rem;
    border-radius: .5rem;
}

.fc-toolbar-chunk .fc-button:not(.fc-dayGridMonth-button):not(.fc-timeGridWeek-button):not(.fc-listWeek-button) {
    background-color: rgb(14 165 233);
    border-color: rgb(14 165 233);
    font-size: 15px !important;
}

.fc-toolbar-title {
    font-size: 19px !important;
}

.fc-dayGridMonth-button,
.fc-timeGridWeek-button,
.fc-listWeek-button {
    font-size: 15px !important;
    background-color: rgb(226 232 240) !important;
    border-color: rgb(226 232 240) !important;
    color: rgb(71 85 105) !important;
}

.fc-dayGridMonth-button.fc-button-active,
.fc-timeGridWeek-button.fc-button-active,
.fc-listWeek-button.fc-button-active {
    background-color: #1a252f !important;
    border-color: #1a252f !important;
    color: rgb(255 255 255) !important;
}

.fc-dayGridMonth-button:hover:not(.fc-button-active),
.fc-timeGridWeek-button:hover:not(.fc-button-active),
.fc-listWeek-button:hover:not(.fc-button-active) {
    background-color: rgb(226 232 240) !important;
    border-color: rgb(226 232 240) !important;
    color: black !important;
    z-index: 0 !important;
}

.fc-today-button {
    background: #50cd89 !important;
    color: #fff !important;
    border-color: #50cd89 !important;
}

.fc-scroller-harness .fc-scroller::-webkit-scrollbar-track
{
	background-color: rgb(255 255 255);
}

.fc-scroller-harness .fc-scroller::-webkit-scrollbar
{
	width: 8px;
	background-color: white;
}

.fc-scroller-harness .fc-scroller::-webkit-scrollbar-thumb
{
	background-color: rgb(226 232 240);
}

</style>