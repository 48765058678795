import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
    profile({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${routePathAPI()}/user/profile`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateProfile({ state, commit }, formData) {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        }
        return new Promise((resolve, reject) => {
            axios
                .post(`${routePathAPI()}profile/`, formData, config)
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200 && responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAllRoles({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            axios.get(`${routePathAPI()}/roles`, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
                params: {
                    per_page: 300,
                }
            }).then(async (response) => {
                const responseData = response.data;
                if (response.status === 200 && responseData.error === undefined) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: responseData,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: responseData,
                    })
                }
            })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAllUsers({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            let params = {}
            if (payload.page) params.page = payload.page
            if (payload.take) params.per_page = payload.take
            if (payload.order) params.order = 'created_at.' + payload.order
            // if (payload.order_role) params.order_role = payload.order_role
            // if (payload.order_email) params.order_email = payload.order_email
            // if (payload.order_name) params.order_name = payload.order_name
            // if (payload.role_ids) params.role_ids = payload.role_ids
            if (payload.q) params.search = payload.q
            if (payload.stats) params.stats = true
            if (payload.year || payload.year == null) params.year = payload.year
            if (payload.semester || payload.semester == null) params.semester = payload.semester

            axios
                .get(`${routePathAPI()}/users`, {
                    params: params,
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: response.data,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: response.data,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getStatsCaseAreasByUser({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            let params = {}
            if (payload.year) params.year = payload.year
            if (payload.semester) params.semester = payload.semester

            axios
                .get(`${routePathAPI()}/users/getStatsCaseAreasByUser/${ payload.user_id }`, {
                    params: params,
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: response.data,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: response.data,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAllIdentificationTypes({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            let params = {}
            params.page = 1
            params.per_page = 50

            axios
                .get(`${routePathAPI()}/identificationtypes`, {
                    params: params,
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: response.data,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: response.data,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getClaimants({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            axios
            .get(`${routePathAPI()}/users/claimants`, {
                headers: {
                'x-language-code': currentLanguageCode,
                },
            })
            .then(async (response) => {
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    createUser({ state, commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {
                identification: payload.identification,
                first_name: payload.first_name ?? '',
                last_name: payload.last_name ?? '',
                username: payload.username ?? '',
                bank_id: payload.bank_id ?? '',
                email: payload.email.toLowerCase() ?? '',
                phone: payload.phone ?? '',
                password: payload.password ?? '',
                role_id: payload.role_id ?? ''
            }

            axios
                .post(`${routePathAPI()}/users`, params, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: response.data,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: response.data,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    deleteUser({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            axios.delete(`${routePathAPI()}/users/${payload.user_id}`, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            }).then((response) => {
                const responseData = response.data
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: responseData,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: responseData,
                    })
                }
            })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateUser({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {
                identification_type_id: payload.identification_type_id,
                identification: payload.identification,
                first_name: payload.first_name ?? '',
                last_name: payload.last_name ?? '',
                username: payload.username ?? '',
                email: payload.email.toLowerCase() ?? '',
                phone: payload.phone ?? '+62888328328322',
                role_id: payload.role_id ?? '',
            }

            if (payload.password != '********') params.password = payload.password

            axios.put(`${routePathAPI()}/users/${payload.user_id}`, params, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            }).then((response) => {
                const responseData = response.data
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: responseData,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: responseData,
                    })
                }
            })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}

// mutations
const mutations = {
    UPDATE_USER_INFO(state, payload) {
        // Get Data localStorage
        const userInfo =
            JSON.parse(localStorage.getItem('userInfo')) || state.AppActiveUser

        for (const property of Object.keys(payload)) {
            if (payload[property] !== null) {
                // If some of user property is null - user default property defined in state.AppActiveUser
                state.AppActiveUser[property] = payload[property]

                // Update key in localStorage
                userInfo[property] = payload[property]
            }
        }
        // Store data in localStorage
        localStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    SET_ACTIVE_USER(state, payload) {
        state.activeUserId = payload
    },
    SET_NEED_VERIFY_STATUS_EDIT(state, status) {
        state.needVerifyStatusEdit = status
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
