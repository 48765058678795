<template>
    <div class="-m-1.5 flex flex-wrap items-center">
        <div class="m-1.5">
            <ModalBasic
            id="another-id"
            :modal-open="isOpen"
            :show-border-header="false"
            :title="getTitleModal"
            @close-modal="close"
            >
                <Form
                ref="form"
                v-slot="{ errors }"
                :validation-schema="getFormSchema"
                @submit="submitForm"
                >
                    <div class="px-5">
                        <div class="space-y-3">
                            <div>
                                <label class="mb-1 block text-xs font-bold" for="name">
                                    <span>
                                        {{ (isFolder) ? $t('document.modalCreateFile.folderName') : $t('document.modalCreateFile.fileName') }}
                                    </span>
                                </label>
                                <Field v-slot="{ field }" v-model="name" name="name">
                                    <input
                                    @input="deleteNotAllowedCharsInName"
                                    id="name"
                                    v-bind="field"
                                    class="form-input w-full p-2"
                                    :class="{ 'bg-slate-200': !isModifiable }"
                                    type="text"
                                    required
                                    :disabled="!isModifiable"
                                    :placeholder="$t('document.modalCreateFile.fileNamePlaceholder')" />
                                </Field>
                                <div v-if="errors.name !== undefined" class="flex w-full">
                                    <span class="text-sm text-red-500">
                                        {{ $t(errors.name) }}
                                    </span>
                                </div>
                            </div>
                            <div v-if="isInCaseCallRecordingsFolder && !isEdit">
                                <label class="mb-1 block text-xs font-bold" for="shareWith">
                                    {{ $t('general.meeting') }}
                                </label>
                                <VueMultiselect
                                v-model="calendarAgendaSelected"
                                :options="getCalendarAgendas"
                                :multiple="false"
                                :close-on-select="true"
                                :placeholder="$t('validation.selectMeeting')"
                                label="meeting_date"
                                track-by="meeting_date"
                                >
                                    <template #singleLabel="props">
                                        <span class="option__desc text-sm">
                                            <span class="option__title">
                                                {{ props.option.title + ' - ' + props.option.meeting_date }}
                                            </span>
                                        </span>
                                    </template>
                                    <template #option="props">
                                        <div class="option__desc flex flex-col">
                                            <span class="option__title">
                                                {{ props.option.title }}
                                            </span>
                                            <div class="option__small inline rounded-lg py-1 text-xs font-bold">
                                                {{ props.option.meeting_date }}
                                            </div>
                                        </div>
                                    </template>
                                </VueMultiselect>
                            </div>
                            <div v-if="!isInCaseCallRecordingsFolder || canEditShares">
                                <label class="mb-1 block text-xs font-bold" for="shareWith">
                                    {{ $t('document.modalCreateFile.shareWith') }}
                                </label>
                                <VueMultiselect
                                v-model="partiesId"
                                :options="getPartiesWithoutMe"
                                :multiple="true"
                                :close-on-select="true"
                                :placeholder="$t('validation.selectShareWith')"
                                label="name"
                                track-by="id"
                                >
                                    <template #singleLabel="props">
                                        <span class="option__desc">
                                            <span class="option__title">
                                                {{ props.option.name }}
                                            </span>
                                        </span>
                                    </template>
                                    <template #option="props">
                                        <div class="option__desc space-x-2">
                                            <span class="option__title">
                                                {{ props.option.name }}
                                            </span>
                                            <div class="option__small inline rounded-lg px-2 py-1 text-xs font-bold">
                                                {{ props.option.role.name }}
                                            </div>
                                        </div>
                                    </template>
                                </VueMultiselect>
                            </div>
                            <div v-if="!isFolder && !isEdit">
                                <label class="mb-1 block text-xs font-bold" for="files">
                                    {{ $t('forms.uploadFile') }}
                                </label>
                                <div class="flex w-full items-center justify-center">
                                    <Field v-model="attachment" name="attachment">
                                        <label for="files"
                                            class="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-gray-300 bg-gray-50 hover:bg-gray-100">
                                            <div class="flex flex-col items-center justify-center py-2">
                                                <svg aria-hidden="true" class="mb-3 h-10 w-10 text-gray-400" fill="none"
                                                    stroke="currentColor" viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12">
                                                    </path>
                                                </svg>
                                                <p class="text-sm text-gray-500">
                                                    {{ $t('forms.dragAndDrop') }}
                                                </p>
                                                <p class="text-sm text-gray-500">
                                                    {{ $t('forms.or') }}
                                                </p>
                                                <p class="text-sm font-semibold text-sky-500">
                                                    {{ $t('forms.browseFiles') }}
                                                </p>
                                            </div>
                                            <input
                                            :accept="(isInCaseCallRecordingsFolder) ? '.mp4, .avi, .mkv, .mov, .wmv, .flv, .mpg, .mpeg, .webm, .3gp, .ogg' : null"
                                            id="files"
                                            type="file"
                                            class="hidden"
                                            @change="fileInput"
                                            />
                                            <span v-if="attachment">{{ attachment.name }}</span>
                                        </label>
                                    </Field>
                                </div>
                                <div v-if="errors.attachment !== undefined" class="flex w-full pb-4">
                                    <span class="pl-3 text-sm text-red-500">
                                        {{ $t(errors.attachment) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="py-4">
                        <div class="flex flex-wrap justify-center space-x-2 px-4">
                            <button
                            v-if="!isEdit"
                            type="submit"
                            class="btn-sm w-full whitespace-nowrap rounded-lg bg-sky-500 p-3 text-white hover:bg-sky-600"
                            :class="[isLoading ? 'cursor-not-allowed' : 'cursor-pointer']">
                                <template v-if="isLoading">
                                    <span>
                                        {{ $t('general.loading') }}
                                    </span>
                                </template>
                                <template v-else>
                                    <span v-if="isFolder">
                                        {{ $t('document.modalCreateFile.createFolder') }}
                                    </span>
                                    <span v-else>
                                        {{ $t('document.modalCreateFile.uploadFile') }}
                                    </span>
                                </template>
                            </button>
                            <button
                            v-else
                            type="submit"
                            class="btn-sm w-full whitespace-nowrap rounded-lg bg-sky-500 p-3 text-white hover:bg-sky-600"
                            :class="[isLoading ? 'cursor-not-allowed' : 'cursor-pointer']">
                                <template v-if="isLoading">
                                    <span>
                                        {{ $t('general.loading') }}
                                    </span>
                                </template>
                                <template v-else>
                                    <span v-if="isFolder">
                                        {{ $t('document.modalCreateFile.updateFolder') }}
                                    </span>
                                    <span v-else>
                                        {{ $t('document.modalCreateFile.updateFile') }}
                                    </span>
                                </template>
                            </button>
                        </div>
                    </div>
                </Form>
            </ModalBasic>
        </div>
    </div>
</template>
<script>
import ModalBasic from '@/components/ModalBasic.vue'
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'
import { useToast } from 'vue-toastification'

export default {
    name: 'ModalCreateFileComponent',
    components: {
        ModalBasic,
        Form,
        Field,
    },
    setup() {
        const toast = useToast()
        return {
            toast,
        }
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        isDocumentLoading: {
            type: Boolean,
            default: false,
        },
        isFolder: {
            type: Boolean,
            default: false,
        },
        currentDocument: {
            type: Object,
            default: () => {
                return {
                    name: '',
                    folder_id: '',
                    type: '',
                }
            },
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
        shareWith: {
            type: Array,
            default: () => {
                return []
            },
        },
        me: {
            type: Object,
            default: () => {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        folderId: {
            type: Number,
            default: 0,
        },
        calendarAgendas: {
            type: Array,
            default: () => {
                return []
            },
        },
        activeFolder: {
            type: Object,
            default: {},
        },
    },
    emits: [
        'closed',
        'submit-data',
    ],
    data: function () {
        return {
            name: '',
            type: 'File',
            partiesId: [],
            attachment: null,
            documentId: null,
            isReadOnly: false,
            calendarAgendaSelected: null,
            onlyOwnerCanEdit: false,
            ownerId: null,

            fileTypes: [],
            filterDataFileTypes: {
                page: 1,
                take: 50,
                search: '',
                order: 'DESC',
            },
            metaDataFileTypes: {
                page: 1,
                take: 10,
                itemCount: 10,
                pageCount: 1,
                hasPreviousPage: false,
                hasNextPage: true,
            },
            updateDocumentSchema: Yup.object().shape({
                name: Yup.string()
                .typeError('validation.enterFileName')
                .required('validation.enterFileName')
                .test(
                    'unique-folder',
                    'validation.documents.common.nameAlreadyInUse',
                    this.checkNameExistence
                ),
            }),
            newDocumentSchema: Yup.object().shape({
                name: Yup.string()
                .typeError('validation.enterFileName')
                .required('validation.enterFileName')
                .test(
                    'unique-folder',
                    'validation.documents.common.nameAlreadyInUse',
                    this.checkNameExistence
                ),
                attachment: Yup.mixed().required('validation.attachmentRequired'),
            }),
            newFolderSchema: Yup.object().shape({
                name: Yup.string()
                .typeError('validation.enterFolderName')
                .required('validation.enterFolderName')
                .test(
                    'unique-folder',
                    'validation.documents.common.nameAlreadyInUse',
                    this.checkNameExistence
                ),
            }),
        }
    },
    watch: {
        currentDocument (val) {
            this.documentId = val.id ?? null
            this.name = val.name ?? null
            this.type = this.isFolder ? 'Folder' : 'File'
            this.isReadOnly = val.is_read_only ?? false
            this.partiesId = val && val.shares ? val.shares.map(item => item.user) : []
            this.onlyOwnerCanEdit = val.only_owner_can_edit ?? false
            this.ownerId = val.owner_id ?? null
        },
        isOpen(val) {
            if (!val) this.close()
            this.calendarAgendaSelected = null
        },
        isFolder (val) {
            this.type = val ? 'Folder' : 'File'
        },
    },
    methods: {
        close() {
            this.$refs.form.resetForm()
            this.partiesId = [];
            this.calendarAgendaSelected = null
            this.$emit('closed')
        },
        submitForm() {
            if (this.isInCaseCallRecordingsFolder && !this.calendarAgendaSelected && !this.isEdit) {
                this.toast.error(this.$t('validation.chooseMeeting'));
                return
            }
            const formData = {
                documentId: this.documentId,
                name: this.name,
                folderId: this.folderId,
                type: this.type,
                attachment: this.attachment,
                partiesId: this.partiesId.length ? this.partiesId.map(item => item.id) : null,
                calendarAgendaId: this.calendarAgendaSelected ? this.calendarAgendaSelected.id : null
            }
            this.$emit('submit-data', formData)
        },
        fileInput(event) {
            this.attachment = event.target.files[0]
        },
        async checkNameExistence (name) {
            let attr = name + (
                (this.attachment)
                    ? this.attachment.name.substring(this.attachment.name.lastIndexOf('.'))
                    : ''
            )
            let validation = true
            if (attr) {
                validation = await this.$store.dispatch(
                    'document/checkNameExistence',
                    {
                        name: this.$methods.encrypt(attr),
                        folderId: this.$methods.encrypt(this.folderId),
                        current:
                            this.currentDocument && this.currentDocument.id
                                ? this.$methods.encrypt(this.currentDocument.id)
                                : null,
                    }
                )
            }
            return validation
        },
        deleteNotAllowedCharsInName (event) {
            event.target.value = event.target.value.replace(/[\\/:*?"<>|]/g, '')
        }
    },
    computed: {
        getFormSchema () {
            if (this.isFolder) {
                return this.newFolderSchema;
            } else if (!this.isFolder && !this.isEdit) {
                return this.newDocumentSchema;
            } else if (!this.isFolder && this.isEdit) {
                return this.updateDocumentSchema;
            }
        },
        getTitleModal () {
            const conditions = [
                {condition: this.isFolder && !this.isEdit, text: this.$t('document.modalCreateFile.titleFolder')},
                {condition: this.isFolder && this.isEdit, text: this.$t('document.modalEditFile.titleFolder')},
                {condition: !this.isFolder && !this.isEdit, text: this.$t('document.modalCreateFile.title')},
                {condition: !this.isFolder && this.isEdit, text: this.$t('document.modalEditFile.title')},
            ];
            return conditions.find(item => item.condition)?.text ?? null;
        },
        getPartiesWithoutMe () {
            return this.shareWith.filter((item) => this.me && item.user.id !== this.me.id).map(item => item.user)
        },
        isModifiable () {
            return !this.isReadOnly;
        },
        isInCaseCallRecordingsFolder () {
            return this.activeFolder.identity === 'case-.case_call_recordings'
        },
        getCalendarAgendas () {
            return this.calendarAgendas.filter(item => item.meeting_document_id == null).map(item => {
                item.meeting_date = this.$filters.formattedDate(item.meeting_date, 'DD MMMM YYYY')
                    + ' (' + this.$filters.formattedDate(item.meeting_time_from, 'HH:mm') + ' - '
                    + this.$filters.formattedDate(item.meeting_time_to, 'HH:mm') + ')';
                return item;
            })
        },
        canEditShares () {
            return this.isEdit && (this.me && this.onlyOwnerCanEdit && this.me.id == this.ownerId || !this.onlyOwnerCanEdit)
        },
    }
}
</script>
