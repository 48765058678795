<template>
  <div class="flex flex-row" :class="wrapClass">
    <div v-if="prefixText !== ''" class="mr-1">
      <span :class="textPrefixClass"> {{ prefixText }}</span>
    </div>

    <p class="inline" :class="textClass">
      <span v-html="formatPrice(totalInString, withPrefix)"></span>
    </p>
    <div v-if="leadingText !== ''" class="ml-1">
      <span :class="textLeadingClass"> {{ leadingText }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CurrencyComponent',
  props: {
    totalInString: {
      type: [String, Number],
      default: '0',
      required: true,
    },
    withPrefix: {
      type: Boolean,
      default: true,
    },
    showCopy: {
      type: Boolean,
      default: true,
    },
    wrapClass: {
      type: String,
      default: '',
      required: false,
    },
    textClass: {
      type: String,
      default: '',
      required: false,
    },
    leadingText: {
      type: String,
      default: '',
      required: false,
    },
    textLeadingClass: {
      type: String,
      default: '',
      required: false,
    },
    prefixText: {
      type: String,
      default: '',
      required: false,
    },
    textPrefixClass: {
      type: String,
      default: '',
      required: false,
    },
    copyText: {
      type: String,
      default: '',
      required: false,
    },
    withColorDigits: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  methods: {
    formatPrice(value, withPrefix) {
      const values = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      let html = ''
      if (withPrefix) {
        html += `<span>Rp</span>`
      }

      if (!this.withColorDigits) {
        html += `<span>${values}</span>`
        return html
      } else {
        const nums = values.split('')
        let arrLen = nums.length
        let arrIndex = [arrLen - 1, arrLen - 2, arrLen - 3]
        nums.forEach(function (n, index) {
          if (arrIndex.includes(index)) {
            html += `<span class=text-darkorange>${n}</span>`
          } else {
            html += `<span>${n}</span>`
          }
        })
        return html
      }
    },
  },
}
</script>
