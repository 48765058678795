<template>
    <div class="flex h-screen overflow-hidden">
        <!-- Sidebar -->
        <Sidebar
        :sidebar-open="sidebarOpen"
        :case-stage="activeCase.stage !== undefined ? activeCase.stage : $route.query.status"
        @close-sidebar="sidebarOpen = false"
        />

        <!-- Content area -->
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <main>
                <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
                    <div class="flex w-full flex-col pb-4">
                        <h1 class="text-2xl font-bold text-black">
                            {{ $t('finalDecision.title') }}
                        </h1>
                        <p>{{ $t('finalDecision.description') }}</p>
                    </div>
                    <template v-if="!isLoading">
                        <!-- Case Details -->
                        <div class="rounded-lg bg-white px-6 py-2 shadow-sm">

                            <div class="grow">
                                <div class="items-start justify-between py-4 sm:flex">
                                    <div
                                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                        <p>{{ $t('caseDetail.details.caseName') }}</p>
                                    </div>
                                    <div class="mb-2 max-w-[50%] text-slate-800 sm:mb-0">
                                        <p>{{ activeCase.name }}</p>
                                    </div>
                                </div>
                                <div class="items-start justify-between border-t py-4 sm:flex">
                                    <div
                                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                        <p>{{ $t('caseDetail.details.caseRequest') }}</p>
                                    </div>
                                    <div class="mb-2 max-w-[50%] text-slate-800 sm:mb-0">
                                        <p>{{ activeCase.request }}</p>
                                    </div>
                                </div>
                                <div class="items-start justify-between border-t py-4 sm:flex">
                                    <div
                                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                        <p>{{ $t('caseDetail.details.caseAmount') }}</p>
                                    </div>
                                    <div
                                    v-if="activeCase.amount && activeCase.currency"
                                    class="mb-2 max-w-[50%] text-slate-800 sm:mb-0"
                                    >
                                        <p>{{ activeCase.amount }}{{ activeCase.currency.code }}</p>
                                    </div>
                                </div>
                                <div class="items-start justify-between border-t py-4 sm:flex">
                                    <div
                                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                        <p>{{ $t('caseDetail.details.submissionDate') }}</p>
                                    </div>
                                    <div class="mb-2 max-w-[50%] text-slate-800 sm:mb-0">
                                        <p>
                                            {{ formatDateDay(activeCase.created_at) }}
                                        </p>
                                    </div>
                                </div>
                                <div class="items-start justify-between border-t py-4 sm:flex">
                                    <div
                                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                        <p>{{ $t('caseDetail.details.submittedBy') }}</p>
                                    </div>
                                    <div class="mb-2 max-w-[50%] text-slate-800 sm:mb-0">
                                        <p v-if="activeCase.user_created">
                                            {{ activeCase.user_created.name }}
                                        </p>
                                        <p v-else>-</p>
                                    </div>
                                </div>
                                <div class="items-start justify-between border-t py-4 sm:flex">
                                    <div
                                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                        <p>{{ $t('finalDecision.resolveDate') }}</p>
                                    </div>
                                    <div class="mb-2 max-w-[50%] text-slate-800 sm:mb-0">
                                        <p v-if="activeCase.case_settled">
                                            {{ formatDateDay(activeCase.case_settled.created_at) }}
                                        </p>
                                        <p v-else>-</p>
                                    </div>
                                </div>
                                <div class="items-start justify-between border-t py-4 sm:flex">
                                    <div
                                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                        <p>{{ $t('finalDecision.partyWhoResolved') }}</p>
                                    </div>
                                    <div class="mb-2 max-w-[50%] text-slate-800 sm:mb-0 capitalize">
                                        <p v-if="activeCase.case_settled">
                                            {{ activeCase.case_settled.type }}
                                        </p>
                                        <p v-else>-</p>
                                    </div>
                                </div>
                                <div class="items-start justify-between border-t py-4 sm:flex">
                                    <div
                                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                        <p>{{ $t('finalDecision.disputeResult') }}</p>
                                    </div>
                                    <div class="mb-2 max-w-[50%] text-green-500 sm:mb-0">
                                        <p v-if="activeCase.case_settled && getSettledResult">
                                            {{ getSettledResult }}
                                        </p>
                                        <p v-else>-</p>
                                    </div>
                                </div>

                                <div class="items-start justify-between gap-4 border-t py-4 sm:flex">
                                    <div
                                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                        <p>{{ $t('finalDecision.notes') }}</p>
                                    </div>
                                    <div class="mb-2 sm:mb-0 max-w-[50%]">
                                        <p v-if="activeCase.case_settled && activeCase.case_settled.notes">
                                            {{ activeCase.case_settled.notes }}
                                        </p>
                                        <p v-else>-</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- End Case Details -->

                        <!-- Final Decision -->
                        <div v-if="activeCase.case_settled" class="mt-4 rounded-lg bg-white px-6 pt-2 shadow-sm">
                            <div
                            v-if="
                                activeCase.case_settled
                                &&
                                activeCase.case_settled.party_solved
                                &&
                                activeCase.case_settled.party_solved.user
                            "
                            >
                                <div class="flex flex-row py-4">
                                    <ImageLoader
                                    wrapper-class="mr-3 shrink-0 rounded-full"
                                    :src="activeCase.case_settled.party_solved.user.avatar"
                                    width="60"
                                    height="60"
                                    :alternate="getSettledResult" />
                                    <div class="pl-4">
                                        <p class="text-lg font-semibold text-slate-800">
                                            {{ activeCase.case_settled.party_solved.user.name }}
                                        </p>
                                        <p class="text-sm text-slate-500">
                                            {{ activeCase.case_settled.party_solved.user.email }}
                                        </p>
                                    </div>
                                </div>
                                <div class="flex items-start">
                                    <div class="grow">
                                        <div class="items-start justify-between py-4 sm:flex">
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                                <p>{{ $t('caseDetail.parties.role') }}</p>
                                            </div>
                                            <div class="mb-2 text-lg font-medium text-slate-800 sm:mb-0">
                                                <p>{{ activeCase.case_settled.party_solved.user.role.name }}</p>
                                            </div>
                                        </div>
                                        <div class="items-start justify-between border-t py-4 sm:flex">
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                                <p>{{ $t('caseDetail.parties.phoneNumber') }}</p>
                                            </div>
                                            <div class="mb-2 text-lg font-medium text-slate-800 sm:mb-0">
                                                <p>
                                                    {{ activeCase.case_settled.party_solved.user.phone ?? '-' }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="items-start justify-between border-t py-4 sm:flex">
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0">
                                                <p>{{ $t('caseDetail.parties.email') }}</p>
                                            </div>
                                            <div class="mb-2 text-lg font-medium text-slate-800 sm:mb-0">
                                                <p>
                                                    {{ activeCase.case_settled.party_solved.user.email ?? '-' }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex items-start">
                                <div class="grow">
                                    <!-- https://sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4 -->
                                    <div v-if="activeCase.case_settled.video" class="flex w-full py-4">
                                        <video-player fluid="true" :src="activeCase.case_settled.video" controls :loop="true"
                                            :volume="0.6" />
                                    </div>
                                    <div
                                    v-if="!activeCase.case_settled.chat_discussion_id"
                                    class="items-start justify-between border-t py-4 sm:flex">
                                        <div class="mb-2 items-center text-left sm:mb-0">
                                            <FileItem
                                            v-if="activeCase.case_settled.attachment"
                                            :file="{
                                                name: getSettledResult,
                                                files: [{ file: activeCase.case_settled.attachment }],
                                            }" wrapper-class="min-w-72 p-4" />
                                            <div class="flex flex-col">
                                                <span class="text-md font-bold">
                                                    {{ getSettledResult }}
                                                </span>
                                                <span class="text-sm text-slate-500">
                                                    {{ activeCase.case_settled.notes }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-6 flex flex-col gap-2 border-t pt-4">
                                        <div
                                        v-if="activeCase.case_settled.document && activeCase.case_settled.document.files.length"
                                        class="flex justify-between items-center">
                                            <span class="text-sky-500">
                                                {{ $t('finalDecision.document.title') }}
                                            </span>
                                            <div
                                            class="flex flex-col items-start pb-4">
                                                <div class="grow">
                                                    <div class="items-center space-y-4 text-left text-base sm:mb-0">
                                                        <a
                                                        class="bg-yellow-200 py-2 flex items-center gap-2 px-4 rounded-md"
                                                        :href="activeCase.case_settled.document.files[0].file"
                                                        target="_blank"
                                                        >
                                                            <FileTypeIcon
                                                            file-type="pdf"
                                                            icon-class="inline h-8 cursor-pointer"
                                                            />
                                                            {{ $t('finalDecision.document.view') }}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                        v-if="activeCase.case_settled.offer && activeCase.case_settled.chat_discussion_id"
                                        class="flex flex-col gap-3">
                                            <div class="grid grid-cols-2">
                                                <div>
                                                    <h4 class="font-bold">
                                                        {{ $t('finalDecision.solutionByOffer.offer.title') }}
                                                    </h4>
                                                    <span class="text-sm text-slate-500">
                                                        {{ activeCase.case_settled.offer.title }}
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 class="font-bold">
                                                        {{ $t('finalDecision.solutionByOffer.offer.content') }}
                                                    </h4>
                                                    <span class="text-sm text-slate-500">
                                                        {{ activeCase.case_settled.offer.content }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="grid grid-cols-2">
                                                <div>
                                                    <h4 class="font-bold">
                                                        {{ $t('finalDecision.solutionByOffer.offer.request') }}
                                                    </h4>
                                                    <span class="text-sm text-slate-500">
                                                        {{ activeCase.case_settled.offer.request }}
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 class="font-bold">
                                                        {{ $t('finalDecision.solutionByOffer.offer.amount') }}
                                                    </h4>
                                                    <span
                                                    v-if="activeCase.case_settled && activeCase.case_settled.offer && activeCase.case_settled.offer.currency"
                                                    class="text-sm text-slate-500">
                                                        {{ activeCase.case_settled.offer.currency.code }} {{ activeCase.case_settled.offer.amount }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End of Final Decision -->

                        <!-- Parties -->
                        <div class="pt-10">
                            <h1 class="text-lg font-bold">
                                {{ $t('caseDetail.parties.title') }}
                            </h1>
                        </div>
                        <div
                            v-for="party in getPartiesCaseSorted"
                            :key="party.user.id"
                            class="mt-4 rounded-lg bg-white px-6 shadow-sm"
                        >
                            <div class="py-6">
                                <div class="flex flex-row py-4">
                                    <ImageLoader
                                        wrapper-class="mr-3 shrink-0 rounded-full"
                                        :src="party.user.avatar"
                                        width="60"
                                        height="60"
                                        alternate="partyImage"
                                    />
                                    <div class="pl-4">
                                        <p
                                            class="text-lg font-semibold text-slate-800"
                                        >
                                            {{ getName(party.user, 'name', 30) }}
                                        </p>
                                        <p class="text-sm text-slate-500">
                                            {{ party.user.email }}
                                        </p>
                                    </div>
                                </div>
                                <div class="flex items-start">
                                    <div class="grow">
                                        <div
                                            class="items-start justify-between py-4 sm:flex"
                                        >
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                                            >
                                                <p>
                                                    {{
                                                        $t(
                                                            'caseDetail.parties.role'
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                            <div
                                                class="mb-2 text-lg font-medium text-slate-800 sm:mb-0"
                                                v-if="party.type"
                                            >
                                                <p>{{ $t($store.getters['cases/getpartyTypes'].find(item => item.key === party.type).translateReference) }}</p>
                                            </div>
                                        </div>
                                        <div
                                            class="items-start justify-between border-t py-4 sm:flex"
                                        >
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                                            >
                                                <p>
                                                    {{
                                                        $t(
                                                            'caseDetail.parties.phoneNumber'
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                            <div
                                                class="mb-2 text-lg font-medium text-slate-800 sm:mb-0"
                                            >
                                                <p>{{ party.user.phone ?? '-' }}</p>
                                            </div>
                                        </div>
                                        <div
                                            class="items-start justify-between border-t py-4 sm:flex"
                                        >
                                            <div
                                                class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                                            >
                                                <p>
                                                    {{
                                                        $t(
                                                            'caseDetail.parties.email'
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                            <div
                                                class="mb-2 text-lg font-medium text-slate-800 sm:mb-0"
                                            >
                                                <p>{{ party.user.email ?? '-' }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                v-if="party.was_reassigned"
                                class="bg-yellow-100 py-2 px-6 -mx-6 -mb-6 rounded-b-lg flex flex-col gap-1">
                                    <div class="flex justify-between">
                                        <span class="font-bold text-slate-500">
                                            {{ $t('caseDetail.parties.reassignment.reassigned') }}
                                        </span>
                                        <span>
                                            {{ $filters.formattedDate(party.reassigned_at, 'DD MMMM YYYY - HH:mm') }}
                                        </span>
                                    </div>
                                    <div class="flex gap-2">
                                        <span>
                                            {{ $t('caseDetail.parties.reassignment.reason') }}:
                                        </span>
                                        <p>
                                            {{ party.reassignment_reason }}
                                        </p>
                                    </div>
                                    <div
                                    v-if="party.document_reassignment"
                                    class="flex gap-2">
                                        <span>
                                            {{ $t('caseDetail.parties.reassignment.attachment') }}:
                                        </span>
                                        <div v-if="party.document_reassignment.files.length">
                                            <a
                                            target="_blank"
                                            class="text-sky-500"
                                            :href="party.document_reassignment.files[0].file">
                                                {{ party.document_reassignment.name }}
                                            </a>
                                        </div>
                                    </div>
                                    <div
                                    v-if="party.user_reassigned && party.user_reassigned"
                                    class="flex items-center gap-2">
                                        <span>
                                            {{ $t('caseDetail.parties.reassignment.by') }}:
                                        </span>
                                        <div class="flex items-center">
                                            <ImageLoader
                                            wrapper-class="mr-3 shrink-0 rounded-full"
                                            :src="party.user_reassigned.avatar"
                                            width="30"
                                            height="30"
                                            alternate="partyImage"
                                            />
                                            <span>
                                                {{ party.user_reassigned.name }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex w-full justify-end">
                                    <div
                                    v-if="party.is_reassignment && !party.was_reassigned"
                                    class="bg-sky-500 -mx-6 -mb-6 rounded-br-lg rounded-tl-lg text-white py-1 px-2 w-24 text-center"
                                    >
                                        {{ $t('caseDetail.parties.reassignment.itis') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Parties -->
                    </template>
                    <DualRingLoader v-else />
                </div>
            </main>
        </div>

        <RightSidebar :sidebar-open="sidebarOpen" @close-sidebar="sidebarOpen = false" />
    </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import DualRingLoader from '@/components/DualRingLoader.vue'
import ImageLoader from '@/components/image/ImageLoader.vue'
import FileItem from '@/components/FileItem.vue'
import FileTypeIcon from '@/components/FileTypeIcon.vue'

import { VideoPlayer } from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

import Sidebar from '@/partials/Sidebar.vue'
import RightSidebar from '@/partials/RightSidebar.vue'

export default {
    name: 'CaseDetailPage',
    components: {
        Sidebar,
        RightSidebar,
        DualRingLoader,
        ImageLoader,
        FileItem,
        VideoPlayer,
        FileTypeIcon,
    },
    mixins: [globalMixin],
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()
        const sidebarOpen = ref(false)
        const activeCase = ref({})

        const parties = ref([])

        return {
            t,
            n,
            store,
            toast,
            sidebarOpen,
            activeCase,
            parties,
        }
    },
    mounted() {
        if (this.$store.getters['auth/adminModeGetter'] === 'admin') {
            //   this.checkUserAccess('USER', true, false, false, true)
        }
        this.getCaseDetail()
    },
    methods: {
        async getCaseDetail() {
            this.isLoading = true
            await this.store
                .dispatch('cases/getCaseDetail', {
                    id: this.$route.params.slug,
                })
                .then((response) => {
                    this.isLoading = false
                    this.activeCase = response.data
                    // If case stage not 5
                    if (this.activeCase.stage !== 5) {
                        this.$router.push({
                            name: 'dashboard',
                            params: {
                                slug: this.$route.params.slug,
                            },
                        })
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
    },
    computed: {
        getSettledResult () {
            if (this.isAReferenceTranslate(this.activeCase.case_settled.result)) {
                return this.$t(this.isAReferenceTranslate(this.activeCase.case_settled.result))
            } else {
                return this.activeCase.case_settled.result;
            }
        },
        getPartiesCaseSorted () {
            return this.activeCase && this.activeCase.case_parties
                ? this.activeCase.case_parties.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                : []
        },
    },
}
</script>
