<template>
    <!-- Modal backdrop -->
    <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0"
        enter-to-class="opacity-100" leave-active-class="transition ease-out duration-100" leave-from-class="opacity-100"
        leave-to-class="opacity-0">
        <div v-show="modalOpen" class="fixed inset-0 z-50 bg-slate-900 bg-opacity-30 transition-opacity" aria-hidden="true">
        </div>
    </transition>
    <!-- Modal dialog -->
    <transition enter-active-class="transition ease-in-out duration-200" enter-from-class="opacity-0 translate-y-4"
        enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in-out duration-200"
        leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-4">
        <div v-show="modalOpen" :id="id"
            class="fixed inset-0 z-50 my-4 flex transform items-center justify-center overflow-hidden px-4 sm:px-6"
            role="dialog" aria-modal="true">
            <div ref="modalContent" class="max-h-full w-full max-w-lg overflow-auto rounded-lg bg-white shadow-lg">
                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'

export default {
    name: 'ModalEmpty',
    props: ['id', 'modalOpen', 'backdrop', 'otherElementsWillCloseModal'],
    emits: ['close-modal'],
    setup(props, { emit }) {
        const modalContent = ref(null)


        // close on click outside
        const clickHandler = ({ target }) => {
            if (
                !props.backdrop
                &&
                (
                    !modalContent.value.contains(target)
                    ||
                    (
                        props.otherElementsWillCloseModal
                        &&
                        props.otherElementsWillCloseModal.some(el => el.contains(target))
                    )
                )
                &&
                (
                    target.id == props.id
                    ||
                    (
                        props.otherElementsWillCloseModal
                        &&
                        props.otherElementsWillCloseModal.some(el => el.contains(target))
                    )
                )

            ) {
                emit('close-modal')
            }
        }

        // close if the esc key is pressed
        const keyHandler = ({ keyCode }) => {
            if (
                !props.backdrop
                &&
                keyCode == 27
            ) {
                emit('close-modal')
            }
        }

        onMounted(() => {
            document.addEventListener('click', clickHandler)
            document.addEventListener('keydown', keyHandler)
        })

        onUnmounted(() => {
            document.removeEventListener('click', clickHandler)
            document.removeEventListener('keydown', keyHandler)
        })

        return {
            modalContent,
        }
    },
}
</script>
