export default {
    data: function () {
        return {
            chatMembers: [],
            allChatRoom: [],
            privateChatRoom: [],
            initialChatRoom: [],
            firstInitialChatRoom: {},
            allChatRoomDocuments: [],
            activeRoom: {},
            activeChat: {},
            newChatRoomData: {},
            isCurrentUserJoined: false,
            filterChat: {
                page: 1,
                take: 125,
                order: 'ASC',
            },
            chatData: [],
            chatId: null,
            accept: false,
            reason: '',
            lastOffer: {},
            currentPdf: null,
        }
    },
    computed: {
        activeUser () {
            return this.$store.getters['auth/getCurrentUser']
        }
    },
    methods: {
        async getAllChat(paramsData = { id: '', type: '' }, loading = true) {
            if (loading) this.isLoading = true
            this.chatData = []
            const params = {
                page: this.filterChat.page,
                take: this.filterChat.take,
                order: this.filterChat.order,
            }
            if (typeof this.$route.params.id !== 'undefined') {
                params.room_id = this.$route.params.id
            } else {
                params.room_id = this.$methods.encrypt(paramsData.id);
            }
            if (paramsData.q) params.q = paramsData.q
            if (paramsData.type && paramsData.type !== '') {
                params.type = paramsData.type
            }
            await this.store
                .dispatch('chat/getAllChat', params)
                .then((response) => {
                    this.isLoading = false
                    if (response.data) {
                        const responseData = response.data.data ? response.data.data : []
                        this.chatData = responseData

                        try {
                            setTimeout(function () {
                                if (document.getElementById('chatContainer')) {
                                    const scrollContainer = document.getElementById('chatContainer');
                                    scrollContainer.scrollTop = 1000000;
                                }
                            }, 500)
                        } catch (error) {

                        }

                    } else {
                        this.$router.go(-1);
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async closeChatAPI() {
            if (this.isLoading) return
            this.isLoading = true
            await this.store
                .dispatch('chat/closeChat', {
                    room_id: this.$route.params.id,
                })
                .then((response) => {
                    this.isLoading = false
                    this.toChatGroup()
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        createChatAPI(params) {
            if (this.isModalLoading) return
            this.isModalLoading = true
            this.store.dispatch('chat/createChat', params)
            .then((response) => {
                this.isModalLoading = false
                this.makeOfferModalOpen = false
                this.responseToTheClaimOpen = false
                this.makeFirstOfferMediatorOpen = false
                this.chatModalOpen = false
                if (response.status === 200 || response.status === 201) {
                    this.toast.success(this.$t('chat.discussion.create.successfully'))
                    this.getAllChat({ id: this.activeChat.id }, false)
                }
            })
            .catch((error) => {
                this.isModalLoading = false
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        async offerActionAPI() {
            if (this.isModalLoading) return
            this.isLoading = true
            await this.store
                .dispatch('chat/chatOfferAction', {
                    chat_id: this.chatId,
                    accept: this.accept,
                    reason: this.reason || '',
                    case_id: this.$methods.decrypt(this.$route.params.slug),
                    room_id: this.$methods.decrypt(this.$route.params.id),
                })
                .then((response) => {
                    this.isLoading = false
                    this.getAllChat({ id: this.activeChat.id })
                })
                .catch((error) => {
                    this.isModalLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        // Chat rooms
        async getAllRoom(is_initial = false) {
            this.isLoading = true
            const params = { case_id: this.$route.params.slug }
            if (is_initial) params.is_initial = true

            await this.store
                .dispatch('chat/getAllRoom', params)
                .then((response) => {
                    this.isLoading = false
                    const responseData = response.data
                    if (is_initial) {
                        this.initialChatRoom = responseData
                        if (this.initialChatRoom.length > 0) {
                            this.firstInitialChatRoom = this.initialChatRoom[0]
                        }
                    } else {
                        this.allChatRoom = responseData.filter(item => !item.private_chat);
                        this.privateChatRoom = responseData.filter(item => item.private_chat);
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async getAllDocumentRoom() {
            this.isLoading = true

            await this.store
                .dispatch('chat/getAllDocumentRoom', {
                    id: this.$route.params.id,
                })
                .then((response) => {
                    this.isLoading = false
                    if (response.data) {
                        this.allChatRoomDocuments = response.data
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async getDetailRoom(loading = true) {
            if (loading) this.isLoading = true
            if (!this.$route.params.id) return

            await this.store
                .dispatch('chat/getDetailRoom', {
                    id: this.$route.params.id,
                })
                .then((response) => {
                    this.isLoading = false
                    const responseData = response.data
                    this.activeChat = responseData
                    this.chatMembers = responseData.member
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async getCurrentStageChat() {
            this.isLoading = true
            this.activeChat = {}
            await this.store.dispatch('chat/getCurrentStageChat', {
                case_id: this.$route.params.slug,
            })
                .then((response) => {
                    this.isLoading = false
                    const responseData = response.data
                    if (responseData) {
                        this.activeChat = responseData
                        const isJoined = this.activeChat.member.some((member) => {
                            return member.user.id === this.activeUser.id;
                        });
                        this.isCurrentUserJoined = isJoined;
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async createChatRoomAPI() {
            this.isLoading = true

            await this.store
                .dispatch('chat/createChatPrivate', this.newChatRoomData)
                .then((response) => {
                    this.isLoading = false
                    this.chatModalOpen = false
                    this.getAllRoom()
                })
                .catch((error) => {
                    this.isLoading = false
                    this.chatModalOpen = true
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async responseClaimAPI() {
            this.isLoading = true

            await this.store
                .dispatch('chat/responseClaim', this.responseClaimData)
                .then((response) => {
                    this.isLoading = false
                    this.responseToTheClaimOpen = false
                    const responseData = response.data
                    this.toast.success(responseData.message)
                    const roomID = responseData.data.chat_room_id
                    return this.$router.push({
                        name: 'chats',
                        params: {
                            slug: this.$route.params.slug,
                            id: roomID,
                        },
                        query: {
                            lang: this.$route.query.lang,
                        },
                    })
                    // this.getAllRoom()
                })
                .catch((error) => {
                    this.isLoading = false
                    this.chatModalOpen = true
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async getLastOffer() {
            if (this.checkEmptyObject(this.firstInitialChatRoom)) {
                return
            }

            this.isLoading = true
            await this.store
                .dispatch('chat/getLastOffer', { roomId: this.firstInitialChatRoom.id })
                .then((response) => {
                    this.isLoading = false
                    const responseData = response.data.data
                    this.lastOffer = responseData
                })
                .catch(() => {
                    this.isLoading = false
                })
        },
        toChatGroup() {
            return this.$router.push({
                name: 'chat-group',
                params: { slug: this.$route.params.slug },
                query: {
                    name: this.$route.query.name,
                    status: this.$route.query.status,
                    sub_status: this.$route.query.sub_status,
                    lang: this.$route.query.lang,
                },
            })
        },
        toChatDiscussion(item, fromDiscussion = false) {
            let chatRoomId = item.id
            if (fromDiscussion) {
                chatRoomId = item.chat_room_id
            }

            return this.$router.push({
                name: 'chats',
                params: {
                    slug: this.$route.params.slug,
                    id: this.$methods.encrypt(chatRoomId),
                },
                query: {
                    name: item.name,
                    status: item.status,
                    lang: this.$route.query.lang,
                },
            })
        },
        toChatRoom(chatRoomId) {
            return this.$router.push({
                name: 'chats',
                params: {
                    slug: this.$route.params.slug,
                    id: chatRoomId,
                },
                query: {
                    lang: this.$route.query.lang,
                },
            })
        },
        createChatRoom(event) {
            this.newChatRoomData = event
            this.createChatRoomAPI()
        },
        async getDocumentAgreement(chat) {
            if (this.isLoading) return
            this.isLoading = true
            await this.store
                .dispatch('chat/getDocumentAgreement', {
                    chat_id: this.$methods.encrypt(chat.id),
                    case_id: this.$route.params.slug,
                })
                .then((response) => {
                    if (response && response.data) {
                        this.currentPdf = response.data.pdf;
                    }
                    this.isLoading = false
                })
                .catch((error) => {
                    this.isModalLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        closePdfEmbed () {
            this.currentPdf = null;
        }
    },
}
