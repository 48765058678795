export default {
    data: () => ({
        documents: [],
        requestedDocuments: [],

        folderDetail: {},
        activeFolder: {},
        directory: [{ root: true, label: '', folderId: null }],
        documentModalOpen: false,
        currentDocument: {},
        isDocumentEdit: false,
        isFolder: false,
        formData: {},
        infoDocumentModalOpen: false,
        deleteDocumentModalOpen: false,

        infoDocumentRequestModalOpen: false,
        currentDocumentRequest: {},

        filterDataDocument: {
            page: 1,
            take: 50,
            search: '',
            order: 'DESC',
        },
        metaDataDocument: {
            page: 1,
            take: 10,
            itemCount: 10,
            pageCount: 1,
            hasPreviousPage: false,
            hasNextPage: true,
        },
        filterDataFolder: {
            page: 1,
            take: 50,
            search: '',
            order: 'DESC',
            type: 'Folder',
        },
        metaDataFolder: {
            page: 1,
            take: 10,
            itemCount: 10,
            pageCount: 1,
            hasPreviousPage: false,
            hasNextPage: true,
        },
    }),
    methods: {
        newDocument (event) {
            this.isFolder = event;
            this.documentModalOpen = true
            this.isDocumentEdit = false
            this.currentDocument = {}
        },
        editDocument (event) {
            this.isFolder = event.document.type === 'Folder'
            this.documentModalOpen = true
            this.isDocumentEdit = true
            this.currentDocument = JSON.parse(JSON.stringify(event.document));
        },
        submitData(formData) {
            if (this.isLoading) return
            this.formData = formData
            if (formData.documentId === null) {
                this.createDocument()
            } else {
                this.updateDocument()
            }
        },
        async getAllDocument(folderId = null) {
            this.isLoading = true

            let folderIdPayload = this.$methods.encrypt(folderId);
            if (!folderIdPayload && this.$route.params.id) {
                folderIdPayload = this.$route.params.id;
            }

            await this.store
                .dispatch('document/getAllDocument', {
                    order: this.filterDataDocument.order,
                    page: this.filterDataDocument.page,
                    take: this.filterDataDocument.take,
                    q: this.filterDataDocument.search,
                    case_id: this.$route.params.slug,
                    folder_id: folderIdPayload,
                })
                .then((response) => {
                    this.isLoading = false
                    const responseData = response.data;
                    if (responseData.data) {
                        this.documents = responseData.data.filter(item => item.type === 'Folder')
                        this.documents.push(...responseData.data.filter(item => item.type === 'File'))
                    }
                    if (responseData.from) {
                        this.metaDataDocument = {
                            current_page: responseData.current_page,
                            from: responseData.from,
                            last_page: responseData.last_page,
                            links: responseData.links,
                            per_page: responseData.per_page,
                            to: responseData.to,
                            total: responseData.total,
                        }
                    }
                })
                .catch((error) => {
                    if (error.response.status === 403) {
                        this.$router.go(-1);
                    }
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async getDocumentRequests(folderId) {
            this.isLoading = true
            await this.store
                .dispatch('document/getDocumentRequests', {
                    order: this.filterDataDocument.order,
                    page: this.filterDataDocument.page,
                    take: this.filterDataDocument.take,
                    q: this.filterDataDocument.search,
                    case_id: this.$route.params.slug,
                })
                .then((response) => {
                    this.isLoading = false
                    const responseData = response.data;
                    if (responseData.data) {
                        this.requestedDocuments = responseData.data
                    }
                    if (responseData.from) {
                        this.metaDataDocument = {
                            current_page: responseData.current_page,
                            from: responseData.from,
                            last_page: responseData.last_page,
                            links: responseData.links,
                            per_page: responseData.per_page,
                            to: responseData.to,
                            total: responseData.total,
                        }
                    }
                })
                .catch((error) => {
                    if (error.response.status === 403) {
                        this.$router.go(-1);
                    }
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async getCurrentFolder(folderId) {
            await this.$store
                .dispatch('document/getCurrentFolder', {
                    case_id: this.$route.params.slug,
                    folder_id: this.$methods.encrypt(folderId) ?? this.$route.params.id
                })
                .then((response) => {
                    const responseData = response.data
                    if (responseData) {
                        this.activeFolder = responseData
                        if (!this.activeFolder.system_path.length) {
                            this.directory[0].folderId = this.activeFolder.id;
                        } else {
                            this.directory = [{ root: true, label: '', folderId: null }],
                            this.activeFolder.system_path.map((item, index) => {
                                if (index === 0) {
                                    this.directory[0].folderId = item.id;
                                } else {
                                    this.directory.push({ label: item.name, folderId: item.id });
                                }
                            })
                        }
                    }
                    if (this.activeFolder.identity === 'case-.case_record.requested_documents') {
                        this.getDocumentRequests(this.activeFolder.id);
                    } else {
                        this.getAllDocument(this.activeFolder.id)
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        createDocument () {
            if (this.isLoading) return
            this.isLoading = true
            this.store.dispatch('document/createDocument', {
                name: this.formData.name,
                folder_id: this.formData.folderId,
                type: this.formData.type,
                attachment: this.formData.attachment,
                parties_id: this.formData.partiesId,
                case_id: this.$methods.decrypt(this.$route.params.slug),
                is_read_only: this.activeFolder.is_read_only,
                calendar_agenda_id: this.formData.calendarAgendaId
            })
            .then((response) => {
                this.isLoading = false
                if (response.status === 200 || response.status === 201) {
                    const message = response.data?.message ?? (
                        this.formData.type === 'Folder'
                            ? this.$t('document.modalCreateFile.successfullyFolder')
                            : this.$t('document.modalCreateFile.successfullyFile')
                    );
                    this.toast.success(message)
                    this.documentModalOpen = false
                    this.getAllDocument(this.activeFolder.id)
                    if (this.formData.calendarAgendaId) {
                        this.getCalendarAgendasToSelect()
                    }
                }
            })
            .catch((error) => {
                console.log(error)
                this.isLoading = false
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        updateDocument () {
            if (this.isLoading) return
            this.isLoading = true
            this.store.dispatch('document/updateDocument', {
                document_id: this.$methods.encrypt(this.formData.documentId),
                name: this.formData.name,
                folder_id: this.formData.folderId,
                type: this.formData.type,
                parties_id: this.formData.partiesId,
                case_id: this.$methods.decrypt(this.$route.params.slug),
            })
            .then((response) => {
                this.isLoading = false
                if (response.status === 200) {
                    const message = response.data?.message ?? (
                        this.formData.type === 'Folder'
                            ? this.$t('document.modalEditFile.successfullyFolder')
                            : this.$t('document.modalEditFile.successfullyFile')
                    );
                    this.toast.success(message)
                    this.documentModalOpen = false
                    this.getAllDocument(this.activeFolder.id)
                }
            })
            .catch((error) => {
                this.isLoading = false
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        async deleteDocumentAPI(events) {
            if (this.isLoading) return

            this.isLoading = true
            await this.store
                .dispatch('document/deleteDocument', {
                    id: this.$methods.encrypt(events.id),
                })
                .then((response) => {
                    this.isLoading = false
                    if (response.status === 200) {
                        this.toast.success(
                            response.data?.message
                            ??
                            ((this.currentDocument.type === 'Folder')
                                ? this.$t('document.modalRemoveFile.successfullyFolder')
                                : this.$t('document.modalRemoveFile.successfullyFile')
                            )
                        )
                        this.deleteDocumentModalOpen = false
                        this.currentDocument = {}
                        this.getAllDocument(this.activeFolder.id)
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async getDetailById() {
            this.isLoading = true
            await this.store
                .dispatch('document/getDocumentDetail', {
                    id: this.$route.params.folderId,
                })
                .then((response) => {
                    this.isLoading = false
                    const responseData = response.data.data
                    if (responseData) this.folderDetail = responseData
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        changeFolder (event) {
            this.documents = [];
            this.getAllDocument(event.folder.id);
            this.activeFolder = event.folder;
        },
        getRequestedDocument (event) {
            this.documents = [];
            this.getDocumentRequests(event.folder.id);
            this.activeFolder = event.folder;
        },
        infoDocument(event) {
            this.infoDocumentModalOpen = true
            this.currentDocument = event.document
        },
        openInfoDocumentRequest (event) {
            this.isLoading = true
            this.store.dispatch('document/getOneDocumentRequest', {
                request_id: this.$methods.encrypt(event.document.request.id),
            })
            .then((response) => {
                const responseData = response.data
                if (responseData) {
                    this.currentDocumentRequest = event.document
                    this.currentDocumentRequest.complete_request = responseData
                    this.infoDocumentRequestModalOpen = true
                }
                this.isLoading = false
            })
            .catch((error) => {
                this.isLoading = false
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        closeDocumentModal() {
            this.documentModalOpen = false
            this.currentDocument = {}
        },
        closeModalDelete () {
            this.deleteDocumentModalOpen = false
            this.currentDocument = {}
        },
        closeInfoDocumentModal () {
            this.infoDocumentModalOpen = false
            this.currentDocument = {}
        },
        closeInfoDocumentRequestModal () {
            this.infoDocumentRequestModalOpen = false
            this.currentDocumentRequest = {}
        },
        deleteDocument(event) {
            this.deleteDocumentModalOpen = true
            this.currentDocument = event.document
        },
        searchDocument(event) {
            this.filterDataDocument.search = event
            this.getAllDocument(this.activeFolder.id)
        },
    },
    computed: {
        getDocumentRequestStatusIdentity () {
            return (document) => {
                let status = 'pending';
                if (
                    this.objectHasProperty(document, 'request.providers')
                    &&
                    document.request.providers.every(provider => provider.process_at === null)
                    &&
                    !this.objectHasProperty(document, 'request.last_document_provided')
                ) {
                    status = 'pending';
                } else if (
                    this.objectHasProperty(document, 'request.providers')
                    &&
                    document.request.providers.some(provider => provider.is_accept === false)
                    &&
                    document.request.providers.some(provider => provider.reason !== null)
                ) {
                    status = 'rejected';
                } else if (
                    this.objectHasProperty(document, 'request.providers')
                    &&
                    document.request.providers.some(provider => provider.is_accept)
                    &&
                    this.objectHasProperty(document, 'request.last_document_provided')
                    &&
                    !this.objectHasProperty(document, 'request.last_document_provided.request_result')
                ) {
                    status = 'on_review';
                } else if (
                    this.objectHasProperty(document, 'request.providers')
                    &&
                    document.request.providers.every(provider => provider.process_at === null)
                    &&
                    this.objectHasProperty(document, 'request.last_document_provided.request_result')
                    &&
                    document.request.last_document_provided.request_result.is_accept == false
                ) {
                    status = 'on_rectification';
                } else if (
                    this.objectHasProperty(document, 'request.providers')
                    &&
                    this.objectHasProperty(document, 'request.last_document_provided.request_result')
                    &&
                    document.request.last_document_provided.request_result.is_accept == true
                ) {
                    status = 'approved';
                }
                return status;
            }
        }
    },
}
