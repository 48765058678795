import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'
import { toBase64 } from '../../utils/Utils.js'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
    getProfile({ commit }) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            axios.get(`${routePathAPI()}/user`, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            })
            .then(async (response) => {
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    updateShowGuide({ state, commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        const config = {
            headers: {
                'x-language-code': currentLanguageCode,
            },
        }

        return new Promise((resolve, reject) => {
            axios
                .post(`${routePathAPI()}/user/show-guide`, config)
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200 && responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateProfile({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            let newPayload = JSON.parse(JSON.stringify(payload))
            toBase64(payload.attachment).then(result => {
                newPayload.attachment = result
                    ? {
                        cont: result.split(',').pop(),
                        name: payload.attachment.name.split('.')[0],
                        ext: payload.attachment.name.split('.').pop(),
                        size: payload.attachment.size
                    }
                    : null

                console.log(newPayload)

                axios
                    .post(`${routePathAPI()}/users/profile/update`, newPayload)
                    .then((response) => {
                        const responseData = response.data
                        if (response.status === 200) {
                            resolve({
                                status: response.status,
                                message: 'success',
                                data: responseData,
                            })
                        } else {
                            reject({
                                status: response.status,
                                message: 'failed',
                                data: responseData,
                            })
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        })
    },
    updatePassword({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {
                old_password: payload.old_password,
                new_password: payload.new_password,
            }

            axios
                .put(`${routePathAPI()}/users/password/change`, params, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}

// mutations
const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
