<template>
  <div class="flex h-screen overflow-hidden">
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <!-- Site header -->
      <!-- <Header
        :sidebarOpen="sidebarOpen"
        @toggle-sidebar="sidebarOpen = !sidebarOpen"
      /> -->

      <main>
        <div class="w-full">
          <div class="flex h-full flex-col justify-center bg-gray-100 md:mt-10">
            <div
              class="w-full self-center px-2 py-5 text-left md:w-8/12 md:py-2 md:px-0"
            >
              <div
                class="flex w-full cursor-pointer flex-row items-center"
                @click="
                  $router.push({
                    name: 'case-stage',
                    query: {
                      name: $route.query.name,
                      status: $route.query.status,
                      sub_status: $route.query.sub_status,
                      lang: $route.query.lang,
                    },
                  })
                "
              >
                <svg
                  class="h-7 w-4 fill-current text-slate-500"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z"
                  />
                </svg>
                <span class="ml-2 text-xs text-slate-500 hover:text-sky-400">{{
                  $t('action.back')
                }}</span>
              </div>
            </div>
            <div
              class="w-full self-center rounded-lg bg-white px-8 py-8 shadow-md md:w-8/12"
            >
              <h1 class="mb-2 text-3xl font-bold text-slate-800">
                {{ $t('arbitratorRequirement.title') }}
              </h1>
              <p class="text-sm">
                {{ $t('arbitratorRequirement.description') }}
              </p>
              <div
                class="flex w-full justify-between border-gray-500 pt-6 text-center font-bold"
              >
                <div class="flex w-full px-2 md:w-3/6">
                  <div
                    class="h-80 w-full cursor-pointer rounded-xl border p-6"
                    :class="typeSelected == 1 ? 'border-sky-500' : ''"
                    @click="typeSelected = 1"
                  >
                    <ProfileIcon class="mx-auto mb-8 w-52 text-sky-500" />
                    {{ $t('arbitratorRequirement.letRedek') }}
                  </div>
                </div>
                <div class="flex w-full px-2 md:w-3/6">
                  <div
                    class="h-80 w-full cursor-pointer rounded-xl border p-6"
                    :class="typeSelected == 2 ? 'border-sky-500' : ''"
                    @click="typeSelected = 2"
                  >
                    <PaperIcon class="mx-auto mb-8 w-52 text-sky-500" />
                    {{ $t('arbitratorRequirement.pickOne') }}
                  </div>
                </div>
              </div>
              <div class="mt-5 flex flex-row justify-end">
                <div class="w-3/12">
                  <div
                    class="btn text-md mt-6 h-12 w-full cursor-pointer rounded-md text-slate-500 shadow-none hover:text-slate-700"
                    @click="
                      $router.push({
                        name: 'case-stage',
                        query: {
                          name: $route.query.name,
                          status: $route.query.status,
                          sub_status: $route.query.sub_status,
                          lang: $route.query.lang,
                        },
                      })
                    "
                  >
                    <svg
                      class="mr-2 h-7 w-7 fill-current text-slate-500"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z"
                      />
                    </svg>
                    {{ $t('action.back') }}
                  </div>
                </div>
                <button
                  class="btn text-md mt-6 h-12 w-3/12 rounded-md bg-sky-500 text-white hover:bg-sky-600"
                  @click="nextPage"
                >
                  {{ $t('action.next') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
// import Header from '@/partials/Header.vue'

import PaperIcon from '@/assets/svg/paper.svg'
import ProfileIcon from '@/assets/svg/profile.svg'

export default {
  name: 'ArbitratorRequirementPage',
  components: {
    // Header,

    PaperIcon,
    ProfileIcon,
  },
  setup() {
    const sidebarOpen = ref(false)

    return {
      sidebarOpen,
    }
  },
  data() {
    return {
      typeSelected: 2,
    }
  },
  methods: {
    nextPage() {
      if (this.typeSelected === 1) {
        return this.$router.push({ name: 'index' })
      } else {
        return this.$router.push({
          name: 'arbitrator-selection',
          query: {
            name: this.$route.query.name,
            status: this.$route.query.status,
            sub_status: this.$route.query.sub_status,
            lang: this.$route.query.lang,
          },
        })
      }
    },
  },
}
</script>
