<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Sidebar -->
    <Sidebar :sidebar-open="sidebarOpen" @close-sidebar="sidebarOpen = false" />

    <!-- Content area -->
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <main>
        <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
          <div class="flex w-full flex-col">
            <h1 class="text-2xl font-bold text-black">
              {{ $t('admin.timeline.title') }}
            </h1>
            <p>{{ $t('admin.timeline.description') }}</p>
          </div>
          <div class="my-4 flex w-full justify-between">
            <SearchForm :placeholder="$t('admin.timeline.searchPlaceholder')" />

            <ButtonAdd
              :title="$t('admin.timeline.buttonAdd')"
              custom-class="rounded-xl"
              @clicked="newUser()"
            />
          </div>

          <!-- <div v-if="!isLoading" class="w-full">
            <Table :header-data="headerData" tbody-class="mt-4" />
            <div v-for="user in users" :key="user.id">
              <UserItem
                :user="user"
                class="mt-2 py-6"
                @edit-user="editUser($event)"
                @remove-user="deleteUser($event)"
              />
            </div>
          </div>
          <DualRingLoader v-else /> -->
        </div>
      </main>

      <ModalDelete
        :is-open="deleteActivityModalOpen"
        :title="$t('admin.user.modalRemoveUser.title')"
        :description="$t('admin.user.modalRemoveUser.description')"
        @closed="deleteActivityModalOpen = false"
      />

      <ModalCreateActivity
        :is-open="newActivityModalOpen"
        :active-activity="activeActivity"
        :types="activityTypes"
        :cases="cases"
        :users="users"
        :is-admin="true"
        :is-create-loading="isModalLoading"
        @closed="newActivityModalOpen = false"
        @submit-data="submitData"
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'

import Sidebar from '@/partials/Sidebar.vue'
import SearchForm from '@/components/SearchForm.vue'
import ButtonAdd from '@/components/ButtonAdd.vue'
import Table from '@/components/Table.vue'
import UserItem from '@/components/UserItem.vue'

import ModalCreateActivity from '@/components/ModalCreateActivity.vue'
import ModalDelete from '@/components/ModalDelete.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'

export default {
  name: 'AdminActivityPage',
  components: {
    Sidebar,
    SearchForm,
    ButtonAdd,
    Table,
    ModalCreateActivity,
    ModalDelete,
    UserItem,
    DualRingLoader,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const toast = useToast()

    const sidebarOpen = ref(false)
    const newActivityModalOpen = ref(false)
    const deleteActivityModalOpen = ref(false)
    const activeActivity = ref({})
    const activityTypes = ref([])
    const cases = ref([])
    const users = ref([])
    const headerData = ref([
      {
        name: t('admin.user.headerTable.name'),
        colspan: 1,
      },
      {
        name: t('admin.user.headerTable.role'),
        colspan: 1,
      },
      {
        name: t('admin.user.headerTable.email'),
        colspan: 1,
      },
      // {
      //   name: t('admin.user.headerTable.action'),
      //   colspan: 1,
      // },
    ])

    return {
      t,
      store,
      toast,
      sidebarOpen,
      activeActivity,
      activityTypes,
      cases,
      users,
      deleteActivityModalOpen,
      newActivityModalOpen,
      headerData,
    }
  },
  data: () => ({
    filterDataUser: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
    metaDataUser: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    formData: {
      first_name: '',
      last_name: '',
      username: '',
      email: '',
      phone: '',
      password: '',
      role_id: '',
    },
  }),
  mounted() {
    // this.checkUserAccess('REDEK_ADMIN')
    this.getAllRoles()
    this.getAllUsers()
  },
  methods: {
    newUser(event) {
      this.newActivityModalOpen = true
      this.activeActivity = {}
    },
    editUser(event) {
      this.newActivityModalOpen = true
      this.activeActivity = event.user
    },
    deleteUser(event) {
      this.deleteActivityModalOpen = true
    },
    async getAllRoles() {
      await this.store
        .dispatch('user/getAllRoles')
        .then((response) => {
          const responseData = response.data.data
          if (responseData) {
            this.roles = responseData
          }
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getAllUsers() {
      this.isLoading = true
      await this.store
        .dispatch('user/getAllUsers', {
          order: this.filterDataUser.order,
          page: this.filterDataUser.page,
          take: this.filterDataUser.take,
          q: this.filterDataUser.search,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          if (responseData.data) {
            this.users = responseData.data
          }
          if (responseData.meta) {
            this.metaDataUser = responseData.meta
          }
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    submitData(formData) {
      this.formData = formData
      this.createUser()
    },
    async createUser() {
      await this.store
        .dispatch('user/createUser', {
          first_name: this.formData.first_name,
          last_name: this.formData.last_name,
          username: this.formData.username,
          email: this.formData.email,
          phone: this.formData.phone,
          password: this.formData.password,
          role_id: this.formData.role_id,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            const message = response.data.message
            this.toast.success(message)
            this.newActivityModalOpen = false
          }
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
  },
}
</script>
