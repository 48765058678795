// import { useI18n } from 'vue-i18n'
// import { useToast } from 'vue-toastification'

// const { t, n } = useI18n()
// const toast = useToast()

export default {
    data: () => ({
        caseAreaLoading: false,
        caseListType: 'grid',
        caseTypes: [],
        typeOfPart: '',
        caseAreas: [],
        caseArea: '',
        caseParties: [],
        newCaseData: {},
        casePriorities: [
            {
                id: 'high',
                name: 'High Urgency',
            },
            {
                id: 'medium',
                name: 'Medium Urgency',
            },
            {
                id: 'low',
                name: 'Low Urgency',
            },
        ],
        validPriority: ['high', 'medium', 'low'],
        activeCase: '',
        advocateReview: {},
        mediatorReview: {},
        arbitratorReview: {},
        extensionTimeQuota: 0,
        activeRequestTime: {},
        caseFiles: [],
        caseFileMapping: [],
        activeCaseId: null,

        mediationAgreement: {},
        mediationUsersAgreement: [],
        mediationUsersAgreementAccept: [],
        mediationUsersAgreementReject: [],
        currentUserCanAgree: false,
        mediationFiles: [],
        hasBankLawyer: false,
        needAssistance: true,
    }),
    mounted() {
        if (this.$route.query.assist !== undefined)
            this.needAssistance = this.$route.query.assist == 'true'
        else this.needAssistance = true
    },
    computed: {
        startDeadlineTime() {
            if (this.activeCase.stage === 2)
                return this.activeCase.negotiation_start_time
            else if (this.activeCase.stage === 3)
                return this.activeCase.mediation_start_time
            else if (this.activeCase.stage === 4)
                return this.activeCase.arbitration_start_time
            else return null
        },
        lastDeadlineTime() {
            if (this.activeCase.stage === 2) return this.activeCase.negotiation_end_time
            else if (this.activeCase.stage === 3)
                return this.activeCase.mediation_end_time
            else if (this.activeCase.stage === 4)
                return this.activeCase.arbitration_end_time
            else return null
        },
        lastDeadlineStage() {
            if (this.activeCase.stage == 1) return 'Case Started';
            else if (this.activeCase.stage == 2) return 'Negotiation';
            else if (this.activeCase.stage == 3) return 'Mediation';
            else if (this.activeCase.stage == 4) return 'Arbitration';
            else return null
        },
        caseExpired() {
            if (this.lastDeadlineTime !== null)
                return (
                    this.hoursMinutesFromTwoDates(
                        this.lastDeadlineTime,
                        new Date(),
                        false
                    ) < 0
                )
            else return false
        },
        hasExtensionQuota() {
            return this.extensionTimeQuota > 0
        },
    },
    methods: {
        async getCaseDetail() {
            this.isLoading = true
            await this.$store
                .dispatch('cases/getCaseDetail', {
                    id: this.$route.params.slug,
                })
                .then((response) => {
                    this.isLoading = false
                    const responseData = response.data
                    this.activeCase = responseData
                    this.caseParties = responseData.case_parties

                    // if stage is solved
                    if (responseData.stage === 5) {
                        this.advocateReview =
                            typeof responseData.advocate_review !== 'undefined' &&
                                responseData.advocate_review !== null
                                ? responseData.advocate_review
                                : {}
                        this.mediatorReview =
                            typeof responseData.mediator_review !== 'undefined' &&
                                responseData.advocate_review !== null
                                ? responseData.mediator_review
                                : {}
                        this.arbitratorReview =
                            typeof responseData.arbitrator_review !== 'undefined' &&
                                responseData.advocate_review !== null
                                ? responseData.arbitrator_review
                                : {}
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async getCaseParties() {
            this.isLoading = true
            await this.store
                .dispatch('cases/getCaseParties', {
                    case_id: this.$route.params.slug,
                })
                .then((response) => {
                    const responseData = response.data
                    this.caseParties = responseData
                })
                .catch((error) => {
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async getAllAreas() {
            this.caseAreaLoading = true
            await this.store.dispatch('cases/getAllAreas', {
                page: 1,
                take: 50,
                order: 'DESC',
            }).then((response) => {
                const responseData = response.data;
                if (responseData.data) {
                    this.caseAreas = responseData.data;
                }
                this.caseAreaLoading = false
                if (responseData.data.length > 0) this.caseArea = responseData.data[0].id
            })
                .catch((error) => {
                    this.caseAreaLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async getAllTypes() {
            await this.store.dispatch('cases/getAllTypes', {
                page: 1,
                take: 50,
                order: 'DESC',
            }).then((response) => {
                const responseData = response.data
                if (responseData.data) {
                    this.caseTypes = responseData.data
                    if (responseData.data.length > 0) this.typeOfPart = responseData.data[0].id
                }
            }).catch((error) => {
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        async getAllCurrencies() {
            await this.store.dispatch('cases/getAllCurrencies', {
                page: 1,
                take: 200,
            }).then((response) => {
                const responseData = response.data
                if (responseData.data) {
                    this.currencies = responseData.data
                }
            }).catch((error) => {
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        async caseAssignAPI(paramsData = {}) {
            this.isLoading = true
            await this.store.dispatch('cases/caseAssign', paramsData)
                .then((response) => {
                    this.$store.dispatch('cases/resetActiveCase')
                    this.toDetailAdmin()
                    this.isLoading = false
                }).catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async caseDuteDateAPI(paramsData = {}) {
            this.isLoading = true
            await this.store
                .dispatch('cases/caseDuteDate', paramsData)
                .then((response) => {
                    this.isLoading = false
                    // this.$store.dispatch('cases/resetActiveCase')
                    // this.toDetailAdmin()
                    this.toast.success(response.data.message)
                    this.assignCaseOpen = false
                    this.getCaseDetailAdmin()
                }).catch((error) => {
                    this.assignCaseOpen = true
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async caseInititateAPI(paramsData = {}) {
            this.isLoading = true
            await this.store
                .dispatch('cases/caseInititate', paramsData)
                .then((response) => {
                    this.isLoading = false
                    this.toast.success(this.$t('caseStage.successInitiate'))
                    this.getCaseDetail(true).then(() => {
                        this.hasBankLawyer = this.activeCase.case_parties.some(item => item.type === 'bank_lawyer');
                        if (this.activeCase && this.activeCase.stage >= 2) {
                            this.activeChat = {}
                            this.chatData = []
                            this.getCurrentStageChat().then(response => {
                                if (this.activeChat.id) {
                                    this.getAllChat({ id: this.activeChat.id, type: 'offer' }).then(responseChat => {
                                        this.chatDataLoaded = true;
                                    })
                                }
                            })
                        }
                    });
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async getCaseDetailAdmin() {
            this.isLoading = true
            await this.store.dispatch('cases/getCaseDetail', {
                id: this.$route.params.slug,
            }).then((response) => {
                this.isLoading = false
                const responseData = response.data;
                // this.$store.dispatch('cases/setActiveCaseVuex', responseData);
                this.activeCase = responseData;
                const hasBankLawyer = responseData.case_parties.some(item => item.type === 'bank_lawyer');
                if (!hasBankLawyer) {
                    this.neutral = 'bank-lawyer'
                } else if (
                    !responseData.case_parties.some(item => item.type === 'mediator') &&
                    responseData.mediator_initiate &&
                    responseData.stage === 3
                ) {
                    this.neutral = 'mediator'
                } else if (
                    !responseData.case_parties.some(item => item.type === 'arbitrator') &&
                    responseData.arbitrator_initiate &&
                    responseData.stage === 4
                ) {
                    this.neutral = 'arbitrator'
                }
            })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async removeCase() {
            this.isLoading = true
            await this.store
                .dispatch('cases/removeCase', {
                    id: this.$route.params.slug,
                })
                .then((response) => {
                    this.toast.success(this.$t('case.delete.success'))
                    this.isLoading = false
                    this.$router.push({
                        name: 'all-cases',
                        query: { lang: this.$route.query.lang },
                    })
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async changeCasePriority() {
            this.isLoading = true
            await this.store
                .dispatch('cases/casePriority', {
                    id: this.$route.params.slug,
                    priority: this.priority,
                })
                .then((response) => {
                    this.isLoading = false
                    this.toast.success(this.t(response.data.message))
                    this.activeCase.priority = this.priority
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async getRequestTimeQuota() {
            // this.isLoading = true
            await this.store.dispatch('casetime/getRequestTimeQuota', {
                case_id: this.$route.params.slug,
            })
            .then((response) => {
                // this.isLoading = false
                const responseData = response.data
                this.extensionTimeQuota = responseData.data
            })
            .catch((error) => {
                // this.isLoading = false
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        async createRequestTime() {
            this.isLoading = true
            await this.store
                .dispatch('casetime/createRequestTime', {
                    case_id: this.$route.params.slug,
                })
                .then((response) => {
                    this.isLoading = false
                    const responseData = response.data
                    this.extenstionTimeModalOpen = false
                    this.toast.success(this.t(responseData.message))
                })
                .catch((error) => {
                    this.isLoading = false
                    this.extenstionTimeModalOpen = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async actionRequestTime(payload = {}, reload = false) {
            this.isLoading = true
            await this.store
                .dispatch('casetime/actionRequestTime', {
                    case_id: this.$route.params.slug,
                    request_time_id: payload.id,
                    accept: payload.accept,
                    reason: payload.reason,
                })
                .then((response) => {
                    this.isLoading = false
                    const responseData = response.data
                    this.rejectRequestTimeOpen = false
                    this.confirmRequestTimeOpen = false
                    // reset request time
                    this.activeRequestTime = {}
                    if (reload) this.getCaseDetailAdmin()
                    this.toast.success(this.t(responseData.message))
                })
                .catch((error) => {
                    this.isLoading = false
                    this.rejectRequestTimeOpen = false
                    this.confirmRequestTimeOpen = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async getActiveRequestTime() {
            // this.isLoading = true
            // await this.store
            //     .dispatch('casetime/getActiveRequestTime', {
            //         case_id: this.$route.params.slug,
            //     })
            //     .then((response) => {
            //         // this.isLoading = false
            //         const responseData = response.data
            //         this.activeRequestTime = responseData.data
            //         // this.extensionTimeQuota = responseData.data
            //     })
            //     .catch((error) => {
            //         // this.isLoading = false
            //         // this.toast.error(this.extractErrorResponse(error))
            //         console.log(error)
            //         this.activeRequestTime = {}
            //     })
        },
        async uploadFileCase(payload) {
            // this.isLoading = true
            await this.store
                .dispatch('cases/uploadFileCase', {
                    case_id: payload.case_id,
                    type: payload.type,
                    attachment: payload.attachment,
                })
                .then((response) => {
                    // this.isLoading = false
                    const responseData = response.data
                    // this.activeRequestTime = responseData.data
                    return responseData
                    // this.extensionTimeQuota = responseData.data
                })
                .catch((error) => {
                    // this.isLoading = false
                    // this.toast.error(this.extractErrorResponse(error))
                    console.log(error)
                    this.activeRequestTime = {}
                })
        },
        async getFileCase(payload) {
            await this.store.dispatch('cases/getFileCase', {
                case_id: payload.case_id,
                type: payload.type,
            }).then((response) => {
                this.caseFileMapping = response.data.map(item => item.document);
            })
                .catch((error) => {
                    this.activeRequestTime = {}
                })
        },
        async actionMediationAgreement(payload = {}) {
            this.isLoading = true
            await this.store
                .dispatch('cases/actionMediationAgreement', {
                    case_id: this.$route.params.slug,
                    mediation_id: payload.mediation_id,
                    accept: payload.accept,
                    reason: payload.reason ? payload.reason : '',
                })
                .then((response) => {
                    this.isLoading = false
                    const responseData = response.data
                    this.currentUserCanAgree = false
                    // this.mediationAgreement = responseData

                    this.toast.success(this.t(responseData.message))
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async getMediationAgreement() {
            // this.isLoading = true
            await this.store
                .dispatch('cases/getMediationAgreement', {
                    case_id: this.$route.params.slug,
                })
                .then((response) => {
                    // this.isLoading = false
                    const responseData = response.data
                    this.mediationAgreement = responseData.data
                    // this.extensionTimeQuota = responseData.data
                })
                .catch((error) => {
                    // this.isLoading = false
                    // this.toast.error(this.extractErrorResponse(error))
                    console.log(error)
                    this.activeRequestTime = {}
                })
        },

        priorityClass(priority) {
            switch (priority) {
                case 'high':
                    return 'text-red-500'
                case 'medium':
                    return 'text-orange-500'
                case 'low':
                    return 'text-green-500'
                default:
                    return 'text-red-500'
            }
        },
        toDetailAdmin() {
            if (!this.$route.params.slug) {
                this.$router.push({
                    name: 'index',
                    query: {
                        lang: this.$route.query.lang,
                    },
                })
            } else {
                this.$router.push({
                    name: 'case-detail-admin',
                    params: { slug: this.$route.params.slug },
                    query: {
                        lang: this.$route.query.lang,
                    },
                })
                this.getCaseDetailAdmin()
                this.$store.dispatch('cases/resetActiveCase')
            }
        },
        openDocumentCase(document) {
            window.open(document, '_blank')
        },
        caseInititate() {
            const params = {
                id: this.$route.params.slug,
                stage: this.activeCase.stage + 1,
            }
            this.initiateMediatorModalOpen = false
            this.initiateArbitratorModalOpen = false
            this.caseInititateAPI(params)
        },
        canReviewCase(role) {
            let canReviewCase = false
            if (
                role.toLowerCase() === 'advocate' &&
                this.checkEmptyObject(this.advocateReview)
            ) {
                canReviewCase = true
            } else if (
                role.toLowerCase() === 'mediator' &&
                this.checkEmptyObject(this.mediatorReview)
            ) {
                canReviewCase = true
            } else if (
                role.toLowerCase() === 'arbitrator' &&
                this.checkEmptyObject(this.arbitratorReview)
            ) {
                canReviewCase = true
            }

            return canReviewCase
        },
        getCaseReview(role) {
            let reviewData = { rating: 0, review: '' }
            if (
                role.toLowerCase() === 'advocate' &&
                !this.checkEmptyObject(this.advocateReview)
            ) {
                reviewData = this.advocateReview
            } else if (
                role.toLowerCase() === 'mediator' &&
                !this.checkEmptyObject(this.mediatorReview)
            ) {
                reviewData = this.mediatorReview
            } else if (
                role.toLowerCase() === 'arbitrator' &&
                !this.checkEmptyObject(this.arbitratorReview)
            ) {
                reviewData = this.arbitratorReview
            }

            return reviewData
        },
    },
}
