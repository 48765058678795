<template>
    <div class="-m-1.5 flex flex-wrap items-center">
        <div class="m-1.5">
            <ModalBasic
            :modal-open="isOpen"
            :show-border-header="false"
            :title="titleModal"
            :subtitle="$t('caseStage.responseToTheClaimDesc')"
            wrapper-class="max-w-5xl"
            @close-modal="close"
            >
                <Form ref="form" v-slot="{ errors }" :validation-schema="makeOfferSchema" @submit="save">
                    <div class="px-5">
                        <div class="space-y-3">
                            <div class="grid grid-cols-2 gap-4">
                                <div>
                                    <label class="mb-1 block text-xs font-bold" for="subject">
                                        {{ $t('chat.discussion.offerSubject') }}
                                    </label>
                                    <Field v-slot="{ field }" v-model="subject" name="subject">
                                        <input id="subject" v-bind="field" class="form-input w-full h-9 p-2" type="text" required
                                            :placeholder="$t('chat.discussion.offerSubjectPlaceholder')" />
                                    </Field>
                                    <div v-if="errors.subject !== undefined" class="flex w-full">
                                        <span class="text-sm text-red-500">
                                            {{ $t(errors.subject) }}
                                        </span>
                                    </div>
                                </div>

                                <div>
                                    <label class="mb-1 block text-xs font-bold" for="meetingTime">
                                        {{ $t('chat.discussion.dueTime') }}
                                    </label>
                                    <Datepicker
                                    v-model="deadlineDate"
                                    wrap-class="form-input h-9 w-full rounded-md pl-9"
                                    mode="single"
                                    :max-today="false"
                                    :max-one-month="true"
                                    :min-twoday="true"
                                    :show-icon="false"
                                    :date-only="true"
                                    @change-date="changeDate" />
                                </div>
                            </div>

                            <div class="grid grid-cols-2 gap-4">
                                <div class="flex w-full flex-col">
                                    <label class="mb-1 block text-xs font-bold" for="request">
                                        {{ $t('chat.discussion.request') }}
                                    </label>
                                    <Field v-slot="{ field }" v-model="request" name="request">
                                        <textarea v-bind="field" :placeholder="$t('chat.discussion.request')"
                                            class="form-input w-full p-2"></textarea>
                                    </Field>
                                    <div v-if="errors.request !== undefined" class="flex w-full">
                                        <span class="text-sm text-red-500">
                                            {{ $t(errors.request) }}
                                        </span>
                                    </div>
                                </div>

                                <div class="flex w-full flex-col">
                                    <label class="mb-1 block text-xs font-bold" for="descriptions">
                                        {{ $t('chat.discussion.description') }}
                                    </label>
                                    <Field v-slot="{ field }" v-model="description" name="description">
                                        <textarea v-bind="field" :placeholder="$t('chat.discussion.description')"
                                            class="form-input w-full p-2"></textarea>
                                    </Field>
                                    <div v-if="errors.description !== undefined" class="flex w-full">
                                        <span class="text-sm text-red-500">
                                            {{ $t(errors.description) }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-4 flex items-center">
                                <input id="default-checkbox" v-model="includeMoney" type="checkbox" value=""
                                    class="h-4 w-4 rounded border-gray-300 bg-gray-100 text-sky-500 focus:ring-2 focus:ring-sky-500" />
                                <label for="default-checkbox" class="ml-2 text-sm font-medium">
                                    {{ $t('caseStage.doesTheOfferIncludeMoney') }}
                                </label>
                            </div>

                            <div v-if="includeMoney">
                                <label class="mb-1 block text-sm font-medium" for="caseHowMuch">
                                    {{ $t('newCase.caseHowMuch') }}
                                </label>
                                <div class="grid grid-cols-1 gap-3 md:grid-cols-3">
                                    <div class="pr-4 col-span-2">
                                        <Field v-slot="{ field }" v-model="amount" name="amount">
                                            <input id="caseHowMuch" v-bind="field" class="form-input h-12 w-full rounded-md"
                                                type="number" :placeholder="$t('newCase.caseHowMuchPlaceholder')" />
                                        </Field>
                                        <div v-if="errors.amount !== undefined" class="flex w-full">
                                            <span class="text-sm text-red-500">
                                                {{ $t(errors.amount) }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-span-1">
                                        <VueMultiselect
                                        v-model="currency"
                                        :options="currencies"
                                        :multiple="false"
                                        :close-on-select="true"
                                        :placeholder="$t('validation.selectCurrency')"
                                        label="name"
                                        track-by="id"
                                        :allow-empty="true"
                                        >
                                            <template #singleLabel="props">
                                                <span class="option__desc">
                                                    <span class="option__title"
                                                        >{{ props.option.code }}
                                                    </span>
                                                </span>
                                            </template>
                                            <template #option="props">
                                                <div class="option__desc space-x-2">
                                                    <span class="option__title">{{
                                                        props.option.name
                                                    }}</span>
                                                    <div
                                                        class="option__small inline rounded-lg px-2 py-1 text-xs font-bold"
                                                    >
                                                        {{ props.option.code }}
                                                    </div>
                                                </div>
                                            </template>
                                        </VueMultiselect>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <label class="mb-1 block text-xs font-bold" for="files">
                                    {{ $t('forms.uploadFile') }}
                                </label>
                                <div class="flex w-full items-center justify-center">
                                    <Field v-model="attachment" name="attachment">
                                        <label for="files"
                                            class="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-gray-300 bg-gray-50 hover:bg-gray-100">
                                            <div class="flex flex-col items-center justify-center py-2">
                                                <svg aria-hidden="true" class="mb-3 h-10 w-10 text-gray-400" fill="none"
                                                    stroke="currentColor" viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12">
                                                    </path>
                                                </svg>
                                                <p class="text-sm text-gray-500">
                                                    {{ $t('forms.dragAndDrop') }}
                                                </p>
                                                <p class="text-sm text-gray-500">
                                                    {{ $t('forms.or') }}
                                                </p>
                                                <p class="text-sm font-semibold text-sky-500">
                                                    {{ $t('forms.browseFiles') }}
                                                </p>
                                            </div>
                                            <input id="files" type="file" class="hidden" @change="fileInput" />
                                            <span v-if="attachment">{{ attachment.name }}</span>
                                        </label>
                                    </Field>
                                </div>
                                <div v-if="errors.attachment !== undefined" class="flex w-full">
                                    <span class="text-sm text-red-500">
                                        {{ $t(errors.attachment) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mx-4 mt-4 bg-yellow-100 p-3 rounded text-sm text-yellow-800">
                        {{ $t('caseStage.thisWillBeOfferTo') }}
                        <span class="font-bold">
                            {{ getUserNameOfferTo }}
                        </span>
                    </div>
                    <div class="py-4">
                        <div class="flex flex-wrap justify-center space-x-2 px-4">
                            <button
                            type="submit"
                            class="btn-sm w-full whitespace-nowrap rounded-lg bg-sky-500 p-3 text-white hover:bg-sky-600"
                            :class="[isLoading ? 'cursor-not-allowed' : 'cursor-pointer']">
                                <span v-if="isLoading">
                                    {{ $t('general.loading') }}
                                </span>
                                <span v-else>
                                    {{ $t('chat.discussion.makeOffer') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </Form>
            </ModalBasic>
        </div>
    </div>
</template>
<script>
import ModalBasic from './ModalBasic.vue'
import Datepicker from '@/components/Datepicker.vue'
// import Timepicker from '@/components/Timepicker.vue'
import { useStore } from 'vuex'
import { Form, Field } from 'vee-validate'
import Editor from '@/components/editor/Editor.vue'
import * as Yup from 'yup'

export default {
    name: 'ModalMakeOfferComponent',
    components: {
        ModalBasic,
        Datepicker,
        // Timepicker,
        Form,
        Field,
        Editor,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        chatMembers: {
            type: Array,
            default: () => {
                return []
            },
        },
        titleModal: {
            type: String,
            default: ''
        }
    },
    emits: ['closed', 'save', 'empty-member'],
    data: () => ({
        makeOfferSchema: Yup.object().shape({
            includeMoney: Yup.boolean(),
            subject: Yup.string()
                .typeError('validation.enterSubject')
                .required('validation.enterSubject')
                .min(5, 'validation.minimumCaseName'),
            description: Yup.string()
                .typeError('validation.enterDescription')
                .required('validation.enterDescription')
                .min(5, 'validation.minimumCaseName'),
            request: Yup.string()
                .typeError('validation.enterRequest')
                .required('validation.enterRequest')
                .min(5, 'validation.minimumCaseName'),
            amount: Yup.number().when('includeMoney', {
                is: true,
                then: Yup.number()
                    .typeError('validation.enterAmount')
                    .required('validation.enterAmount')
                    .test(
                        'checkMinimumAmount',
                        'validation.minimumCaseAmount',
                        (value) => value >= 1
                    ),
            }),
        }),
        subject: '',
        offerTo: '',
        amount: 0,
        request: '',
        currency: null,
        dueDate: '',
        dueTime: '',
        description: '',
        file: '',
        attachment: null,
        deadlineDate: null,
        deadlineTimeFrom: null,
        deadlineTimeTo: null,
        store: null,
        includeMoney: false,
        currencies: [],
    }),
    computed: {
        usersOfferTo () {
            const members = []
            this.chatMembers.map((item) => {
                if (
                    this.$store.getters['auth/getCurrentUser']
                    &&
                    item.user.id !== this.$store.getters['auth/getCurrentUser'].id
                    &&
                    this.$methods.oneUserHasPermissions(item.user, ['cases_manage_case_stage_stages_negotiation_offers_response'])
                    &&
                    !item.was_reassigned
                ) {
                    members.push({
                        id: item.user.id,
                        name: item.user.name,
                        role: item.user.role,
                    })
                }
            })
            return members
        },
        getUserNameOfferTo () {
            return this.usersOfferTo.map(item => item.name + ' (' + item.role.name + ')').join(', ')
        },
    },
    watch: {
        isOpen(val) {
            if (!val) {
                this.close()
            } else {
                this.$refs.form.resetForm()
                // this.agreement = ''
            }
        },
        currencies (val) {
            if (val) {
                this.currency = val.find(item => item.code === 'USD')
            }
        }
    },
    mounted() {
        this.store = useStore()
        this.getAllCurrencies()
    },
    methods: {
        close() {
            this.$refs.form.resetForm()
            this.offerTo = []
            this.$emit('closed')
        },
        save() {
            if (this.usersOfferTo.length <= 0) {
                return
            }
            let params = {}
            params.content = this.description
            params.case_id = this.$methods.decrypt(this.$route.params.slug)
            params.chat_room_id = this.$methods.decrypt(this.$route.params.id)
            params.type = 'offer'
            params.title = this.subject
            params.description = this.description
            params.request = this.request
            params.amount = this.amount
            params.currency = this.currency
            params.deadline_at = this.deadlineDate
            params.attachment = this.attachment
            params.party_ids = this.usersOfferTo.map((item) => item.id)
            this.$emit('save', params)
        },
        fileInput(event) {
            this.attachment = event.target.files[0]
        },
        changeDate(val) {
            this.deadlineDate = val
        },
        getAllCurrencies () {
            this.$store.dispatch('cases/getAllCurrencies', {
                page: 1,
                take: 200,
            }).then((response) => {
                const responseData = response.data
                if (responseData.data) {
                    this.currencies = responseData.data
                }
            }).catch((error) => {
                this.toast.error(this.extractErrorResponse(error))
            })
        }
    },
}
</script>
