<template>
    <div class="border shadow-sm">
        <div v-if="ratingData" class="flex items-center space-x-4 border-b px-3 py-4">
            <ImageLoader
            wrapper-class="h-8 w-8 rounded-full"
            :src="ratingData.user.avatar"
            width="40"
            height="40"
            :alternate="ratingData.user.first_name"
            />
            <p class="flex flex-col">
                <span class="text-sm font-bold">{{ ratingData.user.name }}</span>
                <span class="text-xs text-gray-500">
                    {{ $filters.newDayjs(ratingData.created_at).format('DD MMMM YYYY - HH:mm') }}
                </span>
            </p>
        </div>
        <div class="flex flex-col space-y-4 py-4 px-6">
            <div class="flex flex-row">
                <svg
                v-for="index in 5"
                :key="index"
                :class="[
                    'h-4 w-4 shrink-0 fill-current',
                    (ratingData.rating && ratingData.rating >= index)
                    ? 'text-amber-400'
                    : 'text-slate-300'
                ]"
                >
                    <path
                    d="M8 0L6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934h-6L8 0z"
                    />
                </svg>
            </div>
            <p class="line-clamp-6 text-justify">{{ ratingData.review }}</p>
        </div>
    </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'

import StarRating from '@/components/StarRating.vue'
import ImageLoader from '@/components/image/ImageLoader.vue'

export default {
    name: 'RatingItemComponent',
    components: {
        StarRating,
        ImageLoader,
    },
    mixins: [globalMixin],
    props: {
        ratingData: {
            type: Object,
            required: true,
        },
    },
}
</script>
