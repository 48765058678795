<template>
  <DOCIcon
    v-if="['doc', 'docx'].includes(fileType.toLowerCase())"
    :class="iconClass"
  />
  <JPGIcon
    v-else-if="['jpg', 'jpeg'].includes(fileType.toLowerCase())"
    :class="iconClass"
  />
  <PDFIcon
    v-else-if="['pdf'].includes(fileType.toLowerCase())"
    :class="iconClass"
  />
  <PNGIcon
    v-else-if="['png'].includes(fileType.toLowerCase())"
    :class="iconClass"
  />
  <XLSIcon
    v-else-if="['xls', 'xlsx'].includes(fileType.toLowerCase())"
    :class="iconClass"
  />
  <OtherIcon v-else :class="iconClass" />
</template>
<script>
import DOCIcon from '@/assets/svg/file/doc.svg'
import JPGIcon from '@/assets/svg/file/jpg.svg'
import OtherIcon from '@/assets/svg/file/other.svg'
import PDFIcon from '@/assets/svg/file/pdf.svg'
import PNGIcon from '@/assets/svg/file/png.svg'
import XLSIcon from '@/assets/svg/file/xlsx.svg'

export default {
  name: 'FileTypeIconComponents',
  components: {
    DOCIcon,
    JPGIcon,
    OtherIcon,
    PDFIcon,
    PNGIcon,
    XLSIcon,
  },
  props: {
    fileType: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      default: '',
    },
  },
}
</script>
