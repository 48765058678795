<template>
    <div class="flex h-screen overflow-hidden">
        <!-- Sidebar -->
        <Sidebar :sidebar-open="sidebarOpen" @close-sidebar="sidebarOpen = false" :get-the-case="true" />

        <!-- Content area -->
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden pb-40 scroll-smooth" id="chatContainer">
            <div class="sticky border-b top-0 z-10 flex w-full justify-between bg-white py-4 px-4">
                <div class="flex w-full cursor-pointer flex-row items-center" @click="
                    $router.push({
                        name: 'chat-group',
                        params: {
                            slug: $route.params.slug,
                        },
                        query: {
                            name: $route.query.name,
                            status: $route.query.status,
                            sub_status: $route.query.sub_status,
                            lang: $route.query.lang,
                        },
                    })
                    ">
                    <svg class="h-7 w-7 fill-current text-slate-500" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
                    </svg>
                    <h1 v-if="activeChat.title" class="pl-5 text-2xl font-bold text-slate-800">
                        {{ activeChat.title }}
                    </h1>
                    <h1 v-else-if="!isLoading" class="pl-5 text-2xl font-bold text-slate-800">
                        {{ $t('chat.discussion.title') }}
                    </h1>
                    <p v-if="activeChat.closed_at !== null && !isLoading" class="self-center pl-5 font-bold text-red-500">
                        {{ $t('chat.group.closed') }}
                    </p>
                </div>
                <div class="flex w-full justify-end space-x-4">
                    <Avatars :extract-user="true" :parties="chatMembers" tooltip-position="bottom" />
                    <span class="flex cursor-pointer items-center" @click="showSearch = !showSearch">
                        <SearchIcon class="h-5 w-5" />
                    </span>
                    <div>
                        <EditMenu align="right" class="relative ml-2 inline-flex">
                            <li>
                                <span
                                    class="flex cursor-pointer py-1 px-3 text-sm font-medium text-slate-600 hover:text-slate-800"
                                    @click.stop="conversationInfo()">
                                    {{ $t('about') }}
                                </span>
                            </li>
                        </EditMenu>
                    </div>
                    <div v-if="showSearch" class="z-60 absolute top-16 right-16">
                        <div class="relative text-gray-600 focus-within:text-gray-400">
                            <span class="absolute inset-y-0 left-0 flex items-center">
                                <button type="submit" class="focus:shadow-outline focus:outline-none">
                                    <SearchIcon />
                                </button>
                            </span>
                            <input v-model="search" type="search"
                                class="rounded-lg border border-gray-300 bg-white py-2 pl-10 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                :placeholder="$t('chat.discussion.searchForChat')" autocomplete="off" @keyup="searchChat" />
                        </div>
                    </div>
                </div>
            </div>
            <main>
                <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">

                    <DualRingLoader v-if="isLoading" />
                    <section v-else-if="chatData.length > 0">

                        <ChatDiscussionItem
                        v-for="chat in getChatDataByValidateCurrentPart"
                        :key="chat.id"
                        :chat="chat"
                        :currentTime="currentTime"
                        :me="$store.getters['auth/getCurrentUser']"
                        class="mt-2 rounded-lg bg-white px-6 shadow-sm"
                        @reject="rejectOffer"
                        @accept="acceptOffer"
                        @view-agreement="viewAgreement"
                        />

                        <div v-if="caseCanBeResolved && activeChat.closed_at === null" class="
                            text-sm bg-green-100 border-2 border-green-100 border-2
                            border-green-200 rounded-lg p-4 text-green-700
                        ">
                            {{ $t('caseStage.chats.makeOffersUnabled.reasons.canBeResolved') }}
                            <span v-if="canResolveCase">
                                <router-link class="font-bold text-sky-500 hover:text-sky-600 transition-all"
                                    :to="{ name: 'case-stage' }">
                                    {{ $t('caseStage.chats.doFromHere') }}
                                </router-link>
                            </span>
                        </div>
                    </section>
                    <NoData v-else class="self-center" />
                    <div v-if="activeChat.closed_at === null" class="fixed bottom-0 right-1/4 z-10 mr-10">
                        <div class="mb-10 flex w-full justify-end">

                            <FloatingNewChat v-if="!isConcludedMode" :show-make-offer="showNewOfferAction"
                                :me="$store.getters['auth/getCurrentUser']" @new-chat="newChat" @make-offer="makeOffer"
                                :isa-valid-part-in-case="isAValidPartInCase($store.getters['auth/getCurrentUser'])" />

                        </div>
                    </div>

                    <div class="text-sm bg-yellow-100 border-2 border-yellow-200 rounded-lg p-4 text-yellow-700"
                        v-if="activeChat.closed_at && activeChat.closed_reason_trans_ref">
                        {{ $t(...getParsedReferenceTranslate(activeChat.closed_reason_trans_ref)) }}
                    </div>

                </div>
            </main>
        </div>

        <ModalChatInfo
        :is-open="infoModalOpen"
        :active-chat="activeChat"
        :active-user="$store.getters['auth/getCurrentUser']"
        @closed="infoModalOpen = false"
        @close-chat="closeChatAPI"
        @show-media="mediaModalOpen = true"
        />

        <ModalChatMedia
        :is-open="mediaModalOpen"
        :documents="allChatRoomDocuments"
        @closed="mediaModalOpen = false"
        />

        <ModalMakeOffer :is-open="makeOfferModalOpen" :chat-members="chatMembers" :is-loading="isModalLoading"
            :titleModal="$t('caseStage.makeOffer')" @empty-member="emptyMember('selectOfferTo')"
            @closed="makeOfferModalOpen = false" @save="createOffer" />

        <ModalConfirmation :is-open="acceptOfferModalConfirmOpen" :title="$t('chat.discussion.confirmAcceptance.title')"
            :description="getConfirmAcceptDescription" @closed="acceptOfferModalConfirmOpen = false"
            @confirmed="acceptOfferAction" />

        <ModalRejectOffer :id="activeDiscussionItem.id" :is-open="rejectOfferModalOpen"
            :title="$t('chat.discussion.rejectOffer')" :is-loading="isModalLoading" @reject="rejectOfferAction"
            @closed="rejectOfferModalOpen = false" />

        <ModalNewChat :is-open="chatModalOpen" :date="formatDateDay(new Date())" @closed="chatModalOpen = false"
            @new-chat="createChat" @edit-chat="updateChat" @delete-chat="deleteChat" />

        <RightSidebar :sidebar-open="sidebarOpen" @close-sidebar="sidebarOpen = false" />

        <PdfEmbed :is-visible="currentPdf !== null" :source="'data:application/pdf;base64,' + currentPdf"
            @close="closePdfEmbed" />

    </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import chatMixin from '@/mixins/chat.js'

import Sidebar from '@/partials/Sidebar.vue'
import RightSidebar from '@/partials/RightSidebar.vue'
import Avatars from '@/components/Avatars.vue'
import FloatingNewChat from '@/components/FloatingNewChat.vue'
import ModalMakeOffer from '@/components/ModalMakeOffer.vue'
import ModalRejectOffer from '@/components/ModalRejectOffer.vue'
import ModalNewChat from '@/components/ModalNewChat.vue'
import ModalChatInfo from '@/components/ModalChatInfo.vue'
import ModalChatMedia from '@/components/ModalChatMedia.vue'
import ModalConfirmation from '@/components/ModalConfirmation.vue'
import ChatDiscussionItem from '@/components/ChatDiscussionItem.vue'
import EditMenu from '@/components/DropdownEditMenu.vue'
import NoData from '@/components/NoData.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'

import SearchIcon from '@/assets/svg/search.svg'
import PdfEmbed from '@/components/PdfEmbed.vue'
import { times } from 'lodash'

export default {
    name: 'ChatDiscussion',
    components: {
        Sidebar,
        RightSidebar,
        Avatars,
        FloatingNewChat,
        ModalMakeOffer,
        ModalRejectOffer,
        ModalNewChat,
        ModalChatInfo,
        ModalChatMedia,
        ChatDiscussionItem,
        EditMenu,
        NoData,
        DualRingLoader,
        ModalConfirmation,
        SearchIcon,
        PdfEmbed,
    },
    mixins: [globalMixin, chatMixin],
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()
        const sidebarOpen = ref(false)
        const chatDiscussionLoaded = ref(false)
        const activeDiscussionItem = ref({})

        return {
            t,
            n,
            store,
            toast,
            sidebarOpen,
            chatDiscussionLoaded,
            activeDiscussionItem,
        }
    },
    watch: {
        mediaModalOpen (val) {
            if (val) {
                this.getAllDocumentRoom()
            }
        }
    },
    created() {
        if (this.$echo) {
            this.$echo
                .channel("Chat-Case")
                .listen(".ChatMessageEvent", (event) => {
                    if (
                        event
                        &&
                        event.data
                        &&
                        event.data.chat_room_id
                        &&
                        (
                            event.data.emitted_by
                            ||
                            event.data.emitted_by == null
                        )
                        &&
                        event.data.chat_room_id == this.activeChat.id
                        &&
                        event.data.emitted_by != this.$store.getters['auth/getCurrentUser'].id
                        &&
                        this.isAValidPartInCase(this.$store.getters['auth/getCurrentUser'])
                    ) {
                        this.getDetailRoom(false).then(response => {
                            this.getAllChat({ id: this.activeChat.id, type: null }, false).then(responseAllChat => {
                                this.chatDiscussionLoaded = true;
                                // try {
                                // var audio = new Audio(
                                //     "../../src/assets/audio/notification-sound.mp3"
                                // );
                                // audio.play();
                                // } catch (error) {

                                // }
                            })
                        })
                    }
                });
        }
    },
    data: () => ({
        acceptOfferModalConfirmOpen: false,
        rejectOfferModalOpen: false,
        makeOfferModalOpen: false,
        mediaModalOpen: false,
        chatModalOpen: false,
        infoModalOpen: false,
        showSearch: false,
        search: '',
        currentTime: null,
        intervalID: null,
    }),
    mounted() {
        this.getDetailRoom().then(response => {
            this.getAllChat({ type: null }).then(responseAllChat => {
                this.chatDiscussionLoaded = true;
            })
        })

        this.intervalID = setInterval(() => {
            this.currentTime = new Date();
        }, 1000);
    },
    beforeUnmount () {
        clearInterval(this.intervalID);
    },
    methods: {
        conversationInfo() {
            this.infoModalOpen = true
        },
        newChat() {
            this.chatModalOpen = true
        },
        makeOffer() {
            this.makeOfferModalOpen = true
        },
        rejectOffer(events) {
            this.activeDiscussionItem = events
            this.rejectOfferModalOpen = true
        },
        rejectOfferAction(event) {
            this.chatId = event.id
            this.reason = event.reason
            this.accept = false
            this.offerActionAPI()
            this.rejectOfferModalOpen = false
        },
        acceptOffer(events) {
            this.activeDiscussionItem = events
            this.acceptOfferModalConfirmOpen = true
        },
        acceptOfferAction() {
            this.chatId = this.activeDiscussionItem.id
            this.reason = ''
            this.accept = true
            this.offerActionAPI()
            this.acceptOfferModalConfirmOpen = false
        },
        saveOffer() {
            console.log('save offer')
        },
        createOffer(events) {
            this.createChatAPI(events)
        },
        createChat(events) {
            this.createChatAPI(events)
        },
        searchChat(e) {
            if (e.keyCode === 13) {
                this.getAllChat({ type: null, q: this.search })
            }
            if (e.keyCode === 27) {
                this.showSearch = false
            }
        },
        updateChat() {
            console.log('update chat')
        },
        deleteChat() {
            console.log('delet chat')
        },
        viewAgreement(events) {
            this.getDocumentAgreement(events)
        }
    },
    computed: {
        showNewOfferAction() {
            const lastsOffers = this.chatData
                .filter(item => item.type === 'offer')
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

            const hasPermissionByStage = {
                '2': this.$methods.hasPermissions(['cases_manage_case_stage_stages_negotiation_offers_make']),
                '3': this.$methods.hasPermissions(['cases_manage_case_stage_stages_mediation_offers_make']),
                '4': this.$methods.hasPermissions(['cases_manage_case_stage_stages_arbitration_offers_make'])
            };

            return this.chatDiscussionLoaded
                &&
                !this.activeChat.private_chat
                &&
                hasPermissionByStage[this.activeChat.stage]
                &&
                (
                    (
                        lastsOffers.length > 0
                        &&
                        (
                            (
                                lastsOffers[0].chat_offers.every(elem => elem.is_accept !== null)
                                &&
                                !lastsOffers[0].chat_offers.every(elem => elem.is_accept === true)
                            )
                            ||
                            new Date(lastsOffers[0].deadline_at) < this.currentTime
                        )
                    )
                    ||
                    (
                        this.activeChat.stage == 2
                        &&
                        lastsOffers.length === 0
                        &&
                        this.$methods.hasPermissions(['cases_manage_case_stage_stages_negotiation_offers_response_claim'])
                    )
                    ||
                    (
                        this.activeChat.stage == 3
                        &&
                        lastsOffers.length === 0
                        &&
                        this.$methods.hasPermissions(['cases_manage_case_stage_stages_mediation_offers_make_first'])
                    )
                    ||
                    this.activeChat.stage == 4
                );
        },
        isMeLastPersonAccept() {
            return this.activeDiscussionItem
                &&
                this.activeDiscussionItem.chat_offers
                &&
                this.activeDiscussionItem.chat_offers.filter(item => item.is_accept == null).length === 1
                &&
                this.activeDiscussionItem.chat_offers.find(item => item.is_accept == null).user.id === this.$store.getters['auth/getCurrentUser'].id
        },
        getConfirmAcceptDescription() {
            if (this.activeDiscussionItem.has_agreement) {
                return this.$t('chat.discussion.confirmAcceptance.agreement.description')
            }
        },
        caseCanBeResolved() {
            const lastsOffers = this.chatData
                .filter(item => item.type === 'offer')
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

            return lastsOffers.length > 0 && lastsOffers[0].chat_offers.length && lastsOffers[0].chat_offers.every(elem => elem.is_accept === true)
        },
        canResolveCase() {
            const validation = (
                this.activeChat.stage == 2
                &&
                this.$methods.hasPermissions(['cases_manage_case_stage_stages_negotiation_resolve'])
            )
                ||
                (
                    this.activeChat.stage == 3
                    &&
                    this.$methods.hasPermissions(['cases_manage_case_stage_stages_mediation_resolve'])
                );

            return validation;
        },
        getChatDataByValidateCurrentPart() {
            let data = [];
            if (!this.isAValidPartInCase(this.$store.getters['auth/getCurrentUser'])) {
                const lastReassigned = this.$store.getters['auth/getCurrentUser'].case_parties.filter(item => item.was_reassigned)
                    .sort((a, b) => new Date(b.reassigned_at) - new Date(a.reassigned_at))
                if (lastReassigned.length) {
                    data = this.chatData.filter(
                        item => new Date(item.created_at) < new Date(lastReassigned[0].reassigned_at)
                    );
                }
            } else {
                data = this.chatData;
            }
            return data;
        },
    },
}
</script>
