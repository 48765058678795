<template>
    <div style="min-height: 550px;" class="grow">
        <!-- Panel body -->
        <div v-if="!isLoading">
            <div class="space-y-4 p-6">
                <h2 class="text-2xl font-bold text-slate-800">
                    {{ $t('profile.editProfile') }}
                </h2>
                <p class="text-slate-800">
                    {{ $t('profile.editProfileDesc') }}
                </p>
            </div>

            <!-- Change Password Form -->
            <Form
                v-if="passwordChange"
                ref="form"
                v-slot="{ errors }"
                :validation-schema="changePasswordSchema"
                @submit="updatePassword"
            >
                <div class="space-y-4 p-6">
                    <div>
                        <label
                            class="mb-1 block text-sm font-medium"
                            for="currentPassword"
                        >
                            {{ $t('formLabel.currentPassword') }}
                            <span class="text-rose-500">*</span></label
                        >
                        <Field
                            v-slot="{ field }"
                            v-model="currentPassword"
                            name="currentPassword"
                        >
                            <div class="relative">
                                <input
                                    id="currentPassword"
                                    v-bind="field"
                                    autocomplete="new-password"
                                    class="form-input h-12 w-full rounded-md"
                                    :type="
                                        showCurrentPassword ? 'text' : 'password'
                                    "
                                    :placeholder="
                                        $t('formInput.enterCurrentPassword')
                                    "
                                />
                                <div
                                    class="absolute inset-0 left-auto flex cursor-pointer items-center"
                                >
                                    <span
                                        class="px-3 text-sm font-medium text-slate-400"
                                    >
                                        <EyeStrikeIcon
                                            v-if="showCurrentPassword"
                                            class="h-6 w-6"
                                            @click="showCurrentPassword = false"
                                        />
                                        <EyeIcon
                                            v-else
                                            class="h-6 w-6"
                                            @click="showCurrentPassword = true"
                                        />
                                    </span>
                                </div>
                            </div>
                        </Field>
                        <span class="text-sm text-red-500">
                            <template v-if="errors.currentPassword !== undefined">
                                {{ $t(errors.currentPassword) }}
                            </template>
                        </span>
                    </div>
                    <div>
                        <label
                            class="mb-1 block text-sm font-medium"
                            for="password"
                        >
                            {{ $t('formLabel.newPassword') }}
                            <span class="text-rose-500">*</span></label
                        >
                        <Field
                            v-slot="{ field }"
                            v-model="password"
                            name="password"
                        >
                            <div class="relative">
                                <input
                                    id="password"
                                    v-bind="field"
                                    autocomplete="new-password"
                                    class="form-input h-12 w-full rounded-md"
                                    :type="showPassword ? 'text' : 'password'"
                                    :placeholder="$t('formInput.enterNewPassword')"
                                />
                                <div
                                    class="absolute inset-0 left-auto flex cursor-pointer items-center"
                                >
                                    <span
                                        class="px-3 text-sm font-medium text-slate-400"
                                    >
                                        <EyeStrikeIcon
                                            v-if="showPassword"
                                            class="h-6 w-6"
                                            @click="showPassword = false"
                                        />
                                        <EyeIcon
                                            v-else
                                            class="h-6 w-6"
                                            @click="showPassword = true"
                                        />
                                    </span>
                                </div>
                            </div>
                        </Field>
                        <span class="text-sm text-red-500">
                            <template v-if="errors.password !== undefined">
                                {{ $t(errors.password) }}
                            </template>
                        </span>
                    </div>
                    <div>
                        <label
                            class="mb-1 block text-sm font-medium"
                            for="confirmPassword"
                        >
                            {{ $t('formLabel.newPasswordConfirmation') }}
                            <span class="text-rose-500">*</span></label
                        >
                        <Field
                            v-slot="{ field }"
                            v-model="confirmPassword"
                            name="confirmPassword"
                        >
                            <div class="relative">
                                <input
                                    id="confirmPassword"
                                    v-bind="field"
                                    class="form-input h-12 w-full rounded-md"
                                    autocomplete="new-password"
                                    :type="
                                        showConfirmPassword ? 'text' : 'password'
                                    "
                                    :placeholder="
                                        $t('formInput.enterNewConfirmPassword')
                                    "
                                    required="true"
                                />
                                <div
                                    class="absolute inset-0 left-auto flex cursor-pointer items-center"
                                >
                                    <span
                                        class="px-3 text-sm font-medium text-slate-400"
                                    >
                                        <EyeStrikeIcon
                                            v-if="showConfirmPassword"
                                            class="h-6 w-6"
                                            @click="showConfirmPassword = false"
                                        />
                                        <EyeIcon
                                            v-else
                                            class="h-6 w-6"
                                            @click="showConfirmPassword = true"
                                        />
                                    </span>
                                </div>
                            </div>
                        </Field>
                        <span class="text-sm text-red-500">
                            <template v-if="errors.confirmPassword !== undefined">
                                {{ $t(errors.confirmPassword) }}
                            </template>
                        </span>
                    </div>
                </div>
                <footer>
                    <div class="flex flex-col px-6 py-5">
                        <div class="flex space-x-4 self-start">
                            <button
                                class="btn rounded-lg border-red-500 text-red-500 hover:bg-red-600 hover:text-white"
                                @click="passwordChange = false"
                            >
                                {{ $t('button.cancel') }}
                            </button>
                            <button
                                class="btn rounded-lg bg-sky-500 text-white hover:bg-sky-600"
                                type="submit"
                            >
                                {{ $t('button.saveChanges') }}
                            </button>
                        </div>
                    </div>
                </footer>
            </Form>
            <!-- End of Change Password Form -->

            <!-- Change profile Form -->
            <Form
                v-else
                ref="form"
                v-slot="{ errors }"
                :validation-schema="updateProfileSchema"
                @submit="updateProfile"
            >
                <div class="space-y-4 p-6">
                    <div class="flex justify-between">
                        <section class="w-4/6 space-y-5 border-r">

                            <div class="pr-4 lg:pr-24 flex flex-col gap-3">
                                <div class="grid grid-cols-1 md:grid-cols-2 gap-3 items-end">
                                    <div v-if="profileData && profileData.identification_type">
                                        <label
                                            class="mb-1 block text-sm font-medium"
                                            for="firstName"
                                        >
                                            {{ $t('profile.identificationType') }}
                                        </label>

                                        <input
                                        id="firstName"
                                        v-model="profileData.identification_type.name"
                                        class="form-input w-full bg-gray-200"
                                        disabled
                                        type="text"
                                        />
                                    </div>
                                    <div>
                                        <label
                                            class="mb-1 block text-sm font-medium"
                                            for="firstName"
                                        >
                                            {{ $t('profile.identification') }}
                                        </label>

                                        <input
                                        id="firstName"
                                        v-model="profileData.identification"
                                        class="form-input w-full bg-gray-200"
                                        disabled
                                        type="text"
                                        />
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 gap-3 items-end">
                                    <div>
                                        <label
                                            class="mb-1 block text-sm font-medium"
                                            for="username"
                                        >
                                            {{ $t('profile.username') }}
                                        </label>
                                        <Field
                                            v-slot="{ field }"
                                            v-model="username"
                                            name="username"
                                        >
                                            <input
                                                id="username"
                                                v-bind="field"
                                                class="form-input w-full"
                                                type="text"
                                            />
                                        </Field>
                                        <span class="text-sm text-red-500">
                                            <template
                                                v-if="errors.username !== undefined"
                                            >
                                                {{ $t(errors.username) }}
                                            </template>
                                        </span>
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 md:grid-cols-2 gap-3 items-end">
                                    <div>
                                        <label
                                            class="mb-1 block text-sm font-medium"
                                            for="firstName"
                                        >
                                            {{ $t('profile.firstName') }}
                                        </label>

                                        <Field
                                            v-slot="{ field }"
                                            v-model="firstName"
                                            name="firstName"
                                        >
                                            <input
                                                id="firstName"
                                                v-bind="field"
                                                class="form-input w-full"
                                                type="text"
                                            />
                                        </Field>
                                        <span class="text-sm text-red-500">
                                            <template
                                                v-if="
                                                    errors.firstName !== undefined
                                                "
                                            >
                                                {{ $t(errors.firstName) }}
                                            </template>
                                        </span>
                                    </div>
                                    <div>
                                        <label
                                            class="mb-1 block text-sm font-medium"
                                            for="lastName"
                                        >
                                            {{ $t('profile.lastName') }}
                                        </label>
                                        <Field
                                            v-slot="{ field }"
                                            v-model="lastName"
                                            name="lastName"
                                        >
                                            <input
                                                id="lastName"
                                                v-bind="field"
                                                class="form-input w-full"
                                                type="text"
                                            />
                                        </Field>
                                        <span class="text-sm text-red-500">
                                            <template
                                                v-if="errors.lastName !== undefined"
                                            >
                                                {{ $t(errors.lastName) }}
                                            </template>
                                        </span>
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 md:grid-cols-2 gap-3 items-end">
                                    <div>
                                        <label
                                            class="mb-1 block text-sm font-medium"
                                            for="email"
                                        >
                                            {{ $t('profile.email') }}
                                        </label>
                                        <Field
                                            v-slot="{ field }"
                                            v-model="email"
                                            name="email"
                                        >
                                            <input
                                                id="email"
                                                v-bind="field"
                                                class="form-input w-full"
                                                type="email"
                                            />
                                        </Field>
                                        <span class="text-sm text-red-500">
                                            <template
                                                v-if="errors.email !== undefined"
                                            >
                                                {{ $t(errors.email) }}
                                            </template>
                                        </span>
                                    </div>
                                    <div>
                                        <label
                                            class="mb-1 block text-sm font-medium"
                                            for="phoneNumber"
                                        >
                                            {{ $t('profile.phoneNumber') }}
                                        </label>
                                        <Field
                                            v-slot="{ field }"
                                            v-model="phoneNumber"
                                            name="phoneNumber"
                                        >
                                            <input
                                                id="phoneNumber"
                                                class="form-input w-full"
                                                type="text"
                                                v-bind="field"
                                                :placeholder="
                                                    $t('formInput.enterPhone')
                                                "
                                            />
                                        </Field>
                                        <span class="text-sm text-red-500">
                                            <template
                                                v-if="
                                                    errors.phoneNumber !== undefined
                                                "
                                            >
                                                {{ $t(errors.phoneNumber) }}
                                            </template>
                                        </span>
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 gap-3 items-end">
                                    <!-- Password -->
                                    <section class="space-y-2">
                                        <div class="text-sm font-medium">Password</div>
                                        <p class="flex items-center space-x-2 self-center">
                                            <span>********</span>
                                            <span
                                                class="cursor-pointer text-sky-500 hover:text-sky-600"
                                                @click="passwordChange = true"
                                            >
                                                {{ $t('button.change') }}
                                            </span>
                                        </p>
                                    </section>
                                </div>
                            </div>

                            <section class="pr-6 flex flex-col gap-3">
                                <div class="flex justify-between items-center gap-2">
                                    <div class="flex gap-2 items-center">
                                        <span class="text-sm font-medium">
                                            {{ $t('profile.otp.title') }}
                                        </span>
                                        <div
                                        :class="[
                                            'text-xs font-bold px-2 flex items-center rounded-lg',
                                            (profileData.is_active_otp) ? 'bg-green-200' : 'bg-yellow-200'
                                        ]">
                                            {{ (profileData.is_active_otp) ? 'On' : 'Off' }}
                                        </div>
                                    </div>
                                    <button
                                    @click="openModalOTP(true)"
                                    class="btn bg-yellow-300 min-w-[7rem] hover:bg-yellow-400"
                                    v-if="!profileData.is_active_otp"
                                    >
                                        {{ $t('button.enable') }}
                                    </button>
                                    <button
                                    class="btn bg-red-300 min-w-[7rem] hover:bg-red-400"
                                    @click="openModalOTP(false)"
                                    v-if="profileData.is_active_otp"
                                    >
                                        {{ $t('button.disable') }}
                                    </button>
                                </div>

                                <div
                                :class="[
                                    'p-3 rounded-md flex items-center gap-3',
                                    (profileData.is_active_otp) ? 'bg-green-100 text-green-600' : 'bg-yellow-100 text-yellow-600'
                                ]">
                                    <i v-if="profileData.is_active_otp" class="fas fa-info-circle text-lg"></i>
                                    <i v-if="!profileData.is_active_otp" class="fas fa-exclamation-triangle text-lg"></i>
                                    <p class="text-sm">
                                        {{ $t('profile.otp.alerts.info') }}
                                        {{ (!profileData.is_active_otp) ? $t('profile.otp.alerts.off') : '' }}
                                    </p>
                                </div>
                            </section>
                        </section>

                        <section class="w-2/6">
                            <div class="flex flex-col items-center space-y-2">
                                <div>
                                    <ImageLoader
                                        wrapper-class="h-32 w-32 rounded-full"
                                        :src="profileData.avatar"
                                        width="50"
                                        height="50"
                                        :alternate="profileData.first_name"
                                    />
                                </div>
                                <button
                                    class="btn border-sky-500 text-sky-500 hover:bg-sky-600 hover:text-white"
                                    @click.stop.prevent="$refs.uploadpic.click()"
                                >
                                    {{ $t('profile.uploadPicture') }}
                                </button>
                                <input
                                    ref="uploadpic"
                                    type="file"
                                    class="hidden"
                                    name="uploadpic"
                                    @change="fileAvatarChange"
                                />
                                <p class="text-xs">
                                    *{{ $t('profile.maximumSize') }} : 2Mb
                                </p>
                                <p class="text-xs">
                                    *{{ $t('profile.fileType') }} : .jpeg, .png
                                </p>
                            </div>
                        </section>
                    </div>
                </div>
                <footer>
                    <div class="flex flex-col px-6 py-5">
                        <div class="flex self-start">
                            <button
                                class="btn rounded-lg bg-sky-500 text-white hover:bg-sky-600"
                                type="submit"
                            >
                                {{ $t('button.saveChanges') }}
                            </button>
                        </div>
                    </div>
                </footer>
            </Form>
        </div>
        <!-- End of Change profile Form -->
        <DualRingLoader class="h-full items-center" v-if="isLoading" />

        <ModalBasic
        id="otp-modal"
        :modal-open="isOpenModalOTP"
        :show-border-header="false"
        :title="$t('profile.otp.modal.title', { action: getActionToDoOTP('title') })"
        :subtitle="$t('profile.otp.modal.description', { action: getActionToDoOTP('description') })"
        :backdrop="true"
        @close-modal="closeModalOTP"
        >
            <Form
            ref="formOTP"
            v-slot="{ errors }"
            :validation-schema="actionOTPSchema"
            @submit="actionOTP"
            >
                <div class="px-5 flex flex-col gap-3">
                    <div>
                        <label class="mb-1 block text-xs font-bold" for="subject">
                            {{ $t('profile.otp.modal.password.label') }}
                        </label>

                        <Field
                        v-slot="{ field }"
                        v-model="actionOTPPassword"
                        name="actionOTPPassword">
                            <input
                            v-bind="field"
                            class="form-input w-full h-9 p-2"
                            type="password"
                            :placeholder="$t('profile.otp.modal.password.placeholder')"
                            required
                            />
                        </Field>

                        <div v-if="errors.actionOTPPassword !== undefined" class="flex w-full">
                            <span class="text-sm text-red-500">
                                {{ $t(errors.actionOTPPassword) }}
                            </span>
                        </div>
                    </div>
                    <div>
                        <label class="mb-1 block text-xs font-bold" for="subject">
                            {{ $t('profile.otp.modal.method.label') }}
                        </label>

                        <Field
                        v-slot="{ field }"
                        v-model="actionOTPType"
                        name="actionOTPType">
                            <select
                            v-bind="field"
                            class="form-input w-full">
                                <option
                                v-for="(method, index) in ['email', 'phone']"
                                :key="index"
                                :value="method">
                                    {{ method.substring(0, 1).toUpperCase() + method.substring(1) }}
                                </option>
                            </select>
                        </Field>

                        <div v-if="errors.actionOTPType !== undefined" class="flex w-full">
                            <span class="text-sm text-red-500">
                                {{ $t(errors.actionOTPType) }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="py-4">
                    <div class="flex flex-wrap justify-center space-x-2 px-4">
                        <button
                        :class="{
                            'cursor-not-allowed': isLoadingActionOTP
                        }"
                        type="submit"
                        class="btn-sm w-full whitespace-nowrap rounded-lg bg-sky-500 p-3 text-white hover:bg-sky-600">
                            <span v-if="isLoadingActionOTP">
                                {{ $t('general.loading') }}
                            </span>
                            <span v-else>
                                {{ $t('button.accept') }}
                            </span>
                        </button>
                    </div>
                </div>
            </Form>
        </ModalBasic>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixins from '@/mixins/global.js'
import preventRefreshMixin from '@/mixins/prevent-refresh.js'
import profileMixins from '@/mixins/profile.js'
import { Form, Field } from 'vee-validate'
import DualRingLoader from '@/components/DualRingLoader.vue'

import ImageLoader from '@/components/image/ImageLoader.vue'

import EyeIcon from '@/assets/svg/eye.svg'
import EyeStrikeIcon from '@/assets/svg/eye-strike.svg'
import * as Yup from 'yup'
import ModalBasic from '@/components/ModalBasic.vue'

export default {
    name: 'AccountPanel',
    components: {
        ImageLoader,
        Form,
        Field,
        EyeIcon,
        EyeStrikeIcon,
        DualRingLoader,
        ModalBasic,
    },
    mixins: [globalMixins, profileMixins, preventRefreshMixin],
    setup() {
        const store = useStore()
        const toast = useToast()

        return {
            store,
            toast,
        }
    },
    watch: {
        passwordChange: function (val) {
            if (!val) {
                this.currentPassword = ''
                this.password = ''
                this.confirmPassword = ''
            }
        }
    },
    data () {
        return {
            isOpenModalOTP: false,
            actionOTPSchema: Yup.object().shape({
                actionOTPPassword: Yup.string()
                    .typeError('formInput.enterPassword')
                    .required('formInput.enterPassword')
                    .test(
                        'password-correct',
                        'validation.passwordIncorrect',
                        this.checkPassword
                    ),
            }),
            actionOTPPassword: null,
            actionOTPType: 'email',
            isLoadingActionOTP: false,
        }
    },
    mounted () {
        this.getProfile()
    },
    methods: {
        closeModalOTP () {
            this.isOpenModalOTP = false
        },
        openModalOTP (enable) {
            this.isOpenModalOTP = true
        },
        actionOTP () {
            if (this.isLoadingActionOTP) return;
            this.isLoadingActionOTP = true;
            this.store.dispatch('auth/actionOTP', {
                is_active_otp: !this.profileData.is_active_otp,
                password: this.actionOTPPassword,
                otp_type: this.actionOTPType
            }).then(response => {
                this.isLoadingActionOTP = false
                if (response) {
                    this.toast.success((!this.profileData.is_active_otp)
                        ? this.$t('profile.otp.modal.success', { action: this.$t('general.enabled').toLowerCase() })
                        : this.$t('profile.otp.modal.success', { action: this.$t('general.disabled').toLowerCase() }))
                    this.getProfile()
                    this.actionOTPPassword = null;
                    this.isOpenModalOTP = false
                }
            }).catch(error => {
                this.isLoadingActionOTP = false
                // console.log(error)
            })
        },
    },
    computed: {
        getActionToDoOTP () {
            return (type) => {
                const actionsLabel = {
                    title: {
                        enable: this.$t('button.enable'),
                        disable: this.$t('button.disable'),
                    },
                    description: {
                        enable: this.$t('button.enable').toLowerCase(),
                        disable: this.$t('button.disable').toLowerCase(),
                    },
                }
                const action = !this.profileData.is_active_otp ? 'enable' : 'disable'
                return actionsLabel[type][action]
            }
        },
    },
}
</script>
