import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'
import { decrypt, toBase64 } from '../../utils/Utils.js'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
    // Chat Room
    getAllRoom({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {}
            if (payload.page) params.page = payload.page
            if (payload.take) params.per_page = payload.take
            if (payload.order) params.order = 'created_at.' + payload.order
            if (payload.is_initial) params.query = 'is_initial.1'

            axios
                .get(`${routePathAPI()}/chatrooms/byCase/${payload.case_id}`, {
                    params: params,
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    const responseData = response.data
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getCurrentStageChat({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            axios
                .get(`${routePathAPI()}/chatrooms/getCurrentStageChat/byCase/${payload.case_id}`, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    const responseData = response.data
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getDetailRoom({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            axios
                .get(`${routePathAPI()}/chatrooms/${payload.id}`, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    const responseData = response.data
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAllDocumentRoom({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            axios
                .get(`${routePathAPI()}/chatrooms/documents/${payload.id}`, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    const responseData = response.data
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // Chat Discussion
    getAllChat({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            let params = {}
            if (payload.page) params.page = payload.page
            if (payload.take) params.per_page = payload.take
            if (payload.order) params.order = 'created_at.' + payload.order
            if (payload.type) params.query = 'type.' + payload.type
            if (payload.q) params.search = payload.q

            axios.get(`${routePathAPI()}/chatdiscussions/byChatroom/${payload.room_id}`, {
                params: params,
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            })
            .then(async (response) => {
                const responseData = response.data
                if (response.status === 200) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: responseData,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: responseData,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    createChat({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            let newPayload = JSON.parse(JSON.stringify(payload))
            toBase64(payload.attachment).then(result => {
                newPayload.attachment = result
                    ? {
                        cont: result.split(',').pop(),
                        name: payload.attachment.name.split('.')[0],
                        ext: payload.attachment.name.split('.').pop(),
                        size: payload.attachment.size
                    }
                    : null
                if (payload.currency) newPayload.currency_id = payload.currency.id
                newPayload.case_id = isNaN(payload.case_id) ? decrypt(payload.case_id) : payload.case_id
                newPayload.chat_room_id = isNaN(payload.chat_room_id) ? decrypt(payload.chat_room_id) : payload.chat_room_id

                axios
                    .post(
                        `${routePathAPI()}/chatdiscussions`,
                        newPayload,
                    )
                    .then((response) => {
                        const responseData = response.data
                        if (response.status === 200 || response.status === 201) {
                            resolve({
                                status: response.status,
                                message: 'success',
                                data: responseData,
                            })
                        } else {
                            reject({
                                status: response.status,
                                message: 'failed',
                                data: responseData,
                            })
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })


        })
    },
    createChatPrivate({ state, commit }, payload) {
        const params = {}
        params.case_id = payload.case_id
        params.title = payload.title
        params.members = payload.members
        params.private_chat = true

        return new Promise((resolve, reject) => {
            axios
                .post(`${routePathAPI()}/chatrooms`, params)
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200 || response.status === 201) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    closeChat({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${routePathAPI()}/chat/room/private/close/${payload.room_id}`)
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200 && responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    chatOfferAction({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        const params = {}
        params.chat_id = payload.chat_id
        params.is_accept = payload.accept
        params.reason = payload.reason || ''
        params.case_id = payload.case_id
        params.room_id = payload.room_id

        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${routePathAPI()}/chatoffers/action`,
                    params,
                    {
                        headers: {
                            'x-language-code': currentLanguageCode,
                        },
                    }
                )
                .then((response) => {
                    const responseData = response.data
                    if (responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    responseClaim({ state, commit }, payload) {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        }
        let formData = new FormData()
        formData.append('title', payload.title)
        formData.append('content', payload.content)
        formData.append('request', payload.request)
        formData.append('amount', payload.amount)
        formData.append('currency', payload.currency)
        formData.append('deadline_at', payload.deadline_at)
        formData.append('attachment', payload.attachment)
        formData.append('party_ids', payload.party_ids)

        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${routePathAPI()}/chat/response/${payload.case_id}`,
                    formData,
                    config
                )
                .then((response) => {
                    const responseData = response.data
                    if (response.status === 200 && responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getLastOffer({ commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            axios
                .get(`${routePathAPI()}/chat/offer/last/${payload.roomId}`, {
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    const responseData = response.data
                    if (response.status === 200 && responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getDocumentAgreement({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${routePathAPI()}/chatdiscussions/getPdfAgreement/${payload.chat_id}/${payload.case_id}`,
                    {
                        headers: {
                            'x-language-code': currentLanguageCode,
                        },
                    }
                )
                .then((response) => {
                    const responseData = response.data
                    if (responseData.error === undefined) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: responseData,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: responseData,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}

// mutations
const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
