import dayjs from 'dayjs'

export default {
    install: (app, options) => {
        app.config.globalProperties.$filters = {
            formattedDate(value, dateFormat = 'DD MMMM YYYY') {
                if (!value) return ''
                const formattedDate = dayjs(value.replace('Z', '')).format(dateFormat)
                return formattedDate
            },
            timeFromNowToX (dateX) {
                return dayjs(dayjs()).to(dateX)
            },
            dateDiff (a, b, format = 'minute') {
                return dayjs(b).diff(a, format)
            },
            diffDateFromNow(date, type = 'day') {
                const dateTo = dayjs()
                const dateFrom = dayjs(date)
                return dateFrom.diff(dateTo, type)
            },
            newDayjs(value = null) {
                if (value) return dayjs(value)
                else dayjs()
            }
        };
    },
};
