<template>
    <div>
        <router-link
        v-for="(item, index) in pendingActions"
        class="mt-4 flex w-full cursor-pointer items-center justify-center rounded-md bg-yellow-50 px-4 py-4 text-sm text-black"
        :to="(item.href) ? item.href : '/'"
        :key="index"
        >
            <div class="flex h-10 w-12 flex-col items-center justify-center rounded-md bg-white">
                <div class="flex">
                    <span class="text-sky-500">
                        <PointerIcon class="h-7 w-7 shrink-0"/>
                    </span>
                </div>
            </div>
            <div class="ml-4 flex flex-col w-full">
                <span class="font-bold text-yellow-700">
                    {{
                        isAParsedReferenceTranslate(item.name)
                        ? $t(...getParsedReferenceTranslate(item.name))
                        : item.name
                    }}
                </span>
                <p class="text-sm">
                    {{
                        isAParsedReferenceTranslate(item.message)
                        ? $t(...getParsedReferenceTranslate(item.message))
                        : item.message
                    }}
                </p>
            </div>
        </router-link>
    </div>
</template>
<script>
import PointerIcon from '@/assets/svg/pointer.svg'
import globalMixin from '@/mixins/global.js'

export default {
    name: 'PendingActions',
    components: {
        PointerIcon,
    },
    mixins: [globalMixin],
    props: {
        pendingActions: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    data: function () {
        return {

        }
    }
}
</script>