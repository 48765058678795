<template>
    <div
        v-if="isShow"
        class="w-full items-center justify-center self-center px-2 pt-2 pb-4 text-left"
    >
        <div class="flex flex-wrap justify-center text-sm font-medium">
            <div
                class="flex cursor-pointer flex-col items-center justify-center space-y-2 self-center"
                :class="[
                    active === 1
                        ? 'text-sky-500'
                        : 'text-slate-500 hover:text-sky-500',
                ]"
                @click="toApplicationForm()"
            >
                <PaperTickIcon />
                <span>
                    {{ (isPrecreatedCase) ? $t('newCase.preCreated.applicationForm') : $t('case.applicationForm') }}
                </span>
            </div>
            <template v-if="isAssistance">
                <div class="flex w-1/12 flex-col items-center">
                    <div class="mt-4 w-full border border-slate-500"></div>
                </div>
                <div
                    class="flex flex-col items-center justify-center space-y-2 self-center"
                    :class="[
                        active === 2 ? 'text-sky-500' : 'text-slate-500',
                        activeBreadcrumb >= 2
                            ? 'cursor-pointer hover:text-sky-500'
                            : 'cursor-not-allowed',
                    ]"
                    @click="toAdvocateSelection()"
                >
                    <ThreeUserTickIcon />
                    <span>
                        {{ $t('case.advocateSelection') }}
                    </span>
                </div>
            </template>
            <div
            v-if="showReviewApplication"
            class="flex w-1/12 flex-col items-center">
                <div class="mt-4 w-full border border-slate-500"></div>
            </div>
            <div
            v-if="showReviewApplication"
                class="flex cursor-pointer flex-col items-center justify-center space-y-2 self-center"
                :class="[
                    active === 3 ? 'text-sky-500' : 'text-slate-500 ',
                    activeBreadcrumb >= 3
                        ? 'cursor-pointer hover:text-sky-500'
                        : 'cursor-not-allowed',
                ]"
                @click="toReviewApplication()"
            >
                <DoneTickIcon />
                <span>
                    {{ $t('case.reviewApplication') }}
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import PaperTickIcon from '@/assets/svg/paper-tick.svg'
import ThreeUserTickIcon from '@/assets/svg/three-user-tick.svg'
import DoneTickIcon from '@/assets/svg/done-tick.svg'

export default {
    name: 'BreadcrumbNewCase',
    components: {
        PaperTickIcon,
        ThreeUserTickIcon,
        DoneTickIcon,
    },
    props: {
        active: {
            type: Number,
            required: true,
        },
        isShow: {
            type: Boolean,
            default: true,
        },
        isAssistance: {
            type: Boolean,
            default: true,
        },
        showReviewApplication: {
            type: Boolean,
            default: true,
        },
        isPrecreatedCase: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        activeBreadcrumb() {
            return this.$store.getters['cases/activeNewCaseBreadcrum']
        },
    },
    methods: {
        toApplicationForm() {
            if (this.activeBreadcrumb >= 1) {
                this.$router.push({
                    name: 'new-case',
                    query: {
                        lang: this.$route.query.lang,
                    },
                })
            }
        },
        toAdvocateSelection() {
            if (this.activeBreadcrumb >= 2) {
                this.$router.push({
                    name: 'advocate-requirement',
                    query: {
                        lang: this.$route.query.lang,
                    },
                })
            }
        },
        toReviewApplication() {
            if (this.activeBreadcrumb >= 3) {
                this.$router.push({
                    name: 'review-application',
                    query: {
                        lang: this.$route.query.lang,
                    },
                })
            }
        },
    },
}
</script>
