<template>
    <ModalBlank
    id="mediator-modal"
    :modal-open="isOpen"
    @close-modal="close()"
    :backdrop="true"
    >
        <div class="flex flex-col p-5">
            <!-- Modal header -->
            <div class="mb-2 flex w-full justify-center">
                <div class="text-xl font-semibold text-slate-800">
                    {{ $t(`caseStage.${initiateTitle}`) }}?
                </div>
            </div>
            <!-- Modal content -->
            <div class="mb-10 flex w-full justify-center text-base">
                <p class="text-center">
                    {{ $t(`caseStage.${initiateDesc}`) }}
                </p>
            </div>
            <!-- Modal footer -->
            <div class="flex flex-row justify-between space-x-2">
                <button class="btn w-full rounded-lg bg-slate-100 py-3 px-6 text-slate-800 hover:bg-slate-200"
                    @click.stop="close()">
                    {{ $t('button.cancel') }}
                </button>
                <button class="btn w-full rounded-lg bg-sky-500 py-3 px-6 text-white hover:bg-sky-600" @click="confirm()">
                    {{ $t('button.confirm') }}
                </button>
            </div>
        </div>
    </ModalBlank>
</template>
<script>
import ModalBlank from '@/components/ModalBlank.vue'

export default {
    name: 'ModalInitiateConfirm',
    components: { ModalBlank },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            required: true,
        },
    },
    emits: ['confirm', 'close'],
    computed: {
        initiateTitle() {
            switch (this.type) {
                case 'mediation':
                    return 'inititateMediation'
                case 'arbitration':
                    return 'inititateArbitration'
                default:
                    return ''
            }
        },
        initiateDesc() {
            switch (this.type) {
                case 'mediation':
                    return 'inititateMediationDesc'
                case 'arbitration':
                    return 'inititateArbitrationDesc'
                default:
                    return ''
            }
        },
    },
    methods: {
        close() {
            this.$emit('close')
        },
        confirm() {
            this.$emit('confirm')
        },
    },
}
</script>
