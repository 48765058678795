export default {
    data: () => ({
        roles: [],
        users: [],
        filterDataUser: {
            page: 1,
            take: 10,
            search: '',
            order: 'DESC',
            order_role: '',
            order_email: '',
            order_name: '',
        },
        metaDataUser: {
            page: 1,
            take: 10,
            itemCount: 10,
            pageCount: 1,
            hasPreviousPage: false,
            hasNextPage: true,
        },
        formData: {
            first_name: '',
            last_name: '',
            username: '',
            email: '',
            phone: '',
            password: '',
            role_id: '',
        },
        banks: [],
        identificationTypes: [],
    }),
    methods: {
        newUser(event) {
            this.newUserModalOpen = true
            this.isUserEdit = false
            this.currentUser = {}
        },
        editUser(event) {
            this.newUserModalOpen = true
            this.isUserEdit = true
            this.currentUser = event.user
        },
        deleteUser(event) {
            this.deleteUserModalOpen = true
            this.isUserEdit = false
            this.currentUser = event.user
        },
        getAllRoles() {
            this.store
                .dispatch('user/getAllRoles')
                .then((response) => {
                    const responseData = response.data.data
                    if (responseData) {
                        this.roles = responseData
                    }
                })
                .catch((error) => {
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        getAllUsers() {
            this.isLoading = true
            this.store
                .dispatch('user/getAllUsers', {
                    order: this.filterDataUser.order,
                    order_role: this.filterDataUser.order_role,
                    order_email: this.filterDataUser.order_email,
                    order_name: this.filterDataUser.order_name,
                    page: this.filterDataUser.page,
                    take: this.filterDataUser.take,
                    q: this.filterDataUser.search,
                })
                .then((response) => {
                    this.isLoading = false
                    const responseData = response.data
                    if (responseData.data) {
                        this.users = responseData.data
                    }
                    if (responseData.from) {
                        this.metaDataUser = {
                            current_page: responseData.current_page,
                            from: responseData.from,
                            last_page: responseData.last_page,
                            links: responseData.links,
                            per_page: responseData.per_page,
                            to: responseData.to,
                            total: responseData.total,
                        }
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        submitData(formData) {
            if (this.isLoading) return
            this.formData = formData
            if (formData.user_id === null) {
                this.createUser()
            } else {
                this.updateUser()
            }
        },
        modalUserClose() {
            this.newUserModalOpen = false
            this.currentUser = {}
        },
        updateUser() {
            if (this.isLoading) return
            this.isLoading = true
            this.store
                .dispatch('user/updateUser', {
                    identification_type_id:  this.formData.identification_type_id,
                    identification: this.formData.identification,
                    first_name: this.formData.first_name,
                    last_name: this.formData.last_name,
                    username: this.formData.username,
                    password: this.formData.password,
                    email: this.formData.email.toLowerCase(),
                    phone: this.formData.phone,
                    user_id: this.$methods.encrypt(this.formData.user_id),
                    role_id: this.formData.role_id,
                })
                .then((response) => {
                    this.isLoading = false
                    if (response.status === 200) {
                        const message =
                            response.data?.message ??
                            'User updated successfully'
                        this.toast.success(message)
                        this.newUserModalOpen = false
                        this.getAllUsers()
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        createUser() {
            if (this.isLoading) return
            this.isLoading = true
            this.store
                .dispatch('user/createUser', {
                    identification_type_id:  this.formData.identification_type_id,
                    identification: this.formData.identification,
                    first_name: this.formData.first_name,
                    last_name: this.formData.last_name,
                    username: this.formData.username,
                    email: this.formData.email.toLowerCase(),
                    phone: this.formData.phone,
                    password: this.formData.password,
                    role_id: this.formData.role_id,
                    bank_id: this.formData.bank_id,
                })
                .then((response) => {
                    this.isLoading = false
                    if (response.status === 200 || response.status === 201) {
                        const message =
                            response.data?.message ??
                            'User created successfully'
                        this.toast.success(message)
                        this.newUserModalOpen = false
                        this.getAllUsers()
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        deleteUserAPI(events) {
            if (this.isLoading) return
            this.isLoading = true
            this.store
                .dispatch('user/deleteUser', {
                    user_id: this.$methods.encrypt(events.id),
                })
                .then((response) => {
                    this.isLoading = false
                    if (response.status === 200) {
                        const message =
                            response.data?.message ??
                            'User deleted successfully'
                        this.toast.success(message)
                        this.deleteUserModalOpen = false
                        this.getAllUsers()
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        searchUser(event) {
            this.filterDataUser.search = event
            this.getAllUsers()
        },
        getAllBanks() {
            this.isLoading = true
            this.store
                .dispatch('bank/getAllBank')
                .then((response) => {
                    this.isLoading = false
                    const responseData = response.data.data
                    this.banks = responseData
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        getAllIdentificationTypes() {
            this.store
                .dispatch('user/getAllIdentificationTypes')
                .then((response) => {
                    if (response.data && response.data.data) {
                        this.identificationTypes = response.data.data
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        changeSort(events) {
            this.headerData.forEach((item) => {
                if (item.name === events.name) item.sort = events.sort
            })
            if (events.name === 'Role') {
                this.filterDataUser.order_role = events.sort
                this.filterDataUser.order_name = ''
                this.filterDataUser.order_email = ''
            } else if (events.name === 'Name') {
                this.filterDataUser.order_role = ''
                this.filterDataUser.order_name = events.sort
                this.filterDataUser.order_email = ''
            } else if (events.name === 'E-mail') {
                this.filterDataUser.order_role = ''
                this.filterDataUser.order_name = ''
                this.filterDataUser.order_email = events.sort
            }
            this.getAllUsers()
        },
        changeOrder(event) {
            this.filterDataUser.order = ['DESC', 'ASC'].includes(
                event.target.value
            )
                ? event.target.value
                : 'DESC'
            this.getAllUsers()
        },
    },
}
