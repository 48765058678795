import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'
import { encrypt } from '../../utils/Utils.js'

// initial state
const state = () => ({
    isUserLoggedIn: false,
    forgotPasswordToken: null,
    adminMode: 'admin',
    currentUser: {
        id: null,
        avatar: null,
        name: null,
        role: {
            name: null,
        },
    },
    gettingUser: false,
})

// getters
const getters = {
    getCurrentUser (state) {
        return state.currentUser ?? {
            id: null,
            avatar: null,
            name: null,
            role: {
                name: null,
            },
        }
    },
    adminModeGetter(state) {
        return state.adminMode || 'admin'
    },
    isUserLoggedIn(state) {
        if (state.isUserLoggedIn === false) {
            return (
                localStorage.getItem('redek.accessToken') !== null &&
                localStorage.getItem('redek.refreshToken') !== null
            )
        }
        return state.isUserLoggedIn
    },
    accessToken(state) {
        return localStorage.getItem('redek.accessToken') || null
        // return state.isUserLoggedIn
    },
    refreshToken(state) {
        return localStorage.getItem('redek.refreshToken') || null
        // return state.isUserLoggedIn
    },
    forgotPasswordToken(state) {
        return state.forgotPasswordToken
    },
    activeUser(state) {
        return JSON.parse(localStorage.getItem('redek.activeUser'));
    },
    activeUserTemp(state) {
        return JSON.parse(localStorage.getItem('redek.temp.activeUser'));
    },
    activeEmail(state) {
        return localStorage.getItem('redek.activeEmail') || null
    },
}

// actions
const actions = {
    getAdminMode({ commit, state }, payload) {
        return state.adminMode || 'admin';
    },
    getActiveUserTemp({ commit, state }, payload) {
        return JSON.parse(localStorage.getItem('redek.temp.activeUser'));
    },
    getActiveUser({ commit, state, dispatch }, payload) {
        dispatch('user', payload).then(user => {
            if (user) {
                state.currentUser = user
            }
        })
    },
    updateCurrentUser ({ commit, state, dispatch }, payload) {
        dispatch('user').then(user => {
            if (user) {
                state.currentUser = user
            }
        })
    },
    setAdminMode({ commit, state }, adminMode) {
        return commit('SET_ADMIN_MODE', adminMode)
    },
    setActiveEmail({ commit, state }, activeEmail) {
        return commit('SET_ACTIVE_EMAIL', activeEmail)
    },
    resetActiveUserTemp({ commit, state }) {
        return commit('REMOVE_ACTIVE_USER_TEMP')
    },
    resetActiveEmail({ commit, state }) {
        return commit('REMOVE_ACTIVE_EMAIL')
    },
    updateLoginState({ commit, state }, loggedIn) {
        return commit('SET_IS_USER_LOGGED_IN', loggedIn)
    },
    register({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${routePathAPI()}/register`,
                    {
                        identification_type_id: payload.identification_type_id,
                        identification: payload.identification,
                        first_name: payload.first_name,
                        last_name: payload.last_name,
                        username: payload.username ? payload.username.toLowerCase() : null,
                        email: payload.email.toLowerCase(),
                        phone: payload.phone,
                        password: payload.password,
                        oauth_id: payload.oauth_id ? payload.oauth_id : null,
                        avatar: payload.avatar ? payload.avatar : null,
                    },
                    {
                        headers: {
                            'x-language-code': currentLanguageCode,
                            platform: import.meta.env.VITE_PLATFORM || null,
                            device: import.meta.env.VITE_DEVICE || null,
                        },
                    }
                )
                .then(async (response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    } else {
                        reject(response.data)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    login({ commit }, payload) {
        const currentLanguageCode =
            router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            axios
            .post(
                `${routePathAPI()}/oauth/token`,
                {
                    grant_type: import.meta.env.VITE_PASSPORT_GRANT_TYPE || null,
                    client_id: import.meta.env.VITE_PASSPORT_CLIENT_ID || null,
                    client_secret: import.meta.env.VITE_PASSPORT_CLIENT_SECRET || null,
                    username: payload.username.toLowerCase(),
                    password: payload.password,
                    inactivo: 1,
                },
                {
                    headers: {
                        'x-language-code': currentLanguageCode,
                        platform: import.meta.env.VITE_PLATFORM || null,
                        device: import.meta.env.VITE_DEVICE || null,
                    },
                }
            )
            .then(async (response) => {
                if (response.status === 200) {
                    resolve(response.data)
                } else {
                    reject(response.data)
                }
            })
            .catch((error) => {
                reject(error)
            })
        });
    },
    user({ commit }, payload) {
        const addToRoute = (payload && payload.case_id)
            ? '/' + ((!isNaN(payload.case_id)) ? encrypt(payload.case_id) : payload.case_id)
            : '';

        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE;
        return new Promise((resolve, reject) => {
            axios
            .get(
                `${routePathAPI()}/user${addToRoute}`,
                {
                    headers: {
                        'x-language-code': currentLanguageCode,
                        platform: import.meta.env.VITE_PLATFORM || null,
                        device: import.meta.env.VITE_DEVICE || null,
                    },
                }
            )
            .then(async (response) => {
                if (response.status === 200) {
                    resolve(response.data)
                } else {
                    reject(response.data)
                }
            })
            .catch((error) => {
                reject(error)
            })
        });
    },
    forgotPassword({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${routePathAPI()}/password/email`,
                    {
                        email: payload.email.toLowerCase(),
                    },
                    {
                        headers: {
                            'x-language-code': currentLanguageCode,
                            platform: import.meta.env.VITE_PLATFORM || null,
                            device: import.meta.env.VITE_DEVICE || null,
                        },
                    }
                )
                .then(async (response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    } else {
                        reject(response.data)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    resetPassword({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            axios.post(
                `${routePathAPI()}/password/reset`,
                {
                    token: payload.token,
                    email: payload.email.toLowerCase(),
                    password: payload.password,
                    password_confirmation: payload.password_confirmation
                },
                {
                    headers: {
                        'x-language-code': currentLanguageCode,
                        platform: import.meta.env.VITE_PLATFORM || null,
                        device: import.meta.env.VITE_DEVICE || null,
                    },
                }
            ).then(async (response) => {
                if (response.status === 200 && response?.data.access_token) {
                    resolve(response.data)
                } else {
                    reject(response.data)
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    sendOtp({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE;
        const user = JSON.parse(localStorage.getItem('redek.temp.activeUser'));
        const otpType = user.otp_type;
        const otpTypeUrl = !otpType || otpType === 'email' ? '/sendOTP' : '/onurix/otp/send';
        if (user) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `${routePathAPI()}${otpTypeUrl}`,
                        {
                            user: user,
                        },
                        {
                            headers: {
                                'x-language-code': currentLanguageCode,
                                platform: import.meta.env.VITE_PLATFORM || null,
                                device: import.meta.env.VITE_DEVICE || null,
                            },
                        }
                    )
                    .then(async (response) => {
                        if (response.status === 200) {
                            resolve(response.data)
                        } else {
                            reject(response.data)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        }
    },
    verifyOTP({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        const user = JSON.parse(localStorage.getItem('redek.temp.activeUser'));
        const otpType = user.otp_type;
        const otpTypeUrl = !otpType || otpType === 'email' ? '/verifyOTP' : '/onurix/otp/verify';
        if (user) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `${routePathAPI()}${otpTypeUrl}`,
                        {
                            user: user,
                            otp: payload.otp,
                        },
                        {
                            headers: {
                                'x-language-code': currentLanguageCode,
                                platform: import.meta.env.VITE_PLATFORM || null,
                                device: import.meta.env.VITE_DEVICE || null,
                            },
                        }
                    )
                    .then(async (response) => {
                        if (response.status === 200) {
                            const accessToken = response.data.access_token;
                            const user = JSON.parse(localStorage.getItem('redek.temp.activeUser'));
                            commit('SET_ACCESS_TOKEN', { user: user, accessToken: accessToken });
                            commit('SET_ACTIVE_USER', user)
                            commit('REMOVE_ACTIVE_EMAIL');
                            resolve(response)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        }
    },
    loginWhenOtpIsOff ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const user = JSON.parse(localStorage.getItem('redek.temp.activeUser'));
            if (payload.access_token && user) {
                commit('SET_ACCESS_TOKEN', { user: user, accessToken: payload.access_token });
                commit('SET_ACTIVE_USER', user)
                commit('REMOVE_ACTIVE_EMAIL');
                resolve({
                    message: 'You have successfully logged in'
                })
            } else {
                reject({
                    response: { data: { message: { code: 'message.failed.auth.userLoggingInExpired' } } }
                })
            }

        })
    },
    logout({ commit, state }, payload) {
        axios.post(`${routePathAPI()}/logout`).then(async (response) => {
            state.currentUser = null
            commit('SET_IS_USER_LOGGED_IN', false)
            commit('REMOVE_ACCESS_TOKEN')
            commit('REMOVE_CSRF_TOKENIZER')
            commit('REMOVE_REFRESH_TOKEN')
            commit('REMOVE_ACTIVE_USER')
        })
        .catch((error) => {
            reject(error)
        })
    },
    checkIdentificationExistence ({ commit, state }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        if (payload.identification) {
            return new Promise((resolve, reject) => {
                axios.get(
                    `${routePathAPI()}/users/identification/checkExistence/${payload.identification}${ payload.current ? '/' + payload.current : '' }`,
                    {
                        headers: {
                            'x-language-code': currentLanguageCode,
                            platform: import.meta.env.VITE_PLATFORM || null,
                            device: import.meta.env.VITE_DEVICE || null,
                        },
                    }
                ).then(async (response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    } else {
                        reject(response.data)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
            })
        }
    },
    checkEmailExistence ({ commit, state }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        if (payload.email) {
            return new Promise((resolve, reject) => {
                axios.get(
                    `${routePathAPI()}/users/email/checkExistence/${payload.email.toLowerCase()}${ payload.current ? '/' + payload.current : '' }`,
                    {
                        headers: {
                            'x-language-code': currentLanguageCode,
                            platform: import.meta.env.VITE_PLATFORM || null,
                            device: import.meta.env.VITE_DEVICE || null,
                        },
                    }
                ).then(async (response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    } else {
                        reject(response.data)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
            })
        }
    },
    checkPhoneExistence ({ commit, state }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        if (payload.phone) {
            return new Promise((resolve, reject) => {
                axios.get(
                    `${routePathAPI()}/users/phone/checkExistence/${payload.phone}${ payload.current ? '/' + payload.current : '' }`,
                    {
                        headers: {
                            'x-language-code': currentLanguageCode,
                            platform: import.meta.env.VITE_PLATFORM || null,
                            device: import.meta.env.VITE_DEVICE || null,
                        },
                    }
                ).then(async (response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    } else {
                        reject(response.data)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
            })
        }
    },
    checkPasswordCurrentUser ({ commit, state }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        if (payload.password) {
            return new Promise((resolve, reject) => {
                axios.get(
                    `${routePathAPI()}/users/checkPasswordCurrentUser/${payload.password}`,
                    {
                        headers: {
                            'x-language-code': currentLanguageCode,
                            platform: import.meta.env.VITE_PLATFORM || null,
                            device: import.meta.env.VITE_DEVICE || null,
                        },
                    }
                ).then(async (response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    } else {
                        reject(response.data)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
            })
        }
    },
    actionOTP ({ commit, state }, payload) {
        const currentLanguageCode =
        router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
        axios.post(
            `${routePathAPI()}users/actionOTP`,
            {
                is_active_otp: payload.is_active_otp,
                password: payload.password,
                otp_type: payload.otp_type,
            },
            {
                headers: {
                    'x-language-code': currentLanguageCode,
                    platform: import.meta.env.VITE_PLATFORM || null,
                    device: import.meta.env.VITE_DEVICE || null,
                },
            }
        )
        .then(async (response) => {
            if (response.status === 200) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        })
        .catch((error) => {
            reject(error)
        })
    })
    },
}

// mutations
const mutations = {
    SET_ACCESS_TOKEN(state, payload) {
        state.userAuthenticated = payload.user;
        localStorage.setItem("redek.permissions", window.btoa(JSON.stringify(payload.user.role.permissions)));
        localStorage.setItem("redek.accessToken", JSON.stringify(payload.accessToken));
    },
    SET_REFRESH_TOKEN(state, refreshToken) {
        localStorage.setItem('redek.refreshToken', refreshToken)
    },
    SET_ADMIN_MODE(state, adminMode) {
        state.adminMode = adminMode
    },
    SET_ACTIVE_USER(state, user) {
        localStorage.setItem('redek.activeUser', JSON.stringify(user))
    },
    SET_ACTIVE_EMAIL(state, activeEmail) {
        localStorage.setItem('redek.activeEmail', activeEmail)
    },
    SET_IS_USER_LOGGED_IN(state, isUserLoggedIn) {
        state.isUserLoggedIn = isUserLoggedIn
    },
    SET_FORGOT_PASSWORD_TOKEN(state, forgotPasswordToken) {
        state.forgotPasswordToken = forgotPasswordToken
    },
    REMOVE_ACCESS_TOKEN(state) {
        localStorage.removeItem('redek.accessToken')
    },
    REMOVE_REFRESH_TOKEN(state) {
        localStorage.removeItem('redek.refreshToken')
    },
    REMOVE_ACTIVE_USER_TEMP(state) {
        localStorage.removeItem('redek.temp.activeUser');
    },
    REMOVE_ACTIVE_USER(state) {
        localStorage.removeItem('redek.activeUser');
    },
    REMOVE_ACTIVE_EMAIL(state) {
        localStorage.removeItem('redek.activeEmail');
    },
    REMOVE_FORGOT_PASSWORD_TOKEN(state) {
        state.forgotPasswordToken = null
    },
    REMOVE_CSRF_TOKENIZER(state) {
        localStorage.removeItem('redek.permissions');
    },
    UPDATE_USER_INFO(state, payload) {
        // Get Data localStorage
        const userInfo =
            JSON.parse(localStorage.getItem('userInfo')) || state.AppActiveUser

        for (const property of Object.keys(payload)) {
            if (payload[property] !== null) {
                // If some of user property is null - user default property defined in state.AppActiveUser
                state.AppActiveUser[property] = payload[property]

                // Update key in localStorage
                userInfo[property] = payload[property]
            }
        }
        // Store data in localStorage
        localStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
