<template>
    <div class="flex h-screen overflow-hidden">
        <!-- Sidebar -->
        <Sidebar
        :sidebar-open="sidebarOpen"
        :case-stage="$route.query.status"
        @close-sidebar="sidebarOpen = false"
        :get-the-case="true"
        />

        <!-- Content area -->
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <!-- Site header -->
            <!-- <Header
                :sidebarOpen="sidebarOpen"
                @toggle-sidebar="sidebarOpen = !sidebarOpen"
            /> -->

            <main>
                <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
                    <div class="flex w-full flex-col">
                        <h1 class="text-2xl font-bold text-black">
                            {{ $t('document.title') }}
                        </h1>
                        <p>{{ $t('document.description') }}</p>
                    </div>

                    <div class="flex items-center gap-3">
                        <button @click="changeFolderFromBackButton">
                            <i :class="[
                                'fa-solid fa-circle-chevron-left text-2xl',
                                (directory.length > 1) ? 'text-sky-600' : 'text-slate-400 cursor-not-allowed'
                            ]"></i>
                        </button>
                        <div class="border-2 h-15 p-1 my-4 text-sm flex w-full rounded">
                            <div
                            v-for="(item, index) in directory"
                            :key="index"
                            class="text-slate-500 flex gap-2 ml-2">
                                <FolderIcon
                                v-if="item.root"
                                @click="changeFolderFromDir(item.folderId, index)"
                                class="h-5 cursor-pointer"/>
                                <span
                                class="cursor-pointer"
                                @click="changeFolderFromDir(item.folderId, index)"
                                v-else>
                                    {{ item.label }}
                                </span>
                                <span>
                                    /
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="my-4 flex w-full items-center justify-between">
                        <SearchForm :placeholder="$t('document.searchPlaceholder')" @search="searchDocument" />
                        <EditMenu
                        v-if="
                            !isConcludedMode
                            &&
                            (
                                activeFolder.can_create_files
                                ||
                                activeFolder.can_create_folders
                            )
                            &&
                            (!$store.getters['cases/currentCaseWasClosed'] || $methods.hasPermissions(['cases_manage_documents_create_although_case_closed']))
                            &&
                            isAValidPartInCase($store.getters['auth/getCurrentUser'])
                        "
                        align="right"
                        class="relative inline-flex"
                        custom-class="rounded-xl !bg-sky-500 !text-white !hover:bg-sky-600 p-2"
                        :show-text="true"
                        :show-dots="true"
                        :text="$t('document.buttonAdd')">
                            <li v-if="activeFolder.can_create_files">
                                <span
                                    class="flex cursor-pointer py-1 px-3 text-sm font-medium text-slate-600 hover:text-slate-800"
                                    @click.stop="newDocument(false)">
                                    {{ $t('document.newFile') }}
                                </span>
                            </li>
                            <li v-if="activeFolder.can_create_folders">
                                <span
                                    class="flex cursor-pointer py-1 px-3 text-sm font-medium text-slate-600 hover:text-slate-800"
                                    @click.stop="newDocument(true)">
                                    {{ $t('document.newFolder') }}
                                </span>
                            </li>
                        </EditMenu>
                    </div>

                    <div v-if="!isLoading">
                        <div v-if="activeFolder.identity === 'case-.case_record.requested_documents'">
                            <div v-if="requestedDocuments.length > 0" class="w-full pb-20">
                                <Table
                                :header-data="requestedDocumentsHeaderData"
                                tbody-class="mt-4"
                                col-class="w-1/6"
                                />
                                <div
                                v-for="(document, index) in requestedDocuments"
                                :key="index"
                                >
                                    <DocumentRequestItem
                                    class="mt-2 py-6"
                                    :document="document"
                                    @info-document="openInfoDocumentRequest"
                                    />
                                </div>
                            </div>
                            <!-- <div class="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
                                <div
                                v-for="document in documents" :key="document.id"
                                class="flex items-center flex-col"
                                @click="changeFolder({folder: document})"
                                >
                                    <i class="fa-solid fa-folder text-8xl text-gray-500"></i>
                                    <span class="text-sm">
                                        {{ document.name }}
                                    </span>
                                </div>
                            </div> -->
                            <NoData v-else />
                        </div>
                        <div v-else>
                            <div v-if="documents.length > 0" class="w-full pb-20">
                                <Table :header-data="headerData" tbody-class="mt-4"/>
                                <div v-for="document in documents" :key="document.id">
                                    <DocumentItem
                                    :directory="directory"
                                    :document="document"
                                    :isa-valid-part-in-case="isAValidPartInCase($store.getters['auth/getCurrentUser'])"
                                    class="mt-2 py-6"
                                    @change-folder="changeFolder($event)"
                                    @info-document="infoDocument($event)"
                                    @edit-document="editDocument($event)"
                                    @remove-document="deleteDocument($event)"
                                    @get-requested-documents="getRequestedDocument"
                                    />
                                </div>
                            </div>
                            <NoData v-else />
                        </div>
                    </div>
                    <DualRingLoader v-else-if="isLoading" />
                </div>
            </main>

            <ModalDocumentInfo
            :is-open="infoDocumentModalOpen"
            :document="currentDocument"
            @closed="closeInfoDocumentModal"
            />

            <ModalDocumentRequestInfo
            :is-open="infoDocumentRequestModalOpen"
            :document="currentDocumentRequest"
            @closed="closeInfoDocumentRequestModal"
            />

            <ModalDelete
            :id="currentDocument.id"
            :is-open="deleteDocumentModalOpen"
            :title="$t('document.modalRemoveFile.title')"
            :description="$t('document.modalRemoveFile.description')"
            :is-loading="isLoading"
            @closed="closeModalDelete"
            @delete="deleteDocumentAPI($event)" />

            <ModalCreateFile
            :is-edit="isDocumentEdit"
            :is-open="documentModalOpen"
            :share-with="caseParties"
            :folderId="activeFolder.id"
            :document="currentDocument"
            :is-folder="isFolder"
            :me="$store.getters['auth/getCurrentUser']"
            :folders="getCurrentDirFolders"
            :is-loading="isLoading"
            :current-document="currentDocument"
            :calendar-agendas="calendarAgendasToSelect"
            :active-folder="activeFolder"
            @closed="closeDocumentModal"
            @submit-data="submitData"
            />
        </div>

        <RightSidebar :sidebar-open="sidebarOpen" @close-sidebar="sidebarOpen = false" />
    </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'
import documentMixin from '@/mixins/document.js'

import Sidebar from '@/partials/Sidebar.vue'
import RightSidebar from '@/partials/RightSidebar.vue'
import SearchForm from '@/components/SearchForm.vue'
import Table from '@/components/Table.vue'
import DocumentItem from '@/components/DocumentItem.vue'
import EditMenu from '@/components/DropdownEditMenu.vue'
import DocumentRequestItem from '@/components/DocumentRequestItem.vue'

import ModalCreateFile from '@/components/ModalCreateFile.vue'
import ModalDelete from '@/components/ModalDelete.vue'
import ModalDocumentInfo from '@/components/modal/document/ModalDocumentInfo.vue'
import ModalDocumentRequestInfo from '@/components/modal/document/ModalDocumentRequestInfo.vue'

import DualRingLoader from '@/components/DualRingLoader.vue'
import NoData from '@/components/NoData.vue'
import FolderIcon from '@/assets/svg/folder.svg'

export default {
    name: 'DocumentPage',
    components: {
        Sidebar,
        RightSidebar,
        SearchForm,
        Table,
        DocumentItem,
        EditMenu,
        ModalCreateFile,
        ModalDelete,
        ModalDocumentInfo,
        DualRingLoader,
        NoData,
        FolderIcon,
        DocumentRequestItem,
        ModalDocumentRequestInfo,
    },
    mixins: [globalMixin, caseMixin, documentMixin],
    setup() {
        const { t, n } = useI18n()
        const store = useStore()
        const toast = useToast()
        const sidebarOpen = ref(false)
        const calendarAgendasToSelect = ref([])
        const headerData = ref([
            {
                name: t('document.headerTable.name'),
                colspan: 1,
            },
            {
                name: t('document.headerTable.owner'),
                colspan: 1,
            },
            {
                name: t('document.headerTable.type'),
                colspan: 1,
            },
        ]);
        const requestedDocumentsHeaderData = ref([
            {
                name: t('document.headerTable.requestedDocuments.title'),
                colspan: 1,
            },
            {
                name: t('document.headerTable.requestedDocuments.stage'),
                colspan: 1,
            },
            {
                name: t('document.headerTable.requestedDocuments.dueDate'),
                colspan: 1,
            },
            {
                name: t('document.headerTable.requestedDocuments.status'),
                colspan: 1,
            },
            {
                name: t('document.headerTable.requestedDocuments.requestedAt'),
                colspan: 1,
            },
            {
                name: '',
                colspan: 1,
            },
        ])

        return {
            t,
            n,
            store,
            toast,
            headerData,
            sidebarOpen,
            calendarAgendasToSelect,
            requestedDocumentsHeaderData,
        }
    },
    watch: {
        activeFolder: function (val) {
            if (val) {
                if (val.identity === 'case-.case_call_recordings') {
                    this.getCalendarAgendasToSelect()
                }
            }
        }
    },
    mounted() {
        // this.getAllDocument()
        this.getCurrentFolder()
        this.getCaseParties()
    },
    methods: {
        changeFolderFromBackButton () {
            if (this.directory.length > 1) {
                const indexBack = this.directory.length - 2
                this.getCurrentFolder(this.directory[indexBack].folderId)
                this.directory.pop()
            }
        },
        changeFolderFromDir (folderId, indexDir) {
            this.getCurrentFolder(folderId);
            this.directory.splice(indexDir + 1);
        },
        getCalendarAgendasToSelect () {
            this.store.dispatch('calendar/getForSelectUser', { type: 'Meeting' }).then(response => {
                if (response.data) {
                    this.calendarAgendasToSelect = response.data;
                }
            })
        },
    },
    computed: {
        getCurrentDirFolders () {
            return this.documents.filter((item) => item.type === 'Folder');
        },
    }
}
</script>
