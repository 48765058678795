<template>
    <div class="-m-1.5 flex flex-wrap items-center">
        <div class="m-1.5">
            <ModalBasic id="chat-media-modal" :modal-open="isOpen" :show-border-header="false" title="Media and Documents"
                @close-modal="close">
                <div class="space-y-5 px-5 pb-5">
                    <div v-for="document in documents" :key="document.id"
                        class="flex cursor-pointer items-center justify-between" @click.stop="toFile(document)">
                        <div class="flex items-center justify-start space-x-3">
                            <FileTypeIcon :file-type="fileType(document)" icon-class="inline w-8 cursor-pointer" />
                            <div>
                                <p class="line-clamp-1 text-sm font-medium text-black">
                                    {{ document.name }}
                                </p>
                                <p class="text-xs text-slate-500">
                                    {{ formatBytes(fileSize(document)) }}
                                </p>
                            </div>
                        </div>
                        <div class="flex items-center justify-end">
                            <DownloadIcon />
                        </div>
                    </div>
                </div>
            </ModalBasic>
        </div>
    </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'

import ModalBasic from '@/components/ModalBasic.vue'
import FileTypeIcon from '@/components/FileTypeIcon.vue'

import DownloadIcon from '@/assets/svg/download.svg'

export default {
    name: 'ModalChatMediaComponent',
    components: {
        ModalBasic,
        FileTypeIcon,
        DownloadIcon,
    },
    mixins: [globalMixin],
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        documents: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    emits: ['closed'],
    data: () => ({}),
    methods: {
        close() {
            this.$emit('closed')
        },
        toFile(document) {
            window.open(document.files[0].file, '_blank')
        },
        fileType(file) {
            if (file.type !== 'Folder' && 'files' in file) {
                if (file.files[0] !== undefined) {
                    const fileName = file.files[0].file
                    return fileName.substring(fileName.lastIndexOf('.') + 1)
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        fileSize(file) {
            if (file.type !== 'Folder' && 'files' in file) {
                if (file.files[0] !== undefined) return file.files[0].size
                else return 0
            } else {
                return 0
            }
        },
    },
}
</script>
