import * as Yup from 'yup'

export default {
    data: function () {
        return {
            id: null,
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            username: '',
            currentPassword: '',
            showCurrentPassword: false,
            password: '',
            showPassword: false,
            confirmPassword: '',
            showConfirmPassword: false,
            passwordChange: false,
            attachment: null,
            showGuide: false,
            role: {},

            currentUser: {},
            profileData: {},

            updateProfileSchema: Yup.object().shape({
                firstName: Yup.string()
                    .typeError('validation.enterFirstName')
                    .required('validation.enterFirstName')
                    .min(3, 'validation.minimumFirstName')
                    .matches(/^[A-z ]+$/, 'validation.alphabetOnly'),
                lastName: Yup.string(),
                phoneNumber: Yup.string()
                    .test(
                        'number-format',
                        'validation.enterValidPhoneNumber',
                        function (value) {
                            if (value) {
                                const allowedValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                                return value.toString().split('').every(elem => allowedValues.some(item => item == elem))
                            }
                        }
                    )
                    .typeError('validation.enterPhoneNumber')
                    .required('validation.enterPhoneNumber')
                    .test(
                        'unique-phone',
                        'validation.phoneAlreadyInUse',
                        this.checkPhoneExistence
                    ),
                username: Yup.string()
                    .typeError('validation.enterUserName')
                    .required('validation.enterUserName'),
                email: Yup.string()
                    .email('validation.enterValidEmail')
                    .typeError('validation.enterValidEmail')
                    .required('validation.enterValidEmail')
                    .test(
                        'unique-email',
                        'validation.emailAlreadyInUse',
                        this.checkEmailExistence
                    ),
            }),
            changePasswordSchema: Yup.object().shape({
                currentPassword: Yup.string()
                    .typeError('validation.enterValidPassword')
                    .required('validation.enterValidPassword')
                    .min(6, 'validation.enterValidPassword')
                    .test(
                        'password-correct',
                        'validation.passwordIncorrect',
                        this.checkPassword
                    ),
                password: Yup.string()
                    .typeError('validation.enterValidPassword')
                    .required('validation.enterValidPassword')
                    .min(6, 'validation.enterValidPassword'),
                confirmPassword: Yup.string()
                    .required('validation.enterValidPassword')
                    .min(6, 'validation.enterValidPassword')
                    .test(
                        'passwords-match',
                        'validation.passwordMustMatch',
                        function (value) {
                            return this.parent.password === value
                        }
                    ),
            }),
        }
    },
    methods: {
        async getProfile() {
            this.isLoading = true
            await this.store.dispatch('profile/getProfile')
            .then((response) => {
                this.isLoading = false
                const responseData = response.data
                if (responseData) {
                    this.profileData = responseData;
                    this.id = responseData.id
                    this.firstName = responseData.first_name
                    this.lastName = responseData.last_name || ''
                    this.email = responseData.email
                    this.phoneNumber = responseData.phone
                    this.username = responseData.username
                    this.role = responseData.role.name
                    // this.showGuide = responseData.show_guide !== true
                }
            })
            .catch((error) => {
                console.log(error)
                this.isLoading = false
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        async updateShowGuide() {
            await this.store
                .dispatch('profile/updateShowGuide')
                .then((response) => {
                    if (response.data.statusCode) {
                        return
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async updateProfileAPI() {
            this.isLoading = true
            const params = {}
            params.first_name = this.firstName
            params.last_name = this.lastName
            params.username = this.username
            params.email = this.email.toLowerCase()
            params.phone = this.phoneNumber
            if (this.attachment !== null) params.attachment = this.attachment

            await this.store
                .dispatch('profile/updateProfile', params)
                .then((response) => {
                    this.isLoading = false
                    if (response.status === 200) {
                        this.toast.success(this.$t('profile.update.success'))
                        this.getProfile()
                        this.store.dispatch('auth/updateCurrentUser')
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async updatePasswordAPI() {
            this.isLoading = true
            await this.store
                .dispatch('profile/updatePassword', {
                    old_password: this.currentPassword,
                    new_password: this.password,
                })
                .then((response) => {
                    this.isLoading = false
                    this.toast.success(this.$t('profile.update.successPassword'))
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        updateProfile() {
            if (!this.isLoading) {
                this.updateProfileAPI()
            }
        },
        updatePassword() {
            if (!this.isLoading) {
                this.updatePasswordAPI()
            }
        },
        fileAvatarChange(event) {
            this.attachment = event.target.files[0]
            this.profileData.avatar = URL.createObjectURL(this.attachment)
        },
        async checkEmailExistence(email) {
            let validation = true
            if (email) {
                validation = await this.$store.dispatch(
                    'auth/checkEmailExistence',
                    {
                        email: email,
                        current: this.id,
                    }
                )
            }
            return validation
        },
        async checkPhoneExistence(phone) {
            let validation = true
            if (phone) {
                validation = await this.$store.dispatch(
                    'auth/checkPhoneExistence',
                    {
                        phone: phone,
                        current: this.id,
                    }
                )
            }
            return validation
        },
        async checkPassword(password) {
            let validation = true
            if (password) {
                validation = await this.$store.dispatch(
                    'auth/checkPasswordCurrentUser',
                    {
                        password: this.$methods.encrypt(password),
                    }
                )
            }
            return validation
        },
    },
}
