import { createStore } from 'vuex'
import global from './global'
import advocate from './advocate'
import arbitrator from './arbitrator'
import auth from './auth'
import bank from './bank'
import calendar from './calendar'
import cases from './cases'
import casetime from './casetime'
import chat from './chat'
import document from './document'
import mediator from './mediator'
import notes from './notes'
import notification from './notification'
import timeline from './timeline'
import user from './user'
import roles from './roles'
import permissions from './permissions'
import review from './review'
import profile from './profile'
import useractions from './useractions'
import rightsidebar from './rightsidebar'
import parameters from './parameters'
import currencies from './currencies'
import casetypes from './casetypes'
import identificationtypes from './identificationtypes'
import caseareas from './caseareas'

export default createStore({
    modules: {
        global,
        advocate,
        arbitrator,
        auth,
        bank,
        calendar,
        cases,
        casetime,
        chat,
        document,
        mediator,
        notification,
        notes,
        timeline,
        profile,
        review,
        user,
        roles,
        permissions,
        useractions,
        rightsidebar,
        parameters,
        currencies,
        casetypes,
        identificationtypes,
        caseareas,
    },
})
