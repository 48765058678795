<template>
  <div class="mt-4">
    <div class="mb-3 text-xs font-semibold uppercase text-slate-400">
      Channels
    </div>
    <ul class="mb-6">
      <li class="-mx-2">
        <button
          class="flex w-full items-center justify-between rounded p-2"
          @click.stop="$emit('close-msgsidebar')"
        >
          <div class="flex items-center">
            <div class="truncate">
              <span class="text-sm font-medium text-slate-800">#New Leads</span>
            </div>
          </div>
          <div class="ml-2 flex items-center">
            <div class="h-2 w-2 rounded-full bg-sky-400"></div>
          </div>
        </button>
      </li>
      <li class="-mx-2">
        <button
          class="flex w-full items-center justify-between rounded p-2"
          @click.stop="$emit('close-msgsidebar')"
        >
          <div class="flex items-center truncate">
            <div class="truncate">
              <span class="text-sm font-medium text-slate-800"
                >#Development Team</span
              >
            </div>
          </div>
        </button>
      </li>
      <li class="-mx-2">
        <button
          class="flex w-full items-center justify-between rounded p-2"
          @click.stop="$emit('close-msgsidebar')"
        >
          <div class="flex items-center truncate">
            <div class="truncate">
              <span class="text-sm font-medium text-slate-800"
                >#Product Tips</span
              >
            </div>
          </div>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Channels',
  props: ['msgSidebarOpen'],
}
</script>
