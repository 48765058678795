<template>
    <div class="flex grow flex-col justify-center">
        <div :class="pieClass">
            <canvas ref="canvas" :data="data" :width="width" :height="height"></canvas>
        </div>
        <div v-if="false" class="px-5 py-4">
            <ul ref="legend" class="-m-1 flex flex-wrap justify-center"></ul>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import { Chart, PieController, ArcElement, TimeScale, Tooltip } from 'chart.js'
import 'chartjs-adapter-moment'

Chart.register(PieController, ArcElement, TimeScale, Tooltip)

export default {
    name: 'PieChart',
    props: ['data', 'width', 'height', 'pieClass'],
    watch: {
        data (val) {
            if (this.chart) {
                this.chart.destroy()
                this.createChart()
            }
        }
    },
    methods: {
        createChart () {
            const ctx = this.canvas
            this.chart = new Chart(ctx, {
                type: 'pie',
                data: this.data,
                options: {
                    layout: {
                        padding: {
                            top: 4,
                            bottom: 4,
                            left: 24,
                            right: 24,
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    interaction: {
                        intersect: false,
                        mode: 'nearest',
                    },
                    animation: {
                        duration: 200,
                    },
                    maintainAspectRatio: false,
                },
            })
        }
    },
    mounted () {
        this.createChart()
    },
    onUnmounted () {
        this.chart.destroy()
    },
    setup(props) {
        const canvas = ref(null)
        const legend = ref(null)
        let chart = null

        return {
            canvas,
            legend,
            chart,
        }
    },
}
</script>
