<template>
    <div>
        <!-- Sidebar backdrop (mobile only) -->
        <div class="fixed inset-0 z-50 bg-white bg-opacity-30 transition-opacity duration-200 lg:z-auto lg:hidden"
            :class="sidebarOpen ? 'opacity-100' : 'pointer-events-none opacity-0'" aria-hidden="true"></div>

        <!-- Sidebar -->
        <div id="sidebar" ref="sidebar"
            class="no-scrollbar absolute left-0 top-0 z-40 flex h-screen w-96 shrink-0 transform flex-col overflow-y-scroll bg-white p-4 transition-all duration-200 ease-in-out lg:static lg:left-auto lg:top-auto lg:w-96 lg:translate-x-0 lg:overflow-y-auto 2xl:!w-96"
            :class="sidebarOpen ? 'translate-x-0' : '-translate-x-64'">
            <!-- Sidebar header -->
            <div class="mb-10 flex flex-col pr-3 sm:px-2">
                <div class="mt-4 flex w-full items-center justify-start">
                    <DropdownNotifications align="left" class="absolute md:mr-6" />
                    <DropdownLanguages class="mr-4"/>
                    <UserMenu align="right" mode="aesthetic" />
                </div>
            </div>

            <!-- Links -->
            <div class="px-4">
                <section id="deadlines">
                    <div class="flex w-full justify-between">
                        <div class="flex items-center">
                            <p class="text-xl font-bold text-black">
                                {{ $t('timeline.deadlines') }}
                            </p>
                        </div>
                        <div v-if="false" class="flex items-center">
                            <svg viewBox="0 0 17 15" fill="none" class="h-3 w-3 shrink-0 text-slate-800">
                                <path d="M15.75 7.72559L0.75 7.72559" stroke="currentColor" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M9.70019 1.70124L15.7502 7.72524L9.7002 13.7502" stroke="currentColor"
                                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </div>
                    <section v-if="!$store.getters['cases/currentCaseWasClosed'] && deadlines.length > 0">
                        <div v-for="deadline in deadlines"
                        :key="deadline.id"
                        class="mt-4 cursor-pointer bg-slate-100  px-4 py-4 text-sm text-black"
                        @click="toDeadlineDate(deadline)">
                            <div class="flex w-full items-center justify-center ">
                                <div class="flex h-12 w-16 flex-col items-center justify-center rounded-md bg-white">
                                    <div class="flex">
                                        <span class="text-red-500">{{
                                            extractDate(deadline.due_date, 'DD')
                                        }}</span>
                                    </div>
                                    <div class="flex text-xs">
                                        <span class="text-black">{{
                                            extractDate(deadline.due_date)
                                        }}</span>
                                    </div>
                                </div>
                                <div
                                v-if="deadline.type === 'Document'"
                                class="ml-4 flex flex-col w-full">
                                    {{ $t('deadline.provideDocumentTo', { to: deadline.owner.first_name }) }}
                                    <span
                                    v-if="deadline.request.current_observation_reference"
                                    class="text-sm text-slate-400">
                                        {{ $t('observation') }}: {{ deadline.request.current_observation_reference }}
                                    </span>
                                </div>
                                <div
                                v-else-if="deadline.type === 'Meeting' || deadline.type === 'Alert'"
                                class="ml-4 flex flex-col w-full">
                                    {{
                                        isAParsedReferenceTranslate(deadline.title)
                                        ? $t(...getParsedReferenceTranslate(deadline.title))
                                        : deadline.title
                                    }}
                                    <span
                                    class="text-sm text-slate-400">
                                    {{
                                        isAParsedReferenceTranslate(deadline.description)
                                        ? $t(...getParsedReferenceTranslate(deadline.description))
                                        : deadline.description
                                    }}
                                    </span>
                                </div>
                            </div>

                            <div class="w-full -mb-4 mt-2">
                                <div class="flex w-full justify-between">
                                    <div>
                                        <span
                                        :class="[
                                            'capitalize',
                                            {
                                                'text-sky-500': $filters.diffDateFromNow(deadline.due_date, 'minute') >= 1440,
                                                'text-yellow-500': $filters.diffDateFromNow(deadline.due_date, 'minute') < 1440,
                                                'text-red-500': $filters.diffDateFromNow(deadline.due_date, 'minute') < 1440,
                                            }
                                        ]"
                                        >
                                            {{ $filters.timeFromNowToX(deadline.due_date) }}
                                        </span>
                                    </div>
                                    <span
                                    :class="[
                                        'rounded-tl p-0.5 w-20 text-center -mr-4 text-white',
                                        getClassDeadLineType(deadline)
                                    ]">
                                        {{ deadline.type }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div v-else class="flex w-full flex-col items-center text-center text-sm">
                        <CalendarFilledIcon class="h-20 w-20" />
                        <span class="text-violet">
                            {{ $t('noPendingAction') }}
                        </span>
                    </div>
                </section>

                <div class="mt-8 flex w-full cursor-pointer justify-between" @click="clickPendingAction">
                    <div class="flex items-center">
                        <p class="text-xl font-bold text-black">
                            {{ $t('timeline.pendingAction') }}
                        </p>
                    </div>
                    <div v-if="false" class="flex items-center">
                        <svg viewBox="0 0 17 15" fill="none" class="h-3 w-3 shrink-0 text-slate-800">
                            <path d="M15.75 7.72559L0.75 7.72559" stroke="currentColor" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.70019 1.70124L15.7502 7.72524L9.7002 13.7502" stroke="currentColor"
                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>

                <PendingActions
                v-if="!$store.getters['cases/currentCaseWasClosed'] && pendingActions.length > 0"
                :pending-actions="pendingActions"
                />
                <!-- <ActivityItem v-if="timelines.length > 0" :activities="timelines" wrapper-class="bg-slate-50" /> -->

                <div
                v-else
                class="flex w-full flex-col items-center text-center text-sm">
                    <DiscoveryFilledIcon class="h-20 w-20" />
                    <span class="text-violet">
                        {{ $t('noPendingAction') }}
                    </span>
                </div>
            </div>

            <!-- Expand / collapse button -->
            <div class="mt-auto hidden justify-start pt-3 lg:inline-flex 2xl:hidden">
                <div class="py-2">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'
import chatMixin from '@/mixins/chat.js'

import DropdownNotifications from '@/components/DropdownNotifications.vue'
import UserMenu from '@/components/DropdownProfile.vue'
// import CalendarIcon from '@/assets/svg/calendar-icon.svg'
import CalendarFilledIcon from '@/assets/svg/filled/Calendar-filled.svg'
import DiscoveryFilledIcon from '@/assets/svg/filled/Discovery-filled.svg'

import ActivityItem from '@/components/ActivityItem.vue'
import PendingActions from '@/components/PendingActions.vue'
import axios from 'axios'
import DropdownLanguages from '@/components/DropdownLanguages.vue'

export default {
    name: 'RightSidebar',
    components: {
        DropdownNotifications,
        UserMenu,
        ActivityItem,
        // CalendarIcon,
        DiscoveryFilledIcon,
        CalendarFilledIcon,
        PendingActions,
        DropdownLanguages
    },
    mixins: [globalMixin, caseMixin, chatMixin],
    props: {
        sidebarOpen: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['close-sidebar'],
    setup(props, { emit }) {
        const store = useStore()

        const trigger = ref(null)
        const sidebar = ref(null)

        const storedSidebarExpanded = localStorage.getItem('sidebar-expanded')
        const sidebarExpanded = ref(
            storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
        )

        const route = useRoute()
        const router = useRouter()
        const currentRoute = router.currentRoute.value

        // close on click outside
        const clickHandler = ({ target }) => {
            if (!sidebar.value || !trigger.value) return
            if (
                !props.sidebarOpen ||
                sidebar.value.contains(target) ||
                trigger.value.contains(target)
            )
                return
            emit('close-sidebar')
        }

        // close if the esc key is pressed
        const keyHandler = ({ keyCode }) => {
            if (!props.sidebarOpen || keyCode !== 27) return
            emit('close-sidebar')
        }

        const openLink = (routeName) => {
            router.push({
                name: routeName,
                params: { slug: route.params.slug },
                query: { lang: route.query.lang },
            })
        }

        onMounted(() => {
            document.addEventListener('click', clickHandler)
            document.addEventListener('keydown', keyHandler)
        })

        onUnmounted(() => {
            document.removeEventListener('click', clickHandler)
            document.removeEventListener('keydown', keyHandler)
        })

        watch(sidebarExpanded, () => {
            localStorage.setItem('sidebar-expanded', sidebarExpanded.value)
            if (sidebarExpanded.value) {
                document.querySelector('body').classList.add('sidebar-expanded')
            } else {
                document.querySelector('body').classList.remove('sidebar-expanded')
            }
        })

        return {
            store,
            trigger,
            sidebar,
            sidebarExpanded,
            currentRoute,
            openLink,
        }
    },
    data: () => ({
        deadlines: [],
        timelines: [],
        pendingActions: [],
        gettingDeadlines: false,
        gettingPendingActions: false,
    }),
    mounted() {
        this.getAllDateline()
        this.getAllTimeline()
        this.getAllPendingActions()

        if (this.$echo) {
            this.$echo.channel("Deadline")
            .listen(".DeadlineEvent", (event) => {
                if (
                    event
                    &&
                    event.data
                    &&
                    event.data.emitted_by
                    &&
                    event.data.emitted_to
                    &&
                    this.$store.getters['auth/getCurrentUser']
                    &&
                    (
                        event.data.emitted_by == this.$store.getters['auth/getCurrentUser'].id
                        ||
                        event.data.emitted_to.some(item => item == this.$store.getters['auth/getCurrentUser'].id)
                    )
                ) {
                    this.getAllDateline();
                }
            });
            this.$echo.channel("PendingAction")
            .listen(".PendingActionEvent", (event) => {
                if (
                    event
                    &&
                    event.data
                    &&
                    event.data.emitted_by
                    &&
                    event.data.emitted_to
                    &&
                    this.$store.getters['auth/getCurrentUser']
                    &&
                    (
                        event.data.emitted_by == this.$store.getters['auth/getCurrentUser'].id
                        ||
                        event.data.emitted_to.some(item => item == this.$store.getters['auth/getCurrentUser'].id)
                    )
                ) {
                    this.getAllPendingActions();
                }
            });
        }
    },
    methods: {
        async getAllPendingActions () {
            if (this.gettingPendingActions) return
            this.gettingPendingActions = true
            this.isLoading = true
            await this.store
                .dispatch('useractions/getAllPendingActions', {
                    case_id: this.$route.params.slug,
                })
                .then((response) => {
                    this.isLoading = false
                    this.gettingPendingActions = false
                    const responseData = response.data.data
                    this.pendingActions = responseData
                })
                .catch((error) => {
                    this.isLoading = false
                    this.gettingPendingActions = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async getAllDateline() {
            if (this.gettingDeadlines) return
            this.isLoading = true
            this.gettingDeadlines = true
            await this.store
                .dispatch('calendar/getAllDateline', {
                    case_id: this.$route.params.slug,
                })
                .then((response) => {
                    this.isLoading = false
                    this.gettingDeadlines = false
                    const responseData = response.data.data
                    this.deadlines = responseData
                })
                .catch((error) => {
                    this.isLoading = false
                    this.gettingDeadlines = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        async getAllTimeline() {
            this.isLoading = true
            await this.store
                .dispatch('timeline/getAllTimeline', {
                    case_id: this.$route.params.slug,
                    types: 'chat,calendar',
                })
                .then((response) => {
                    this.isLoading = false
                    const responseData = response.data.data
                    this.timelines = responseData
                })
                .catch((error) => {
                    this.isLoading = false
                    this.toast.error(this.extractErrorResponse(error))
                })
        },
        toDeadlineDate(deadline) {
            return this.$router.push({
                name: 'calendar',
                params: {
                    slug: this.$route.params.slug,
                },
                query: {
                    status: this.$route.query.status,
                    date: deadline.due_date,
                },
            })
        },
        clickPendingAction() {
            return

            $router.push({
                name: 'timeline',
                params: { slug: $route.params.slug },
                query: {
                    name: $route.query.name,
                    status: $route.query.status,
                    sub_status: $route.query.sub_status,
                    lang: $route.query.lang,
                },
            })
        },
    },
    computed: {
        getReloadUserActions () {
            return this.store.getters['rightsidebar/getReloadUserActions'];
        },
        getReloadDeadlines () {
            return this.store.getters['rightsidebar/getReloadDeadlines'];
        },
        getClassDeadLineType () {
            return (deadline) => {
                const types = {
                    Alert: 'bg-sky-500',
                    Document: 'bg-yellow-500',
                    Meeting: 'bg-green-500',
                }
                return types[deadline.type];
            }
        }
    },
    watch: {
        getReloadUserActions (val) {
            this.getAllPendingActions()
        },
        getReloadDeadlines (val) {
            this.getAllDateline()
        },
    }
}
</script>
