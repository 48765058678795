<template>
  <img
    :class="[wrapperClass !== '' ? wrapperClass : 'rounded-full']"
    :src="src ?? defaultImage"
    :width="width"
    :height="height"
    :alt="alternate"
  />
</template>

<script>
export default {
  name: 'ImageLoaderComponent',
  inject: ['defaultImage'],
  props: {
    src: {
      type: String,
      default: null,
    },
    alternate: {
      type: String,
      default: 'image',
    },
    wrapperClass: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: '50',
    },
    height: {
      type: String,
      default: '50',
    },
  },
}
</script>
