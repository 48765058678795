<template>
    <div class="flex h-screen overflow-hidden">
        <!-- Sidebar -->
        <Sidebar :sidebarOpen="sidebarOpen" @close-sidebar="sidebarOpen = false" />

        <!-- Content area -->
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-white">
            <!-- Site header -->
            <Header :sidebarOpen="sidebarOpen" @toggle-sidebar="sidebarOpen = !sidebarOpen" />

            <main>
                <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
                    <div class="m-auto mt-16 max-w-2xl">
                        <div class="px-4 text-center">
                            <div class="mb-8 inline-flex">
                                <img src="../../assets/images/404-illustration.svg" width="176" height="176"
                                    alt="404 illustration" />
                            </div>
                            <div class="mb-6">
                                Hmm...this page doesn’t exist. Try searching for something else!
                            </div>
                            <router-link to="/" class="btn bg-sky-500 text-white hover:bg-sky-600">Back To
                                Dashboard</router-link>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import Sidebar from '../../partials/Sidebar.vue'
import Header from '../../partials/Header.vue'

export default {
    name: 'PageNotFound',
    components: {
        Sidebar,
        Header,
    },
    setup() {
        const sidebarOpen = ref(false)

        return {
            sidebarOpen,
        }
    },
}
</script>
