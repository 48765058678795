<template>
  <main class="bg-white">
    <div class="relative flex">
      <!-- Content -->
      <div class="w-full">
        <div
          class="flex h-full min-h-screen flex-col justify-center bg-gray-100"
        >
          <div class="mb-8 flex justify-center">
            <img
              class="h-14 object-cover object-center"
              src="../assets/images/logo.png"
              alt="Redek Logo"
            />
          </div>
          <div
            class="w-full self-center rounded-lg bg-white px-8 py-8 shadow-md lg:w-1/3"
          >
            <h1 class="mb-2 text-3xl font-bold text-slate-800">
              {{ $t('forgotPassword.title') }}
            </h1>
            <div class="text-justify text-sm">
              {{ $t('forgotPassword.description') }}

              <div class="inline w-full text-sm">
                <span class="pr-1">{{ $t('forms.or') }}</span>
                <router-link
                id="form-forgot-password-action-signin"
                class="font-bold text-sky-500"
                to="/login">
                  {{ $t('button.loginInstead') }}
                </router-link>
              </div>
            </div>
            <Form
            id="form-forgot-password"
              ref="form"
              v-slot="{ errors }"
              :validation-schema="forgotPasswordSchema"
              @submit="doReset"
            >
              <div class="mt-4 space-y-4">
                <div>
                  <label class="mb-1 block text-sm font-medium" for="email">
                    {{ $t('formLabel.email') }}
                  </label>
                  <Field v-slot="{ field }" v-model="email" name="email">
                    <input
                      id="form-forgot-password-email"
                      v-bind="field"
                      class="form-input h-12 w-full rounded-md"
                      type="email"
                      :placeholder="$t('formInput.enterEmail')"
                    />
                  </Field>
                  <span class="text-sm text-red-500">
                    <template v-if="errors.email !== undefined">
                      {{ $t(errors.email) }}
                    </template>
                  </span>
                </div>
              </div>
              <div class="mt-1 flex flex-col">
                <button
                id="form-forgot-password-action-submit"
                  class="btn text-md mt-2 h-12 w-full rounded-md"
                  :class="[
                    email === '' || errors.email !== undefined
                      ? ' bg-gray-200 text-gray-50'
                      : ' bg-sky-500 text-white hover:bg-sky-600',
                    isLoading ? 'cursor-not-allowed' : 'cursor-pointer',
                  ]"
                  type="submit"
                >
                  <span v-if="isLoading">
                    {{ $t('general.loading') }}
                  </span>
                  <span v-else>
                    {{ $t('button.resetPassword') }}
                  </span>
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Form, Field } from 'vee-validate'
import globalMixin from '@/mixins/global.js'

import { useToast } from 'vue-toastification'

export default {
  name: 'ForgotPasswordPage',
  components: {
    Form,
    Field,
  },
  mixins: [globalMixin],
  setup() {
    const toast = useToast()
    return { toast }
  },
  data: () => ({
    email: '',
  }),
  methods: {
    doReset() {
      if (this.isLoading) return

      this.isLoading = true
      const payload = {
        email: this.email,
      }

      this.$store
        .dispatch('auth/forgotPassword', payload)
        .then((response) => {
            this.isLoading = false
            this.toast.success(this.$t('resetPassword.successEmailSend'));
                // this.$store.dispatch('auth/setActiveEmail', this.email)
                // this.$router.push({
                //   name: 'verification-otp',
                //   params: {
                //     type: 'forgot-password',
                //   },
                //   query: {
                //     lang: this.$route.query.lang,
                //   },
                // })

        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
  },
}
</script>
