<template>
  <div class="sticky">
    <div
      class="flex h-16 items-center justify-between border-b border-slate-200 bg-white px-4 sm:px-6 md:px-5"
    >
      <!-- People -->
      <div class="flex items-center">
        <!-- Close button -->
        <button
          class="mr-4 text-slate-400 hover:text-slate-500 md:hidden"
          @click.stop="$emit('toggle-msgsidebar')"
          aria-controls="messages-sidebar"
          :aria-expanded="msgSidebarOpen"
        >
          <span class="sr-only">Close sidebar</span>
          <svg
            class="h-6 w-6 fill-current"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z"
            />
          </svg>
        </button>
        <!-- People list -->
        <div class="-ml-px flex -space-x-3">
          <a class="block" href="#0">
            <img
              class="box-content rounded-full border-2 border-white"
              src="../../assets/images/user-32-01.jpg"
              width="32"
              height="32"
              alt="User 01"
            />
          </a>
          <a class="block" href="#0">
            <img
              class="box-content rounded-full border-2 border-white"
              src="../../assets/images/user-32-02.jpg"
              width="32"
              height="32"
              alt="User 04"
            />
          </a>
        </div>
      </div>
      <!-- Buttons on the right side -->
      <div class="flex">
        <button
          class="ml-2 shrink-0 rounded border border-slate-200 p-1.5 shadow-sm hover:border-slate-300"
        >
          <svg class="h-4 w-4 fill-current text-slate-400" viewBox="0 0 16 16">
            <path
              d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z"
            />
          </svg>
        </button>
        <button
          class="ml-2 shrink-0 rounded border border-slate-200 p-1.5 shadow-sm hover:border-slate-300"
        >
          <svg class="h-4 w-4 fill-current text-sky-500" viewBox="0 0 16 16">
            <path
              d="M14.3 2.3L5 11.6 1.7 8.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4 4c.2.2.4.3.7.3.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0z"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessagesHeader',
  props: ['msgSidebarOpen'],
}
</script>
