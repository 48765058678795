export default {
    data: function () {
        return {
            roles: [],
            filterDataRoles: {
                page: 1,
                take: 10,
                search: '',
                order: 'DESC',
                order_code: '',
                order_name: '',
                order_description: '',
            },
            metaDataRoles: {
                page: 1,
                take: 10,
                itemCount: 10,
                pageCount: 1,
                hasPreviousPage: false,
                hasNextPage: true,
            },
            formData: {
                first_name: '',
                last_name: '',
                username: '',
                email: '',
                phone: '',
                password: '',
                role_id: '',
            },
            TreeConfig: {
                customOptions: {
                    treeEvents: {
                        expanded: {
                            state: false,
                        },
                        collapsed: {
                            state: false,
                        },
                        selected: {
                            state: false,
                        },
                        checked: {
                            state: true,
                            fn: this.checkPermissionOnTree,
                        },
                    },
                    events: {
                        expanded: {
                            state: true,
                        },
                        selected: {
                            state: false,
                        },
                        checked: {
                            state: true,
                        },
                        editableName: {
                            state: true,
                            calledEvent: "checked",
                        },
                    },
                    addNode: { state: false },
                    editNode: { state: false },
                    deleteNode: { state: false },
                    showTags: true,
                },
                customStyles: {
                    tree: {
                        style: {
                            maxHeight: "300px",
                            width: "100%",
                            overflowY: "auto",
                            display: "inline-block",
                            textAlign: "left",
                            padding: "5px 20px"
                        },
                    },
                    row: {
                        style: {
                            width: "100%",
                            cursor: "pointer",
                            userSelect: "none",
                        },
                        child: {
                            class: "custom_tree_expand",
                            style: {
                                height: "35px",
                                display: "flex",
                                alignItems: "center",
                                gap: ".2rem",
                            },
                            active: {
                                style: {
                                    height: "35px",
                                },
                            },
                        },
                    },
                    rowIndent: {
                        paddingLeft: "30px",
                    },
                    text: {
                        style: {},
                        active: {
                            style: {
                                "font-weight": "bold",
                                color: "#2ECC71",
                            },
                        },
                    },
                }
            }
        }
    },
    methods: {
        newRole(event) {
            this.newRoleModalOpen = true
            this.isRoleEdit = false
            this.currentRole = {}
        },
        editRole(event) {
            this.newRoleModalOpen = true
            this.isRoleEdit = true
            this.currentRole = event.role
        },
        deleteRole(event) {
            this.deleteRoleModalOpen = true
            this.isRoleEdit = false
            this.currentRole = event.role
        },
        getAllRoles() {
            this.isLoading = true
            this.store.dispatch('roles/getAllRoles', {
                order: this.filterDataRoles.order,
                order_code: this.filterDataRoles.order_code,
                order_name: this.filterDataRoles.order_name,
                order_description: this.filterDataRoles.order_description,
                page: this.filterDataRoles.page,
                take: this.filterDataRoles.take,
                q: this.filterDataRoles.search,
            })
            .then((response) => {
                this.isLoading = false;
                const responseData = response.data;
                if (responseData.data) {
                    this.roles = responseData.data;
                }
                if (responseData.from) {
                    this.metaDataRoles = {
                        current_page: responseData.current_page,
                        from: responseData.from,
                        last_page: responseData.last_page,
                        links: responseData.links,
                        per_page: responseData.per_page,
                        to: responseData.to,
                        total: responseData.total,
                    }
                }
            })
            .catch((error) => {
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        submitData(formData) {
            if (this.isLoading) return
            this.formData = formData
            if (formData.role_id === null) {
                this.createRole()
            } else {
                this.updateRole()
            }
        },
        modalRoleClose() {
            this.newRoleModalOpen = false
            this.currentRole = {}
        },
        updateRole() {
            if (this.isLoading) return
            this.isLoading = true
            this.store.dispatch('roles/updateRole', {
                code: this.formData.code,
                name: this.formData.name,
                description: this.formData.description,
                role_id: this.$methods.encrypt(this.formData.role_id),
                permissions: this.formData.permissions,
            }).then((response) => {
                this.isLoading = false
                if (response.status === 200) {
                    const message = response.data?.message ?? 'Role updated successfully'
                    this.toast.success(message)
                    this.newRoleModalOpen = false
                    this.$router.go(-1);
                    // this.getAllRoles()
                }
            })
            .catch((error) => {
                this.isLoading = false
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        createRole() {
            if (this.isLoading) return;
            this.isLoading = true;
            this.store.dispatch('roles/createRole', {
                code: this.formData.code,
                name: this.formData.name,
                description: this.formData.description,
            }).then((response) => {
                this.isLoading = false
                if (response.status === 200 || response.status === 201) {
                    const message = response.data?.message ?? 'Role created successfully'
                    this.toast.success(message)
                    this.newRoleModalOpen = false
                    this.getAllRoles()
                }
            }).catch((error) => {
                this.isLoading = false
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        deleteRoleAPI(event) {
            if (this.isLoading) return
            this.isLoading = true
            this.store.dispatch('roles/deleteRole', {
                role_id: this.$methods.encrypt(event.id),
            }).then((response) => {
                this.isLoading = false
                if (response.status === 200) {
                    const message = response.data?.message ?? 'Role deleted successfully'
                    this.toast.success(message)
                    this.deleteRoleModalOpen = false
                    this.getAllRoles()
                }
            }).catch((error) => {
                this.isLoading = false
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        searchRole(event) {
            this.filterDataRoles.search = event
            this.getAllRoles()
        },
        changeSort(events) {
            this.headerData.forEach((item) => {
                if (item.name === events.name) item.sort = events.sort
            })
            if (events.name === 'Code') {
                this.filterDataRoles.order_code = events.sort
                this.filterDataRoles.order_name = ''
                this.filterDataRoles.order_description = ''
            } else if (events.name === 'Name') {
                this.filterDataRoles.order_code = ''
                this.filterDataRoles.order_name = events.sort
                this.filterDataRoles.order_description = ''
            } else if (events.name === 'Description') {
                this.filterDataRoles.order_code = ''
                this.filterDataRoles.order_name = ''
                this.filterDataRoles.order_description = events.sort
            }
            this.getAllRoles()
        },
        changeOrder(event) {
            this.filterDataRoles.order = ['DESC', 'ASC'].includes(event.target.value)
            ? event.target.value
            : 'DESC'
            this.getAllRoles()
        },
        getRoleById() {
            this.isLoading = true
            this.store.dispatch('roles/getRoleById', {
                role_id: this.$route.params.id
            }).then((response) => {
                this.isLoading = false;
                const responseData = response.data;
                if (responseData) {
                    this.currentRole = responseData;
                } else {
                    this.$router.go(-1);
                }
            }).catch((error) => {
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        getBuiltPermissions() {
            this.initializingPermissions = true;
            this.store.dispatch('roles/getBuiltPermissions', {
                role_id: this.$route.params.id
            }).then((response) => {
                this.initializingPermissions = false;
                const responseData = response.data;
                if (responseData) {
                    this.builtPermissions = responseData;
                }
            }).catch((error) => {
                this.toast.error(this.extractErrorResponse(error))
            })
        },
    },
  }
