import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'

const state = () => ({})

const getters = {}

const actions = {
    getAll({ commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            let params = {}
            if (payload.page) params.page = payload.page
            if (payload.take) params.per_page = payload.take
            if (payload.order) params.order = 'created_at.' + payload.order
            if (payload.q) params.search = payload.q
            if (!payload.manage) params.query = 'status.1'

            axios
                .get(`${routePathAPI()}/identificationtypes`, {
                    params: params,
                    headers: {
                        'x-language-code': currentLanguageCode,
                    },
                })
                .then(async (response) => {
                    if (response.status === 200) {
                        resolve({
                            status: response.status,
                            message: 'success',
                            data: response.data,
                        })
                    } else {
                        reject({
                            status: response.status,
                            message: 'failed',
                            data: response.data,
                        })
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    create ({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            let params = {
                nomenclature: payload.nomenclature,
                name: payload.name,
                status: payload.status,
            }
            axios.post(`${routePathAPI()}/identificationtypes`, params, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            })
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    update ({ state, commit }, payload) {
        const currentLanguageCode = router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE
        return new Promise((resolve, reject) => {
            let params = {
                nomenclature: payload.nomenclature,
                name: payload.name,
                status: payload.status,
            }
            axios.put(`${routePathAPI()}/identificationtypes/${ payload.id }`, params, {
                headers: {
                    'x-language-code': currentLanguageCode,
                },
            })
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    delete ({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(`${routePathAPI()}/identificationtypes/${ payload.id }`)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    resolve({
                        status: response.status,
                        message: 'success',
                        data: response.data,
                    })
                } else {
                    reject({
                        status: response.status,
                        message: 'failed',
                        data: response.data,
                    })
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
