<template>
    <div class="relative inline-flex">
        <button ref="trigger" class="group inline-flex max-w-full items-center justify-center"
            :class="mode === 'aesthetic' && 'rounded-full bg-slate-100 px-4 py-2'" aria-haspopup="true"
            :aria-expanded="dropdownOpen" @click.prevent="dropdownOpen = !dropdownOpen">
            <ImageLoader wrapper-class="h-8 w-8 rounded-full" :src="$store.getters['auth/getCurrentUser'].avatar" width="32" height="32"
                alternate="profileImage" />
            <div class="flex items-center truncate">
                <span class="ml-2 hidden truncate text-sm font-medium group-hover:text-slate-800 md:inline">
                    {{ getName($store.getters['auth/getCurrentUser'], 'first', 15) }}
                </span>
                <svg v-if="mode === 'aesthetic'" class="ml-2 h-3 w-3 shrink-0 text-slate-800" viewBox="0 0 8 12"
                    fill="none">
                    <path d="M1.66669 1.33341L6.33335 6.00008L1.66669 10.6667" stroke="currentColor" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                <svg v-else class="ml-2 h-3 w-3 shrink-0 fill-current text-slate-400" viewBox="0 0 12 12">
                    <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                </svg>
            </div>
        </button>
        <transition enter-active-class="transition ease-out duration-200 transform"
            enter-from-class="opacity-0 -translate-y-2" enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-200" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <div v-show="dropdownOpen"
                class="min-w-44 absolute top-full z-10 mt-1 origin-top-right overflow-hidden rounded border border-slate-200 bg-white py-1.5 shadow-lg"
                :class="align === 'right' ? 'right-0' : 'left-0'">
                <div class="mb-1 border-b border-slate-200 px-3 pt-0.5 pb-2">
                    <div class="line-clamp-1 font-medium text-slate-800">
                        {{ getName($store.getters['auth/getCurrentUser'], 'first', 15) }}
                    </div>
                    <div class="text-xs italic text-slate-500">
                        {{ $store.getters['auth/getCurrentUser'] ? $store.getters['auth/getCurrentUser'].role.name : '' }}
                    </div>
                </div>
                <ul ref="dropdown" @focusin="dropdownOpen = true" @focusout="dropdownOpen = false">
                    <li>
                        <router-link class="flex items-center py-1 px-3 text-sm font-medium text-sky-500 hover:text-sky-600"
                            to="/profile/account" @click="dropdownOpen = false">
                            {{ $t('profile.title') }}
                        </router-link>
                    </li>
                    <li>
                        <span
                            class="flex cursor-pointer items-center py-1 px-3 text-sm font-medium text-sky-500 hover:text-sky-600"
                            @click="logout">
                            {{ $t('profile.signOut') }}
                        </span>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import globalMixin from '@/mixins/global.js'
import profileMixin from '@/mixins/profile.js'

import ImageLoader from '@/components/image/ImageLoader.vue'

export default {
    name: 'DropdownProfile',
    components: { ImageLoader },
    mixins: [globalMixin, profileMixin],
    props: ['align', 'mode'],
    setup() {
        const dropdownOpen = ref(false)
        const store = useStore()
        const trigger = ref(null)
        const dropdown = ref(null)
        const router = useRouter()

        // close on click outside
        const clickHandler = ({ target }) => {
            if (
                !dropdownOpen.value ||
                dropdown.value.contains(target) ||
                trigger.value.contains(target)
            )
                return
            dropdownOpen.value = false
        }

        // close if the esc key is pressed
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen.value || keyCode !== 27) return
            dropdownOpen.value = false
        }

        onUnmounted(() => {
            document.removeEventListener('click', clickHandler)
            document.removeEventListener('keydown', keyHandler)
        })

        return {
            dropdownOpen,
            trigger,
            dropdown,
            store,
            clickHandler,
            keyHandler,
        }
    },
    mounted () {
        document.addEventListener('click', this.clickHandler)
        document.addEventListener('keydown', this.keyHandler)

        this.getProfile().then(() => {
            if (this.showGuide) this.updateShowGuide()
        })
    },
    methods: {
        logout() {
            this.dropdownOpen = false
            this.$store.dispatch('auth/logout')
            this.$router.push({ name: 'login' })
        },
    },
    computed: {
        getCurrentUser () {
            return this.$store.getters['auth/getCurrentUser']
        }
    }
}
</script>
