<template>
  <!-- <div class="-m-1.5 flex flex-wrap items-center"> -->
  <!-- <div class="m-1.5"> -->
  <ModalBasic
    id="assignCaseDatemodal"
    :backdrop="true"
    :modal-open="isOpen"
    :show-border-header="false"
    :title="$t('case.dueTime')"
    wrapper-class="max-w-md"
    class="relative"
    @close-modal="close"
  >
    <div class="px-5 pb-20">
      <div class="absolute flex w-full flex-col pr-4">
        <label class="mb-1 block text-xs font-bold" for="caseDueDate">
          {{ $t('case.setDateTime') }}
        </label>
        <Datepicker
          v-model="caseDueDate"
          :max-today="false"
          :max-one-month="true"
          :min-today="true"
          :show-icon="true"
          wrap-class="form-input h-12 w-full rounded-md pl-9 z-10"
          mode="single"
          @change-date="changeDate"
        />
      </div>
    </div>
    <div class="px-5 py-4">
      <div class="flex flex-wrap justify-center space-x-2">
        <button
          type="submit"
          class="btn-sm w-full whitespace-nowrap rounded-lg bg-sky-500 p-3 text-white hover:bg-sky-600"
          @click.stop="confirm"
        >
          <span>
            {{ $t('button.save') }}
          </span>
        </button>
      </div>
    </div>
  </ModalBasic>
  <!-- </div> -->
  <!-- </div> -->
</template>
<script>
import ModalBasic from '@/components/ModalBasic.vue'

import Datepicker from '@/components/Datepicker.vue'
//   import Timepicker from '@/components/Timepicker.vue'

export default {
  name: 'ModalAssignCaseDateComponent',
  components: {
    ModalBasic,
    Datepicker,
    //   Timepicker,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closed', 'confirm'],
  data: () => ({
    caseDueDate: null,
  }),
  methods: {
    close() {
      this.$emit('closed')
    },
    confirm() {
      this.$emit('confirm', this.caseDueDate)
    },
    changeDate(val) {
      this.caseDueDate = val
    },
  },
}
</script>
