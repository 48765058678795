<template>
    <ModalBlank id="success-modal" :modal-open="isOpen" @close-modal="close">
        <div class="flex flex-col p-5 gap-6">
            <div class="flex items-start gap-3">
                <div class="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-emerald-100">
                    <svg class="h-4 w-4 shrink-0 fill-current text-emerald-500" viewBox="0 0 16 16">
                        <path
                            d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
                    </svg>
                </div>
                <div>
                    <div class="text-lg font-semibold text-slate-800">
                        {{ title }}
                    </div>
                    <div
                    v-if="description"
                    class="text-sm">
                        <div class="space-y-2">
                            <p>
                                {{ description }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap gap-4 justify-end">
                <button class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300" @click.stop="close">
                    {{ $t('calendar.modalConfirm.cancel') }}
                </button>

                <button class="btn-sm bg-sky-500 text-white hover:bg-sky-600" @click.stop="confirmed">
                    {{ $t('calendar.modalConfirm.confirm') }}
                </button>
            </div>
        </div>
    </ModalBlank>
</template>
<script>
import ModalBlank from './ModalBlank.vue'

export default {
    name: 'ModalConfirmationComponent',
    components: { ModalBlank },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
    },
    emits: ['confirmed', 'closed'],
    methods: {
        close() {
            this.$emit('closed')
        },
        confirmed() {
            this.$emit('confirmed')
        },
    },
}
</script>
