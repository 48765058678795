export default {
    data: function () {
        return {
            permissions: [],
            filterDataPermissions: {
                page: 1,
                take: 10,
                search: '',
                order: 'DESC',
                order_name: '',
                order_description: '',
                order_reference: '',
                order_status: '',
            },
            metaDataPermissions: {
                page: 1,
                take: 10,
                itemCount: 10,
                pageCount: 1,
                hasPreviousPage: false,
                hasNextPage: true,
            },
            formData: {
                name: '',
                description: '',
                functionalities_id: '',
                reference: '',
                // icon: '',
                status: '',
                permission_id: '',
            },
        }
    },
    methods: {
        newPermission(event) {
            this.newPermissionModalOpen = true
            this.isPermissionEdit = false
            this.currentPermission = {}
        },
        editPermission(event) {
            this.newPermissionModalOpen = true
            this.isPermissionEdit = true
            this.currentPermission = event.permission
        },
        deletePermission(event) {
            this.deletePermissionModalOpen = true
            this.isPermissionEdit = false
            this.currentPermission = event.permission
        },
        getAllPermissions() {
            this.isLoading = true
            this.store.dispatch('permissions/getAllPermissions', {
                order: this.filterDataPermissions.order,
                order_name: this.filterDataPermissions.order_name,
                order_description: this.filterDataPermissions.order_description,
                order_reference: this.filterDataPermissions.order_reference,
                order_status: this.filterDataPermissions.order_status,
                page: this.filterDataPermissions.page,
                take: this.filterDataPermissions.take,
                q: this.filterDataPermissions.search,
            })
            .then((response) => {
                this.isLoading = false;
                const responseData = response.data;
                if (responseData.data) {
                    this.permissions = responseData.data;
                }
                if (responseData.from) {
                    this.metaDataPermissions = {
                        current_page: responseData.current_page,
                        from: responseData.from,
                        last_page: responseData.last_page,
                        links: responseData.links,
                        per_page: responseData.per_page,
                        to: responseData.to,
                        total: responseData.total,
                    }
                }
            })
            .catch((error) => {
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        submitData(formData) {
            if (this.isLoading) return
            this.formData = formData
            if (formData.permission_id === null) {
                this.createPermission()
            } else {
                this.updatePermission()
            }
        },
        modalPermissionClose() {
            this.newPermissionModalOpen = false
            this.currentPermission = {}
        },
        updatePermission() {
            if (this.isLoading) return
            this.isLoading = true
            this.store.dispatch('permissions/updatePermission', {
                name: this.formData.name,
                description: this.formData.description,
                functionalities_id: this.formData.functionalities_id,
                reference: this.formData.reference,
                status: this.formData.status,
                permission_id: this.formData.permission_id,
            }).then((response) => {
                this.isLoading = false
                if (response.status === 200) {
                    const message = response.data?.message ?? 'Permission updated successfully'
                    this.toast.success(message)
                    this.newPermissionModalOpen = false
                    this.getAllPermissions()
                }
            })
            .catch((error) => {
                this.isLoading = false
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        createPermission() {
            if (this.isLoading) return;
            this.isLoading = true;
            this.store.dispatch('permissions/createPermission', {
                name: this.formData.name,
                description: this.formData.description,
                functionalities_id: this.formData.functionalities_id,
                reference: this.formData.reference,
                status: this.formData.status,
            }).then((response) => {
                this.isLoading = false
                if (response.status === 200 || response.status === 201) {
                    const message = response.data?.message ?? 'Permission created successfully'
                    this.toast.success(message)
                    this.newPermissionModalOpen = false
                    this.getAllPermissions()
                }
            }).catch((error) => {
                this.isLoading = false
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        deletePermissionAPI(event) {
            if (this.isLoading) return
            this.isLoading = true
            this.store.dispatch('permissions/deletePermission', {
                permission_id: event.id,
            }).then((response) => {
                this.isLoading = false
                if (response.status === 200) {
                    const message = response.data?.message ?? 'Permission deleted successfully'
                    this.toast.success(message)
                    this.deletePermissionModalOpen = false
                    this.getAllPermissions()
                }
            }).catch((error) => {
                this.isLoading = false
                this.toast.error(this.extractErrorResponse(error))
            })
        },
        searchPermission(event) {
            this.filterDataPermissions.search = event
            this.getAllPermissions()
        },
        changeSort(events) {
            this.headerData.forEach((item) => {
                if (item.name === events.name) item.sort = events.sort
            })
            this.filterDataPermissions.order_name = events.name === 'Name' ? events.sort : '';
            this.filterDataPermissions.order_description = events.name === 'Description' ? events.sort : '';
            this.filterDataPermissions.order_reference = events.name === 'Reference' ? events.sort : '';
            this.filterDataPermissions.order_status = events.name === 'Status' ? events.sort : '';
            this.getAllPermissions()
        },
        changeOrder(event) {
            this.filterDataPermissions.order = ['DESC', 'ASC'].includes(event.target.value)
            ? event.target.value
            : 'DESC'
            this.getAllPermissions()
        },
    },
  }
