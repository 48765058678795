<template>
    <div class="-m-1.5 flex flex-wrap items-center">
        <div class="m-1.5">
            <ModalBasic
            id="notes-modal"
            :modal-open="isOpen"
            :show-border-header="false"
            :title="isEdit ? $t('admin.roles.modalCreateRole.editTitle') : $t('admin.roles.modalCreateRole.title')"
            @close-modal="close"
            :backdrop="true"
            >
                <Form
                id="form_create_update_roles"
                ref="form"
                v-slot="{ errors }"
                :validation-schema="rolesCreationSchema"
                @submit="submitForm"
                >
                    <div class="px-5">
                        <div class="space-y-3 px-5">
                            <Field v-slot="{ field }" name="isEdit">
                                <input type="checkbox" name="isEdit" class="hidden" v-bind="field" :checked="isEdit" />
                            </Field>
                            <div>
                                <label class="mb-1 block text-xs font-bold">
                                    {{ $t('admin.roles.modalCreateRole.code') }}
                                </label>
                                <Field v-slot="{ field }" v-model="code" name="code">
                                    <input
                                    v-bind="field"
                                    class="form-input w-full p-2"
                                    type="text"
                                    id="form_create_update_roles_code"
                                    required
                                    :placeholder="$t('admin.roles.modalCreateRole.code')" />
                                </Field>
                                <div v-if="errors.code !== undefined" class="flex w-full">
                                    <span class="text-sm text-red-500">
                                        {{ $t(errors.code) }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <label class="mb-1 block text-xs font-bold">
                                    {{ $t('admin.roles.modalCreateRole.name') }}
                                </label>
                                <Field v-slot="{ field }" v-model="name" name="name">
                                    <input
                                    v-bind="field"
                                    class="form-input w-full p-2"
                                    type="text"
                                    id="form_create_update_roles_name"
                                    required
                                    :placeholder="$t('admin.roles.modalCreateRole.name')" />
                                </Field>
                                <div v-if="errors.name !== undefined" class="flex w-full">
                                    <span class="text-sm text-red-500">
                                        {{ $t(errors.name) }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <label class="mb-1 block text-xs font-bold">
                                    {{ $t('admin.roles.modalCreateRole.description') }}
                                </label>
                                <Field v-slot="{ field }" v-model="description" name="description">
                                    <input
                                    id="form_create_update_roles_description"
                                    v-bind="field"
                                    class="form-input w-full p-2"
                                    type="text"
                                    required
                                    :placeholder="$t('admin.roles.modalCreateRole.description')" />
                                </Field>
                                <div v-if="errors.description !== undefined" class="flex w-full">
                                    <span class="text-sm text-red-500">
                                        {{ $t(errors.description) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-5 py-4">
                        <div class="flex flex-wrap justify-center space-x-2 px-4">
                            <button
                            type="submit"
                            id="form_create_update_roles_action_submit"
                            class="btn-sm w-full whitespace-nowrap rounded-lg bg-sky-500 p-3 text-white hover:bg-sky-600"
                            :class="[
                                isRoleLoading ? 'cursor-not-allowed' : 'cursor-pointer',
                            ]">
                                <span v-if="isRoleLoading">
                                    {{ $t('general.loading') }}
                                </span>
                                <span v-else>
                                    {{ $t('admin.roles.modalCreateRole.save') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </Form>
            </ModalBasic>
        </div>
    </div>
</template>
<script>
import ModalBasic from '@/components/ModalBasic.vue'
import { Form, Field } from 'vee-validate'
import globalMixin from '@/mixins/global.js'

export default {
    name: 'ModalCreateRoleComponent',
    components: {
        ModalBasic,
        Form,
        Field,
    },
    mixins: [globalMixin],
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        isRoleLoading: {
            type: Boolean,
            default: false,
        },
        currentRole: {
            type: Object,
            default: () => {
                return {
                    id: '',
                    first_name: '',
                    last_name: '',
                    email: '',
                }
            },
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['closed', 'submitData'],
    data: () => ({
        role_id: null,
        code: null,
        name: '',
        description: '',
    }),
    watch: {
        currentRole(val) {
            this.role_id = val.id ?? null
            this.code = val.code ?? null
            this.name = val.name
            this.description = val.description
        },
        isOpen(val) {
            if (!val) this.close()
            // else this.$refs.form.resetForm()
        },
    },
    methods: {
        close() {
            this.$refs.form.resetForm()

            this.$emit('closed')
        },
        submitForm() {
            const formData = {
                role_id: this.role_id,
                code: this.code,
                name: this.name,
                description: this.description,
            }
            this.$emit('submitData', formData)
        },
    },
}
</script>
